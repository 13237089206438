import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './AutorizarCotizacionesProductosGenerales.css';
import { Table } from 'reactstrap';
import {estiloBtnVer, estiloComboBox, estiloFiltroEncabezados} from '../../Services/PaletaDeColores';
import { FaCheck } from 'react-icons/fa'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import {  getInformacionCotizacionProductoGeneral } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Cotizacion from './Cotizacion'
import { formatoNumeroTecla } from '../../Services/Validaciones';

export default class AutorizarCotizacionesProductosGenerales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cuantosdecimales:2,
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      accionmodal: 1,
      titulomodal: '',

      itemseleccionado: null,

      listatiporequesicion: [],
      listaestatus: [],
      listastock: [],

      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      contenidorequisicion: [],

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      tablaresumenrequisicion: {
        encabezados: [
          "Tipo",
          "Producto",
          "Comentario",
          "Área destino",
          "Unidad de medida",
          "Cantidad",
          "Pendientes",
          "Completados",
        ],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "20%",
          "15%",
          "15%",
          "15%",
          "15%"
        ],
        mindimensiones: [
          "150px",
          "170px",
          "200px",
          "130px",
          "170px",
          "100px",
          "120px",
          "150px"
        ],
        maxdimensiones: [
          "150px",
          "170px",
          "200px",
          "130px",
          "170px",
          "100px",
          "120px",
          "150px"
        ],
        elementos: [],
        total: 0.00,
      },    

      tablaresumencompra: {
        encabezados: [
          "ID cotización",
          "Proveedor",
          "Fecha de compra",
          "Estatus",
          "Total"
        ],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "20%",
          "15%"
        ],
        mindimensiones: [
          "100px",
          "200px",
          "150px",
          "200px",
          "150px"
        ],
        maxdimensiones: [
          "100px",
          "200px",
          "150px",
          "200px",
          "150px"
        ],
        elementos: [],
        informacion: {},
        total: 0,
      },

      // Modal  (todos)
      modalautorizacion: false,

      // Modal formulario rechazar cotización (todos)
      modalrechazarcotizacion: false,

      redireccionando: false,
      redireccionandobandera: "",

      idcotizacion: 0,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idrequisicion = await localStorage.getItem('dato')
    if (idrequisicion != null) {
      var respautorizacioncotizacion = await getInformacionCotizacionProductoGeneral(idrequisicion);
      if (this.manejadorLlamadasApis(respautorizacioncotizacion)) {
        return;
      }

      if (respautorizacioncotizacion.code == 200) {
        await toast.error(respautorizacioncotizacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var informacionrequisicion = this.state.informacionrequisicion
      informacionrequisicion = respautorizacioncotizacion.data ? respautorizacioncotizacion.data : {};

      var tablaresumenrequisicion = this.state.tablaresumenrequisicion
      tablaresumenrequisicion.elementos = informacionrequisicion.Resumen ? informacionrequisicion.Resumen : []

      var contenidorequisicion = this.state.contenidorequisicion
      contenidorequisicion = informacionrequisicion.cotizaciones
      contenidorequisicion = this.calcularAcumulados(contenidorequisicion)

      var tablaresumencompra = this.state.tablaresumencompra
      tablaresumencompra.informacion = informacionrequisicion.resumencompras ? informacionrequisicion.resumencompras : {}
      tablaresumencompra.elementos = tablaresumencompra.informacion.compras ? tablaresumencompra.informacion.compras : []

      var stock = informacionrequisicion.stock ? informacionrequisicion.stock : []
      var listastock = stock.productos ? stock.productos : []

      this.setState({
        informacionrequisicion: informacionrequisicion,
        contenidorequisicion: contenidorequisicion,
        tablaresumenrequisicion: tablaresumenrequisicion,
        listastock: listastock,
        idcotizacion: informacionrequisicion.idcotizacion
      });
    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  
  calcularAcumulados(cotizaciones) {
    let nuevacotizacion = [];

    cotizaciones.forEach(coti => {
      let sumaprecios = 0;
      let sumarivas = 0;
      coti.productos.forEach(prod => {
        sumaprecios = sumaprecios + parseFloat(prod.precio);
        sumarivas = sumarivas + parseFloat(prod.iva);
      });

      nuevacotizacion.push({

        "idcotizacion_proveedor": coti.idcotizacion_proveedor,
        "idproveedor": coti.idproveedor,
        "proveedor": coti.proveedor,
        "fechacompra": coti.fechacompra,
        "idestatuscotizacion": coti.idestatuscotizacion,
        "estatuscotizacion": coti.estatuscotizacion,
        "mensaje_estatuscotizacion": coti.mensaje_estatuscotizacion,
        "iva_activo": coti.iva_activo,
        "productos": coti.productos,
        "total": parseFloat(sumarivas + sumaprecios).toFixed(4),
        "totalivas": parseFloat(sumarivas).toFixed(4),
        "totalprecios": parseFloat(sumaprecios).toFixed(4),

      });
    });




    return nuevacotizacion

  }


  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }


  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={this.state.redireccionandobandera} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

       

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.informacionrequisicion.nombre}</label>
              </div>

              {this.state.informacionrequisicion.periodo && this.state.informacionrequisicion.mes ?
                <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>
                    {'Plan anual ' + this.state.informacionrequisicion.periodo + ', ' + this.state.informacionrequisicion.mes}
                  </label>
                </div> : null}

           
                <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.tiporequisicion}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 col-lg-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaestimada_entrega}</label>
              </div>

       

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.estatusrequisicion}</label>
              </div>

             

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechacreacion}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.solicitante}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >
                  {this.state.informacionrequisicion.fechaactualizacion ? this.state.informacionrequisicion.fechaactualizacion : this.state.informacionrequisicion.fechacreacion}
                </label>
              </div>

            </div>
            <br />
            <br />

          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className='container-fluid contenedorResumen' style={{ background: "white", textAlign: "right" }}>
          <label className='lblTexto letraTitulos' >
            <button className='btncomprasCotizaciones' onClick={() => this.setState({ cuantosdecimales: (this.state.cuantosdecimales == 2) ? (4) : (2) })}>Mostrar formato con {(this.state.cuantosdecimales == 2) ? (4) : (2)} decimales</button>
          </label>
        </div>

        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>

          {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>
                <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                  <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Resumen requisición</b></label>
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablaresumenrequisicion.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaresumenrequisicion.mindimensiones[index],
                              maxWidth: this.state.tablaresumenrequisicion.maxdimensiones[index],
                              width: this.state.tablaresumenrequisicion.dimensiones[index],
                              textAlign: index > 0 ? 'center' : '',
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.tablaresumenrequisicion.elementos.map((item, index) => (
                        <tr key={"tabla" + index}
                          style={{
                            borderBottom: (index == this.state.tablaresumenrequisicion.elementos.length - 1) ? 'solid 1px #91b9f9' : '',
                            color: item.pendientes == '0' ? '#007bff' : ''
                          }}>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {"Maquinaria"}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {item.producto}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {"Comentario de prueba"}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {"área abcd"}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {item.unidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {item.cantidad}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'center' }}>
                            {item.pendientes}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'center' }}>
                            {item.pendientes == '0' ?  <FaCheck className="icoCheck" /> : ''}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}

          {/* ////////////////////   INICIO Sección COTIZACIONES TARJETAS ////////////////////////////////////// */}
          <div className="container-fluid containerTabla">
            <div className="container-fluid anchoMaximo">
              <div>
                {this.state.contenidorequisicion.length > 0 ?
                  this.state.contenidorequisicion.map((item, index) => (
                    <Cotizacion key={"tipo_" + index} item={item} 
                      listastock={JSON.parse(JSON.stringify(item.productos))}
                      permisoautorizacion={this.state.informacionrequisicion.permisoautorizacion}
                      fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} 
                      cuantosdecimales={this.state.cuantosdecimales}/>
                  ))
                  : <div></div>
                }
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin Sección COTIZACIONES TARJETAS ////////////////////////////////////// */}

          {/* ////////////////////   Inicio Sección Tabla  Resumen COMPRA ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1050px', maxWidth: '1200px' }}>
                {(this.state.tablaresumencompra.elementos.length != 0) ?
                  <>
                    <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                      <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Resumen compras</b></label>
                    </div>
                    <div className='divMinWidthAutoCot' style={{ aamarginBottom: '0px' }}>
                      <Table style={{ marginBottom: '0px' }}>
                        <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                          <tr>
                            {this.state.tablaresumencompra.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tablaresumencompra.mindimensiones[index],
                                  maxWidth: this.state.tablaresumencompra.maxdimensiones[index],
                                  width: this.state.tablaresumencompra.dimensiones[index],
                                  color: this.state.colorencabezadostabla
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {this.state.tablaresumencompra.elementos.map((item, index) => (
                            <tr key={"tabla" + index} style={{ borderBottom: (index == this.state.tablaresumencompra.elementos.length - 1) ? 'solid 1px #91b9f9' : '' }}>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.idproveedorcompra}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.proveedorcompra}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.fechacompra}
                              </td>
                              <td className={"letraNormal lblSubtabla stlEstatusCompra" + ([1, 2, 3].includes(item.idestatuscompra) ? 1 : item.idestatuscompra == 4 ? 2 : '')} style={{ fontWeight: 'bold' }}>
                                {item.estatuscompra}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{textAlign:"right", fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E' }}>
                                ${formatoNumeroTecla(item.total, this.state.cuantosdecimales)}
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td className="lblEncabezadosTabla letraTitulos" colSpan={4}
                              style={{ color: this.state.colorencabezadostabla }}>
                              <b>Total a pagar por requisición</b>
                            </td>
                            <td className="lblEncabezadosTablaa letraTitulos"
                              style={{ color: this.state.colorencabezadostabla, textAlign:"right" }}>
                              <b>${formatoNumeroTecla(this.state.tablaresumencompra.informacion.totalgeneral, this.state.cuantosdecimales)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </> :
                  <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                    <label className='letraTitulos' style={{ padding: '15px', textAlign: 'center' }}><b>Sin cotizaciones</b></label>
                  </div>}
              </div>
            </div>
          </div>
          <br />
          {/* ////////////////////   Fin Sección Tabla  Resumen COMPRA ////////////////////////////////////// */}
        </div>
        

      </>
    )
  }
}

function AccionesVista({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'rectificarproductos') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/rectificaragroquimicos"} />)
  }else /*(bandera == 'goreferencias')*/ {
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)
  }
}
