import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Pagination from "react-js-pagination";

export default class Paginacion extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Pagination
                    className="pagination-container"
                    activePage={this.props.activepage}
                    itemsCountPerPage={this.props.itemscountperpage}
                    totalItemsCount={this.props.totalitemscount.length>0?this.props.totalitemscount.length:1}
                    pageRangeDisplayed={this.props.pagerangedisplayed}

                    onChange={this.props.cambiarcontenido}

                    prevPageText={<IoIosArrowBack className="icoFlechas" />}
                    nextPageText={<IoIosArrowForward className="icoFlechas" />}

                    activeClass={"paginacionActiva"}
                    itemClass={"paginacionItem"}

                    itemClassPrev={"paginacionPrevio"}
                    itemClassNext={"paginacionSiguiente"}

                    disabledClass={"paginacionDisabled"}
                    hideFirstLastPages={true}
                />
            </>
        )
    }
}



// genera y devuelve la lista de elementos para llenar la página seleccionada por el usuario
export function buildContent(itemsperpage, page, lista) {
    let list = lista;
    let content = [];
    let itemfinal = ((itemsperpage * page) - 1);

    if (itemfinal > list.length)
        itemfinal = list.length - 1;
    let iteminicial = (itemsperpage * (page - 1));
    for (let i = iteminicial; i <= itemfinal; i++) {
        if (i < list.length) {
            content.push(list[i]);
        }
    }
    return content;
}

