import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsCheckLg } from "react-icons/bs";
import "./ModalFormularioMoverComprasAlmacenAgroquimicos.css";
import { estiloComboBox, estilosModal, } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Table } from '@material-ui/core';
import {
    getCatalogoAlmacenes, getListaAlmacenesProductosGenerales, getInformacionCompra,
    getListaAlmacenesEquiposyHerramientas, getComboAreasDeTrabajo, getListaMaquinarias, getListaRanchosDisponibles,
    getListaTipoEquipos, getListaEquipos,
    moverProductosCompra, moverProductosGeneralesCompra, moverProductosGeneralesEquipos, moverProductosGeneralesAreasRanchosVehiculos,
    moverProductosGeneralesSoloEquipos
} from '../../../Services/Api';
import ModalFormularioMoverComprasAlmacenAgroquimicosTablaMovimiento from './ModalFormularioMoverComprasAlmacenAgroquimicosTablaMovimiento';

import ModalFormularioMoverComprasGeneralesFiltros from './ModalFormularioMoverComprasGeneralesFiltro';
import ModalMoverAgroquimicosCompra from './ModalMoverAgroquimicosCompra';
import ModalConfirmarMoverProductos from './ModalConfirmarMoverProductos';


export default class ModalFormularioMoverComprasAlmacenAgroquimicos extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: "Mover compra a almacén",
            tablaresumen: {
                encabezados: [
                    "Producto",
                    "Unidad de medida",
                    "Cantidad",
                    "Precio",
                    "Disponibles",
                    "Completados"
                ],
                dimensiones: [
                    "30%",
                    "20%",
                    "10%",
                    "10%",
                    "15%",
                    "15%"
                ],
                mindimensiones: [
                    "200px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                ],
                maxdimensiones: [
                    "200px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px"
                ],
            },
            tipoalmacendestino: 0,
            almacenseleccionado: undefined,
            modalalmacenprincipal: false,
            listaalmacenes: [],
            listatipoequipos: [],
            listaequipos: [],
            item: [],
            productos: [],
            movimientos: [],
            masdeunprincipal: false,
            txtselected: 0,
            modalnuevotipo: false,
            productosdisponibles: 1,
            contenidoaguardar: [],

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,

            vermodalmoveragroquimicos: false,
            vermodalopcionesgenerales: false,

        }
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        }
        else if (resp.code == 200) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 300) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        return false
    }

    //////////////////////////////cargar informción propia del modal 
    async componentDidMount() {
        await this.verInformacionCompra();
        this.props.activarSpinner(false);
    }

    async verInformacionCompra() {
        var infomovimientoscompra = await getInformacionCompra({ idcompra: this.props.item.contenido.idcompra });

        if (this.manejadorLlamadasApis(infomovimientoscompra)) {
            this.props.activarSpinner(false);
            return;
        }

        var listamovimientos = [];
        infomovimientoscompra.data.movimientos.forEach(item => {

            listamovimientos.push({
                idalmacen: (item.idalmacen) ? (item.idalmacen) : (
                    item.idalmacen
                ),
                nombrealmacen: (item.nombrealmacen) ? (item.nombrealmacen) : ((item.nombre) ? (item.nombre) : (item.rancho)),
                productos: item.productos
            })

        });
        this.setState({
            item: infomovimientoscompra.data,
            productos: infomovimientoscompra.data.productos,
            movimientos: listamovimientos//infomovimientoscompra.data.movimientos
        });
    }


    //////////////////////////////cargar información del formulario para mover productos
    vermodalAsignarProducto = async () => { // obtiene la lista de almacenes y abre el modal formulario
        if (this.props.item.contenido.sonagroquimicos == 1) { // opción agroquímicos arirá el modal de agroquímicos
            this.props.activarSpinner(true);
           let hayalmacenes= await this.cargarAlmacenes();
            this.setState({
                titulo: "Mover agroquímicos a almacén",
                instruccion: "Asigna una cantidad a los agroquímicos que quieres mover:",
                encabezados: [
                    "Agroquímico",
                    "Unidad de medida",
                    "Disponibles",
                    "Cantidad",
                ],
                dimensiones: [
                    "30%",
                    "20%",
                    "20%",
                    "20%",
                ],
                mindimensiones: [
                    "200px",
                    "100px",
                    "100px",
                    "100px",
                ]
            });
            if(hayalmacenes !=0){
                 this.setState({ vermodalmoveragroquimicos: true })
            }else{
                this.props.activarSpinner(false);
                toast.info("No hay almacenes disponibles, revisa tu catálogo de almacenes de agroquímicos", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                  }

           
        } else { // opción generales  deberá abrir el modal para seleccionar el tipo de destino: generales, equipos, áreas, vehículos, ranchos      
            this.setState({ vermodalopcionesgenerales: true });
        }
    }

    vermodalAsignarProductoGenerales = async () => { // obtiene la lista de almacenes y abre el modal formulario
        // opción generales  deberá abrir el modal para seleccionar el tipo de destino: generales, equipos, áreas, vehículos, ranchos      
        this.props.activarSpinner(true);
        await this.cargarAlmacenes();
        this.setState(

            (this.state.tipoalmacendestino == 1 || this.state.tipoalmacendestino == 4) ? (
                //caso para almacenes de generales(1) y vehículos(4)
                {
                    titulo: (this.state.tipoalmacendestino == 1) ? ("Mover productos generales a almacén general") : ("Mover productos generales a maquinaria/vehículo"),
                    instruccion: "Asigna una cantidad a los productos que quieres mover:",
                    encabezados: [
                        "Producto",
                        "Unidad de medida",
                        "Disponibles",
                        "Cantidad",
                    ],
                    dimensiones: [
                        "30%",
                        "20%",
                        "20%",
                        "20%",
                    ],
                    mindimensiones: [
                        "200px",
                        "100px",
                        "100px",
                        "100px",
                    ]
                }
            ) : (
                (this.state.tipoalmacendestino == 2) ? (
                    // caso para almacenes de equipos
                    {
                        titulo: "Mover productos generales a almacén de equipos",
                        instruccion: "Asigna una cantidad a los productos que quieres mover:",
                        encabezados: [
                            "Producto",
                            "Unidad de medida",
                            "Disponibles",
                            "Cantidad",
                            "Tipo equipo",
                            "Equipo"
                        ],
                        dimensiones: [
                            "20%",
                            "20%",
                            "10%",
                            "10%",
                            "20%",
                            "20%",
                        ],
                        mindimensiones: [
                            "200px",
                            "100px",
                            "100px",
                            "100px",
                        ]
                    }
                ) : (this.state.tipoalmacendestino == 6) ? (
                    // caso para almacenes de equipos
                    {
                        titulo: "Mover productos generales a equipos",
                        instruccion: "Asigna una cantidad a los productos que quieres mover:",
                        encabezados: [
                            "Producto",
                            "Unidad de medida",
                            "Disponibles",
                            "Cantidad",
                            "Equipo",
                            "Marca",
                            "Modelo",

                        ],
                        dimensiones: [
                            "20%",
                            "20%",
                            "10%",
                            "10%",
                            "20%",
                            "20%",
                            "10%"
                        ],
                        mindimensiones: [
                            "200px",
                            "100px",
                            "100px",
                            "100px",
                            "100px",
                            "100px",
                            "100px",
                        ]
                    }
                ) : (

                    // caso para áreas(3) y ranchos(5)
                    {
                        titulo: (this.state.tipoalmacendestino == 3) ? ("Mover productos generales a área") : ("Mover productos generales a rancho"),
                        instruccion: "Asigna una cantidad a los productos que quieres mover:",
                        encabezados: [
                            "Producto",
                            "Unidad de medida",
                            "Disponibles",
                            "Cantidad",
                            "Activo fijo",
                        ],
                        dimensiones: [
                            "20%",
                            "20%",
                            "20%",
                            "20%",
                            "20%",
                        ],
                        mindimensiones: [
                            "200px",
                            "100px",
                            "100px",
                            "100px",
                        ]
                    }
                )
            )
        )

        setTimeout(() => {
            this.setState({ vermodalmoveragroquimicos: true })
        }, 200);

    }

    async cargarAlmacenes() {
        
        var listaalmacenesresp = (this.props.item.contenido.sonagroquimicos == 1) ? (await getCatalogoAlmacenes()) : (
            (this.state.tipoalmacendestino == 1) ? (await getListaAlmacenesProductosGenerales()) : (//lista de almacenes de productos generales
                (this.state.tipoalmacendestino == 2 || this.state.tipoalmacendestino == 6) ? (await getListaAlmacenesEquiposyHerramientas()) : ( //lista de almacenes de equipos y herramientas
                    (this.state.tipoalmacendestino == 3) ? (await getComboAreasDeTrabajo()) : ( // lista de áreas
                        (this.state.tipoalmacendestino == 4) ? (await getListaMaquinarias()) : (// lista de vehículos
                            await getListaRanchosDisponibles()//lista de ranchos
                        )
                    )
                )
            )
        );

        if (this.manejadorLlamadasApis(listaalmacenesresp)) {
            this.props.activarSpinner(false);
            return;
        }
        let nuevalista = await this.ordenarAlmacenesDestino(listaalmacenesresp.data);

        if (this.state.tipoalmacendestino == 2 || this.state.tipoalmacendestino == 6) {
            var listatipoequipos = await getListaTipoEquipos()
            if (this.manejadorLlamadasApis(listatipoequipos)) {
                this.props.activarSpinner(false);
                return;
            }
            var listaequipos = await getListaEquipos()
            if (this.manejadorLlamadasApis(listaequipos)) {
                this.props.activarSpinner(false);
                return;
            }
            
            this.setState({ listatipoequipos: listatipoequipos.data, listaequipos: listaequipos.data })

        }
      
       
        this.setState({ listaalmacenes: nuevalista });
        this.props.activarSpinner(false);

        return nuevalista.length
    }

    ordenarAlmacenesDestino = async (lista) => {
        
        var nuevalista = [];
        if (this.props.item.contenido.sonagroquimicos == 1) { // si son agroquímicos 
            lista.forEach(item => {
                if (item.idtipoalmacen == 1 && item.idestatus == 1)
                    nuevalista.push({
                        idalmacen: item.idcatalogoalmacen,
                        nombrealmacen: item.nombrealmacen
                    })
            });
        } else {// si son generales: equipos, áreas, vehículos, etc...            
            lista.forEach(item => {
                if (item.idestatus && parseInt(item.idestatus) == 1 || !item.idestatus) {
                    
                    nuevalista.push(
                        (this.state.tipoalmacendestino == 1) ? (
                            {
                                idalmacen: item.idalmacengenerales,
                                nombrealmacen: item.rancho
                            }
                        ) : (
                            (this.state.tipoalmacendestino == 2 || this.state.tipoalmacendestino == 6) ? (
                                {
                                    idalmacen: item.idalmacenequipos,
                                    nombrealmacen: item.rancho
                                }
                            ) : (
                                (this.state.tipoalmacendestino == 3) ? (
                                    {
                                        idalmacen: item.idcatalogo_areas,
                                        nombrealmacen: item.area
                                    }
                                ) : (
                                    (this.state.tipoalmacendestino == 4) ? (
                                        {
                                            idalmacen: item.idmaquinaria,
                                            nombrealmacen: item.nombreclave
                                        }
                                    ) : (
                                        {
                                            idalmacen: item.idcatalogo_rancho,
                                            nombrealmacen: item.rancho
                                        }
                                    )
                                )
                            )
                        )
                    )
                }

            });
        }
        
        return nuevalista;
    }

    guardarCambios = async () => { // problema - al guardr y aber un problem con un producto me devuelve 200 (correcto) y el mensaje de error llega como un string
          
        var requestbody = this.crearObjetoAGuardar(this.state.contenidoaguardar.objeto);

        this.props.activarSpinner(true);
        var respmoveraalmacen = (this.state.contenidoaguardar.sonagroquimicos == 1) ? (await moverProductosCompra(requestbody)) : (
            (this.state.tipoalmacendestino == 1) ? (
                await moverProductosGeneralesCompra(requestbody)
            ) : (
                (this.state.tipoalmacendestino == 2) ? (
                    await moverProductosGeneralesEquipos(requestbody)
                ) : (
                    (this.state.tipoalmacendestino == 6) ? (
                        await moverProductosGeneralesSoloEquipos(requestbody)
                    ) : (
                        await moverProductosGeneralesAreasRanchosVehiculos(requestbody)
                    )
                   
                )
            )
        );

        if (respmoveraalmacen == "pendiente") {
            toast.info("Acción pendiente de conectar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        if (this.manejadorLlamadasApis(respmoveraalmacen)) {            
            this.props.activarSpinner(false);
            return;
        }
        toast.success("Movimiento guardado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.setState({ vermodalmoveragroquimicos: false });
        this.verInformacionCompra()
        this.props.activarSpinner(false);
    }

    crearObjetoAGuardar = (objeto) => {
        var productos = [];
        objeto.productos.forEach(item => {

            (this.state.contenidoaguardar.sonagroquimicos != 1) ? (

                (this.state.tipoalmacendestino == 1 || this.state.tipoalmacendestino == 4) ? (
                    productos.push({ idproducto: item.idproducto, cantidad: item.cantidad })
                ) : (
                    (this.state.tipoalmacendestino == 2) ? (
                        productos.push({ idproducto: item.idproducto, cantidad: item.cantidad, idequipo: item.idequipo })
                    ) :
                    (this.state.tipoalmacendestino == 6) ?(
                        productos.push({ idproducto: item.idproducto, cantidad: item.cantidad, idequipo: item.idequipo })
                    ):
                     (
                        productos.push({ idproducto: item.idproducto, cantidad: item.cantidad, activo: (item.activo == true) ? (1) : (0) })
                    )
                )
            ) : (
                productos.push({ idproducto: item.idproducto, cantidad: item.cantidad })
            )

        });
        let x = (this.state.tipoalmacendestino == 3 || this.state.tipoalmacendestino == 4 || this.state.tipoalmacendestino == 5) ? (
            {
                tipomovimiento: (this.state.tipoalmacendestino == 3) ? (/*area*/1) : ((this.state.tipoalmacendestino == 5) ? (/*rancho*/2) : (/*vehiculo*/3)),
                idubicacion: objeto.idalmacen,
                idcompra: this.props.item.contenido.idcompra,
                idmovimiento: 7,
                productos: productos
            }
        ) : (
            {
                idalmacen: objeto.idalmacen,
                idcompra: this.props.item.contenido.idcompra,
                idmovimiento: 7,
                productos: productos
            }
        )

        return x;
    }



    //////////////////////// manejo de movimientos para productos generales    
    verAprobarMovimiento = (respuesta) => {
        this.setState({ vermodalconfirmarproductos: true, contenidoaguardar: respuesta })
    }



    cerrarmodal = async (opcion) => {

        if (opcion.origen == "modalconfirmarmoverproductos") {
            this.setState({ vermodalconfirmarproductos: false })
            if (opcion.respuesta == '10') {              
                await this.guardarCambios()
            }
            else if (opcion.respuesta == '0') {
                //console.log("cancelar confirmcion");
            }
        }
        else if (opcion.origen == "modalmoveragroquimicos") {

            if (opcion.respuesta == '10') {
                //console.log("guardar elemento ")
            }
            else if (opcion.respuesta == '0') {
                this.setState({ vermodalmoveragroquimicos: false })
            }
        }
        else if (opcion.origen == "modalseleccionarmovimientogeneral") {
            if (opcion.respuesta != '0') {
                this.setState({ tipoalmacendestino: opcion.respuesta, vermodalopcionesgenerales: false })
                setTimeout(async () => {
                    await this.vermodalAsignarProductoGenerales();
                }, 200);

            }
            else if (opcion.respuesta == '0') {
                this.setState({ vermodalopcionesgenerales: false })
            }
        }

    }


    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    render() {
        return (
            <>
                {(this.state.vermodalconfirmarproductos == true) ? (
                    <ModalConfirmarMoverProductos
                        show={this.state.vermodalconfirmarproductos}
                        tipoalmacendestino={this.state.tipoalmacendestino}
                        cerrarmodal={this.cerrarmodal}
                        contenido={this.state.contenidoaguardar}
                        almacen={this.state.contenidoaguardar.objeto.nombrealmacen}

                    />
                ) : null
                }
                {(this.state.vermodalmoveragroquimicos == true) ? (
                    <ModalMoverAgroquimicosCompra
                        listaalmacenes={this.state.listaalmacenes}
                        listatipoequipos={this.state.listatipoequipos}
                        listaequipos={this.state.listaequipos}
                        show={this.state.vermodalmoveragroquimicos}
                        titulo={this.state.titulo}
                        instruccion={this.state.instruccion}
                        encabezados={this.state.encabezados}
                        dimensiones={this.state.dimensiones}
                        mindimensiones={this.state.mindimensiones}
                        tipoalmacendestino={this.state.tipoalmacendestino}
                        sonagroquimicos={parseInt(this.props.item.contenido.sonagroquimicos)}
                        cerrarmodal={this.cerrarmodal}
                        productos={this.state.productos}
                        idcompra={this.state.item.idcompra}
                        verAprobarMovimiento={this.verAprobarMovimiento}
                       activarSpinner={ this.props.activarSpinner}
                    />
                ) : null
                }



                {(this.state.vermodalopcionesgenerales == true) ? (
                    <ModalFormularioMoverComprasGeneralesFiltros
                        show={this.state.vermodalopcionesgenerales}
                        cerrarmodal={this.cerrarmodal}
                    />
                ) : null
                }

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Mover compra a almacén </label>
                        <br /> <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/* ////////////////////////////// información de compra ///////////////////// */}
                                <div className='row'>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID compra
                                        </div>
                                        <div className='lblTexto letraTitulos'>    {this.state.item.idcompra}  </div>
                                    </div>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID requisición
                                        </div>
                                        <div className='lblTexto letraTitulos'> {this.state.item.idrequisicion}  </div>
                                    </div>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            Requisición
                                        </div>
                                        <div className='lblTexto letraTitulos'>  {this.state.item.requisicion}  </div>
                                    </div>
                                </div>
                            </div>

                            {/* ////////////////////   Inicio Sección Tabla  Resumen requisicion  ////////////////////////////////////// */}
                            <br />
                            <div className="container-fluid containerTabla ">
                                <div className="container-fluid contenedorResumenCompraAgroquimicos">
                                    <div className='containerSubTablaAA divMinWidth'>
                                        <tr >
                                            <th className="lblEncabezadosTabla letraTitulos"
                                                style={{ width: "750px", color: this.state.colorencabezadostabla }}>Resumen</th>

                                            {this.state.item.quedanproductos == 1 ? (
                                                <th className="lblEncabezadosTabla letraTitulos"
                                                    style={{ width: "20%", color: this.state.colorencabezadostabla }}>
                                                    <Button className="btnAzulCotizaciones" onClick={this.vermodalAsignarProducto.bind()}>Mover productos</Button>
                                                </th>
                                            ) : null}

                                        </tr>
                                        <Table>
                                            <thead style={{ verticalAlign: "bottom" }}>

                                                <tr>
                                                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                                                        index != (this.state.tablaresumen.encabezados.length - 1) ?
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos" colSpan={index == 4 ? 2 : 1}
                                                                style={{
                                                                    minWidth: this.state.tablaresumen.mindimensiones[index],
                                                                    maxWidth: this.state.tablaresumen.maxdimensiones[index],
                                                                    width: this.state.tablaresumen.dimensiones[index],
                                                                    color: this.state.colorencabezadostabla
                                                                }}>
                                                            </th>
                                                            : null
                                                    ))}
                                                </tr>
                                                <tr style={{ borderBottom: 'solid 2px #91b9f9' }}>
                                                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                            style={{
                                                                minWidth: this.state.tablaresumen.mindimensiones[index],
                                                                maxWidth: this.state.tablaresumen.maxdimensiones[index],
                                                                width: this.state.tablaresumen.dimensiones[index],
                                                                color: this.state.colorencabezadostabla,
                                                                textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                            }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                {/* ////////////////////   lista de productos resumen  ////////////////////////////////////// */}
                                                {



                                                    this.state.productos.map((item, index) => (
                                                        <tr
                                                            key={"tabla" + index} className={item.pendientes == 0 ? "sinPendientes" : ""}
                                                            style={{ borderBottom: (index == this.state.productos.length - 1) ? 'solid 2px #91b9f9' : 'solid 1px lightgray' }}>
                                                            <td className="letraNormal lblSubtabla"
                                                                style={{
                                                                    color: this.state.colorcontenidotabla,
                                                                    paddinfRight: '8px', paddingLeft: '8px',
                                                                }}>
                                                                {item.producto}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {item.unidadmedida}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {item.cantidad}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {item.precio}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {item.pendientes}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {item.pendientes == 0 ? <BsCheckLg className="icoBotones" /> : null}
                                                            </td>
                                                        </tr>
                                                    ))




                                                }
                                                <br></br>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            {/* ////////////////////   Fin Sección Tabla  Resumen requisicion ////////////////////////////////////// */}

                            <br></br>
                            <div> <b>Movimientos realizados</b></div>
                            {

                                (this.state.movimientos.length > 0) ? (this.state.movimientos.map((listamovimiento, index) => (
                                    <ModalFormularioMoverComprasAlmacenAgroquimicosTablaMovimiento contenido={listamovimiento} />
                                ))
                                ) : "Sin movimientos"

                            }

                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}

