import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { BiEdit } from "react-icons/bi";
import { RiRefreshLine, RiErrorWarningFill } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioAlmacenCombustible from '../../Complements/Modales/ModalFormularioAlmacenCombustible/ModalFormularioAlmacenCombustible';
import ModalFormularioNuevoMovimiento from '../../Complements/Modales/ModalFormularioAlmacenCombustible/ModalFormularioNuevoMovimiento'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getInformacionAlmacenCombustible, getCatalogoTipoMovimientoCombustible, getCatalogoMovimientoCombustible } from '../../Services/Api';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getListaCombustibles } from '../../Services/Api'
import { formatoNumero2 } from '../../Services/Validaciones';


export default class AlmacenCombustible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,

      tabla: {
        encabezados: [
          "Combustible",
          "Min. existencia",
          "Existencias",
          "Ingreso semanal sugerido",
          ""
        ],
        dimensiones: [
          "26%",
          "20%",
          "12%",
          "10%",
          "12%"
        ],
        mindimensiones: [
          "250px",
          "150px",
          "140px",
          "240px",
          "150px"
        ],
        informacion: {},
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,
      idcatalogoalmacenescombustible: 0,

      redireccionando: false,

      // Modal nuevo movimiento
      modalmovimiento: false,
      listatipomovimientos: [],
      listamovimientos: [],
      listacombustibles: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
      return;
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    // Se leen el id de localstorage
    var idcc = await localStorage.getItem('dato')
    if (idcc != null) {
      var respci = await getInformacionAlmacenCombustible({
        "idcatalogoalmacenescombustible": parseInt(idcc, 10)
      });
      if (this.manejadorLlamadasApis(respci)) {
        return;
      }

      if (respci.code == 200) {
        await toast.error(respci.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var tabla = this.state.tabla;
      tabla.informacion = respci.data ? respci.data : {};

      ///////////////// Se asignan datos a la Lista de Combustibles
      tabla.elementos = tabla.informacion.combustibles ? tabla.informacion.combustibles : [];
      var idcatalogoalmacenescombustible = tabla.informacion.idcatalogoalmacenescombustible;

      this.setState({ tabla: tabla, idcatalogoalmacenescombustible: idcatalogoalmacenescombustible });

      var estatus = await getEstatus();
      if (this.manejadorLlamadasApis(estatus)) {
        return;
      }

      var resplistacombustibles = await getListaCombustibles()
      if (this.manejadorLlamadasApis(resplistacombustibles)) {
        return
      }
      var listacombustibles = resplistacombustibles.data

      var resplistatipomovimientos = await getCatalogoTipoMovimientoCombustible()
      if (this.manejadorLlamadasApis(resplistatipomovimientos)) {
        return
      }
      var listatipomovimientos = resplistatipomovimientos.data

      var resplistamovimientos = await getCatalogoMovimientoCombustible()
      if (this.manejadorLlamadasApis(resplistamovimientos)) {
        return
      }
      var listamovimientos = resplistamovimientos.data

      this.setState({ estatus: estatus.data, listacombustibles: listacombustibles, listatipomovimientos: listatipomovimientos, listamovimientos: listamovimientos });

      this.dimensiones();
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    }
    else {
      this.redireccionando();
    }

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo combustible', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar combustible', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.componentDidMount();
    }
  }

  verModalMovimiento = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo movimiento', accionmodal: 1, informacion: JSON.parse(JSON.stringify(this.state.tabla)) })
    }
    this.setState({ modalmovimiento: true });
  }

  cerrarModalMovimiento = (accion) => {
    this.setState({ modalmovimiento: !this.state.modalmovimiento })
    if (accion == 1) {
      this.componentDidMount();
    }
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioAlmacenCombustible titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal}
            idcatalogoalmacenescombustible={this.state.idcatalogoalmacenescombustible} item={this.state.itemseleccionado}
            listacombustibles={this.state.listacombustibles} setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modalmovimiento == true) ? (
          <ModalFormularioNuevoMovimiento titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMovimiento}
            show={this.state.modalmovimiento} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listatipomovimientos={this.state.listatipomovimientos} listamovimientos={this.state.listamovimientos}
            informacion={JSON.parse(JSON.stringify(this.state.tabla.informacion))}
            setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-auto containerSubTabla paddingBottomEncabezados' style={{ minWidth: '400px' }}>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.tabla.informacion.rancho}</label>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalMovimiento.bind(this, { opcion: "1" })}>Nuevo movimiento</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                          textAlign: (index < 4 && index > 0) ? 'center' : ''
                        }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.tabla.elementos.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.tipo}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla,  }}>
                        {item.minimoexistencia}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, }}>
                        {!(!item.existencia || item.existencia < item.umbralalerta || item.existencia < item.minimoexistencia) ?
                        formatoNumero2(item.existencia)
                          : <>
                            {item.existencia}
                            <span className='lblSinDatos' style={{ marginRight: '-28px' }} >
                              <RiErrorWarningFill style={{ fontSize: 'x-large' }} />
                            </span>
                          </>}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, }}>
                        {item.ingresosemanal}
                      </td>
                     
                      <td className="letraNormal > lblSubtablaBotones" >
                        <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                          backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                        }}><BiEdit className="icoBotones" /> Editar</button>
                      </td>
                    </tr>
                  ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoalmacenescombustible"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
