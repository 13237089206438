import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalPropuestaAnual.css";
import { estiloColorError,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasConEspacios,validarLetrasNumerosLongitud, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaZonas } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalConfirmacion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            estilosmodal: estilosModal(),
        }
    }

    async componentDidMount() {
    }

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal(0)} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal text-center" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                                                      
                                    <label className=" letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >
                                        {this.props.mensaje}
                                    </label>
                                </div>

                                <div className='row justify-content-center'>
                                    <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >
                                        {this.props.mensajepreguntaconfirmacion}
                                    </label>
                                </div>
                               
                            </div>
                            
                        </div>
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal(1)} > {this.props.labelbotonconfirmacion}</Button>
                                </div>
                            </>
                      

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

