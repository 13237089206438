import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './ReporteOrdenTrabajo.css';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnNuevo, estiloBtnVer, estiloColorError,
  estiloComboBox, estiloFiltroEncabezados
} from '../../Services/PaletaDeColores';
//import Zona from './Zona';
//import Stock from './Stock'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { IoIosDocument, IoMdClose } from 'react-icons/io';
import { BsFillEyeFill } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaCalendar, FaTrashAlt } from 'react-icons/fa';

import { informacionReporteOrdenTrabajo, cambiarEstatusOrdenTrabajo } from '../../Services/Api';


import { validarNumeros, validarDescripciónOrdenTrabajo } from '../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getInformacionOrden, getTablaempleados, // Informacion principal
  asignacionTablaFuncion, getTablaMaquinaria, getTablaEquipo, getTablaProductos
} from './DatosDummy'

import ModalDocumentos from '../../Complements/Modales/ModalRequisicion/ModalDocumentos';
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatusContrasena';
import ModalFormularioCambiosEstatus from '../../Complements/Modales/ModalAprobarOrdenTrabajo/ModalFormularioCambioEstatus';


//import ModalBuscarProducto from '../../Complements/Modales/ModalOrdenTrabajo/ModalBuscarProducto';
import ModalAgregarProductos from '../../Complements/Modales/ModalOrdenTrabajo/ModalAgregarProductos'
import ModalSeleccionActividades from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionActividades'
import ModalSeleccionTablas from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionTablas'
import ModalSeleccionMaquinaria from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionMaquinaria'
import ModalSeleccionEquipo from '../../Complements/Modales/ModalOrdenTrabajo/ModalSeleccionEquipo'

import ModalSeleccionAnio from '../../Complements/Modales/ModalFormularioPlanAnual/ModalSeleccionAnio'
import ModalSeleccionMeses from '../../Complements/Modales/ModalOrdenTrabajo/MesesAnio/ModalSeleccionMeses'

import ModalListaNotas from '../../Complements/Modales/ModalFormularioReporteOrdenTrabajo/ModalFormularioNotas'

import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import ModalNotasEvidenciasOrdenTrabajo from '../../Complements/Modales/ModalNotasEvidenciasOrdenTrabajo/ModalNotasEvidenciasOrdenTrabajo';
registerLocale('es', es)

const todoslosfiltrosdisponibles = -1

export default class ReporteOrdenTrabajo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtnombrerequisicion: '',
      txttiporequesicion: '',
      txtfechaestimadacompra: '',
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      // variables para la sección 1
      plananual: false,
      idzonaseleccionada: todoslosfiltrosdisponibles,
      msgerroridzona: '',
      vermsgerroridzona: false,

      idranchoseleccionado: todoslosfiltrosdisponibles,
      msgerroridrancho: '',
      vermsgerroridrancho: false,

      anio: new Date(),
      //actividad: // revisar
      mes: new Date(),

      fecha: '',
      vermsgerrorfecha: false,
      msgerrorfecha: '',

      duracionhoras: '',
      vermsgerrorduracionhoras: false,
      msgerrorduracionhoras: '',

      numeroparticipantes: '',
      vermsgerrornumeroparticipantes: false,
      msgerrornumeroparticipantes: '',

      actividadoperativa: false,

      txtdescripcion: '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: '',


      // Datos para reporte orden trabajo
      informacionorden: {},
      modalnotas: false,
      listanotas: [
        {
          titulo: "Titulo de la nota 1",
          descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel pellentesque odio. Cras eu lectus luctus, mollis purus eget, venenatis ipsum. Nunc laoreet eget turpis in eleifend.",
          evidencias: '(3)',
          link: 'MOD'
        },
        {
          titulo: "Titulo de la nota 2",
          descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel pellentesque odio. Cras eu lectus luctus, mollis purus eget, venenatis ipsum. Nunc laoreet eget turpis in eleifend.",
          evidencias: '(3)',
          link: 'MOD'
        },
        {
          titulo: "Titulo de la nota 3",
          descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel pellentesque odio. Cras eu lectus luctus, mollis purus eget, venenatis ipsum. Nunc laoreet eget turpis in eleifend.",
          evidencias: '(10)',
          link: 'MOD'
        }
      ],


      // Sección listas de selectores
      listazonas: [],
      listaranchos: [],


      // Sección tablas de información en vista principal
      tablaasignartablas: {
        encabezados: [
          "Tabla",
          "Estatus de siembra",
          "Área total (ha)",
          "Plantas totales",
        ],
        dimensiones: [
          "27.5%",
          "27.5%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px",
          "200px"
        ],
        elementos: []
      },

      resumenproductos: {
        encabezados: [
          "Clave",
          "Producto general",
          "Unidad de medida",
          "Cantidad",
        ],
        dimensiones: [
          "25%",
          "25%",
          "25%",
          "25%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px",
          "200px"
        ],
        elementos: []
      },
      resumenagroquimicos: {
        encabezados: [
          "Clave",
          "Agroquímico",
          "Unidad de medida",
          "Cantidad",
        ],
        dimensiones: [
          "25%",
          "25%",
          "25%",
          "25%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px",
          "200px"
        ],
        elementos: []
      },

      resumencombustibles: {
        encabezados: [
          "Combustible",
          "Cantidad",
        ],
        dimensiones: [
          "50%",
          "50%",
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px",
          "200px"
        ],
        elementos: []
      },



      tablaempleados: {
        encabezados: [
          "Fecha",
          "Nombres",
          "Apellidos"
        ],
        dimensiones: [
          "20%",
          "40%",
          "40%"
        ],
        mindimensiones: [
          "250px",
          "250px",
          "200px"
        ],
        elementos: []
      },

      tablamaquinaria: {
        encabezados: [
          "Fecha",
          "Tipo maquinaria",
          "Maquinaria",
          "Usuario",
          "Horas",
          "Combustible(l)",
          "Tabla"
        ],
        dimensiones: [
          "14%",
          "17%",
          "15%",
          "17%",
          "10%",
          "15%",
          "17%"
        ],
        mindimensiones: [
          "150px",
          "170px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px"
        ],
        elementos: []
      },

      tablaequipos: {
        encabezados: [
          "Fecha",
          "Equipo",
          "Tipo equipo",
          "Usuario",
          "Combustible",
          "Tabla",
        ],
        dimensiones: [
          "15%",
          "25%",
          "15%",
          "15%",
          "15%",
          "15%",
        ],
        mindimensiones: [
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px"
        ],
        elementos: []
      },

      tablaproductos: {
        encabezados: [
          "Fecha",
          "Clave",
          "Nombre",
          "Unidad de medida",
          "Cantidad",
          "Tabla"
        ],
        dimensiones: [
          "15%",
          "15%",
          "15%",
          "15%",
          "15%",
          "15%",
        ],
        mindimensiones: [
          "150px",
          "150px",
          "150px",
          "170px",
          "150px",
          "150px",
        ],
        elementos: []
      },


      modalnotasyevidencias: false,




      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      contenidorequisicion: [],

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,


      // Mdal documentos
      modaldetallesdocumentos: false,

      // Modal de acciones rechazar/aprobar requisición
      modalaccionestatus: false,

      // Modal de solicitud de contraseñas para rechazar/aprobar requisición
      modalaccionestatuscontrasena: false,

      // Modales: 
      // Modal productos
      modalproductos: false,
      // Selección de actividad
      modalactividades: false,
      listaactividades: [],
      listatipoactividades: [],
      actividadseleccionada: '',
      // Selección de tablas
      modaltablas: false,
      listatablascultivo: [],
      listalotes: [],
      ranchoseleccionado: '',
      // Seccion de maquinaria
      modalmaquinaria: false,
      listamaquinaria: [],
      listatipomaquinaria: [],
      // Sección de equipos
      modalequipo: false,
      listaequipos: [],
      listatipoequipos: [],

      redireccionando: false,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,


      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnNuevo().borderbtnnuevo,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colormsgerror: estiloColorError().msgerror,

      modalseleccionanio: false,
      anioseleccionado: new Date().getFullYear(),
      modalmeses: false,
      messeleccionado: { id_mes: -1, mes: '----' },
      fechaprincipal: new Date(),
      informacion: null

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var datoorden = localStorage.getItem('datoorden')
    //var datoorden = localStorage.getItem('datoorden')
    if (datoorden != null) {
      const { orden, opcion } = JSON.parse(datoorden)

      // Sección para solicitar datos de las listas (selectores)
      //console.log("datoorden", datoorden);
      var resplistazonas = await informacionReporteOrdenTrabajo({ "idorden": orden });
      //console.log(resplistazonas);
      if (this.manejadorLlamadasApis(resplistazonas)) {
        return;
      }


      let info = resplistazonas.data;

      this.setState({
        informacion: resplistazonas.data,
      });
     
      

    } else {
      this.redireccionando();
    }


    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

    this.setState({ loadingspinners: false })
      var textoConSaltos = this.state.informacion.descripcion;
      var descripcionElement = document.getElementById("descripcionorden");
      var textoFormateado = textoConSaltos.replace(/\n/g, "<br>");
      descripcionElement.innerHTML = textoFormateado;



  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  verModalDetallesDocumentos = (ban) => {
    if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Documentos', accionmodal: 2, modaldetallesdocumentos: true });
    }
  }

  cerrarModalDocumentos = (opciones) => {
    this.setState({ modaldetallesdocumentos: !this.state.modaldetallesdocumentos })
    if (opciones.accion == 1) {
      //this.setState({ loadingspinners: true })
      //this.redireccionar({ contenido: opciones.id })
    }
  }

  verModalFormularioAccionEstatus = (ban) => {
    const aux = this.state.informacionrequisicion
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.idestatusrequisicion*/ }


    this.setState({ titulomodal: 'Finalizar orden de trabajo', accionmodal: this.state.informacion.idorden, modalaccionestatus: true, itemseleccionado: [] });

  }




  verModalFormularioAccionEstatusContrasena = (ban) => {
    this.setState({ titulomodal: 'Aprobar acción', modalaccionestatuscontrasena: true });
  }

  cerrarModalFormularioAccionEstatusContrasena = async (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.accion == 1) {

      this.setState({ loadingspinners: true });
      let eljson = {
        idorden: this.state.informacion.idorden,
        idestatusorden: 4,
        password: opciones.password

      };

      let resp = await cambiarEstatusOrdenTrabajo(eljson);
      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setState({ loadingspinners: false })
        return true
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true
      }
      else {
        toast.success("Orden finalizada exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });

        localStorage.setItem('datoorden', JSON.stringify({ "orden": this.state.informacion.idorden, "opcion": false }))
        this.componentDidMount();
      }
    }
    else {

    }
  }



  finalizarorden = async () => {

    if (this.state.informacion.fechafin != "") {

      this.verModalFormularioAccionEstatusContrasena();

    }
    else {
      toast.info("Fecha de finalización requerida desde app", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }


  }


  verModalNotas = () => {
    this.setState({ modalnotasyevidencias: true });
  }
  cerrarModalNotasyEvidencias = () => {
    this.setState({ modalnotasyevidencias: false });
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetallesdocumentos == true) ? (
          <ModalDocumentos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalDocumentos} estatus={this.state.estatus}
            show={this.state.modaldetallesdocumentos} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            /*listadocumentos={JSON.parse(JSON.stringify(this.state.listadocumentos))}*/ />
        ) : null}


        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioCambiosEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={1} />
        ) : null}

        {(this.state.modalproductos) ? (
          <ModalAgregarProductos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalProductos} show={this.state.modalproductos}
          /*activarSpinner={this.activarSpinner}*/ setloadingspinner={this.setLoadingSpinner}
            datosinventarioalmacen={this.state.datosinventarioalmacen} />
          /*<ModalBuscarProducto/>*/
        ) : null}

        {this.state.modalactividades ? (
          <ModalSeleccionActividades titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalActividades}
            listaactividades={this.state.listaactividades} listatipoactividades={this.state.listatipoactividades}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalactividades} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}

          />
        ) : null}

        {this.state.modaltablas ? (
          <ModalSeleccionTablas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalTablas}
            listalotes={this.state.listalotes} listatablascultivo={this.state.listatablascultivo}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modaltablas} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}

          />
        ) : null}

        {this.state.modalmaquinaria ? (
          <ModalSeleccionMaquinaria titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMaquinaria}
            listamaquinaria={this.state.listamaquinaria} listatipomaquinaria={this.state.listatipomaquinaria}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalmaquinaria} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
          />
        ) : null}

        {this.state.modalequipo ? (
          <ModalSeleccionEquipo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalEquipo}
            listaequipos={this.state.listaequipos} listatipoequipos={this.state.listatipoequipos}

            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalequipo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
          />
        ) : null}

        {this.state.modalseleccionanio ? (
          <ModalSeleccionAnio titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionAnio} show={this.state.modalseleccionanio}
            accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} anioseleccionado={this.state.anioseleccionado}
          />
        ) : null}

        {this.state.modalmeses ? (
          <ModalSeleccionMeses titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMeses} show={this.state.modalmeses}
            accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} messeleccionado={this.state.messeleccionado}
          />
        ) : null}

        {this.state.modalnotasyevidencias ? (
          <ModalNotasEvidenciasOrdenTrabajo
            cerrarModal={this.cerrarModalNotasyEvidencias} show={this.state.modalnotasyevidencias}
            listanotas={this.state.informacion.notasreporte}
          />
        ) : null}



        {(this.state.modalnotas == true) ? (
          <ModalListaNotas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarNotas} estatus={this.state.estatus}
            show={this.state.modalnotas} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listanotas={this.state.listanotas}
          //arraytipos={JSON.parse(JSON.stringify(this.state.arraytipos))} actualizarListaDocumentos={this.actualizarListaDocumentos}
          //listatiposdocumento={JSON.parse(JSON.stringify(this.state.listatiposdocumento))}
          //listadocumentos={JSON.parse(JSON.stringify(this.state.listadocumentos))}
          />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        {(this.state.informacion != null) &&
          <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <div className="container-fluid anchoMaximo">

              <div className='row paddingEncabezados align-items-end'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Reporte de orden de trabajo #{this.state.informacion.idorden}</label>
                </div>
                <div className="col-12 containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                  {this.state.informacion.plananual != "" ? (<label className='lblTexto letraTitulos'>Plan anual {this.state.informacion.plananual}, {this.state.informacion.mes} </label>) : null}
                  {this.state.informacion.actividadoperativa == 1 ? (<label className='lblTexto letraTitulos'>Actividad operativa</label>) : null}
                </div>
                <div className="col-12 containerSubTabla paddingBottomEncabezados checkBoxPlanOT">
                  <label className='lblTexto letraTitulos'>Estatus: {this.state.informacion.estatusreporte} </label>

                </div>

                <div className='col-12  containerSubTabla paddingBottomEncabezadoss' style={{ marginTop: "20px" }}>
                </div>



                {(this.state.informacion != null && parseInt(this.state.informacion.idestatusreporte) != 4) ? (
                  <>
                    <div className='col containerSubTabla'></div>

                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                      <button className='btnActualizar letraNormal'
                        style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                        onClick={this.finalizarorden}> Cerrar orden de trabajo
                      </button>
                    </div>
                  </>
                ) : null}


                <div className='col containerSubTabla'></div>

                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <button className='btnActualizar letraNormal'
                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                    onClick={this.verModalNotas}> Ver notas y evidencias
                  </button>
                </div>

                {this.state.informacion.periodo && this.state.informacion.mes ?
                  <div className='col-12 containerSubTabla paddingBottomEncabezadoss'>
                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>
                      {'Plan anual ' + this.state.informacion.periodo + ', ' + this.state.informacion.mes}
                    </label>
                  </div> : null}

              </div>
            </div>
          </div>
        }
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Datos e Información   ////////////////////////////////////// */}
        {(this.state.informacion != null) &&
          <div className="container-fluid" style={{ backgroundColor: '' }}>
            <div className="container-fluid anchoMaximo">
              <div className='row paddingEncabezadosMOD align-items-end contenedorEncabezadoMOD' style={{ paddingTop: '12px' }}>

                <div className='col-6 col-sm-6 col-md-6 col-lg-6 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Zona: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.zona}</label>
                </div>

                <div className='col-6 col-sm-6 col-md-6 col-lg-6 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Rancho: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.rancho}</label>
                </div>



                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.actividad}</label>
                </div>

                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha inicio: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.fechainicio}</label>
                </div>
                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha finalización: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.fechafin}</label>
                </div>

                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Duración estimada: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.horasreales}</label>
                </div>

                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Participantes sugeridos: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.empleadosreales}</label>
                </div>

                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                  <label className='lblTexto letraTitulos' >{this.state.informacion.lote}</label>
                </div>

                <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tablas usadas: </label>
                </div>

                {/*this.state.informacionorden.comentario ?
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-2 pr-2 seccionAviso text-center' style={{ marginTop: '8px' }}>
                  <label className='lblTexto letraTitulos text-center'>{this.state.informacionorden.comentario}</label>
                </div> : null*/}

              </div>
              <br />

            </div>
          </div>
        }
        {/* ////////////////////   Fin Sección Datos e Información   ////////////////////////////////////// */}








        {/* ////////////////////////////////////////   Inicio tabla Asignar Tablas   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
            <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #bebdbc', borderRadius: '5px', minWidth: '1005px', paddingLeft: '22px', paddingRight: '22px' }}>
              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaasignartablas.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaasignartablas.mindimensiones[index],
                            width: this.state.tablaasignartablas.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.informacion.tablas.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[0], width: this.state.tablaasignartablas.dimensiones[0] }}>
                          {item.tabla}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[1], width: this.state.tablaasignartablas.dimensiones[1] }}>
                          {item.estatussiembra}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[2], width: this.state.tablaasignartablas.dimensiones[2], textAlign: 'center' }}>
                          {item.areatotal}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaasignartablas.mindimensiones[3], width: this.state.tablaasignartablas.dimensiones[3], textAlign: 'center' }}>
                          {item.plantastotales}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        {/* ////////////////////////////////////////   Final tabla Asignar Tablas   //////////////////////////////////////////*/}



        {/* ////////////////////////////////////////   Inicio descripción   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <> <br />
            <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
              <div className="container-fluid anchoMaximo">
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Descripción: </label>
              </div>
              <div className="container-fluid anchoMaximo"
                style={{
                  border: 'solid 1px #bebdbc', borderRadius: '5px', minWidth: '1005px', paddingLeft: '22px', paddingRight: '22px',
                  paddingTop: '12px'
                }}>
                <div className="containerSubTabla">

                  <p id="descripcionorden">{this.state.informacion.descripcion}</p>

                </div>
              </div>
            </div>
          </>}
        {/* ////////////////////////////////////////   Final descripción   //////////////////////////////////////////*/}


        <br />
        <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Resumen de gastos: </label>
          </div>

        </div>




        {/* ////////////////////////////////////////   Inicio tabla resumen combustibles  //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
            <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #bebdbc', borderRadius: '5px', minWidth: '1005px', paddingLeft: '22px', paddingRight: '22px' }}>
              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                <div className="col-auto">
                  <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Productos generales</label>
                </div>
              </div>

              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.resumenproductos.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.resumenproductos.mindimensiones[index],
                            width: this.state.resumenproductos.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.informacion.resumenproductos.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenproductos.mindimensiones[0], width: this.state.resumenproductos.dimensiones[0] }}>
                          {item.clave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenproductos.mindimensiones[1], width: this.state.resumenproductos.dimensiones[1] }}>
                          {item.producto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenproductos.mindimensiones[2], width: this.state.resumenproductos.dimensiones[2], textAlign: 'center' }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenproductos.mindimensiones[3], width: this.state.resumenproductos.dimensiones[3], textAlign: 'center', }}>
                          {item.cantidad}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        <br />
        {/* ////////////////////////////////////////   Final tabla resumen combustibles   //////////////////////////////////////////*/}





        {/* ////////////////////////////////////////   Inicio tabla resumen productos generales  //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
            <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #bebdbc', borderRadius: '5px', minWidth: '1005px', paddingLeft: '22px', paddingRight: '22px' }}>

              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                <div className="col-auto">
                  <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Agroquímicos </label>
                </div>
              </div>

              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.resumenagroquimicos.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.resumenagroquimicos.mindimensiones[index],
                            width: this.state.resumenagroquimicos.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.informacion.resumenagroquimicos.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenagroquimicos.mindimensiones[0], width: this.state.resumenagroquimicos.dimensiones[0] }}>
                          {item.clave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenagroquimicos.mindimensiones[1], width: this.state.resumenagroquimicos.dimensiones[1] }}>
                          {item.producto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenagroquimicos.mindimensiones[2], width: this.state.resumenagroquimicos.dimensiones[2], textAlign: 'center' }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumenagroquimicos.mindimensiones[2], width: this.state.resumenagroquimicos.dimensiones[2], textAlign: 'center' }}>
                          {item.cantidad}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        <br />
        {/* ////////////////////////////////////////   Final tabla resumen productos generales   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla resumen agroquímicos  //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
            <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #bebdbc', borderRadius: '5px', minWidth: '1005px', paddingLeft: '22px', paddingRight: '22px' }}>

              <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                <div className="col-auto">
                  <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Combustibles</label>
                </div>
              </div>

              <div className="containerSubTabla">
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.resumencombustibles.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.resumencombustibles.mindimensiones[index],
                            width: this.state.resumencombustibles.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index >= 2) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.informacion.combustiblesreales.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumencombustibles.mindimensiones[0], width: this.state.resumencombustibles.dimensiones[0] }}>
                          {item.combustible}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.resumencombustibles.mindimensiones[1], width: this.state.resumencombustibles.dimensiones[1] }}>
                          {item.cantidad}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>}
        {/* ////////////////////////////////////////   Final tabla agroquímicos   //////////////////////////////////////////*/}




        <br />
        <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
          <div className="container-fluid anchoMaximo">
            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, padding: '10px 0px 10px' }}>Más información: </label>
          </div>

        </div>







        {/* ////////////////////////////////////////   Inicio tabla empleados asignados   //////////////////////////////////////////*/}
        {/* Descripción */}
        {(this.state.informacion != null) &&
          <> <br />
            <div className="container-fluid containerTabla" style={{ minWidtah: '970px' }}>
              <div className="container-fluid anchoMaximo" style={{ borderRadius: '5px', minWidth: '1005px', backgroundColor: '#f7f5f2', paddingBottom: '12px' }}>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                  <div className="col-auto">
                    <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Empleados asignados</label>
                  </div>
                </div>

                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaempleados.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaempleados.mindimensiones[index],
                              width: this.state.tablaempleados.dimensiones[index],
                              color: this.state.colorencabezadostabla,
                              // textAlign: (index >= 2) ? 'center' : ''
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informacion.empleados.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaempleados.mindimensiones[0], width: this.state.tablaempleados.dimensiones[0] }}>
                            {item.fecha}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaempleados.mindimensiones[1], width: this.state.tablaempleados.dimensiones[1] }}>
                            {item.nombres}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaempleados.mindimensiones[2], width: this.state.tablaempleados.dimensiones[2] }}>
                            {item.apellidos}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>}
        {/* ////////////////////////////////////////   Final tabla Empleados asignados   //////////////////////////////////////////*/}




        {/* ////////////////////////////////////////   Inicio tabla Selección Maquinaria   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <>
            <br />
            <div className="container-fluid containerTabla">
              <div className="container-fluid anchoMaximo" style={{ borderRadius: '5px', minWidth: '1005px' }}>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                  <div className="col-auto">
                    <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Maquinaria</label>
                  </div>
                </div>

                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablamaquinaria.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablamaquinaria.mindimensiones[index],
                              width: this.state.tablamaquinaria.dimensiones[index],
                              color: this.state.colorencabezadostabla,
                              textAlign: (index == 4 || index == 5) ? 'center' : ''
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informacion.maquinaria.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[0], width: this.state.tablamaquinaria.dimensiones[0] }}>
                            {item.fecha}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[1], width: this.state.tablamaquinaria.dimensiones[1] }}>
                            {item.tipomaquinaria}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[2], width: this.state.tablamaquinaria.dimensiones[2] }}>
                            {item.maquinaria}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[3], width: this.state.tablamaquinaria.dimensiones[3] }}>
                            {item.usuario}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[4], width: this.state.tablamaquinaria.dimensiones[4], textAlign: 'center' }}>
                            {item.horas}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[5], width: this.state.tablamaquinaria.dimensiones[5], textAlign: 'center' }}>
                            {item.cantidadcombustible}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablamaquinaria.mindimensiones[6], width: this.state.tablamaquinaria.dimensiones[6] }}>
                            {item.tabla}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>}
        {/* ////////////////////////////////////////   Final tabla Selección Maquinaria   //////////////////////////////////////////*/}




        {/* ////////////////////////////////////////   Inicio tabla Selección Equipos   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <>
            <br />
            <div className="container-fluid containerTabla">
              <div className="container-fluid anchoMaximo" style={{ borderRadius: '5px', minWidth: '1005px', backgroundColor: '#f7f5f2', paddingBottom: '12px' }}>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                  <div className="col-auto">
                    <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Equipo</label>
                  </div>
                </div>

                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaequipos.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaequipos.mindimensiones[index],
                              width: this.state.tablaequipos.dimensiones[index],
                              color: this.state.colorencabezadostabla,
                              //textAlign: (index >= 2) ? 'center' : ''
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informacion.equipos.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[0], width: this.state.tablaequipos.dimensiones[0] }}>
                            {item.fecha}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[1], width: this.state.tablaequipos.dimensiones[1] }}>
                            {item.tipoequipo}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[2], width: this.state.tablaequipos.dimensiones[2] }}>
                            {item.equipo}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[3], width: this.state.tablaequipos.dimensiones[3] }}>
                            {item.usuario}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[4], width: this.state.tablaequipos.dimensiones[4] }}>
                            {item.cantidadcombustible}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaequipos.mindimensiones[5], width: this.state.tablaequipos.dimensiones[5] }}>
                            {item.tabla}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>}
        {/* ////////////////////////////////////////   Final tabla Selección Equipos   //////////////////////////////////////////*/}









        {/* ////////////////////////////////////////   Inicio tabla Selección Productos   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <>
            <br />
            <div className="container-fluid containerTabla">
              <div className="container-fluid anchoMaximo" style={{ borderRadius: '5px', minWidth: '1005px' }}>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                  <div className="col-auto">
                    <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Productos</label>
                  </div>
                </div>

                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaproductos.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaproductos.mindimensiones[index],
                              width: this.state.tablaproductos.dimensiones[index],
                              color: this.state.colorencabezadostabla,
                              textAlign: (index == 3 || index == 4) ? 'center' : ''
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informacion.productos.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[0], width: this.state.tablaproductos.dimensiones[0] }}>
                            {item.fecha}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[1], width: this.state.tablaproductos.dimensiones[1] }}>
                            {item.clave}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[2], width: this.state.tablaproductos.dimensiones[2] }}>
                            {item.producto}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[3], width: this.state.tablaproductos.dimensiones[3], textAlign: 'center' }}>
                            {item.unidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[4], width: this.state.tablaproductos.dimensiones[4], textAlign: 'center' }}>
                            {item.cantidad}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[5], width: this.state.tablaproductos.dimensiones[5] }}>
                            {item.tabla}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>}
        {/* ////////////////////////////////////////   Final tabla Selección Productos   //////////////////////////////////////////*/}







        {/* ////////////////////////////////////////   Inicio tabla Selección Agroquímicos   //////////////////////////////////////////*/}
        {(this.state.informacion != null) &&
          <>
            <br />
            <div className="container-fluid containerTabla">
              <div className="container-fluid anchoMaximo" style={{ borderRadius: '5px', minWidth: '1005px' }}>
                <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px 8px 8px 0px" }}>
                  <div className="col-auto">
                    <label className='lblTexto letraTitulos' style={{ fontSize: 'large', fontWeight: 'bold' }} >Agroquímicos</label>
                  </div>
                </div>

                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaproductos.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaproductos.mindimensiones[index],
                              width: this.state.tablaproductos.dimensiones[index],
                              color: this.state.colorencabezadostabla,
                              textAlign: (index == 3 || index == 4) ? 'center' : ''
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informacion.agroquimicos.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[0], width: this.state.tablaproductos.dimensiones[0] }}>
                            {item.fecha}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[1], width: this.state.tablaproductos.dimensiones[1] }}>
                            {item.clave}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[2], width: this.state.tablaproductos.dimensiones[2] }}>
                            {item.producto}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[3], width: this.state.tablaproductos.dimensiones[3], textAlign: 'center' }}>
                            {item.unidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[4], width: this.state.tablaproductos.dimensiones[4], textAlign: 'center' }}>
                            {item.cantidad}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaproductos.mindimensiones[5], width: this.state.tablaproductos.dimensiones[5] }}>
                            {item.tabla}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>}
        <br />
        <br />
        {/* ////////////////////////////////////////   Final tabla Selección de agroquímicos   //////////////////////////////////////////*/}

      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    {value} <FaCalendar className="icoBotones" />
  </button>
));

const DateMonthButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '120px', cursor: 'pointer' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordentrabajo"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
