import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
//import { BsQuestionSquare } from 'react-icons/bs';
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
//import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioBuscarUsuarios extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      txtbuscador: "",
      tabla: {
        encabezados: [
          "Nombre(s)",
          "Apellidos",
          "Puesto"
        ],
        dimensiones: [
          "5%",
          "15%",
          "15%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px"
        ],
        elementos: [],
      },

      usuariosseleccionados: {},
      idestatus: 1,
      msgerror: '',

      // Usuarios
      listapuestos: [],
      idpuestoseleccionada: todoslosfiltrosdisponibles,
      listausuariosfiltrados: [],


      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    var tabla = this.state.tabla;
    tabla.elementos = [
      {
         "idcatalogousuarios":2,
         "nombres":"nombres",
         "apellidos":"apellidos",
         "area":"nombre área de trabajo",
         "idcatalogo_areas":"11",
         "puesto":"Puesto de trabajo",
         "idcatalogo_puestos":"23"
      },{
        "idcatalogousuarios":3,
        "nombres":"nombres2",
        "apellidos":"apellidos",
        "area":"nombre área de trabajo",
        "idcatalogo_areas":"11",
        "puesto":"Puesto de trabajo",
        "idcatalogo_puestos":"23"
      },{
        "idcatalogousuarios":4,
        "nombres":"nombres3",
        "apellidos":"apellidos",
        "area":"nombre área de trabajo",
        "idcatalogo_areas":"11",
        "puesto":"Puesto de trabajo",
        "idcatalogo_puestos":"23"
      }
   ]
   //this.props.listausuarios

    this.setState({
      txtbuscador: '',
      tabla: tabla,
      listapuestos: this.props.listapuestos,
      listausuariosfiltrados: tabla.elementos
    })
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {

    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    // Mandar datos del item de usuario seleccionado
    this.showModal(this.state.usuariosseleccionados)
  }

  filtrarComboPuesto = async (idpuesto) => {

    await this.setState({ idpuestoseleccionada: idpuesto })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idpuesto, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.idpuestoseleccionada, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }
  }

  filtrarBusqueda = (idpuesto, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idpuesto != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idcatalogo_puestos == idpuesto)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter(
      (item) => item.nombres.toLocaleLowerCase().includes(textobusqueda))

  }

  filtro = (pagenumber, array) => {

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listausuariosfiltrados: array
      });
    }, 0);

  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      listausuariosfiltrados: this.state.tabla.elementos
    });
  }

  seleccionarUsuario = async (item) => {
    var usuariosseleccionados = this.state.usuariosseleccionados
    var usuarioseleccionado = usuariosseleccionados[item.idcatalogousuarios]
    if (usuarioseleccionado === undefined) {
      usuariosseleccionados[item.idcatalogousuarios] = item
      this.setState({ usuariosseleccionados: usuariosseleccionados, confirmarenvio: true })
    } else {
      delete usuariosseleccionados[item.idcatalogousuarios]
      var confirmarenvio = Object.keys(usuariosseleccionados).length != 0
      this.setState({ usuariosseleccionados: usuariosseleccionados, confirmarenvio: confirmarenvio })
    }
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Buscar usuarios</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Puesto</label>
                      <div className="selectBox ">
                      <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarComboPuesto(event.target.value)}>
                          <option selected={todoslosfiltrosdisponibles == this.state.idpuestoseleccionada} value={todoslosfiltrosdisponibles}>Puesto</option>
                          {this.state.listapuestos.map((i, index) => (
                            <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='txtBuscadorCN' >
                      <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                      <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                        placeholder="Buscar nombre"></input>
                      {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                        onClick={this.borrarBusqueda} />) : null}
                    </div>
                  </div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {
                            this.state.listausuariosfiltrados.map((item, index) => (
                              <tr key={"tabla" + index}
                                onClick={() => this.seleccionarUsuario(item)}
                                className={this.state.usuariosseleccionados[item.idcatalogousuarios] != undefined ? "trFondoActivoGreen" : "" } >
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.nombres}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.apellidos}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.puesto}
                                </td>
                                {/*<td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.descripcion}
                                </td>*/}

                              </tr>
                            ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

              {/*this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null*/}
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

