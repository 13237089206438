import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import './Calendario.css';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { BsFillCalendarFill } from "react-icons/bs";
import { IoMdClose } from 'react-icons/io';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';

export default class Calendario extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = {
            selectedDay: undefined,
            calendarioactivo: false,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorfondotipo: estiloComboBox().colorfondocombo,
            colorbordertipo: estiloComboBox().colorbordercombo,
            coloriconocalendario: coloresTxtBuscador().colorlupa,
            colortipolbl: estiloComboBox().colorcombolbl,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
        };

    }

    async componentDidMount() {
       
    }

    handleDayChange(day) {
        this.setState({ selectedDay: day });
    }

    mostrarOcultarCalendario = () => {
       this.setState({ calendarioactivo: !this.state.calendarioactivo});
    }

    limpiarFiltroFecha = () => {
        this.setState({ selectedDay: undefined });
    }

    render() {
        return (
            <div>
                <div className='principalCalendario' 
                    style={{ color: this.state.colorcbxtipolbl,position:'relative', backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}
                    
                >
                    <div className='principalCalendario'  onClick={this.mostrarOcultarCalendario}>
                        <div className='subcontenedorIconoCalendario'>
                            <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.coloriconocalendario, stroke: this.state.coloriconocalendario }}/>
                            
                        </div>
                        <div className="subcontenedorEtiquetaFecha ">
                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Fecha:</label>
                        </div>
                        <div className="subcontenedorFecha">
                                {this.state.selectedDay ? (
                                    <p>{this.state.selectedDay.toLocaleDateString()}</p>
                                ) : (
                                    <p></p>
                                )}
                        </div>
                        <div className="subcontenedorBorrarFecha">
                            {this.state.selectedDay ? 
                                (<IoMdClose className='icoInputBuscarClose' 
                                            style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                            onClick={this.limpiarFiltroFecha}
                                />) : null}
                        </div>
                    </div>
                      
                      {
                        (this.state.calendarioactivo) ? 
                        (<div className="contenedorCalendario rounded" id="calendario" style={{  border: this.state.colorbordertipo }}>
                            <IoMdClose className='icoInputCerrarCalendario' 
                                            style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                            onClick={this.mostrarOcultarCalendario}
                                />
                          {<DayPicker
                                mode="single"
                                locale={es} 
                                onDayClick={this.handleDayChange}
                                selected={this.state.selectedDay }
                            />}
                        </div>)
                        :null
                      }
                      
                  </div>
                
                

            </div>
           
        );
    }
    
}
