import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalBuscarProveedorCotizacion.css";
import { estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalAgregarActividadCotizacion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            listaactividades: this.props.listaactividades,
            actividadseleccionada: undefined,
            idactividadseleccionada: 0,
            idsactividadseleccionada: [],
            actividadesseleccionadas: [],

            estilosmodal: estilosModal(),
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }



    guardarCambios = async () => {
        // Mandar datos del item de actividad seleccionada
        var pseleccionados = [];
        this.state.idsactividadseleccionada.forEach((i) => {
            this.state.listaactividades.map((p) => {
                if (p.idcatalogo_requisicion_actividad == i && p.sustituir != 1) {
                    pseleccionados.push(p)
                }
            })
        })
        this.showModal(pseleccionados)

    }

    seleccionarActividad = async (item) => {
        if (this.state.idsactividadseleccionada.indexOf(item.idcatalogo_requisicion_actividad) != -1) {
            this.state.idsactividadseleccionada.splice(this.state.idsactividadseleccionada.indexOf(item.idcatalogo_requisicion_actividad), 1);
        } else {
            this.state.idsactividadseleccionada.push(item.idcatalogo_requisicion_actividad)
            await this.setState({ confirmarenvio: true })
        }

        await this.setState({})
    }

    procesarActividades = () => {
        var todosActividades = this.props.listaactividades;

        let filtro=todosActividades.filter(x=> x.asignada==false)
        return filtro;
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Agregar servicios</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                    <label className='lblTexto letraTitulos'>Selecciona una actividad: </label>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaProd">
                                            <Table hover>
                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.procesarActividades().length > 0 ? this.procesarActividades().map((item, index) => (

                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarActividad(item)} className={this.state.idsactividadseleccionada.indexOf(item.idcatalogo_requisicion_actividad) != -1 ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, width:"25%" }}>
                                                                    {item.actividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, width:"20%" }}>
                                                                    {item.tipoactividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, width:"30%" }}>
                                                                    {item.descripcion}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, width:"25%" }}>
                                                                    {item.destino}
                                                                </td>
                                                            </tr>
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >AGREGAR</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

