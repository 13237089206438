import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs'
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimalesAlmacenCombustible, validarLetrasNumerosLongitudV2 } from '../../../Services/Validaciones';
import { updateAlmacenCombustible } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import 'react-toastify/dist/ReactToastify.css';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioAlmacenCombustible extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      id: (props.accion == 2) ? (props.item.idcombustible_catalogoalmacenescombustible) : 0,
      idcatalogoalmacencombustible: props.idcatalogoalmacenescombustible,

      listacombustibles: props.listacombustibles,

      idtipocombustible: (props.accion == 2) ? (props.item.idcombustible) : todoslosfiltrosdisponibles,
      vermsgerroridtipocombustible: false,
      msgerroridtipocombustible: '',

      txtunidadmedida: (props.accion == 2) ? ('litros') : 'Seleccione combustible',
      
      txtminimo: (props.accion == 2) ? (String(props.item.minimoexistencia)) : '',
      vermsgerrortxtminimo: false,
      msgerrortxtminimo: "",

      txtmaximo: (props.accion == 2) ? (String(props.item.maximorecomendado)) : '',
      vermsgerrortxtmaximo: false,
      msgerrortxtmaximo: "",

      txtumbralalerta: (props.accion == 2) ? (String(props.item.umbralalerta)) : '',
      vermsgerrortxtumbralalerta: false,
      msgerrortxtumbralalerta: "",

      txtingresosemanal: (props.accion == 2) ? (String(props.item.ingresosemanal)) : '',
      vermsgerrortxtingresosemanal: false,
      msgerrortxtingresosemanal: "",

      arrayestatus: props.estatus,
      idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo
    }

  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones.accion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
    if (item.tipo == 'idtipocombustible') {
      if (item.valor == todoslosfiltrosdisponibles) {
        this.setState({ txtunidadmedida: 'Seleccione combustible' })
      } else {
        this.setState({ txtunidadmedida: 'litro' })
      }
    }
  }

  guardarCambios = async () => {

    this.setState({ vermsgerroridtipocombustible: false })
    this.setState({ vermsgerrortxtminimo: false })
    this.setState({ vermsgerrortxtmaximo: false })
    this.setState({ vermsgerrortxtumbralalerta: false })
    this.setState({ vermsgerrortxtingresosemanal: false })
    let validacionescorrectas = true

    if (this.state.idtipocombustible == todoslosfiltrosdisponibles) {
      this.setState({ msgerroridtipocombustible: "Selecciona un combustible" })
      this.setState({ vermsgerroridtipocombustible: true })
      validacionescorrectas = false;
    }

    let longitudvalida = { longitudminimaentera: 0, longitudmaximaentera: 7, longitudminimadecimal: 1, longitudmaximadecimal: 2 }
    var txtminimo = ''
    if (this.state.txtminimo.trim().length > 0) {
      txtminimo = validarNumerosDecimalesAlmacenCombustible(this.state.txtminimo.trim(), longitudvalida)
      if (txtminimo.codigo == "invalid") {
        this.setState({ msgerrortxtminimo: txtminimo.mensaje })
        this.setState({ vermsgerrortxtminimo: true })
        validacionescorrectas = false;
      }
    }

    var txtmaximo = ''
    if (this.state.txtmaximo.trim().length > 0) {
      txtmaximo = validarNumerosDecimalesAlmacenCombustible(this.state.txtmaximo.trim(), longitudvalida)
      if (txtmaximo.codigo == "invalid") {
        this.setState({ msgerrortxtmaximo: txtmaximo.mensaje })
        this.setState({ vermsgerrortxtmaximo: true })
        validacionescorrectas = false;
      }
    }

    var txtumbralalerta = ''
    if (this.state.txtumbralalerta.trim().length > 0) {
      txtumbralalerta = validarNumerosDecimalesAlmacenCombustible(this.state.txtumbralalerta.trim(), longitudvalida)
      if (txtumbralalerta.codigo == "invalid") {
        this.setState({ msgerrortxtumbralalerta: txtumbralalerta.mensaje })
        this.setState({ vermsgerrortxtumbralalerta: true })
        validacionescorrectas = false;
      }
    }
    if (txtminimo.codigo == "valid" && txtmaximo.codigo == "valid") {
      var minimo = Number(this.state.txtminimo.trim())
      var maximo = Number(this.state.txtmaximo.trim())
      var umbral = Number(this.state.txtumbralalerta.trim())
      if (minimo > maximo) {
        this.setState({ msgerrortxtminimo: 'Este valor no puede ser superior al máximo de existencias' })
        this.setState({ vermsgerrortxtminimo: true })
        this.setState({ msgerrortxtmaximo: 'Este valor no puede ser inferior al mínimo de existencias' })
        this.setState({ vermsgerrortxtmaximo: true })
        validacionescorrectas = false;
      } else if (txtumbralalerta.codigo == 'valid' && (umbral < minimo || umbral > maximo)) {
        this.setState({ msgerrortxtumbralalerta: 'El umbral de alerta debe ser intermedio entre número inferior y máximo sugerido' })
        this.setState({ vermsgerrortxtumbralalerta: true })
        validacionescorrectas = false;
      }
    }

    if (this.state.txtingresosemanal.trim().length > 0) {
      let txtingresosemanal = validarNumerosDecimalesAlmacenCombustible(this.state.txtingresosemanal.trim(), longitudvalida)
      if (txtingresosemanal.codigo == "invalid") {
        this.setState({ msgerrortxtingresosemanal: txtingresosemanal.mensaje })
        this.setState({ vermsgerrortxtingresosemanal: true })
        validacionescorrectas = false;
      }
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json = {
        "idcombustible_catalogoalmacenescombustible": this.state.id,
        "idcombustible": this.state.idtipocombustible,
        "minimoexistencia": this.state.txtminimo.trim(),
        "maximorecomendado": this.state.txtmaximo.trim(),
        "idcatalogo_almacen_combustible": this.state.idcatalogoalmacencombustible,
        "umbralalerta": this.state.txtumbralalerta.trim(),
        "idestatus": this.state.idestatus,
        "ingresosemanal": this.state.txtingresosemanal.trim()
      }
      var resp = await updateAlmacenCombustible(json);

      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return true
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true
      }
      else {
        toast.success("Combustible guardado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal({ accion: '1' })
      }
    }

  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Combustible</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo({ tipo: "idtipocombustible", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipocombustible} >Seleccionar </option>
                          {this.state.listacombustibles.map((i, index) => (
                            <option value={i.idtipocombustible} key={"tipo_" + index} selected={i.idtipocombustible == this.state.idtipocombustible} >{i.tipo} </option>
                          ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                {this.state.vermsgerroridtipocombustible ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipocombustible}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Unidad de medida</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none' }}>
                    {this.state.txtunidadmedida}
                  </label>
                ) : <label className='lblTextoModal letraNormal' style={{
                  color: this.state.estilosmodal.colorlbltextomodal,
                  borderBottom: this.state.estilosmodal.colorborderbottom
                }}>{this.state.txtunidadmedida}</label>}

                {/*this.state.vermsgerrortxtunidadmedida ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtunidadmedida}</p> : null*/}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Mínimo existencia</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtminimo} onChange={this.escribir("txtminimo")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtminimo}</label>}

                {this.state.vermsgerrortxtminimo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtminimo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Máximo recomendado</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtmaximo} onChange={this.escribir("txtmaximo")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmaximo}</label>}

                {this.state.vermsgerrortxtmaximo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmaximo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Umbral alerta</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtumbralalerta} onChange={this.escribir("txtumbralalerta")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtumbralalerta}</label>}

                {this.state.vermsgerrortxtumbralalerta ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtumbralalerta}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingreso semanal sugerido</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtingresosemanal} onChange={this.escribir("txtingresosemanal")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtingresosemanal}</label>}

                {this.state.vermsgerrortxtingresosemanal ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtingresosemanal}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                }
              </div>
              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

