import React from 'react'
import { Button, Collapse, Modal, ModalBody, Table } from 'reactstrap';
import { CgClose } from 'react-icons/cg';

import "./../../ModalMovimiento.css";
import SpinnerModal from '../../../SpinnerModal/SpinnerModal';
import { estilosModal } from '../../../../../Services/PaletaDeColores';





export default class ModalRequisicion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            loadingspinners:false,
            accion: 1, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            estilosmodal: estilosModal()



        }

    }
    async componentDidMount() {


    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarmodal(accion);
        }, 200);

    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>

                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <p className='lblSubTitulosModal letraNormal'>Seleccione una requisición</p>
                        <br />
                        <div className="divCuerpoModal">



                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, minHeight:'400px' }} >

                                <p className='lblSubTitulosModal letraNormal'><b>No hay requisiciones disponibles</b></p>

                            </div>
                            <br />

                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}>Agregar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

