import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'

import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsFillEyeFill } from 'react-icons/bs';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion,
  estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getListaZonas, getListaRanchosDisponibles, getCatalogoProductosActivos } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

const todoslosfiltrosdisponibles = -1

export default class CatalogoAlmacenProductosActivos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idzonaseleccionada: todoslosfiltrosdisponibles,

      //arrayzonas: [],
      listazonas: [],
      listaranchos: [],

      tabla: {
        encabezados: [


          "Ubicación",
          "Tipo ubicación",
          ""
        ],
        dimensiones: [

          "50%",
          "30%",
          "20%"
        ],
        mindimensiones: [
          "50%",
          "30%",
          "20%"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (this.state.loadingspinners == false) {
      this.setState({ loadingspinners: true })
    }
    this.setState({ txtbuscador: '' })

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var respcatproductosactivos = await getCatalogoProductosActivos()
    if (this.manejadorLlamadasApisComodin(respcatproductosactivos)) {
      return;
    }
    var tabla = this.state.tabla;
    tabla.elementos = JSON.parse(JSON.stringify(respcatproductosactivos.data));

    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApisComodin(resplistazonas)) {
      return;
    }
    var listazonas = JSON.parse(JSON.stringify(resplistazonas.data));

    var resplistaranchos = await getListaRanchosDisponibles()
    if (this.manejadorLlamadasApisComodin(resplistaranchos)) {
      return;
    }
    var listaranchos = JSON.parse(JSON.stringify(resplistaranchos.data));

    this.setState({ tabla: tabla, listazonas: listazonas, listaranchos: listaranchos })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApisComodin = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }


  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ idzonaseleccionada: todoslosfiltrosdisponibles })
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarCombo = async (idzona) => {

    await this.setState({ idzonaseleccionada: idzona })

    var filtroarearancho = this.filtrarBusqueda(
      idzona, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroarearancho.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroarearancho)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroarearancho = this.filtrarBusqueda(
        this.state.idzonaseleccionada, cadenain.toLocaleLowerCase())

      if (filtroarearancho.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroarearancho)
    }

  }

  filtrarBusqueda = (idzona, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idzona != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idzona == idzona)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    
return  filtrozonasdisponibles.filter((item) => item.rancho.toLocaleLowerCase().includes(textobusqueda) || item.area.toLocaleLowerCase().includes(textobusqueda))


  //  return filtrozonasdisponibles.filter( (item) => item.rancho.toLocaleLowerCase().includes(textobusqueda))

  }



  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }

  

  redireccioando = (itemalmacen) => {
    var idubicacion = (itemalmacen.contenido.tipoubicacion == 1) ? (itemalmacen.contenido.idarea) : (itemalmacen.contenido.idrancho);

    localStorage.setItem("datos", JSON.stringify({ tipoubicacion: itemalmacen.contenido.tipoubicacion, idubicacion: parseInt(idubicacion) }));

    if (itemalmacen.ruta == "inventarioproductosactivos") {
      this.setState({ redireccionando: true })
    } else if (itemalmacen.ruta == "alertas") {
      this.setState({ redireccionandoaalertas: true })
    }
  }

 

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? (<AccionesVista bandera={'inventarioproductosactivos'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

      

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo, minWidth: '380px' }}>Almacenes de activos fijos  </label>
              </div>

            

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar área/rancho"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

           

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.trim().length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(item.tipoubicacion == 1) ? (item.area) : (item.rancho + " / " + item.zona)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(item.tipoubicacion == 1) ? ("Área") : ("Rancho")}
                        </td>


                        <td className="centrarbotones letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <label
                            onClick={this.redireccioando.bind(this, { contenido: item, ruta: "inventarioproductosactivos" })}
                            style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                            <BsFillEyeFill className="icoBotones" />
                            <b className='btnVerTabla'>Ver inventario </b>
                          </label>
                        </td>
                       
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(item.tipoubicacion == 1) ? (item.area) : (item.rancho + " / " + item.zona)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(item.tipoubicacion == 1) ? ("Área") : ("Rancho")}
                        </td>


                        <td className="centrarbotones letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <label
                            onClick={this.redireccioando.bind(this, { contenido: item, ruta: "inventarioproductosactivos" })}
                            style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                            <BsFillEyeFill className="icoBotones" />
                            <b className='btnVerTabla'>Ver inventario </b>
                          </label>
                        </td>
                       
                      </tr>
                    ))
                  }

                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'inventarioproductosactivos') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/inventarioproductosactivos"} />)
  }
}
