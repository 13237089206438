import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './Cotizacion.css';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import { BsCheckLg, BsArrowCounterclockwise } from "react-icons/bs";
import { estiloComboBox, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import EditarTablaCotizacion from './EditarTablaCotizacion';
import GuardarTablaCotizacion from './GuardarTablaCotizacion';
import AutorizacionTablaCotizacion from './AutorizacionTablaCotizacion';
import AutorizadoTablaCotizacion from './AutorizadoTablaCotizacion';
import CompraRealizadaTablaCotizacion from './CompraRealizadaTablaCotizacion';
import RechazadoTablaCotizacion from './RechazadoTablaCotizacion';
import CambioTablaCotizacion from './CambioTablaCotizacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  solicitarAutorizacionCotizacion, comprarCotizacion,
  getComboTipoProveedor, getProveedoresActivos, getInformacionCotizacion, guardarCotizacion,
  autorizarProductos
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { informacionCotizacionDummy } from './informaciondummy';

import ModalAsignarProductoCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalAsignarProductoCotizacion';
import ModalEliminarCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalEliminarCotizacion';
import ModalSustituirProductoCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalSustituirProductoCotizacion';
import ModalRestaurarProductoCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalRestaurarProductoCotizacion';
import ModalRegresarProductoCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalRegresarProductoCotizacion';
import { formatoNumero, formatoNumero2, formatoNumeroTecla } from '../../Services/Validaciones';
import ModalRechazarCotizacion from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalRechazarCotizacion';
import ModalFormularioAutorizacion from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalFormularioAutorizacion';
import ModalFormularioResumen from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalFormularioResumen';

export default class Cotizacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      estatuseditable: false,
      redireccionando: false,
      redireccionandobandera: "",
      estatus: [],

      accionmodal: 1,
      titulomodal: '',

      itemseleccionado: null,

      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Pendientes",
          "Proveedor sugerido",
          "Completados",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "15%",
          "15%",
          "20%",
          "20%",
          "30%"
        ],
        mindimensiones: [
          "120px",
          "170px",
          "100px",
          "100px",
          "180px",
          "130px",
          "190px"
        ],
        maxdimensiones: [
          "120px",
          "160px",
          "100px",
          "100px",
          "180px",
          "130px",
          "190px"
        ],
        elementos: [],
        total: 0.00,
      },

      tablaresumenCompra: {
        encabezados: [
          "ID Cotización",
          "Proveedor",
          "Fecha de compra",
          "Estatus",
          "Total"],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "20%",
          "15%"
        ],
        mindimensiones: [
          "100px",
          "170px",
          "170px",
          "170px",
          "100px"
        ],
        maxdimensiones: [
          "100px",
          "300px",
          "300px",
          "300px",
          "300px"
        ],
        elementos: [],
        total: 0.00,
      },

      haysustitutos: false,

      //tarjetas cotizacion
      contenidorequisicion: [],
      idcotizacionGeneral: 0,

      statusNoborrar: 4, // se cambiara a estatus compra realiza = 4 , 4 es compra realizada

      //modales
      modalasignarproducto: false,
      modalsustituirproducto: false,
      modalrestaurarproducto: false,
      modalregresarproducto: false,
      modaleliminartarjeta: false,
      modalautorizacion: false,

      tarjetaautorizar: null,

      listaproveedores: [],
      listatipoproveedores: [],
      listaproductossustitutos: [],
      listanuevosproductos: [],

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      cuantosdecimales:2,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idrequisicion = await localStorage.getItem('dato')

    if (idrequisicion != null) {

      this.setState({ loadingspinners: true })

      //obtiene lista de tipo proveedores
      var resptipoprovedores = await getComboTipoProveedor();
      if (this.manejadorLlamadasApis(resptipoprovedores)) {
        return;
      }

      //obtiene lista de proveedores
      var respproveedores = await getProveedoresActivos();
      if (this.manejadorLlamadasApis(respproveedores)) {
        return;
      }

      var resprequisicioninformacion = await getInformacionCotizacion(idrequisicion);
      //var resprequisicioninformacion22 = informacionCotizacionDummy.informacionCotizacion;

      if (this.manejadorLlamadasApis(resprequisicioninformacion)) {
        return;
      }

      if (resprequisicioninformacion.code == 200) {
        await toast.error(resprequisicioninformacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 1000);
        return;
      }

      var informacionrequisicion = this.state.informacionrequisicion
      informacionrequisicion = resprequisicioninformacion.data ? resprequisicioninformacion.data : {};
      //informacionrequisicion = resprequisicioninformacion22;

      //--- Tabla Resumen de productos
      var tablaresumen = this.state.tablaresumen
      var objetoresumen = informacionrequisicion.Resumen ? informacionrequisicion.Resumen : {}
      tablaresumen.elementos = objetoresumen ? objetoresumen : {}


      //--- Tabla Resumen general
      var tablaresumenCompra = this.state.tablaresumenCompra
      var objetoresumenGeneral = informacionrequisicion.resumencompras ? informacionrequisicion.resumencompras : {}
      tablaresumenCompra.elementos = objetoresumenGeneral.compras ? objetoresumenGeneral.compras : {}
      tablaresumenCompra.total = objetoresumenGeneral.totalgeneral ? objetoresumenGeneral.totalgeneral : 0.0


      //--- Tarjetas cotizaciones
      var contenidorequisicion = this.state.contenidorequisicion
      contenidorequisicion = informacionrequisicion.cotizaciones ? informacionrequisicion.cotizaciones : {}
      contenidorequisicion = this.calcularAcumulados(contenidorequisicion)

      // console.log("componentdidmount",contenidorequisicion)

      this.setState({
        informacionrequisicion: informacionrequisicion,
        contenidorequisicion: contenidorequisicion,
        tablaresumen: tablaresumen,
        tablaresumenCompra: tablaresumenCompra,
        listaproveedores: respproveedores.data,
        listatipoproveedores: resptipoprovedores.data,
        estatuseditable: false,
        idcotizacionGeneral: informacionrequisicion.idcotizacion,
        idcotizacion: informacionrequisicion.idcotizacion
      });


      setTimeout(() => {
        this.actualizarResumenGeneral();
        this.actualizarResumenPrincipal();
        this.iniciarSustitutos();
      }, 300);

    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }


  calcularAcumulados(cotizaciones) {
    let nuevacotizacion = [];

    cotizaciones.forEach(coti => {
      let sumaprecios = 0;
      let sumarivas = 0;
      let sumarieps = 0;
      coti.productos.forEach(prod => {
        sumaprecios = sumaprecios + parseFloat(prod.precio);
        sumarivas = sumarivas + parseFloat(prod.iva);
        sumarieps = sumarieps + parseFloat(prod.ieps_calculado);
      });

      nuevacotizacion.push({

        "idcotizacion_proveedor": coti.idcotizacion_proveedor,
        "idproveedor": coti.idproveedor,
        "proveedor": coti.proveedor,
        "fechacompra": coti.fechacompra,
        "idestatuscotizacion": coti.idestatuscotizacion,
        "estatuscotizacion": coti.estatuscotizacion,
        "mensaje_estatuscotizacion": coti.mensaje_estatuscotizacion,
        "iva_activo": coti.iva_activo,
        "idieps": coti.idieps,
        "productos": coti.productos,
        "total": parseFloat(sumarivas + sumarieps + sumaprecios).toFixed(4),
        "totalivas": parseFloat(sumarivas).toFixed(4),
        "totalieps": parseFloat(sumarieps).toFixed(4),
        "totalprecios": parseFloat(sumaprecios).toFixed(4),

      });

    });




    return nuevacotizacion

  }

  iniciarSustitutos = () => {
    if (this.state.tablaresumen.elementos.length > 0) {
      var sustitutos = [];
      this.state.tablaresumen.elementos.map((pd) => {
        if (pd.sustitutode != "" && pd.sustitutode != "Sustituido") {
          this.setState({ haysustitutos: true })
          sustitutos.push(pd)
          var indexcotizacioneditar = this.state.tablaresumen.elementos.findIndex((item => item.idproducto == pd.sustitutode))
          if (indexcotizacioneditar > -1) {
            this.state.tablaresumen.elementos[indexcotizacioneditar].sustitutode = "Sustituido";
            this.state.tablaresumen.elementos[indexcotizacioneditar].pendientes = 0;
          }
        }
      })
    }
    this.setState({ listaproductossustitutos: sustitutos })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  vermodalAsignarProducto = (ban) => {
    
    if (ban.accion == 1) {
      //ASIGNAR PRODUCTO
      this.setState({ accionmodal: 1, modalasignarproducto: true });
    } else if (ban.accion == 2) {
      //CAMBIAR PRODUCTO
      var tarjetas = this.state.contenidorequisicion;
      let productosustituir = {
        cantidadcomprada: 0.0,
        productosustituido: ban.item,
        productos: this.state.tablaresumen.elementos
      };
      var cantidadcomprada = 0.0;
      tarjetas.map((tarjeta) => {
        if (tarjeta.idestatuscotizacion == this.state.statusNoborrar) {
          tarjeta.productos.map((pd) => {
            if (pd.idproducto == ban.item.idproducto) {
              cantidadcomprada = cantidadcomprada + parseFloat(pd.cantidad);
            }
          })
        }
      })
      productosustituir.cantidadcomprada = cantidadcomprada;
      this.setState({ accionmodal: 2, modalsustituirproducto: true, itemseleccionado: productosustituir });
    } else if (ban.accion == 3) {
      //RESTAURAR PRODUCTO
      //se obtienen los proveedores que contengan el producto
      var proveedores = [];
      var vendidos = 0;
      var pendientes = 0;
      var asignados = 0;
      var vendidoscomprados = 0;
      var enablerestaurar = false;

      this.state.contenidorequisicion.map((tarjeta) => {

        var indexprod = tarjeta.productos.findIndex((item => item.idproducto == ban.item.idproducto))
        if (indexprod > -1) {
          let prov = {
            idproveedor: tarjeta.idproveedor,
            proveedor: tarjeta.proveedor,
            cantidad: tarjeta.productos[indexprod].cantidad,
            idestatuscotizacion: tarjeta.idestatuscotizacion,
            estatuscotizacion: tarjeta.estatuscotizacion
          }
          vendidos = vendidos + parseFloat(tarjeta.productos[indexprod].cantidad)
          proveedores.push(prov)
          if (tarjeta.idestatuscotizacion == this.state.statusNoborrar) {
            vendidoscomprados = vendidoscomprados + parseFloat(tarjeta.productos[indexprod].cantidad)
          } else {
            enablerestaurar = true;
            asignados = asignados + parseFloat(tarjeta.productos[indexprod].cantidad)
          }
        }
      })

      pendientes = parseFloat(ban.item.cantidad) - vendidos;

      let restaurarprod = {
        prodrestaurar: ban.item,
        proveedores: proveedores,
        vendidos: vendidos,
        asignados: asignados,
        vendidoscomprados: vendidoscomprados,
        pendientes: pendientes,
        accion: 3,
        enablerestaurar: enablerestaurar
      }
      this.setState({ accionmodal: 3, modalrestaurarproducto: true, itemseleccionado: restaurarprod, titulomodal: "Restaurar producto" });
    } else if (ban.accion == 4) {
      //REGRESAR PRODUCTO
      //se obtienen los productos sustitutos
      var productos = [];
      var proveedores = [];
      var vendidos = 0;
      var pendientes = 0;
      var vendidoscomprados = 0;

      //se obtiene los productos sustitutos
      this.state.tablaresumen.elementos.map((producto) => {
        if (producto.sustitutode == ban.item.idproducto) {
          let prod = {
            idproducto: producto.idproducto,
            producto: producto.producto,
            cantidad: producto.cantidad,
            pendientes: producto.pendientes
          }
          productos.push(prod)
        }
      })

      //se optiene los proveedores que contengan los productos
      productos.map((prod) => {
        this.state.contenidorequisicion.map((tarjeta) => {

          var indexprod = tarjeta.productos.findIndex((item => item.idproducto == prod.idproducto))
          if (indexprod > -1) {
            let prov = {
              idproducto: prod.idproducto,
              idproveedor: tarjeta.idproveedor,
              proveedor: tarjeta.proveedor,
              cantidad: tarjeta.productos[indexprod].cantidad,
              idestatuscotizacion: tarjeta.idestatuscotizacion,
              estatuscotizacion: tarjeta.estatuscotizacion
            }

            proveedores.push(prov)
            if (prod.idproducto == ban.item.idproducto) {
              vendidos = vendidos + parseFloat(tarjeta.productos[indexprod].cantidad)
              if (tarjeta.idestatuscotizacion == this.state.statusNoborrar) {
                vendidoscomprados = vendidoscomprados + parseFloat(tarjeta.productos[indexprod].cantidad)
              }
            }
          }

        })
      })

      pendientes = parseFloat(ban.item.cantidad) - vendidos;

      let restaurarprod = {
        prodrestaurar: ban.item,
        productos: productos,
        proveedores: proveedores,
        vendidos: vendidos,
        vendidoscomprados: vendidoscomprados,
        pendientes: pendientes,
        accion: 4
      }

      this.setState({ accionmodal: 4, modalregresarproducto: true, itemseleccionado: restaurarprod, titulomodal: "Regresar producto" });
    }

  }

  cerrarModalAsignarProducto = async (opciones) => {
    
    if (opciones != "0") {

      var aux = this.state.contenidorequisicion;

      var total = 0.00;
      opciones.productos.map((p) => {
        total = total + parseFloat(p.precio)
      })

      var newTotal = "" + total;
      var auxTotal = newTotal.split('.');
      if (auxTotal.length > 1) {
        newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
      } else {
        newTotal = auxTotal[0].slice(0, 7) + ".00"
      }

      //opciones.total = newTotal;

      if (opciones.btnEditado) {
        var indexcotizacioneditar = aux.findIndex((item => item.idcotizacion_proveedor == opciones.idcotizacion_proveedor))
        if (indexcotizacioneditar > -1) {
          aux[indexcotizacioneditar] = opciones;
        } else {
          toast.info("No se encontró la tarjeta a editar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      } else {
        opciones.idcotizacion_proveedor = aux.length + 1;
        opciones.esdummy = 1;
        aux.push(opciones)
      }

      this.state.contenidorequisicion = aux;
     // console.log("fin cerrarModalAsignarProducto", aux)
      await this.setState({ estatuseditable: true, })

      this.actualizarResumenPrincipal();
      this.actualizarResumenGeneral();
    }

    this.setState({ modalasignarproducto: !this.state.modalasignarproducto })
  }

  actualizarContenido = (tarjetaCotizacion) => {
    this.setState({ accionmodal: 4, modalasignarproducto: true, itemseleccionado: tarjetaCotizacion });
  }

  actualizarResumenGeneral = async () => {

    var resumenAux = [];
    var totalResumen = 0.0;
    if (this.state.contenidorequisicion.length > 0) {
      this.state.contenidorequisicion.map((tarjeta, index) => {

        var newTotal2 = "" + tarjeta.total;
        var auxTotal = newTotal2.split('.');
        if (auxTotal.length > 1) {
          newTotal2 = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 4)
        } else {
          newTotal2 = auxTotal[0].slice(0, 7) + ".00"
        }

        let json = {
          "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
          "idproveedorcompra": tarjeta.idproveedor,
          "proveedorcompra": tarjeta.proveedor,
          "idestatuscompra": tarjeta.idestatuscotizacion,
          "estatuscompra": tarjeta.estatuscotizacion,
          "fechacompra": tarjeta.fechacompra,
          "total": newTotal2
        }
        if (tarjeta.total != undefined) {
          totalResumen = totalResumen + parseFloat(newTotal2);
        }

        resumenAux.push(json)
      })
    }

    var newTotal = "" + totalResumen;
    var auxTotal = newTotal.split('.');
    if (auxTotal.length > 1) {
      newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 4)
    } else {
      newTotal = auxTotal[0].slice(0, 7) + ".00"
    }

    this.state.tablaresumenCompra.elementos = resumenAux;
    this.state.tablaresumenCompra.total = newTotal;

    var tablaresumencompra = this.state.tablaresumenCompra;
    

    await this.setState({ tablaresumenCompra: tablaresumencompra })
  }

  actualizarResumenPrincipal = async () => {

    var resumenAux = [];
    if (this.state.tablaresumen.elementos.length > 0) {
      this.state.tablaresumen.elementos.map((producto) => {
        var repartidos = 0.00;
        this.state.contenidorequisicion.forEach((tarjeta) => {
          tarjeta.productos.forEach((p) => {
            if (p.idproducto == producto.idproducto) {
              repartidos = repartidos + parseFloat(p.cantidad);
            }
          })
        })
       

        //if (producto.sustituir == 1) {
          producto.pendientes = "0.00";
        //} else {
          var auxPendientes = parseFloat(producto.cantidad) - parseFloat(repartidos);
          var newPendientes = "" + auxPendientes;
          var auxPen = newPendientes.split('.');
          if (auxPen.length > 1) {
            newPendientes = auxPen[0].slice(0, 7) + "." + auxPen[1].slice(0, 2)
          } else {
            newPendientes = auxPen[0].slice(0, 7) + ".00"
          }
          producto.pendientes = newPendientes;
       // }

        var newCantidad = "" + producto.cantidad;
        var auxCan = newCantidad.split('.');
        if (auxCan.length > 1) {
          newCantidad = auxCan[0].slice(0, 7) + "." + auxCan[1].slice(0, 2)
        } else {
          newCantidad = auxCan[0].slice(0, 7) + ".00"
        }
        producto.cantidad = newCantidad;

        resumenAux.push(producto)
      })
    }

    this.state.tablaresumen.elementos = resumenAux;

    
    var tablaresumenAux = this.state.tablaresumen;

    await this.setState({ tablaresumen: tablaresumenAux })
  }

  eliminarTarjeta = async (tarjetaCotizacion) => {
    await this.setState({ modaleliminartarjeta: !this.state.modaleliminartarjeta })
    if (tarjetaCotizacion != "0") {
      var aux = this.state.contenidorequisicion.filter((item) => !(item.idcotizacion_proveedor == tarjetaCotizacion.idcotizacion_proveedor));

      await this.setState({ contenidorequisicion: aux })

      this.actualizarResumenPrincipal();
      this.actualizarResumenGeneral();
      this.setState({ estatuseditable: true })
      await this.setState({})
    }
  }

  guardarCambios = async () => {

    var validaciones = true;


    var resumen = [];
    if (this.state.informacionrequisicion.Resumen.length > 0) {
      this.state.informacionrequisicion.Resumen.map((item) => {

        let json = {
          "idproducto_resumen": item.idproducto_resumen,
          "idproducto": item.idproducto,
          "sustitutode": item.sustitutode == "Sustituido" ? "" : item.sustitutode,
          "sustituir": item.sustitutode == "Sustituido" ? 1 : 0,
          "cantidad": item.cantidad,
          "pendientes": item.pendientes,
          "idproveedorsugerido": item.idproveedor_recomendado
        }

        resumen.push(json)
      })
    }

    var cotizaciones = [];
   // console.log("informacion requisicion", this.state.informacionrequisicion);

    // if (this.state.informacionrequisicion.cotizaciones.length > 0) {
    this.state.contenidorequisicion.map((item, index) => {
      // console.log("idieps",this.state.contenidorequisicion[index]);
      let elemento = {
        idcotizacion_proveedor: (this.state.contenidorequisicion[index].esdummy == 1) ? (0) : (this.state.contenidorequisicion[index].idcotizacion_proveedor),
        idproveedor: this.state.contenidorequisicion[index].idproveedor,
        total: this.state.contenidorequisicion[index].total,//this.state.contenidorequisicion[index].total ? this.state.contenidorequisicion[index].total : 0.0,
        iva_activo: this.state.contenidorequisicion[index].iva_activo,
        idieps: this.state.contenidorequisicion[index].idieps,
        productos: []
      }
      if (item.productos.length > 0) {
        item.productos.map((p, index2) => {
          let producto = {
            idproducto_cotizacionproveedor: this.state.contenidorequisicion[index].productos[index2].idproducto_cotizacionproveedor,
            idproducto: this.state.contenidorequisicion[index].productos[index2].idproducto,
            cantidad: this.state.contenidorequisicion[index].productos[index2].cantidad,
            precio: this.state.contenidorequisicion[index].productos[index2].precio,
            iva: this.state.contenidorequisicion[index].productos[index2].iva,
            ieps_calculado: this.state.contenidorequisicion[index].productos[index2].ieps_calculado,
          }
          elemento.productos.push(producto)
        })
      } else {
        validaciones = false;
        toast.error("No puede haber una cotización sin productos", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      }
      cotizaciones.push(elemento);
    })
    // }

    var compras = [];

    if (this.state.tablaresumenCompra.elementos.length > 0) {
      this.state.tablaresumenCompra.elementos.map((item, index) => {
        let elemento = {
          "idcotizacion_proveedor": (this.state.contenidorequisicion[index].esdummy == 1) ? (0) : (item.idcotizacion_proveedor),
          "idproveedorcompra": item.idproveedorcompra,
          "idestatuscompra": (item.idestatuscompra == 0 || item.idestatuscompra == 5) ? (1) : item.idestatuscompra,
          "fechacompra": item.fechacompra == "" ? "" : item.fechacompra,
          "total": item.total ? item.total : 0.0
        }
        compras.push(elemento);
      })
    }
    this.state.informacionrequisicion.resumencompras = { "compras": compras, "totalgeneral": this.state.tablaresumenCompra.total };

    let json = {
      "idcatalogo_requisicion": this.state.informacionrequisicion.idcatalogo_requisicion,
      "idcotizacion": this.state.informacionrequisicion.idcotizacion,
      "Resumen": resumen,
      "cotizaciones": cotizaciones,
      "resumencompras": this.state.informacionrequisicion.resumencompras,
      "mensaje_resumen": this.state.informacionrequisicion.mensaje_resumen
    }

    //console.log(json)
    if (validaciones) {
      this.setState({ loadingspinners: true })
      var resp = await guardarCotizacion(json);
      if (resp == -1 || resp.code == 1000) {
        this.setState({ loadingspinners: false })
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setState({ loadingspinners: false })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        this.setState({ listaproductossustitutos: [], haysustitutos: false })
        toast.success("Cotización guardada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
    }
  }

  cambiarStatus = async (tarjeta) => {
    if (!this.state.estatuseditable) {
      //se valida que la tarjeta no contenga productos sustitutos sin validar
      var validacion = true;

      tarjeta.productos.map((prod) => {
        var indexpdresumen = this.state.tablaresumen.elementos.findIndex((item => item.idproducto == prod.idproducto));
        if (indexpdresumen > -1) {
          if (this.state.tablaresumen.elementos[indexpdresumen].sustitutode != "") {
            validacion = false;
          }
        }
      })

      //si la tarjeta no tiene productos sustitutos, se hace el cambio de estatus
      if (validacion) {
        this.setState({ loadingspinners: true })
        let json = {
          "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
          "idestatuscotizacion": 2,
          "password": "",
          "comentario": ""
        }

        var resp = await solicitarAutorizacionCotizacion(json);

        if (resp == -1 || resp.code == 1000) {
          this.setState({ loadingspinners: false })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        }
        else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
          this.setState({ loadingspinners: false })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        }
        else {
          toast.success("Solicitud realizada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.componentDidMount();
        }

      } else {
        toast.info("Hay productos sin validar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  comprar = async (tarjeta) => {
    if (!this.state.estatuseditable) {
      //se valida que la tarjeta no contenga productos sustitutos sin validar
      var validacion = true;

      tarjeta.productos.map((prod) => {
        var indexpdresumen = this.state.tablaresumen.elementos.findIndex((item => item.idproducto == prod.idproducto));
        if (indexpdresumen > -1) {
          if (this.state.tablaresumen.elementos[indexpdresumen].sustitutode != "") {
            validacion = false;
          }
        }
      })

      //si la tarjeta no tiene productos sustitutos, se hace el cambio de estatus
      if (validacion) {
        this.setState({ loadingspinners: true })

        var resp = await comprarCotizacion(tarjeta.idcotizacion_proveedor);

        if (resp == -1 || resp.code == 1000) {
          this.setState({ loadingspinners: false })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        }
        else if (resp.code == 200 || resp.code == 300  || resp.code == 400) {
          this.setState({ loadingspinners: false })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        } else {
          toast.success("Compra realizada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.componentDidMount()
        }

      } else {
        toast.info("Hay productos sin validar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  vermodalEliminar = (tarjetaCotizacion) => {
    this.setState({ accionmodal: 5, modaleliminartarjeta: true, itemseleccionado: tarjetaCotizacion });
  }

  cambiarStatusDisponible = async (tarjeta) => {
    if (!this.state.estatuseditable) {
      //se valida que la tarjeta no contenga productos sustitutos sin validar
      var validacion = true;

      tarjeta.productos.map((prod) => {
        var indexpdresumen = this.state.tablaresumen.elementos.findIndex((item => item.idproducto == prod.idproducto));
        if (indexpdresumen > -1) {
          if (this.state.tablaresumen.elementos[indexpdresumen].sustitutode != "") {
            validacion = false;
          }
        }
      })

      //si la tarjeta no tiene productos sustitutos, se hace el cambio de estatus
      if (validacion) {
        this.setState({ loadingspinners: true })

        let json = {
          "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
          "idestatuscotizacion": 1,
          "password": "",
          "comentario": "TEST manual"
        }

        var resp = await solicitarAutorizacionCotizacion(json);

        if (resp == -1 || resp.code == 1000) {
          this.setState({ loadingspinners: false })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        } else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
          this.setState({ loadingspinners: false })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        } else {
          toast.success("Estatus cambiado con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.componentDidMount();
        }

      } else {
        toast.info("Hay productos sin validar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  cerrarModalSustituirProducto = async (opciones) => {
    if (opciones != "0") {
      var resumen = this.state.tablaresumen.elementos;
      var sustitutos = [];
      if (opciones.productos.length > 0) {
        var indexcotizacioneditar = resumen.findIndex((item => item.idproducto == opciones.idproductosustituido))
        if (indexcotizacioneditar > -1) {
          resumen[indexcotizacioneditar].sustitutode = "Sustituido";
          resumen[indexcotizacioneditar].sustituir = 1;
          resumen[indexcotizacioneditar].pendientes = "0.00";

          var concatenados = resumen.concat(opciones.productos)
          var resumen2 = this.state.tablaresumen;
          sustitutos = this.state.listaproductossustitutos.concat(opciones.productos)
          resumen2.elementos = concatenados;

          // se borrara el producto de las tarjetas que lo contienen
          var cotizaciones = this.state.contenidorequisicion;

          if (cotizaciones.length > 0) {
            cotizaciones.map((tarjeta) => {
              var existe = tarjeta.productos.findIndex((item => item.idproducto == opciones.idproductosustituido && tarjeta.idestatuscotizacion != this.state.statusNoborrar))
              if (existe > -1) {
                var aux = tarjeta.productos.filter((pd) => !(pd.idproducto == opciones.idproductosustituido))
                tarjeta.productos = aux;
                tarjeta.idestatuscotizacion = 0;
                tarjeta.estatuscotizacion = "Editado";
                var total = 0.0;
                if (tarjeta.productos.length > 0) {
                  tarjeta.productos.map((tpd) => {
                    total = total + parseFloat(tpd.precio).toFixed(4);
                  })
                }
                tarjeta.total = total;
              }

            })
          }

          var auxinformacion = this.state.informacionrequisicion;
          auxinformacion.Resumen = resumen2.elementos;

          cotizaciones = this.calcularAcumulados(cotizaciones)

          await this.setState({
            tablaresumen: resumen2,
            listaproductossustitutos: sustitutos,
            contenidorequisicion: cotizaciones,
            informacionrequisicion: auxinformacion,
            listanuevosproductos: opciones.productosNuevos
          })

          this.setState({ estatuseditable: true })
          //this.actualizarResumenPrincipal();
          this.actualizarResumenGeneral();

          toast.info("Se borró el agroquímico sustituido de las cotizaciones que la contenían", { autoClose: 1500, position: toast.POSITION.TOP_LEFT })
        } else {
          toast.info("No se encontró el agroquímico a sustituir", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      }
    }

    this.setState({ modalsustituirproducto: !this.state.modalsustituirproducto })
  }

  RenderColumns = (item, index) => {
    return (
      <>
        <tr key={"tabla" + index} className={"sustituidoFila"}>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
            {item.producto}
          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
            {item.unidadmedida}
          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
            {item.cantidad}
          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
            {"0.00"}
          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
            {item.proveedor_recomendado}
          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>

          </td>
          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
            {
              <Button className="btnRestaurarCotizaciones" onClick={this.vermodalAsignarProducto.bind(this, { item: item, accion: 4 })}><BsArrowCounterclockwise className="icoBotones" />Regresar 1</Button>
            }
          </td>
        </tr>

        {this.state.listaproductossustitutos.map((item2, index2) => (
          item2.sustitutode == item.idproducto ?
            <tr key={"tablasus" + index2} className={item2.pendientes == 0 ? "sustitutosFila sinPendientes" : "sustitutosFila"} >
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                {item2.producto}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                {item2.unidadmedida}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                {item2.cantidad}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                {item2.pendientes}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                {item2.proveedor_recomendado}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                {item2.pendientes == 0 ? <BsCheckLg className="icoBotones" /> : null}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

              </td>
            </tr>
            : null
        ))}
      </>
    );
  }

  cerrarModalRestaurarProducto = async (opciones) => {
    if (opciones != "0") {
      if (opciones.accion == 3) { // restaurar producto
        var resumen = this.state.tablaresumen.elementos;
        var sustitutos = [];
        if (opciones.proveedores.length > 0) {
          var indexcotizacioneditar = resumen.findIndex((item => item.idproducto == opciones.prodrestaurar.idproducto))
          if (indexcotizacioneditar > -1) {
            resumen[indexcotizacioneditar].pendientes = resumen[indexcotizacioneditar].cantidad - opciones.vendidoscomprados;

            //var concatenados = resumen.concat(opciones.productos)
            var resumen2 = this.state.tablaresumen;
            //sustitutos = this.state.listaproductossustitutos.concat(opciones.productos)
            resumen2.elementos = resumen;

            // se borrara el producto de las tarjetas que lo contienen
            var cotizaciones = this.state.contenidorequisicion;

            if (cotizaciones.length > 0) {
              cotizaciones.map((tarjeta) => {
                var existe = tarjeta.productos.findIndex((item => item.idproducto == opciones.prodrestaurar.idproducto && tarjeta.idestatuscotizacion != this.state.statusNoborrar))
                if (existe > -1) {
                  var aux = tarjeta.productos.filter((pd) => !(pd.idproducto == opciones.prodrestaurar.idproducto))
                  tarjeta.productos = aux;
                  tarjeta.idestatuscotizacion = 0;
                  tarjeta.estatuscotizacion = "Editado";
                  var total = 0.0;
                  if (tarjeta.productos.length > 0) {
                    tarjeta.productos.map((tpd) => {
                      total = total + parseFloat(tpd.precio);
                    })
                  }
                  tarjeta.total = total;
                }

              })
            }

            var auxinformacion = this.state.informacionrequisicion;
            auxinformacion.Resumen = resumen2.elementos;

            await this.setState({ tablaresumen: resumen2, contenidorequisicion: cotizaciones, informacionrequisicion: auxinformacion })

            this.setState({ estatuseditable: true })
            //this.actualizarResumenPrincipal();
            this.actualizarResumenGeneral();

            toast.info("Se borró el agroquímico sustituido de las cotizaciones que la contenían", { autoClose: 1500, position: toast.POSITION.TOP_LEFT })
          } else {
            toast.info("No se encontró el agroquímico a sustituir", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
        }
      } else if (opciones.accion == 4) { //resustituir producto
        var resumen = this.state.tablaresumen.elementos;
        var sustitutos = [];
        if (opciones.proveedores.length > 0) {
          var indexcotizacioneditar = resumen.findIndex((item => item.idproducto == opciones.prodrestaurar.idproducto))
          if (indexcotizacioneditar > -1) {
            resumen[indexcotizacioneditar].pendientes = resumen[indexcotizacioneditar].cantidad;
            resumen[indexcotizacioneditar].sustitutode = "";

            var aux = resumen.filter((pd) => !(pd.sustitutode == opciones.prodrestaurar.idproducto))

            //var concatenados = resumen.concat(opciones.productos)
            var resumen2 = this.state.tablaresumen;
            sustitutos = this.state.listaproductossustitutos;
            resumen2.elementos = aux;

            // se borrara el producto de las tarjetas que lo contienen
            var cotizaciones = this.state.contenidorequisicion;

            if (cotizaciones.length > 0) {
              cotizaciones.map((tarjeta) => {
                opciones.proveedores.map((productosustituto) => {
                  var existe = tarjeta.productos.findIndex((item => item.idproducto == productosustituto.idproducto))
                  if (existe > -1) {
                    var aux = tarjeta.productos.filter((pd) => !(pd.idproducto == productosustituto.idproducto))
                    tarjeta.productos = aux;
                    tarjeta.idestatuscotizacion = 0;
                    tarjeta.estatuscotizacion = "Editado";
                    var total = 0.0;
                    if (tarjeta.productos.length > 0) {
                      tarjeta.productos.map((tpd) => {
                        total = total + parseFloat(tpd.precio);
                      })
                    }
                    tarjeta.total = total;
                  }
                })
              })
            }

            opciones.proveedores.map((productosustituto) => {
              var existe = sustitutos.findIndex((item => item.idproducto == productosustituto.idproducto))
              if (existe > -1) {
                sustitutos = sustitutos.filter((pd) => !(pd.idproducto == productosustituto.idproducto))
              }
            })

            var auxinformacion = this.state.informacionrequisicion;
            auxinformacion.Resumen = resumen2.elementos;


            await this.setState({ tablaresumen: resumen2, listaproductossustitutos: sustitutos, contenidorequisicion: cotizaciones, informacionrequisicion: auxinformacion, modalregresarproducto: !this.state.modalregresarproducto })

            this.setState({ estatuseditable: true })
            //this.actualizarResumenPrincipal();
            this.actualizarResumenGeneral();

            toast.info("Se borró el agroquímico sustituido de las cotizaciones que la contenían", { autoClose: 1500, position: toast.POSITION.TOP_LEFT })
          } else {
            toast.info("No se encontró el agroquímico a sustituir", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
        }
      }
    }
    this.setState({ modalrestaurarproducto: !this.state.modalrestaurarproducto })
  }

  cerrarModalRegresarProducto = async (opciones) => {

    if (opciones != "0") {
      if (opciones.accion == 4) { //resustituir producto

        var resumen = this.state.tablaresumen.elementos;
        var sustitutos = [];
        if (opciones.productos.length > 0) {
          var indexcotizacioneditar = resumen.findIndex((item => item.idproducto == opciones.prodrestaurar.idproducto))
          if (indexcotizacioneditar > -1) {
            resumen[indexcotizacioneditar].pendientes = resumen[indexcotizacioneditar].cantidad - opciones.vendidoscomprados;
            resumen[indexcotizacioneditar].sustitutode = "";

            var aux = resumen.filter((pd) => !(pd.sustitutode == opciones.prodrestaurar.idproducto))

            //var concatenados = resumen.concat(opciones.productos)
            var resumen2 = this.state.tablaresumen;
            sustitutos = this.state.listaproductossustitutos;
            resumen2.elementos = aux;

            // se borrara el producto de las tarjetas que lo contienen
            var cotizaciones = this.state.contenidorequisicion;

            if (cotizaciones.length > 0) {
              cotizaciones.map((tarjeta) => {
                opciones.productos.map((productosustituto) => {
                  var existe = tarjeta.productos.findIndex((item => item.idproducto == productosustituto.idproducto && tarjeta.idestatuscotizacion != this.state.statusNoborrar))
                  if (existe > -1) {
                    var aux = tarjeta.productos.filter((pd) => !(pd.idproducto == productosustituto.idproducto))
                    
                    tarjeta.productos = aux;
                    tarjeta.idestatuscotizacion = 0;
                    tarjeta.estatuscotizacion = "Editado";
                    var totalprecios = 0.0;
                    var totaliva = 0.0;
                    var totalieps = 0.0;
                    if (tarjeta.productos.length > 0) {
                      tarjeta.productos.map((tpd) => {
                        totalprecios = totalprecios + parseFloat(tpd.precio);
                        totaliva= totaliva + parseFloat(tpd.iva);
                        totalieps = totalieps + parseFloat(tpd.ieps_calculado);

                      })
                    }
                    
                    tarjeta.totalivas = totaliva;
                    tarjeta.totalieps = totalieps;
                    tarjeta.totalprecios = totalprecios;
                    tarjeta.total = totaliva + totalieps + totalprecios;
                  }
                })
              })
            }

            opciones.productos.map((productosustituto) => {
              var existe = sustitutos.findIndex((item => item.idproducto == productosustituto.idproducto))
              if (existe > -1) {
                sustitutos = sustitutos.filter((pd) => !(pd.idproducto == productosustituto.idproducto))
              }
            })

            var auxinformacion = this.state.informacionrequisicion;
            auxinformacion.Resumen = resumen2.elementos;


            await this.setState({ tablaresumen: resumen2, listaproductossustitutos: sustitutos, contenidorequisicion: cotizaciones, informacionrequisicion: auxinformacion })

            this.setState({ estatuseditable: true })
            //this.actualizarResumenPrincipal();
            this.actualizarResumenGeneral();

            toast.info("Se borró el agroquímico sustituido de las cotizaciones que la contenían", { autoClose: 1500, position: toast.POSITION.TOP_LEFT })
          } else {
            toast.info("No se encontró el agroquímico a sustituir", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
        }
      }
    }
    this.setState({ modalregresarproducto: !this.state.modalregresarproducto })
  }

  solicitarAutorizacion = async () => {
    if (!this.state.estatuseditable) {
      //se valida que la tarjeta no contenga productos sustitutos sin validar
      var validacion = true;

      //si la tarjeta no tiene productos sustitutos, se hace el cambio de estatus
      if (validacion) {
        this.setState({ loadingspinners: true })

        let json = {
          "idcotizacion": this.state.idcotizacionGeneral,
          "estatusautorizarproductos": 1,
          "password": "",
          "mensaje_resumen": ""
        }

        var resp = await autorizarProductos(json);

        if (resp == -1 || resp.code == 1000) {
          this.setState({ loadingspinners: false })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        } else if (resp.code == 200 || resp.code == 300) {
          this.setState({ loadingspinners: false })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        } else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        } else {
          toast.success("Autorización de productos solicitada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.componentDidMount();
        }

      } else {
        toast.info("Hay productos sin validar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }





  //////////// lo de autorizar cotizaciones y productos


  verModalRechazarCotizacion = (ban) => {
    if (ban.accion == 1) { //Nuevo
      this.setState({ titulomodal: 'Rechazar cotización', accionmodal: 1, itemseleccionado: JSON.parse(JSON.stringify(ban.item)) })
    }
    this.setState({ modalrechazarcotizacion: true });
  }

  cerrarModalRechazarCotizacion = (datos) => {
    this.setState({ modalrechazarcotizacion: !this.state.modalrechazarcotizacion })
    if (datos.id == 1) {
      this.verModalAutorizacion({ id: 3, item: datos.item })
    }
  }

  verModalAutorizacion = (ban) => {
    //console.log("verModalAutorizacion", ban);
    if (ban.accion == 1) {// Rechazar productos
      //toast.error('Pendiente de implementar', { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
      //this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: 'item' });
    }
    else if (ban.id == 2) {// Aprobar productos
      localStorage.setItem("dato", [this.state.idcotizacion]);
      this.setState({ redireccionando: true, redireccionandobandera: "rectificarproductos" })
    }
    else if (ban.id == 3) {// Rechazar cotización
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 4) {// Aprobar cotización
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
    }
  }

  cerrarModalAutorizacion = (opciones) => {
   // console.log("cerrarModalAutorizacion", opciones)
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (opciones.accion == 3) {
      toast.success("Cotización rechazada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()
    }
    else if (opciones.accion == 4) {
      toast.success("Cotización aprobada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()
    } else if (opciones.accion == 1) {
      toast.success("Cambio de producto rechazado con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()
    }
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  verModalFormularioResumen = (ban) => {
    const aux = this.state.informacionrequisicion
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.Idestatisrequisocion*/ }
   // console.log("verModalFormularioResumen", aux, item)
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar productos sugeridos', accionmodal: ban.id, modalresumen: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioResumen = (opciones) => {
    this.setState({ modalresumen: !this.state.modalresumen })
  //  console.log("cerrarModalFormularioResumen", opciones)

    if (opciones.accion == 1) {
      this.verModalAutorizacion(opciones)
    }
  }

  comprobarProductosSustitutos = (listaproductoresumen) => {
    return listaproductoresumen.filter(item => item.sustitutode != '').length > 0
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

       
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalasignarproducto == true) ? (
          <ModalAsignarProductoCotizacion titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalAsignarProducto}
            estatus={this.state.estatus}
            show={this.state.modalasignarproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listaproveedores={this.state.listaproveedores}
            listatipoproveedores={this.state.listatipoproveedores}
            listaproductosResumen={this.state.tablaresumen.elementos}
            listacotizaciones={this.state.contenidorequisicion} />
        ) : null}

        {(this.state.modaleliminartarjeta == true) ? (
          <ModalEliminarCotizacion titulomodal={"Eliminar tarjeta de cotización"}
            cerrarModal={this.eliminarTarjeta}
            show={this.state.modaleliminartarjeta}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalsustituirproducto == true) ? (
          <ModalSustituirProductoCotizacion titulomodal={"Sustituir agroquímico"}
            cerrarModal={this.cerrarModalSustituirProducto}
            estatus={this.state.estatus}
            show={this.state.modalsustituirproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            statusNoborrar={this.state.statusNoborrar}
            listaproductosnuevos={this.state.listanuevosproductos}
          />

        ) : null}

        {(this.state.modalrestaurarproducto == true) ? (
          <ModalRestaurarProductoCotizacion
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalRestaurarProducto}
            estatus={this.state.estatus}
            show={this.state.modalrestaurarproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            statusNoborrar={this.state.statusNoborrar} />
        ) : null}

        {(this.state.modalregresarproducto == true) ? (
          <ModalRegresarProductoCotizacion
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalRegresarProducto}
            estatus={this.state.estatus}
            show={this.state.modalregresarproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            statusNoborrar={this.state.statusNoborrar} />
        ) : null}


        {(this.state.modalautorizacion == true) ? (/////////// modal para autorizar cotización
          <ModalFormularioAutorizacion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalAutorizacion} estatus={this.state.estatus}
            show={this.state.modalautorizacion} accion={this.state.accionmodal} item={this.state.itemseleccionado} idcotizacion={this.state.idcotizacion}
            setloadingspinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modalrechazarcotizacion == true) ? (////// modal para rechazar cotizacion
          <ModalRechazarCotizacion titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalRechazarCotizacion}
            show={this.state.modalrechazarcotizacion}
            accion={this.state.accionmodal}
            item={{ sw: 12/*this.state.itemseleccionado*/ }}
            /*listatipomovimientos={this.state.listatipomovimientos} listamovimientos={this.state.listamovimientos}¨*/
            informacion={this.state.itemseleccionado}
            setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modalresumen == true) ? (
          <ModalFormularioResumen titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioResumen} estatus={this.state.estatus}
            show={this.state.modalresumen} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}


        {(this.state.redireccionando) ? (<AccionesVista bandera={this.state.redireccionandobandera} />) : null}


        <Navbar />

        {/* ////////////////////   Inicio Sección informacion   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.informacionrequisicion.nombre}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>{this.state.informacionrequisicion.idperiodo != undefined && this.state.informacionrequisicion.idperiodo != "" ? "Plan anual " + this.state.informacionrequisicion.periodo + ", " + this.state.informacionrequisicion.mes : null}</label>
              </div>

              

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.actividad}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                   </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.tiporequisicion}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 col-lg-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaestimada_compra}</label>
              </div>
            

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.solicitante}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechacreacion}</label>
              </div>
             

              <div className='col-7 col-sm-7 col-md-7 col-lg-7 containerSubTabla paddingBottomEncabezadoss'>
              <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.estatuscotizacion !="" ? this.state.informacionrequisicion.estatuscotizacion : this.state.informacionrequisicion.estatusrequisicion}</label>
          
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >
                  {this.state.informacionrequisicion.fechaactualizacion ? this.state.informacionrequisicion.fechaactualizacion : this.state.informacionrequisicion.fechacreacion}
                </label>
              </div>

            </div>
            <br />
            <br />

          </div>
        </div>
        {/* ////////////////////   Fin Sección informacion   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla  Resumen requisicion  ////////////////////////////////////// */}
        <div className='container-fluid contenedorResumen' style={{ background: "white", textAlign: "right" }}>
          <label className='lblTexto letraTitulos' >
            <button className='btncomprasCotizaciones' onClick={() => this.setState({ cuantosdecimales: (this.state.cuantosdecimales == 2) ? (4) : (2) })}>Mostrar formato con {(this.state.cuantosdecimales == 2) ? (4) : (2)} decimales</button>
          </label>
        </div>
        <br />
        <div className="container-fluid containerTabla ">
          <div className="container-fluid contenedorResumen">
            <div className='containerSubTablaAA divMinWidth'>
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tablaresumen.mindimensiones[index],
                          maxWidth: this.state.tablaresumen.maxdimensiones[index],
                          width: this.state.tablaresumen.dimensiones[index],
                          color: this.state.colorencabezadostabla
                        }}>
                        {index == 0 ? "Resumen" : index == 6 ? <Button className="btnAzulCotizaciones" onClick={this.vermodalAsignarProducto.bind(this, { id: 2, accion: 1 })}>Asignar producto</Button> : null}
                      </th>
                    ))}
                  </tr>
                  <tr style={{ borderBottom: 'solid 2px #91b9f9' }}>
                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tablaresumen.mindimensiones[index],
                          maxWidth: this.state.tablaresumen.maxdimensiones[index],
                          width: this.state.tablaresumen.dimensiones[index],
                          color: this.state.colorencabezadostabla,
                          textAlign: (index > 0 && index < 6) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                        }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {/* ////////////////////   lista de productos resumen  ////////////////////////////////////// */}
                  {
                    this.state.tablaresumen.elementos.map((item, index) => (

                      item.sustitutode == "" ?

                        <tr key={"tabla" + index} className={item.pendientes == 0 ? "sinPendientes" : ""} style={{ borderBottom: (index == this.state.tablaresumen.elementos.length - 1) ? 'solid 2px #91b9f9' : '' }}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.producto}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {item.unidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                            {formatoNumero2(item.cantidad)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                            {formatoNumero2(item.pendientes)}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {item.proveedor_recomendado != "" ? item.proveedor_recomendado : "Sin proveedor"}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {item.pendientes == 0 ? <BsCheckLg className="icoBotones" /> : null}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.pendientes == 0 ?
                              <Button className="btnRestaurarCotizaciones" onClick={this.vermodalAsignarProducto.bind(this, { item: item, accion: 3 })}><BsArrowCounterclockwise className="icoBotones" />Restaurar</Button>
                              : item.sustitutode != "" ?
                                <Button className="btnRestaurarCotizaciones" ><BsArrowCounterclockwise className="icoBotones" />Regresar 2</Button>
                                :
                                <Button className="btnBlancoCotizaciones" onClick={this.vermodalAsignarProducto.bind(this, { item: item, accion: 2 })}>Cambiar producto</Button>
                            }
                          </td>
                        </tr>

                        : item.sustitutode == "Sustituido" ?
                          this.RenderColumns(item, index)

                          : null
                    ))
                  }
                  {(this.state.informacionrequisicion.estatusautorizarproductos == 2 && this.state.informacionrequisicion.mensaje_resumen && this.state.informacionrequisicion.mensaje_resumen != "") || this.state.informacionrequisicion.estatusautorizarproductos == 1 ? <br /> : null}
                  {/* ////////////////////   mensaje de rechazo  ////////////////////////////////////// */}
                  {
                    this.state.informacionrequisicion.estatusautorizarproductos == 2 && this.state.informacionrequisicion.mensaje_resumen && this.state.informacionrequisicion.mensaje_resumen != "" ?
                      <tr style={{ borderBottom: 'hidden' }}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}></td>
                        <td colSpan={5} className="letraNormal lblSubtabla" style={{ color: '#000000', paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center', background: '#f9a9a9', borderRadius: '5px' }}>
                          {this.state.informacionrequisicion.mensaje_resumen}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}></td>
                      </tr>
                      : this.state.informacionrequisicion.estatusautorizarproductos == 1 ?
                        <tr style={{ borderBottom: 'hidden' }}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}></td>
                          <td colSpan={5} className="letraNormal lblSubtabla" style={{ color: '#000000', paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center', background: '#f9a9a9', borderRadius: '5px' }}>
                            {"En espera de autorizar productos"}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}></td>
                        </tr>
                        : null
                  }
                  {/* ////////////////////   boton de confirmar informacion y solicitar validacion  ////////////////////////////////////// */}
                  {(this.state.informacionrequisicion.estatusautorizarproductos == 0 && this.state.haysustitutos && !this.state.estatuseditable) || this.state.informacionrequisicion.estatusautorizarproductos == 2 ? <br /> : null}
                  {(this.state.informacionrequisicion.estatusautorizarproductos == 0 && this.state.haysustitutos && !this.state.estatuseditable) || this.state.informacionrequisicion.estatusautorizarproductos == 2 ?
                    <tr style={{ borderBottom: 'hidden' }}>
                      <td colSpan={2} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                      </td>
                      <td colSpan={3} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                        <div>
                          <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                          </div><br></br>
                          <Button onClick={() => this.solicitarAutorizacion()} className="btnAzulCotizaciones" disabled={!this.state.confirmarenvio}>Solicitar validación de productos</Button>
                        </div>
                      </td>
                      <td colSpan={2} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>

                      </td>
                    </tr>
                    : null
                  }


                  <br></br>
                </tbody>
              </Table>

              {
                this.state.informacionrequisicion.permisoautorizarproductos != 0 && this.state.informacionrequisicion.estatusautorizarproductos == 1 && this.comprobarProductosSustitutos(this.state.tablaresumen.elementos) &&
                <div className='row paddingEncabezados align-items-end' style={{ padding: '20px 12px 20px 12px' }}>
                  <div className='col'></div>
                  <div className='col-auto paddingBottomEncabezados'>
                    <Button className="btnRechazarProd" style={{ padding: '4px 12px' }} onClick={this.verModalFormularioResumen.bind(this, { id: 1 })}>
                      Rechazar cambio de productos
                    </Button>
                  </div>
                  <div className='col-auto paddingBottomEncabezados'>
                    <Button className="btnAceptarProd" style={{ padding: '4px 12px' }} onClick={this.verModalAutorizacion.bind(this, { id: 2 })}>
                      Aprobar cambio de productos
                    </Button>
                  </div>
                </div>}

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla  Resumen requisicion ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTablaAA">
              {this.state.contenidorequisicion != undefined && this.state.contenidorequisicion.length > 0 ?
                this.state.contenidorequisicion.map((item, index) => (
                  item.idestatuscotizacion == 0 ? <EditarTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cuantosdecimales={this.state.cuantosdecimales}/>
                    : item.idestatuscotizacion == 1 ? <GuardarTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cambiarStatus={this.cambiarStatus} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales}/>
                      : item.idestatuscotizacion == 2 ? <AutorizacionTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} permisoautorizacion={this.state.informacionrequisicion.permisoautorizacion} vermodalrechazarcotizacion={this.verModalRechazarCotizacion} vermodalautorizacion={this.verModalAutorizacion} cuantosdecimales={this.state.cuantosdecimales}/>
                        : item.idestatuscotizacion == 3 ? <AutorizadoTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} comprar={this.comprar} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales}/>
                          : item.idestatuscotizacion == 4 ? <CompraRealizadaTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cuantosdecimales={this.state.cuantosdecimales}/>
                            : item.idestatuscotizacion == 5 ? <RechazadoTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cambiarStatusDisponible={this.cambiarStatusDisponible} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales}/>
                              : item.idestatuscotizacion == 6 ? <CambioTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales}/>
                                : null
                ))
                : <div></div>
              }
            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla  Resumen compra  ////////////////////////////////////// */}
        <br />
        <br />
        {this.state.contenidorequisicion != undefined && this.state.contenidorequisicion.length > 0 ?
          <div className="container-fluid containerTabla ">
            <div className="container-fluid contenedorResumen">
              <div className='containerSubTablaAA divMinWidth'>
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaresumenCompra.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaresumenCompra.mindimensiones[index],
                            maxWidth: this.state.tablaresumenCompra.maxdimensiones[index],
                            width: this.state.tablaresumenCompra.dimensiones[index],
                            color: this.state.colorencabezadostabla
                          }}>
                          {index == 0 ? "Resumen" : null}
                        </th>
                      ))}
                    </tr>
                    <tr style={{ borderBottom: 'solid 2px #91b9f9' }}>
                      {this.state.tablaresumenCompra.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaresumenCompra.mindimensiones[index],
                            maxWidth: this.state.tablaresumenCompra.maxdimensiones[index],
                            width: this.state.tablaresumenCompra.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index == 4) ? 'center' : '',
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {(this.state.tablaresumenCompra.elementos.length > 0) ?
                      this.state.tablaresumenCompra.elementos.map((item, index) => (
                        <tr key={"tabla" + index} style={{ borderBottom: (index == this.state.tablaresumenCompra.elementos.length - 1) ? 'solid 2px #91b9f9' : '' }}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.idcotizacion_proveedor}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.proveedorcompra}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.fechacompra}
                          </td>
                          <td className={item.idestatuscompra == 5 ? "letrasRojas" : item.idestatuscompra == 4 || item.idestatuscompra == 3 ? "letrasVerdes" : item.idestatuscompra == 2 || item.idestatuscompra == 1 || item.idestatuscompra == 0 ? "letrasAzules" : "letraNormal lblSubtabla"} style={{ color: this.state.colorcontenidotabla }}>
                            {item.estatuscompra == "Compra realizada" ? <button className='btncomprasCotizaciones'>Compra realizada</button> : item.estatuscompra}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                            ${formatoNumeroTecla(item.total, this.state.cuantosdecimales)}
                          </td>
                        </tr>
                      )
                      ) : null
                    }
                    <tr>
                      <td className="lblEncabezadosTabla letraTitulos" colSpan={4} style={{ color: this.state.colorencabezadostabla }}>
                        <b>{"Total a pagar por requisición"}</b>
                      </td>

                      <td className="lblEncabezadosTabla letraTitulos" style={{ color: this.state.colorencabezadostabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                        <b>${formatoNumeroTecla(this.state.tablaresumenCompra.total, this.state.cuantosdecimales)}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          : null
        }
        {/* ////////////////////   Fin Sección Tabla  Resumen compra ////////////////////////////////////// */}



        <br />
        {
          <div className="divBtnContenedorRequisicion" >
            <Button className="btnRechazar" onClick={() => this.componentDidMount()}>Cancelar</Button>
            <Button className="btnAceptar" onClick={() => this.guardarCambios()} disabled={!this.state.estatuseditable}>Guardar</Button>
          </div>
        }
        <br />
      </>
    )
  }
}


function AccionesVista({ bandera }) {
  const history = useHistory();
  const location = useLocation();


  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'rectificarproductos') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/rectificaragroquimicos"} />)
  } else /*(bandera == 'goreferencias')*/ {
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)
  }
}
