import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoUsuarios.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { FaKey, FaCheck } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer } from '../../Services/PaletaDeColores';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import ModalConfirmarRestablecerContraseña from '../../Complements/Modales/ModalDetallesUsuario/ModalConfirmarRestablecerContraseña';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaCatalogoUsuarios, getComboAreasDeTrabajo, getListaPuestosDeTrabajo, getListaTipoUsuarios, setRestablecerUsuario, setEstatusUsuario } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class CatalogoUsuarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      loadingspinners: true,

      tabla: {
        encabezados: [
          "Nombre(s)",
          "Apellido(s)",
          "E-mail",
          "Teléfono",
          "Dirección",
          "Puesto",
          "Acceso",
          "Usuario",
          "Estatus",
          "",
          "",
          ""],
        dimensiones: [
          "10%",
          "10%",
          "15%",
          "10%",
          "40%",
          "10%",
          "5%",
          "10%",
          "5%",
          "15%",
          "180px",
          "150px"
        ],
        mindimensiones: [
          "130px",
          "80px",
          "75px",
          "60px",
          "300px",
          "100px",
          "85px",
          "80px",
          "50px",
          "50px",
          "150px",
          "120px",

        ],
        elementos: []
      },
      estatus: [],
      listaelementosfiltrados: [],
      areaseleccionado: "TodasLasAreas",
      puestoseleccionado: "TodosLosPuestos",
      listatipousuario: [],
      listaarea: [],
      listapuesto: [],
      listafiltropuesto: [],
      listafiltroarea: [],
      accionmodal: 1,
      titulomodal: '',
      modalformulariousuario: false,
      itemseleccionado: null,

      errordeacceso: false,
      idusuario: 0,
      modalrestablecercontraseña: false,
      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],


      //filtrar por usuarios con acceso 
      usuariosconacceso:false,
      usuariossinacceso:false,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
      colorbtnverde: estiloBtnVerde().colorbtnverde,
      colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

      colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
      colorbtnrojo: estiloBtnRojo().colorbtnrojo,
      colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

      colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
      colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
      colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    var resplistapuestos = await getListaPuestosDeTrabajo();
    if (this.manejadorLlamadasApis(resplistapuestos)) {
      return;
    }

    var resplistatipousuario = await getListaTipoUsuarios();
    if (this.manejadorLlamadasApis(resplistatipousuario)) {
      return;
    }

    var respcatalogousuarios = await getListaCatalogoUsuarios();
    if (this.manejadorLlamadasApis(respcatalogousuarios)) {
      return;
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = respcatalogousuarios.data;
    await this.setState({
      txtbuscador: '',
      tabla: tabla,
      estatus: estatus.data,
      listaarea: resplistaareas.data,
      listapuesto: resplistapuestos.data,
      listafiltropuesto: resplistapuestos.data,
      listatipousuario: resplistatipousuario.data
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({
      loadingspinners: true,
      txtbuscador: '',
      puestoseleccionado: "TodosLosPuestos",
      areaseleccionado: "TodasLasAreas",
      errordeacceso: false,
    })
    this.componentDidMount();
  }

  filtrarArea = async (itemfiltrado, accion) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      areaseleccionado: itemfiltrado
    })

    var filtroacceso=[];
    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];

    filtroacceso=data;
    if(this.state.usuariosconacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==1)
    } 

    if(this.state.usuariossinacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==0)
    } 


    filtroarea = filtroacceso;
    if (itemfiltrado != "TodasLasAreas") { // si item filtrado = áreaX

      var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == itemfiltrado)

      await this.setState({
        listafiltropuesto: resplistapuestos,
        //txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }

      filtroarea = filtroacceso.filter((item) => item.idarea == itemfiltrado)
    } else { // si item filtrado == todas las áreas 
      await this.setState({
        listafiltropuesto: this.state.listapuesto,
        //txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }
    }

    if (this.state.puestoseleccionado != "TodosLosPuestos") { // si puesto = puuestoX

      filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
    } else { // si puesto = todos los puestos 
      filtropuesto = filtroarea;
    }

    if (this.state.txtbuscador.length >= 3) { // si buscador == algo
      filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else { // sin buscador
      filtrofinal = filtropuesto;
      await this.setState({
        txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

   
    this.filtro(1, filtrofinal)
  }

  filtrarPuesto = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      puestoseleccionado: itemfiltrado
    })

    var filtroacceso=[];
    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];

    filtroacceso=data;
    if(this.state.usuariosconacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==1)
    }  
    if(this.state.usuariossinacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==0)
    } 

    filtroarea = filtroacceso;
    if (this.state.areaseleccionado != "TodasLasAreas") {

      filtroarea = filtroacceso.filter((item) => item.idarea == this.state.areaseleccionado)
    }

    filtropuesto = filtroarea;
    if (itemfiltrado != "TodosLosPuestos") {

      filtropuesto = filtroarea.filter((item) => item.idpuesto == itemfiltrado)
    }

    if (this.state.txtbuscador.length >= 3) {
      filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellidos.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.usuario.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else {
      filtrofinal = filtropuesto;
      await this.setState({
        //txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrofinal)

  }

  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value });


    
    var filtroacceso=[];
    var filtropuesto = [];
    var filtroarea = [];
    var filtrofinal = [];

    if (tamanocadenain >= 3 || banderacasosespeciales) {
      

    filtroacceso=data;
    if(this.state.usuariosconacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==1)
    }  
    if(this.state.usuariossinacceso==true){
      filtroacceso= data.filter((item) =>item.acceso==0)
    } 

      filtroarea = filtroacceso;
      if (this.state.areaseleccionado != "TodasLasAreas") {
        filtroarea = filtroacceso.filter((item) => item.idarea == this.state.areaseleccionado)
      }
      filtropuesto = filtroarea;

      if (this.state.puestoseleccionado != "TodosLosPuestos") {
        filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
      } else { filtropuesto = filtroarea; }

      filtrofinal = filtropuesto;
      if (e.target.value.toLocaleLowerCase().length >= 3) {
        filtrofinal = filtropuesto.filter((item) => item.nombres.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
          || item.apellidos.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
          || item.usuario.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      }
      if (filtrofinal.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtrofinal)

    }

  }

  setEstatusUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios,
      "idestatus": ban.estatus
    }
    this.activarSpinner(true);
    var resp = await setEstatusUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    }
    else {
      this.activarSpinner(false);
      this.actualizarTabla();

    }
  }

  restablecerUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios
    }
    this.activarSpinner(true);
    var resp = await setRestablecerUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    } else {
      this.activarSpinner(false);
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo usuario', accionmodal: 1, modalformulariousuario: true })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar usuario', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modalformulariousuario: true });
    }
    else if (ban.opcion == 4) { //restablecer contraseña
      let nombre = ban.contenido.nombres + " " + ban.contenido.apellidos;

      this.setState({ titulomodal: 'Restablecer contraseña', modalrestablecercontraseña: true, txtnombre: nombre, idusuario: ban.contenido.idcatalogousuarios });
    }


  }

  cerrarModal = (accion) => {

    this.setState({ modalformulariousuario: !this.state.modalformulariousuario })
    if (accion == 1) {
      toast.success("Usuario creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Usuario editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }

  }

  cerrarModalRestablecerContraseña = async (accion, id) => {

    this.setState({ modalrestablecercontraseña: !this.state.modalrestablecercontraseña })
    if (accion == 1) {
      this.setState({ loadingspinners: true });

      let json = {
        "idcatalogousuarios": id
      }

      var resp = await setRestablecerUsuario(json);

      if (resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setState({ loadingspinners: false });
        return;
      }
      else if (resp.code == 200 || resp.code == 300) {
        this.setState({ loadingspinners: false });
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp === -1) {
        this.setState({ loadingspinners: false });
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      } else {
        this.setState({ loadingspinners: false });
        toast.success("Contraseña restablecida exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
  }

  filtro = (pagenumber, array) => {
    
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 10);

  }

  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber,
      listapaginada: [],
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.listaelementosfiltrados),
      });
    }, 0);

  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
    })
    this.filtrarArea(this.state.areaseleccionado, 1)
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }

  redireccionar = (idusuario) => {
    localStorage.setItem("idusuario", idusuario);
    this.setState({ redireccionando: true })
  }



  filtroPorAcceso  =  () =>{
    
    this.setState({ usuariosconacceso: !this.state.usuariosconacceso, usuariossinacceso:false, })

    //actualizar la lista de datos   
      this.filtrarArea(this.state.areaseleccionado, 1)
  
  }

  filtroSinAcceso  =  () =>{
    
    this.setState({ usuariossinacceso: !this.state.usuariossinacceso, usuariosconacceso:false })

    //actualizar la lista de datos   
      this.filtrarArea(this.state.areaseleccionado, 1)
  
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ?
          <Acciones bandera={'detalles'} />
          : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalformulariousuario == true) ? (
          <ModalFormularioUsuarios titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modalformulariousuario} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            activarSpinner={this.activarSpinner}
            listaarea={JSON.parse(JSON.stringify(this.state.listaarea))}
            listapuesto={JSON.parse(JSON.stringify(this.state.listapuesto))}
            listatipousuario={JSON.parse(JSON.stringify(this.state.listatipousuario))}

          />
        ) : null}

        {(this.state.modalrestablecercontraseña == true) ?
          <ModalConfirmarRestablecerContraseña
            show={this.state.modalrestablecercontraseña}
            titulomodal={this.state.titulomodal}
            nombres={this.state.txtnombre}
            idusuario={this.state.idusuario}
            cerrarmodal={this.cerrarModalRestablecerContraseña}
            setLoadingSpinner={this.activarSpinner}
          />
          : null}
        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Usuarios</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Área</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarArea(event.target.value, 0)}>
                      {this.state.areaseleccionado == "TodasLasAreas" ?
                        <option selected="true" value='TodasLasAreas'>Todas</option> : <option value='TodasLasAreas'>Todas</option>}
                      {this.state.listaarea.map((i, index) => (
                        <option value={i.idcatalogo_areas} key={"tipo_" + index}  >{i.area} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Puesto</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarPuesto(event.target.value)}>
                      {this.state.puestoseleccionado == "TodosLosPuestos" ?
                        <option selected="true" value='TodosLosPuestos'>Todos</option> : <option value='TodosLosPuestos'>Todos</option>}
                      {this.state.listafiltropuesto.map((i, index) => (
                        <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar perfil"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className="modalDetallesCheck" onClick={ this.filtroPorAcceso}>
                  <input type="checkbox" checked={this.state.usuariosconacceso} /> Solo usuarios con acceso
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className="modalDetallesCheck" onClick={ this.filtroSinAcceso}>
                  <input type="checkbox" checked={this.state.usuariossinacceso} /> Solo usuarios sin acceso
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoTabCultivo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3
                    && this.state.puestoseleccionado == 'TodosLosPuestos'
                    && this.state.areaseleccionado == 'TodasLasAreas' && !this.state.usuariosconacceso && !this.state.usuariossinacceso ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombres}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.apellidos}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.email}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.telefono}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                          <>
                            {
                              item.calle + ", núm. exterior: " +
                              item.numeroexterior + ", núm. interior: " +
                              item.numerointerior + ", " +
                              item.colonia + ", " +
                              item.codigopostal + ", " +
                              item.localidad + ", " +
                              item.municipio + ", " +
                              item.estado + ", " +
                              item.pais
                            }

                          </>
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.puesto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.acceso ?
                            <div className='iconoAcceso' >
                              <FaCheck className="icoBotones" style={{
                                color: this.state.btncoloreditar
                              }} />
                            </div>
                            : null
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.usuario}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEstandar btnBase' onClick={this.setEstatusUsuario.bind(this, { idcatalogousuarios: item.idcatalogousuarios, estatus: item.idestatus == 1 ? 2 : 1 })} style={{
                            backgroundColor: item.estatus == "Activo" ? this.state.colorfondobtnverde : this.state.colorfondobtnrojo,
                            color: item.estatus == "Activo" ? this.state.colorbtnverde : this.state.colorbtnrojo,
                            border: item.estatus == "Activo" ? this.state.colorbordebtnverde : this.state.colorbordebtnrojo
                          }}> {item.estatus}</button>

                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          {item.acceso ?
                            <button className='btnEstandar btnRestablecer' onClick={this.verModalDetalles.bind(this, { opcion: "4", contenido: item })} style={{
                              backgroundColor: this.state.colorfondobtnrestablecer, color: this.state.btncoloreditar, border: this.state.colorbordebtnrestablecer
                            }}><FaKey className="icoBotones" /> Restablecer</button>
                            : null
                          }

                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.redireccionar.bind(this, item.idcatalogousuarios)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}>Ver detalles</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombres}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.apellidos}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.email}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.telefono}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          <>
                            {
                              item.calle + ", núm. exterior: " +
                              item.numeroexterior + ", núm. interior: " +
                              item.numerointerior + ", " +
                              item.colonia + ", " +
                              item.codigopostal + ", " +
                              item.localidad + ", " +
                              item.municipio + ", " +
                              item.estado + ", " +
                              item.pais
                            }

                          </>
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.puesto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.acceso ?
                            <div className='iconoAcceso' >
                              <FaCheck className="icoBotones" style={{
                                color: this.state.btncoloreditar
                              }} />
                            </div>
                            : null
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.usuario}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEstandar btnBase' onClick={this.setEstatusUsuario.bind(this, { idcatalogousuarios: item.idcatalogousuarios, estatus: item.idestatus == 1 ? 2 : 1 })} style={{
                            backgroundColor: item.estatus == "Activo" ? this.state.colorfondobtnverde : this.state.colorfondobtnrojo,
                            color: item.estatus == "Activo" ? this.state.colorbtnverde : this.state.colorbtnrojo,
                            border: item.estatus == "Activo" ? this.state.colorbordebtnverde : this.state.colorbordebtnrojo
                          }}> {item.estatus}</button>

                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          {item.acceso ?
                            <button className='btnEstandar btnRestablecer' onClick={this.verModalDetalles.bind(this, { opcion: "4", contenido: item })} style={{
                              backgroundColor: this.state.colorfondobtnrestablecer, color: this.state.btncoloreditar, border: this.state.colorbordebtnrestablecer
                            }}><FaKey className="icoBotones" /> Restablecer</button>
                            : null
                          }
                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.redireccionar.bind(this, item.idcatalogousuarios)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}>Ver detalles</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Paginacion   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.txtbuscador.length < 3
              && this.state.puestoseleccionado == 'TodosLosPuestos'
              && this.state.areaseleccionado == 'TodasLasAreas' && !this.state.usuariosconacceso && !this.state.usuariossinacceso ? this.state.tabla.elementos : this.state.listaelementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
        {/* ////////////////////   Fin Sección Paginacion   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {
  if (bandera == 'detalles') {
    return (<Redirect from='/' to={"/detallesusuario"} />)
  }
  else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}