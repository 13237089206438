import React from 'react'
import './EditarRequisicion.css';
import './CatalogoNuevaRequisicion.css';
import {estiloBtnActualizar, estiloBtnEditar, estiloTabla} from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalAgregarProductoCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalAgregarProductoCotizacion';

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,

      tabla: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          ""],
        dimensiones: [
          "25%",
          "20%",
          "15%",
          "10%"
        ],
        mindimensiones: [
          "130px",
          "180px",
          "120px",
          "100px",
        ],
        elementos: []
      },

      //algo asi entregara el arreglo de la api informacion
      datosDummy: this.props.productos,

      actualizartablalocal:false,

      listaimcompletos: [],
      listadetenidos: [],

      todoslosproductosalmacen: true,
      listaproductosalmacen: [],
      listaproductosResumen: this.props.listaprodresumen,

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,
      originales: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    //Se asigna atributos auxiliares
    await this.procesarProductos();

    //Se validan datos incompletos para marcar fila de tabla en rojo
    this.actualizarTabla();   

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    //validaremos si los datos estan incompletos para pintar la fila en rojo
    var aux = []
    this.state.datosDummy.map((item, index) => {
      if(item.Producto.length<0 || item.Producto=="" || item.cantidad<=0 || item.cantidad==""  || item.Unidadmedida.length<0 || item.Unidadmedida==""){
        //guardamos el indice del elemento con datos incompletos
        aux.push(index)
      }
    })

    this.generarListaProductosGuardar();

    //a estos indices se le colocara la fila en rojo
    await this.setState({listaimcompletos: aux})

  }

  generarListaProductosGuardar = async () => {

    //llamar función de actualización general
    this.props.actualizarContenido(this.state.datosDummy);

  }

  cerrarModalNuevoProducto = async (itemseleccionado) => {

    //si en la modal de nuevo le dio en guardar y no cancel, agregamos el json a la tabla
    if(itemseleccionado!=0 && itemseleccionado.length > 0){
      var productosseleccionado = false;
      var concatenarprod = this.state.datosDummy;

      itemseleccionado.map((p1) => {
        var prodagregado = false;
        var indexproducto = concatenarprod.findIndex((item => item.idproducto==p1.idproducto && item.sustituido!=true))
        if(indexproducto > -1){
          prodagregado = true;
        }
        /*concatenarprod.forEach((p2) => {
            if (p2.idproducto == p1.idproducto && p2.sustituido!=true) {
                prodagregado = true;
            }
        })*/

        if (!prodagregado) {
            let nuevopd = {
              Idunidadmedida: p1.idunidadmedida,
              Producto: p1.producto,
              Unidadmedida: p1.unidadmedida,
              cantidad: 0,
              eliminar: false,
              sustituido: false,
              idproducto: p1.idproducto,
              cantidadStatic: p1.cantidad
            }
            this.state.datosDummy.push(nuevopd)
            productosseleccionado = true
        }else{
          toast.error("Ya existe el producto en la lista", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      })

      if(productosseleccionado){
          var aux = this.state.datosDummy;
          await this.setState({ datosDummy: aux})
          await this.actualizarTabla();
      }
    } 

    this.setState({ modalnuevoproducto: !this.state.modalnuevoproducto})
  }

  //al precionar el boton agregar
  agregarElemento = async (ban) => {

      this.setState({ titulomodal: 'Nuevo producto', accionmodal: 1, modalnuevoproducto: true})
    
  }

  escribirCantidadPrecio = async (index, prop, event) => {
   var longitud = {enterosMinimo: 1, enterosMaximos: 7, decimalesMinimo: 0, decimalesMaximo: 3};
   
   if(parseFloat(event.target.value)>this.state.datosDummy[index]["cantidadStatic"]){
    toast.error("La cantidad supera los disponibles: "+this.state.datosDummy[index]["cantidadStatic"], { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
   }else{
    //actualizamos los valores dinamicamente de la fila en modo edicion
        
    this.state.datosDummy[index][prop] = event.target.value;
    
    var dummy = this.state.datosDummy
      
    this.setState({ datosDummy: dummy });

    //validamos datos incompletos
    this.actualizarTabla();
   }
  };

  eliminarElemento = async (elemento) => {
    var aux = this.state.datosDummy.filter((item, ind) => (((item.idproducto!=elemento.item.idproducto) || (item.idproducto==elemento.item.idproducto && item.sustituido==true) ) ));
    
    this.setState({datosDummy:aux,
      actualizartablalocal:true
    })

    setTimeout( () => {
       this.actualizarTabla();
    }, 200);
   
  }

  procesarProductos = async() => {
     
    var sonoriginales = true;

    this.props.productos.map((pd)=>{
      if(this.state.listaproductosResumen){
        var aux = this.state.listaproductosResumen.filter((item) => item.idproducto==pd.idproducto)
        if(aux.length > 0){
          if(aux[0].sustitutode=="Sustituido"){
            pd.sustituido = true;
            sonoriginales = false;
          }else if(aux[0].sustitutode=="" && (aux[0].sustituir=="0" || aux[0].sustituir=="")){
            pd.original = true;
            pd.sustituido = false;
            pd.cantidadStatic = parseFloat(aux[0].cantidad)
            pd.cantidadVariable = parseFloat(aux[0].cantidad)
          }else{
            sonoriginales = false;
            pd.sustituido = false;
            pd.cantidadStatic = parseFloat(aux[0].cantidad)
            pd.cantidadVariable = parseFloat(aux[0].cantidad)
          }
        }
      }      
    })

    var respaldoProds = this.props.productos;

    this.setState({originales: sonoriginales, datosDummy: respaldoProds})  
  }

  render() {
    return (

      <>
        {(this.state.modalnuevoproducto == true) ? (
                    <ModalAgregarProductoCotizacion
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalNuevoProducto}
                        show={this.state.modalnuevoproducto}
                        accion={this.state.accionmodal}
                        listaproductos={this.state.listaproductosResumen}
                        productostarjeta={this.props.productos} />
                ) : null}

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <div className='row justify-content-center' >
              <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                  
                <Table hover>
                    <thead style={{ verticalAlign: "bottom" }}>
                        <tr>
                          {this.state.tabla.encabezados.map((titulo, index) => (
                            <th key={"tabla" + index} className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                              style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                              {titulo}
                            </th>
                          ))}
                        </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.datosDummy.map((item, index) => (
                        <tr key={index} className={item.sustituido ? "sustituidoFila" : item.detener ? "trFondoDetener" : this.state.listaimcompletos.indexOf(index) != -1 ? "trFondoIncompleto" : ""} >
                          <td key={"col-"+index+"-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.Producto}
                          </td>
                          <td key={"col-"+index+"-02"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                            {item.Unidadmedida}
                          </td>
                          <td key={"col-"+index+"-03"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                            {item.sustituido ? item.cantidad : item.original ? item.cantidadStatic : <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla text-center" value={item.cantidad} onChange={(e) => this.escribirCantidadPrecio(index, 'cantidad', e)}></input>}
                          </td>
                          
                          <td key={"col-"+index+"-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.sustituido ? "Anterior" 
                            : item.original ? "Original"
                            :
                            <div className="modalDetallesCheck" >
                              <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, {item, index})}
                              ><FaTrashAlt className="icoBotones" /></button>
                            </div>
                            }
                          </td>
                        </tr>
                      
                      ))}
                    </tbody>
                </Table>

                {!this.state.originales ? 
                  <div className='row paddingEncabezados' style={{ marginBottom: "6px", padding: "8px" }}>
                      <div className="col-auto">
                        <button className='btnActualizar letraNormal' onClick={this.agregarElemento.bind(this, { opcion: "1" })}
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                          >+ Agregar</button>
                      </div>
                  </div>
                : null}
                

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
