import React from 'react'
import './CatalogoNuevaRequisicion.css';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box } from '@material-ui/core';
import { estiloBtnActualizar, estilosModal } from '../../Services/PaletaDeColores';
import Lote from './Lote';
import 'react-toastify/dist/ReactToastify.css';


export default class Rancho extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      accion:  props.accion,
      titulomodal: "",

      rancho: this.props.itemrancho,

      colapsartabla:true,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();    

    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarContenido = (lote) => {
    var listalotes = this.props.itemrancho.lotes;

    var index =  listalotes.findIndex((obj => obj.idlote == lote.idlote));
    if(index > -1){
      listalotes[index] = lote;
    }
    var auxrancho = this.state.rancho;
    auxrancho.lotes = listalotes;

    this.props.actualizarContenido(auxrancho);
  }

  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var lotes =  this.props.itemrancho.lotes.filter(lote => lote.idlote != elementoeliminado.idlote);
    
    var auxrancho = this.state.rancho;
    auxrancho.lotes = lotes;

    this.props.actualizarContenido(auxrancho);
  }

  render() {
    return (

      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                            <p className='sinMargen'><b>Rancho:</b>{this.props.itemrancho.rancho}</p>
                            </div>
                          </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemrancho.lotes.length > 0 ?
                              this.props.itemrancho.lotes.map((item) => (
                                <div className='spacePaddingBottom'>
                                <Lote key={"lote_" + item.idlote} 
                                  accion={this.state.accion} 
                                  itemlote={item}
                                  actualizarContenido={this.actualizarContenido}
                                  eliminarElemento={this.eliminarElemento}
                                  listatablas={this.props.listatablas}
                                  listaprodresumen={this.props.listaprodresumen}
                                 />
                                </div>
                              ))
                              : <div></div>
                            }
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}

