import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioEditarRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

const sinseleccionar = -1

export default class ModalFormularioNuevoProductoRequisicion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      loadingspinners: false,

      txtnombreproducto: '',
      msgerrornombreproducto: "",
      vermsgerrornombreproducto: false,

      txtunidadmedida: '',
      msgerrorunidadmedida: "",
      vermsgerrorunidadmedida: false,

      idunidadmedida: sinseleccionar,
      listaunidadmedida: props.listaunidadmedida,

      //idestatus: 1,
      //msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

    }

  }
  async componentDidMount() {

  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {

    if (prop == "txtunidadmedida") {
      var unidadmedida = this.state.listaunidadmedida.filter((item) => item.idcatalogo_unidadmedida == event.target.value)

      this.setState({
        txtunidadmedida: unidadmedida[0].clave,
        idunidadmedida: event.target.value
      });
    } else {
      this.setState({ [prop]: event.target.value });
    }

  }

  /*cambiarValor = (id) => {
    var array = this.state.arrayestatus;
    array.forEach(function (item) {
      item.activo = false;
      if (item.idestatus == id) {
        item.activo = true;

      }
    });
    this.setState({ idestatus: id })
  }*/

  guardarCambios = async () => {

    this.setState({ vermsgerrornombreproducto: false })
    this.setState({ vermsgerrorunidadmedida: false })

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
    let tipocorrecto = validarLetrasNumerosLongitud(this.state.txtnombreproducto.trim(), longitudvalida);
    let validacionescorrectas = true
    if (tipocorrecto.codigo == "invalid") {
      this.setState({ msgerrornombreproducto: tipocorrecto.mensaje })
      this.setState({ vermsgerrornombreproducto: true })
      validacionescorrectas = false;
    }

    if (this.state.txtunidadmedida == "") {
      validacionescorrectas = false;
      this.setState({ msgerrorunidadmedida: "Seleccione una unidad de medida" })
      this.setState({ vermsgerrorunidadmedida: true })
    }

    if (validacionescorrectas) {
      let json = {
        "idcatalogo_producto_general": "nuevo-" + (this.props.cantidadproductosnuevos + 1),
        "nombrecomercial": this.state.txtnombreproducto,
        "idcatalogo_unidadmedida": this.state.idunidadmedida,
        "claveunidadmedida": this.state.txtunidadmedida,
      }
console.log(json)
      this.showModal(json)
    }
  }

  render() {
    return (
      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre del producto</p>
                {this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnombreproducto} onChange={this.escribir("txtnombreproducto")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombreproducto}</label>}

                {this.state.vermsgerrornombreproducto ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombreproducto}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Unidad de medida</p>
                {this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={this.escribir("txtunidadmedida")}>
                          <option disabled="disabled" value="" selected="true">Seleccionar</option>
                          {this.state.listaunidadmedida.map((i, index) => (
                            <option value={i.idcatalogo_unidadmedida} key={"tipo_" + index}>{i.clave}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}


                {this.state.vermsgerrorunidadmedida ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrorunidadmedida}</p> : null}
              </div>
            </div>

            {this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}
          </ModalBody>
        </Modal>
      </>
    )
  }
}

