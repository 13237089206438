import React from 'react'
import { Button, Collapse, Modal, ModalBody, Table } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare, BsTrash } from 'react-icons/bs';
import "./ModalMovimiento.css";

import { estiloBtnNuevo, estiloColorError, estiloComboBox, estilosModal, estiloContenedores } from '../../../Services/PaletaDeColores';
import SpinnerModal from "../SpinnerModal/SpinnerModal";
import ReactTooltip from "react-tooltip";
import {
    getListaTipoMovimientoInventario, getListaMovimientoInventario,
    guardarMoverProductosActivos,
    getListaProductosGenerales,
    getListaTipoProductosGeneral, getComboAreasDeTrabajo, getListaRanchosDisponibles, getListaZonas
} from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiSearchAlt2 } from 'react-icons/bi';
import ModalFormularioBuscarProductos from '../ModalFormularioInventarioAlmacen/ModalFormularioBuscarProductos';

export default class ModalMovimientoActivos extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            loadingspinners: false,
            modaldetalles: false,
            accion: 1, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            arrayestatus: props.estatus,
            arraytipomovimiento: [

                {
                    idtipo: 2,
                    tipo: "Egreso"
                }
            ],

            arraymovimiento: [

                {
                    id_movimientos_inventario: 3,
                    nombre: "Movimiento entre almacenes",
                    tipo: 2,
                    estatus: 1
                },

            ],


            tabla: {
                encabezados: this.props.encabezados,
                dimensiones: this.props.dimensiones,
                mindimensiones: this.props.mindimensiones,
                elementos: [],
            },
            infoalmacen: JSON.parse(JSON.stringify(this.props.datosinventarioalmacen)),
            listaproductosalmacen: this.props.productos,
            todoslosproductosalmacen: true,
            listaalmacen: [],
            filtrolistapaginada: [],
            txtbuscador: '',
            idtipomovimiento: -1,
            idmovimiento: -1,
            vermsgerroridtipomovimiento: false,
            msgerroridtipomovimiento: '',
            vermsgerroridmovimiento: false,
            msgerroridmovimiento: '',
            vermsgerroralmacendestino: false,
            msgerroralmacendestino: '',
            btntexto: 'Buscar',

            tipoorigen: -1,
            zonaorigen: -1,
            origen: (this.props.datosinventarioalmacen.tipoubicacion == 1) ? (this.props.datosinventarioalmacen.idarea) : (this.props.datosinventarioalmacen.idrancho),
            tipodestino: -1,
            zonadestino: -1,
            destino: -1,

            listaalmacenareas: [],
            listaalmacenranchos: [],

            destino: -1,
            txtcomentario: "Este es un comentario default",

            respfamiliaproductos: [],
            resptipoproductos: [],
            respproductos: this.props.productos,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
            borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

            bordeinputcomentario: estiloContenedores().colorbordenegro,

            resptipoproductosgenerales: [],
        }


    }
    async componentDidMount() {
        this.setState({ loadingspinners: true })

        var resptipomovimientos = await getListaTipoMovimientoInventario();
        if (this.manejadorLlamadasApis(resptipomovimientos)) {
            return;
        }

        var respmovimientos = await getListaMovimientoInventario();
        if (this.manejadorLlamadasApis(respmovimientos)) {
            return;
        }

        var respareas = await getComboAreasDeTrabajo();
        if (this.manejadorLlamadasApis(respareas)) {
            return;
        }
        var respranchos = await getListaRanchosDisponibles();
        if (this.manejadorLlamadasApis(respareas)) {
            return;
        }

        var respzonas = await getListaZonas();
        if (this.manejadorLlamadasApis(respareas)) {
            return;
        }

        await this.apisproductos();
        await this.setState({
            
            loadingspinners: false,
            arraytipomovimiento: resptipomovimientos.data,
            arraymovimiento: respmovimientos.data,
            listaalmacenareas: respareas.data,
            listaalmacenranchos: respranchos.data,
            listaalmacenzonas: respzonas.data
        })
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    eliminar = (pos) => {
        var tablainfoalmacen = this.state.tabla;
        var array = tablainfoalmacen.elementos;
        array.splice(pos, 1);

        var tabla = this.state.tabla;
        tabla.elementos = array;
        this.setState({ tabla: tabla })
    };

    cambiarTipo = async (id) => {
        var array = this.state.arraytipomovimiento;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idtipo == id) {
                item.activo = true;
            }
        });

        if (id == 2) {
            await this.setState({ todoslosproductosalmacen: false })
        } else {
            await this.setState({ todoslosproductosalmacen: true })
        }

        var tabla = this.state.tabla;
        tabla.elementos = [];
        this.setState({ tabla: tabla })

        await this.setState({ idtipomovimiento: id })
        this.cambiarMovimiento(-1);

    }

    cambiarMovimiento = (id) => {
        var array = this.state.arraymovimiento;
        array.forEach(function (item) {
            item.activo = false;
            if (item.id_movimientos_inventario == id) {
                item.activo = true;
            }
        });

        if (this.state.idtipomovimiento == 1 && id == 2) {
            var tabla = this.state.tabla;
            tabla.elementos = [];
            this.setState({ tabla: tabla })
            this.setState({ btntexto: "Requisición" })
        }
        else {
            this.setState({ btntexto: "Buscar" })
        }
        this.setState({ idmovimiento: id, destino: -1 })
    }

    guardarCambios = async () => {
        this.setState({ vermsgerroridmovimiento: false })
        this.setState({ vermsgerroridtipomovimiento: false })
        this.setState({ vermsgerroralmacendestino: false })
        let validacionescorrectas = true
        if (this.state.idtipomovimiento == -1) {
            this.setState({ msgerroridtipomovimiento: "Seleccione un tipo de movimiento" })
            this.setState({ vermsgerroridtipomovimiento: true })
            validacionescorrectas = false;
        }

        if (this.state.idmovimiento == -1) {
            this.setState({ msgerroridmovimiento: "Seleccione un movimiento" })
            this.setState({ vermsgerroridmovimiento: true })
            validacionescorrectas = false;
        } else if (this.state.idmovimiento == 3) {
            if (this.state.destino == -1) {
                this.setState({ msgerroralmacendestino: "Seleccione un destino" })
                this.setState({ vermsgerroralmacendestino: true })
                validacionescorrectas = false;
            }
        }

        var arryatemp = this.state.tabla;
        var temp = arryatemp.elementos;
        let datoscero = 0;
        for (let i = 0; i < temp.length; i++) {
            temp[i].fondo = "white";
            if (temp[i].existencias == undefined || temp[i].existencias <= 0) {
                temp[i].fondo = "#fa9393";
                validacionescorrectas = false;
                datoscero = 1;

            }
        }

        if (datoscero == 1) {
            toast.error("Escriba una cantida mayor a cero", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        if (validacionescorrectas) {
            
            this.props.activarSpinner(true);
            var productos = [];
            let jsonfile1 = {
                "idcatalogoalmacen": this.state.infoalmacen.idcatalogoalmacen,
                "id_movimientos_inventario": this.state.idmovimiento,
                "productos": [],
            };
            let jsonfile2 = {
                "tipoorigen": this.props.datosinventarioalmacen.tipoubicacion,
                "idorigen": (this.props.datosinventarioalmacen.tipoubicacion == 1) ? (this.props.datosinventarioalmacen.idarea) : (this.props.datosinventarioalmacen.idrancho),
                "id_movimientos_inventario": this.state.idmovimiento,
                "tipodestino": this.state.tipodestino,
                "iddestino": this.state.destino,
                "productos": [],
            };
            await this.state.tabla.elementos.forEach(async item => {
                
                let jsonproduct =
                {
                    "idproducto": item.idproducto,
                    "cantidad": item.existencias,
                }
                    ;
                productos.push(jsonproduct);
            });
            jsonfile1.productos = productos;
            jsonfile2.productos = productos;
            var jsonfile = this.state.idmovimiento != 3 ? jsonfile1 : jsonfile2;

            var resp = await guardarMoverProductosActivos(jsonfile, this.state.idmovimiento);

            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.props.activarSpinner(false);
                return true
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true
            }
            else {
                toast.success("Movimiento realizado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal('1')
            }
        }
    }

    cerrarModal = (ban) => {
        if (ban == 0 || ban == undefined) {
            this.setState({ modaldetalles: !this.state.modaldetalles })
        }
        else {
            var tabla = this.state.tabla;
            
            var productoalmacen = tabla.elementos.filter((item) => item.idproducto == ban.idcatalogo_producto_general);

            if (productoalmacen.length > 0) {
                toast.info("Este producto ya está en la lista", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            } else {
                tabla.elementos.push(
                    {
                        "idproducto": ban.idcatalogo_producto_general,
                        "clave": ban.clave,
                        "idcatalogoproducto": ban.idcatalogoproducto,
                        "nombrecomercial": ban.nombrecomercial,
                        "idtipoproducto": ban.idtipoproducto,
                        "tipoproducto": ban.tipoproducto,
                        "idcatalogo_unidadmedida": ban.idcatalogo_unidadmedida,
                        "claveunidadmedida": ban.claveunidadmedida,
                        "unidadmedida": ban.unidadmedida,
                        "existencias": "",
                        "tipoubicacion": ban.tipoubicacion,
                        "idzona": ban.idzona,
                        "zona": ban.zona,
                        "idrancho": ban.idrancho,
                        "rancho": ban.rancho,
                        "idarea": ban.idarea,
                        "area": ban.area
                    }
                )
                this.setState({ tabla: tabla })
            }
            this.setState({ modaldetalles: !this.state.modaldetalles })
        }
    }

    vermodal = () => {
        this.setState({ vermsgerroridtipomovimiento: false })

        if (this.state.idtipomovimiento == -1) {
            this.setState({ msgerroridtipomovimiento: "Seleccione un tipo de movimiento" })
            this.setState({ vermsgerroridtipomovimiento: true })
            return;
        }
        this.setState({ modaldetalles: !this.state.modaldetalles });
    }


    changecanti = async (e, disponibles) => {
        var nueva = parseFloat(e.target.value);
        var valdisponible = parseFloat(disponibles);

        if (valdisponible == 0 && nueva >= valdisponible ||
            valdisponible > 0 && nueva > valdisponible) {

            toast.error("La cantidad supera los productos disponibles: " + valdisponible,
                { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            var arryatemp = this.state.tabla;
            var temp = arryatemp.elementos;
            temp[e.target.id].existencias = "";
            this.setState({ tabla: arryatemp })
        } else {
            if (e.target.value.trim().length < 10) {
                var arryatemp = this.state.tabla;
                var temp = arryatemp.elementos;
                temp[e.target.id].existencias = e.target.value;
                this.setState({ tabla: arryatemp })
            }
        }
    }


    apisproductos = async () => {
        if (this.state.resptipoproductosgenerales.length == 0 || this.state.respproductos.length == 0) {
            var resptipoproductosgenerales = await getListaTipoProductosGeneral();
            if (this.manejadorLlamadasApis(resptipoproductosgenerales)) {
                return;
            }
            var respproductos = await getListaProductosGenerales();
            if (this.manejadorLlamadasApis(respproductos)) {
                return;
            }
            this.setState({
                respproductos: respproductos.data,
                resptipoproductosgenerales: resptipoproductosgenerales.data
            })
        }
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };


    elegirorigen = (datos) => {
        if (datos.tipo == "tipodestino") {
            this.setState({ tipodestino: parseInt(datos.opcion), zonadestino: -1, destino: -1 })
        }
        if (datos.tipo == "destinozonas") {
            this.setState({ zonadestino: datos.opcion, destino: -1 })
        }
        if (datos.tipo == "destinoareas" || datos.tipo == "destinosranchos") {
            this.setState({ destino: datos.opcion, })
        }
    }


    filtrarproductosorigen(origen) {

        var lista = this.props.productos.filter(item => item.tipoubicacion == this.state.tipoorigen);
        var filtroarea = lista.filter(item => parseInt(item.idarea) == origen);
        var filtrorancho = lista.filter(item => parseInt(item.idrancho) == origen);

        if (this.state.tipoorigen == 1) {
            this.setState({ respproductos: filtroarea });
        } else if (this.state.tipoorigen == 2) {
            this.setState({ respproductos: filtrorancho });
        }
    }

    render() {
        return (

            <>
                {(this.state.modaldetalles == true) ? (
                    <ModalFormularioBuscarProductos
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        todoslosproductosalmacen={this.state.todoslosproductosalmacen}
                        listaproductosalmacen={this.props.productos}

                        show={this.state.modaldetalles}
                        accion={this.state.accionmodal}
                        item={this.state.itemseleccionado}
                        respfamiliaproductos={this.state.respfamiliaproductos}
                        resptipoproductos={this.state.resptipoproductos}
                        respproductos={this.state.respproductos}

                        origen={this.props.origen}
                        resptipoproductosgenerales={this.state.resptipoproductosgenerales}
                    />
                ) : null}

                <ToastContainer />
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>

                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }} >

                                <p className='lblSubTitulosModal letraTitulos'>¿Qué movimiento quieres hacer?</p>

                                <div className='row'>

                                    <div className='col-4'>

                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                            Tipo de movimiento:</p>

                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraNormal"
                                                        onChange={(event) => this.cambiarTipo(event.target.value)}>
                                                        <option value={-1} key={"tipo_x"} selected={-1 == this.state.idtipomovimiento} >Seleccionar </option>
                                                        {this.state.arraytipomovimiento.map((i, index) => (
                                                            (i.idtipo == 2) ? (
                                                                <option value={i.idtipo} key={"tipo" + index} selected={i.activo} >{i.tipo}</option>
                                                            ) : null

                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.vermsgerroridtipomovimiento ? <p className="msgError "
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipomovimiento}</p> : null}
                                    </div>

                                    <div className='col-1'></div>
                                    <div className='col-4'>

                                        <p className='lblSubTitulosModal letraTitulos'
                                            style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Movimiento:</p>

                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraNormal"
                                                        onChange={(event) => this.cambiarMovimiento(event.target.value)}>
                                                        {this.state.idtipomovimiento == -1 ?
                                                            <option value={-1} key={"tipo_x"} selected={-1 == this.state.idmovimiento} >Seleccionar </option>
                                                            :
                                                            <>
                                                                <option value={-1} key={"tipo_x"} selected={-1 == this.state.idmovimiento} >Seleccionar</option>
                                                                {this.state.arraymovimiento.map((i, index) => (
                                                                    <>{i.idtipo == this.state.idtipomovimiento && i.id_movimientos_inventario == 3 ?
                                                                        <option value={i.id_movimientos_inventario} key={"tipo" + index} >{i.nombre}</option>
                                                                        : null
                                                                    }</>
                                                                ))}
                                                            </>}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.vermsgerroridmovimiento ? <p className="msgError "
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerroridmovimiento}</p> : null}

                                    </div>
                                    <div className='col'></div>
                                </div>

                                <br />
                                <Collapse isOpen={this.state.idtipomovimiento == 2 && this.state.idmovimiento == 3 ? true : false}>
                                    {this.state.idtipomovimiento == 2 && this.state.idmovimiento == 3 ? (
                                        <div className='row'>

                                            <div className='col-4'>
                                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                                    Origen: </p>
                                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>
                                                    {(this.props.datosinventarioalmacen.tipoubicacion == 1) ? (this.props.datosinventarioalmacen.area) : (this.props.datosinventarioalmacen.rancho)}
                                                </p>

                                            </div>

                                            <div className='col-1'></div>
                                            <div className='col-4'>

                                                <p className='lblSubTitulosModal letraTitulos'
                                                    style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Selecciona el destino:</p>

                                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>
                                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                        <div className="selectBox ">
                                                            <select className="cbxTexto letraNormal"
                                                                onChange={(event) => this.elegirorigen({ tipo: "tipodestino", opcion: event.target.value })}>
                                                                <option disabled="disabled" value={-1} key={"tipo_x"} selected={-1 == this.state.tipodestino} >Seleccionar</option>
                                                                <option value={1} key={"t" + 1} selected={1 == this.state.tipodestino} >Área </option>
                                                                <option value={2} key={"t" + 2} selected={2 == this.state.tipodestino} >Rancho </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <br />


                                                    {(parseInt(this.state.tipodestino) == 1) ? (
                                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                            <div className="selectBox ">
                                                                <select className="cbxTexto letraNormal"
                                                                    onChange={(event) => this.elegirorigen({ tipo: "destinoareas", opcion: event.target.value })}>

                                                                    <option disabled="disabled" value={-1} key={"tipo_x"} selected={-1 == this.state.destino} >Seleccionar área</option>

                                                                    {this.state.listaalmacenareas.map((i, index) => (
                                                                        (this.props.datosinventarioalmacen.tipoubicacion == 1 && i.idcatalogo_areas == this.state.origen) ?
                                                                            (null)
                                                                            : (
                                                                                <option value={i.idcatalogo_areas} key={"t" + i.idcatalogo_areas} selected={i.idcatalogo_areas == this.state.destino} >{i.area} </option>
                                                                            )
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        (parseInt(this.state.tipodestino) == 2) ? (
                                                            <>
                                                                <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                    <div className="selectBox ">
                                                                        <select className="cbxTexto letraNormal"
                                                                            onChange={(event) => this.elegirorigen({ tipo: "destinozonas", opcion: event.target.value })}>
                                                                            <option disabled="disabled" value={-1} key={"tipo_x"} selected={-1 == this.state.destino} >Seleccionar zona</option>

                                                                            {this.state.listaalmacenzonas.map((i, index) => (

                                                                                <option value={i.idcatalogo_zona} key={"t" + i.idcatalogo_zona} selected={i.idcatalogo_zona == this.state.zonadestino} >{i.zona} </option>

                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <br />

                                                                <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                    <div className="selectBox ">
                                                                        <select className="cbxTexto letraNormal" onChange={(event) => this.elegirorigen({ tipo: "destinosranchos", opcion: event.target.value })}>
                                                                            <option disabled="disabled" value={-1} key={"tipo_x"} selected={-1 == this.state.destino} >Seleccionar rancho</option>

                                                                            {this.state.listaalmacenranchos.map((i, index) => (
                                                                                (i.idcatalogo_zona == this.state.zonadestino) ?
                                                                                    (
                                                                                        (this.props.datosinventarioalmacen.tipoubicacion == 2 && parseInt(i.idcatalogo_rancho) == parseInt(this.state.origen)) ?
                                                                                            (null)
                                                                                            : (<option value={i.idcatalogo_rancho} key={"t" + i.idcatalogo_rancho} selected={i.idcatalogo_rancho == this.state.destino} >{i.rancho}  </option>))
                                                                                    : (null)
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (null)
                                                    )}
                                                </p>

                                                {this.state.vermsgerroralmacendestino ?
                                                    <p className="msgError " style={{ color: this.state.colormsgerror }}>{this.state.msgerroralmacendestino}</p> : null}

                                            </div>
                                            <div className='col'></div>
                                        </div>
                                    )
                                        : null}

                                </Collapse>
                            </div>
                            <br />
                            <br />

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div className='row'>
                                    <div className='col'>
                                        <label className="modalLblTitulo letraTitulos">Productos</label>
                                    </div>
                                    <div className='col-auto'>

                                        <button className='btnNuevo letraNormal' onClick={this.vermodal}
                                            style={{
                                                color: this.state.colorbtnnuevo,
                                                backgroundColor: this.state.fondobtnnuevo,
                                                border: this.state.borderbtnnuevo,
                                                position: 'relative'
                                            }}>

                                            <BiSearchAlt2 className='icoLupa'
                                                style={{
                                                    top: '7px',
                                                    paddingRight: '2px',
                                                    color: this.state.colorlupa,
                                                    stroke: this.state.colorlupa
                                                }} />
                                            {this.state.btntexto}
                                        </button>

                                    </div>

                                </div>
                                <br />


                                <Table>

                                    <thead style={{ verticalAlign: "bottom" }}>
                                        <tr style={{ borderTop: 'solid 2px #a1a1a1' }}>
                                            {this.state.tabla.encabezados.map((titulo, index) => (
                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                    {titulo}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody style={{ verticalAlign: "bottom" }}>

                                        <> {
                                            this.state.tabla.elementos.map((item, index) => (
                                                <tr key={"tabla" + index} style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1', background: item.fondo }}>


                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                        {item.nombrecomercial}
                                                    </td>

                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                        {this.state.listaproductosalmacen.filter((i) => i.idproducto == item.idproducto)[0].existencias}
                                                    </td>
                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                        <input id={index} type="number"
                                                            onChange={(e) => this.changecanti(e,
                                                                this.state.listaproductosalmacen.filter((i) => i.idproducto == item.idproducto)[0].existencias)
                                                            }
                                                            value={item.existencias}
                                                            className="txtcanti letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                            placeholder="00.00"></input>
                                                    </td>

                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                        {item.unidadmedida}
                                                    </td>
                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                                                        <div style={{ marginRigth: "6px", marginLeft: "6px", minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} className='divBotonesTrash'>
                                                            <BsTrash onClick={this.eliminar.bind(this, index)} className="icoBotonesTrash" />
                                                        </div>
                                                    </td>



                                                </tr>
                                            ))
                                        }
                                            <tr style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1' }} ></tr>

                                            <tr style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1' }} ></tr>
                                        </>

                                    </tbody>

                                </Table>

                            </div>
                            {this.state.infoalmacen != {} && this.state.accion == 2 ? (
                                <>{this.state.infoalmacen.fechacreacion != "" || this.state.infoalmacen.fechaactualizacion != "" ? (
                                    <>
                                        <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                        <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                            Creado: {this.state.infoalmacen.fechacreacion}
                                            <br />
                                            Actualizado: {this.state.infoalmacen.fechaactualizacion}
                                        </ReactTooltip>
                                    </>
                                ) : null
                                }
                                </>
                            ) : null}
                        </div >

                        {
                            this.state.accion == 2 || this.state.accion == 1 ? (
                                <>
                                    <br />
                                    <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                        <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                    </div>
                                    <br />
                                    <div className="divBtnContenedor" >
                                        <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                        <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                    </div>
                                </>
                            ) : null
                        }

                    </ModalBody >

                </Modal >
            </>
        )
    }

}

