import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoProductosGenerales.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioProductosGenerales from '../../Complements/Modales/ModalFormularioProductosGenerales/ModalFormularioProductosGenerales';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getCatalogoProductosGenerales, getListaTipoProductosGeneral, getComboUnidadMedida} from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default class CatalogoProductosGenerales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      txtselectedtipoproducto: '0',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: false,
      listatipoproductosgenerales: [],
      listaunidadmedida: [],
      errordeacceso: false,

      tabla: {
        encabezados: [
          "Nombre comercial",
          "Tipo producto",
          "Unidad de medida",
          "Descripción",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "10%",
          "20%",
        ],
        mindimensiones: [
          "180px",
          "170px",
          "170px",
          "100px",
          "160px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      redireccionando: false,
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    this.setState({ txtbuscador: '' })
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if ( !this.state.loadingspinners ) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    //obtiene catalogo de productos
    var resp = await getCatalogoProductosGenerales();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }

    //obtiene catalogo de tipo productos
    var resptipoproductos = await getListaTipoProductosGeneral();
    if (this.manejadorLlamadasApis(resptipoproductos)) {
      return;
    }

    //obtiene catalogo de unidades de medida
    var respunidades= await getComboUnidadMedida();
    if (this.manejadorLlamadasApis(respunidades)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({
      txtbuscador: '',
      estatus: estatus.data,
      listaunidadmedida: respunidades.data,
      listatipoproductosgenerales: resptipoproductos.data,
      tabla: tabla
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  borrarBusqueda = () => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrostipo = [];
    var filtrosnombre = [];
    if (this.state.txtselectedtipoproducto == 0) {
      filtrostipo= data;
    } else {
      filtrostipo = data.filter((item) => item.idtipo_producto_general==this.state.txtselectedtipoproducto)
    }
    filtrosnombre = filtrostipo;
    this.setState({ txtbuscador: '' })
    if (filtrosnombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    } else {
      this.filtro(1, filtrosnombre)
    }
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedtipoproducto: '0' })
    this.setState({ errordeacceso:false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselectedtipoproducto: '0' })
    this.setState({ errordeacceso:false })
    this.setState({ loadingspinners: bandera });
  }

  //Funcion para filtrar por los select y texto en buscador
  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrostipo = [];
    var filtrosnombre = [];
    
    
    if (e.target.id == 'Search') {
      this.setState({ txtbuscador: e.target.value });
    
      if (this.state.txtselectedtipoproducto == '0') {
        filtrostipo= data;
      } else {
        filtrostipo = data.filter((item) => item.idtipo_producto_general==this.state.txtselectedtipoproducto)
      }
      filtrosnombre = filtrostipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      
    
    } else if (e.target.id == 'tipoproducto') {
      this.setState({ txtselectedtipoproducto: e.target.value });
     
      if (e.target.value == 0) {
        filtrostipo= data;
      } else {
        filtrostipo = data.filter((item) => item.idtipo_producto_general==e.target.value)
      }
      if(this.state.txtbuscador.length < 3) {
        filtrosnombre = filtrostipo;
      }else{
        filtrosnombre = filtrostipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    } else if (e.target.id == 'CSearch') {
     
      if (this.state.txtselectedtipoproducto == 0) {
        filtrostipo= data;
      } else {
        filtrostipo = data.filter((item) => item.idtipo_producto_general==this.state.txtselectedtipoproducto)
      }
      filtrosnombre = filtrostipo;
      this.setState({ txtbuscador: '' })
    }

    if (filtrosnombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    } else {
      this.filtro(1, filtrosnombre)
    }
  }
  //Termina Funcion para filtrar por los select y texto en buscador

  redireccionando = (idproducto) => {
    localStorage.setItem("dato", [idproducto.contenido]);
    this.setState({ redireccionando: true })
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo producto general', accionmodal: ban.opcion })
    }else if (ban.opcion == 2) { //Nuevo
      this.setState({ titulomodal: 'Editar producto general', accionmodal: ban.opcion, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido))})
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Producto creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Producto editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  render() {
    return (

      <>

        {
          this.state.salir ? 
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesProductos bandera={'salir'} />
          </> 
          : null
        }

        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesProductos bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioProductosGenerales titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus} setLoadingSpinner={this.setLoadingSpinner}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} listatipoproducto={this.state.listatipoproductosgenerales} listaunidadmedida={this.state.listaunidadmedida} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end'>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Productos generales</label>
              </div>

              {/* ////////////////////   Filtro tipo productos ////////////////////////////////////// */}
              <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo2' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto2 letraTitulos' style={{ color: this.state.colortipolbl }}>Tipos producto</label>
                  <div className="selectBox ">
                    <select id='tipoproducto' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedtipoproducto == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}
                      {this.state.listatipoproductosgenerales.map((item) => {
                        return ( <option key={item.tipo} value={item.idtipo_producto_general}>{item.tipo}</option> );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro buscador  ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar producto"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>
            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedtipoproducto == 0 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>                        
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombrecomercial}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto_general}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                       
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombrecomercial}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto_general}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                      
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
        <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.txtselectedtipoproducto == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesProductos({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'goreferencias') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/producto"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}