import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioPatronesInjerto.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaPatronInjertoUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ModalFormularioPatronesInjerto extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      arrayestatus: props.estatus,

      idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,
      txtinjerto: (props.accion == 2) ? (props.item.patroninjerto) : '',
      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',

      vermsgerrorinjerto: false,
      vermsgerrordescripcion: false,

      msgerrorinjerto: "",
      msgerrordescripcion: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colorbordertipo: estiloComboBox().colorbordercombo,

    }
  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    this.setState({ vermsgerrorinjerto: false })
    this.setState({ vermsgerrordescripcion: false })

    let validacionescorrectas = true

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
    let injertocorrecto = validarLetrasNumerosEspacios(this.state.txtinjerto.trim(), longitudvalida);
    if (injertocorrecto.codigo == "invalid") {
      this.setState({ msgerrorinjerto: injertocorrecto.mensaje })
      this.setState({ vermsgerrorinjerto: true })
      validacionescorrectas = false;
    }

    if (this.state.txtdescripcion.trim().length > 0) {
      let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let descripcioncorrecto = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
      if (descripcioncorrecto.codigo == "invalid") {
        this.setState({ msgerrordescripcion: descripcioncorrecto.mensaje })
        this.setState({ vermsgerrordescripcion: true })
        validacionescorrectas = false;
      }
    }

    if (validacionescorrectas) {
      this.setLoadingSpinner(true);
      let json = {
        "idpatroninjerto": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogo_patron_injerto),
        "patroninjerto": this.state.txtinjerto,
        "descripcion": this.state.txtdescripcion,
        "idestatus": this.state.idestatus
      }
      var resp = await getListaPatronInjertoUpdate(json)

      if(resp.code == 0){
        if (this.state.accion == 1) {
            this.showModal('1')
        }
        else if (this.state.accion == 2) {
            this.showModal('2')
        }
      }
      else if(resp.code == 200){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 300){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 400){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if (resp.code == 1000) {
          this.setLoadingSpinner(false);
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
      }else{
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }

  }

  render() {
    return (
      <>
        {/*///////// Sección de mensajes toast ////////*/}
        <ToastContainer />

        <Modal
          isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
            <br />
            <br />

            <div className="divCuerpoModal">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                {/*/////////////// Sección de patrón de injerto ///////////////*/}
                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Patrón de injerto</p>
                {this.state.accion == 2 || this.state.accion == 1 ?
                  <input
                    className='lblTextoModal letraNormal'
                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'}
                    value={this.state.txtinjerto}
                    onChange={this.escribir("txtinjerto")}
                  >
                  </input>
                :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                    {this.state.txtinjerto}
                  </label>
                }

                {this.state.vermsgerrorinjerto ?
                  <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorinjerto}</p>
                :null}

                {/*/////////////// Sección de descripción ///////////////*/}
                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                {this.state.accion == 2 || this.state.accion == 1 ?
                  <input
                    className='lblTextoModal letraNormal'
                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'}
                    value={this.state.txtdescripcion}
                    onChange={this.escribir("txtdescripcion")}
                  >
                  </input>
                :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                    {this.state.txtdescripcion}
                  </label>
                }

                {this.state.vermsgerrordescripcion ?
                  <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p>
                :null}

                {/*/////////////// Sección de estatus ///////////////*/}
                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ?
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option
                              value={i.idestatus}
                              key={"tipo_" + index}
                              selected={i.idestatus == this.state.idestatus}
                            >
                              {i.estatus}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                    Activo
                  </label>
                }
              </div>

              {/*/////////////// Botón que muestra fecha de creación y actualización ///////////////*/}
              {this.state.accion == 2 ?
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              :null}
            </div>
            <br />

            {/*/////////////// Sección de botones ///////////////*/}
            {this.state.accion == 2 || this.state.accion == 1 ?
              <>
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            :null}

          </ModalBody>
        </Modal>
      </>
    )
  }
}

