import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarArchivoCargadoJpgJpegPngPdf, validarNumerosDecimales } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { MdCloudUpload } from 'react-icons/md';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import ModalBuscarVentas from './ModalBuscarVentas';
import {  guardarFacturaGlobalCompras, getDetalleFacturaGlobalGeneral } from '../../../Services/Api';
import { formatoNumero2 } from '../../../Services/Validaciones';

export default class ModalFormularioGuardarFacturaGlobal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,

            txtmonto: '',
            txtfechafactura: '',
            fechafactura: '',
            
            vermsgerrormonto: false,
            vermsgerrorfechafactura: false,
            msgerrormonto: "",
            msgerrorfechafactura: "",

            archivocargado: null,
            txtnombrearchivo: 'Buscar',
            vermsgerrortxtarchivo: false,
            msgerrortxtarchivo: '',

            modalbuscarcompra: false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            tabla: {
                encabezadosCompras: [
                    "ID compra",
                    "Subtotal",
                    "Descuento",
                    "Total",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "10px"
                ]
            },

            tablaComprasRelacionadas: {
                subtotal: 0.00,
                descuento: 0.00,
                total: 0.00,
                listacompras: []
            }

        }

    }

    async componentDidMount() {
        if(this.props.accion==8){
            this.setLoadingSpinner(true)
            let request = {
                idcompra: this.props.idcompra,
            }

            var resp = await getDetalleFacturaGlobalGeneral(request)

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }
            else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }else{
                var auxlistaCompras = this.state.tablaComprasRelacionadas
                auxlistaCompras.listacompras = resp.data.comprasrelacionadas
                

                let totalesCalculados = this.calcularTotales(auxlistaCompras.listacompras)

                auxlistaCompras.subtotal = totalesCalculados.subtotal;
                auxlistaCompras.descuento = totalesCalculados.descuento;
                auxlistaCompras.total = totalesCalculados.total;

                const fechapago = new Date();
                var hayfecha = false;

                if(resp.data.facturaglobal!="" || resp.data.facturaglobal.fechapago!=undefined){
                    var fecha = "29/06/2023"
                    var [day, month, year] = resp.data.facturaglobal.fechapago.split("/");
                    fechapago.setFullYear(year, month-1, day);
                    hayfecha=true;
                }

                setTimeout(() => {
                    this.setState({
                        tablaComprasRelacionadas: auxlistaCompras,
                        txtmonto: resp.data.facturaglobal.monto,
                        fechafactura: hayfecha ? fechapago : ""
                    });
                }, 0);

                this.setLoadingSpinner(false)
            }
        }
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    async seleccionarArchivo(event) {
        try {
          event.persist();
          var archi = event.target.files[0];
          if (event.target.files.length > 0) {
            this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
          }
        } catch (error) { }
    }

    cerrarModal = async (item) => {
        if(item!=0){

            var auxComprasSeleccionada = [];
            var agregarCompras = false;
            //validamos que exista la compra en la tabla
            item.map((compraSeleccionada)=>{
                var indexCompra = this.state.tablaComprasRelacionadas.listacompras.findIndex((compra)=>compra.idcompra==compraSeleccionada.idcompra)
                if(indexCompra > -1){
                    toast.info("La comra con ID "+compraSeleccionada.idcompra+" ya está en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                }else{
                    auxComprasSeleccionada.push(compraSeleccionada)
                    agregarCompras = true;
                }
            })

            if(agregarCompras){
                var auxlistaCompras = this.state.tablaComprasRelacionadas
                auxlistaCompras.listacompras = auxlistaCompras.listacompras.concat(item)

                let totalesCalculados = this.calcularTotales(auxlistaCompras.listacompras)

                auxlistaCompras.subtotal = totalesCalculados.subtotal;
                auxlistaCompras.descuento = totalesCalculados.descuento;
                auxlistaCompras.total = totalesCalculados.total;

                setTimeout(() => {
                    this.setState({
                        tablaComprasRelacionadas: auxlistaCompras
                    });
                }, 0);
            }
        }
        this.setState({ modalbuscarcompra: !this.state.modalbuscarcompra })
    }

    verModalDetalles = () => {
        this.setState({ modalbuscarcompra: true })
    }

    eliminarCompra = async (compra) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.tablaComprasRelacionadas;
        this.state.tablaComprasRelacionadas.listacompras = aux.listacompras.filter((item) => !(item.idcompra == compra.idcompra));
        
        let totalesCalculados = this.calcularTotales( this.state.tablaComprasRelacionadas.listacompras)

        this.state.tablaComprasRelacionadas.subtotal = totalesCalculados.subtotal;
        this.state.tablaComprasRelacionadas.descuento = totalesCalculados.descuento;
        this.state.tablaComprasRelacionadas.total = totalesCalculados.total;

        var aux = this.state.tablaComprasRelacionadas;
        await this.setState({ tablaComprasRelacionadas: aux})
    }

    calcularTotales (listacompras) {
        var total = 0, descuento = 0, subtotal = 0;
        listacompras.map((compra)=>{
            subtotal = parseFloat(subtotal) + parseFloat(compra.precio);
            
            if(compra.descuento!=undefined){
                descuento = parseFloat(descuento) + parseFloat(compra.descuento);
                total = parseFloat(total) + (parseFloat(compra.precio)-parseFloat(compra.descuento))
            }else{
                total = parseFloat(total) + (parseFloat(compra.precio))
            }
            
        })
        let calculados = {
            total: total,
            subtotal: subtotal,
            descuento: descuento
        }

        return calculados;
    }

    setFormatDateSave = (fecha) => {
        var fechaseteada = "";
        if(fecha!=null){
            var [year, month, day] = fecha.split("-");
        
            if(day!=undefined && month!=undefined && year!=undefined){
                fechaseteada = `${day}/${month}/${year}`
                return fechaseteada;
            }else{
                fechaseteada = fecha;
            }
        }
           
        return fechaseteada;
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrormonto: false })
        this.setState({ vermsgerrorfechafactura: false })
        this.setState({ vermsgerrortxtarchivo: false })
        let validacionescorrectas = true
        
        if (this.state.archivocargado == null) {
            this.setState({ msgerrortxtarchivo: "Selecciona un archivo" })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }

        let archivocargado = validarArchivoCargadoJpgJpegPngPdf (this.state.archivocargado)
        if (archivocargado.codigo == "invalid") {
            this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }

        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtmonto = validarNumerosDecimales(this.state.txtmonto);
        if (txtmonto.codigo == "invalid") {
            this.setState({ msgerrormonto: txtmonto.mensaje })
            this.setState({ vermsgerrormonto: true })
            validacionescorrectas = false;
        }

        if (this.state.txtfechafactura.length < 1) {
            this.setState({ msgerrorfechafactura: "Seleccione una fecha de pago" })
            this.setState({ vermsgerrorfechafactura: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);

            const formdata = new FormData();
            formdata.append('idcompra', this.props.idcompra)
            formdata.append('monto', this.state.txtmonto)
            formdata.append('fechafactura', this.setFormatDateSave(this.state.txtfechafactura))
            formdata.append('factura', this.state.archivocargado)

            if(this.state.tablaComprasRelacionadas.listacompras.length>0){
                var index = 0;
                this.state.tablaComprasRelacionadas.listacompras.map((compra)=>{
                    formdata.append(`comprasrelacionadas[${index}][idcompra]`, compra.idcompra);
                    index++;
                })
            }
            
            var resp = await guardarFacturaGlobalCompras(formdata)

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }
            else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else {
                this.showModal({accion: 4, resp: resp})
            }
        }
    }

    setearComprasEditar (listacompras){
        var auxlista = [];
        listacompras.map((compra)=>{
            compra.editar=true;
            auxlista.push(compra)
        })
        return auxlista;
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalbuscarcompra == true) ? (
                        <ModalBuscarVentas 
                        titulomodal={"Buscar compras"}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalbuscarcompra}
                        accion={this.state.accionmodal}
                        listacompras={this.props.listacompras}
                        idcompra={this.props.idcompra}
                        />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({accion: 4, resp: undefined})} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <br />
                            <br />
                            <div className="container-fluid containerTabla " style={{ background: '#EBF9FF', borderRadius: '10px'}}>
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla ">

                                        <div className='row' style={{ marginTop: '20px' }}>
                                            <div className='col'>
                                                <label className="lblEncabezadosTabla letraTitulos"><strong>Compras relacionadas:</strong></label>
                                            </div>
                                            <div className='col-3'>
                                                <Button className='btnAceptarModal' style={{position: 'relative'}}
                                                onClick={() => this.verModalDetalles()}>
                                                    {"+ Agregar"}
                                                </Button>
                                            </div>
                                        </div>
                                        
                                        <Table hover>
                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.tabla.encabezadosCompras.map((titulo, index) => (
                                                        <th
                                                            className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>

                                                {
                                                    
                                                    this.state.tablaComprasRelacionadas.listacompras.map((item, index) => (
                                                        <tr style={{ borderTop: 'solid 2px black'}} >
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idcompra}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${formatoNumero2(item.precio)}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${item.descuento ? formatoNumero2(item.descuento) : 0}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${(item.descuento ? formatoNumero2(item.precio-item.descuento) : formatoNumero2(item.precio))}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarCompra(item)}>
                                                                    <FaTrashAlt className="icoBotonesTrash iconbtneliminar"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                
                                                <tr style={{ borderTop: 'solid 2px black'}} >
                                                    <td className="containerSubTablaAA" style={{ color: this.state.colorcontenidotabla }}>
                                                        <b>Totales acumulados</b>
                                                    </td>
                                                    <td className="containerSubTablaAA centerText" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaComprasRelacionadas.subtotal)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA centerText" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaComprasRelacionadas.descuento)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA centerText" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaComprasRelacionadas.total)}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div style={{ width: '70%', marginLeft: '15%' }}>
                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Monto de factura</p>
                                    <input className='lblTextoModal letraNormal' 
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtmonto} onChange={this.escribir("txtmonto")}></input>
                                    
                                    {this.state.vermsgerrormonto ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrormonto}</p> : null}


                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de factura</p>
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechafactura}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechafactura", valor: date })}
                                            dateFormat="dd/MM/yyyy"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                        
                                    {this.state.vermsgerrorfechafactura ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechafactura}</p> : null}


                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Factura (jpg/jpeg/png/pdf)</p>
                                    <div className='centrarBotonFile' >
                                        <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=" .pdf, .jpg, .jpeg, .png"
                                        onChange={(event) => this.seleccionarArchivo(event)} />
                                        <label for="file-2"><MdCloudUpload />
                                        <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                                        </label>
                                    </div>

                                    {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}
                                </div>
                                    
                            </div>

                        </div>

                        {this.state.accion == 4 || this.state.accion == 8? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({accion: 4, resp: undefined})}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }

}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686',width: '96%',margin: '0px 8px 18px 8px'
        }}
    >
      <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value: 'dd/mm/aaaa'}</label>
      <FaCalendar className="icoBotones" style={{ minWidth: '28px' }}/>
    </button>
  ));