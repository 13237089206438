import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { IoMdNotifications } from "react-icons/io";
import "./ModalNotificaciones.css";
import { estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { validarLetrasConEspacios, validarLetrasNumerosLongitud, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { setCatalogoActividadesUpdate, getNotificaciones } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalNotificaciones extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,

            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            notificaciones: [ ]
        }

    }
    async componentDidMount() {
        if (!this.state.loadingspinners) {
            this.setState({ loadingspinners: true });
        }

        var notificaciones = await getNotificaciones();
        if (this.manejadorLlamadasApis(notificaciones)) {
            return;
        }

        console.log(notificaciones.data.compras);
        this.setState({ loadingspinners: false,  notificaciones: notificaciones.data.compras})

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.message == "Token no encontrado") {
            this.setState({ salir: true })
            return true
        } else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }


    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos tituloNotificacion" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
                        <IoMdNotifications className='icoNotifications' />
                        <br />
                        <br />
                        <div className="divCuerpoModal cuerpoNotifiacion">
                            {
                            
                            
                            this.state.notificaciones.map((item, index) => (
                                <div className="divContenedorModal contenedorNotificacion" >
                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.colortitulo }}>{item.titulo}</p>
                                    <p className='letraTitulos mensajeNotifiacion' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>{item.mensaje}</p>

                                </div>

                            ))
                            }
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

