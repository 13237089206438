import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs'
import "./ModalFormularioAlmacenesCombustible.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitudV2, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { updateCatalogoAlmacenCombustible } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import 'react-toastify/dist/ReactToastify.css';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioAlmacenesCombustible extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      id: (props.accion == 2) ? (props.item.idcatalogoalmacenescombustible) : 0,

      listazonas: props.listazonas,
      listaranchos: props.listaranchos,

      idzona: (props.accion == 2) ? (props.item.idzona) : todoslosfiltrosdisponibles,
      vermsgerrortxtzona: false,
      msgerrortxtzona: '',

      idrancho: (props.accion == 2) ? (props.item.idrancho) : todoslosfiltrosdisponibles,
      vermsgerroridrancho: false,
      msgerroridrancho: '',

      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: "",

      arrayestatus: props.estatus,
      idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo
    }

  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    this.setState({ vermsgerrortxtzona: false })
    this.setState({ vermsgerroridrancho: false })
    this.setState({ vermsgerrortxtdescripcion: false })
    let validacionescorrectas = true

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };

    if (this.state.idzona == todoslosfiltrosdisponibles) {
      this.setState({ msgerrortxtzona: "Selecciona una zona" })
      this.setState({ vermsgerrortxtzona: true })
      validacionescorrectas = false;
    }

    if (this.state.idrancho == todoslosfiltrosdisponibles) {
      this.setState({ msgerroridrancho: "Selecciona un rancho" })
      this.setState({ vermsgerroridrancho: true })
      validacionescorrectas = false;
    }

    if (this.state.txtdescripcion.trim().length > 0) {
      longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
      if (txtdescripcion.codigo == "invalid") {
        this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
        this.setState({ vermsgerrortxtdescripcion: true })
        validacionescorrectas = false;
      }
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json = {
        "idcatalogoalmacenescombustible": this.state.id,
        "idrancho": this.state.idrancho,
        "idestatus": this.state.idestatus,
        "descripcion": this.state.txtdescripcion.trim()
      }
      var resp = await updateCatalogoAlmacenCombustible(json);

      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return;
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        toast.success("Almacén guardado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal({ accion: '1' })
      }
    }

  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo({ tipo: "idzona", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idzona} >Seleccionar </option>
                          {this.state.listazonas.map((i, index) => (
                            <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.idzona} >{i.zona} </option>
                          ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                {this.state.vermsgerrortxtzona ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtzona}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo({ tipo: "idrancho", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idrancho} >
                            {todoslosfiltrosdisponibles == this.state.idzona ? 'Selecciona primero una zona' : 'Seleccionar'}
                          </option>
                          {this.state.listaranchos.filter((item) => item.idcatalogo_zona == this.state.idzona).map((i, index) => (
                            <option value={i.idcatalogo_rancho} key={"tipo_" + index} selected={i.idcatalogo_rancho == this.state.idrancho} >{i.rancho} </option>
                          ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                {this.state.vermsgerroridrancho ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerroridrancho}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                }
              </div>
              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

