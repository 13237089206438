import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacenAgroquimicos.css";
import { estiloComboBox, estilosModal, estiloFiltroEncabezados, estiloBtnActualizar } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import { getInformacionCompra } from '../../../Services/Api';


export default class ModalFormularioDetallesServicios extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: "Servicios asignados",
            tablaresumen: {
                encabezados: [
                    "Actividad",
                    "Tipo actividad",
                    "Precio",
                ],
                dimensiones: [
                    "40%",
                    "40%",
                    "20%",
                ],
                mindimensiones: [
                    "200px",
                    "100px",
                    "100px",
                ],
                maxdimensiones: [
                    "200px",
                    "100px",
                    "100px",
                ],
            },
            tipoalmacendestino: 0,

            item: [],
            movimientos: [],
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            estilosmodal: estilosModal(),

        }
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        }
        else if (resp.code == 200) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 300) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        return false
    }

    //////////////////////////////cargar informción propia del modal 
    async componentDidMount() {
        await this.verInformacionCompra();
        this.props.activarSpinner(false);
    }

    async verInformacionCompra() {
        var infomovimientoscompra = await getInformacionCompra({ idcompra: this.props.item.contenido.idcompra });
        if (this.manejadorLlamadasApis(infomovimientoscompra)) {
            this.props.activarSpinner(false);
            return;
        }
        const actividadesTransformadas = this.transformarActividadesPorDestino(infomovimientoscompra.data.actividades);
        this.setState({
            item: infomovimientoscompra.data,
            movimientos: actividadesTransformadas
        });
    }

    transformarActividadesPorDestino(actividades) {
        const resultado = {};
        let x = 0;

        actividades.forEach((actividad) => {
            const { actividad: nombreActividad, destino, precio, tipoactividad } = actividad;
            if (!resultado[destino]) {
                resultado[destino] = {
                    nombrealmacen: destino,
                    actividades: [],
                    index: x,
                    colapsar: false
                };
                x = x + 1
            }
            resultado[destino].actividades.push({ actividad: nombreActividad, tipo: tipoactividad, precio: precio });
        });
        return Object.values(resultado);
    }

    colapsar = (index) => {
        var lista = this.state.movimientos
        lista[index].colapsar = !lista[index].colapsar
        this.setState({ movimientos: lista })
    }


    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    render() {
        return (
            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
                        <br /> <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/* ////////////////////////////// información de compra ///////////////////// */}
                                <div className='row'>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID compra
                                        </div>
                                        <div className='lblTexto letraTitulos'>    {this.state.item.idcompra}  </div>
                                    </div>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID requisición
                                        </div>
                                        <div className='lblTexto letraTitulos'> {this.state.item.idrequisicion}  </div>
                                    </div>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            Requisición
                                        </div>
                                        <div className='lblTexto letraTitulos'>  {this.state.item.requisicion}  </div>
                                    </div>
                                </div>
                            </div>

                            <br></br>
                            <div> <b>Servicios aplicados</b></div>
                            {this.state.movimientos.map((item, index) => (

                                <div className="marginTopSeparator" >

                                    <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                                        <div className='row flexNoWrap'>
                                                            <div className='col-1 containerSubTablaAA minPaddingTopBottom' >
                                                                <button className='btnCollapsar letraNormal'
                                                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                                                    onClick={() => this.colapsar(index)}>{!item.colapsar ? "+" : "-"}</button>
                                                            </div>
                                                            <div className='col-8 containerSubTablaAA minPaddingTopBottom'>
                                                                <p className='sinMargen'><b>{item.nombrealmacen}</b></p>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={item.colapsar} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <div className='tablaProductoCotizaciones'>
                                                                    {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                                                    <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
                                                                        <div className="container-fluid">
                                                                            <div className='row'>
                                                                                <div>
                                                                                    <Table hover>
                                                                                        <thead style={{ verticalAlign: "bottom" }}>
                                                                                            <tr style={{ borderBottomWidth: "1px" }}>
                                                                                                {this.state.tablaresumen.encabezados.map((titulo, index) => (
                                                                                                    <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                                                        style={{
                                                                                                            minWidth: this.state.tablaresumen.mindimensiones[index], width: this.state.tablaresumen.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                                                            textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                                                                        }}>
                                                                                                        {titulo}
                                                                                                    </th>
                                                                                                ))}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody style={{ verticalAlign: "bottom" }}>

                                                                                            {item.actividades.map((actividad) => (

                                                                                                <tr style={{ borderBottomWidth: "1px" }}>
                                                                                                    <td key={"col-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                                        {actividad.actividad}
                                                                                                    </td>
                                                                                                    <td key={"col-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                        {actividad.tipo}                                                                                       </td>
                                                                                                    <td key={"col-03"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                        {actividad.precio}
                                                                                                    </td>

                                                                                                </tr>

                                                                                            ))}


                                                                                            <br /><br />
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>


                            ))}

                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}

