import React from 'react'
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import "./ModalCompraCombustible.css";
import { estiloComboBox, estiloBtnActualizar, estiloFiltroEncabezados, estilosModal, estiloColorError } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { validarLetrasNumerosEspacios, validarNumerosDecimales3, formatoNumero } from '../../../Services/Validaciones';
import ModalBuscarProveedorCotizacions from '../ModalCotizaciones/ModalBuscarProveedorCotizacion';
import {
    getComboTipoProveedor, getProveedoresActivos, getListaTipoProductosGeneral, getListaProductosGenerales,
     getListaTipoCombustibles, guardarCompraCombustible, verCompraCombustible

} from '../../../Services/Api';
import ModalBuscarValesCombustible from '../ModalBuscarValesCombustible/ModalBuscarValesCombustible';

export default class ModalCompraCombustible extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tipoedicion: props.tipoedicion,
            show: props.show,
            accion: props.accion,

            hubocambio: false,

            tabla: {
                encabezados: (props.tipoedicion == 1) ? (
                    [
                        "Fecha vale",
                        "Folio",
                        "Combustible",
                        "Litros",
                        "Importe",
                        "Destino",
                        ""
                    ]
                ) : (
                    [
                        "Fecha vale",
                        "Folio",
                        "Combustible",
                        "Litros",
                        "Importe",
                        "Destino",
                    ]
                ),
                dimensiones: [
                    "10%",
                    "15%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "200px",
                    "150px",
                    "100px",

                ],
                elementos: [],
            },

            tablacombustibles: {
                encabezados: (props.tipoedicion == 1) ? (
                    [
                        "Tipo combustible",
                        "Precio por litro",
                        "Seleccionar"
                    ]
                ) : ([
                    "Tipo combustible",
                    "Precio por litro"
                ]),
                dimensiones: [
                    "50%",
                    "40%",
                    "10%",
                ],
                mindimensiones: [
                    "350px",
                    "150px",
                    "100px",
                ],
                elementos: [],
            },

            nuevaCotizacion: {
                "idcotizacion_proveedor": 0,
                "idproveedor": 0,
                "proveedor": "",                
                "idestatuscotizacion": 0,
                "estatuscotizacion": "Editado",
                "mensaje_estatuscotizacion": "",
                "productos": [],
                "total": 0.00
            },

            // nuevos
            listaproveedores: [],
            listatipoproveedores: [],

            proveedorselect: undefined,
            modalproveedores: false,

            modalvervales: false,
            listavalesseleccionados: [],
            listanuevosproductos: [],


            loadingspinners: false,

            informacioncompra: undefined,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            //para tablas
            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colortipolbl: estiloComboBox().colorcombolbl,
            colormsgerror: estiloColorError().msgerror,
            banderadidmount: false,

            txttitulocompra: "",
            vermsgerrortxttitulocompra: false,
            msgerrortxttitulocompra: "",

            totalapagar: 0,
            vermsgerrortotalapagar: false,
            msgerrortotalapagar: "",

            listavalesseleccionados: [],
            listacombustibles: [],
            listacombustiblesprecios: [],
        }
    }


    async componentDidMount() {
        this.setState({ loadingspinners: true })
        //obtiene lista de tipo proveedores
        var resptipoprovedores = await getComboTipoProveedor({ idcompra: 5 });
        if (this.manejadorLlamadasApis(resptipoprovedores)) {
            return;
        }

        //obtiene lista de proveedores
        var respproveedores = await getProveedoresActivos();
        if (this.manejadorLlamadasApis(respproveedores)) {
            return;
        }

        //obtiene lista de tipo productos
        var resptipoproductos = await getListaTipoProductosGeneral();
        if (this.manejadorLlamadasApis(resptipoproductos)) {
            return;
        }

        //obtiene lista de productos
        var respproductos = await getListaProductosGenerales();
        if (this.manejadorLlamadasApis(respproductos)) {
            return;
        }

        //obtiene lista de tipo combustibles
        var resptiposcombustibles = await getListaTipoCombustibles();
        if (this.manejadorLlamadasApis(resptiposcombustibles)) {
            return;
        }

        await this.setState({
            listaproveedores: respproveedores.data, listatipoproveedores: resptipoprovedores.data,
            listatipoproductos: resptipoproductos.data,
            listacombustibles: resptiposcombustibles.data,

        })

        setTimeout(async () => {

            if (this.state.tipoedicion == 2) { // es una edicion
                await this.cargarinformacioncompra()
            } else {
                await this.cargarListaPreciosCombustibles()
            }
            this.setState({ banderadidmount: true, loadingspinners: false, })
        }, 900);

    }


    async cargarListaPreciosCombustibles() {
        let listacombustiblesprecios = [];

        this.state.listacombustibles.forEach(item => {
            listacombustiblesprecios.push({
                idtipocombustible: item.idtipocombustible,
                precio: "",
                tipo: item.tipo,
                activo: false,
            });

        });


        await this.setState({ listacombustiblesprecios: listacombustiblesprecios });
    }

    async cargarinformacioncompra() {
        let idcompra = this.props.idcompra

        //obtiene lista de tipo productos
        var respinfoalmacen = await verCompraCombustible({ idcompra: idcompra });
        if (this.manejadorLlamadasApis(respinfoalmacen)) {
            return;
        }

        var informacion = respinfoalmacen.data;
        let infoproveedor = this.state.listaproveedores.filter((item) => ((item.idcatalogo_proveedores == informacion.idproveedor)))
        let listacombustiblesprecios = [];
        this.state.listacombustibles.forEach(item => {
            let precionuevo = (informacion.combustibles.find(comb => parseInt(comb.idcombustible) == parseInt(item.idtipocombustible)))
            listacombustiblesprecios.push({
                idtipocombustible: item.idtipocombustible,
                precio: (precionuevo) ? (precionuevo.precio) : (""),
                tipo: item.tipo,
                activo: false,
            });

        });

        this.setState({
            listavalesseleccionados: informacion.values,
            proveedorselect: infoproveedor[0],
            txttitulocompra: informacion.titulo,
            listacombustiblesprecios: listacombustiblesprecios,
            totalapagar: informacion.totalcompra,
            loadingspinners: false,
            tipoedicion: 2
        })

    }


    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 200 || resp.code == 300) {

            this.setState({ loadingspinners: false })
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }


    showModal = async (accion) => {
        if (accion == "10") {
            //validar elementos 
            let validacionescorrectas = true;

            //validad proveedor 
            if (this.state.proveedorselect == undefined) {
                toast.info("Selecciona un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                validacionescorrectas = false;
                return;
            }

            //validar título
            this.setState({ vermsgerrortxttitulocompra: false })
            let logitudtexto = { longitudminima: 1, longitudmaxima: 200 };
            let nombre = validarLetrasNumerosEspacios(this.state.txttitulocompra.trim(), logitudtexto);

            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 4 };
            let totalfinal = validarNumerosDecimales3(this.state.totalapagar, longitud)

            if (nombre.codigo == "invalid") {
                this.setState({ msgerrortxttitulocompra: nombre.mensaje })
                this.setState({ vermsgerrortxttitulocompra: true })
                validacionescorrectas = false;
                return;
            }

            if (totalfinal.codigo == "invalid") {
                this.setState({ msgerrortotalapagar: totalfinal.mensaje })
                this.setState({ vermsgerrortotalapagar: true })
                validacionescorrectas = false;
                return;
            }

            //validar vales
            if (this.state.listavalesseleccionados.length == 0) {
                toast.info("Selecciona al menos un vale", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }

            //validar total a pagar 
            var aux = this.state.listavalesseleccionados;
            let jsonvales = [];
            aux.map(async (prod) => {
                jsonvales.push({
                    "idvale": prod.idvale,
                })
            })

            var jsonfinal = {
                "idcompra": 0,
                titulo: this.state.txttitulocompra,
                "idproveedor": this.state.proveedorselect.idcatalogo_proveedores,
                "vales": jsonvales,
                "totalcompra": this.state.totalapagar
            }

            if (validacionescorrectas == true) {
                this.setState({ loadingspinners: true })

                let respguardarcompra = await guardarCompraCombustible(jsonfinal);
                if (this.manejadorLlamadasApis(respguardarcompra)) {
                    return;
                } else {
                    toast.success("Compra combustible guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    this.setState({ show: false })
                    setTimeout(() => {
                        this.props.cerrarModal(0);
                    }, 200);
                }
            }

        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }


    verModalProveedores = (ban) => {
        this.setState({ titulomodal: 'Buscar proveedor', accionmodal: 2, modalproveedores: true })
    }

    cerrarModalProveedor = async (prov) => {
        if (prov != '0') {
            let auxCoti = this.state.nuevaCotizacion;
            auxCoti.idproveedor = prov.idcatalogo_proveedores;
            auxCoti.proveedor = prov.nombre;
            this.setState({ proveedorselect: prov, nuevaCotizacion: auxCoti, hubocambio: true })
        }
        this.setState({ modalproveedores: false })
    }


    verModalVales = async (ban) => {
        let haycombustibles =
            this.state.listacombustiblesprecios.find((item => item.activo == true));

        if (this.state.proveedorselect != undefined) {
            this.setState({ accionmodal: 1, modalvervales: true })
        }
        else {
            toast.info("Selecciona un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
    }

    cerrarModalBuscarVales = async (listavalesseleccionados) => {
        if (listavalesseleccionados != '0') {

            listavalesseleccionados.forEach(el => {
                let preciocombustible = this.state.listacombustiblesprecios.find((comb => comb.idtipocombustible == el.idcombustible));
                el.totalfinal = preciocombustible.precio * el.litros

            });

            this.setState({ listavalesseleccionados: listavalesseleccionados })
        }
        this.setState({ modalvervales: false });

    }


    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    borrarBusqueda(opcion) {
        if (opcion == 1) {
            this.setState({
                txttitulocompra: '',
            })
        } else if (opcion == 2) {
            this.setState({
                totalapagar: 0,
            })
        }

    }


    escribirCantidadPrecio = async (index, prop, event) => {

        if (prop == 'cantidad' && parseFloat(event.target.value) < 0
            || prop == 'precio' && parseFloat(event.target.value) < 0) {
            toast.error("La cantidad no puede ser menor a cero: " + this.cantidaddisponible(index), { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        else {

            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 2 };
            let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
            var updateValor = event.target.value;

            if (tipocorrecto.codigo == 'invalid') {
                var auxUpdateValor = updateValor.split('.');
                if (auxUpdateValor.length > 1) {
                    updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 2)
                } else {
                    updateValor = auxUpdateValor[0].slice(0, 7)
                }
                toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            this.state.listacombustiblesprecios[index][prop] = updateValor;
            var dummy = this.state.listavalesseleccionados
            this.setState({ listavalesseleccionados: dummy, hubocambio: true });
            this.actualizarlistavales();
        }
    };



    cambiarValores = async (elemento, index) => {
        if (elemento == "activo") {
            let listacombustiblesprecios = this.state.listacombustiblesprecios;

            listacombustiblesprecios[index][elemento] = !listacombustiblesprecios[index][elemento];

            this.setState({
                listacombustiblesprecios: listacombustiblesprecios
            })

            await this.actualizarlistavales()
        }
    }

    async actualizarlistavales() {
        let listavales = this.state.listavalesseleccionados;
        let nuevalistavales = [];
        let listacombustibles = this.state.listacombustiblesprecios;

        listacombustibles.forEach(comb => {
            if (comb.activo == true) {
                listavales.forEach(vale => {
                    if (vale.idcombustible == comb.idtipocombustible) {
                        vale.totalfinal = vale.litros * comb.precio;
                        nuevalistavales.push(vale)
                    }

                });
            }

        });

        await this.setState({ listavalesseleccionados: nuevalistavales });
    }

    
    render() {

        return (
            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalproveedores == true) ? (
                    <ModalBuscarProveedorCotizacions
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProveedor}
                        show={this.state.modalproveedores}
                        accion={this.state.accionmodal}
                        listaproveedores={this.state.listaproveedores}
                        listatipoproveedores={this.state.listatipoproveedores} />
                ) : null}

                {(this.state.modalvervales == true) ? (
                    <ModalBuscarValesCombustible
                        cerrarModal={this.cerrarModalBuscarVales}
                        todoslosproductosalmacen={true}
                        show={this.state.modalvervales} accion={1} item={this.state.listavalesseleccionados}
                        listacombustiblesprecios={this.state.listacombustiblesprecios}
                        idproveedor={this.state.proveedorselect.idcatalogo_proveedores}
                        nombreproveedor={this.state.proveedorselect.nombre}
                        listavalesseleccionados={this.state.listavalesseleccionados}
                    />
                ) : null}



                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{(this.props.tipoedicion == 1) ? (
                            "Nueva compra combustible"
                        ) : ("Información compra combustible")}</label>


                        {/* ////////////////////   Inicio Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}
                        <br />
                        <div className="container-fluid containerTabla">
                            <div className="container-fluid anchoMaximo ">
                                <div className="containerSubTablaAA">

                                    <div className="marginTopSeparator" >
                                        <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                                            <div className='row flexNoWrap'>

                                                                <div className='col-9 containerSubTablaAA minPaddingTopBottom'>
                                                                    <p className='sinMargen'>

                                                                        {/*////////////////////////////  selección de proveedor ///////////////////////*/}
                                                                        <div className='col-8 col-sm-12 col-md-8 containerSubTabla paddingBottomEncabezados' >
                                                                            {(this.state.tipoedicion == 1) ? (
                                                                                <button className='btnActualizar letraNormal botonesB' style={{ width: "auto" }}
                                                                                    onClick={this.verModalProveedores.bind(this, { opcion: "1" })}><b>
                                                                                        Proveedor: {(this.state.proveedorselect) ? (this.state.proveedorselect.nombre)
                                                                                            : ("Selecciona uno")}
                                                                                    </b>
                                                                                </button>

                                                                            ) : (
                                                                                (this.state.proveedorselect != undefined) ? (
                                                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}><b>Proveedor: {this.state.proveedorselect.nombre}</b></label>

                                                                                ) :
                                                                                    null
                                                                            )}
                                                                        </div>

                                                                        {/*////////////////////////////  fin selección de proveedor ///////////////////////*/}





                                                                        <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezados'>


                                                                            {(this.state.tipoedicion == 1) ? (
                                                                                <>
                                                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Título</label>
                                                                                    <input id='Search' type="search"
                                                                                        onChange={this.escribir("txttitulocompra")}
                                                                                        value={this.state.txttitulocompra}
                                                                                        style={{ color: this.state.colortxtbuscar }}
                                                                                        className="txtBuscador letraNormal inputBasico"
                                                                                        placeholder=""></input>
                                                                                    {this.state.txttitulocompra ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }}
                                                                                        onClick={() => this.borrarBusqueda(1)} />) : null}

                                                                                    {this.state.vermsgerrortxttitulocompra ? <p className="msgError letraNormal"
                                                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttitulocompra}</p> : null}

                                                                                </>

                                                                            ) : (
                                                                                <> <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Título:</label><label className='lblTexto letraTitulos' style={{color:"black"}}> {this.state.txttitulocompra}</label></>

                                                                            )}
                                                                        </div>


                                                                        <div className='col-8 col-sm-12 col-md-8 containerSubTabla paddingBottomEncabezados' >


                                                                            {(this.state.tipoedicion == 1) ? (
                                                                                <>
                                                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Total a pagar:</label>
                                                                                    <input id='Search' type="search"
                                                                                        onChange={this.escribir("totalapagar")}
                                                                                        value={this.state.totalapagar}
                                                                                        style={{ color: this.state.colortxtbuscar, width: "300px" }}
                                                                                        className="txtBuscador letraNormal inputBasico"
                                                                                        placeholder=""></input>
                                                                                    {this.state.totalapagar ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }}
                                                                                        onClick={() => this.borrarBusqueda(2)} />) : null}

                                                                                    {this.state.vermsgerrortotalapagar ? <p className="msgError letraNormal"
                                                                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortotalapagar}</p> : null}

                                                                                </>

                                                                            ) : (
                                                                                <>  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Total a pagar:</label>  <label className='lblTexto letraTitulos' style={{color:"black"}}>${formatoNumero(this.state.totalapagar)}</label> </>

                                                                            )}
                                                                        </div>

                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>

                                                                    <br></br>
                                                                    <br></br>

                                                                    {/*////////////////////////////  vales de combustibles ///////////////////////*/}
                                                                    <div className='tablaProductoCotizaciones'>
                                                                        <br />

                                                                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                                                        <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
                                                                            <div className="container-fluid">
                                                                                <div className='row'>
                                                                                    <div>
                                                                                        {/*JSON.stringify(this.state.listavalesseleccionados)*/}

                                                                                        <Table hover>
                                                                                            <thead style={{ verticalAlign: "bottom" }}>

                                                                                                <tr>
                                                                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                                                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                                                            style={{
                                                                                                                minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                                                                textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                                                                            }}>
                                                                                                            {titulo}
                                                                                                        </th>
                                                                                                    ))}
                                                                                                </tr>
                                                                                            </thead>

                                                                                            <tbody style={{ verticalAlign: "bottom" }}>


                                                                                                {this.state.listavalesseleccionados.map((item, index) => (
                                                                                                    <tr key={index} style={{ borderBottom: "1px solid #c5c5c5", borderTop: "1px solid #c5c5c5" }}>
                                                                                                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                                            {item.fechasolicitud}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {item.folio}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {item.combustible}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {item.litros}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                        ${formatoNumero(item.importe)}
                                                                                                        </td>

                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {(item.tipodestino == 1) ? (item.area) : (null)}
                                                                                                            {(item.tipodestino == 2) ? (item.rancho + ", " + item.zona) : (null)}
                                                                                                            {(item.tipodestino == 3) ? (item.maquinaria) : (null)}
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                ))
                                                                                                }


                                                                                                {this.state.tipoedicion == 1 ? (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className=" txttotal" colSpan={3} style={{ color: this.state.colorcontenidotabla }}>
                                                                                                                <button className='btnActualizar letraNormal botonesB' onClick={this.verModalVales.bind(this, { opcion: "1" })}
                                                                                                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                                                                                                                >+ Agregar</button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ) : null
                                                                                                }

                                                                                                <br /><br />

                                                                                            </tbody>
                                                                                        </Table>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>





                                                                        <div className='row'>

                                                                            <br></br>
                                                                            <br></br>
                                                                        </div>
                                                                    </div>
                                                                    <br></br>
                                                                    <br></br>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ////////////////////   Fin Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}


                        {this.state.tipoedicion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')} disabled={!this.state.hubocambio} >Guardar</Button>
                                </div>
                            </>
                        ) : null
                        }

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

