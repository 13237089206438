import React from 'react'
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getListaPalabras } from '../../Services/Api'

export default class AlmacenCombustible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,

      // Código para la ejecución de solicitudes periódicas
      listapalabras: [],
      idsintervalos: [],
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
      return;
    }

    ////// Código para la ejecución de solicitudes periódicas //////
    await this.solicitarInformacionAPI()
    var idintervalo = setInterval(this.solicitarInformacionAPI, 300_000);
    var idsintervalos = this.state.idsintervalos
    idsintervalos.push(idintervalo)
    await this.setState({ idsintervalos: idsintervalos });
    ////// Código para la ejecución de solicitudes periódicas //////


    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);

  }

  ////// Código para la ejecución de solicitudes periódicas //////
  componentWillUnmount() {
    var idsintervalos = this.state.idsintervalos
    idsintervalos.map(id => clearInterval(id))
  }

  solicitarInformacionAPI = async () => {
    var resppalabras = await getListaPalabras()
    console.log(resppalabras)
    var idsintervalos = this.state.idsintervalos
    if (idsintervalos.length > 1) {
      var id = idsintervalos.slice(-1)
      clearInterval(id)
      idsintervalos.pop()
      await this.setState({ idsintervalos: idsintervalos });
    }
    if (resppalabras && resppalabras.text) {
      this.setState({ listapalabras: [resppalabras] })
    } else {
      this.setState({ listapalabras: [] })
    }
  }
  ////// Código para la ejecución de solicitudes periódicas //////

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  render() {
    return (

      <>
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo" style={{ border: 'solid 1px #4534e5' }}>
            <div className="containerSubTabla">
              <Table>
                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.listapalabras.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla">
                        {item.text}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoalmacenescombustible"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
