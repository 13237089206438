import React from 'react'
import './EditarRequisicion.css';
import './RequisicionGeneral.css';
import { estiloBtnActualizar, estiloBtnEditar, estiloTabla } from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

import ModalBuscarProductosRequisicion from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalBuscarProductosRequisicion';

import ModalSeleccionarDestino from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalSeleccionarDestino'
import ModalAreas from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalAreas'
import ModalRanchos from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalRancho'
import ModalVehiculo from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalVehiculo'
import ModalAlmacenes from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalAlmacenes'
import ModalEquiposYHerramientas from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalEquiposYHerramientas';

import {
  getAlmacenesEquiposInformaciónLista, getListaProductosGenerales, getListaTipoProductosGeneral,
  getComboUnidadMedida, getComboAreasDeTrabajo, getListaZonas, getListaRanchosDisponibles, getListaLotes,
  getListaAlmacenProductoGeneral, getListaAlmacenEquipos, getListaMaquinarias
} from '../../Services/Api';
import { validarSoloNumeros } from '../../Services/Validaciones';
const coloresporestatus = {
  1: 'btnDisponible', 2: 'btnEsperaAutorizacion', 3: 'btnAutorizado', 4: 'btnRechazado', 5: 'btnCancelado',
  6: 'btnDisponible', 7: 'btnDisponible', 8: 'btnCompletado', 9: 'btnCompletado'
}
const destinos = { 1: 'Área', 2: 'Rancho', 3: "Vehículo", 4: 'Almacén productos', 5: 'Almacén equipos', 6: "Equipo" }

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      opcioneditar: true,

      tabla: {
        encabezados: [
          "Tipo",
          "Producto",
          "Cantidad",
          "Unidad de medida",
          "Destino",
          "",
          ""
        ],
        dimensiones: [
          "12%",
          "15%",
          "12%",
          "16%",
          "15%",
          "15%",
          "10%"
        ],
        mindimensiones: [
          "130px",
          "180px",
          "120px",
          "200px",
          "180px",
          "120px",
          "100px"
        ],
        elementos: []
      },

      //algo asi entregara el arreglo de la api informacion
      datosDummy: props.productos,

      actualizartablalocal: false,

      //arreglo para guardar nuevos productos
      listanuevosproductos: this.props.listanuevosproductos,



      listaunidadmedida: [],
      listaimcompletos: [],
      listadetenidos: [],

      respfamiliaproductos: [],
      resptipoproductos: [],
      respproductos: [],
      todoslosproductosalmacen: true,
      listaproductosalmacen: [],
      listaequiposinventario: [],

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",

      modalselecciondestino: false,
      modalseleccionarea: false,
      modalseleccionrancho: false,
      modalseleccionalmacen: false,
      modalseleccionaequipo: false,
      modalseleccionvehiculo: false,

      listaguardados: this.props.listaguardados ? this.props.listaguardados : {},
      listadestinos: this.props.listadestinosauxiliar ? this.props.listadestinosauxiliar : {},

      destinoseleccionado: { nombre: 'Seleccionar destino', iddestino: -1, idtipo_destino: -1 },

      listaproductosgenerales: {}
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }
    //Se asigna atributos auxiliares
    this.setAtributosAux();

    //Se validan datos incompletos para marcar fila de tabla en rojo
    this.actualizarTabla();

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setAtributosAux = async () => {
    //agregamos los atributos, detener, eliminar, editable a los elementos de las tablas
    var aux = this.state.datosDummy;
    aux.map((item) => {
      item.eliminar = false;
    })

    //a estos indices se le colocara la fila en rojo
    await this.setState({ datosDummy: aux })
  }

  actualizarTabla = async () => {
    //validaremos si los datos estan incompletos para pintar la fila en rojo
    var aux = []
    /*
//return 
    this.state.datosDummy.map((item, index) => {
      if (item.producto.length < 0 || item.producto == "" ||
        item.cantidad <= 0 || item.cantidad == ""/* || item.Unidadmedida.length < 0 || item.Unidadmedida == ""* /) {
        //guardamos el indice del elemento con datos incompletos
        aux.push(index)
      }
    })*/
    // --------------- 
    this.props.productos.map((item, index) => {
      if (item.producto.length < 0 || item.producto == "" ||
        item.cantidad <= 0 || item.cantidad == ""/* || item.Unidadmedida.length < 0 || item.Unidadmedida == ""*/) {
        //guardamos el indice del elemento con datos incompletos
        aux.push(index)
      }
    })

    //this.generarListaProductosGuardar();
    //this.actualizarContenidoGeneral(this.state.datosDummy);

    //a estos indices se le colocara la fila en rojo
    await this.setState({ listaimcompletos: aux })

  }

  generarListaProductosGuardar = async () => {

    //llamar función de actualización general
    this.props.actualizarContenido(this.state.datosDummy);

  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }


  cerrarModalNuevoProducto = async (itemseleccionado) => {

    //si en la modal de nuevo le dio en guardar y no cancel, agregamos el json a la tabla
    if (itemseleccionado != 0) {
      var aux = this.props.productos.filter((item) => { //this.state.datosDummy.filter((item) => {
        return item.idproducto == itemseleccionado.idcatalogo_producto_general
      })

      //si no existe en la tabla, lo agregamos
      if (aux.length < 1) {
        var json = {
          idproducto: itemseleccionado.idcatalogo_producto_general,
          cantidad: "",
          unidadmedida: itemseleccionado.claveunidadmedida ? itemseleccionado.claveunidadmedida : itemseleccionado.unidadmedida,
          tipoproducto: itemseleccionado.tipoproducto_general,
          producto: itemseleccionado.nombrecomercial,
          //idtipoproducto: itemseleccionado.idtipo_producto_general,
          //idunidadmedida: itemseleccionado.idcatalogo_unidadmedida,
          idcatalogo_unidadmedida: itemseleccionado.idcatalogo_unidadmedida,
          //precio: "",
          eliminar: false,
        }
        this.state.datosDummy.push(json)

        var dummy = this.state.datosDummy

        this.setState({ datosDummy: dummy });
        // -----------------------
        var productosaux = this.props.productos
        var listaproductosgenerales = {}
        productosaux.forEach((item) => {
          /*var idproducto = item.idproducto
          var aux = {}//{ idproducto: item }
          aux[idproducto] =  item*/
          listaproductosgenerales[item.idproducto] = item
        }) //this.props.productos //this.state.listaproductosgenerales
        listaproductosgenerales[json.idproducto] = json //item
        this.setState({ listaproductosgenerales: listaproductosgenerales })

        if (json.idproducto.toString().includes("nuevo")) {
          json.nombrecomercial = json.producto;
          json.idcatalogo_producto_general = json.idproducto
          json.clave = json.idproducto + " *";
          //json.familiaproducto = "";
          json.tipoproducto = "";
          json.idcatalogoproducto = json.idproducto;
          json.unidadmedida = json.unidadmedida;
          json.idcatalogo_unidadmedida = json.idcatalogo_unidadmedida;
          await this.props.actualizarListaNuevosProductos(json)
        } else {
          console.log('Otra opción')
        }

        this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)
        await this.actualizarTabla();
      } else {
        toast.error("El producto ya existe en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
    this.setState({ modalnuevoproducto: !this.state.modalnuevoproducto, loadingspinners: false })
  }

  //al precionar el boton agregar
  agregarElemento = async (ban) => {

    this.setState({ loadingspinners: true })

    var respproductos = await getListaProductosGenerales()
    if (this.manejadorLlamadasApis(respproductos)) {
      return;
    }

    /*
    var respfamiliaproductos = await getComboFamiliaProducto();
    if (this.manejadorLlamadasApis(respfamiliaproductos)) {
      return;
    }*/

    var resptipoproductos = await getListaTipoProductosGeneral()//getComboTipoProducto();
    if (this.manejadorLlamadasApis(resptipoproductos)) {
      return;
    }



    var respunidades = await getComboUnidadMedida();
    if (this.manejadorLlamadasApis(respunidades)) {
      return;
    }





    this.setState({
      listaproductos: respproductos.data,
      // listafamiliaproductos: respfamiliaproductos.data,
      listaunidadesmedida: respunidades.data,
      listatipoproductos: resptipoproductos.data,
      titulomodal: 'Nuevo producto',
      accionmodal: 1,
      modalnuevoproducto: true,
      loadingspinners: false
    })

  }

  escribirCantidadPrecio = async (index, prop, event, idproducto) => {
    //let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)

    //actualizamos los valores dinamicamente de la fila en modo edicion
    /*this.state.datosDummy[index][prop] = event.target.value;
    var dummy = this.state.datosDummy
    
    this.setState({ datosDummy: dummy });*/
    //
    //var listaproductosgenerales = this.props.productos //this.state.listaproductosgenerales
    var productosaux = this.props.productos
    var listaproductosgenerales = {}
    productosaux.forEach((item) => {
      listaproductosgenerales[item.idproducto] = item
    }) //this.props.productos //this.state.listaproductosgenerales
    //listaproductosgenerales[json.idproducto] = json //item

    listaproductosgenerales[idproducto][prop] = event.target.value; //json //item


    //listaproductosgenerales[index][prop] = event.target.value;
    //listaproductosgenerales[json.idproducto] = json //item
    /*var listaproductosgenerales = listaproductosgenerales.map(item => {
      var element = item
      if (idproducto == element.idproducto) {
        element.cantidad = event.target.value
      }
      return element
    })*/
    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)

    //validamos datos incompletos
    this.actualizarTabla();


  }

  eliminarElemento = async (elemento) => {

    var productosaux = this.props.productos
    var listaproductosgenerales = {}
    productosaux.forEach((item) => {
      if (item.idproducto != elemento.item.idproducto) {
        listaproductosgenerales[item.idproducto] = item
      }
    })
    //listaproductosgenerales[idproducto] = 
    // ------------------------------------------------------------
    var listaguardados = this.state.listaguardados
    listaguardados[elemento.item.idproducto] = false
    this.setState({ listaguardados: listaguardados })
    // ------------------------------------------------------------
    // ------------------------------------------------------------
    var listadestinos = this.state.listadestinos
    //var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
    delete listadestinos[elemento.item.idproducto] // = JSON.parse(JSON.stringify(destinoseleccionado))
    this.setState({ listadestinos: listadestinos })
    // ------------------------------------------------------------

    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)

    //validamos datos incompletos
    this.actualizarTabla();





    /*var aux = this.state.datosDummy.filter((item, ind) => ((item.idproducto != elemento.item.idproducto)));

    this.setState({
      datosDummy: aux,
      actualizartablalocal: true
    })

    setTimeout(() => {
      this.actualizarTabla();
    }, 200);*/

  }

  //seleccionar el chec eliminar
  checkEliminar = async (index, check) => {
    this.state.datosDummy[index].eliminar = check;
    var dummy = this.state.datosDummy

    this.setState({ datosDummy: dummy });

    this.generarListaProductosGuardar();
  }

  verModalSeleccionarDestino = (ban) => {
    if (ban.opcion == 1) {
      this.setState({
        titulomodal: 'Seleccionar destino:', accionmodal: 1, modalselecciondestino: true,
        itemseleccionado: JSON.parse(JSON.stringify(ban.item))
      });
    }
  }

  cerrarModalSeleccionarDestino = (item) => {
    this.setState({ modalselecciondestino: !this.state.modalselecciondestino })
    if (item.accion == 1) {

      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.idtipo_destino = item.id
      this.setState({ destinoseleccionado: destinoseleccionado })
      if (item.id == 1 /*Área*/) {
        this.verModalSeleccionarArea(item)
      } else if (item.id == 2) {
        this.verModalSeleccionarRancho(item)
      } else if (item.id == 3) {
        this.verModalSeleccionarVehiculo(item)
      }
      else if (item.id == 6) {
        this.verModalSeleccionarRancho(item)
      }
    }
  }

  verModalSeleccionarArea = async (ban) => {

    this.setState({ loadingspinners: true })

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    //if (ban.opcion == 1) {
    this.setState({
      listaareas: resplistaareas.data,
      titulomodal: 'Seleccionar áreas', accionmodal: 1, modalseleccionarea: true, loadingspinners: false
      //itemseleccionado: {}/*JSON.parse(JSON.stringify(ban.item))*/
    })
    //}
  }

  cerrarModalSeleccionarArea = (datos) => {
    this.setState({ modalseleccionarea: !this.state.modalseleccionarea })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.nombre = datos.item.area
      destinoseleccionado.iddestino = datos.item.idcatalogo_areas
      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idproducto] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  verModalSeleccionarRancho = async (ban) => {


    this.setState({ loadingspinners: true })

    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      return;
    }
    var resplistaranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }

    var resplistalotes = await getListaLotes();
    if (this.manejadorLlamadasApis(resplistalotes)) {
      return;
    }




    this.setState({
      listazonas: resplistazonas.data,
      listaranchos: resplistaranchos.data,
      listalotes: resplistalotes.data,
      titulomodal: 'Seleccionar destino:', accionmodal: 1, modalseleccionrancho: true,
      loadingspinners: false
    })
  }

  cerrarModalSeleccionarRancho = (datos) => {

    this.setState({ modalseleccionrancho: !this.state.modalseleccionrancho })
    if (datos.accion == 1) {
      if (this.state.destinoseleccionado.idtipo_destino == 2) {
        this.verModalSeleccionarAlmacen(datos.item)
      } else {
        this.verModalSeleccionarEquipoHerramienta(datos.item)
      }

    }
  }

  verModalSeleccionarAlmacen = async (item) => {

    this.setState({ loadingspinners: true })

    var resplistaproductosgenerales = await getListaAlmacenProductoGeneral();
    if (this.manejadorLlamadasApis(resplistaproductosgenerales)) {
      return;
    }

    var resplistaalmacenequipos = await getListaAlmacenEquipos();
    if (this.manejadorLlamadasApis(resplistaalmacenequipos)) {
      return;
    }

    //if (ban.opcion == 1) {
    this.setState({
      listaalamcenproductosgenerales: resplistaproductosgenerales.data,
      listaalmacenequipos: resplistaalmacenequipos.data,
      titulomodal: 'Selección de almacén', accionmodal: 1, modalseleccionalmacen: true,
      itemrancho: item,
      loadingspinners: false
    })
    //}
  }

  cerrarModalSeleccionarAlmacen = (datos) => {
    this.setState({ modalseleccionalmacen: !this.state.modalseleccionalmacen })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      if (datos.item.idalmacengenerales) {
        destinoseleccionado.idtipo_destino = 4
        destinoseleccionado.iddestino = datos.item.idalmacengenerales
        destinoseleccionado.nombre = destinos[4]
      } else if (datos.item.idalmacenequipos) {
        destinoseleccionado.idtipo_destino = 5
        destinoseleccionado.iddestino = datos.item.idalmacenequipos
        destinoseleccionado.nombre = destinos[5]
      } else {
        destinoseleccionado.idtipo_destino = 2
        destinoseleccionado.iddestino = datos.item.idcatalogo_rancho
        destinoseleccionado.nombre = destinos[2] + ' ' + datos.item.rancho + ' (Sin almacén)' //destinos[2]
      }
      //destinoseleccionado.nombre = datos.item.descripcion
      //destinoseleccionado.iddestino = datos.item.idalmacengenerales
      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idproducto] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  verModalSeleccionarEquipoHerramienta = async (item) => {

    var resplistaalmacenequipos = await getListaAlmacenEquipos();
    if (this.manejadorLlamadasApis(resplistaalmacenequipos)) {
      return;
    }


    //llamar api para obtener equipos de ese rancho 
    let idcatalogoinventarioequipos = resplistaalmacenequipos.data.filter(
      (itemfilter) => itemfilter.idrancho == item.idcatalogo_rancho)

    if (idcatalogoinventarioequipos.length > 0) {
      this.setState({ loadingspinners: true })
      let equipos = await getAlmacenesEquiposInformaciónLista(idcatalogoinventarioequipos[0].idalmacenequipos)
      if (this.manejadorLlamadasApis(equipos)) {
        return;
      }
      this.setState({ loadingspinners: false })
      this.setState({
        listaalmacenequipos:resplistaalmacenequipos.data,
        titulomodal: 'Selección de equipo o herramienta', accionmodal: 1, modalseleccionaequipo: true,
        itemrancho: item, listaequiposinventario: equipos.data.productos

      })
    } else {
      this.setState({
        titulomodal: 'Selección de equipo o herramienta', accionmodal: 1, modalseleccionaequipo: true,
        itemrancho: item, listaequiposinventario: []

      })
    }

  }

  cerrarModalSeleccionarEquipoHerramienta = (datos) => {

    this.setState({ modalseleccionaequipo: !this.state.modalseleccionaequipo })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.idtipo_destino = 6
      destinoseleccionado.iddestino = datos.item.idequipo
      destinoseleccionado.nombre = datos.item.nombreclave


      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idproducto] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })

    }
  }


  verModalSeleccionarVehiculo = async (ban) => {

    this.setState({loadingspinners:true})

    var resplistavehiculos = await getListaMaquinarias()
    if (this.manejadorLlamadasApis(resplistavehiculos)) {
      return;
    }

    var resplistaranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }


    

    this.setState({
        listaranchos: resplistaranchos.data,
      listavehiculos: resplistavehiculos.data,
      titulomodal: 'Seleccionar vehículo', accionmodal: 1, modalseleccionvehiculo: true,
      loadingspinners:false
    })
  }

  cerrarModalSeleccionarVehiculo = (datos) => {
    this.setState({ modalseleccionvehiculo: !this.state.modalseleccionvehiculo })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.nombre = datos.item.nombreclave
      destinoseleccionado.iddestino = datos.item.idmaquinaria
      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idproducto] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  guardarRegistro = ({ item, index }) => {
    // Si el egistro que se quiere guardar está incompleto entonces: true
    var band = this.state.listaimcompletos.indexOf(index) != -1 || !(this.state.listadestinos[item.idproducto])
    if (band) {
      toast.error("Complete el registro", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }

    let validarnumeroX=validarSoloNumeros(item.cantidad)

    if (validarnumeroX.codigo=='invalid') {
      toast.error(validarnumeroX.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }

    if (item.cantidad.toString().length > 8) {
      toast.error("Cantidad no puede exceder 8 caracteres", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }


    var listaguardados = this.state.listaguardados
    listaguardados[item.idproducto] = true
    this.setState({ listaguardados: listaguardados })
    var listaproductosgenerales = this.state.listaproductosgenerales
    var producto = {
      idproducto: item.idproducto,
      cantidad: item.cantidad,
      idtipodestino: this.state.listadestinos[item.idproducto].idtipo_destino,
      iddestino: this.state.listadestinos[item.idproducto].iddestino,
      unidadmedida: item.unidadmedida,
      idcatalogo_unidadmedida: item.idcatalogo_unidadmedida,
      tipoproducto: item.tipoproducto,
      producto: item.producto
    }
    listaproductosgenerales[item.idproducto] = producto //item
    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, listaguardados)
  }

  editarRegistro = ({ item }) => {
    var listaguardados = this.state.listaguardados
    listaguardados[item.idproducto] = false
    this.setState({ listaguardados: listaguardados })

    var productosaux = this.props.productos
    var listaproductosgenerales = {}
    productosaux.forEach((item) => {
      listaproductosgenerales[item.idproducto] = item
    })
    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)
  }

  actualizarContenidoGeneral = (productos, listadestinos, listaguardados) => {
    this.props.actualizarContenidoGeneral(productos, listadestinos, listaguardados)
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {/* Listo */}
        {(this.state.modalselecciondestino == true) ? (
          <ModalSeleccionarDestino titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarDestino} estatus={this.state.estatus}
            show={this.state.modalselecciondestino} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listadestinos={this.props.listadestinos}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionarea == true) ? (
          <ModalAreas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarArea} estatus={this.state.estatus}
            show={this.state.modalseleccionarea} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            listaareas={this.state.listaareas} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionrancho == true) ? (
          <ModalRanchos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarRancho} estatus={this.state.estatus}
            show={this.state.modalseleccionrancho} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            listaareas={this.props.listaareas}
            listazonas={this.state.listazonas} listaranchos={this.state.listaranchos} />
        ) : null}

        {/* Listo para seleccionr almacenes de algún rancho*/}
        {(this.state.modalseleccionalmacen == true) ? (
          <ModalAlmacenes titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarAlmacen} estatus={this.state.estatus}
            show={this.state.modalseleccionalmacen} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
           // listaareas={this.props.listaareas}
            listaalamcenproductosgenerales={this.state.listaalamcenproductosgenerales}
            listaalmacenequipos={this.state.listaalmacenequipos}
            listadestinos={this.props.listadestinos}

            itemrancho={this.state.itemrancho}
            listazonas={this.state.listazonas} listaranchos={this.state.listaranchos} />
        ) : null}
        {/*listo, para seleccionar equipos*/}
        {(this.state.modalseleccionaequipo == true) ? (
          <ModalEquiposYHerramientas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarEquipoHerramienta} estatus={this.state.estatus}
            show={this.state.modalseleccionaequipo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            //listaareas={this.props.listaareas}
            listaalamcenproductosgenerales={this.state.listaalamcenproductosgenerales}
            listaalmacenequipos={this.state.listaalmacenequipos}
            listadestinos={this.props.listadestinos}
            listaequiposinventario={this.state.listaequiposinventario}

            itemrancho={this.state.itemrancho}
            listazonas={this.props.listazonas} listaranchos={this.props.listaranchos} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionvehiculo == true) ? (
          <ModalVehiculo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarVehiculo} estatus={this.state.estatus}
            show={this.state.modalseleccionvehiculo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            //listaareas={this.props.listaareas}
            listavehiculos={this.state.listavehiculos} 
           // listaranchos={this.state.listaranchos} 
            />
        ) : null}

        {/* Listo */}
        {(this.state.modalnuevoproducto == true) ? (
          <ModalBuscarProductosRequisicion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalNuevoProducto}
            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalnuevoproducto} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            //respfamiliaproductos={this.props.listafamiliaproductos}
            resptipoproductos={this.state.listatipoproductos}
            respproductos={this.state.listaproductos}
            listaunidadmedida={this.state.listaunidadesmedida}
            listaproductosnuevos={this.props.listanuevosproductos}
            listaproductosagregados={this.props.productos}//this.state.datosDummy}
          />
        ) : null}

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla" style={{ backgroundColor: 'white' }}>
          <div className="container-fluid anchoMaximo">
            <div className='row justify-content-center' >
              <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>

                <Table hover>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className={(index > 1) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                          style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.props.productos.sort((a, b) => a.idproducto - b.idproducto).map((item, index) => (
                      <tr key={index}
                        className={
                          item.detener ? "trFondoDetener" : '' /*this.state.listaimcompletos.indexOf(index) != -1 ? "trFondoIncompleto" : ""*/} >
                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto}
                        </td>
                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.producto}
                        </td>
                        <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                          {!this.state.listaguardados[item.idproducto] ?
                            <input type={'text'} maxlength="8" pattern="^\d*,?\d*$" className="inputSubtabla text-center" value={item.cantidad}
                              onChange={(e) => this.escribirCantidadPrecio(index, 'cantidad', e, item.idproducto)}></input> :
                            item.cantidad
                          }
                        </td>
                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], textAlign: 'center' }}>
                          {!this.state.listaguardados[item.idproducto] ?
                            <button className={'btnEstandar btnBase ' + coloresporestatus[1]} /*disabled={![1, 2, 4].includes(item.idestatusrequisicion)}*/
                              onClick={this.verModalSeleccionarDestino.bind(this, { opcion: "1", item: item })}
                              style={{ minWidth: "186px" }}>
                              {this.state.listadestinos[item.idproducto] ?
                                (/*destinos[this.state.listadestinos[item.idproducto].idtipo_destino] + ' ' + */this.state.listadestinos[item.idproducto].nombre) : 'Selecciona destino'}
                            </button> :
                            this.state.listadestinos[item.idproducto] ?
                              (/*destinos[this.state.listadestinos[item.idproducto].idtipo_destino] + ' ' + */this.state.listadestinos[item.idproducto].nombre) : ''
                          }
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {/*(this.state.listaimcompletos.indexOf(index) != -1 || !(this.state.listadestinos[item.idproducto]))*/
                            (!this.state.listaguardados[item.idproducto]) ?
                              <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'} /*disabled={![1, 2, 4].includes(item.idestatusrequisicion)}*/
                                onClick={this.eliminarElemento.bind(this, { opcion: "1", item: item })}
                                style={{ minWidth: "120px", border: 'solid 2px #bebdbc' }}>
                                Cancelar
                              </button>
                              :
                              <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'} /*disabled={![1, 2, 4].includes(item.idestatusrequisicion)}*/
                                onClick={this.editarRegistro.bind(this, { opcion: "1", item: item })}
                                style={{ minWidth: "120px", backgroundColor: '#0061FE', border: 'solid 2px #0061FE', color: 'white' }}>
                                Editar
                              </button>}
                        </td>

                        <td key={"col-" + index + "-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(!this.state.listaguardados[item.idproducto]) ? //this.state.listaimcompletos.indexOf(index) != -1 ?
                            <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'} /*disabled={![1, 2, 4].includes(item.idestatusrequisicion)}*/
                              onClick={this.guardarRegistro.bind(this, { opcion: "1", item: item, index: index })}
                              style={{ minWidth: "100px", backgroundColor: '#0061FE', border: 'solid 2px #0061FE', color: 'white' }}>
                              Guardar
                            </button>
                            : <div className="modalDetallesCheck" >
                              <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, { item, index })}
                              ><FaTrashAlt className="icoBotones" /></button>
                            </div>}
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </Table>

                <div className='row paddingEncabezados' style={{ marginBottom: "6px", padding: "8px" }}>
                  <div className="col-auto">
                    <button className='btnActualizar letraNormal' onClick={this.agregarElemento.bind(this, { opcion: "1" })}
                      style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                    >+ Agregar</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
