import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoEquipos.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion,
  estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getCatalogoEquipos, getListaTipoEquipos, getListaTipoCombustibles } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ModalFormularioEquipos from '../../Complements/Modales/ModalFormularioEquipos/ModalFormularioEquipos';
import { Redirect } from 'react-router-dom';

const todoslosfiltrosdisponibles = -1

export default class CatalogoEquipos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idtiposeleccionado: todoslosfiltrosdisponibles,//

      listatipoequipos: [],
      listacombustibles:[],

      tabla: {
        encabezados: [
          "Nombre/Clave",
          "Marca",
          "Tipo equipo",
          "Modelo",
          "Combustible",
          "Descripción",
          "Estatus",
          ""
        ],
        dimensiones: [
          "120%",
          "10%",
          "10%",
          "10%",
          "10%",
          "24%",
          "8%",
          "8%"
        ],
        mindimensiones: [
          "220px",
          "125px",
          "140px",
          "140px",
          "140px",
          "200px",
          "100px",
          "120px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (this.state.loadingspinners == false) {
      this.setState({ loadingspinners: true })
    }
    this.setState({ txtbuscador: '' })

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var respcc = await getCatalogoEquipos();
    if (this.manejadorLlamadasApis(respcc)) {
      return;
    }
    var tabla = this.state.tabla;
    tabla.elementos = JSON.parse(JSON.stringify(respcc.data));

    var resp = await getListaTipoEquipos();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }

    var respcombustibles = await getListaTipoCombustibles ();
    if (this.manejadorLlamadasApis(respcombustibles)) {
      return;
    }


    var arraytemp = JSON.parse(JSON.stringify(resp.data));
    this.setState({ estatus: estatus.data, tabla: tabla, listatipoequipos: arraytemp, listacombustibles:respcombustibles.data })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ idtiposeleccionado: todoslosfiltrosdisponibles })
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarCombo = async (idtipo) => {

    await this.setState({ idtiposeleccionado: idtipo })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idtipo, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.idtiposeleccionado, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }

  }

  filtrarBusqueda = (idtipo, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idtipo != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idtipo_equipo == idtipo)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter(
      (item) => item.nombreclave.toLocaleLowerCase().includes(textobusqueda))

  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo equipo', accionmodal: 1, itemseleccionado: {} })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar equipo', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (opciones) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })

    if (opciones == 1) {
      toast.success("Equipo creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (opciones == 2) {
      toast.success("Equipo editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioEquipos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus} show={this.state.modaldetalles}
            accion={this.state.accionmodal} item={this.state.itemseleccionado} listatipoequipos={JSON.parse(JSON.stringify(this.state.listatipoequipos))} listacombustibles={this.state.listacombustibles} setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Equipo  </label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event.target.value)}>
                      <option selected={this.state.idtiposeleccionado == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todos</option>
                      {this.state.listatipoequipos.map((i, index) => (
                        <option value={i.idtipoequipo} key={"tipo_" + index} selected={i.idtipoequipo == this.state.idtiposeleccionado} >{i.tipo} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar nombre / clave"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.trim().length < 3 && this.state.idtiposeleccionado == todoslosfiltrosdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.tipoequipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          {item.combustible}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7]
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.tipoequipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          {item.combustible}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7]
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idtiposeleccionado == todoslosfiltrosdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
