import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { validarArchivoCargadoJpgJpegPngPdf, validarNumerosDecimales } from '../../../Services/Validaciones';
import { postGuardarFacturaVenta } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { MdCloudUpload } from 'react-icons/md';

export default class ModalFormularioGuardarFactura extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,

            txtmonto: '',
            txtfechafactura: '',
            fechafactura: '',

            vermsgerrormonto: false,
            vermsgerrorfechafactura: false,
            msgerrormonto: "",
            msgerrorfechafactura: "",

            archivocargado: null,
            txtnombrearchivo: 'Buscar',
            vermsgerrortxtarchivo: false,
            msgerrortxtarchivo: '',

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),

        }

    }

    async componentDidMount() {

    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    async seleccionarArchivo(event) {
        try {
            event.persist();
            var archi = event.target.files[0];
            if (event.target.files.length > 0) {
                this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
            }
        } catch (error) { }
    }

    setFormatDateSave = (fecha) => {
        var fechaseteada = "";
        if (fecha != null) {
            var [year, month, day] = fecha.split("-");

            if (day != undefined && month != undefined && year != undefined) {
                fechaseteada = `${day}/${month}/${year}`
                return fechaseteada;
            } else {
                fechaseteada = fecha;
            }
        }

        return fechaseteada;
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrormonto: false })
        this.setState({ vermsgerrorfechafactura: false })
        this.setState({ vermsgerrortxtarchivo: false })
        let validacionescorrectas = true

        if (this.state.archivocargado == null) {
            this.setState({ msgerrortxtarchivo: "Selecciona un archivo" })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }

        let archivocargado = validarArchivoCargadoJpgJpegPngPdf(this.state.archivocargado)
        if (archivocargado.codigo == "invalid") {
            this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }

        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtmonto = validarNumerosDecimales(this.state.txtmonto);
        if (txtmonto.codigo == "invalid") {
            this.setState({ msgerrormonto: txtmonto.mensaje })
            this.setState({ vermsgerrormonto: true })
            validacionescorrectas = false;
        }

        if (this.state.txtfechafactura.length < 1) {
            this.setState({ msgerrorfechafactura: "Seleccione una fecha de pago" })
            this.setState({ vermsgerrorfechafactura: true })
            validacionescorrectas = false;
        }




        if (validacionescorrectas) {
            this.setLoadingSpinner(true);

            const formdata = new FormData();
            formdata.append('idventa', this.props.idventa)
            formdata.append('monto', this.state.txtmonto)
            formdata.append('fechafactura', this.setFormatDateSave(this.state.txtfechafactura))
            formdata.append('factura', this.state.archivocargado)

            var resp = await postGuardarFacturaVenta(formdata)

            if(resp.code == 0){
                toast.success("Factura agregada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal({ accion: 2, resp: resp })
            }
            else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false)
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }
        }
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: 2, resp: undefined })} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Monto factura</p>
                                <input className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'text'} value={this.state.txtmonto} onChange={this.escribir("txtmonto")}></input>

                                {this.state.vermsgerrormonto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrormonto}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de factura</p>
                                <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                    <DatePicker
                                        selected={this.state.fechafactura}
                                        onChange={(date) => this.cambiarFecha({ llave: "fechafactura", valor: date })}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>

                                {this.state.vermsgerrorfechafactura ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechafactura}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Factura (jpg/jpeg/png/pdf)</p>
                                <div className='centrarBotonFile' >
                                    <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=" .jpg, .jpeg, .png, .pdf"
                                        onChange={(event) => this.seleccionarArchivo(event)} />
                                    <label for="file-2"><MdCloudUpload />
                                        <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                                    </label>
                                </div>

                                {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}

                            </div>

                        </div>

                        {this.state.accion == 2 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({ accion: 2, resp: undefined })}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }

}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));