import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioClientes.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasMayusculasNumerosSE, validarLetrasNumerosLongitudV2, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaCatalogoClienteUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

const todaslasopcionesdisponibles = -1

export default class ModalFormularioClientes extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: 1, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            txtclabe: '',
            vermsgerrortxtclabe: false,
            msgerrortxtclabe: '',

            idtipocliente: todaslasopcionesdisponibles,
            vermsgerroridtipocliente: false,
            msgerroridtipocliente: '',

            txtnombrecomercial: '',
            vermsgerrorcomercial: false,
            msgerrorcomercial: '',

            txtdescripcion: '',
            vermsgerrordescripcion: false,
            msgerrordescripcion: '',

            arraytipo: props.arraytipos,
            arrayestatus: props.estatus,
            idestatus: 1,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo
        }

    }

    showModal = (opciones) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(opciones);
        }, 200);
    }

    escribir = (prop) => (event) => {
        if ('txtclabe' == prop) {
            this.setState({ [prop]: event.target.value.toUpperCase() });
        } else {
            this.setState({ [prop]: event.target.value });
        }
    }

    cambiarTipo = (item) => {
        this.setState({ [item.tipo]: item.valor })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrortxtclabe: false })
        this.setState({ vermsgerroridtipocliente: false })
        this.setState({ vermsgerrorcomercial: false })
        this.setState({ vermsgerrordescripcion: false })
        let validacionescorrectas = true

        let longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
        let clabe = validarLetrasMayusculasNumerosSE(this.state.txtclabe.trim(), longitudvalida)
        if (clabe.codigo == "invalid") {
            this.setState({ msgerrortxtclabe: clabe.mensaje })
            this.setState({ vermsgerrortxtclabe: true })
            validacionescorrectas = false
        }

        if (this.state.idtipocliente == todaslasopcionesdisponibles) {
            this.setState({ msgerroridtipocliente: "Selecciona un tipo de cliente" })
            this.setState({ vermsgerroridtipocliente: true })
            validacionescorrectas = false
        }

        longitudvalida = { longitudminima: 1, longitudmaxima: 50 }
        let txtnombrecomercial = validarLetrasNumerosLongitudV2(this.state.txtnombrecomercial.trim(), longitudvalida)
        if (txtnombrecomercial.codigo == "invalid") {
            this.setState({ msgerrorcomercial: txtnombrecomercial.mensaje })
            this.setState({ vermsgerrorcomercial: true })
            validacionescorrectas = false
        }

        if (this.state.txtdescripcion.trim().length > 0) {
            longitudvalida = { longitudminima: 1, longitudmaxima: 100 }
            let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
            if (descorrecto.codigo == "invalid") {
                this.setState({ msgerrordescripcion: descorrecto.mensaje })
                this.setState({ vermsgerrordescripcion: true })
                validacionescorrectas = false;
            }
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            let json = {
                "idcatalogo_clientes": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogo_clientes),
                "clave": this.state.txtclabe,
                "idtipocliente": this.state.idtipocliente,
                "nombre": this.state.txtnombrecomercial,
                "comentario": this.state.txtdescripcion,
                "idestatus": this.state.idestatus
            }

            var resp = await getListaCatalogoClienteUpdate(json);
            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setState({ loadingspinners: false })
                return true
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true 
            }
            else {
                toast.success("Cliente guardado correctamente ", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal({ accion: '1', id: resp.data.idcatalogo_clientes })
            }
        }

    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Clave</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtclabe} onChange={this.escribir("txtclabe")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombrecomercial}</label>}

                                {this.state.vermsgerrortxtclabe ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtclabe}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo cliente</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo({ tipo: "idtipocliente", valor: event.target.value })}>
                                                    <option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={todaslasopcionesdisponibles == this.state.idtipocliente} >Seleccionar </option>
                                                    {this.state.arraytipo.map((i, index) => (
                                                        <option value={i.idtipo_cliente} key={"tipo_" + index} selected={i.idtipo_cliente == this.state.idtipocliente} >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }

                                {this.state.vermsgerroridtipocliente ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipocliente}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre completo / Nombre comercial</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombrecomercial} onChange={this.escribir("txtnombrecomercial")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombrecomercial}</label>}

                                {this.state.vermsgerrorcomercial ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcomercial}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                                {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

