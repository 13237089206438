import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalFormularioResumen.css'
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { ToastContainer } from 'react-toastify';
import { BsQuestionSquare } from 'react-icons/bs';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalRechazarCotizacion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,/*
      listatipomovimientos: props.listatipomovimientos,
      listamovimientos: props.listamovimientos,*/
      //idcatalogoalmacenescombustible: props.informacion.idcatalogoalmacenescombustible,
      item: props.informacion ? props.informacion : {},


      idtipomovimiento: (props.accion == 2) ? (props.item.idtipo_equipo) : todoslosfiltrosdisponibles,
      vermsgerroridtipomovimiento: false,
      msgerroridtipomovimiento: "",

      idmovimiento: (props.accion == 2) ? (props.item.idtipo_equipo) : todoslosfiltrosdisponibles,
      vermsgerroridmovimiento: false,
      msgerroridmovimiento: "",

      listacombustiblesmovimientos: props.informacion.productos ? props.informacion.productos : [],
      vermsgerrorlista: false,
      msgerrorlista: '',

      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: "",

      tabla: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Precio"
        ],
        dimensiones: [
          "34%",
          "28%",
          "19%",
          "19%"
        ],
        mindimensiones: [
          "200px",
          "200px",
          "130px",
          "150px"
        ],
        titulo: 'Documentos'
      },

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo
    }

  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  escribirEnTabla = (indice) => (event) => {
    if (this.state.listacombustiblesmovimientos[indice]) {
      var listacombustiblesmovimientos = this.state.listacombustiblesmovimientos
      listacombustiblesmovimientos[indice].cantidad = event.target.value
      this.setState({ listacombustiblesmovimientos: listacombustiblesmovimientos })
    }
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {
    this.setState({ vermsgerrortxtdescripcion: false })

    let validacionescorrectas = true
    let longitudvalida = { longitudminima: 1, longitudmaxima: 300 }

    let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
    if (txtdescripcion.codigo == "invalid") {
      this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
      this.setState({ vermsgerrortxtdescripcion: true })
      validacionescorrectas = false;
    }


    if (validacionescorrectas) {
      var item = this.state.item
      item.comentario = this.state.txtdescripcion.trim()
      this.showModal({ id: 1, item: item })
    }

  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="lg"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal" style={{ paddingBottom: '0px' }}>

              <div className="divContenedorModal">


                {/*<label className="modalLblTitulo letraTitulos">¿Qué movimiento quieres hacer?</label>*/}
                <div className='row align-items-end ' style={{ paddingTop: '10px' }}>
                  <div className='col-3'>
                    <p className='modalLblTitulo letraTitulos lblModalDato' style={{ margin: '0px !important', color: '#0061fe' }}>ID cotización</p>
                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>
                      <b>{this.state.item.idcotizacion_proveedor}</b>
                    </label>
                  </div>

                  <div className='col-8'>
                    <p className='modalLblTitulo letraTitulos lblModalDato' style={{ color: '#0061fe' }}>Proveedor</p>
                    <label className='lblTextoModal letraNormal' style={{ margin: '0px !important', color: this.state.estilosmodal.colorlbltextomodal }}>
                      <b>{this.state.item.proveedor}</b>
                    </label>
                  </div>
                </div>

              </div>


              <div className="divContenedorModal" style={{ paddingTop: '4px' }}>

                <label className="modalLblTitulo letraTitulos">Contenido: </label>

                <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ margin: '0px', padding: '0px' }}>
                    <div className="containerSubTablaaa" style={{ minWidth: '500px', padding: "4px" }}>
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                  textAlign: (index > 0) ? 'center' : ''
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {this.state.listacombustiblesmovimientos.map((item, index) => (
                            <tr key={"tabla2" + index} style={{ background: item.fondo }}>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.producto}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {item.unidadmedida}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {item.cantidad}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {item.precio}
                              </td>
                            </tr>
                          ))}
                          <tr style={{ border: 'solid 1px white' }}>
                            <td className="lblEncabezadosTabla letraTitulos" style={{ paddingTop: '20px', minWidth: '530px', width: '81%', color: this.state.colorcontenidotabla }} colSpan={3}>
                              <b>Total</b>
                            </td>
                            <td className="lblEncabezadosTabla letraTitulos" style={{ paddingTop: '20px', minWidth: '150px', width: '19%', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                              <b>{this.state.item.total}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

              </div>


              <div className="divContenedorModal" style={{ paddingTop: '2px', backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                <label className="lblSubtabla letraNormal" style={{ marginLeft: '15px', marginTop: '15px', marginBottom: '15px' }}><b>Motivo del rechazo</b></label>
                <textarea className='areaTextoModal letraNormal ' rows="5" id='comentarios'
                  value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></textarea>

                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}

              </div>


              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

