import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { estilosModal } from '../../../Services/PaletaDeColores';
import { CgClose } from 'react-icons/cg';

export default class ModalVerImagenRecepcionFruta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            estilosmodal: estilosModal(),
            imagen: props.imagen
        }
    }

    showModal = () => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal();
        }, 200);
    }

    render() {
        return (
            <>
                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
                        <br />
                        <br />
                        {/*///////// Sección de imagen ////////*/}
                        <div className="divCuerpoModal">
                            <img src= {this.state.imagen} style={{ maxWidth: '100%', height: 'auto' }}/>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}
