import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoDocumentos.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioDocumentos from '../../Complements/Modales/ModalFormularioDocumentos/ModalFormularioDocumentos';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoDocumentos, getEstatus, getListaDocumentos } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class CatalogoDocumentos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      loadingspinners: true,
      errordeacceso: false,
      tabla: {
        encabezados: [
          "Nombre",
          "Tipo",
          "Descripción",
          "Estatus",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "50%",
          "10%",
          "150px"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "100px",
          "150px"
        ],
        elementos: []
      },
      estatus: [],
      listaelementosfiltrados: [],
      tipodocumentoseleccionado: "TodosLosTiposDeDocumentos",
      listatipodocumentos:[],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var resptipodocumentos = await getComboTipoDocumentos();
    if ( this.manejadorLlamadasApis (resptipodocumentos) ) {
      return;
    }
  
    var resp = await getListaDocumentos();
    if ( this.manejadorLlamadasApis (resp) ) {
      return;
    }
    
    var estatus = await getEstatus();
    if ( this.manejadorLlamadasApis (estatus) ) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;
    await this.setState({ 
        txtbuscador: '',
        tabla: tabla,
        estatus: estatus.data,
        listatipodocumentos:  resptipodocumentos.data
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ 
      loadingspinners: true, 
      txtbuscador: '',
      tipodocumentoseleccionado: "TodosLosTiposDeDocumentos",
      errordeacceso: false
    })
    this.componentDidMount();
  }

  cambiarValor = () => {
    //console.log("AAA");
  }

  filtrarTipoDocumento = async (tipo) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    await this.setState({ 
      tipodocumentoseleccionado: tipo 
    })

    var filtrotipodocumento = [];
    var filtrodocumentos = [];
    
    filtrotipodocumento = data;
    if(tipo != "TodosLosTiposDeDocumentos"){
      filtrotipodocumento = data.filter((item) => item.idtipo_documento == tipo)
    }
    filtrodocumentos = filtrotipodocumento.filter((item) => item.documento.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    if (filtrodocumentos.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrodocumentos)
}

  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrotipodocumento = [];
    var filtrodocumentos = [];

    this.setState({ txtbuscador: e.target.value });
    if (e.target.value.trim().length >= 3) {
      filtrotipodocumento = data;

        if(this.state.tipodocumentoseleccionado != "TodosLosTiposDeDocumentos"){
          filtrotipodocumento = data.filter((item) => item.idtipo_documento == this.state.tipodocumentoseleccionado)
          }

          filtrodocumentos = filtrotipodocumento.filter((item) => item.documento.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
        if (filtrodocumentos.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        this.filtro(1, filtrodocumentos)
        
    }else if(e.target.value.trim().length < 1){
      this.filtrarTipoDocumento(this.state.tipodocumentoseleccionado)
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo documento', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar documento', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles})

    if (accion == 1) {
      toast.success("Documento creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ txtbuscador: '', tipodocumentoseleccionado:"TodosLosTiposDeDocumentos"})
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Documento editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ txtbuscador: '', tipodocumentoseleccionado:"TodosLosTiposDeDocumentos"})
      this.componentDidMount();
    }
  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados:array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber,
      listapaginada: [],
      filtrolistapaginada:[]
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos ),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.listaelementosfiltrados),
      });
    }, 0);

  }

  borrarBusqueda = () => {
    this.setState({ 
        txtbuscador: '',
    })
    this.filtrarTipoDocumento(this.state.tipodocumentoseleccionado)
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  render() {
    return (

      <>
{this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioDocumentos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            activarSpinner={this.activarSpinner}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} 
            actualizarTablaAlAgregarOEditar={this.actualizarTablaAlAgregarOEditar} arraytipos={JSON.parse(JSON.stringify(this.state.listatipodocumentos))}/>
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Documentos</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                      <div className="selectBox ">
                          <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                              onChange={(event) => this.filtrarTipoDocumento(event.target.value)}>
                              {this.state.tipodocumentoseleccionado == "TodosLosTiposDeDocumentos" ?
                               <option selected="true" value='TodosLosTiposDeDocumentos'>Todos</option> : <option value='TodosLosTiposDeDocumentos'>Todos</option>}  
                              {this.state.listatipodocumentos.map((i, index) => (
                                  <option value={i.idtipo_documento} key={"tipo_" + index}  >{i.tipo} </option>
                              ))}
                          </select>
                      </div>
                  </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar documentos"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine/>&nbsp;Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.tipodocumentoseleccionado == 'TodosLosTiposDeDocumentos'?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.documento}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipodocumento}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.documento}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipodocumento}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }

                </tbody>
              </Table>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Paginacion   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.txtbuscador.length < 3 && this.state.tipodocumentoseleccionado == 'TodosLosTiposDeDocumentos' ? this.state.tabla.elementos : this.state.listaelementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
        {/* ////////////////////   Fin Sección Paginacion   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}