import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import "./ModalFormularioRanchosCultivo.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarArchivoCargadoJpgJpegPng, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { getListaRanchosCultivoUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IoMdClose } from 'react-icons/io';
import ModalBuscadorEncargados from '../ModalBuscadorEncargados/ModalBuscadorEncargados';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioRanchosCultivo extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      arrayzonas: props.arrayzonas,

      txtnombrerancho: (props.accion == 2) ? (props.item.rancho) : '',
      vermsgerrortxtrancho: false,
      msgerrortxtrancho: "",

      idzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : todoslosfiltrosdisponibles,
      vermsgerrortxtzona: false,
      msgerrortxtzona: '',

      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: "",

      archivocargado: null,
      txtnombrearchivo: (props.accion == 2) ? ("imagen_mapa_" + props.item.idcatalogo_rancho) : 'Buscar',
      vermsgerrortxtarchivo: false,
      msgerrortxtarchivo: '',

      arrayestatus: props.estatus,
      idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,

      encargados: (props.accion == 2) ? (props.item.encargados) : [],
      vermsgerrorencargados: false,
      msgerrortxtencargados: "",
      modalencargados: false,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
    }

  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    this.setState({ vermsgerrortxtrancho: false })
    this.setState({ vermsgerrortxtzona: false })
    this.setState({ vermsgerrortxtdescripcion: false })
    this.setState({ vermsgerrortxtarchivo: false })
    this.setState({ vermsgerrorencargados: false })
    let validacionescorrectas = true

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
    let txtnombrerancho = validarLetrasNumerosEspacios(this.state.txtnombrerancho.trim(), longitudvalida)
    if (txtnombrerancho.codigo == "invalid") {
      this.setState({ msgerrortxtrancho: txtnombrerancho.mensaje })
      this.setState({ vermsgerrortxtrancho: true })
      validacionescorrectas = false;
    }

    if (this.state.idzona == todoslosfiltrosdisponibles) {
      this.setState({ msgerrortxtzona: "Selecciona una zona" })
      this.setState({ vermsgerrortxtzona: true })
      validacionescorrectas = false;
    }
    let listaencargados = []

    if (this.state.encargados.length == 0) {
      this.setState({ msgerrortxtencargados: "Selecciona al menos un encargado" })
      this.setState({ vermsgerrorencargados: true })
      validacionescorrectas = false;
    } else {
      this.state.encargados.forEach(item => {
        listaencargados.push({ idusuario: item.idusuario })
      });
    }

    if (this.state.txtdescripcion.trim().length > 0) {
      longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
      if (txtdescripcion.codigo == "invalid") {
        this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
        this.setState({ vermsgerrortxtdescripcion: true })
        validacionescorrectas = false;
      }
    }

    if (this.props.accion == 1) {
      let archivocargado = validarArchivoCargadoJpgJpegPng(this.state.archivocargado)
      if (archivocargado.codigo == "invalid") {
        this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
        this.setState({ vermsgerrortxtarchivo: true })
        validacionescorrectas = false;
      }
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      const formdata = new FormData();
      formdata.append('idrancho', (this.props.accion == 2) ? this.props.item.idcatalogo_rancho : "0")
      formdata.append('rancho', this.state.txtnombrerancho)
      formdata.append('idzona', this.state.idzona)
      formdata.append('idestatus', this.state.idestatus)
      formdata.append('descripcion', this.state.txtdescripcion.trim())
      //formdata.append('encargados[]', listaencargados)
      if (this.state.archivocargado) {
        formdata.append('imagenmapa', this.state.archivocargado)
      }


      if (listaencargados.length > 0) {
        var index = 0;
        listaencargados.map((item) => {
          formdata.append(`encargados[${index}][idusuario]`, item.idusuario);
          index++;
        })
      }
    
      var resp = await getListaRanchosCultivoUpdate(formdata);

      if(resp.code == 0){
        if (this.state.accion == 1) {
            this.showModal('1')
        }
        else if (this.state.accion == 2) {
            this.showModal('2')
        }
      }
      else if(resp.code == 200){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 300){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 400){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if (resp.code == 1000) {
          this.setLoadingSpinner(false);
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
      }else{
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }

  }

  async seleccionarArchivo(event) {
    try {
      event.persist();
      var archi = event.target.files[0];
      if (event.target.files.length > 0) {
        this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
      }
    } catch (error) { }
  }




  ////////////////// encargado de rancho ///////////////////

  borrarEncargado = (idusuario) => {
    let lista = this.state.encargados;
    let nuevalista = [];
    lista.forEach(item => {
      if (item.idusuario != idusuario) {
        nuevalista.push(item)
      }
    });

    this.setState({ encargados: nuevalista })
  }
  cerrarModalBuscadorEmpleados = (datos) => {
   

    if (datos != 0) {
      let listaencargados = this.state.encargados;

      var existe = listaencargados.find((item) => item.idusuario == datos.idusuario);
      
      if (existe != undefined) {
        toast.info("El usuario " + datos.nombre + " ya existe", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      } else {
        listaencargados.push({ idusuario: datos.idusuario, nombre: datos.nombre })
        this.setState({
          encargados: listaencargados
        })
      }
     
    }
    this.setState({
      modalencargados: false,
    })


  }
  ////////////// fin de encargado de rancho ////////////////

  render() {
    return (

      <>

        {this.state.modalencargados == true ? (
          <ModalBuscadorEncargados
            setLoadingSpinner={this.setLoadingSpinner}
            show={this.state.modalencargados}
            // accion={1 /*this.state.accionmodal*/}
            cerrarModalBuscadorEmpleados={this.cerrarModalBuscadorEmpleados} />
        ) : null}


        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre rancho</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnombrerancho} onChange={this.escribir("txtnombrerancho")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombrerancho}</label>}

                {this.state.vermsgerrortxtrancho ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrancho}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idzona", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idzona} >Seleccionar </option>
                          {this.state.arrayzonas.map((i, index) => (
                            <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.idzona} >{i.zona} </option>
                          ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                {this.state.vermsgerrortxtzona ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtzona}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}



                {/*////////////////////////////// Encargados /////////////////////// */}
                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Encargado(s)
                  <button onClick={() => this.setState({ modalencargados: true })} style={{ background: "white", marginLeft:"calc(100% - 170px)", borderRadius:"30px", width:"32px", border:"2px solid blue" }}>+</button></p>

                {
                  this.state.encargados.length == 0 ? (
                  <label className='lblTextoModal letraNormal'>
                    Selecciona al menos uno
                  </label>) : (
                    this.state.encargados.map((item, index) => (
                      <>
                        <div style={{ display: "flex" }}>
                          <label className='lblTextoModal letraNormal'
                            style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                            {item.nombre}
                          </label>
                          {this.state.encargados.length > 1 ? (
                            <IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                              onClick={() => this.borrarEncargado(item.idusuario)} />
                          ) : null}
                        </div>
                      </>
                    ))
                  )

                }

                {this.state.vermsgerrorencargados ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtencargados}</p> : null}

                {/*////////////////////////////// Fin encargados /////////////////////// */}

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Imagen de mapa (jpg/jpeg/png)</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='centrarBotonFile' >
                    <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png"
                      onChange={(event) => this.seleccionarArchivo(event)} />
                    <label for="file-2"><MdCloudUpload />
                      <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                    </label>
                  </div>
                ) : (
                  <div className='centrarBotonFile' >
                    <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png" disabled />
                    <label for="file-2"><MdCloudUpload />
                      <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                    </label>
                  </div>
                )}

                {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}
                <br />


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                }
              </div>
              {/*this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null*/}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

