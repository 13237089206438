import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarArchivoCargadoJpgJpegPng, validarNumerosDecimales } from '../../../Services/Validaciones';
import { postGuardarPagoVenta, guardarPagoFacturaGlobalCompras, guardarPagoPagoGlobalVentas } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { MdCloudUpload } from 'react-icons/md';

export default class ModalFormularioGuardarPago extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,

            txtformapago: 0,
            txtmonto: '',
            txtfechapago: '',
            fechapago: '',

            vermsgerrorformapago: false,
            vermsgerrormonto: false,
            vermsgerrorfechapago: false,
            msgerrorformapago: "",
            msgerrormonto: "",
            msgerrorfechapago: "",

            archivocargado: null,
            txtnombrearchivo: 'Buscar',
            vermsgerrortxtarchivo: false,
            msgerrortxtarchivo: '',

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorbordertipo: estiloComboBox().colorbordercombo,

        }

    }

    async componentDidMount() {

    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    async seleccionarArchivo(event) {
        try {
            event.persist();
            var archi = event.target.files[0];
            if (event.target.files.length > 0) {
                this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
            }
        } catch (error) { }
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorformapago: false })
        this.setState({ vermsgerrormonto: false })
        this.setState({ vermsgerrorfechapago: false })
        this.setState({ vermsgerrortxtarchivo: false })

        let validacionescorrectas = true

        if (this.state.archivocargado != null) {
            let archivocargado = validarArchivoCargadoJpgJpegPng(this.state.archivocargado)
            if (archivocargado.codigo == "invalid") {
                this.setState({ msgerrortxtarchivo: archivocargado.mensaje })
                this.setState({ vermsgerrortxtarchivo: true })
                validacionescorrectas = false;
            }
        }else{
            this.setState({ msgerrortxtarchivo: "Selecciona un archivo" })
            this.setState({ vermsgerrortxtarchivo: true })
            validacionescorrectas = false;
        }

        if (this.state.txtformapago == 0) {
            this.setState({ msgerrorformapago: "Selecciona una forma de pago" })
            this.setState({ vermsgerrorformapago: true })
            validacionescorrectas = false;
        }

        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtmonto = validarNumerosDecimales(this.state.txtmonto);
        if (txtmonto.codigo == "invalid") {
            this.setState({ msgerrormonto: txtmonto.mensaje })
            this.setState({ vermsgerrormonto: true })
            validacionescorrectas = false;
        }

        if (this.state.txtfechapago.length < 1) {
            this.setState({ msgerrorfechapago: "Seleccione una fecha de pago" })
            this.setState({ vermsgerrorfechapago: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);

            const formdata = new FormData();
            formdata.append('idventa', this.props.idventa)
            formdata.append('idtipopago', this.state.txtformapago)
            formdata.append('monto', this.state.txtmonto)
            formdata.append('fechapago', this.setFormatDateSave(this.state.txtfechapago))

            if (this.state.archivocargado != null) {
                formdata.append('comprobante', this.state.archivocargado)
            }


            var resp = {};

            if (this.props.accion == 1) {
                resp = await postGuardarPagoVenta(formdata)
            } else if (this.props.accion == 6) {
                resp = await guardarPagoFacturaGlobalCompras(formdata)
            } else if (this.props.accion == 7) {
                resp = await guardarPagoPagoGlobalVentas(formdata)
            }

            if(resp.code == 0){
                if (this.props.accion == 1) {
                    toast.success("Pago agregado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                } else if (this.props.accion == 7) {
                    toast.success("Pago de pago global agregado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
                this.showModal({ accion: 1, resp: resp })
            }
            else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false)
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }
        }
    }

    setFormatDateSave = (fecha) => {
        var fechaseteada = "";
        if (fecha != null) {
            var [year, month, day] = fecha.split("-");

            if (day != undefined && month != undefined && year != undefined) {
                fechaseteada = `${day}/${month}/${year}`
                return fechaseteada;
            } else {
                fechaseteada = fecha;
            }
        }

        return fechaseteada;
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: 1, resp: undefined })} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            {this.props.accion == 1 ?
                                <div className='row'>
                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID venta
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.props.idventa}
                                        </div>
                                    </div>
                                    <div className='col-8 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            Cliente
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.props.cliente}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Forma de pago</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" onChange={this.escribir("txtformapago")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.props.listaformaspago.length > 0 ? this.props.listaformaspago.map((i, index) => (
                                                    <option value={i.idtipo_pago} key={"tipo_" + index}>{i.pago}</option>
                                                )) : null}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorformapago ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorformapago}</p> : null}



                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Monto</p>
                                <input className='lblTextoModal letraNormal'
                                    style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                    type={'number'} value={this.state.txtmonto} onChange={this.escribir("txtmonto")}></input>

                                {this.state.vermsgerrormonto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrormonto}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de pago</p>
                                <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                    <DatePicker
                                        selected={this.state.fechapago}
                                        onChange={(date) => this.cambiarFecha({ llave: "fechapago", valor: date })}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>

                                {this.state.vermsgerrorfechapago ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechapago}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comprobante de pago (jpg/jpeg/png)</p>
                                <div className='centrarBotonFile' >
                                    <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept={".jpg, .jpeg, .png" /*accept=".txt, .pdf, .csv, .xsl, .doc, .docx, .jpg, .jpeg, .png"*/}
                                        onChange={(event) => this.seleccionarArchivo(event)} />
                                    <label for="file-2"><MdCloudUpload />
                                        <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                                    </label>
                                </div>

                                {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}

                            </div>

                        </div>

                        {this.state.accion == 1 || this.state.accion == 6 || this.state.accion == 7 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({ accion: 1, resp: undefined })}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }

}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));