import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoClientes.css';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion,
  estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoCliente, getEstatus, getListaCatalogoCliente } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ModalFormularioClientes from '../../Complements/Modales/ModalFormularioClientes/ModalFormularioClientes';
import { AiFillCheckSquare, AiFillCloseSquare } from 'react-icons/ai';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

const todaslasopcionesdisponibles = -1

export default class CatalogoClientes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idtipoclienteseleccionado: todaslasopcionesdisponibles,

      arraytipos: [],

      tabla: {
        encabezados: [
          "Clave",
          "Nombre",
          "Tipo cliente",
          "Contacto",
          "Datos fiscales",
          "Estatus",
          ""],
        dimensiones: [
          "8%",
          "30%",
          "12%",
          "12%",
          "12%",
          "8%",
          "13%"
        ],
        mindimensiones: [
          "50px",
          "350px",
          "150px",
          "150px",
          "150px",
          "70px",
          "160px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      redireccionando: false,
      modaldetalles: false,
      itemseleccionado: null,

      idclienteseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }
    this.setState({ txtbuscador: '' })

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var respcc = await getListaCatalogoCliente();
    if (this.manejadorLlamadasApis(respcc)) {
      return;
    }
    var tabla = this.state.tabla;
    tabla.elementos = JSON.parse(JSON.stringify(respcc.data));

    var resp = await getComboTipoCliente();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }
    var arraytemp = JSON.parse(JSON.stringify(resp.data));


    this.setState({ estatus: estatus.data, tabla: tabla, arraytipos: arraytemp })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {

    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true });
    this.setState({ txtbuscador: '' })
    this.setState({ idtipoclienteseleccionado: todaslasopcionesdisponibles })
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  filtrarCombo = async (idtipo) => {

    await this.setState({ idtipoclienteseleccionado: idtipo })

    var filtroclientes = this.filtrarBusqueda(
      idtipo, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroclientes.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroclientes)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroclientes = this.filtrarBusqueda(
        this.state.idtipoclienteseleccionado, cadenain.toLocaleLowerCase())

      if (filtroclientes.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroclientes)
    }

  }

  filtrarBusqueda = (idtipo, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrotipocliente = tabla.elementos;

    if (idtipo != todaslasopcionesdisponibles) {
      filtrotipocliente = filtrotipocliente.filter((item) => item.idtipo_cliente == idtipo)
    }

    if (textobusqueda.length < 3) {
      return filtrotipocliente
    }

    return filtrotipocliente.filter(
      (item) => item.nombre.toLocaleLowerCase().includes(textobusqueda)
        || item.clave.toLocaleLowerCase().includes(textobusqueda))

  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo cliente', accionmodal: 1 })
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (opciones) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (opciones.accion == 1) {
      this.setState({ loadingspinners: true })
      this.redireccionar({ contenido: opciones.id })
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }


  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber
    });
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  redireccionar = (idcliente) => {
    localStorage.setItem("dato", [idcliente.contenido]);
    this.setState({ redireccionando: true })
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }


  render() {
    return (

      <>


        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesClientes bandera={'salir'} />
          </>
          : null}

        <ToastContainer />
        {(this.state.redireccionando) ? (<AccionesClientes bandera={'goreferencias'} idcatalogoclientes={this.state.idclienteseleccionado} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioClientes titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} arraytipos={JSON.parse(JSON.stringify(this.state.arraytipos))} />
        ) : null}

        <Navbar />
        <div className="container-fluid anchoMaximo" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximoExtendido">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados' style={{ minWidth: '200px' }} >
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Clientes  </label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event.target.value)}>
                      {<option selected={this.state.idtipoclienteseleccionado == todaslasopcionesdisponibles} value={todaslasopcionesdisponibles}>Todos</option>}
                      {this.state.arraytipos.map((i, index) => (
                        <option value={i.idtipo_cliente} key={"tipo_" + index} selected={this.state.idtipoclienteseleccionado == i.idtipo_cliente} >{i.tipo} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar cliente"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoExtendido ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.trim().length < 3 && this.state.idtipoclienteseleccionado == todaslasopcionesdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                          {item.clave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                          {item.nombre}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla }}>
                          {item.tipocliente}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla }}>
                          {item.contactoprincipal == undefined || item.contactoprincipal.length == 0 ? <b className='lblSinDatos'>Sin contacto</b> : item.contactoprincipal.nombre + " " + item.contactoprincipal.telefono}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla }}>
                          {!item.tienedatosfiscales /*|| item.datosfiscales.length == 0*/ ? <b className='lblSinDatos'>Incompletos <AiFillCloseSquare style={{ fontSize: 'x-large' }} /></b> : "Completados"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], color: this.state.colorencabezadostabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                          <label onClick={this.redireccionar.bind(this, { contenido: item.idcatalogo_clientes })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                            <b className='btnVerTabla'>Más información</b>
                          </label>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.clave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.nombre}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.tipocliente}
                        </td>
                        
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          {item.contactoprincipal == undefined || item.contactoprincipal.length == 0 ? <b className='lblSinDatos'>Sin contacto</b> : item.contactoprincipal.nombre + " " + item.contactoprincipal.telefono}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {!item.tienedatosfiscales /*|| item.tienedatosfiscales.length == 0*/ ? <b className='lblSinDatos'>Incompletos <AiFillCloseSquare style={{ fontSize: 'x-large' }} /></b> : <> Completados <AiFillCheckSquare className='datosCorrectos' /> </>}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          <label onClick={this.redireccionar.bind(this, { contenido: item.idcatalogo_clientes })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }}>
                            <b className='btnVerTabla'>Más información</b>
                          </label>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idtipoclienteseleccionado == todaslasopcionesdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesClientes({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'goreferencias') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/cliente"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}

