import React from 'react'
//import Navbar from '../../Complements/Navbar/Navbar'
import './EditarRequisicion.css';
import {
  estiloBtnActualizar, estiloBtnEditar,
  //estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados,
  estiloTabla
} from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
//import { FaTrashAlt } from 'react-icons/fa';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
//import { getComboUnidadMedida } from '../../Services/Api';
//import ModalFormularioEditarRequisicion from '../../Complements/Modales/ModalFormularioEditarRequisicion/ModalFormularioEditarRequisicion';
//import { validarNumerosDecimales3 } from '../../Services/Validaciones';

//import Autocomplete from 'react-autocomplete';

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      listaproductos: props.listaproductos ? props.listaproductos : [],

      tabla: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad"
        ],
        dimensiones: [
          "26%",
          "22%",
          "17%"
        ],
        mindimensiones: [
          "140px",
          "200px",
          "130px"
        ],
        elementos: []
      },

      listaunidadmedida: [],
      listaimcompletos: [],
      listadetenidos: [],

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  render() {
    return (
      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        <br />

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
        <div className="container-fluid containerTablaAA anchoTablaProducto">
          <div className="container-fluid">
            <div className='row'>
              <div>

                <Table hover>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                          textAlign: (index > 0) ? 'center' : 'left' }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.state.listaproductos.map((item, index) => (
                      <tr key={index}
                        className={''} >
                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.Producto}
                        </td>
                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                          {item.Unidadmedida}
                        </td>
                        <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                          {item.cantidad}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
