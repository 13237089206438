import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsFillEyeFill } from 'react-icons/bs';
import { BsTrash } from "react-icons/bs"
import { BiEdit } from "react-icons/bi";
import { FaTrashAlt } from 'react-icons/fa';
import { ImDownload3 } from 'react-icons/im'
import { GrDocumentPdf, GrDocumentTxt } from 'react-icons/gr';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnActualizar } from '../../../Services/PaletaDeColores';
import { getDetallesCompra, getFormasPagoCompras, eliminarPago, eliminarFactura, agregarDescuento, eliminarFacturaGlobalGeneral, eliminarPagoGlobalGeneral, eliminarPagoFacturaGlobalCompras, eliminarPagoPagoGlobalCompras } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

import { formatoNumero2 } from '../../../Services/Validaciones';

import ModalFormularioGuardarPago from './ModalFormularioGuardarPago';
import ModalFormularioGuardarFactura from './ModalFormularioGuardarFactura';
import ModalFormularioGuardarFacturaGlobal from './ModalFormularioGuardarFacturaGlobal';
import ModalFormularioGuardarPagoGlobal from './ModalFormularioGuardarPagoGlobal';
import ModalPedirPassword from '../ModalRectificacion/ModalPedirPassword';
import ModalVerImagen from './ModalVerImagen';

export default class ModalFormularioDetallesCompraNormal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            titulo: props.titulomodal,
            total: 0,
            loadingspinners: true,
            tabla: {
                encabezados: [
                    "Formas de pago",
                    "Fecha de pago",
                    "Monto",
                    "Comprobante de pago",
                    "",
                    "",
                    ""
                ],
                encabezadosFactura: [
                    "ID",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    "",
                    "",
                    ""
                ],
                encabezadosPagosGlobal: [
                    "Formas de pago",
                    "Fecha de pago",
                    "Monto",
                    "Comprobante de pago",
                    "",
                    ""
                ],
                encabezadosComprasGlobal: [
                    "ID compra",
                    "Fecha de compra",
                    "Subtotal",
                    "Descuento",
                    "Total"
                ],
                encabezadosFacturaGlobal: [
                    "ID compra",
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    "",
                    ""
                ],
                encabezadosFacturaGlobal2: [
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%",
                    "5%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "140px",
                    "150px",
                    "10px",
                    "10px"
                ],
                mindimensiones2: [
                    "100px",
                    "100px",
                    "100px",
                    "140px",
                    "10px",
                    "10px"
                ],
                mindimensionesFacturasGlobal: [
                    "70px",
                    "70px",
                    "100px",
                    "100px",
                    "140px",
                    "10px",
                    "10px"
                ],
                dimensionesFacturasGlobal: [
                    "10%",
                    "10%",
                    "15%",
                    "15%",
                    "25%",
                    "5%",
                    "5%"
                ],
                elementos: this.props.respproductos,
            },

            condescuento: false,
            txtdescuento: "0.00",
            listaformaspago: [],
            rutaseleccionada: "",

            almacenseleccionado: undefined,
            listaalmacenes: this.props.listaalmacenes,
            masdeunprincipal: false,
            modalguardarpago: false,
            modalguardarfactura: false,
            modalguardarpagoglobal: false,
            modalguardarfacturaglobal: false,
            modalpedirpassword: false,
            modalverimg: false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listaproductosfiltrados: this.props.item.productos,

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
           
            infoDetalle: {},
            pagoEliminar: {},
            pagoEliminarEnabled: false,
            facturaEliminar: {},
            facturaEliminarEnabled: false,
            pagofacturaGlobalGeneralEliminar: {},
            facturaGlobaGeneralEliminarEnabled: false,
            pagoGlobaGeneralEliminarEnabled: false,
            haycambios: false,
            pagoGlobalEliminar: {},
            pagoGlobalEliminarEnabled: false,
            pagoPagoGlobalEliminarEnabled: false,
        }

    }

    async componentDidMount() {

        this.setState({ loadingspinners: true })

        //se obtiene los detalles de la compra
        var respdetallescompra = await getDetallesCompra({ idcompra: this.props.item.idcompra });
        if (this.manejadorLlamadasApis(respdetallescompra)) {
            this.setState({ loadingspinners: false })
            return;
        }

        //se obtiene las formas de pago
        var respformaspago = await getFormasPagoCompras();
        if (this.manejadorLlamadasApis(respformaspago)) {
            this.setState({ loadingspinners: false })
            return;
        }

        await this.setState({
            listaformaspago: respformaspago.data,
            infoDetalle: respdetallescompra.data,
            haycambios: false
        });

        this.setState({
            loadingspinners: false,
            condescuento: respdetallescompra.data.tienedescuento,
            txtdescuento: respdetallescompra.data.descuento,
            pagoEliminarEnabled: false,
            facturaEliminarEnabled: false,
            facturaGlobaGeneralEliminarEnabled: false,
            pagoGlobaGeneralEliminarEnabled: false,
            pagoGlobalEliminarEnabled: false,
            pagoPagoGlobalEliminarEnabled: false
        })

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    guardarDescuento = async () => {
        if (this.state.haycambios == true) {
            var descuentoAux = "0.00", actualizar = false;
            this.state.txtdescuento == "" ? this.state.txtdescuento = "0.00" : this.state.txtdescuento = this.state.txtdescuento;
            if (parseFloat(this.state.txtdescuento) != parseFloat(this.state.infoDetalle.descuento)) {
                if (parseFloat(this.state.txtdescuento) < 0) {
                    toast.error("El descuento no puede ser negativo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                } else if (parseFloat(this.state.txtdescuento) > parseFloat(this.state.infoDetalle.totalAnterior)) {
                    toast.error("El descuento no puede superar la totalidad", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                } else {
                    actualizar = true;
                    descuentoAux = this.state.txtdescuento != "" ? this.state.txtdescuento : "0.00";
                }
            } else if (this.state.condescuento != this.state.infoDetalle.tienedescuento && this.state.condescuento == false) {
                actualizar = true;
            } else {
                toast.info("No hubo cambios en el descuento", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            if (actualizar == true) {
                this.setState({ loadingspinners: true })
                let request = {
                    idcompra: this.props.item.idcompra,
                    descuento: descuentoAux
                }
                var respdetallescompra = await agregarDescuento(request);
                if (this.manejadorLlamadasApis(respdetallescompra)) {
                    this.setState({ loadingspinners: false })
                    return;
                }
                toast.success("Descuento guardado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
            }

        }
    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 1) {
            this.setState({ titulomodal: 'Guardar pago', accionmodal: 1, modalguardarpago: true })
        } else if (ban.opcion == 2) {
            this.setState({ titulomodal: 'Guardar factura', accionmodal: 2, modalguardarfactura: true })
        } else if (ban.opcion == 3) {
            this.setState({ titulomodal: 'Guardar pago global', accionmodal: 3, modalguardarpagoglobal: true })
        } else if (ban.opcion == 4) {
            this.setState({ titulomodal: 'Guardar factura global', accionmodal: 4, modalguardarfacturaglobal: true })
        } else if (ban.opcion == 5) {
            this.setState({ titulomodal: ban.titulo, accionmodal: 5, modalverimg: true, rutaseleccionada: ban.img })
        } else if (ban.opcion == 6) {
            this.setState({ titulomodal: 'Guardar pago de factura global', accionmodal: 6, modalguardarpago: true })
        } else if (ban.opcion == 7) {
            this.setState({ titulomodal: 'Guardar pago de pago global', accionmodal: 7, modalguardarpago: true })
        } else if (ban.opcion == 8) {
            this.setState({ titulomodal: 'Editar factura global', accionmodal: 8, modalguardarfacturaglobal: true })
        } else if (ban.opcion == 9) {
            this.setState({ titulomodal: 'Editar pago global', accionmodal: 9, modalguardarpagoglobal: true })
        }
    }

    cerrarModal = async (item) => {
        if (item.accion == 1) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarpago: !this.state.modalguardarpago })
        } else if (item.accion == 2) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarfactura: !this.state.modalguardarfactura })
        } else if (item.accion == 3) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarpagoglobal: !this.state.modalguardarpagoglobal })
        } else if (item.accion == 4) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarfacturaglobal: !this.state.modalguardarfacturaglobal })
        } else if (item.accion == 5) {
            this.setState({ modalverimg: !this.state.modalverimg })
        }
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value, haycambios: true });
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 300 || resp.code == 200) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    eliminarPagoFactura = (item) => {
        if (item.opcion == 1) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idcompra": item.pago.idcompra,
                "password": ""
            }
            this.setState({ pagoEliminar: pagoDelete, pagoEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 2) {
            let facturaDelete = {
                "idfactura": item.factura.idfactura,
                "idcompra": item.factura.idcompra,
                "password": ""
            }
            this.setState({ facturaEliminar: facturaDelete, facturaEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 3) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idcompra": item.pago.idcompra,
                "password": ""
            }
            this.setState({ pagoGlobalEliminar: pagoDelete, pagoGlobalEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 4) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idcompra": item.pago.idcompra,
                "password": ""
            }
            this.setState({ pagoGlobalEliminar: pagoDelete, pagoPagoGlobalEliminarEnabled: true, modalpedirpassword: true })
        }
    }

    eliminarPagoFacturaGlobalGeneral = (item) => {
        let request = {
            "idcompra": this.props.item.idcompra,
            "password": ""
        }
        if (item.opcion == 1) {
            this.setState({ pagofacturaGlobalGeneralEliminar: request, pagoGlobaGeneralEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 2) {
            this.setState({ pagofacturaGlobalGeneralEliminar: request, facturaGlobaGeneralEliminarEnabled: true, modalpedirpassword: true })
        }
    }

    cerrarModalPedirPassword = async (accion) => {
        
        this.setState({ modalpedirpassword: !this.state.modalpedirpassword })
        if (accion != 0) {
            this.setLoadingSpinner(true)
            if (this.state.pagoEliminarEnabled == true) {
                this.state.pagoEliminar.password = accion;

                var resp = await eliminarPago(this.state.pagoEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }

                let pagoDelete = {
                    "idpago": 0,
                    "idcompra": 0,
                    "password": ""
                }
                this.setState({ pagoEliminar: pagoDelete, pagoEliminarEnabled: false })
                toast.success("Pago eliminado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
               
            } else if (this.state.facturaEliminarEnabled == true) {
                this.state.facturaEliminar.password = accion;

                var resp = await eliminarFactura(this.state.facturaEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }

                let facturaDelete = {
                    "idfactura": 0,
                    "idcompra": 0,
                    "password": ""
                }
                this.setState({ facturaEliminar: facturaDelete, facturaEliminarEnabled: false })
                toast.success("Factura eliminada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
                
            } else if (this.state.facturaGlobaGeneralEliminarEnabled == true) {
                this.state.pagofacturaGlobalGeneralEliminar.password = accion;

                var resp = await eliminarFacturaGlobalGeneral(this.state.pagofacturaGlobalGeneralEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }

                let request = {
                    "idcompra": 0,
                    "password": ""
                }
                this.setState({ pagofacturaGlobalGeneralEliminar: request, facturaGlobaGeneralEliminarEnabled: false })
                toast.success("Factura global eliminada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
               
            } else if (this.state.pagoGlobaGeneralEliminarEnabled == true) {
                this.state.pagofacturaGlobalGeneralEliminar.password = accion;

                var resp = await eliminarPagoGlobalGeneral(this.state.pagofacturaGlobalGeneralEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }

                let request = {
                    "idcompra": 0,
                    "password": ""
                }
                this.setState({ pagofacturaGlobalGeneralEliminar: request, pagoGlobaGeneralEliminarEnabled: false })
                toast.success("Pago global eliminado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
                
            } else if (this.state.pagoGlobalEliminarEnabled == true) {
                this.state.pagoGlobalEliminar.password = accion;
                var resp = await eliminarPagoFacturaGlobalCompras(this.state.pagoGlobalEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }

                let request = {
                    "idcompra": 0,
                    "idpago": 0,
                    password: ""
                }
                this.setState({ pagoGlobalEliminar: request, pagoGlobalEliminarEnabled: false })
                toast.success("Pago global eliminado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
               
            } else if (this.state.pagoPagoGlobalEliminarEnabled == true) {
                this.state.pagoGlobalEliminar.password = accion;
                var resp = await eliminarPagoPagoGlobalCompras(this.state.pagoGlobalEliminar)
                if(this.manejadorLlamadasApis(resp)){
                    this.setState({ loadingspinners: false })
                    return;
                }
                let request = {
                    "idcompra": 0,
                    "idpago": 0,
                    password: ""
                }
                this.setState({ pagoGlobalEliminar: request, pagoPagoGlobalEliminarEnabled: false })
                toast.success("Pago global eliminado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                await this.componentDidMount()
               
            }
        }
    }

    calcularSubtotal(listacompras) {
        var subtotal = 0.00;
        listacompras.map((compra) => {
            subtotal = subtotal + parseFloat(compra.precio)
        })
        return subtotal;
    }

    calcularDescuento(listacompras) {
        var descuento = 0.00;
        listacompras.map((compra) => {
            descuento = descuento + parseFloat(compra.descuento)
        })
        return descuento;
    }

    calcularTotal(listacompras) {
        var total = 0.00;
        listacompras.map((compra) => {
            total = total + parseFloat(compra.total)
        })
        return total;
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalguardarpago == true) ? (
                    <ModalFormularioGuardarPago
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarpago}
                        accion={this.state.accionmodal}
                        listaformaspago={this.state.listaformaspago}
                        idcompra={this.props.item.idcompra}
                        proveedor={this.props.item.proveedor}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarfactura == true) ? (
                    <ModalFormularioGuardarFactura
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarfactura}
                        accion={this.state.accionmodal}
                        idcompra={this.props.item.idcompra}
                        proveedor={this.props.item.proveedor}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarpagoglobal == true) ? (
                    <ModalFormularioGuardarPagoGlobal
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarpagoglobal}
                        accion={this.state.accionmodal}
                        listaproductos={this.props.item.productos}
                        listacompras={this.props.listacompras}
                        idcompra={this.props.item.idcompra}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarfacturaglobal == true) ? (
                    <ModalFormularioGuardarFacturaGlobal
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarfacturaglobal}
                        accion={this.state.accionmodal}
                        idcompra={this.props.item.idcompra}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalpedirpassword == true) ? (
                    <ModalPedirPassword titulomodal={"Autorizar acción"}
                        cerrarModal={this.cerrarModalPedirPassword}
                        show={this.state.modalpedirpassword}
                        accion={this.state.accionmodal} />
                ) : null}

                {(this.state.modalverimg == true) ? (
                    <ModalVerImagen
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalverimg}
                        accion={this.state.accionmodal}
                        img={this.state.rutaseleccionada} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('5')} />
                        <label className="modalLblTitulo letraTitulos">Detalles de compra {this.props.item.idcompra}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal">

                                {/* ////////////////////   Detalles general   ////////////////////////////////////// */}
                                <div className='row' style={{ border: '2px solid #2371FD', borderRadius: '10px' }}>
                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID compra
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.props.item.idcompra}
                                        </div>
                                    </div>
                                    <div className='col-9 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            Proveedor
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.state.infoDetalle != undefined ? this.state.infoDetalle.proveedor : null}
                                        </div>
                                    </div>


                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        {this.state.condescuento ?
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='lblTexto letraTitulos' style={{ backgroundColor: '#AFE0F7', border: '1px solid #2371FD', textAlign: 'center' }}>
                                                    <label><span style={{ color: this.state.colortipolbl }}>Nuevo total: </span><span> ${this.state.infoDetalle != undefined ? formatoNumero2(parseFloat(this.state.infoDetalle.totalAnterior) - (this.state.txtdescuento != "" ? parseFloat(this.state.txtdescuento) : 0)) : null}</span></label>

                                                </div>
                                                <span style={{ color: '#8B8B8B' }}><del>total anterior: ${formatoNumero2(this.state.infoDetalle.totalAnterior)}</del></span>
                                            </div>
                                            :
                                            <div className='lblTexto letraTitulos' style={{ textAlign: 'center' }}>
                                                <label><strong><span style={{ color: this.state.colortipolbl, marginRight: '5px' }}>Total actual: </span><span> ${this.state.infoDetalle != undefined ? formatoNumero2(this.state.infoDetalle.total) : null}</span></strong></label>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <label><span style={{ color: this.state.colortipolbl }}>Pagado: </span>

                                                {
                                                    this.state.infoDetalle != undefined ? (

                                                        isNaN(parseFloat(this.state.infoDetalle.pagado)) ? (this.state.infoDetalle.pagado) : ("$" + formatoNumero2(this.state.infoDetalle.pagado))

                                                    ) : null

                                                }




                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <label><span style={{ color: this.state.colortipolbl }}>Pendiente: </span>
                                                {
                                                    this.state.infoDetalle != undefined ? (

                                                        isNaN(parseFloat(this.state.infoDetalle.pendiente)) ? (this.state.infoDetalle.pendiente) : ("$" + formatoNumero2(this.state.infoDetalle.pendiente))

                                                    ) : null

                                                }

                                            </label>
                                        </div>
                                    </div>


                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' onClick={() => this.setState({ condescuento: !this.state.condescuento, haycambios: true })}>
                                            <input type="checkbox" checked={this.state.condescuento} /> Descuento
                                        </div>
                                    </div>
                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <input className='letraNormal' style={{ border: (parseFloat(this.state.txtdescuento) > parseFloat(this.state.infoDetalle.total) || parseFloat(this.state.txtdescuento) < 0) ? '2px solid #dc3545' : '2px solid #2371FD', width: '100%', display: this.state.condescuento ? "" : "none" }}
                                            type={'number'} value={this.state.txtdescuento} onChange={this.escribir("txtdescuento")}>
                                        </input>
                                    </div>

                                    {/*<div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <Button className="btnAceptarModal" onClick={this.verModalDetalles.bind(this, { opcion: "4" })}>Factura global</Button>
                                        </div>
                                    </div>*/}
                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <Button className="btnAceptarModal" onClick={this.verModalDetalles.bind(this, { opcion: "3" })}>Pago global</Button>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                {/* ////////////////////   Tabla pago global  ////////////////////////////////////// */}
                                <br />
                                {
                                    this.state.infoDetalle.pagoglobal == true ?
                                        (<>

                                            <br />
                                            <div className='row' style={{ background: '#EBF9FF', borderRadius: '10px' }}>
                                                <div className='row' style={{ marginTop: '20px' }}>
                                                    <div className='col-8'>
                                                        <label className="lblEncabezadosTabla letraTitulos"><strong>Pago global</strong></label>
                                                    </div>
                                                    <div className='col-3 containerSubTablaAA'>
                                                        <button className='btnActualizar letraNormal'
                                                            style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: '2px solid #2371FD' }}
                                                            onClick={this.verModalDetalles.bind(this, { opcion: "9" })}>
                                                            <BiEdit className="icoBotones" />Editar</button>
                                                    </div>
                                                    <div className='col-1 containerSubTablaAA'>
                                                        <button className='divBotonesTrash btnEliminarModalDetalles'
                                                            onClick={this.eliminarPagoFacturaGlobalGeneral.bind(this, { opcion: "1" })}>
                                                            <FaTrashAlt className="icoBotones iconbtneliminar" /></button>
                                                    </div>
                                                </div>

                                                <br />
                                                <br />
                                                <div className="container-fluid containerTabla">
                                                    <div className="container-fluid anchoMaximo ">
                                                        <div className="containerSubTabla">

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Compras relacionadas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosComprasGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                                    {this.state.infoDetalle.global.comprasrelacionadas.length > 0 ?
                                                                        this.state.infoDetalle.global.comprasrelacionadas.map((compra) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.idcompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.fechacompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(compra.precio)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(compra.descuento)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(compra.total)}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}

                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="containerSubTablaAA" colSpan={2} style={{ color: this.state.colorcontenidotabla }}>
                                                                            <b>Totales acumulados</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularSubtotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularDescuento(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularTotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            <br />

                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <label className="containerSubTablaAA"><strong>Pago:</strong></label>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                                        onClick={this.verModalDetalles.bind(this, { opcion: "7" })}>
                                                                        {"Agregar pago"}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosPagosGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.pagos.length > 0 ?
                                                                        this.state.infoDetalle.global.pagos.map((pago) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.tipopago}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.fechapago}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(pago.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.comprobante}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={pago.rutaComprobante} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 4, pago: pago })}>
                                                                                        <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>

                                                            <br />

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Facturas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosFacturaGlobal2.map((titulo, index) => (
                                                                            <th
                                                                                className={(index > 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.facturas.length > 0 ?
                                                                        this.state.infoDetalle.global.facturas.map((factura) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.idfactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.fechafactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(factura.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.factura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={factura.rutaFactura} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </>)
                                        : null
                                }

                                {/* ////////////////////   Tabla factura global  ////////////////////////////////////// */}
                                {
                                    this.state.infoDetalle.facturaglobal == true ?
                                        (<>

                                            <br />
                                            <div className='row' style={{ background: '#EBF9FF', borderRadius: '10px' }}>
                                                <div className='row' style={{ marginTop: '20px' }}>
                                                    <div className='col-8'>
                                                        <label className="lblEncabezadosTabla letraTitulos"><strong>Factura global</strong></label>
                                                    </div>
                                                    <div className='col-3 containerSubTablaAA'>
                                                        <button className='btnActualizar letraNormal'
                                                            style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: '2px solid #2371FD' }}
                                                            onClick={this.verModalDetalles.bind(this, { opcion: "8" })}>
                                                            <BiEdit className="icoBotones" />Editar</button>
                                                    </div>
                                                    <div className='col-1 containerSubTablaAA'>
                                                        <button className='divBotonesTrash btnEliminarModalDetalles'
                                                            onClick={this.eliminarPagoFacturaGlobalGeneral.bind(this, { opcion: "2" })}>
                                                            <FaTrashAlt className="icoBotones iconbtneliminar" /></button>
                                                    </div>
                                                </div>

                                                <br />
                                                <br />
                                                <div className="container-fluid containerTabla">
                                                    <div className="container-fluid anchoMaximo ">
                                                        <div className="containerSubTabla">

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Compras relacionadas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosComprasGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                                    {this.state.infoDetalle.global.comprasrelacionadas.length > 0 ?
                                                                        this.state.infoDetalle.global.comprasrelacionadas.map((compra) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.idcompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.fechacompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.precio)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.descuento)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.total)}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}

                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="containerSubTablaAA" colSpan={2} style={{ color: this.state.colorcontenidotabla }}>
                                                                            <b>Totales acumulados</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularSubtotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularDescuento(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularTotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            {/*<br />

                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label className="containerSubTablaAA"><strong>Pagos:</strong></label>
                                                            </div>
                                                            <div className='col-3'>
                                                                <Button className='btnAceptarModal' style={{position: 'relative'}}
                                                                onClick={this.verModalDetalles.bind(this, { opcion: "6" })}>
                                                                    {"Agregar pago"} 
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        
                                                        <Table hover>
                                                            <thead style={{ verticalAlign: "bottom" }}>
                                                                <tr>
                                                                    {this.state.tabla.encabezadosPagosGlobal.map((titulo, index) => (
                                                                        <th
                                                                            className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                            style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                            {titulo}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>

                                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.pagos.length>0 ? 
                                                                    this.state.infoDetalle.global.pagos.map((pago)=>(
                                                                        <tr style={{ borderTop: 'solid 2px black'}} >
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.tipopago}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.fechapago}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.monto}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.comprobante}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                <a href={pago.rutaComprobante} download>
                                                                                    <div className='divBotonesTrash'>
                                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                                    </div>
                                                                                </a>
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 3, pago: pago })}>
                                                                                    <FaTrashAlt className="icoBotonesTrash iconbtneliminar"/>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                : null}
                                                            </tbody>
                                                        </Table>*/}

                                                            <br />

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Factura:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosFacturaGlobal2.map((titulo, index) => (
                                                                            <th
                                                                                className={(index > 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.facturas.length > 0 ?
                                                                        this.state.infoDetalle.global.facturas.map((factura) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.idfactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.fechafactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(factura.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.factura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={factura.rutaFactura} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </>)
                                        : null
                                }


                                {/* ////////////////////   Tabla pagos  ////////////////////////////////////// */}
                                <br />
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className="lblEncabezadosTabla letraTitulos"><strong>Pagos</strong></label>
                                        </div>
                                        <div className='col-4'>
                                            <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>
                                                {"Agregar pago"}
                                            </Button>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="container-fluid containerTabla">
                                        <div className="container-fluid anchoMaximo ">
                                            <div className="containerSubTabla">

                                                <Table hover>
                                                    <thead style={{ verticalAlign: "bottom" }}>
                                                        <tr>
                                                            {this.state.tabla.encabezados.map((titulo, index) => (
                                                                <th
                                                                    className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ verticalAlign: "bottom" }}>

                                                        {
                                                            this.state.infoDetalle.pagos != undefined && this.state.infoDetalle.pagos.length > 0 ?

                                                                this.state.infoDetalle.pagos.map((item, index) => (
                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.tipopago}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.fechapago}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            ${formatoNumero2(item.monto)}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.comprobante}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>

                                                                            {(item.comprobante != "") ? (
                                                                                <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.rutaComprobante, titulo: item.comprobante })}
                                                                                    style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                                                    <u>
                                                                                        <BsFillEyeFill className="icoBotones" />
                                                                                        <b className='btnVerTabla'>Ver </b></u>
                                                                                </label>
                                                                            ) : (null)
                                                                            }

                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {(item.comprobante != "") ? (
                                                                                <a href={item.rutaComprobante} download>
                                                                                    <div className='divBotonesTrash'>
                                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                                    </div>
                                                                                </a>
                                                                            ) : (null)
                                                                            }


                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 1, pago: item })}>
                                                                                <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                                : null

                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla facturas  ////////////////////////////////////// */}
                                <br />
                                <div className='row'>
                                    <div className='row' style={{ marginTop: '20px' }}>
                                        <div className='col'>
                                            <label className="lblEncabezadosTabla letraTitulos"><strong>Facturas</strong></label>
                                        </div>
                                        <div className='col-4'>
                                            <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                onClick={this.verModalDetalles.bind(this, { opcion: "2" })}>
                                                {"Agregar factura"}
                                            </Button>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="container-fluid containerTabla">
                                        <div className="container-fluid anchoMaximo ">
                                            <div className="containerSubTabla">

                                                <Table hover>
                                                    <thead style={{ verticalAlign: "bottom" }}>
                                                        <tr>
                                                            {this.state.tabla.encabezadosFactura.map((titulo, index) => (
                                                                <th
                                                                    className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ verticalAlign: "bottom" }}>

                                                        {
                                                            this.state.infoDetalle.facturas != undefined && this.state.infoDetalle.facturas.length > 0 ?

                                                                this.state.infoDetalle.facturas.map((item, index) => (
                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.idfactura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.fechafactura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            ${formatoNumero2(item.monto)}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.factura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.rutaFactura, titulo: item.factura })}
                                                                                style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                                                <u>
                                                                                    <BsFillEyeFill className="icoBotones" />
                                                                                    <b className='btnVerTabla'>Ver </b></u>
                                                                            </label>
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <a href={item.rutaFactura} download>
                                                                                <div className='divBotonesTrash'>
                                                                                    <ImDownload3 className="icoBotonesTrash" />
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 2, factura: item })}>
                                                                                <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                                : null

                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('5')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarDescuento()} disabled={!this.state.haycambios} >Guardar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}

