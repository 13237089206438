import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './PerfilUsuario.css';
import {
  estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo,
  estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import ModalCambioPassword from '../../Complements/Modales/ModalPerfilUsuario/ModalFormularioCambioPasswordPerfilUsuario';
import { Table } from 'reactstrap';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {getPerfilUsuario, getListaCatalogoTipoDocumento, getListaCatalogoDocumentos
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

export default class PerfilUsuario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      tablainfogeneral: {
        titulo: "Información general",
        elementos: {}
      },
      tabladocumentos: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Descripción",
          "Creado",
          ""
        ],
        dimensiones: [
          "14%",
          "14%",
          "20%",
          "20%",
          "20%",
          "12%"
        ],
        mindimensiones: [
          "140px",
          "130px",
          "180px",
          "200px",
          "200px",
          "150px"
        ],
        titulo: "Documentos",
        elementos: []
      },

      arraytipos: [],
      arraytipodocumentos: [],

      txtarea: "",
      txtpuesto: "",
      txttipousuario: "",
      txtusuario: "",
      txtpassword: "",
      txtultimasesion: "",
      txttelefono: "",
      txtemail: "",
      txtdireccion: "",
      txtreferencia: "",
      txtiduser: 0,
      txtacceso: "",


      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      modalcambiapassword: false,
      modalnuevodocumento: false,
      modaleliminardocumento: false,
      itemseleccionado: null,
      errordeacceso: false,

      redireccionando: false,
      modalautorizacion: false,
      modalautorizaciontitulo: "",

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

      var resperfil = await getPerfilUsuario();
      if (this.manejadorLlamadasApis(resperfil)) {
        return;
      }else{
        if (resperfil.code == 200) {
          await toast.error(resperfil.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          setTimeout(async () => {
            this.redireccionando();
          }, 3000);
          return;
        }
        this.cargarinfo(resperfil.data)
      }

      var tablainfogeneral = this.state.tablainfogeneral;
      tablainfogeneral.elementos = resperfil.data ? resperfil.data : {};

      ///////////////// Se asignan datos de tabla Documentos
      var tabladocumentos = this.state.tabladocumentos;
      tabladocumentos.elementos = tablainfogeneral.elementos.documentos ? tablainfogeneral.elementos.documentos : [];

      this.setState({
        tablainfogeneral: tablainfogeneral,
        tabladocumentos: tabladocumentos
      });

      var resp = await getListaCatalogoTipoDocumento();
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      var arraytemp = JSON.parse(JSON.stringify(resp.data));
      this.setState({ arraytipodocumentos: arraytemp })

      resp = await getListaCatalogoDocumentos();
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      arraytemp = JSON.parse(JSON.stringify(resp.data));
      this.setState({ arraylistacatalogodocumentos: arraytemp })

      this.dimensiones();
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    
  }


  cargarinfo = (resp) => {
    if(resp!=undefined){
      //Validar telefono
      let telefono;
      if(resp.telefono  == "" || resp.telefono  == null || resp.telefono  == undefined ){
          telefono = "Sin asignar";
      }else{
          telefono = resp.telefono
      }

      //Validar telefono
      let email;
      if(resp.email  == "" || resp.email  == null || resp.email  == undefined ){
          email = "Sin asignar";
      }else{
          email = resp.email
      }

      //Validar usuario
      let usuario;
      if(resp.usuario  == "" || resp.usuario  == null || resp.usuario  == undefined ){
          usuario = "Sin asignar";
      }else{
          usuario = resp.usuario
      }

      //Validar referencia
      let referencia;
      if(resp.referencia  == "" || resp.referencia  == null || resp.referencia  == undefined || resp.referencia.length  < 1 ){
          referencia = "Sin asignar";
      }else{
          referencia = resp.referencia
      }

      //Validar calle
      let calle;
      if(resp.calle){
          calle = resp.calle;
      }else{
          calle = ""
      }

      //Validar numero exterior
      let numeroexterior;
      if (resp.numeroexterior) {
          if(calle){
              numeroexterior = ", " + resp.numeroexterior;
          }else{
              numeroexterior = resp.numeroexterior;
          }
      } else {
          numeroexterior = "";
      }

      //Validar numero interior
      let numerointerior;
      if (resp.numerointerior) {
          if(calle || numeroexterior){
              numerointerior = ", " + resp.numerointerior;
          }else{
              numerointerior = resp.numerointerior;
          }
      } else {
          numerointerior = "";
      }

      //Validar colonia
      let colonia;
      if (resp.colonia) {
          if(calle || numeroexterior || numerointerior){
              colonia = ", " + resp.colonia;
          }else{
              colonia = resp.colonia;
          }
      } else {
          colonia = "";
      }

      //Validar codigo postal
      let codigopostal;
      if (resp.codigopostal) {
          if(calle || numeroexterior || numerointerior || colonia){
              codigopostal = ", " + resp.codigopostal;
          }else{
              codigopostal = resp.codigopostal;
          }
      } else {
          codigopostal = "";
      }

      //Validar localidad
      let localidad;
      if (resp.localidad) {
          if(calle || numeroexterior || numerointerior || colonia || codigopostal){
              localidad = ", " + resp.localidad;
          }else{
              localidad = resp.localidad;
          }
      } else {
          localidad = "";
      }

      //Validar municipio
      let municipio;
      if (resp.municipio) {
          if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad){
              municipio = ", " + resp.municipio;
          }else{
              municipio = resp.municipio;
          }
      } else {
          municipio = "";
      }

      //Validar estado
      let estado;
      if (resp.estado) {
          if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad || municipio){
              estado = ", " + resp.estado;
          }else{
              estado = resp.estado;
          }
      } else {
          estado = "";
      }

      //Validar pais
      let pais;
      if (resp.pais) {
          if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad || municipio || estado){
              pais = ", " + resp.pais;
          }else{
              pais = resp.pais;
          }
      } else {
          pais = "";
      }

      let direccion = calle + numeroexterior + numerointerior + colonia + codigopostal + localidad + municipio + estado + pais + "."
      if (direccion == ".") {
          direccion = "Sin asignar";
      }

      this.setState({
          txtnombre:resp.nombres+" "+resp.apellidos,
          txtestatus:resp.estatus,
          txtarea: resp.area,
          txtpuesto: resp.puesto,
          txttipousuario: resp.tipousuario,
          txtusuario: usuario,
          txttelefono: telefono,
          txtemail: email,
          txtdireccion: direccion,
          txtreferencia: referencia,
          txtacceso: resp.acceso
      })
    }
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarVista = async () => {
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  verModalDetalles = (ban) => {

    if (ban.opcion === 8) { //Cambiar contraseña
      this.setState({ titulomodal: 'Cambiar contraseña', accionmodal: 8})
      this.setState({ modalcambiapassword: true });
    }

  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.actualizarVista();
    }
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalcambiapassword == true) ? (
          <ModalCambioPassword titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalPassword}
            show={this.state.modalcambiapassword} accion={this.state.accionmodal} iduser={this.state.txtiduser} setLoadingSpinner={this.setLoadingSpinner}/>
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.tablainfogeneral.elementos.nombres+" "+this.state.tablainfogeneral.elementos.apellidos}</label>
              </div>
            </div>

          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            
              <div className='row' style={{ marginLeft: "0px", marginRight: "0px" }}>

                {/* ////////////////////   Perfil de usuario  ////////////////////////////////////// */}
                <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos"  colSpan="3">
                          {"Perfil de usuario"}
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Área"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtarea}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Puesto"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtpuesto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Tipo usuaro"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txttipousuario}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'></div>
                
                {/* ////////////////////   Sesion  ////////////////////////////////////// */}
                <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos" colSpan="3">
                          {"Sesión"}
                        </td>
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Usuario"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtusuario}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Contraseña"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtacceso == 1 ? "*******" : "" }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtacceso == 1 ? <button className='btnNuevo letraNormal' onClick={this.verModalDetalles.bind(this, { opcion: 8 })}
                              style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                            >Cambiar</button> : "" }
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'></div>
                
                {/* ////////////////////   Informacion de contacto  ////////////////////////////////////// */}
                <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos" colSpan="3">
                          {"Informacion de contacto"}
                        </td>
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Télefono"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txttelefono == "Sin asignar" ?
                            <i className='lblSinDatos'>{this.state.txttelefono}</i>
                          :
                            this.state.txttelefono
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"E-mail"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtemail == "Sin asignar" ?
                            <i className='lblSinDatos'>{this.state.txtemail}</i>
                          :
                            this.state.txtemail
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'></div>

                {/* ////////////////////   Direccion ////////////////////////////////////// */}
                <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td className="letraTitulos" colSpan="3">
                          {"Dirección"}
                        </td>
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      <tr>
                        <td colSpan="3" className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtdireccion == "Sin asignar" ?
                            <i className='lblSinDatos'>{this.state.txtdireccion}</i>
                          :
                            this.state.txtdireccion
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {"Referencia"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.state.txtreferencia == "Sin asignar" ?
                            <i className='lblSinDatos'>{this.state.txtreferencia}</i>
                          :
                            this.state.txtreferencia
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
