import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoMaquinarias.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo,
  estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getEstatus, getListaTipoMaquinarias, getCatalogoMaquinarias, getListaRanchosDisponibles, getListaZonas,
  getListaTipoCombustibles, getComboAreasDeTrabajo
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalFormularioMaquinarias from '../../Complements/Modales/ModalFormularioMaquinarias/ModalFormularioMaquinarias';


export default class CatalogoMaquinarias extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      txtselectedrancho: '0',
      txtselectedtipomaquinaria: '0',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: false,
      errordeacceso: false,

      listaranchos: [],
      listazonas: [],
      listatipomaquinaria: [],
      listacombustible: [],
      listaareas: [],

      tabla: {
        encabezados: [
          "Nombre / Clave",
          "Marca",
          "Modelo",
          "Color",
          "Ubicación",
          "Observaciones",
          "Estatus",
          ""],
        dimensiones: [
          "15%",
          "10%",
          "10%",
          "10%",
          "10%",
          "30%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "100px",
          "100px",
          "150px",
          "100px",
          "120px"
        ],
        elementos: []
      },

      datosDummy: [
        {
          nombre: "Foreign",
          marca: "NISSAN",
          modelo: "2000",
          color: "Rojo",
          rancho: "Rancho XDR",
          observaciones: "Llantas gastadas requieren nuevas",
          estatus: "Activo",
        },
        {
          nombre: "Foreign",
          marca: "NISSAN",
          modelo: "2000",
          color: "Rojo",
          rancho: "Rancho XDR",
          observaciones: "Llantas gastadas requieren nuevas",
          estatus: "Activo",
        },
        {
          nombre: "Foreign",
          marca: "NISSAN",
          modelo: "2000",
          color: "Rojo",
          rancho: "Rancho XDR",
          observaciones: "Llantas gastadas requieren nuevas",
          estatus: "Activo",
        },
      ],

      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      redireccionando: false,
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    this.setState({ txtbuscador: '' })
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if (this.manejadorLlamadasApis(respzonas)) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(respranchos)) {
      return;
    }

    //obtiene catalogo de tipo maquinarias
    var resptipomaquinarias = await getListaTipoMaquinarias();
    if (this.manejadorLlamadasApis(resptipomaquinarias)) {
      return;
    }

    //obtiene catalogo de tipo combustibles
    var resptipocombustibles = await getListaTipoCombustibles();
    if (this.manejadorLlamadasApis(resptipocombustibles)) {
      return;
    }

    var respareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(respareas)) {
      return;
    }

    //se obtenra informacion
    var resp = await getCatalogoMaquinarias();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }
    //var resp = this.state.datosDummy;



    var tabla = this.state.tabla;
    tabla.elementos = resp.data;
    //tabla.elementos = this.state.areasCultivosDummy;

    await this.setState({
      txtbuscador: '',
      estatus: estatus.data,
      listatipomaquinaria: resptipomaquinarias.data,
      listacombustible: resptipocombustibles.data,
      listaranchos: respranchos.data,
      listazonas: respzonas.data,
      listaareas: respareas.data,
      tabla: tabla
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedrancho: '0' })
    this.setState({ txtselectedtipomaquinaria: '0' })
    this.setState({ errordeacceso: false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselectedrancho: '0' })
    this.setState({ txtselectedtipomaquinaria: '0' })
    this.setState({ errordeacceso: false })
    this.setState({ loadingspinners: bandera });
  }

  //Funcion para filtrar por los select y texto en buscador
  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtroranchos = [];
    var filtrotipomaquinaria = [];
    var filtronombre = [];

    if (e.target.id == 'Search') {
      this.setState({ txtbuscador: e.target.value });

      if (e.target.value.trim().length < 3) {
        return;
      }
      if (this.state.txtselectedrancho == 0) {
        filtroranchos = data;
      } else {
        filtroranchos = data.filter((item) => item.idrancho_area == this.state.txtselectedrancho)
      }
      if (this.state.txtselectedtipomaquinaria == 0) {
        filtrotipomaquinaria = filtroranchos;
      } else {
        filtrotipomaquinaria = filtroranchos.filter((item) => item.idtipomaquinaria == this.state.txtselectedtipomaquinaria)
      }
      filtronombre = filtrotipomaquinaria.filter((item) => item.nombreclave.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
    }
    else if (e.target.id == 'tipomaquinaria') {
      this.setState({ txtselectedtipomaquinaria: e.target.value });
      if (e.target.value == 0) {
        filtrotipomaquinaria = data;
        this.setState({txtselectedrancho:0})
      } else {
        this.setState({txtselectedrancho:0})
        filtrotipomaquinaria = data.filter((item) => item.idtipomaquinaria == e.target.value)
      }
      if (this.state.txtselectedrancho == 0) {
        filtroranchos = data;
      } else {
        filtroranchos = data.filter((item) => item.idrancho_area == this.state.txtselectedrancho)
      }
      if (this.state.txtbuscador.length < 3) {
        filtronombre = filtrotipomaquinaria;
      } else {
        filtronombre = filtrotipomaquinaria.filter((item) => item.nombreclave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    } else if (e.target.id == 'ranchos') {
      this.setState({ txtselectedrancho: e.target.value});

      if (e.target.value == 0) {
        filtroranchos = data;
      } else {
        filtroranchos = data.filter((item) => item.idrancho_area == e.target.value)
      }
      if (this.state.txtselectedtipomaquinaria == 0) {
        filtrotipomaquinaria = filtroranchos;
      } else {
        filtrotipomaquinaria = filtroranchos.filter((item) => item.idtipomaquinaria == this.state.txtselectedtipomaquinaria)
      }
      if (this.state.txtbuscador.length < 3) {
        filtronombre = filtrotipomaquinaria;
      } else {
        filtronombre = filtrotipomaquinaria.filter((item) => item.nombreclave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    } else if (e.target.id == 'CSearch') {
      if (this.state.txtselectedrancho == 0) {
        filtroranchos = data;
      } else {
        filtroranchos = data.filter((item) => item.idrancho_area == this.state.txtselectedrancho)
      }
      if (this.state.txtselectedtipomaquinaria == 0) {
        filtrotipomaquinaria = filtroranchos;
      } else {
        filtrotipomaquinaria = filtroranchos.filter((item) => item.idtipomaquinaria == this.state.txtselectedtipomaquinaria)
      }
      filtronombre = filtrotipomaquinaria;
      this.setState({ txtbuscador: '' })
    }

    if (filtronombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    } else {
      this.filtro(1, filtronombre)
    }
  }
  //Termina Funcion para filtrar por los select y texto en buscador

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva maquinaria', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar maquinaria', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Maquinaria/Vehículo creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Maquinaria/Vehículo editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  render() {
    return (

      <>

        {
          this.state.salir ?
            <>
              <SpinnerModal tipo="full" show={true} />
              <AccionesClientes bandera={'salir'} />
            </>
            : null
        }

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioMaquinarias titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listaranchos={this.state.listaranchos}
            listazonas={this.state.listazonas}
            listacombustible={this.state.listacombustible}
            listatipomaquinarias={this.state.listatipomaquinaria}
            listaareas={this.state.listaareas}
            setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end'>
              <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Maquinaria y vehículos</label>
              </div>

              {/* ////////////////////   Filtro tipo maquinaria ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto2 letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo maquinaria</label>
                  <div className="selectBox ">
                    <select id='tipomaquinaria' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedtipomaquinaria == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}
                      {this.state.listatipomaquinaria == undefined || this.state.listatipomaquinaria.length == 0 ? null : this.state.listatipomaquinaria.map((item, index) => {
                        return (<option key={"tc_" + index} value={item.idtipomaquinaria}>{item.tipo}</option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro ranchos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                  <div className="selectBox ">
                    <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}
                      {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                        return (<option key={"r_" + index} value={item.idcatalogo_rancho}>{item.rancho}</option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro buscador  ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar nombre/clave"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.saveInputLocacion} />) : null}
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>


            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedrancho == 0 && this.state.txtselectedtipomaquinaria == 0 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.color}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho_area}
                          {(item.idtipo_destino == 1) ? (<>, {item.zona} </>) : null}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.color}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho_area}
                          {(item.idtipo_destino == 1) ? (<>, {item.zona} </>) : null}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.txtselectedrancho == 0 && this.state.txtselectedtipomaquinaria == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}
function AccionesClientes({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}