import React from 'react'
import './Cotizacion.css';
import { Button } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import {estiloBtnActualizar, estiloComboBox, estiloFiltroEncabezados, estilosModal} from '../../Services/PaletaDeColores';
import TablaProductos from './TablaProductos';
import 'react-toastify/dist/ReactToastify.css';

export default class AutorizacionTablaCotizacion extends React.Component {
  constructor(props) {
    super(props);
    //console.log("AutorizacionTablaCotizacion",props)

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      accion: 1, 

      nombrezona: props.itemzona.proveedor,
      listaproductos: props.itemzona.productos,
      aprobaciones: props.itemzona.aprobaciones,

      colapsartabla: true,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colortipolbl: estiloComboBox().colorcombolbl,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  editarTarjeta = async () => {
    this.props.actualizarContenido(this.props.itemzona);
  }

  eliminarTarjeta = async () => {
    this.props.eliminarTarjeta(this.props.itemzona);
  }


  verModalRechazarCotizacion = (accion) => {
    var contenido = { accion: accion, item: this.props.itemzona }
   // console.log("verModalRechazarCotizacion", contenido);
    this.props.vermodalrechazarcotizacion(contenido)
  }

  verModalAutorizacion = (accion) => {
    var contenido = { id: accion, item: this.props.itemzona }
    this.props.vermodalautorizacion(contenido)
  }

  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="marginTopSeparator" >
          <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row flexNoWrap'>
                      <div className='col-1 containerSubTablaAA minPaddingTopBottom' >
                        <button className='btnCollapsar letraNormal'
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                          onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                      </div>
                      <div className='col-5 containerSubTablaAA minPaddingTopBottom'>
                        <p className='sinMargen'><b>{this.props.itemzona.proveedor}</b><br></br>Fecha estimada de compra: {this.props.fechaestimadacompra}</p>
                      </div>
                      <div className='col-3 containerSubTablaAA minPaddingTopBottom'>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl}}>Autorización en proceso</label>
                        <label className='lblTexto letraTitulos'>{this.props.itemzona.aprobaciones}</label>
                      </div>
                      <div className='col-2 containerSubTablaAA minPaddingTopBottom'>
                        <button className='btnEditarCotizaciones'
                         onClick={this.editarTarjeta}>Editar</button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={this.state.colapsartabla} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <div className='tablaProductoCotizaciones'>
                          <TablaProductos key={"tabla_" + 1} listaproductos={this.props.itemzona.productos} 
                          
                          totalsuma={this.props.itemzona.total} 
                          totalivas={this.props.itemzona.totalivas} 
                          totalieps={this.props.itemzona.totalieps} 
                          totalprecios={this.props.itemzona.totalprecios} 
                          cuantosdecimales={this.props.cuantosdecimales}
                          accion={this.state.accion} />
                        </div>
                        {( this.props.permisoautorizacion)?
                              <div className='row paddingEncabezados align-items-end ' style={{ padding: '20px 12px 0px 0px' }}>
                                <div className='col'></div>
                                <div className='col-auto paddingBottomEncabezados'>
                                  <Button className="btnRechazarProd" style={{ padding: '4px 12px' }} onClick={this.verModalRechazarCotizacion.bind(this, 1)}>
                                    Rechazar cotización
                                  </Button>
                                </div>
                                <div className='col-auto paddingBottomEncabezados'>
                                  <Button className="btnAceptarProd" style={{ padding: '4px 12px' }} onClick={this.verModalAutorizacion.bind(this, 4)}>
                                    Aprobar cotización
                                  </Button>
                                </div>
                              </div>
                              :null}
                        <br></br>
                        <br></br>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
