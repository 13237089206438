import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalCotizaciones.css";
import { estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { formatoNumero, validarNumerosDecimales3 } from '../../../Services/Validaciones';

import ModalBuscarProveedorCotizacions from './ModalBuscarProveedorCotizacion';
import ModalAgregarActividadCotizacion from './ModalAgregarActividadCotizacion';


const todaslasopcionesdisponibles = 0

export default class ModalAsignarServicioCotizacion extends React.Component {



    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,
            hubocambio: false,

            tabla: {
                encabezados2: [
                    "Actividad",
                    "Tipo",
                    "Destino",
                    "Precio",
                    "IVA",
                    "ISR",
                    "Total",
                    "Eliminar"
                ],
                dimensiones: [
                    "20%",
                    "10%",
                    "20%",
                    "10%",
                    "8%",
                    "8%",
                    "10%",
                    "5%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "160px",
                    "90px",
                    "90px",
                    "160px",
                    "75px",
                ],
                elementos: [],
            },

            nuevaCotizacion: {
                "idcotizacion_proveedor": 0,
                "idproveedor": 0,
                "proveedor": "",
                "fechacompra": "",
                "idestatuscotizacion": 0,
                "estatuscotizacion": "Editado",
                "mensaje_estatuscotizacion": "",
                "productos": [],
                "total": 0.00,
            },

            // nuevos
            listaproductos: this.obtenerproductosresumen(),
            listaproveedores: this.props.listaproveedores,
            listatipoproveedores: this.props.listatipoproveedores,
            listaproductosguardar: [],
            listaproductosseleccionado: [],
          

            iva_activo: false,
            isr_activo: false,
            precios_acumulado: 0,
            iva_acumulado: 0,
            isr_acumulado: 0,
            total_acumulado: 0,

            proveedorselect: undefined,
            modalproveedores: false,

            modalproductos: false,
            productoselect: undefined,

            cargafirstime: true,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
        }
    }

    obtenerproductosresumen() {

        let x = [];

        this.props.listaproductosResumen.forEach(el => {
            if (el.sustitutode != "Sustituido") {
                x.push(el)
            }
        });

        return x
    }

    async componentDidMount() {
        
        if (this.props.accion == 4) {
            await this.iniciarProveedor()
            await this.iniciarProductos()
        }
        this.totalAcumulados();

    }

    obtienerFechaCtual() {

        const fecha = new Date();


        var year = fecha.getFullYear();
        var mes = fecha.getMonth() + 1;
        var dia = fecha.getDate();

        if (mes < 10) {
            mes = "0" + mes;
        }

        if (dia < 10) {
            dia = "0" + dia;
        }

        var fechaCompra = dia + "-" + mes + "-" + year;

        return fechaCompra
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {

        if (accion == "10") {
            var aux = this.state.listaproductosseleccionado;
            var lista = [];
            var total = 0.00;
            var band = false;
            var mensajeerror= "";

            if (this.state.proveedorselect == undefined) {
                toast.info("Selecciona un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            } else {

                if (aux.length > 0) {
                    aux.map((prod) => {
                        //agregamos dos decimales si no lo traen
                       

                        var newPrecio = "" + prod.precioCoti;
                        var auxPrecio = newPrecio.split('.');
                        if (auxPrecio.length > 1) {
                            newPrecio = auxPrecio[0].slice(0, 7) + "." + auxPrecio[1].slice(0, 4)
                        } else {
                            newPrecio = auxPrecio[0].slice(0, 7) + ".0000"
                        }


                        let jsonProd = {
                            "idactividad_cotizacionproveedor": (prod.idactividad_cotizacionproveedor) ? (prod.idactividad_cotizacionproveedor) : 0,
                            "idcatalogo_requisicion_actividad": prod.idcatalogo_requisicion_actividad,
                          
                            "actividad": prod.actividad,
                            "idactividad": prod.idactividad,
                            "tipoactividad": prod.tipoactividad,
                            "precio": newPrecio,
                            "iva": (prod.iva != undefined ? prod.iva : 0),
                            "isr": (prod.isr != undefined ? prod.isr : 0)
                        
                        }


                        if(parseFloat(prod.isr)> parseFloat(newPrecio) ){
                            mensajeerror = "ISR no puede ser mayor que el precio"
                        }
                        lista.push(jsonProd);
                        band = prod.precioCoti <= 0 || prod.cantidadCoti <= 0 

                    })
                    if (!band && mensajeerror =="") {
                        this.totalAcumulados();
                        
                        let auxCoti = this.state.nuevaCotizacion;
                        auxCoti.productos = lista;
                        auxCoti.totalprecios = this.state.precios_acumulado;
                        auxCoti.totalivas = this.state.iva_acumulado;
                        auxCoti.totalisr = this.state.isr_acumulado;
                        auxCoti.isr_activo = (this.state.isr_activo == true ? (1) : (0));
                        auxCoti.iva_activo = (this.state.iva_activo == true ? (1) : (0));
                        auxCoti.total = this.state.total_acumulado;

                        if (this.props.accion == 1) {
                            auxCoti.fechacompra = this.obtienerFechaCtual();
                        }

                        this.setState({ nuevaCotizacion: auxCoti, show: false })
                        setTimeout(() => {

                            this.props.cerrarModal(auxCoti);
                        }, 200);
                    } else {

                        if (band){
                            toast.info("Debe asignar alguna cantidad y precio", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                        }

                        if(mensajeerror !=""){
                            toast.info(mensajeerror, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                        }
                        
                    }
                } 
            }
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 2) {
            this.setState({ titulomodal: 'Buscar proveedor', accionmodal: 2, modalproveedores: true })
        } else if (ban.opcion == 3) {
            if (this.props.isProductoGeneral == true) {
                this.setState({ titulomodal: 'Agregar actividad', accionmodal: 3, modalproductos: true })
            } else {
                this.setState({ titulomodal: 'Agregar agroquímicos', accionmodal: 3, modalproductos: true })
            }

        }
    }

    cerrarModalProveedor = async (prov) => {
        this.setState({ modalproveedores: !this.state.modalproveedores })
        if (prov != 0) {
            var existe = 0;
            if (this.props.listacotizaciones.length > 0) {
                existe = this.props.listacotizaciones.filter((cotizacion) => cotizacion.idproveedor == prov.idcatalogo_proveedores && cotizacion.idestatuscotizacion != 4)
            }

            if (existe.length < 1 || existe == 0) {
                let auxCoti = this.state.nuevaCotizacion;
                auxCoti.idproveedor = prov.idcatalogo_proveedores;
                auxCoti.proveedor = prov.nombre;

                this.setState({ proveedorselect: prov, nuevaCotizacion: auxCoti, hubocambio: true })
            } else {
                toast.info("Ya existe una cotización en proceso de compra con este proveedor", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            }

        }
    }

    iniciarProveedor = async () => {

        let auxCoti = this.state.nuevaCotizacion;

        auxCoti.idproveedor = this.props.item.idproveedor;
        auxCoti.proveedor = this.props.item.proveedor;
        auxCoti.btnEditado = true;
        auxCoti.idcotizacion_proveedor = (this.props.accion == 1) ? (0) : this.props.item.idcotizacion_proveedor;
        auxCoti.fechacompra = this.props.item.fechacompra;
        auxCoti.esdummy = this.props.item.esdummy;
        auxCoti.iva_activo = false;
        auxCoti.isr_activo = false;

        var proveedorSel = this.state.listaproveedores.filter((item) => ((item.idcatalogo_proveedores == this.props.item.idproveedor)))

        this.setState({ proveedorselect: proveedorSel[0], nuevaCotizacion: auxCoti })

    }

    iniciarProductos = async () => {
        var aux = this.props.item.productos;

        var lista = [];
        aux.map((prodd) => {
            var productoSel = this.state.listaproductos.filter((item) => item.idcatalogo_requisicion_actividad == prodd.idcatalogo_requisicion_actividad);
            if (productoSel.length > 0) {
                productoSel[0].idactividad_cotizacionproveedor = prodd.idactividad_cotizacionproveedor;
                productoSel[0].precioCoti = parseFloat(prodd.precio).toFixed(4);
                productoSel[0].pendientes = parseFloat(productoSel[0].pendientes);
                productoSel[0].iva = prodd.iva;
                if (this.state.isProductoGeneral != true) {
                    productoSel[0].isr = prodd.isr;
                }
                lista.push(productoSel[0])
            }

            if (parseInt(this.props.item.iva_activo) == 1) {
                this.setState({ iva_activo: true })
            }
            if (parseInt(this.props.item.isr_activo) == 1) {
                this.setState({ isr_activo: true })
            }
          
        })
        // console.log("iniciarProductos", lista);
        this.setState({ listaproductosseleccionado: lista, cargafirstime: false })

        setTimeout(() => {
            this.totalAcumulados()
        }, 300);


    }

    cerrarModalProductos = async (prod) => {
        this.setState({ modalproductos: !this.state.modalproductos })
        if (prod != 0 && prod.length > 0) {

            var productosseleccionado = [];

            prod.map((p1) => {
                var prodagregado = false;
                this.state.listaproductosseleccionado.forEach((p2) => {
                    if (p2.idcatalogo_requisicion_actividad == p1.idcatalogo_requisicion_actividad) {
                        prodagregado = true;
                    }
                })

                if (!prodagregado) {
                 //   p1.cantidadCoti = 0.00;
                    p1.precioCoti = 0.00;
                   // p1.cantidadStatic = 0.00;
                    productosseleccionado.push(p1)
                }
            })

            if (productosseleccionado.length > 0) {
                // si ya habian seleccionados o opciones en la modal, se agregan los nuevos seleccionados
                var concatenarprod = this.state.listaproductosseleccionado.concat(productosseleccionado)

                await this.setState({ listaproductosseleccionado: concatenarprod, hubocambio: true })
            }
        }
    }

    eliminarProducto = async (producto, index) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listaproductosseleccionado.filter((item) => !(item.idcatalogo_requisicion_actividad == producto.idcatalogo_requisicion_actividad));
        await this.setState({ listaproductosseleccionado: aux, hubocambio: true })
        this.totalAcumulados();
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    cantidaddisponible(index) {
        if (this.props.accion == 1) {
            return this.state.listaproductosseleccionado[index]['pendientes']
        }
        return parseFloat(this.state.listaproductosseleccionado[index]['pendientes']) + parseFloat(this.state.listaproductosseleccionado[index]['cantidadStatic'])
    }

    escribirCantidadPrecio = async (index, prop, event) => {
      
            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 4 };
            let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
            var updateValor = event.target.value;

            if (tipocorrecto.codigo == 'invalid') {
                var auxUpdateValor = updateValor.split('.');
                if (auxUpdateValor.length > 1) {
                    updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 2)
                } else {
                    updateValor = auxUpdateValor[0].slice(0, 7)
                }
                toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            this.state.listaproductosseleccionado[index][prop] = updateValor;
            if (prop == 'precioCoti' && this.state.iva_activo == true) {
                this.state.listaproductosseleccionado[index]['iva'] = this.calcularIva(this.state.listaproductosseleccionado[index])
            }
          
            var dummy = this.state.listaproductosseleccionado
            this.setState({ listaproductosseleccionado: dummy, hubocambio: true });
            this.totalAcumulados();
      

    };

    escribirImpuestos = async (index, prop, e) => {

        // console.log(index, prop, e.target.value)
        var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 4 };
        let tipocorrecto = validarNumerosDecimales3(e.target.value, longitud)
        var updateValor = e.target.value;

        if (tipocorrecto.codigo == 'invalid') {
            var auxUpdateValor = updateValor.split('.');
            if (auxUpdateValor.length > 1) {
                updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 4)
            } else {
                updateValor = auxUpdateValor[0].slice(0, 7)
            }
            toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        this.state.listaproductosseleccionado[index][prop] = (tipocorrecto.codigo == 'invalid' ? (0) : (updateValor));
        var dummy = this.state.listaproductosseleccionado
        this.setState({ listaproductosseleccionado: dummy, hubocambio: true });
        this.totalAcumulados();

    }

    checkImpuestos = async (prop, booleano) => {
        // console.log(prop, booleano)

        let lista = this.state.listaproductosseleccionado;
        if (prop == "iva_activo") {
            if (booleano == false) {
                lista.forEach(prod => {
                    prod.iva = "0";
                });
            }
            else if (booleano == true) {
                lista.forEach(prod => {
                    prod.iva = this.calcularIva(prod)
                });
            }
            this.setState({ hubocambio: true })
        }

        if (prop == "isr_activo") {
            if (booleano == false) {
                lista.forEach(prod => {
                    prod.isr = "0";
                });
                this.setState({ hubocambio: true })
            }
            else if (booleano == true) {
                lista.forEach(prod => {
                    prod.isr = "0"
                });
            }
        }
        this.setState({ [prop]: booleano, listaproductosseleccionado: lista })

        this.totalAcumulados();
    }

    calcularIva(prod) {
        //console.log(prod)
        return parseFloat(parseFloat(prod.precioCoti) * 0.16).toFixed(4)
    }





    totalProducto(producto) {
        if (this.state.isProductoGeneral == true) {
            let productocoti = parseFloat(producto.precioCoti ? producto.precioCoti : 0)
            let productoiva = parseFloat(producto.iva ? producto.iva : 0)
            return parseFloat(productocoti + productoiva).toFixed(4)
        }
        else {
            let productocoti = parseFloat(producto.precioCoti ? producto.precioCoti : 0)
            let productoiva = parseFloat(producto.iva ? producto.iva : 0)
            let productoisr = parseFloat(producto.isr ? producto.isr : 0)

            return parseFloat(productocoti + productoiva - productoisr).toFixed(4)
        }
    }
    totalAcumulados() {
        var sumaprecios = 0;
        var sumarivas = 0;
        var sumarisr = 0;
        let sumatotal = 0;
        this.state.listaproductosseleccionado.forEach(prod => {
            sumaprecios = sumaprecios + parseFloat(prod.precioCoti);
            sumarivas = sumarivas + parseFloat(prod.iva);
            sumarisr = sumarisr + parseFloat(prod.isr);
        });


        // console.log(typeof (sumarivas), isNaN(sumarivas))
        if (isNaN(sumaprecios) == true) {
            sumaprecios = 0;
        }
        if (isNaN(sumarivas) == true) {
            sumarivas = 0;
        }
        if (isNaN(sumarisr) == true) {
            sumarisr = 0;
        }

        sumatotal = sumaprecios + sumarivas - sumarisr

        this.setState({ precios_acumulado: sumaprecios, iva_acumulado: parseFloat(sumarivas).toFixed(4), isr_acumulado: parseFloat(sumarisr).toFixed(4), total_acumulado: parseFloat(sumatotal).toFixed(4) })

    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalproveedores == true) ? (
                    <ModalBuscarProveedorCotizacions
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProveedor}
                        show={this.state.modalproveedores}
                        accion={this.state.accionmodal}
                        listaproveedores={this.state.listaproveedores}
                        listatipoproveedores={this.state.listatipoproveedores} />
                ) : null}

                {(this.state.modalproductos == true) ? (
                    <ModalAgregarActividadCotizacion
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProductos}
                        show={this.state.modalproductos}
                        accion={this.state.accionmodal}
                        listaactividades={this.state.listaproductos}
                        isProductoGeneral={this.props.isProductoGeneral} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    style={{ minWidth: "1300px" }}
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Asignar servicios</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Proveedor: </label>
                                                <label className='lblTexto letraTitulos'>{this.state.proveedorselect ? this.state.proveedorselect.nombre : "Seleccione un proveedor"}</label>
                                            </div>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnActualizar letraNormal botonesB'
                                                    onClick={this.verModalDetalles.bind(this, { opcion: "2" })}>Buscar</button>
                                            </div>
                                        </div>
                                        <div className='row paddingEncabezados'>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                {this.props.isProductoGeneral == true ? <label className='lblTexto letraTitulos'>Productos generales seleccionados: </label> : <label className='lblTexto letraTitulos'>Agroquímicos seleccionados: </label>}
                                            </div>

                                            <div className='offset-6 col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnActualizar letraNormal botonesB'
                                                    onClick={this.verModalDetalles.bind(this, { opcion: "3" })}>+ Agregar</button>
                                            </div>
                                        </div>
                                        <div className='row '>

                                            <div className='col-auto containerSubTabla '>
                                                <label className='lblTexto letraTitulos checkPlanAnual' >
                                                    <input type="checkbox" checked={this.state.iva_activo} onClick={this.checkImpuestos.bind(this, 'iva_activo', !this.state.iva_activo)} />Aplicar IVA </label>
                                            </div>

                                                <div className='col-auto containerSubTabla ' style={{ display: "flex" }}>
                                                    <label className='lblTexto letraTitulos checkPlanAnual' style={{ marginTop: "auto", marginBottom: "auto" }} >
                                                        <input type="checkbox" checked={this.state.isr_activo} onClick={this.checkImpuestos.bind(this, "isr_activo", !this.state.isr_activo)} />
                                                        Aplicar ISR
                                                    </label>
                                                </div>



                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu" style={{ minWidth: "1100px", width: "100%" }}>
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {
                                                            this.state.tabla.encabezados2.map((titulo, index) => (

                                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[(index == 6) ? (7) : (index == 6) ? (8) : index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            )
                                                            )
                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaproductosseleccionado.length > 0 ? this.state.listaproductosseleccionado.map((producto, index) => (
                                                            <tr key={"tabla" + index} className={ producto.precioCoti <= 0 ? "trFondoIncompleto" : ""} style={{ textAlign: "right" }}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: "left" }}>
                                                                    {producto.actividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {producto.tipoactividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: "left" }}>
                                                                    {producto.destino}
                                                                </td>
                                                               
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <input type={'number'} className="inputSubtabla" value={(producto.precioCoti ? producto.precioCoti : "0.00")} onChange={(e) => this.escribirCantidadPrecio(index, 'precioCoti', e)} style={{ textAlign: "right", }}></input>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {(this.state.iva_activo == true) ? (
                                                                        <input type={'number'} className="inputSubtabla" value={producto.iva ? producto.iva : "0.0000"} onChange={(e) => this.escribirImpuestos(index, 'iva', e)} style={{ textAlign: "right", }}></input>

                                                                    ) : (
                                                                        producto.iva ? producto.iva : "0.0000"
                                                                    )}

                                                                </td>

                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                        {(this.state.isr_activo == true) ? (
                                                                            <input type={'number'} className="inputSubtabla" value={producto.isr ? producto.isr : "0.0000"} onChange={(e) => this.escribirImpuestos(index, 'isr', e)} style={{ textAlign: "right", }}></input>
                                                                        ) : (
                                                                            producto.isr ? producto.isr : "0.0000"
                                                                        )}
                                                                    </td>

                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    ${formatoNumero(this.totalProducto(producto))}

                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <div className="modalDetallesCheck" >
                                                                        <button className='btnEliminarFila'
                                                                        ><FaTrashAlt className="icoBotones" onClick={() => this.eliminarProducto(producto, index)} /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        )) : null
                                                    }

                                                    {this.state.listaproductosseleccionado.length > 0 ? (
                                                        <tr key={"tabla_acumulados"} style={{ textAlign: "right", fontWeight: "bold ", border: "0px !important" }}>
                                                            <td className="letraTitulos lblSubtabla"
                                                                style={{ textAlign: "left", color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                Totales acumulados
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                           
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                ${formatoNumero(this.state.precios_acumulado)}
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                {this.state.iva_activo == true ? ("$" + formatoNumero(this.state.iva_acumulado)) : (null)}
                                                            </td>

                                                          
                                                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                    {this.state.isr_activo == true ? ("$" + formatoNumero(this.state.isr_acumulado)) : (null)}
                                                                </td>

                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                {"$" + formatoNumero(this.state.total_acumulado)}
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                        </tr>
                                                    ) : "Tabla vacía"}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.state.accion == 1 || this.state.accion == 4 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')}  >Asignar</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}