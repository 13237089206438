import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalCotizaciones.css";
import { estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { formatoNumero, validarNumerosDecimales, validarNumerosDecimales3 } from '../../../Services/Validaciones';

import ModalBuscarProveedorCotizacions from './ModalBuscarProveedorCotizacion';
import ModalAgregarProductoCotizacion from './ModalAgregarProductoCotizacion';
import { getCatalogoIEPSLista } from '../../../Services/Api';

const todaslasopcionesdisponibles = 0

export default class ModalAsignarProductoCotizacion extends React.Component {



    constructor(props) {
        super(props)
        
        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,
            hubocambio: false,
            loadingspinners:true,

            tabla: {
                encabezados: [
                    "Agroquímico",
                    // "Unidad de medida",
                    "Disponibles",
                    "Cantidad",
                    "Precio",
                    "IVA",
                    "IEPS",
                    "Total",
                    "Eliminar"
                ],
                encabezados2: [
                    "Producto general",
                    //"Unidad de medida",
                    "Disponibles",
                    "Cantidad",
                    "Precio",
                    "IVA",
                    "Total",
                    "Eliminar"
                ],
                dimensiones: [
                    "30%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                    "10%",
                ],
                mindimensiones: [
                    "200px",
                    "100px",
                    "120px",
                    "160px",
                    "160px",
                    "160px",
                    "160px",
                    "85px",
                ],
                elementos: [],
            },

            nuevaCotizacion: {
                "idcotizacion_proveedor": 0,
                "idproveedor": 0,
                "proveedor": "",
                "fechacompra": "",
                "idestatuscotizacion": 0,
                "estatuscotizacion": "Editado",
                "mensaje_estatuscotizacion": "",
                "productos": [],
                "total": 0.00,
                "iva": "0",
                "idieps": "0",
            },

            // nuevos
            listaproductos: this.obtenerproductosresumen(),
            listaproveedores: this.props.listaproveedores,
            listatipoproveedores: this.props.listatipoproveedores,
            listaproductosguardar: [],
            listaproductosseleccionado: [],
            arraytipos: [],
            idtiporequisicionseleccionada: todaslasopcionesdisponibles,


            iva_activo: false,
            ieps_activo: false,
            precios_acumulado: 0,
            iva_acumulado: 0,
            ieps_acumulado: 0,
            total_acumulado: 0,

            proveedorselect: undefined,
            modalproveedores: false,

            modalproductos: false,
            productoselect: undefined,

            cargafirstime: true,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
        }
    }

    obtenerproductosresumen() {

        let x = [];

        this.props.listaproductosResumen.forEach(el => {
            if (el.sustitutode != "Sustituido") {
                x.push(el)
            }
        });

        return x
    }

    async componentDidMount() {
        //console.log(respestatusrequisiciones)
        var resptiporequisiciones = await getCatalogoIEPSLista()
        if (this.manejadorLlamadasApis(resptiporequisiciones)) {
            return;
        }

        this.setState({ arraytipos: resptiporequisiciones.data })
        //console.log("elprops", this.props.accion)
        if (this.props.accion == 4) {
            await this.iniciarProveedor()
            await this.iniciarProductos()
        }
        this.totalAcumulados();

    }

    obtienerFechaCtual() {

        const fecha = new Date();


        var year = fecha.getFullYear();
        var mes = fecha.getMonth() + 1;
        var dia = fecha.getDate();

        if (mes < 10) {
            mes = "0" + mes;
        }

        if (dia < 10) {
            dia = "0" + dia;
        }

        var fechaCompra = dia + "-" + mes + "-" + year;

        return fechaCompra
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 200 || resp.code == 300) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        this.setState({ loadingspinners: false })
        return false
    }

    showModal = (accion) => {
        if (accion == "10") {
            var aux = this.state.listaproductosseleccionado;
            var lista = [];
            var total = 0.00;
            var band = false;

            if (this.state.proveedorselect == undefined) {
                toast.info("Selecciona un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            } else {

                if (aux.length > 0) {
                    aux.map((prod) => {
                        //agregamos dos decimales si no lo traen
                        var newCantidad = "" + prod.cantidadCoti;
                        var auxCantidad = newCantidad.split('.');
                        if (auxCantidad.length > 1) {
                            newCantidad = auxCantidad[0].slice(0, 7) + "." + auxCantidad[1].slice(0, 2)
                        } else {
                            newCantidad = auxCantidad[0].slice(0, 7) + ".0000"
                        }

                        var newPrecio = "" + prod.precioCoti;
                        var auxPrecio = newPrecio.split('.');
                        if (auxPrecio.length > 1) {
                            newPrecio = auxPrecio[0].slice(0, 7) + "." + auxPrecio[1].slice(0, 4)
                        } else {
                            newPrecio = auxPrecio[0].slice(0, 7) + ".0000"
                        }




                        let jsonProd = {
                            "idproducto_cotizacionproveedor": (prod.idproducto_cotizacionproveedor) ? (prod.idproducto_cotizacionproveedor) : 0,
                            "idproducto": prod.idproducto,
                            "cantidad": newCantidad,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "precio": newPrecio,
                            "iva": (prod.iva!=undefined?prod.iva:0),
                            "ieps_calculado": (prod.ieps_calculado !=undefined?prod.ieps_calculado:0)
                        }
                        lista.push(jsonProd);
                        //total = total + parseFloat(newPrecio);
                        band = prod.precioCoti <= 0 || prod.cantidadCoti <= 0
                    })
                    if (!band) {
                        this.totalAcumulados();
                      //  console.log("idiepsenmodal", this.state.idtiporequisicionseleccionada)
                        let auxCoti = this.state.nuevaCotizacion;
                        auxCoti.productos = lista;
                        auxCoti.totalprecios = this.state.precios_acumulado;
                        auxCoti.totalivas = this.state.iva_acumulado;
                        auxCoti.totalieps = this.state.ieps_acumulado;
                        auxCoti.idieps = (this.state.idtiporequisicionseleccionada);
                        auxCoti.iva_activo = (this.state.iva_activo == true ? (1) : (0));
                        auxCoti.total = this.state.total_acumulado;

                        if (this.props.accion == 1) {
                            auxCoti.fechacompra = this.obtienerFechaCtual();
                        }

                       // console.log(auxCoti)

                        this.setState({ nuevaCotizacion: auxCoti, show: false })
                        setTimeout(() => {

                            this.props.cerrarModal(auxCoti);
                        }, 200);
                    } else {
                        toast.info("Debe asignar alguna cantidad y precio", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    }
                } else {
                    if (this.props.isProductoGeneral == true) {
                        toast.info("Seleccione al menos un producto general", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    } else {
                        toast.info("Seleccione al menos un agroquímico", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    }

                }
            }
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 2) {
            this.setState({ titulomodal: 'Buscar proveedor', accionmodal: 2, modalproveedores: true })
        } else if (ban.opcion == 3) {
            if (this.props.isProductoGeneral == true) {
                this.setState({ titulomodal: 'Agregar producto general', accionmodal: 3, modalproductos: true })
            } else {
                this.setState({ titulomodal: 'Agregar agroquímicos', accionmodal: 3, modalproductos: true })
            }

        }
    }

    cerrarModalProveedor = async (prov) => {
        this.setState({ modalproveedores: !this.state.modalproveedores })
        if (prov != 0) {
            var existe = 0;
            if (this.props.listacotizaciones.length > 0) {
                existe = this.props.listacotizaciones.filter((cotizacion) => cotizacion.idproveedor == prov.idcatalogo_proveedores && cotizacion.idestatuscotizacion != 4)
            }

            if (existe.length < 1 || existe == 0) {
                let auxCoti = this.state.nuevaCotizacion;
                auxCoti.idproveedor = prov.idcatalogo_proveedores;
                auxCoti.proveedor = prov.nombre;

                this.setState({ proveedorselect: prov, nuevaCotizacion: auxCoti, hubocambio: true })
            } else {
                toast.info("Ya existe una cotización en proceso de compra con este proveedor", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            }

        }
    }

    iniciarProveedor = async () => {

        let auxCoti = this.state.nuevaCotizacion;

        auxCoti.idproveedor = this.props.item.idproveedor;
        auxCoti.proveedor = this.props.item.proveedor;
        auxCoti.btnEditado = true;
        auxCoti.idcotizacion_proveedor = (this.props.accion == 1) ? (0) : this.props.item.idcotizacion_proveedor;
        auxCoti.fechacompra = this.props.item.fechacompra;
        auxCoti.esdummy = this.props.item.esdummy;
        auxCoti.iva_activo = false;
        auxCoti.ieps_activo = false;
        auxCoti.idieps = false;

        var proveedorSel = this.state.listaproveedores.filter((item) => ((item.idcatalogo_proveedores == this.props.item.idproveedor)))

        this.setState({ proveedorselect: proveedorSel[0], nuevaCotizacion: auxCoti })

    }

    iniciarProductos = async () => {
        var aux = this.props.item.productos;

        var lista = [];
        aux.map((prodd) => {
            var productoSel = this.state.listaproductos.filter((item) => item.idproducto == prodd.idproducto);
            if (productoSel.length > 0) {
                productoSel[0].idproducto_cotizacionproveedor = prodd.idproducto_cotizacionproveedor;
                productoSel[0].cantidadStatic = prodd.cantidad;
                productoSel[0].cantidadCoti = prodd.cantidad;
                productoSel[0].precioCoti = parseFloat(prodd.precio).toFixed(4);
                productoSel[0].pendientes = parseFloat(productoSel[0].pendientes);
                productoSel[0].iva = prodd.iva;
                if (this.state.isProductoGeneral != true) {
                    productoSel[0].ieps_calculado = prodd.ieps_calculado;
                }
                lista.push(productoSel[0])
            }

            if (parseInt(this.props.item.iva_activo) == 1) {
                this.setState({ iva_activo: true })
            }
            if (parseInt(this.props.item.idieps) > 0) {

                let encontrado = this.state.arraytipos.find((item => item.idcatalogo_ieps == this.props.item.idieps))
               // console.log("ieps encontrado", encontrado, this.state.arraytipos);
                this.setState({ ieps_activo: true, idtiporequisicionseleccionada: this.props.item.idieps })
            }

        })
       // console.log("iniciarProductos", lista);
        this.setState({ listaproductosseleccionado: lista, cargafirstime: false })

        setTimeout(() => {
            this.totalAcumulados()
        }, 300);


    }

    cerrarModalProductos = async (prod) => {
        this.setState({ modalproductos: !this.state.modalproductos })
        if (prod != 0 && prod.length > 0) {

            var productosseleccionado = [];

            prod.map((p1) => {
                var prodagregado = false;
                this.state.listaproductosseleccionado.forEach((p2) => {
                    if (p2.idproducto == p1.idproducto) {
                        prodagregado = true;
                    }
                })

                if (!prodagregado) {
                    p1.cantidadCoti = 0.00;
                    p1.precioCoti = 0.00;
                    p1.cantidadStatic = 0.00;
                    productosseleccionado.push(p1)
                }
            })

            if (productosseleccionado.length > 0) {
                // si ya habian seleccionados o opciones en la modal, se agregan los nuevos seleccionados
                var concatenarprod = this.state.listaproductosseleccionado.concat(productosseleccionado)

                await this.setState({ listaproductosseleccionado: concatenarprod, hubocambio: true })
            }
        }
    }

    eliminarProducto = async (producto, index) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listaproductosseleccionado.filter((item) => !(item.idproducto == producto.idproducto));
        await this.setState({ listaproductosseleccionado: aux, hubocambio: true })
        this.totalAcumulados();
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    cantidaddisponible(index) {
        if (this.props.accion == 1) {
            return this.state.listaproductosseleccionado[index]['pendientes']
        }
        return parseFloat(this.state.listaproductosseleccionado[index]['pendientes']) + parseFloat(this.state.listaproductosseleccionado[index]['cantidadStatic'])
    }

    escribirCantidadPrecio = async (index, prop, event) => {
        let x = validarNumerosDecimales(event.target.value)

        if(x.codigo=='valid'){

            if (prop == 'cantidadCoti' && this.state.listaproductosseleccionado[index]['pendientes'] == 0
            && parseFloat(event.target.value) > this.state.listaproductosseleccionado[index]['cantidadStatic']
            || prop == 'cantidadCoti' && this.state.listaproductosseleccionado[index]['pendientes'] > 0
            && parseFloat(event.target.value) > this.cantidaddisponible(index)) {
            toast.error("La cantidad supera los agroquímicos disponibles: " + this.cantidaddisponible(index), { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        else {

            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 4 };
            let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
            var updateValor = event.target.value;

            if (tipocorrecto.codigo == 'invalid') {
                var auxUpdateValor = updateValor.split('.');
                if (auxUpdateValor.length > 1) {
                    updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 2)
                } else {
                    updateValor = auxUpdateValor[0].slice(0, 7)
                }
                toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            this.state.listaproductosseleccionado[index][prop] = updateValor;
            if (prop == 'precioCoti' && this.state.iva_activo == true) {
                this.state.listaproductosseleccionado[index]['iva'] = this.calcularIva(this.state.listaproductosseleccionado[index])                
            }
            if (prop == 'precioCoti' && this.state.ieps_activo == true && this.state.idtiporequisicionseleccionada !=0) {
                let iepsencontrado = this.state.arraytipos.find((item => item.idcatalogo_ieps == this.state.idtiporequisicionseleccionada))
                this.state.listaproductosseleccionado[index]['ieps_calculado'] = parseFloat(parseFloat(this.state.listaproductosseleccionado[index].precioCoti) * parseFloat(iepsencontrado.porcentaje)).toFixed(4)                
            }
            var dummy = this.state.listaproductosseleccionado
            this.setState({ listaproductosseleccionado: dummy, hubocambio: true });
            this.totalAcumulados();
        }
        }

        

    };

    escribirImpuestos = async (index, prop, e) => {

       // console.log(index, prop, e.target.value)
        var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 4 };
        let tipocorrecto = validarNumerosDecimales3(e.target.value, longitud)
        var updateValor = e.target.value;

        if (tipocorrecto.codigo == 'invalid') {
            var auxUpdateValor = updateValor.split('.');
            if (auxUpdateValor.length > 1) {
                updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 4)
            } else {
                updateValor = auxUpdateValor[0].slice(0, 7)
            }
            toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        this.state.listaproductosseleccionado[index][prop] = (tipocorrecto.codigo == 'invalid'?(0):(updateValor));
        var dummy = this.state.listaproductosseleccionado
        this.setState({ listaproductosseleccionado: dummy, hubocambio: true });
        this.totalAcumulados();

    }

    checkImpuestos = async (prop, booleano) => {
       // console.log(prop, booleano)

        let lista = this.state.listaproductosseleccionado;
        if (prop == "iva_activo") {
            if (booleano == false) {
                lista.forEach(prod => {
                    prod.iva = "0";
                });
            }
            else if (booleano == true) {
                lista.forEach(prod => {
                    prod.iva = this.calcularIva(prod)
                });
            }
            this.setState({ hubocambio: true })
        }

        if (prop == "ieps_activo") {
            if (booleano == false) {
                lista.forEach(prod => {
                    prod.ieps_calculado = "0";
                });
                this.setState({ idtiporequisicionseleccionada: todaslasopcionesdisponibles,hubocambio: true  })
            }
            else if (booleano == true) {
                lista.forEach(prod => {
                    prod.ieps_calculado = "0"
                });
            }
        }
        this.setState({ [prop]: booleano, listaproductosseleccionado: lista })

        this.totalAcumulados();
    }

    calcularIva(prod) {
        //console.log(prod)
        return parseFloat(parseFloat(prod.precioCoti) * 0.16).toFixed(4)
    }







    seleccionadoIEPS = async (iepsseleccionado) => {
        let iepsencontrado = this.state.arraytipos.find((item => item.idcatalogo_ieps == iepsseleccionado))
       // console.log("seleccionadoIEPS", iepsseleccionado, iepsencontrado);

        let lista = this.state.listaproductosseleccionado;
        lista.forEach(prod => {
            prod.ieps_calculado = parseFloat(parseFloat(prod.precioCoti) * parseFloat(iepsencontrado.porcentaje)).toFixed(4)
        });

        this.setState({ listaproductosseleccionado: lista, idtiporequisicionseleccionada: iepsencontrado.idcatalogo_ieps });
        this.totalAcumulados();

    }

    totalProducto(producto) {
        if (this.state.isProductoGeneral == true) {
            let productocoti=parseFloat(producto.precioCoti?producto.precioCoti:0)
            let productoiva= parseFloat(producto.iva?producto.iva:0)
            return parseFloat( productocoti + productoiva).toFixed(4)
        }
        else {
            let productocoti=parseFloat(producto.precioCoti?producto.precioCoti:0)
            let productoiva= parseFloat(producto.iva?producto.iva:0)
            let productoieps= parseFloat(producto.ieps_calculado?producto.ieps_calculado:0)

            return parseFloat(productocoti + productoiva + productoieps).toFixed(4)
        }
    }
    totalAcumulados() {
        var sumaprecios = 0;
        var sumarivas = 0;
        var sumarieps = 0;
        let sumatotal = 0;
        this.state.listaproductosseleccionado.forEach(prod => {
            sumaprecios = sumaprecios + parseFloat(prod.precioCoti);
            sumarivas = sumarivas + parseFloat(prod.iva);
            sumarieps = sumarieps + parseFloat(prod.ieps_calculado);
        });


       // console.log(typeof (sumarivas), isNaN(sumarivas))
        if (isNaN(sumaprecios) == true) {
            sumaprecios = 0;
        }
        if (isNaN(sumarivas) == true) {
            sumarivas = 0;
        }
        if (isNaN(sumarieps) == true) {
            sumarieps = 0;
        }

        sumatotal = sumaprecios + sumarivas + sumarieps

        this.setState({ precios_acumulado: sumaprecios, iva_acumulado: parseFloat(sumarivas).toFixed(4), ieps_acumulado: parseFloat(sumarieps).toFixed(4), total_acumulado: parseFloat(sumatotal).toFixed(4) })
        //console.log("totalacumulados", sumaprecios, sumarivas, sumarieps, sumatotal)
    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalproveedores == true) ? (
                    <ModalBuscarProveedorCotizacions
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProveedor}
                        show={this.state.modalproveedores}
                        accion={this.state.accionmodal}
                        listaproveedores={this.state.listaproveedores}
                        listatipoproveedores={this.state.listatipoproveedores} />
                ) : null}

                {(this.state.modalproductos == true) ? (
                    <ModalAgregarProductoCotizacion
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProductos}
                        show={this.state.modalproductos}
                        accion={this.state.accionmodal}
                        listaproductos={this.state.listaproductos}
                        isProductoGeneral={this.props.isProductoGeneral} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    style={{minWidth:"1300px"}}
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.props.isProductoGeneral == true ? "Asignar producto general" : "Asignar agroquímicos"}</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Proveedor: </label>
                                                <label className='lblTexto letraTitulos'>{this.state.proveedorselect ? this.state.proveedorselect.nombre : "Seleccione un proveedor"}</label>
                                            </div>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnActualizar letraNormal botonesB'
                                                    onClick={this.verModalDetalles.bind(this, { opcion: "2" })}>Buscar</button>
                                            </div>
                                        </div>
                                        <div className='row paddingEncabezados'>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                {this.props.isProductoGeneral == true ? <label className='lblTexto letraTitulos'>Productos generales seleccionados: </label> : <label className='lblTexto letraTitulos'>Agroquímicos seleccionados: </label>}
                                            </div>

                                            <div className='offset-6 col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnActualizar letraNormal botonesB'
                                                    onClick={this.verModalDetalles.bind(this, { opcion: "3" })}>+ Agregar</button>
                                            </div>
                                        </div>
                                        <div className='row '>

                                            <div className='col-auto containerSubTabla '>
                                                <label className='lblTexto letraTitulos checkPlanAnual' >
                                                    <input type="checkbox" checked={this.state.iva_activo} onClick={this.checkImpuestos.bind(this, 'iva_activo', !this.state.iva_activo)} />Aplicar IVA </label>
                                            </div>

                                            {this.props.isProductoGeneral != true ? (
                                                <div className='col-auto containerSubTabla 'style={{display:"flex"}}>
                                                    <label className='lblTexto letraTitulos checkPlanAnual' style={{marginTop:"auto", marginBottom:"auto"}} >
                                                        <input type="checkbox" checked={this.state.ieps_activo} onClick={this.checkImpuestos.bind(this, "ieps_activo", !this.state.ieps_activo)} />
                                                        Aplicar IEPS 
                                                    </label>

                                                    {this.state.ieps_activo == true ? (
                                                        <>
                                                        
                                                        
                                                        <div className='col-auto containerSubTabla paddingBottomEncabezados' style={{marginTop:"auto", marginBottom:"auto"}}>
                                                            <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>IEPS </label>
                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                        onChange={(event) => this.seleccionadoIEPS(event.target.value)} >
                                                                        {<option selected={this.state.idtiporequisicionseleccionada == todaslasopcionesdisponibles} value={todaslasopcionesdisponibles} disabled={true}>Seleccionar</option>}
                                                                        {this.state.arraytipos.map((i, index) => (
                                                                            <option value={i.idcatalogo_ieps} key={"tipo_" + index} selected={this.state.idtiporequisicionseleccionada == i.idcatalogo_ieps} >{i.porcentaje} </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ) : null}



                                                </div>
                                            ) : null}



                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu" style={{minWidth:"1100px", width:"100%"}}>
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.props.isProductoGeneral == true ?
                                                            this.state.tabla.encabezados2.map((titulo, index) => (

                                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[(index == 5) ? (6) : (index == 6) ? (7) : index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))
                                                            :
                                                            this.state.tabla.encabezados.map((titulo, index) => (
                                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaproductosseleccionado.length > 0 ? this.state.listaproductosseleccionado.map((producto, index) => (
                                                            <tr key={"tabla" + index} className={producto.cantidadCoti <= 0 || producto.precioCoti <= 0 ? "trFondoIncompleto" : ""} style={{ textAlign: "right" }}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: "left" }}>
                                                                    {producto.producto}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {producto.pendientes}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, }}>
                                                                    <label style={{ display: "flex" }}>
                                                                        {producto.unidadmedida+" " }
                                                                        <input type={'text'} pattern="^\d*,?\d*$" className="inputSubtabla" value={producto.cantidadCoti} onChange={(e) => this.escribirCantidadPrecio(index, 'cantidadCoti', e)} style={{ textAlign: "right", marginLeft:"5px" }}></input>
                                                                    </label>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <input type={'text'} className="inputSubtabla" value={(producto.precioCoti?producto.precioCoti:"0.00")} onChange={(e) => this.escribirCantidadPrecio(index, 'precioCoti', e)} style={{ textAlign: "right", }}></input>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {(this.state.iva_activo == true) ? (
                                                                        <input type={'text'} className="inputSubtabla" value={producto.iva?producto.iva:"0.0000"} onChange={(e) => this.escribirImpuestos(index, 'iva', e)} style={{ textAlign: "right", }}></input>

                                                                    ) : (
                                                                        producto.iva?producto.iva:"0.0000"
                                                                    )}

                                                                </td>

                                                                {this.props.isProductoGeneral != true ? (
                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                        {(this.state.ieps_activo == true) ? (
                                                                            <input type={'text'} className="inputSubtabla" value={producto.ieps_calculado?producto.ieps_calculado:"0.0000"} onChange={(e) => this.escribirImpuestos(index, 'ieps_calculado', e)} style={{ textAlign: "right", }}></input>

                                                                        ) : (
                                                                            
                                                                            producto.ieps_calculado?producto.ieps_calculado:"0.0000"
                                                                        )}
                                                                    </td>
                                                                ) : null}

                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    ${formatoNumero(this.totalProducto(producto))}

                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <div className="modalDetallesCheck" >
                                                                        <button className='btnEliminarFila'
                                                                        ><FaTrashAlt className="icoBotones" onClick={() => this.eliminarProducto(producto, index)} /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        )) : null
                                                    }

                                                    {this.state.listaproductosseleccionado.length > 0 ? (
                                                        <tr key={"tabla_acumulados"} style={{ textAlign: "right", fontWeight: "bold ", border: "0px !important" }}>
                                                            <td className="letraTitulos lblSubtabla"
                                                                style={{ textAlign: "left", color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                Totales acumulados
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                ${formatoNumero(this.state.precios_acumulado)}
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                {this.state.iva_activo == true ? ("$" + formatoNumero(this.state.iva_acumulado)) : (null)}
                                                            </td>

                                                            {this.props.isProductoGeneral != true ? (
                                                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                    {this.state.ieps_activo == true ? ("$" + formatoNumero(this.state.ieps_acumulado)) : (null)}
                                                                </td>
                                                            ) : null}

                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>
                                                                {"$" + formatoNumero(this.state.total_acumulado)}
                                                            </td>
                                                            <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla, borderBottomWidth: "0px" }}>

                                                            </td>
                                                        </tr>
                                                    ) : "Tabla vacía"}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.state.accion == 1 || this.state.accion == 4 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')}  >Asignar</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}