import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosLongitudV2, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { updateEquipo } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { BsQuestionSquare } from 'react-icons/bs';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioEquipos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      listatipoequipos: props.listatipoequipos,

      txtnombreclave: (props.accion == 2) ? (props.item.nombreclave) : '',
      vermsgerrortxtnombre: false,
      msgerrortxtnombre: "",

      idtipoequipo: (props.accion == 2) ? (props.item.idtipo_equipo) : todoslosfiltrosdisponibles,
      vermsgerrortxttipo: false,
      msgerrortxttipo: '',

      txtmarca: (props.accion == 2) ? (props.item.marca) : '',
      vermsgerrortxtmarca: false,
      msgerrortxtmarca: "",

      txtmodelo: (props.accion == 2) ? (props.item.modelo) : '',
      vermsgerrortxtmodelo: false,
      msgerrortxtmodelo: "",

      txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
      vermsgerrortxtdescripcion: false,
      msgerrortxtdescripcion: "",

      arrayestatus: props.estatus,
      idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,

      listacombustibles: props.listacombustibles,
      idcombustible: (props.accion == 2) ? (props.item.idcombustible) : todoslosfiltrosdisponibles,
      vermsgerrortxtcombustible: false,
      msgerrortxtcombustible: "",
      usacombustible: (props.accion==2)?( props.item.idcombustible==0 ?(0):(1) ):0,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo
    }

  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    this.setState({ vermsgerrortxtnombre: false })
    this.setState({ vermsgerrortxttipo: false })
    this.setState({ vermsgerrortxtmarca: false })
    this.setState({ vermsgerrortxtmodelo: false })
    this.setState({ vermsgerrortxtdescripcion: false })
    this.setState({ vermsgerrortxtarchivo: false })
    this.setState({ vermsgerrortxtcombustible: false })
    let validacionescorrectas = true

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
    let txtnombreclave = validarLetrasNumerosEspacios(this.state.txtnombreclave.trim(), longitudvalida)
    if (txtnombreclave.codigo == "invalid") {
      this.setState({ msgerrortxtnombre: txtnombreclave.mensaje })
      this.setState({ vermsgerrortxtnombre: true })
      validacionescorrectas = false;
    }

    if (this.state.idtipoequipo == todoslosfiltrosdisponibles) {
      this.setState({ msgerrortxttipo: "Este campo es obligatorio" })
      this.setState({ vermsgerrortxttipo: true })
      validacionescorrectas = false;
    }


    longitudvalida = { longitudminima: 1, longitudmaxima: 20 };
    if (this.state.txtmarca.trim().length > 0) {
      let txtmarca = validarLetrasNumerosEspacios(this.state.txtmarca.trim(), longitudvalida)
      if (txtmarca.codigo == "invalid") {
        this.setState({ msgerrortxtmarca: txtmarca.mensaje })
        this.setState({ vermsgerrortxtmarca: true })
        validacionescorrectas = false;
      }
    }

    if (this.state.txtmodelo.trim().length > 0) {
      let txtmodelo = validarLetrasNumerosEspacios(this.state.txtmodelo.trim(), longitudvalida)
      if (txtmodelo.codigo == "invalid") {
        this.setState({ msgerrortxtmodelo: txtmodelo.mensaje })
        this.setState({ vermsgerrortxtmodelo: true })
        validacionescorrectas = false;
      }
    }


    if (this.state.txtdescripcion.trim().length > 0) {
      longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
      if (txtdescripcion.codigo == "invalid") {
        this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
        this.setState({ vermsgerrortxtdescripcion: true })
        validacionescorrectas = false;
      }
    }

    if (this.state.idcombustible == todoslosfiltrosdisponibles && this.state.usacombustible == 1) {
      this.setState({ msgerrortxtcombustible: "Selecciona un combustible" })
      this.setState({ vermsgerrortxtcombustible: true })
      validacionescorrectas = false;
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)
      let json = {
        "idequipo": this.state.accion == 1 ? 0 : (this.props.item.idequipo),
        "idtipoequipo": this.state.idtipoequipo,
        "nombreclave": this.state.txtnombreclave,
        "marca": this.state.txtmarca,
        "modelo": this.state.txtmodelo,
        "descripcion": this.state.txtdescripcion,
        "idcombustible": (this.state.idcombustible != -1) ? (this.state.idcombustible) : 0,
        "idestatus": this.state.idestatus
      }

      var resp = await updateEquipo(json);

      if(resp.code == 0){
        if (this.state.accion == 1) {
            this.showModal('1')
        }
        else if (this.state.accion == 2) {
            this.showModal('2')
        }
      }
      else if(resp.code == 200){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 300){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 400){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if (resp.code == 1000) {
          this.setLoadingSpinner(false);
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
      }else{
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }

  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre / Clave</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnombreclave} onChange={this.escribir("txtnombreclave")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombreclave}</label>}

                {this.state.vermsgerrortxtnombre ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombre}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo equipo</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idtipoequipo", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} key={"tipo_x"} selected={todoslosfiltrosdisponibles == this.state.idtipoequipo} >Seleccionar </option>
                          {this.state.listatipoequipos.map((i, index) => (
                            <option value={i.idtipoequipo} key={"tipo_" + index} selected={i.idtipoequipo == this.state.idtipoequipo} >{i.tipo} </option>
                          ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                {this.state.vermsgerrortxttipo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttipo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Marca </p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtmarca} onChange={this.escribir("txtmarca")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmarca}</label>}

                {this.state.vermsgerrortxtmarca ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmarca}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Modelo </p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtmodelo} onChange={this.escribir("txtmodelo")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmodelo}</label>}

                {this.state.vermsgerrortxtmodelo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmodelo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}

                {/*/////////////// Combustible ///////////////*/}

                <div className="checkAccesoAlsistema letraNormal" style={{marginBottom:"10px"}} onClick={() => this.setState({ usacombustible: this.state.usacombustible == 0 ? 1 : 0, idcombustible:todoslosfiltrosdisponibles })}>
                  <input type="checkbox" checked={this.state.usacombustible} /> Usa combustible
                </div>
                {this.state.usacombustible == 1 ? (
                  <>
                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Combustible</p>
                    {this.state.accion == 2 || this.state.accion == 1 ? (
                      <div className='col lblSubTitulosModal '>
                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                          <div className="selectBox ">
                            <select className="cbxTexto letraNormal"
                              onChange={(event) => this.cambiarTipo({ tipo: "idcombustible", valor: event.target.value })}>
                              <option value={todoslosfiltrosdisponibles} key={"tipo_xy"} selected={todoslosfiltrosdisponibles == this.state.idcombustible} >Seleccionar </option>
                              {this.state.listacombustibles.map((i, index) => (
                                <option value={i.idtipocombustible} key={"tipoc_" + index} selected={i.idtipocombustible == this.state.idcombustible} >{i.tipo} </option>
                              ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Seleccionar</label>}

                    {this.state.vermsgerrortxtcombustible ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcombustible}</p> : null}


                  </>
                ) : null}


                {/*/////////////// Fin combustible ///////////////*/}

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idestatus", valor: event.target.value })}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                }
              </div>
              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

