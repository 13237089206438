import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { Table } from 'reactstrap';
import "./ModalFormularioTipoUsuarios.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioTipoUsuariosNuevoAccesos extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,
            txttipousuario: '',
            idestatus: 1,
            vermsgerrordescripcion: false,
            vermsgerrortipo: false,
            msgerror: '',
            msgerrortipo: "",
            msgerrordescripcion: "",
            listatipomodulos: props.listatipomodulos,
            listamodulos: props.listamodulos,
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            selecttipo: '0',	
            listaproductosfiltrados: [],
            idproductoseleccionado: [],
        }

    }
    async componentDidMount() {
        
    }
    
    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        this.showModal(this.state.idproductoseleccionado)
    }

    filtrarTipoAceeso = async (itemfiltrado) => {
        var data = this.state.listamodulos.modulos;
      
        await this.setState({ selecttipo: itemfiltrado, idproductoseleccionado: []})
      
        var filtrofinal = [];

        filtrofinal = data.filter((item) => item.idtipomodulo==itemfiltrado)
        
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({listaproductosfiltrados: filtrofinal})
    }

    seleccionarProducto = async (item) => {
        if(this.state.idproductoseleccionado.indexOf(item.idmodulo)!=-1){
            this.state.idproductoseleccionado.splice(this.state.idproductoseleccionado.indexOf(item.idmodulo),1);
        }else{
            this.state.idproductoseleccionado.push(item.idmodulo)
        }
        
        await this.setState({})
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Seleccione un tipo</p>
                            
                                <div className='containerSubTabla paddingBottomEncabezados'>
                                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" onChange={(event) => this.filtrarTipoAceeso(event.target.value)}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listatipomodulos.map((i, index) => (
                                                        <option value={i.idtipomodulo} key={"tipo_" + index}>{i.tipo}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla" style={{ overflow: "hidden" }}>
                                    <div className="container-fluid">
                                        <div className="containerSubTabla">
                                            <Table hover>
                                                <tbody style={{ overflow: "hidden", verticalAlign: "bottom" }}>
                                                    {this.state.listaproductosfiltrados.length > 0 ?
                                                        this.state.listaproductosfiltrados.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)} className={this.state.idproductoseleccionado.indexOf(item.idmodulo) != -1 ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.modulo}
                                                                </td>
                                                            </tr>
                                                        )) : "No hay permisos disponibles"
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.state.accion == 4 ? (
                            <>
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}