import React from 'react'
import { FaCalendar } from 'react-icons/fa';
import { Button, Modal, ModalBody } from 'reactstrap';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createNuevaNominaSeguridad } from '../../Services/Api';
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';


export default class ModalNuevaNominaSeguridad extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpomodal,
            loadingspinners: false,

            fechacompraini: new Date(),
            fechacomprafin: new Date(),


            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

        }

    }

    showModal = async (accion) => {

        if (accion == 1) {
            this.setState({ loadingspinners: true })
            const result = format(this.state.fechacompraini, 'yyyy-MM-dd');
            const result2 = format(this.state.fechacomprafin, 'yyyy-MM-dd');


            localStorage.setItem("idcatalogo_nominas", "0")

            var json = {
                "fechainicio": result,
                "fechafin": result2,
                "fechainiciojson": this.state.fechacompraini,
                "fechafinjson": this.state.fechacomprafin,
                "idestatus": "5"
            }

            var json2 = {
                "fechainicio": result,
                "fechafin": result2,
                "idestatus": "5"
            }

            var resp = await createNuevaNominaSeguridad(json2);

            if(resp.code == 0|| resp.code == "0") {
                this.setState({ loadingspinners: false })
                toast.info("Ya tienes una nomina con esa fecha", { autoClose: 4000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 200){
                this.setState({ loadingspinners: false })
                this.setState({ show: false });
                localStorage.setItem("nominanueva", 1);
                setTimeout(() => {
                    this.props.cerrarmodal(1, json);
                }, 200);
            }
            else if(resp.code == 300){
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setState({ loadingspinners: false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setState({ loadingspinners: false })
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

        }
        else {
            this.setState({ loadingspinners: false })

            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarmodal(0);
            }, 200);

        }

    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        // //console.log("Entro en cambiar fecha")
        // //console.log(item)

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    render() {
        return (

            <>
                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >Nueva Nómina Seguridad</label><br />
                            <small style={{ paddingLeft: "8px" }}>Selecciona fecha inicio y fecha fin</small>
                        </div>


                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>


                                <div className='row'>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal ' >
                                            Fecha Inicio:

                                            <DatePicker
                                                selected={this.state.fechacompraini}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </p>

                                    </div>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal ' >
                                            Fecha Fin:

                                            <DatePicker
                                                selected={this.state.fechacomprafin}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.showModal(1)} disabled={!this.state.confirmarenvio}>Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }
}


const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));
