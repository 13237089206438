import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { FaTrashAlt } from 'react-icons/fa'
import { BiEdit } from "react-icons/bi";
import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estiloBtnEditar, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import ModalNotasFormulario from './ModalNotasFormulario';

const todoslosfiltrosdisponibles = -1
const opcionsinespecificar = -2

export default class ModalNotasOrden extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      modalnotasformulario: false,

      tabla: {
        encabezados: [
          "Título",
          "Descripción",
          "",
        ],
        dimensiones: [
          "25%",
          "60%",
          "15%",
        ],
        mindimensiones: [
          "200px",
          "200px",
          "80px"
        ],
        elementos: props.notas,
      },



      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

    }

  }



  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }


  abrirEditor = (item) => {
    this.setState({ modalnotasformulario: true, item: item })

  }

  cerrarModalEditor = (item) => {
    if (item != '0') {
      let x = this.state.tabla;
      let nueva = [];
      let encontrado = x.elementos.findIndex(a => a.idnota == item.idnota)
      if (encontrado == -1) {
        x.elementos.push(item)
      } else {
        x.elementos[encontrado].titulo = item.titulo
        x.elementos[encontrado].descripcion = item.descripcion
      }
      this.setState({ tabla: x })
    }
    
    this.setState({ modalnotasformulario: false })
  }

  guardarCambios = async () => {
    // Mandar datos del item de producto seleccionado
    this.showModal(this.state.tabla.elementos)//productoseleccionado)
  }

  seleccionarElementoaEliminar(indexitem) {
    let lista = [];

    this.state.tabla.elementos.forEach((el, index) => {
      if (index != indexitem) {
        lista.push(el)
      }
    });
    let x = this.state.tabla
    x.elementos = lista
    this.setState({ tabla: x })
   
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {this.state.modalnotasformulario ? (
          <ModalNotasFormulario titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalEditor}
            show={this.state.modalnotasformulario}
            numnotas={this.state.tabla.elementos.length}
            item={this.state.item}
          />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="lg"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>


                                {index == 2 && this.props.idestatus !=4 ? (
                                  <button className='btnNuevo letraNormal' onClick={() => this.abrirEditor(1)}/*onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}*/
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                  > Nueva</button>
                                ) : (titulo)
                                }
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {/*<tr onClick={() => this.seleccionarProducto({ idequipo: opcionsinespecificar })}
                            /*className={this.state.idproductoseleccionado == item.idequipo ? "trFondoActivoGreen" : ""}* /
                            className={this.state.productosseleccionados[opcionsinespecificar] != undefined ? "trFondoActivoGreen" : ""} >
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                              Sin especificar
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            </td>
                          </tr>*/}

                          {this.state.tabla.elementos.map((item, index) => (
                            <tr key={"tabla_nota_" + index}                            >
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.titulo}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                {item.descripcion}
                              </td>
                              {this.props.idestatus !=4 ? 
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                <button className='btnEliminarFila'

                                  onClick={() => this.abrirEditor(item)}
                                  style={{
                                    backgroundColor: this.state.btnfondoeditar,
                                    color: this.state.btncoloreditar,
                                    marginRight: "10px"
                                  }}>
                                  <BiEdit className="icoBotones" /> </button>
                                <button className='btnEliminarFila'
                                onClick={() => this.seleccionarElementoaEliminar( index)} >
                                  <FaTrashAlt className="icoBotones" />
                                </button>
                              </td>
                              :null 
  }
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.props.idestatus !=4 ? 
            <>
            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}  >Agregar</Button>
            </div>
            </>

            :null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

