import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioProductosGenerales.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud, validarLetrasMayusculasYNumeros } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { agregarProductoGeneral } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioProductosGenerales extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo
            titulo: props.titulomodal,
            loadingspinners: false,
            txtclave: (props.accion == 2) ? (props.item.clave) : '',
            txtnombrecomercial: (props.accion == 2) ? (props.item.nombrecomercial) : '',
            txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
            txttipoproducto: (props.accion == 2) ? (props.item.idtipo_producto_general) : '',
            txtunidadmedida: (props.accion == 2) ? (props.item.idcatalogo_unidadmedida) : '',
            arrayestatus: props.estatus,
            idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,
            vermsgerrorclave: false,
            vermsgerrornombrecomercial: false,
            vermsgerrordescripcion: false,
            vermsgerrortipoproducto: false,
            vermsgerrorunidadmedida: false,
            msgerror: '',
            msgerrorclave: "",
            msgerrornombrecomercial: "",
            msgerrordescripcion: "",
            msgerrortipoproducto: "",
            msgerrorunidadmedida: "",
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            listatipoproducto: props.listatipoproducto,
            listaunidadmedida: props.listaunidadmedida

        }

    }
    async componentDidMount() {
        this.cambiarValor(this.state.idestatus)
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorclave: false })
        this.setState({ vermsgerrornombrecomercial: false })
        this.setState({ vermsgerrortipoproducto: false })
        this.setState({ vermsgerrordescripcion: false })
        this.setState({ vermsgerrorunidadmedida: false })
        let validacionescorrectas = true
        let longitudvalida = ""
        let validacion = ""

        longitudvalida = {longitudminima: 1, longitudmaxima: 10};
        /*validacion = validarLetrasMayusculasYNumeros(this.state.txtclave.trim(), longitudvalida);
        if (validacion.codigo == "invalid") {
            this.setState({ msgerrorclave: validacion.mensaje })
            this.setState({ vermsgerrorclave: true })
            validacionescorrectas = false;
        }*/

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        validacion = validarLetrasNumerosLongitud(this.state.txtnombrecomercial.trim(), longitudvalida);
        if (validacion.codigo == "invalid") {
            this.setState({ msgerrornombrecomercial: validacion.mensaje })
            this.setState({ vermsgerrornombrecomercial: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 300};
        validacion = validarLetrasNumerosLongitud(this.state.txtdescripcion.trim(), longitudvalida);
        if(this.state.txtdescripcion !=""){
            if (validacion.codigo == "invalid") {
                this.setState({ msgerrordescripcion: validacion.mensaje })
                this.setState({ vermsgerrordescripcion: true })
                validacionescorrectas = false;
            }
        }
       

        if (this.state.txttipoproducto.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortipoproducto: "Seleccione un tipo de producto" })
            this.setState({ vermsgerrortipoproducto: true })
        }

        if (this.state.txtunidadmedida.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorunidadmedida: "Seleccione una unidad de medida" })
            this.setState({ vermsgerrorunidadmedida: true })
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);

            let json = {
                "idcatalogo_producto_general": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogo_producto_general),
                "clave": this.state.txtclave,
                "nombrecomercial": this.state.txtnombrecomercial,
                "idtipoproductogeneral": this.state.txttipoproducto,
                "idcatalogo_unidadmedida": this.state.txtunidadmedida,
                "descripcion": this.state.txtdescripcion,
                "idestatus": this.state.idestatus
            }

            var resp = await agregarProductoGeneral(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                               {/* <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Clave</p>
                                
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtclave} onChange={this.escribir("txtclave")}></input>
                               

                                {this.state.vermsgerrorclave ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorclave}</p> : null}
                                */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre comercial</p>
                               
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombrecomercial} onChange={this.escribir("txtnombrecomercial")}></input>
                                
                                {this.state.vermsgerrornombrecomercial ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombrecomercial}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                               
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                
                                {this.state.vermsgerrordescripcion ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo producto general</p>
                                {<div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                           
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={this.escribir("txttipoproducto")}>
                                                      {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}  
                                                    {this.state.listatipoproducto.map((i, index) => (
                                                       this.state.accion == 1 ?
                                                        <option value={i.idtipo_producto_general} key={"tipo_" + index} >{i.tipo} </option>:
                                                        <option value={i.idtipo_producto_general} key={"tipo_" + index} selected={i.idtipo_producto_general==this.state.txttipoproducto ? true:false} >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                </div>}

                                {this.state.vermsgerrortipoproducto ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipoproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Unidad de medida</p>
                                {<div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txtunidadmedida")}>
                                                {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}  
                                                    {this.state.listaunidadmedida.map((i, index) => (
                                                       this.state.accion == 1 ?
                                                        <option value={i.idcatalogo_unidadmedida} key={"tipo_" + index} >{i.clave} </option>:
                                                        <option value={i.idcatalogo_unidadmedida} key={"tipo_" + index} selected={i.idcatalogo_unidadmedida==this.state.txtunidadmedida ? true:false} >{i.clave} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                </div>}

                               

                                {this.state.vermsgerrorunidadmedida ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorunidadmedida}</p> : null}

                               {/* <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                          
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal"
                                                onChange={(event) => this.cambiarValor(event.target.value)}>
                                                {this.state.arrayestatus.map((i, index) => (
                                                    <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>

                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

