import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoProducto.css';
import { BiEdit } from "react-icons/bi";
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloContenedores, estiloBtnEliminar } from '../../Services/PaletaDeColores';
import ModalFormularioProducto from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioProducto';
import ModalFormularioIngredienteActivo from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioIngredienteActivo';
import ModalFormularioPlagasControlar from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioPlagasControlar';
import ModalFormularioCultivosAutorizados from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioCultivosAutorizados';
import ModalFormularioPresentaciones from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioPresentaciones';
import ModalFormularioDocumentos from '../../Complements/Modales/ModalFormularioProducto/ModalFormularioDocumentos';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import ModalAutorizacion from '../../Complements/Modales/ModalFormularioProducto/ModalAutorizacion/ModalAutorizacion';
import TablaContenidoUnico from './TablaContenidoUnico';
import TablaListado from './TablaListado';
import {
  getEstatus, getListaIngredientesActivos,
  getListaPresentacionDeProductosDisponibles, getComboTipoDocumentos, getListaCatalogoDocumentos,
  getComboFamiliaProducto, getComboTipoProducto, getListaUnidadesDeMedidaDisponibles,
  getListaProductoInformacion, getComboPlagas, getComboTipoPlagas,
  getComboTipoCultivo, getListaCultivosDisponibles
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

const tablaeliminar = ["ingrediente activo", "plaga", "cultivo autorizado", "presentación", "archivo"];

export default class CatalogoProducto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,

      tablainformaciongeneral: {
        encabezados: [[
          "Nombre comercial",
          "Familia de agroquímico",
          "Tipo de agroquímico",
          "Registro COFEPRIS",
          "Estatus"
        ], [
          "Unidad de medida",
          "Banda toxicológica",
          "Intervalo de seguridad",
          "Tiempo reentrada",
          "Dosis mínima",
          "Dosis máxima",
          "Volumen agua"
        ]],
        dimensiones: [[
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "25%",
          "20%"
        ], [
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%"
        ]],
        mindimensiones: [[
          "190px",
          "190px",
          "190px",
          "190px",
          "240px",
          "200px"
        ], [
          "170px",
          "180px",
          "210px",
          "170px",
          "140px",
          "140px",
          "190px"
        ]],
        titulo: 'Información general',
        elementos: []
      },
      tablaingredientesactivos: {
        encabezados: [
          "Nombre",
          "Concentración",
          "Comentario",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "30%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "150px"
        ],
        titulo: 'Ingredientes activos',
        elementos: []
      },
      tablaplagasacontrolar: {
        encabezados: [
          "Plaga",
          "Tipo plaga",
          "Descripción",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "30%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "150px"
        ],
        titulo: 'Plagas a controlar',
        elementos: []
      },
      tablacultivosautorizados: {
        encabezados: [
          "Cultivo",
          "Tipo cultivo",
          "Descripción",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "30%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "150px"
        ],
        titulo: 'Cultivos autorizados',
        elementos: []
      },
      tablapresentaciones: {
        encabezados: [
          "Presentación",
          "Cantidad",
          "Unidad de medida",
          ""
        ],
        dimensiones: [
          "20%",
          "20%",
          "30%",
          "20%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "150px"
        ],
        titulo: 'Presentaciones',
        elementos: []
      },
      tabladocumentos: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Comentario",
          "Creado",
          ""],
        dimensiones: [
          "14%",
          "14%",
          "20%",
          "20%",
          "20%",
          "12%"
        ],
        mindimensiones: [
          "140px",
          "130px",
          "180px",
          "200px",
          "200px",
          "150px"
        ],
        titulo: 'Documentos',
        elementos: []
      },
      estatus: [],
      listaelementosfiltrados: [],

      listafamiliaproducto: [],
      listatipoproducto: [],
      listaunidadesmedida: [],

      listaingredientesactivos: [],

      listatipoplagas: [],
      listaplagas: [],

      listatipocultivo: [],
      listacultivo: [],

      listapresentaciones: [],
      unidadmedida: [],

      listatiposdocumento: [],
      listadocumentos: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      modaldetallesproducto: false,
      modaldetallesingredientes: false,
      modaldetallesplagas: false,
      modaldetallescultivos: false,
      modaldetallespresentaciones: false,
      modaldetallesdocumentos: false,
      itemseleccionado: null,
      errordeacceso: false,

      redireccionando: false,
      modalautorizacion: false,
      modalautorizaciontitulo: "",

      colorborde: estiloContenedores().colorborde,
      fondoblancocontenedor: estiloContenedores().colorblancofondocontenedor,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      fondoblancobtneditar: estiloBtnEditar().fondoblancobtneditar,

      colorfondobtneliminar: estiloBtnEliminar().colorfondobtneliminar,
      colorbtneliminar: estiloBtnEliminar().colorbtneliminar,
      colorbordebtneliminar: estiloBtnEliminar().colorbordebtneliminar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idproductoinformacion = await localStorage.getItem('dato')
    if (idproductoinformacion != null) {
      var respproductoinformacion = await getListaProductoInformacion(idproductoinformacion);
      if (this.manejadorLlamadasApis(respproductoinformacion)) {
        return;
      }

      if (respproductoinformacion.code == 200) {
        await toast.error(respproductoinformacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var tablainformaciongeneral = this.state.tablainformaciongeneral;
      tablainformaciongeneral.elementos = respproductoinformacion.data ? respproductoinformacion.data : {};
      var unidadmedida = tablainformaciongeneral.elementos.unidadmedida

      ///////////////// Se asignan datos de tabla Datos Fiscales
      var tablaingredientesactivos = this.state.tablaingredientesactivos;
      tablaingredientesactivos.elementos = tablainformaciongeneral.elementos.ingredientesactivos;

      ///////////////// Se asignan datos de tabla Contactos
      var tablaplagasacontrolar = this.state.tablaplagasacontrolar;
      tablaplagasacontrolar.elementos = tablainformaciongeneral.elementos.plagasacontrolar;

      ///////////////// Se asignan datos de tabla Direcciones
      var tablacultivosautorizados = this.state.tablacultivosautorizados;
      tablacultivosautorizados.elementos = tablainformaciongeneral.elementos.cultivosautorizados;

      ///////////////// Se asignan datos de tabla Direcciones
      var tablapresentaciones = this.state.tablapresentaciones;
      tablapresentaciones.elementos = tablainformaciongeneral.elementos.presentaciones;

      ///////////////// Se asignan datos de tabla Documentos
      var tabladocumentos = this.state.tabladocumentos;
      tabladocumentos.elementos = tablainformaciongeneral.elementos.documentos;

      this.setState({
        tablainformaciongeneral: tablainformaciongeneral,
        tablaingredientesactivos: tablaingredientesactivos,
        tablaplagasacontrolar: tablaplagasacontrolar,
        tablacultivosautorizados: tablacultivosautorizados,
        tablapresentaciones: tablapresentaciones,
        tabladocumentos: tabladocumentos,
        unidadmedida: unidadmedida
      });
    } else {
      this.redireccionando();
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Información General
    var respfamiliaproducto = await getComboFamiliaProducto();
    if (this.manejadorLlamadasApis(respfamiliaproducto)) {
      return;
    }
    var resptipoproducto = await getComboTipoProducto();
    if (this.manejadorLlamadasApis(resptipoproducto)) {
      return;
    }
    var respunidadesmedida = await getListaUnidadesDeMedidaDisponibles()
    if (this.manejadorLlamadasApis(respunidadesmedida)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Ingredientes Activos
    var respingredientesactivos = await getListaIngredientesActivos();
    if (this.manejadorLlamadasApis(respingredientesactivos)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Plagas a controlar
    var resplistaplagas = await getComboPlagas();
    if (this.manejadorLlamadasApis(resplistaplagas)) {
      return;
    }
    var resptipoplagas = await getComboTipoPlagas();
    if (this.manejadorLlamadasApis(resptipoplagas)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Cultivos Autorizados
    var resplistacultivos = await getListaCultivosDisponibles();
    if (this.manejadorLlamadasApis(resplistacultivos)) {
      return;
    }
    var resptipocultivos = await getComboTipoCultivo();
    if (this.manejadorLlamadasApis(resptipocultivos)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Presentaciones
    var resppresentaciones = await getListaPresentacionDeProductosDisponibles();
    if (this.manejadorLlamadasApis(resppresentaciones)) {
      return;
    }
    // Inicia seción de APIs para selectores del modal Documentos
    var resptipodocumento = await getComboTipoDocumentos();
    if (this.manejadorLlamadasApis(resptipodocumento)) {
      return;
    }
    var respdocumento = await getListaCatalogoDocumentos();
    if (this.manejadorLlamadasApis(respdocumento)) {
      return;
    }


    await this.setState({
      estatus: estatus.data,
      /** Modal Información General */
      listafamiliaproducto: respfamiliaproducto.data,
      listatipoproducto: resptipoproducto.data,
      listaunidadesmedida: respunidadesmedida.data,
      /** Modal Ingredientes Activos */
      listaingredientesactivos: respingredientesactivos.data,
      /** Modal Plagas */
      listatipoplagas: resptipoplagas.data,
      listaplagas: resplistaplagas.data,
      /** Modal cultivos autorizados */
      listacultivo: resplistacultivos.data,
      listatipocultivo: resptipocultivos.data,
      /** Modal presentaciones */
      listapresentaciones: resppresentaciones.data,
      /** Modal documentos */
      listatiposdocumento: resptipodocumento.data,
      listadocumentos: respdocumento.data
    })

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false 
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  verModalDetallesProducto = (ban) => {

    if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar agroquímico', accionmodal: 2, modaldetallesproducto: true });
    }
  }

  verModalDetallesIngredientes = (ban) => {

    if (ban.opcion == 3) { //Nuevo
      this.setState({ titulomodal: 'Nuevo ingrediente activo', accionmodal: 1, itemseleccionado: { idcatalogoproducto: this.state.tablainformaciongeneral.elementos.idcatalogoproducto } })
    }
    else if (ban.opcion == 4) { //editar
      this.setState({ titulomodal: 'Editar ingrediente activo', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetallesingredientes: true });
  }

  verModalDetallesPlagas = (ban) => {

    if (ban.opcion == 5) { //Nuevo
      this.setState({ titulomodal: 'Nueva plaga', accionmodal: 1, itemseleccionado: { idcatalogoproducto: this.state.tablainformaciongeneral.elementos.idcatalogoproducto } })
    }
    else if (ban.opcion == 6) { //editar
      this.setState({ titulomodal: 'Editar plaga', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetallesplagas: true });
  }

  verModalDetallesCultivos = (ban) => {

    if (ban.opcion == 7) { //Nuevo
      this.setState({ titulomodal: 'Nuevo cultivo', accionmodal: 1, itemseleccionado: { idcatalogoproducto: this.state.tablainformaciongeneral.elementos.idcatalogoproducto } })
    }
    else if (ban.opcion == 8) { //editar
      this.setState({ titulomodal: 'Editar cultivo', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetallescultivos: true });
  }

  verModalDetallesPresentaciones = (ban) => {

    if (ban.opcion == 9) { //Nuevo
      this.setState({ titulomodal: 'Nueva presentación', accionmodal: 1, itemseleccionado: { idcatalogoproducto: this.state.tablainformaciongeneral.elementos.idcatalogoproducto } })
    }
    else if (ban.opcion == 10) { //editar
      this.setState({ titulomodal: 'Editar presentación', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetallespresentaciones: true });
  }

  verModalDetallesDocumentos = (ban) => {

    if (ban.opcion == 11) { //Nuevo
      this.setState({ titulomodal: 'Nuevo documento', accionmodal: 1, itemseleccionado: { idcatalogoproducto: this.state.tablainformaciongeneral.elementos.idcatalogoproducto } })
    }
    else if (ban.opcion == 12) { //editar
      this.setState({ titulomodal: 'Editar documento', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetallesdocumentos: true });
  }

  cerrarModalProducto = (accion) => {
    this.setState({ modaldetallesproducto: !this.state.modaldetallesproducto })
    if (accion == 2) {
      toast.success("Información general editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalIngredientes = (accion) => {
    this.setState({ modaldetallesingredientes: !this.state.modaldetallesingredientes })
    if (accion == 1) {
      toast.success("Ingrediente agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Ingrediente editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalPlagas = (accion) => {
    this.setState({ modaldetallesplagas: !this.state.modaldetallesplagas })
    if (accion == 1) {
      toast.success("Plaga agregada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Plaga editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalCultivos = (accion) => {
    this.setState({ modaldetallescultivos: !this.state.modaldetallescultivos })
    if (accion == 1) {
      toast.success("Cultivo agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Cultivo editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalPresentaciones = (accion) => {
    this.setState({ modaldetallespresentaciones: !this.state.modaldetallespresentaciones })
    if (accion == 1) {
      toast.success("Presentación agregada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Presentación editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalDocumentos = (accion) => {
    this.setState({ modaldetallesdocumentos: !this.state.modaldetallesdocumentos })
    if (accion == 1) {
      toast.success("Documento agregado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Documento editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  verModalEliminarItem = async (ban) => {
    this.setState({
      modalautorizaciontitulo: `Eliminar ${tablaeliminar[ban.eliminar]}`,
      modalautorizacion: !this.state.modalautorizacion,
      itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)),
      accionmodal: ban.eliminar
    })
  }

  cerrarModalAtutorizacion = (accion) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (accion == 1) {
      toast.success("Ingrediente eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Plaga eliminada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 3) {
      toast.success("Cultivo eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 4) {
      toast.success("Presentación eliminada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 5) {
      toast.success("Documento eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalautorizacion == true) ? (
          <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
            cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodal} />
        ) : null}

        {(this.state.modaldetallesproducto == true) ? (
          <ModalFormularioProducto titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalProducto} estatus={this.state.estatus}
            show={this.state.modaldetallesproducto} accion={this.state.accionmodal} informacionproducto={this.state.tablainformaciongeneral.elementos}
            listafamiliaproducto={JSON.parse(JSON.stringify(this.state.listafamiliaproducto))} listatipoproducto={this.state.listatipoproducto}
            listaunidadesmedida={this.state.listaunidadesmedida} setloadingspinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modaldetallesingredientes == true) ? (
          <ModalFormularioIngredienteActivo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalIngredientes} estatus={this.state.estatus}
            show={this.state.modaldetallesingredientes} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listaingredientesactivos={this.state.listaingredientesactivos} setloadingspinner={this.setLoadingSpinner} />
        ) : null}


        {(this.state.modaldetallesplagas == true) ? (
          <ModalFormularioPlagasControlar titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalPlagas} estatus={this.state.estatus}
            show={this.state.modaldetallesplagas} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listatipoplagas={this.state.listatipoplagas} listaplagas={this.state.listaplagas} setloadingspinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modaldetallescultivos == true) ? (
          <ModalFormularioCultivosAutorizados titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalCultivos} estatus={this.state.estatus}
            show={this.state.modaldetallescultivos} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listacultivo={this.state.listacultivo} listatipocultivo={this.state.listatipocultivo} setloadingspinner={this.setLoadingSpinner} />
        ) : null}


        {(this.state.modaldetallespresentaciones == true) ? (
          <ModalFormularioPresentaciones titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalPresentaciones} estatus={this.state.estatus}
            show={this.state.modaldetallespresentaciones} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listapresentaciones={this.state.listapresentaciones} unidadmedida={this.state.unidadmedida} setloadingspinner={this.setLoadingSpinner} /*arraytipos={JSON.parse(JSON.stringify(this.state.listapresentaciones))}*/ />
        ) : null}

        {(this.state.modaldetallesdocumentos == true) ? (
          <ModalFormularioDocumentos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalDocumentos} estatus={this.state.estatus}
            show={this.state.modaldetallesdocumentos} accion={this.state.accionmodal} item={this.state.itemseleccionado} setloadingspinner={this.setLoadingSpinner}
            listatiposdocumento={JSON.parse(JSON.stringify(this.state.listatiposdocumento))} listadocumentos={JSON.parse(JSON.stringify(this.state.listadocumentos))} />
        ) : null}


        <Navbar />

        {/* ////////////////////   Inicio Sección Nombre del proveedor   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.tablainformaciongeneral.elementos.nombrecomercial}</label>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Nombre del proveedor   ////////////////////////////////////// */}

        <br />

        {/* ////////////////////   Tabla Información General   ////////////////////////////////////// */}

        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo" style={{ minWidth: "1240px" }}>
            <div style={{ marginBottom: "15px" }}>
              <div style={{ marginBottom: "2px", paddingLeft: "8px" }}>
                <label className="lblTituloHoja letraTitulos" style={{ color: this.state.colorencabezadostabla }}>{this.state.tablainformaciongeneral.titulo}</label>
                <BiEdit className="icoEditarTablaModal" onClick={this.verModalDetallesProducto.bind(this, { opcion: 2, contenido: this.state.tablainformaciongeneral.elementos })} />
              </div>
              <div className="containerSubTabla tablaUnica" style={{ padding: "4px", border: "2px solid #bebdbc", minWidth: "1240px" }}>
                <TablaContenidoUnico tabla={this.state.tablainformaciongeneral} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"informaciongeneral1"} />
                <TablaContenidoUnico tabla={this.state.tablainformaciongeneral} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"informaciongeneral2"} />
              </div>
            </div>
          </div>
        </div>

        <br />

        {/* ////////////////////   Tabla Ingredientes activos ////////////////////////////////////// */}
        <TablaListado vermodaldetalles={this.verModalDetallesIngredientes} vermodaleliminaritem={this.verModalEliminarItem} opcion={[3, 4]} tabla={this.state.tablaingredientesactivos}
          backgroundcolor={this.state.fondotitulosencabezados} editar={"editar"} botonesestilotrash={["eliminar"]} />

        <br />

        {/* ////////////////////   Tabla Plagas a controlar  ////////////////////////////////////// */}
        <TablaListado vermodaldetalles={this.verModalDetallesPlagas} vermodaleliminaritem={this.verModalEliminarItem} opcion={[5, 6]} tabla={this.state.tablaplagasacontrolar}
          backgroundcolor={"#FFF"} editar={"editar"} botonesestilotrash={["eliminar"]} />

        <br />

        {/* ////////////////////   Tabla Cultivos autorizados  ////////////////////////////////////// */}
        <TablaListado vermodaldetalles={this.verModalDetallesCultivos} vermodaleliminaritem={this.verModalEliminarItem} opcion={[7, 8]} tabla={this.state.tablacultivosautorizados}
          backgroundcolor={this.state.fondotitulosencabezados} editar={"editar"} botonesestilotrash={["eliminar"]} /*botonesestilotrash={["descargar", "ver", "eliminar"]}*/ />

        <br />

        {/* ////////////////////   Tabla Presentaciones  ////////////////////////////////////// */}
        <TablaListado vermodaldetalles={this.verModalDetallesPresentaciones} vermodaleliminaritem={this.verModalEliminarItem} opcion={[9, 10]} tabla={this.state.tablapresentaciones}
          backgroundcolor={"#FFF"} editar={"editar"} botonesestilotrash={["eliminar"]} unidadmedida={this.state.unidadmedida} />

        <br />

        {/* ////////////////////   Tabla Documentos  ////////////////////////////////////// */}
        <TablaListado vermodaldetalles={this.verModalDetallesDocumentos} vermodaleliminaritem={this.verModalEliminarItem} opcion={[11, 12]} tabla={this.state.tabladocumentos}
          backgroundcolor={this.state.fondotitulosencabezados} editar={"editar"} botonesestilotrash={["descargar", "ver", "eliminar"]} />

        <br />
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoagroquimicos"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
