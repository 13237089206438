import React from 'react'
import './CatalogoNuevaRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { estiloBtnAgregar,coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla,estilosModal } from '../../Services/PaletaDeColores';
import ModalFormularioRanchosDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioRanchosDisponibles';

import { Redirect } from 'react-router-dom';
import Rancho from './Rancho';
import 'react-toastify/dist/ReactToastify.css';


export default class Zona extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,

      zona: this.props.itemzona,
    //  listaranchosagregados:  this.props.itemzona.ranchos,

      colapsartabla:true,
      eliminartabla:false,

      listaranchosdisponibles:[],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();

    
    var ranchosdisponibles = this.props.listaranchos.filter((item) => item.idcatalogo_zona == this.props.itemzona.idzona );
    setTimeout(() => {
       this.setState({ 
        listaranchosdisponibles:  ranchosdisponibles
      })
    }, 200);
   
    window.addEventListener("resize", this.dimensiones);
    
  }

  
  
  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Ranchos disponibles', accionmodal: 1 })
    }
    
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (listaranchos) => {
    var listaranchosagregadosaux = this.props.itemzona.ranchos;
    listaranchosagregadosaux = listaranchosagregadosaux.concat(listaranchos)
    this.setState({ 
      modaldetalles: !this.state.modaldetalles,
      colapsartabla: true,
   //   listaranchosagregados: listaranchosagregadosaux
    })

    var auxzona = this.state.zona;
    auxzona.ranchos = listaranchosagregadosaux;
    this.props.actualizarContenido(auxzona);
   
  }

  actualizarContenido = (rancho) => {
    var listaranchos = this.props.itemzona.ranchos;

    var index =  listaranchos.findIndex((obj => obj.idrancho == rancho.idrancho));
    if(index > -1){
      listaranchos[index] = rancho;
    }

    var auxzona = this.state.zona;
    auxzona.ranchos = listaranchos;

    this.props.actualizarContenido(auxzona);

  }

  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var ranchos =  this.props.itemzona.ranchos.filter(rancho => rancho.idrancho != elementoeliminado.idrancho);
    
    var auxzona = this.state.zona;
    auxzona.ranchos = ranchos;

    this.props.actualizarContenido(auxzona);
  }

  seleccionarElementoaEliminar = () => {
    this.props.eliminarElemento(this.state.zona)
  }

  actualizarListaNuevosProductos = (nuevoproducto) =>{
    this.props.actualizarListaNuevosProductos(nuevoproducto);
  }

  
  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  render() {
    return (

      <>
       {(this.state.modaldetalles == true) ? (
          <ModalFormularioRanchosDisponibles titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} 
            show={this.state.modaldetalles} accion={this.state.accionmodal} zona={this.props.itemzona} listaranchosdisponibles={this.state.listaranchosdisponibles}/>
        ) : null}
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator" >
              <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                    <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                            <p className='sinMargen'><b>Zona:</b>{this.props.itemzona.zona}</p>
                            </div>
                          </div>
                      </TableCell>
                  
                      <TableCell align="right" className='displayFlex nonePaddingTopBottom' >
                        <div className='row flexNoWrap'>
                          {
                            <div className='col-auto minPaddingTopBottom'>
                            <button className='btnNuevo letraNormal'
                              style={{ color: this.state.colorbtnagregar, backgroundColor: this.state.colorfondobtnragregar, border: this.state.borderbtnactualizar}}
                              onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Agregar</button>
                            </div>
                          }
                
                          <div className="col-auto modalDetallesCheck minPaddingTopBottom" >
                              <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar()}
                              ><FaTrashAlt className="icoBotones" /></button>
                          </div>
                          
                        </div>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemzona.ranchos.length > 0 ?
                              this.props.itemzona.ranchos.map((item, index) => (
                                <Rancho key={"rancho_" + item.idrancho} accion={this.state.accion} itemrancho={item} 
                                actualizarContenido={this.actualizarContenido}
                                eliminarElemento={this.eliminarElemento}
                                actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                                listalotes={this.props.listalotes}
                                listatablas={this.props.listatablas}
                                listaproductos={this.props.listaproductos}
                                listafamiliaproductos={this.props.listafamiliaproductos}
                                listatipoproductos={this.props.listatipoproductos}
                                listaunidadesmedida={this.props.listaunidadesmedida}
                                listanuevosproductos={this.props.listanuevosproductos}
                                />
                              ))
                              : <div></div>
                            }
                            
                            
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


      
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

