import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoRanchosCultivo.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { TfiMenuAlt } from "react-icons/tfi"
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { ImDownload3 } from 'react-icons/im'
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion,
  estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getCatalogoRanchosCultivo, getListaZonas, getComboAreasDeTrabajo, getListaPuestosEncargados } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ModalFormularioRanchosCultivo from '../../Complements/Modales/ModalFormularioRanchosCultivo/ModalFormularioRanchosCultivo';
import ModalEncargadosRancho from '../../Complements/Modales/ModalFormularioRanchosCultivo/ModalEncargadosRancho'
import { Redirect } from 'react-router-dom';
import { formatoNumero2 } from '../../Services/Validaciones';

const todoslosfiltrosdisponibles = -1

export default class CatalogoRanchosCultivo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idzonaseleccionada: todoslosfiltrosdisponibles,

      arrayzonas: [],

      tabla: {
        encabezados: [
          "Nombre rancho",
          "Zona",
          "Mapa",

          "Encargados",
          "Descripción",
          "Área total (ha)",

          "Área efectiva (ha)",
          "Área no efectiva (ha)",
          "Plantas totales (ud)",
          
          "Desarrollo (ud)",
          "Productivas (ud)",
          "Fallas (ud)",

          "Estatus",
          ""],
        dimensiones: [
          "9.6%",
          "8.4%",
          "5%",
          "12.8%",
          "12.8%",
          "8.6%",
          "7.0%",
          "11.2%",       
          "11.2%",

          "8.4%",
          "9.2%",
          "6.8%",
          "5.0%",
          "10.4%"
        ],
        mindimensiones: [
          "150px",
          "120px",
          "80px",

          "140px",
          "140px",
          "140px",
          
          "170px",
          "200px",
          "190px",
          
          "150px",
          "155px",
          "115px",

          "56px",
          "140px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      // Modal enbcargados
      modalencargadosrancho: false,
      listaareas: [],
      listapuestos: [],

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (this.state.loadingspinners == false) {
      this.setState({ loadingspinners: true })
    }
    this.setState({ txtbuscador: '' })

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var respcc = await getCatalogoRanchosCultivo();
    if (this.manejadorLlamadasApis(respcc)) {
      return;
    }
    var tabla = this.state.tabla;
    tabla.elementos = JSON.parse(JSON.stringify(respcc.data));

    var resp = await getListaZonas();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }
    var arraytemp = JSON.parse(JSON.stringify(resp.data));
    this.setState({ estatus: estatus.data, tabla: tabla, arrayzonas: arraytemp })

    // Se obtienen las listas para el modal "Encargado para el rancho"
    var resplistaareas = await getComboAreasDeTrabajo()
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }
    var listaareas = resplistaareas.data

    var resplistapuestos = await getListaPuestosEncargados()
    if (this.manejadorLlamadasApis(resplistapuestos)) {
      return;
    }
    var listapuestos = resplistapuestos.data
    
    this.setState({ listaareas: listaareas, listapuestos: listapuestos })
    

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ idzonaseleccionada: todoslosfiltrosdisponibles })
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarCombo = async (idzona) => {

    await this.setState({ idzonaseleccionada: idzona })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idzona, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.idzonaseleccionada, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }

  }

  filtrarBusqueda = (idzona, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idzona != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idcatalogo_zona == idzona)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter(
      (item) => item.rancho.toLocaleLowerCase().includes(textobusqueda))

  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo rancho', accionmodal: 1, itemseleccionado: {} })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar rancho', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Rancho creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Rancho editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }

  }

  verModalEncargadosRancho = ({ contenido }) => {
    this.setState({ titulomodal: 'Encargados para el rancho ' + contenido.rancho, accionmodal: 1, itemseleccionado: JSON.parse(JSON.stringify(contenido)) })
    this.setState({ modalencargadosrancho: true });
  }

  cerrarModalEncargadosRancho = (opciones) => {
    this.setState({ modalencargadosrancho: !this.state.modalencargadosrancho })
    if (opciones.accion == 1) {
      this.actualizarTabla()
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioRanchosCultivo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus} show={this.state.modaldetalles}
            accion={this.state.accionmodal} item={this.state.itemseleccionado} arrayzonas={JSON.parse(JSON.stringify(this.state.arrayzonas))} setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        {this.state.modalencargadosrancho ? (
          <ModalEncargadosRancho  titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalEncargadosRancho}
            itemseleccionado={JSON.parse(JSON.stringify(this.state.itemseleccionado))}
            listaareas={this.state.listaareas} listapuestos={this.state.listapuestos}
            show={this.state.modalencargadosrancho} accion={this.state.accionmodal}

            todoslosproductosalmacen={true/*this.state.todoslosproductosalmacen*/} listaproductosalmacen={this.state.listaproductosalmacen}
            item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
            />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Ranchos  </label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event.target.value)}>
                      <option selected={this.state.idzonaseleccionada == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todas</option>
                      {this.state.arrayzonas.map((i, index) => (
                        <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.idzonaseleccionada} >{i.zona} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar rancho"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximoConScroll containerTabla">
          <div className="container-fluid anchoMaximoConScroll">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: [2, 3].includes(index) ? 'center' : '' }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.trim().length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], textAlign: 'center' }}>
                          {item.imagenmapa && <div style={{ display: 'inline-flex' }}>
                            <a href={item.imagenmapa} download>
                              <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>
                          </div>}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], textAlign: 'center' }}>
                          {
                            item.encargados.map((encargado, index)=>(
                              <label>{encargado.nombre}{index < item.encargados.length-1?(","):null}</label>  
                            ))
                          
                          
                          /*item.encargados && <>
                            {item.encargados.length}
                            <button className='btnMenuCRC' onClick={this.verModalEncargadosRancho.bind(this, { contenido: item })}>
                              <TfiMenuAlt className="icoBotonMenuCRC"/>
                            </button>
                    </>*/}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {formatoNumero2(item.areatotal)}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[8], width: this.state.tabla.dimensiones[8] }}>
                          {(item.plantastotales)}
                        </td>                       
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[9], width: this.state.tabla.dimensiones[9] }}>
                          {(item.desarrollo)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[10], width: this.state.tabla.dimensiones[10] }}>
                          {(item.productivas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }}>
                          {(item.fallas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[12], width: this.state.tabla.dimensiones[12] }}>
                          {(item.estatus)}
                        </td>
                        <td className="letraNormal lblSubtabla"  style={{ minWidth: this.state.tabla.mindimensiones[13], width: this.state.tabla.dimensiones[13] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], textAlign: 'center' }}>
                          {item.imagenmapa && <div style={{ display: 'inline-flex' }}>
                            <a href={item.imagenmapa} download>
                              <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>
                          </div>}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], textAlign: 'center' }}>
                          {
                            item.encargados.map((encargado, index)=>(
                              <label>{encargado.nombre}{index < item.encargados.length-1?(","):null}</label>  
                            ))
                          
                          
                          /*item.encargados && <>
                            {item.encargados.length}
                            <button className='btnMenuCRC' onClick={this.verModalEncargadosRancho.bind(this, { contenido: item })}>
                              <TfiMenuAlt className="icoBotonMenuCRC"/>
                            </button>
                    </>*/}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          {item.descripcion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }}>
                          {formatoNumero2(item.areatotal)}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[8], width: this.state.tabla.dimensiones[8] }}>
                          {(item.plantastotales)}
                        </td>                       
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[9], width: this.state.tabla.dimensiones[9] }}>
                          {(item.desarrollo)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[10], width: this.state.tabla.dimensiones[10] }}>
                          {(item.productivas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }}>
                          {(item.fallas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[12], width: this.state.tabla.dimensiones[12] }}>
                          {(item.estatus)}
                        </td>
                        <td className="letraNormal lblSubtabla"  style={{ minWidth: this.state.tabla.mindimensiones[13], width: this.state.tabla.dimensiones[13] }}>
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idzonaseleccionada == todoslosfiltrosdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
