import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioDetalleOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumeros, validarNumeros } from '../../../Services/Validaciones';
import { postDetalleBitacora } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioIngresoBitacoraCosecha extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false, 
            accion: props.accion,
            titulo: props.titulomodal,

            idbitacoracosecha: this.props.idbitacoracosecha != undefined ? this.props.idbitacoracosecha : 0,
            rancho: this.props.infodetalles.ordencosecha.idcatalogo_rancho,
            lote: (this.props.accion==2)?(this.props.item.idcatalogo_ranchos_lotes_cultivo):0,
            tabla: (this.props.accion==2)?(this.props.item.idcatalogo_lotecultivo_tabla):0,
            cuadrilla: (this.props.accion==2)?(this.props.item.idcatalogo_cuadrillas):0,
            txtrejasenviadas: (this.props.accion==2)?(this.props.item.rejas !="N/A"? this.props.item.rejas:''):'',
            txtrejasenpiso: (this.props.accion==2)?(this.props.item.rejaspiso !="N/A" ? this.props.item.rejaspiso:''):'',
            txtfolio: (this.props.accion==2)?(props.item.folio):'',
            foliotraslado: (this.props.accion==2)?(props.item.idcatalogo_bitacora_traslados):0,

            listalotes: this.props.listalotes != undefined ? this.props.listalotes : [],
            infolote: (this.props.accion==2)?(props.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo==this.props.item.idcatalogo_ranchos_lotes_cultivo)):undefined,
            listatablas: this.props.listatablas != undefined ? this.props.listatablas : [],
            listacuadrillas: this.props.listacuadrillas != undefined ? this.props.listacuadrillas : [],
            listatraslados: this.props.listatraslados != undefined ? this.props.listatraslados : [],

            vermsgerrorlote: false,
            vermsgerrortabla: false,
            vermsgerrorcuadrilla: false,
            vermsgerrortxtrejasenviadas: false,
            vermsgerrortxtrejasenpiso: false,
            vermsgerrortxtpersonas: false,
            vermsgerrortxtfolio: false,
            vermsgerrorfoliotraslado: false,

            msgerrorlote: "",
            msgerrortabla: "",
            msgerrorcuadrilla: "",
            msgerrortxtrejasenviadas: "",
            msgerrortxtrejasenpiso: "",
            msgerrortxtpersonas: "",
            msgerrortxtfolio: "",
            msgerrorfoliotraslado: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colorbordertipo: estiloComboBox().colorbordercombo
        }
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if (prop == "lote") {
            let infolote = this.state.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo == event.target.value)
            this.setState({ infolote: infolote, txtrejasenviadas:'', txtrejasenpiso:'' })
        }
    };

    guardarCambios = async () => {

        let validacionescorrectas = true;

        this.setState({ vermsgerrorlote: false })
        this.setState({ vermsgerrortabla: false })
        this.setState({ vermsgerrorcuadrilla: false })
        this.setState({ vermsgerrortxtrejasenviadas: false })
        this.setState({ vermsgerrortxtrejasenpiso: false })
        this.setState({ vermsgerrortxtpersonas: false })
        this.setState({ vermsgerrortxtfolio: false })
        this.setState({vermsgerrorfoliotraslado:false})

        if (this.state.lote < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorlote: "Seleccione un lote" })
            this.setState({ vermsgerrorlote: true })
        }

        if (this.state.tabla < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortabla: "Seleccione una tabla" })
            this.setState({ vermsgerrortabla: true })
        }

        if (this.state.cuadrilla < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorcuadrilla: "Seleccione una cuadrilla" })
            this.setState({ vermsgerrorcuadrilla: true })
        }

        let longitudvalida = { longitudminima: 1, longitudmaxima: 15 };

        if (this.state.infolote != undefined && this.state.infolote.idtipo_corte == 1) {

            let vrejasenviadas = validarNumeros(this.state.txtrejasenviadas, longitudvalida);
            if (vrejasenviadas.codigo == "invalid") {
                this.setState({ msgerrortxtrejasenviadas: vrejasenviadas.mensaje })
                this.setState({ vermsgerrortxtrejasenviadas: true })
                validacionescorrectas = false;
            }

            if (this.state.txtrejasenpiso.trim().length > 0) {
                let vrejasenpiso = validarNumeros(this.state.txtrejasenpiso, longitudvalida);
                if (vrejasenpiso.codigo == "invalid") {
                    this.setState({ msgerrortxtrejasenpiso: vrejasenpiso.mensaje })
                    this.setState({ vermsgerrortxtrejasenpiso: true })
                    validacionescorrectas = false;
                }
            }

        }

        longitudvalida = { longitudminima: 1, longitudmaxima: 20 };
        let vfolio = validarLetrasNumeros(this.state.txtfolio, longitudvalida);
        if (vfolio.codigo == "invalid") {
            this.setState({ msgerrortxtfolio: vfolio.mensaje })
            this.setState({ vermsgerrortxtfolio: true })
            validacionescorrectas = false;
        }

        if (this.state.foliotraslado < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorfoliotraslado: "Seleccione un folio" })
            this.setState({ vermsgerrorfoliotraslado: true })
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })

            let json = {
                "idcatalogo_bitacora_cosecha_detalles": this.props.accion==2 ? this.props.item.idcatalogo_bitacora_cosecha_detalles : 0,
                "idcatalogo_bitacora_cosecha": this.state.idbitacoracosecha,
                "idcatalogo_lotecultivo_tabla": this.state.tabla,
                "idcatalogo_bitacora_traslados_detalles": this.state.foliotraslado,
                "idcatalogo_cuadrillas": this.state.cuadrilla,
                "folio": this.state.txtfolio,
                "rejas": this.state.txtrejasenviadas == "" ? (0) : parseInt(this.state.txtrejasenviadas),
                "rejaspiso": this.state.txtrejasenpiso == "" ? (0) : parseInt(this.state.txtrejasenpiso),
                "eliminar": 0
            }

            var resp = await postDetalleBitacora(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else{
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio, loadingspinners:false })
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de spinner ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Sección de lote  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("lote")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listalotes == undefined || this.state.listalotes.length == 0 ?
                                                    null
                                                :
                                                    this.state.listalotes.map((i) => (
                                                        this.state.rancho == i.idcatalogo_rancho ?
                                                            <option
                                                                value={i.idcatalogo_ranchos_lotes_cultivo}
                                                                key={"tipo_" + i.idcatalogo_ranchos_lotes_cultivo}
                                                                selected={i.idcatalogo_ranchos_lotes_cultivo==this.state.lote ? true : false}>
                                                                {i.lote}
                                                            </option>
                                                        :null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorlote ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorlote}</p>
                                :null}

                                {/* ////////////////////   Sección de tabla  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tabla  </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tabla")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listatablas == undefined || this.state.listatablas.length == 0 ?
                                                    null
                                                :
                                                    this.state.listatablas.map((i) => (
                                                        this.state.lote == i.idcatalogo_ranchos_lotes_cultivo ?
                                                            <option
                                                                value={i.idcatalogo_lotecultivo_tabla}
                                                                key={"tipo_" + i.idcatalogo_lotecultivo_tabla}
                                                                selected={i.idcatalogo_lotecultivo_tabla==this.state.tabla ? true : false}>
                                                                {i.tabla}
                                                            </option>
                                                        :null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortabla ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortabla}</p>
                                :null}

                                {/* ////////////////////   Sección de cuadrilla  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cuadrilla </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("cuadrilla")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listacuadrillas == undefined || this.state.listacuadrillas.length == 0 ?
                                                    null
                                                :
                                                    this.state.listacuadrillas.map((i) => (
                                                        this.state.infolote != undefined && i.cultivos.find(x => x.idtipo_corte == this.state.infolote.idtipo_corte && x.idtipo_cultivo == this.state.infolote.idtipo_cultivo) ?
                                                            <option
                                                                value={i.idcatalogo_cuadrillas}
                                                                key={"tipo_" + i.idcatalogo_cuadrillas}
                                                                selected={i.idcatalogo_cuadrillas==this.state.cuadrilla ? true : false}>
                                                                {i.nombre}
                                                            </option>
                                                        :null
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorcuadrilla ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcuadrilla}</p>
                                :null}

                                {/* //////////////////// Si el tipo de corte del lote es reja se muestran inputs para rejas  ////////////////////////////////////// */}
                                {this.state.infolote != undefined && this.state.infolote.idtipo_corte == 1 ?
                                    <>

                                        {/* //////////////////// Sección de rejas enviadas  ////////////////////////////////////// */}
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rejas enviadas</p>
                                        {this.state.accion == 2 || this.state.accion == 1 ?
                                            <input
                                                className='lblTextoModal letraNormal'
                                                style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                                type={'number'}
                                                value={this.state.txtrejasenviadas}
                                                onChange={this.escribir("txtrejasenviadas")}>
                                            </input>
                                        :
                                            <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                                {this.state.txtrejasenviadas}
                                            </label>
                                        }

                                        {this.state.vermsgerrortxtrejasenviadas ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrejasenviadas}</p>
                                        :null}

                                        {/* //////////////////// Sección de rejas en piso  ////////////////////////////////////// */}
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rejas en piso</p>
                                        {this.state.accion == 2 || this.state.accion == 1 ?
                                            <input
                                                className='lblTextoModal letraNormal'
                                                style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                                type={'number'}
                                                value={this.state.txtrejasenpiso}
                                                onChange={this.escribir("txtrejasenpiso")}>
                                            </input>
                                        :
                                            <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                                {this.state.txtrejasenpiso}
                                            </label>
                                        }

                                        {this.state.vermsgerrortxtrejasenpiso ?
                                            <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrejasenpiso}</p>
                                        :null}

                                    </>
                                :null}

                                {/* //////////////////// Sección de folio  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Folio</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        value={this.state.txtfolio}
                                        onChange={this.escribir("txtfolio")}>
                                    </input>
                                :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtfolio}
                                    </label>
                                }

                                {this.state.vermsgerrortxtfolio ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfolio}</p>
                                :null}

                                {/* ////////////////////   Sección de folio traslado  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Folio traslado</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("foliotraslado")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listatraslados == undefined || this.state.listatraslados.length == 0 ?
                                                    null
                                                :
                                                    this.state.listatraslados.map((i) => (
                                                        <option
                                                            value={i.idcatalogo_bitacora_traslados_detalles}
                                                            key={"tipo_" + i.idcatalogo_bitacora_traslados_detalles}
                                                            selected={i.idcatalogo_bitacora_traslados_detalles==this.state.foliotraslado ? true : false}>
                                                            {i.folio}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorfoliotraslado ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfoliotraslado}</p>
                                :null}

                            </div>
                        </div>

                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

