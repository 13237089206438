import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { FaTrashAlt } from 'react-icons/fa'
import { BiEdit } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estiloBtnEditar, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { getComboFamiliaProducto, getComboTipoProducto, getAlmacenesEquiposInformación, getCatalogoAlmacenesEquiposyHerramientas } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1
const opcionsinespecificar = -2

export default class ModalNotasFormulario extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      idnota: props.item != 1 ? (props.item.idnota) : ("-" + props.numnotas),
      txttitulo: props.item != 1 ? (props.item.titulo) : (""),
      vermsgerrortxttitulo: false,
      msgerrortxttitulo: "",

      txtdescripcion: props.item != 1 ? (props.item.descripcion) : (""),
      vermsgerrordescripcion: false,
      msgerrortxtdescripcion: "",

      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,


    }

  }


  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {

    this.setState({
      vermsgerrortxttitulo: false,
      msgerrortxttitulo: "",
      vermsgerrordescripcion: false,
      msgerrortxtdescripcion: "",
    })

    let validacionescorrectas = true

    let longitudvalida = { longitudminima: 2, longitudmaxima: 20 };
    let resptitulo = validarLetrasNumerosPuntoComa(this.state.txttitulo.trim(), longitudvalida)
    if (resptitulo.codigo == "invalid") {
      this.setState({ msgerrortxttitulo: resptitulo.mensaje })
      this.setState({ vermsgerrortxttitulo: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 2, longitudmaxima: 300 };
    let respdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
    if (respdescripcion.codigo == "invalid") {
      this.setState({ msgerrortxtdescripcion: respdescripcion.mensaje })
      this.setState({ vermsgerrordescripcion: true })
      validacionescorrectas = false;
    }

    if (validacionescorrectas == true) {
      // Mandar datos del item de producto seleccionado
      this.showModal({ idnota: this.state.idnota, titulo: this.state.txttitulo, descripcion: this.state.txtdescripcion })//productoseleccionado
    }


  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="lg"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Editar nota</label>
            <br />
            <br />
            <div className="divCuerpoModal">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <div className="row">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                          <label style={{ width: "70px" }}>Título:</label>
                          <input style={{ width: "calc(100% - 70px)", border: "0px", borderBottom: "solid 1px black" }} onChange={this.escribir("txttitulo")} value={this.state.txttitulo}>
                          </input>
                        </div>

                        {this.state.vermsgerrortxttitulo ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrortxttitulo}</p> : null}

                        <div className="col-12">
                          <label style={{ width: "100px" }}>Descripción:</label>
                          <textarea style={{ width: "calc(100% - 100px)" }} onChange={this.escribir("txtdescripcion")} value={this.state.txtdescripcion}>
                          </textarea>
                        </div>
                        {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror, marginBottom: '4px' }}>{this.state.msgerrortxtdescripcion}</p> : null}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}  >Agregar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

