import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import "./ModalPropuestaAnual.css";
import { coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalTablasDisponibles extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            titulo: "Agregar tablas",
            txtbuscador:"",
            estilosmodal: estilosModal(),
            filtrolote: -1,
            listalotes: [],
            listatablas: [],//this.props.listacatalogotablas,
            listatablasfiltradas: [],//this.props.listacatalogotablas,
            listatablasseleccionadas: [],
            listatablasagregadas: this.props.listatablasagregadas,
            actualizarfiltro:true,
            tabla: {
                encabezados: [
                    "Tabla",
                    "Lote",
                ],
                dimensiones: [
                    "15%",
                    "15%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                ],
                elementos: [],
            },
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }
    async componentDidMount() {

        if(this.state.actualizarfiltro){
            this.actualizarDatosFiltro();
            await this.setState({ 
                actualizarfiltro: false
            })
        }

    }
    actualizarDatosFiltro = async () => {
        var listalotesdisponibles = this.props.listalotes.filter((item) => item.idcatalogo_rancho == this.props.ranchoseleccionado);
        var listatablasdisponibles = [];
        listalotesdisponibles.forEach(lote => {
            var tablas = this.props.listacatalogotablas.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == lote.idcatalogo_ranchos_lotes_cultivo);
           
            listatablasdisponibles = listatablasdisponibles.concat(tablas);
        });
       
        await this.setState({ 
            listalotes: listalotesdisponibles,
            listatablas:listatablasdisponibles,
            listatablasfiltradas:listatablasdisponibles
        })
    }
    filtroLote = async (itemfiltrado) => {
        var data = JSON.parse(JSON.stringify(this.state.listatablas))
      
        await this.setState({ 
            filtrolote: itemfiltrado
        })
      
        var filtrofinal = [];
    
        var listafiltrolote = [];
        
        listafiltrolote = data;
        if(itemfiltrado != -1){
      
            listafiltrolote = data.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == itemfiltrado)
        }
    
        filtrofinal = listafiltrolote;
    
        if(this.state.txtbuscador.length>2){
            filtrofinal = listafiltrolote.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }
        
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listatablasfiltradas: filtrofinal
        })
      
      }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data = JSON.parse(JSON.stringify(this.state.listatablas))

        var listafiltrolote = data;
        

        if(this.state.filtrolote != -1){
            listafiltrolote = data.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.state.filtrolote)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrolote.filter((item) => item.tabla.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
           await this.setState({ listatablasfiltradas: filtros });
        }else if(e.target.value.trim().length < 1){
            this.filtroLote(this.state.filtrolote)
        }

    }

    borrarBusqueda = async () => {
       await this.setState({
            txtbuscador: '',
            filtrolote: -1
        });
        this.filtroLote(this.state.filtrolote)
    }

    

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    continuarProceso = async () => {
        if(this.state.listatablasseleccionadas.length > 0){
            var tablarepetida=false;
            var mesesagregados = [];
            var listameses = [];
            this.state.listatablasseleccionadas.forEach(element => {
                var itemfound = this.state.listatablasagregadas.find(item => item.idtabla == element.idcatalogo_lotecultivo_tabla);
                if(itemfound != undefined){
                    tablarepetida = true; 
                }
            });

            var indextablaconmeses = this.state.listatablasagregadas.findIndex(tabla => tabla.meses.length > 0);
            if(indextablaconmeses > -1 ){
                mesesagregados = this.state.listatablasagregadas[indextablaconmeses].meses;

                mesesagregados.forEach(element => {
                    let mes = {
                        "idmestablaactividad_presupuestorancho":0,
                        "idmes": element.idmes,
                        "mes":element.mes,
                        "presupuesto":0
                    }
                    listameses.push(mes)
                });
               
            }

            if(!tablarepetida){
                var listatablasseleccionadasaux = []
                this.state.listatablasseleccionadas.forEach(element => {
                    var respaldoinfo = this.props.respaldoinfopropuesta;
                    var idtablaactividad_presupuestorancho = -1;
                    if(JSON.stringify(respaldoinfo) !== '{}'){
                        var indexact =  respaldoinfo.actividades.findIndex((obj => obj.idactividad == this.props.idactividad));
                        if(indexact > -1){
                          var actividad = respaldoinfo.actividades[indexact];
                          var indextabla =  actividad.tablas.findIndex((obj => obj.idtabla == element.idcatalogo_lotecultivo_tabla));
                          if(indextabla > -1){
                            idtablaactividad_presupuestorancho = actividad.tablas[indextabla].idtablaactividad_presupuestorancho;
                          }
                          
                        }
                    }
                    
                    var tabla= {
                        "idtablaactividad_presupuestorancho":  idtablaactividad_presupuestorancho != -1 ? idtablaactividad_presupuestorancho : 0,
                        "idtabla": element.idcatalogo_lotecultivo_tabla,
                        "tabla": element.tabla,
                        "cultivo": element.cultivo,
                        "hectareas": element.areatotal,
                        "arboles": element.plantastotales,
                        "lote": element.lote,
                        "idlote": element.idcatalogo_ranchos_lotes_cultivo,
                        "meses":listameses.length > 0 ? listameses:[]
                     }
                    listatablasseleccionadasaux.push(tabla);
                });
                
                this.showModal( listatablasseleccionadasaux)
            }else{
                toast.info("Las tablas ya se encuentran agregadas", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            
        }else{
            toast.info("Favor de seleccionar al menos una tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
    }

    seleccionarItem = async (itemseleccionado) => {
        var listatablasseleccionadasaux = this.state.listatablasseleccionadas;
        var itemfound = listatablasseleccionadasaux.find(item => item.idcatalogo_lotecultivo_tabla == itemseleccionado.idcatalogo_lotecultivo_tabla);

        if(itemfound == undefined){
            listatablasseleccionadasaux.push(itemseleccionado)
        }else{
            var index = listatablasseleccionadasaux.indexOf(itemseleccionado)
            if(index > -1){
                listatablasseleccionadasaux.splice(index,1)
            }
        }
        await this.setState({
            listatablasseleccionadas: listatablasseleccionadasaux,
            confirmarenvio: true
        })
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal([])} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos lblSelect' style={{ color: this.state.colorlupa }}>Lote</label>
                                            <div className="selectBox selectOptionL">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtroLote(event.target.value)}>
                                                    {this.state.filtrolote == -1 ?
                                                        <option selected="true" value={-1}>Todos</option> : <option value={-1}>Todos</option>}
                                                    {this.state.listalotes.map((i, index) => (
                                                          i.idcatalogo_rancho == this.props.ranchoseleccionado ?
                                                        <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index}  >{i.lote} </option>
                                                        :null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.colorbordertipo }}
                                                placeholder="Buscar tabla"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='row '>
                                    <div className='col-12 containerSubTabla  '>
                                        <label className='letraTitulos'>Selecciona las tablas: </label>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="container-fluid tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tabla" + index} className={" letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                        
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listatablasfiltradas.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarItem(item)} 
                                                                className={this.props.listatablasagregadas.find(tabla => tabla.idtabla == item.idcatalogo_lotecultivo_tabla) != undefined ? "trFondoInactivoGray" :this.state.listatablasseleccionadas.find(selected => selected.idcatalogo_lotecultivo_tabla == item.idcatalogo_lotecultivo_tabla) == undefined ? ""
                                                               : "trFondoActivoGreen"}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tabla}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.lote}
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                       
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal([])}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.continuarProceso()}  disabled={!this.state.confirmarenvio}>Agregar</Button>
                                </div>
                            </>
               

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

