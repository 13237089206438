import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNominaVer.css';
import { FaCalendar } from "react-icons/fa";
import { Table, Button } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer } from '../../Services/PaletaDeColores';

import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaRanchosDisponibles, getListaNominas, guardardatosNomina, finalizanomina, Getinfocsv } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validarNumerosDecimales2 } from '../../Services/Validaciones';
import { BsFillCalendarFill } from 'react-icons/bs';
import { format } from 'date-fns';
import ModalAutorizacion from '../../Complements/Modales/ModalAutorizacion/ModalAutorizacion';
import Modalconfirmaasignar from '../CatalogoAsignarUsuarios/Modalconfirmaasignar';
import ExportarCSV from '../../Complements/exportarcsv/exportarcsv';
import ModalSalarios from '../../Complements/Modales/ModalSalarios/ModalSalarios';



export default class CatalogoNominaVer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            jsoncsv: [],
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            loadingspinners: true,
            confirmarenvio: false,
            verCSV: false,
            bandera_periodo: "activo",

            tablaMaster: [],
            usuariospen: {
                encabezados: [
                    "ID",
                    "Nombre",
                    "Puesto",
                    "Estatus",
                ],
                dimensiones: [
                    "4%",
                    "15%",
                    "10%",
                    "15%"
                ],
                mindimensiones: [
                    "30px",
                    "130px",
                    "100px",
                    "130px",
                ],
                elementos: []
            },
            tabla: {
                encabezados: [

                ],
                dimensiones: [


                ],
                mindimensiones: [



                ],
                elementos: [],
                totales: []
            },
            save_idcatalogo_nominas: "",
            estatus: [],
            listaelementosfiltrados: [],
            zonaseleccionado: "TodasLasZonas",
            ranchoseleccionado: "TodosLosRanchos",
            encargadoseleccionado: "TodosLosEncargados",
            listatipousuario: [],

            accionmodal: 1,
            titulomodal: '',
            modalnotas: false,
            arrayModal: [],
            titulomodal: "",
            bandera_modal: false,
            regresarnominas: false,
            modaldetalles: false,
            itemseleccionado: null,

            errordeacceso: false,
            total_salarial: 0,
            total_otras: 0,
            total_salario: 0,
            total_deduciones: 0,

            //paginacion
            activepage: 1,
            itemsperpage: 20,

            listapaginada: [],


            titulomodal: "",
            nombreusuaremodal: "",
            mensajeasignar: "",
            vermodaleliminar: false,

            modalautorizacion: false,
            modalautorizaciontitulo: "",

            fechacompraini: "",
            fechacomprafin: "",

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
            colorbtnverde: estiloBtnVerde().colorbtnverde,
            colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

            colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
            colorbtnrojo: estiloBtnRojo().colorbtnrojo,
            colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

            colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
            colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
            colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

        }
    }


    async componentDidMount(banderacambiaestatus) {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }

        if (localStorage.getItem('nominanueva') != null || localStorage.getItem('nominanueva') != undefined) {
            toast.success("Nómina creada exitósamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        var estatus = await getEstatus();
        if (this.manejadorLlamadasApis(estatus)) {
            return;
        }
        //datosnomina este esc
        if (localStorage.getItem('datosnomina') == null || localStorage.getItem('datosnomina') == undefined) {
            this.setState({ regresarnominas: true })
            return;
        }


        var datosSave = JSON.parse(localStorage.getItem("datosnomina"))

        this.setState({ save_idcatalogo_nominas: datosSave.idcatalogo_nominas })

        if (banderacambiaestatus == 1) {
            this.setState({ bandera_periodo: "finalizado" });
        }
        else {

            if (datosSave.idestatus == 5) {
                this.setState({ bandera_periodo: "activo" });
            }
            else {
                this.setState({ bandera_periodo: "finalizado" });
            }

        }
        var json = {
            "idcatalogo_nominas": datosSave.idcatalogo_nominas,
        }


        this.setState({ fechacompraini: datosSave.fechainicio });
        this.setState({ fechacomprafin: datosSave.fechafin });

        var resplista = await getListaNominas(json);
        if (this.manejadorLlamadasApis(resplista)) {
            return;
        }


        var usuariospenG = resplista.data.usuariospendientes
        var arraytempG = resplista.data.ranchos//[0].nomina//.nomina[0]

        let dias = 0;

        var arraytemp = [];

        var arraytitulos = [];
        var arraytitulos_dimenciones = [];
        var arraytitulos_min_dimenciones = [];
        for (let x = 0; x < arraytempG.length; x++) {

            arraytitulos = [];
            arraytitulos_dimenciones = [];
            arraytitulos_min_dimenciones = [];

            arraytitulos.push("Nombre");
            arraytitulos.push("Puesto");
            arraytitulos.push("Salario");
            arraytitulos.push("Tardeada");

            arraytitulos_dimenciones.push("30%");
            arraytitulos_dimenciones.push("148px");
            arraytitulos_dimenciones.push("10%");
            arraytitulos_dimenciones.push("10%");

            arraytitulos_min_dimenciones.push("280px");
            arraytitulos_min_dimenciones.push("180px");
            arraytitulos_min_dimenciones.push("100px");
            arraytitulos_min_dimenciones.push("110px");


            arraytemp = arraytempG[x].nomina
            for (let i = 0; i < arraytemp.length; i++) {
                arraytemp[i].vermsgerrorbono = false;
                arraytemp[i].vermsgerrordeducciones = false;
                arraytemp[i].vermsgerrorgastos = false;
                arraytemp[i].vermsgerrorviaticos = false;
                //suma = Number.parseFloat(arraytemp[i].nomina);
                dias = 0;
                for (let j = 0; j < arraytemp[i].dias.length; j++) {

                    if (i == 0) {
                        arraytitulos.push(arraytemp[i].dias[j].dia);
                        arraytitulos_dimenciones.push("30px");
                        arraytitulos_min_dimenciones.push("30px");
                    }
                    if (arraytemp[i].dias[j].turnomatutino == 1 && arraytemp[i].dias[j].turnovespertino == 1) {
                        dias++;

                    }
                    else if (arraytemp[i].dias[j].turnomatutino == 1) {
                        dias++;
                    }
                    else if (arraytemp[i].dias[j].turnovespertino == 1) {
                        dias++;
                    }

                }

                arraytemp[i].diastrabajados = dias;
            }
            //   arraytempG[x].nomina = arraytemp 


            arraytitulos.push("Dias trabajados");
            arraytitulos.push("Percepcion salarial");
            arraytitulos.push("Bonos");
            arraytitulos.push("Deducciones");

            arraytitulos.push("Gastos");
            arraytitulos.push("Viáticos");
            arraytitulos.push("Observaciones");
            arraytitulos.push("Salario a recibir");

            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");

            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");
            arraytitulos_dimenciones.push("13%");


            arraytitulos_min_dimenciones.push("140px");
            arraytitulos_min_dimenciones.push("140px");
            arraytitulos_min_dimenciones.push("140px");
            arraytitulos_min_dimenciones.push("140px");

            arraytitulos_min_dimenciones.push("140px");
            arraytitulos_min_dimenciones.push("140px");
            arraytitulos_min_dimenciones.push("150px");
            arraytitulos_min_dimenciones.push("200px");
        }

        var tabla = this.state.tabla;
        tabla.encabezados = arraytitulos;
        tabla.dimensiones = arraytitulos_dimenciones;
        tabla.mindimensiones = arraytitulos_min_dimenciones;
        //tabla.elementos = resplista.data.ranchos[0].nomina;

        var arrayt = this.state.usuariospen
        arrayt.elementos = usuariospenG


        await this.setState({
            txtbuscador: '',
            tabla: tabla,
            usuariospen: arrayt,  // ----
            estatus: estatus.data,
            confirmarenvio: false,
            tablaMaster: arraytempG

        })


        this.dimensiones();
        this.cambiarContenido(1);
        setTimeout(() => {
            // this.crearCSV();
            this.sumardatos()
            this.setState({ loadingspinners: false })
        }, 600);

        localStorage.removeItem("nominanueva");
        window.addEventListener("resize", this.dimensiones);


    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        return false
    }


    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({
            loadingspinners: true,
            txtbuscador: '',
            ranchoseleccionado: "TodosLosRanchos",
            zonaseleccionado: "TodasLasZonas",
            errordeacceso: false,
        })
        this.componentDidMount();
    }
    cambiarContenido = (pagenumber) => {

        this.setState({
            activepage: pagenumber,
            listapaginada: [],
            filtrolistapaginada: []
        });

        setTimeout(() => {
            this.setState({
                activepage: pagenumber,
                listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.usuariospen.elementos),
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.usuariospen.elementos),
            });
        }, 0);

    }
    cerrarModal = (accion) => {

        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
            this.setState({ txtbuscador: '' })
            this.componentDidMount();
            this.filtrarArea("TodasLasZonas", 0)
        }

    }

    filtro = (pagenumber, array) => {
        this.setState({
            activepage: pagenumber,
            filtrolistapaginada: []
        });

        setTimeout(() => {
            this.setState({
                listaelementosfiltrados: array,
                activepage: pagenumber,
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
            });
        }, 0);

    }

    activarSpinner = (accion) => {
        this.setState({ loadingspinners: accion })
    }


    sumardatos = () => {



        var array = this.state.tablaMaster;
        var datos = [];

        var total_salarial = 0;
        var total_otras = 0;
        var total_salario = 0;
        var deduciones = 0;

        for (let j = 0; j < array.length; j++) {

            datos = array[j].nomina


            total_salarial = 0;
            total_otras = 0;
            total_salario = 0;
            deduciones = 0;


            for (let i = 0; i < datos.length; i++) {
                datos[i].totalsalariosuma = (parseFloat(datos[i].nomina) + parseFloat(datos[i].bonos) + parseFloat(datos[i].gastos) + parseFloat(datos[i].viaticos)) - parseFloat(datos[i].deducciones);
                deduciones += (parseFloat(datos[i].deducciones));
                total_salarial += (parseFloat(datos[i].nomina));
                total_otras += (parseFloat(datos[i].bonos) + parseFloat(datos[i].gastos) + parseFloat(datos[i].viaticos));
            }

            total_salario = (total_salarial + total_otras) - deduciones;

            //array[j].nomina

            array[j].total_salarial = total_salarial
            array[j].total_otras = total_otras
            array[j].total_salario = total_salario
            array[j].total_deduciones = deduciones
            /*
                    this.setState({ total_salarial: total_salarial });
                    this.setState({ total_otras: total_otras });
                    this.setState({ total_salario: total_salario });
                    this.setState({ total_deduciones: deduciones });
            */
        }

        this.setState({ tablaMaster: array });



    }

    GuardarCambiosNomina = async (ban) => {
        var arraytempG = this.state.tablaMaster;
        var arraytemp = [];
        var json = [];

        for (let x = 0; x < arraytempG.length; x++) {
            arraytemp = arraytempG[x].nomina
            for (let i = 0; i < arraytemp.length; i++) {
                arraytemp[i].vermsgerrorbono = false;
                arraytemp[i].vermsgerrordeducciones = false;
                arraytemp[i].vermsgerrorgastos = false;
                arraytemp[i].vermsgerrorviaticos = false;
            }
        }

        let validacionescorrectas = true
        let longitudvalida = undefined;
        let textvalid = undefined;

        let numeroinvalido = false;
        let longitudinvalida = false;
        let campovacio = false;

        for (let x = 0; x < arraytempG.length; x++) {
            arraytemp = arraytempG[x].nomina
            for (let i = 0; i < arraytemp.length; i++) {
                longitudvalida = { longitudminima: 1, longitudmaxima: 12 };
                textvalid = validarNumerosDecimales2(arraytemp[i].bonos.trim(), longitudvalida);
                if (textvalid.codigo == "invalid") {
                    if (textvalid.mensaje == "Este campo no puede superar más de 12 caracteres") {
                        longitudinvalida = true;
                    }
                    else if (textvalid.mensaje == "Escribe un número válido") {
                        numeroinvalido = true;
                    }
                    else if (textvalid.mensaje == "Este campo es obligatorio") {
                        campovacio = true;
                    }
                    arraytemp[i].vermsgerrorbono = true;
                    validacionescorrectas = false;
                }

                textvalid = validarNumerosDecimales2(arraytemp[i].deducciones.trim(), longitudvalida);
                if (textvalid.codigo == "invalid") {
                    if (textvalid.mensaje == "Este campo no puede superar más de 12 caracteres") {
                        longitudinvalida = true;
                    }
                    else if (textvalid.mensaje == "Escribe un número válido") {
                        numeroinvalido = true;
                    }
                    else if (textvalid.mensaje == "Este campo es obligatorio") {
                        campovacio = true;
                    }
                    arraytemp[i].vermsgerrordeducciones = true;
                    validacionescorrectas = false;
                }

                textvalid = validarNumerosDecimales2(arraytemp[i].gastos.trim(), longitudvalida);
                if (textvalid.codigo == "invalid") {
                    if (textvalid.mensaje == "Este campo no puede superar más de 12 caracteres") {
                        longitudinvalida = true;
                    }
                    else if (textvalid.mensaje == "Escribe un número válido") {
                        numeroinvalido = true;
                    }
                    else if (textvalid.mensaje == "Este campo es obligatorio") {
                        campovacio = true;
                    }
                    arraytemp[i].vermsgerrorgastos = true;
                    validacionescorrectas = false;
                }

                textvalid = validarNumerosDecimales2(arraytemp[i].viaticos.trim(), longitudvalida);
                if (textvalid.codigo == "invalid") {
                    if (textvalid.mensaje == "Este campo no puede superar más de 12 caracteres") {
                        longitudinvalida = true;
                    }
                    else if (textvalid.mensaje == "Escribe un número válido") {
                        numeroinvalido = true;
                    }
                    else if (textvalid.mensaje == "Este campo es obligatorio") {
                        campovacio = true;
                    }
                    arraytemp[i].vermsgerrorviaticos = true;
                    validacionescorrectas = false;
                }
            }
        }

        await this.setState({
            tablaMaster: arraytempG
        })

        if (campovacio) {
            toast.warning("No se permiten campos vacíos.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        if (numeroinvalido) {
            toast.warning("Escribe un número válido.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        if (longitudinvalida) {
            toast.warning("No se permiten datos mayores a 12 caracteres.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            for (let x = 0; x < arraytempG.length; x++) {
                arraytemp = arraytempG[x].nomina
                for (let i = 0; i < arraytemp.length; i++) {
                    json.push({
                        "idcatalogo_nominas_detalles": arraytemp[i].idcatalogo_nominas_detalles,
                        "idusuario": arraytemp[i].idusuario,
                        "nomina": arraytemp[i].nomina,
                        "bonos": arraytemp[i].bonos,
                        "deducciones": arraytemp[i].deducciones,
                        "gastos": arraytemp[i].gastos,
                        "viaticos": arraytemp[i].viaticos
                    })

                }

            }

            var mandar_json = {
                "idcatalogo_nominas": this.state.save_idcatalogo_nominas,
                "usuarios": json,
            }

            var resp = await guardardatosNomina(mandar_json);

            if (resp.code == 200 || resp.code == "200") {
                this.componentDidMount();
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.success("Datos guardados exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 300) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 400) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            } else {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }



    fenalizarNomina = async () => {

        this.setState({
            titulomodal: "Aviso",
            nombreusuaremodal: "Estás seguro de Finalizar la Nomina ",
            mensajeasignar: "",
            vermodaleliminar: !this.state.vermodaleliminar
        })

    }

    continuar_eliminar_datos = async (item) => {
        this.setState({ vermodaleliminar: !this.state.vermodaleliminar })
        if (item == 1) {
            this.eliminardatos();
        }
    }

    eliminardatos = async () => {

        if (this.state.bandera_periodo == "activo") {
            this.setState({ loadingspinners: true });

            ///nominas/finalizar-nomina
            var json = {
                "idcatalogo_nominas": this.state.save_idcatalogo_nominas
            }
            var resp = await finalizanomina(json)
            this.componentDidMount(1);
            //this.setState({ loadingspinners: false });

        }
        else {
            toast.info("La Nomina finalizado", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            //toas

        }

    }

    cerrarModalAtutorizacion = (accion) => {
        this.setState({ modalautorizacion: !this.state.modalautorizacion })
        if (accion == 1) {
            this.componentDidMount();
        }
    }

    escribir = (prop) => (event) => {

        let info = prop.split('_');

        var datosArray = this.state.tablaMaster;
        var datos = datosArray[info[2]].nomina;
        if (info[1] == "nomina") {
            datos[info[0]].nomina = event.target.value;
        }
        else if (info[1] == "bonos") {
            datos[info[0]].bonos = event.target.value;
        }
        else if (info[1] == "deducciones") {
            datos[info[0]].deducciones = event.target.value;
        }
        else if (info[1] == "gastos") {
            datos[info[0]].gastos = event.target.value;
        }
        else if (info[1] == "viaticos") {
            datos[info[0]].viaticos = event.target.value;
        }


        this.setState({ tablaMaster: datosArray });
        this.sumardatos();
    }



    cambiarFecha = (item) => {

        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }


    format_nombre = (nombre, apellidos) => {
        let nombrecompleto = nombre + ' ' + apellidos;
        if (nombrecompleto.length >= 34) {
            return (
                <span title={nombrecompleto}>
                    {nombrecompleto.substring(0, 34)}...
                </span>
            );
        } else {
            return nombrecompleto;
        }
    }

    format_puesto = (puesto) => {
        if (puesto.length >= 20) {
            return (
                <span title={puesto}>
                    {puesto.substring(0, 20)}...
                </span>
            );
        } else {
            return puesto;
        }
    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    format_moneda_sin_logo = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    crearCSV = async () => {
        this.setState({ verCSV: false })
    }
    crearCSVss = async () => {
        this.setState({ verCSV: false })
        var json = {
            "idcatalogo_nominas": this.state.save_idcatalogo_nominas
        }


        var resp = await Getinfocsv(json);

        this.setState({ jsoncsv: resp.data })

        setTimeout(() => {
            this.setState({ verCSV: true })
        }, 500);


        /*
        var temarray = bitacora.data.items;

        var array_datos = [];
        var array_subdatos = [];


        array_datos = ["Fecha de gestión", "Cliente", "Fecha inicio", "Fecha Fin"];
        array_subdatos.push(array_datos);

        array_datos = [fechaaux, seleccionado[3],
            seleccionado[0], seleccionado[1]];
        array_subdatos.push(array_datos);
        array_subdatos.push([""]);

        var arrayheders = JSON.parse(JSON.stringify(this.state.tabla.encabezados));
        arrayheders.pop();
        array_subdatos.push(arrayheders);



        for (let i = 0; i < temarray.length; i++) {

            array_datos = [temarray[i].id,
            temarray[i].cuenta,
            temarray[i].cliente,
            temarray[i].medio,
            temarray[i].servicio,
            temarray[i].archivo,
            temarray[i].fecharecepcion,
            temarray[i].totaltransacciones,
            temarray[i].totaltransaccioneserror,
            temarray[i].totaltransaccionesenviadas,
            temarray[i].totaltransaccionesenviadaserror,
            temarray[i].fechavalidacion,
            temarray[i].fechanotificacioncliente,
            temarray[i].codigoestructura,
            temarray[i].estatus

            ]
            array_subdatos.push(array_datos)

        }

        if (array_subdatos.length > 3) {

            this.setState({ jsoncsv: array_subdatos })
        }

*/

    }

    vernotas = (item) => {

        this.setState({ titulomodal: 'Notas' });
        this.setState({ arrayModal: item })
        this.setState({ modalnotas: !this.state.modalnotas });

    }


    regresar = () => {
        this.setState({ loadingspinners: true });
        this.setState({ regresarnominas: true });
    }


    render() {
        return (

            <>







                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                    : null}
                <ToastContainer />


                {(this.state.vermodaleliminar == true) ? (
                    <Modalconfirmaasignar titulomodal={this.state.titulomodal} cerrarModal={this.continuar_eliminar_datos}
                        show={this.state.vermodaleliminar} mensaje={this.state.mensajeasignar} nombre={this.state.nombreusuaremodal}
                    />
                ) : null}


                {(this.state.modalnotas == true) ? (
                    <ModalSalarios titulomodal={this.state.titulomodal} cerrarModal={this.vernotas}
                        show={this.state.modalnotas} arrayModal={this.state.arrayModal} bandera={"notas"}
                    />

                ) : null}


                {(this.state.regresarnominas == true) ? (
                    <Acciones bandera={"regresar"} />
                ) : null}


                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modalautorizacion == true) ? (
                    <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
                        cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodal} />
                ) : null}

                <Navbar />

                {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Nómina semanal</label>
                            </div>
                             <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                               <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={() => this.crearCSVss()}>Generar CSV</button>
                               
                            </div>


                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                               
                                {(this.state.verCSV) ? (
                                    <ExportarCSV arraycsv={this.state.jsoncsv} totalitems={this.state.jsoncsv.length}
                                    />
                                ) : null
                                }
                            </div>


                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={() => this.regresar()}>Regresar</button>
                            </div>

                           

                        </div>

                        <div className='row align-items-end '>
                            <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                                {this.state.bandera_periodo == "activo" ? <label className='lbljoract letraTitulos' style={{ cursor: "pointer" }} onClick={this.fenalizarNomina}>Nomina activa</label> : <label className='lbljorfin letraTitulos'>Nomina finalizada</label>}
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>

                                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>
                                    <div className='row'>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                            <label className='lblTexto letraTitulos' style={{ textAlign: "start" }} >{this.state.fechacompraini}</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                            <label className='lblTexto letraTitulos' >{this.state.fechacomprafin}</label>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <br />
                {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}
                <div className="container-fluid anchoMaximo containerTabla">
                    <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Usuarios Pendientes</label>
                    <div className="containerSubTabla">
                        <Table style={{ border: "1px solid #a7a7a7" }}>
                            <thead style={{ verticalAlign: "bottom" }}>
                                <tr>
                                    {this.state.usuariospen.encabezados.map((titulo, index) => (
                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                            style={{ minWidth: this.state.usuariospen.mindimensiones[index], width: this.state.usuariospen.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index == 16 ? 'start' : 'start' }}>
                                            {titulo}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody style={{ verticalAlign: "bottom" }}>


                                {this.state.listapaginada.map((item, index) => (
                                    <tr key={"tabla" + index} style={{ border: "1px solid #a7a7a7" }}>

                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.usuariospen.mindimensiones[0], width: this.state.usuariospen.dimensiones[0] }}>
                                            {item.idusuario}
                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.usuariospen.mindimensiones[1], width: this.state.usuariospen.dimensiones[1] }}>
                                            {item.nombre} {item.apellido}
                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.usuariospen.mindimensiones[2], width: this.state.usuariospen.dimensiones[2] }}>
                                            {item.puesto}
                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.usuariospen.mindimensiones[3], width: this.state.usuariospen.dimensiones[3] }}>
                                            {item.estatus}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="panelPaginationSection">
                    <Paginacion
                        activepage={this.state.activepage}
                        itemscountperpage={this.state.itemsperpage}
                        totalitemscount={this.state.txtbuscador.length < 3 ? this.state.usuariospen.elementos : this.state.usuariospen}
                        pagerangedisplayed={this.state.rangepages}
                        cambiarcontenido={(e) => this.cambiarContenido(e)}
                    />
                </div>
                {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
                <div className="container-fluid anchoMaximo">
                    <div className="container-fluid anchoMaximoTabCultivo ">
                        <div className="containerSubTabla">
                            <div className='row contenedorNowrap'>
                                <div className='contenedorInformaciónEmpleado'>
                                    {this.state.tablaMaster.map((itemG, indexG) => (
                                        <div key={indexG}>
                                            <br />
                                            <br />
                                            <div className='tituloRancho'>
                                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Zona: {itemG.zona} - Rancho: {itemG.rancho}</label>
                                            </div>
                                            <br />
                                            <div className='contenedorTabla'>
                                                <Table style={{ border: "1px solid #a7a7a7" }}>
                                                    <thead style={{ verticalAlign: "bottom", height: '65px' }}>
                                                        <tr>
                                                            {this.state.tabla.encabezados.slice(0, 4).map((titulo, index) => (
                                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index == 16 ? 'start' : 'start' }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ verticalAlign: "bottom" }}>
                                                        {itemG.nomina.map((item, index) => (
                                                            <tr key={"tabla" + index} style={{ border: "1px solid #a7a7a7", height: "33px" }}>

                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: 'fit-content' }}>
                                                                    {this.format_nombre(item.nombre, item.apellido)}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla1cell" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], maxWidth: this.state.tabla.dimensiones[1] }}>
                                                                    {(item.puesto)}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                                    {this.format_moneda(item.salario)}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                                    {this.format_moneda(item.tardeada)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                    {itemG.nomina.length > 0 ?
                                                        <tbody style={{ verticalAlign: "bottom" }}>
                                                            <tr key={"tablass"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                                </td>
                                                            </tr>
                                                            <tr key={"tablass"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                                </td>
                                                            </tr>
                                                            <tr key={"tablass"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                                </td>
                                                            </tr>
                                                            <tr key={"tablass"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        : null}
                                                </Table>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='contenedorDiasCalculos'>
                                    {this.state.tablaMaster.map((itemG, indexG) => (
                                        <div key={indexG}>
                                            <div style={{ height: '108px' }}>

                                            </div>
                                            <Table style={{ border: "1px solid #a7a7a7" }}>
                                                <thead style={{ verticalAlign: "bottom", height: '65px' }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.slice(4).map((titulo, index) => (
                                                            <th key={"tabla" + index + 4} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index + 4], width: this.state.tabla.dimensiones[index + 4], color: this.state.colorencabezadostabla, textAlign: index + 4 == 16 ? 'start' : 'start' }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {itemG.nomina.map((item, index) => (
                                                        <tr key={"tabla" + index} style={{ border: "1px solid #a7a7a7", height: "33px" }}>
                                                            {item.dias.map((itemd, indexday) => (
                                                                <td className="letraNormal lblSubtabla bodersinputopagos" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                                    {itemd.turnomatutino == 1 && itemd.turnovespertino == 1 ? "AT" : itemd.turnomatutino == 1 ? "A" : itemd.turnovespertino == 1 ? "T" : itemd.turnocosecha == 1 ? "C" : " - "}
                                                                </td>
                                                            ))}

                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: "center", minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 1)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 1)] }}>
                                                                {item.diastrabajados}
                                                            </td>

                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 2)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 2)] }}>
                                                                {this.state.bandera_periodo == "finalizado" ? <>{this.format_moneda(item.nomina)}</>
                                                                    :
                                                                    <>
                                                                        {/**
                                                                        <input className='txtinputcostos' type="number" value={item.nomina}
                                                                            onChange={this.escribir(index + "_nomina_" + indexG)}></input>
                                                                        */}
                                                                        <>{this.format_moneda(item.nomina)}</>
                                                                    </>}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 3)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 3)] }}>
                                                                {this.state.bandera_periodo == "finalizado" ?
                                                                    <>
                                                                        {this.format_moneda(item.bonos)}
                                                                    </>
                                                                    :
                                                                    <input
                                                                        className={item.vermsgerrorbono ? 'txtinputcostosconerror' : 'txtinputcostos'}
                                                                        type="number"
                                                                        value={item.bonos}
                                                                        onChange={this.escribir(index + "_bonos_" + indexG)}>
                                                                    </input>
                                                                }
                                                            </td>

                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 4)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 4)] }}>
                                                                {this.state.bandera_periodo == "finalizado" ?
                                                                    <>
                                                                        {this.format_moneda(item.deducciones)}
                                                                    </>
                                                                    :
                                                                    <input
                                                                        className={item.vermsgerrordeducciones ? 'txtinputcostosconerror' : 'txtinputcostos'}
                                                                        type="number"
                                                                        value={item.deducciones}
                                                                        onChange={this.escribir(index + "_deducciones_" + indexG)}>
                                                                    </input>
                                                                }
                                                            </td>

                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 5)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 5)] }}>
                                                                {this.state.bandera_periodo == "finalizado" ?
                                                                    <>
                                                                        {this.format_moneda(item.gastos)}
                                                                    </>
                                                                    :
                                                                    <input
                                                                        className={item.vermsgerrorgastos ? 'txtinputcostosconerror' : 'txtinputcostos'}
                                                                        type="number"
                                                                        value={item.gastos}
                                                                        onChange={this.escribir(index + "_gastos_" + indexG)}>
                                                                    </input>
                                                                }
                                                            </td>

                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 6)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 6)] }}>
                                                                {this.state.bandera_periodo == "finalizado" ?
                                                                    <>
                                                                        {this.format_moneda(item.viaticos)}
                                                                    </>
                                                                    :
                                                                    <input
                                                                        className={item.vermsgerrorviaticos ? 'txtinputcostosconerror' : 'txtinputcostos'}
                                                                        type="number" value={item.viaticos}
                                                                        onChange={this.escribir(index + "_viaticos_" + indexG)}>
                                                                    </input>
                                                                }
                                                            </td>

                                                            <td className="letraNormal lblSubtabla" text-center onClick={this.vernotas.bind(this, item)} style={{ color: this.state.colorcontenidotabla, textAlign: 'center', minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 7)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 7)] }}>
                                                                {item.notasencargado.length} <b style={{ textDecoration: "underline" }}><> Ver </></b>
                                                            </td>

                                                            <td className="lblSubtabla letraTitulos" text-center style={{ color: this.state.colorcontenidotabla, textAlign: 'center', minWidth: this.state.tabla.mindimensiones[(item.dias.length + 4 + 8)], width: this.state.tabla.dimensiones[(item.dias.length + 4 + 8)] }}>
                                                                <b>{this.format_moneda(item.totalsalariosuma)}</b>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                                {itemG.nomina.length > 0 ?
                                                    <tbody style={{ verticalAlign: "bottom" }}>

                                                        <tr key={"tablass"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                            {itemG.nomina[0].dias.map((itemd, indexday) => (
                                                                <td key={indexday} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                                    {""}
                                                                </td>

                                                            ))}

                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 1)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 1)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 2)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 2)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 3)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 3)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 4)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 4)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 5)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 5)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 6)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 6)] }}>
                                                            </td>


                                                            <td className="letraTitulos lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 7)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 7)] }}>
                                                                <b style={{ fontSize: "14px" }}>Percepcion salarial total</b>
                                                            </td>

                                                            <td className="lblSubtabla letraTitulos" text-center style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 8)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 8)], textAlign: 'center' }}>
                                                                <b style={{ fontSize: "14px" }}>{this.format_moneda(itemG.total_salarial)}</b>
                                                            </td>

                                                        </tr>

                                                        <tr key={"tablass1"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                            {itemG.nomina[0].dias.map((itemd, indexday) => (
                                                                <td key={indexday} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                                    {""}
                                                                </td>
                                                            ))}


                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 1)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 1)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 2)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 2)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 3)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 3)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 4)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 4)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 5)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 5)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 6)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 6)] }}>
                                                            </td>


                                                            <td className="letraTitulos lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 7)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 7)] }}>
                                                                <b style={{ fontSize: "14px" }}>Otras Percepciones</b>
                                                            </td>

                                                            <td className="lblSubtabla letraTitulos" text-center style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 8)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 8)], textAlign: 'center' }}>
                                                                <b style={{ fontSize: "14px" }}>{this.format_moneda(itemG.total_otras)}</b>
                                                            </td>

                                                        </tr>



                                                        <tr key={"tablass23"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                            {itemG.nomina[0].dias.map((itemd, indexday) => (
                                                                <td key={indexday} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                                    {""}
                                                                </td>
                                                            ))}


                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 1)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 1)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 2)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 2)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 3)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 3)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 4)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 4)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 5)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 5)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 6)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 6)] }}>
                                                            </td>

                                                            <td className="letraTitulos lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 7)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 7)] }}>
                                                                <b style={{ fontSize: "15px" }}>Deducciones</b>
                                                            </td>

                                                            <td className="lblSubtabla letraTitulos" text-center style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 8)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 8)], textAlign: 'center' }}>
                                                                <b style={{ fontSize: "15px" }}>-{this.format_moneda(itemG.total_deduciones)}</b>
                                                            </td>

                                                        </tr>





                                                        <tr key={"tablass23"} style={{ border: "1px solid #a7a7a7", height: '34px' }}>

                                                            {itemG.nomina[0].dias.map((itemd, indexday) => (
                                                                <td key={indexday} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                                                                    {""}
                                                                </td>
                                                            ))}


                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 1)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 1)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 2)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 2)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 3)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 3)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 4)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 4)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 5)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 5)] }}>
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 6)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 6)] }}>
                                                            </td>


                                                            <td className="letraTitulos lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 7)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 7)] }}>
                                                                <b style={{ fontSize: "16px" }}>Total</b>
                                                            </td>

                                                            <td className="lblSubtabla letraTitulos" text-center style={{ minWidth: this.state.tabla.mindimensiones[(itemG.nomina[0].dias.length + 4 + 8)], width: this.state.tabla.dimensiones[(itemG.nomina[0].dias.length + 4 + 8)], textAlign: 'center' }}>
                                                                <b style={{ fontSize: "16px" }}>{this.format_moneda(itemG.total_salario)}</b>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                    : null}
                                            </Table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

                {this.state.bandera_periodo == "activo" ?
                    <>
                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.regresar()}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.GuardarCambiosNomina()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>
                    </>
                    :
                    null
                }
                <br />
            </>
        )
    }
}
function Acciones({ bandera }) {

    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'regresar') {
        return (<Redirect from='/' to={"/catalogonomina"} />)
    }


}




const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));

