import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { getComboFamiliaProducto, getComboTipoProducto } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioBuscarProductos extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      txtbuscador: "",
      tabla: {
        encabezados: [
          "Tabla",
          "Estatus de siembra",
          "Área total(ha)",
          "Plantas totales(ud)"
        ],
        dimensiones: [
          "30%",
          "30%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "200px",
          "250px",
          "200px",
          "200px"
        ],
        elementos: [],
      },


      idlote: 0,

      // Listas
      listalotes: props.listalotes,
      listatablascultivo: [],
      tipoactividadseleccionada: todoslosfiltrosdisponibles,
      listatablascultivofiltradas: [],



      todoslosproductosalmacen: this.props.todoslosproductosalmacen,
      listaproductosalmacen: [],//this.props.listaproductosalmacen,
      productoseleccionado: undefined,
      idproductoseleccionado: 0,
      productosseleccionados: {},
      //arrayidproductosseleccionados: 0,
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    this.setState({
      txtbuscador: '',
      listafamiliaproductos: [],//this.props.respfamiliaproductos,
      listatipoproductos: [],//this.props.resptipoproductos,
      listatipoproductosfiltrados: [],//this.props.resptipoproductos,
      listaproductosfiltrados: [],//tabla.elementos
    })

     this.props.tablasseleccionadas.forEach(el => {   
      let productosseleccionados= this.state.productosseleccionados;
      productosseleccionados[el.idcatalogo_lotecultivo_tabla] = el
      this.setState({ productosseleccionados: productosseleccionados, confirmarenvio: true })
    });
  
    this.setState({ loadingspinners: false })

    if (this.props.idlote != 0) {
      await this.filtrarTipoActividades(this.props.idlote)
      setTimeout(() => {
        this.props.tablasmarcadas.forEach(el => {
          this.seleccionarProducto(el)
        });
      }, 300);
    }

  } 

  filtrarProductosdeAlmacen(respproductos) {
    var productosdealmacen = [];

    this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
      var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
      if (producto != undefined) {
        productosdealmacen = productosdealmacen.concat(producto)
      }
    });

    return productosdealmacen;
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {

    if (accion != 0) {
      let x = this.state.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo == this.state.idlote)

      let enviar = { lote: { idlote: x.idcatalogo_ranchos_lotes_cultivo, lote: x.lote }, tablas: accion }
      this.setState({ show: false });
      setTimeout(() => {
        this.props.cerrarModal(enviar);
      }, 200);
    }
    else {
      this.setState({ show: false });
      setTimeout(() => {
        this.props.cerrarModal(accion);
      }, 200);
    }


  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    // Mandar datos del item de producto seleccionado

    let listatablascultivo = [];
  
    this.state.listatablascultivofiltradas.forEach(el => {
      if (this.state.productosseleccionados[el.idcatalogo_lotecultivo_tabla] != undefined) {
        listatablascultivo.push(el)
      }
    });

    this.showModal(listatablascultivo)//productoseleccionado)
  }

  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrotipoactividad = [];
    var filtroactividades = [];

    this.setState({ txtbuscador: e.target.value })
    if (e.target.value.trim().length >= 3) {
      filtrotipoactividad = data

      if (this.state.tipoactividadseleccionada != todoslosfiltrosdisponibles) {
        filtrotipoactividad = data.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.state.tipoactividadseleccionada)
      }

      filtroactividades = filtrotipoactividad.filter((item) => item.tabla.toLocaleLowerCase().includes(e.target.value.trim().toLocaleLowerCase()))
      if (filtroactividades.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(filtroactividades)

    } else if (e.target.value.trim().length < 1) {
      this.filtrarTipoActividades(this.state.tipoactividadseleccionada)
    }
  }

  filtro = (array) => {

    this.setState({ listatablascultivofiltradas: array })

  }

  filtrarTipoActividades = async (tipo) => {
    //var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    this.setState({ idlote: tipo })
    var data = this.props.listatablascultivo;
    await this.setState({ tipoactividadseleccionada: tipo })
    var filtrotipoactividad = [];
    var filtroactividades = [];

    filtrotipoactividad = data;
    if (tipo != todoslosfiltrosdisponibles) {
      filtrotipoactividad = data.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == tipo)
    }
    filtroactividades = filtrotipoactividad.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    if (filtroactividades.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(filtroactividades)
  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      tipoactividadseleccionada: todoslosfiltrosdisponibles,
      listatablascultivofiltradas: []
    });
  }

  seleccionarProducto = async (item) => {
    var productosseleccionados = this.state.productosseleccionados
    var productoseleccionado = productosseleccionados[item.idcatalogo_lotecultivo_tabla]
    if (productoseleccionado === undefined) {
      /*productosseleccionados = { [item.idcatalogo_lotecultivo_tabla]: item }
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: true, //idproductoseleccionado: item.idcatalogo_lotecultivo_tabla
      })*/
      productosseleccionados[item.idcatalogo_lotecultivo_tabla] = item
      this.setState({ productosseleccionados: productosseleccionados, confirmarenvio: true })
    } else {
      /*productosseleccionados = {}
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: false, //idproductoseleccionado: item.idcatalogo_lotecultivo_tabla
      })*/
      delete productosseleccionados[item.idcatalogo_lotecultivo_tabla]
      var confirmarenvio = Object.keys(productosseleccionados).length != 0
      this.setState({ productosseleccionados: productosseleccionados, confirmarenvio: confirmarenvio })
    }
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              {this.props.idrancho <= 0 ? (
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Primero selecciona un rancho </label>
              ) : (
                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                  {this.props.idlote != 0 ? (
                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Lote:
                      {this.state.listalotes.find(x => x.idcatalogo_ranchos_lotes_cultivo == this.props.idlote).lote} </label>
                  ) :
                    <div className='row'>
                      <div className='col-6 containerSubTablaMMM paddingBottomEncabezadosMMM'>
                        <div className='divComboSelect'
                          style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Lote: </label>
                          <div className="selectBox ">
                            <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                              onChange={(event) => this.filtrarTipoActividades(event.target.value)}>
                              {this.state.filtrotipoproducto == todoslosfiltrosdisponibles ?
                                <option selected="true" value={todoslosfiltrosdisponibles}>Selecciona</option> : <option value={todoslosfiltrosdisponibles}>Selecciona</option>}
                              {this.state.listalotes.map((i, index) => (
                                i.idcatalogo_rancho == this.props.idrancho ? (
                                  <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index}  >{i.lote} </option>
                                ) : null
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 containerSubTabla paddingBottomEncabezados'>
                        <div className='txtBuscadorCN' >
                          <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                          <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                            className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                            placeholder="Buscar tabla"></input>
                          {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                            onClick={this.borrarBusqueda} />) : null}
                        </div>
                      </div>
                    </div>
                  }



                  {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                  <br />
                  <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">
                      <div className="containerSubTabla">
                        <Table hover>
                          <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                              {this.state.tabla.encabezados.map((titulo, index) => (
                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                  style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                  {titulo}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody style={{ verticalAlign: "bottom" }}>

                            {(this.state.tipoactividadseleccionada == todoslosfiltrosdisponibles) && (this.state.txtbuscador.trim().length < 3) ?
                              this.state.tabla.elementos.map((item, index) => (
                                <tr key={"tabla" + index}
                                  onClick={() => this.seleccionarProducto(item)}
                                  /*className={this.state.idproductoseleccionado == item.idcatalogo_lotecultivo_tabla ? "trFondoActivoGreen" : ""}*/
                                  className={this.state.productosseleccionados[item.idcatalogo_lotecultivo_tabla] != undefined ? "trFondoActivoGreen" : ""} >
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.tabla} 
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.tipoactividad}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.tipoactividad}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.tipoactividad}
                                  </td>
                                </tr>
                              ))
                              : this.state.listatablascultivofiltradas.map((item, index) => (
                                <tr key={"tabla" + index}
                                  onClick={() => this.seleccionarProducto(item)}
                                  /*className={this.state.idproductoseleccionado == item.idcatalogo_lotecultivo_tabla ? "trFondoActivoGreen" : ""}*/
                                  className={this.state.productosseleccionados[item.idcatalogo_lotecultivo_tabla] != undefined ? "trFondoActivoGreen" : ""} >
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.tabla} 
                                    
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.estatuscultivo}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.areatotal}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.plantastotales}
                                  </td>
                                </tr>
                              ))

                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}


            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

