export function coloresLogin() {
    var json = {
        logologin: "/assets/logo.png",
        fondogeneral: "white",
        colortitulos: "black",
        fondoinputs: "white",
        colorinputs: "black",
        borderinputslogin: " solid 1px #464646",
        btnfondologin: "#589b31",
        btncolorlogin: "white",
        olvidadecontrasena: "black",

    }
    return json;
}

export function estiloColorError() {
    var json = {
        msgerror: "#ff3e3e"
    }
    return json;
}

export function estiloFiltroEncabezados() {
    var json = {
        fondotitulosencabezados: "#f7f5f2",
        colortitulos: "#2371fd",
    }
    return json;
}

export function estiloComboBox() {
    var json = {
        colorcombolbl: "#2371fd",
        colorcbxlbl: "#22222280",
        colorbordercombo: " solid 2px #bebdbc",
        colorfondocombo: "white",
    }
    return json;
}

export function estiloTabla() {
    var json = {
        colorencabezadostablaclientes:"#5c5a5a",
        colorencabezadostabla: "black",
        colorcontenidotabla: "black"
    }
    return json;
}
export function estiloBtnActualizar() {
    var json = {
        colorbtnactualizar: "#0061fe",
        fondobtnactualizar: "white",
        borderbtnactualizar: " solid 2px #bebdbc",
    }
    return json;
}

export function estiloBtnNuevo() {
    var json = {
        colorbtnnuevo: "white",
        fondobtnnuevo: "#0061fe",
        borderbtnnuevo: " solid 2px #0061fe",
    }
    return json;
}

export function estiloBtnEditar() {
    var json = {
        btnfondoeditar: "#d3e3fd",
        btncoloreditar: "#0061fe",
        btnbordereditar: " solid 2px #d3e3fd",
        btnbordereditar2: "2px solid #0061fe",
        fondoblancobtneditar: 'white'
    }
    return json;
}

export function estiloBtnMasInformacion() {
    var json = {
        btnfondomasinfo: "#ede9e3",
        btncolormasinfo: "#22222280",
        btnbordermasinfo: " solid 2px #ede9e3",
    }
    return json;
}

export function coloresTxtBuscador() {
    var json = {
        colorlupa: "#0061fe",
        fondotxtbuscar: "white",
        colortxtbuscar: "black",
        bordertxtbuscar: " solid 2px #bebdbc",
        fondoicocloseinputs: "#d3e3fd",
    }
    return json;
}

export function estiloBtnVer() {
    var json = {
        colorverlbl: "black",
        borderver: " solid 2px #9b9b9b",
    }
    return json;
}

export function estilosModal() {
    var json = {
        fondocontenedormodal: "#fafbfd",
        colorlblsubtitulosmodal: "#8f8f90",
        colorlbltextomodal: "black",
        colorborderbottom: " solid 1px #8f8f90",
    }
    return json;
}

export function estiloContenedores() {
    var json = {
        colorfondocontenedor: "#f7f5f2",
        colorblancofondocontenedor: "white",
        colorborde: "solid 1px lightgray",
        colorbordenegro: "solid 1px gray",
    }
    return json;
}

export function estiloBtnEliminar() {
    var json = {
        colorfondobtneliminar: "#626161",
        colorbtneliminar: "white",
        colorbordebtneliminar: "solid 1px #626161"
    }
    return json;
}		

export function estiloBtnVerde() {
    var json = {
        colorfondobtnverde: "white",
        colorbtnverde: "#00d953",
        colorbordebtnverde: "solid 2px #00d953"
    }
    return json;
}		

export function estiloBtnRojo() {
    var json = {
        colorfondobtnrojo: "white",
        colorbtnrojo: "#f2556f",
        colorbordebtnrojo: "solid 1px #f2556f"
    }
    return json;
}

export function estiloBtnRestablecer() {
    var json = {
        colorfondobtnrestablecer: "white",
        colorbtnrestablecer: "#0061fe",
        colorbordebtnrestablecer: "solid 2px #0061fe"
    }
    return json;
}

export function estiloBtnAgregar() {
    var json = {
        colorfondobtnragregar: "#e8f0fe",
        colorbtnagregar: "#0061fe",
        colorbordebtnagregar: "solid 2px #bebdbc"
    }
    return json;
}
