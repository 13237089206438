import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoOrdenesTrabajo.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsFillEyeFill } from "react-icons/bs";
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar,
  estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getCatalogoOrdenTrabajo, getListaTipoUsuario, getListaZonas } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
//import ModalFormularioCambioEstatus from '../../Complements/Modales/ModalFomularioOrdenesTrabajo/ModalFormularioCambioEstatus';
import { FaEye, FaLockOpen } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const coloresporestatus = {
  1: 'btnEditarOT', 2: 'btnDisponibleOT', 3: 'btnAsignadoOT', 4: 'btnCompletadoOT', 5: 'btnCompletado'
}
const diamilisegundos = 1000 * 60 * 60 * 24

export default class CatalogoOrdenesTrabajo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: false,
      errordeacceso: false,

      tabla: {
        encabezados: [
          "ID",
          "Actividad",
          "Programa",
          "Rancho",
          "Fecha operación",
          "Estatus",
          "Monitoreo",
          ""
        ],
        dimensiones: [
          "5%",
          "15%",
          "15%",
          "13%",
          "13%",
          "10%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "50px",
          "150px",
          "150px",
          "100px",
          "150px",
          "100px",
          "100px",
          "100px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      modalaccesos: false,
      itemseleccionado: null,

      modalcambiarestatus: false,

      listazonas: [],
      txtselected: 0,

      datosDummy: [
        {
          "idordentrabajo": 1,
          "actividad": "nombre actividad",
          "idactividad": 1,
          "programa": "nombre programa en caso de",
          "rancho": "nombre rancho",
          "idrancho": 1,
          "zona": "nombre zona",
          "idzona": 1,
          "fechaestimada": "12/11/2023",
          "idestatus": 1,
          "estatus": "edición"
        },
        {
          "idordentrabajo": 1,
          "actividad": "nombre actividad",
          "idactividad": 1,
          "programa": "nombre programa en caso de",
          "rancho": "nombre rancho",
          "idrancho": 1,
          "zona": "nombre zona",
          "idzona": 1,
          "fechaestimada": "12/11/2023",
          "idestatus": 2,
          "estatus": "Disponible"
        },
        {
          "idordentrabajo": 1,
          "actividad": "nombre actividad",
          "idactividad": 1,
          "programa": "nombre programa en caso de",
          "rancho": "nombre rancho",
          "idrancho": 1,
          "zona": "nombre zona",
          "idzona": 1,
          "fechaestimada": "12/11/2023",
          "idestatus": 3,
          "estatus": "Asignada"
        },
        {
          "idordentrabajo": 1,
          "actividad": "nombre actividad",
          "idactividad": 1,
          "programa": "nombre programa en caso de",
          "rancho": "nombre rancho",
          "idrancho": 1,
          "zona": "nombre zona",
          "idzona": 1,
          "fechaestimada": "12/11/2023",
          "idestatus": 4,
          "estatus": "Finalizada"
        }

      ],

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      redireccionando: false,
      redireccionandobandera: '',

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

      // Nuevas variables
      fechainicio: new Date(),
      fechafinal: new Date(),
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var fechafinal = new Date()
    var fechamilisegundos = fechafinal.getTime()
    var fechainicio = new Date(fechamilisegundos - 6 * diamilisegundos)
    fechainicio.setHours(0, 0, 0, 0)
    fechafinal.setHours(0, 0, 0, 0)
    this.setState({ fechainicio: fechainicio, fechafinal: fechafinal })

    var tabla = this.state.tabla;
    let respordenes = await getCatalogoOrdenTrabajo();
    if (this.manejadorLlamadasApis(respordenes)) {
      return;
    }

    tabla.elementos = respordenes.data;
    this.setState({ txtbuscador: '', tabla: tabla })

    setTimeout(() => {
      this.dimensiones();
      this.cambiarContenido(1);
      this.filtro(1, tabla.elementos)
    }, 400);

    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.message == "Token no encontrado") {
      this.setState({ salir: true })
      return true
    } else if (resp.code == 300 ) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso: false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtrarPorFechas = ({ fechainicio, fechafinal }) => {
    const fechavalida = this.esFechaValida(fechainicio, fechafinal)
    if (!fechavalida) {
      toast.error("Seleccione un periodo de días válido", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }
    var filtroranchoscultivo = this.filtrarBusqueda(
      fechainicio, fechafinal, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)
  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    const fechavalida = this.esFechaValida(this.state.fechainicio, this.state.fechafinal)
    if (!fechavalida) {
      toast.error("Seleccione un periodo de días válido", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.fechainicio, this.state.fechafinal, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }

  }

  filtrarBusqueda = (fechainicio, fechafinal, textobusqueda) => {
    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    filtrozonasdisponibles = filtrozonasdisponibles.filter(
      (item) => this.esFechaValida(fechainicio, fechafinal, item.fechaoperacion))

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter((item) =>
      item.rancho.toLocaleLowerCase().includes(textobusqueda) ||
      item.actividad.toLocaleLowerCase().includes(textobusqueda))
  }

  esFechaValida = (fechainicio, fechafinal, fechaoperacion) => {
    fechainicio.setHours(0, 0, 0, 0)
    fechafinal.setHours(0, 0, 0, 0)
    if (fechainicio.getTime() > fechafinal.getTime()) {
      return false
    }

    if (fechaoperacion != undefined) {
      const [diaoperacion, mesoperacion, aniooperacion] = fechaoperacion.split('/')
      const fechaoperacionreal = new Date(aniooperacion, (mesoperacion - 1), diaoperacion)
      fechaoperacionreal.setHours(0, 0, 0, 0)

      if (fechainicio.getTime() > fechaoperacionreal.getTime()) {
        return false
      }

      if (fechafinal.getTime() < fechaoperacionreal.getTime()) {
        return false
      }
    }

    return true
  }

  /*
  verModalCambioEstatus = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Cambiar estatus de orden de trabajo', accionmodal: 1 })
      this.setState({ modalcambiarestatus: true });
    }
  }

  cerrarModalCambioEstatus = (accion) => {
    this.setState({ modalcambiarestatus: !this.state.modalcambiarestatus })
    if (accion == 1) {
      this.componentDidMount();
    }
  }
  */

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  redireccionar = ({ idrequisicion, opcion }) => {
    
    if (opcion == 'ordentrabajo') {
      localStorage.setItem("datoorden", JSON.stringify({ orden: idrequisicion, opcion: false }))
      this.setState({ redireccionando: true, redireccionandobandera: opcion })
    }

    if (opcion == 'reporteordentrabajo') {
      localStorage.setItem("datoorden", JSON.stringify({ orden: idrequisicion, opcion: false }))
      this.setState({ redireccionando: true, redireccionandobandera: opcion })
    }
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })
  }

  borrarBusqueda = () => {
    this.setState({ txtbuscador: '', })
    this.cambiarContenido(1);
    this.filtro(1, this.state.tabla.elementos)
    this.setState({ filtrolistapaginada: [] });
  }



  render() {
    return (

      <>

        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesClientes bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? <AccionesClientes bandera={this.state.redireccionandobandera} /> : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {/*(this.state.modalcambiarestatus == true) ? (
          <ModalFormularioCambioEstatus
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalCambioEstatus}
            show={this.state.modalcambiarestatus}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            setloadingspinner={this.setLoadingSpinner} />
        ) : null*/}

        <Navbar />

        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              {/** Título de la vista */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados' style={{ width: '290px' }}>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Órdenes de trabajo</label>
              </div>

            
              {/** Caja de búsqueda por actividad/rancho */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar actividad/rancho"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              {/** Botón refrescar lista */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/** Botón nuevo */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.redireccionar.bind(this, { idrequisicion: 0, opcion: 'ordentrabajo' })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                          textAlign: (index == 2 || index > 3) ? 'center' : ''
                        }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.filtrolistapaginada.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                        {item.idordentrabajo}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                        {item.actividad}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                        {item.programa}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                        {item.rancho}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                        {item.fechaestimada}
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                        <label className={'btnEstandar btnBase ' + coloresporestatus[item.idestatus]}
                          style={{ width: '100%', border: "0px !important" }}>{item.estatus}</label>
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                        {item.idestatus > 2 ? (
                          <button className={'btnEstandar btnBase botonNotasOT'}
                          onClick={this.redireccionar.bind(this, { idrequisicion: item.idordentrabajo, opcion: 'reporteordentrabajo' })}
                            style={{ width: '100%' }}>
                            <BsFillEyeFill className="icoBotones" /> Ver reporte </button>
                        ) : null}

                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7], paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>

                        {item.idestatus > 3 ? (
                          <button className='btnEstandar btnBase' onClick={this.redireccionar.bind(this, { idrequisicion: item.idordentrabajo, opcion: 'ordentrabajo' })} /*onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}*/
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, width: '100%' }}>
                            <FaEye className="icoBotones" /> Ver orden </button>
                        ) : (
                          <button className='btnEstandar btnBase' onClick={this.redireccionar.bind(this, { idrequisicion: item.idordentrabajo, opcion: 'ordentrabajo' })} /*onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}*/
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, width: '100%' }}>
                            <BiEdit className="icoBotones" /> Editar</button>
                        )}

                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '106px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '104px' }}>{value}</label> {/*<FaCalendar className="icoBotones" />*/}
  </button>
));

function AccionesClientes({ bandera }) {
  const history = useHistory();
  const location = useLocation();
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  } else if (bandera == 'ordentrabajo') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/ordentrabajo"} />)
  } else if (bandera == 'reporteordentrabajo') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/reporteordentrabajo"} />)
  }

  
}