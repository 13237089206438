import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';


export default class ModalConfirarAccion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpomodal,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalConfirmar(accion);
        }, 200);
    }



    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                        </div>



                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                
                                <p className='lblSubTitulosModal ' >
                                    Fecha solicitud: {this.props.item.fechasolicitud}
                                </p>
                                <p className='lblSubTitulosModal ' >
                                    Solicitó: {this.props.item.usuario}
                                </p>
                                <p className='lblSubTitulosModal ' >
                                    Proveedor: {this.props.item.proveedor}
                                </p>
                                <p className='lblSubTitulosModal ' >
                                    Destino: 
                                    {this.props.item.tipodestino==1?(this.props.item.area):(null)}
                                    {this.props.item.tipodestino==2?(this.props.item.rancho +', '+this.props.item.zona):(null)}
                                    {this.props.item.tipodestino==3?(this.props.item.maquinaria):(null)}
                                </p>                               
                                <p className='lblSubTitulosModal ' >
                                    Combustible: {this.props.item.combustible +" ("+this.props.item.litros+" L)"}
                                    {this.props.item.importe!=0.00 ?(" => $ "+ this.props.item.importe):(null)}
                                </p>
                            </div>
                        </div>

                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.showModal(1)} >Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }
}
