import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { FaCalendar } from "react-icons/fa";
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { ImDownload3 } from 'react-icons/im'
import ModalBuscarCompras from './ModalBuscarCompras';
import { buscarFacturasCompraSeleccionadaPagoGlobal, getDetallePagoGlobalGeneral, guardarPagoGlobalCompras } from '../../../Services/Api';
import { formatoNumero2 } from '../../../Services/Validaciones';

export default class ModalFormularioGuardarPagoGlobal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,
            
            estilosmodal: estilosModal(),

            tabla: {
                encabezadosCompras: [
                    "ID compra",
                    "Subtotal",
                    "Descuento",
                    "Total",
                    ""
                ],
                encabezadosCompras2: [
                    "ID compra",
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "10px"
                ],
                dimensiones2: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "20%",
                    "5%"
                ],
                mindimensiones2: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "150px",
                    "10px"
                ]
            },

            tablaComprasRelacionadas: {
                subtotal: 0.00,
                descuento: 0.00,
                total: 0.00,
                listacompras: []
            },

            listafacturascompras: [],
            listafacturascomprasSeleccionadas: [],
            idslistafacturascomprasSeleccionadas: [],

        }

    }
    
    async componentDidMount() {
        if(this.props.accion==9){
            this.setLoadingSpinner(true)
            let request = {
                idcompra: this.props.idcompra,
            }

            var resp = await getDetallePagoGlobalGeneral(request)

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }else{
                var auxlistaCompras = this.state.tablaComprasRelacionadas
                auxlistaCompras.listacompras = resp.data.comprasrelacionadas
                

                let totalesCalculados = this.calcularTotales(auxlistaCompras.listacompras)

                auxlistaCompras.subtotal = totalesCalculados.subtotal;
                auxlistaCompras.descuento = totalesCalculados.descuento;
                auxlistaCompras.total = totalesCalculados.total;

                var auxlistaFacturas = resp.data.facturas_relacionadas

                setTimeout(() => {
                    this.setState({
                        tablaComprasRelacionadas: auxlistaCompras,
                        listafacturascompras: auxlistaFacturas
                    });
                }, 0);

                this.setLoadingSpinner(false)
            }
        }
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    cerrarModal = async (item) => {
        if(item!=0){

            var auxComprasSeleccionada = [];
            var agregarCompras = false;
            //validamos que exista la compra en la tabla
            item.map((compraSeleccionada)=>{
                var indexCompra = this.state.tablaComprasRelacionadas.listacompras.findIndex((compra)=>compra.idcompra==compraSeleccionada.idcompra)
                if(indexCompra > -1){
                    toast.info("La comra con ID "+compraSeleccionada.idcompra+" ya está en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                }else{
                    auxComprasSeleccionada.push(compraSeleccionada)
                    agregarCompras = true;
                }
            })

            if(agregarCompras){
                this.setLoadingSpinner(true)
                var auxlistaCompras = this.state.tablaComprasRelacionadas
                auxlistaCompras.listacompras = auxlistaCompras.listacompras.concat(auxComprasSeleccionada)

                let totalesCalculados = this.calcularTotales(auxlistaCompras.listacompras)

                auxlistaCompras.subtotal = totalesCalculados.subtotal;
                auxlistaCompras.descuento = totalesCalculados.descuento;
                auxlistaCompras.total = totalesCalculados.total;

                var resp = await this.getFacturasCompras(auxlistaCompras.listacompras)

                setTimeout(() => {
                    this.setState({
                        tablaComprasRelacionadas: auxlistaCompras,
                        listafacturascompras: resp
                    });
                }, 0);

                this.setLoadingSpinner(false)
            }
        }
        this.setState({ modalbuscarcompra: !this.state.modalbuscarcompra })
    }

    getFacturasCompras = async (listacompras) => {
        var facturas = [];

        for (let index = 0; index < listacompras.length; index++){
            var compra = listacompras[index];
            var resp = await buscarFacturasCompraSeleccionadaPagoGlobal({idcompra: compra.idcompra});
            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }else if (resp.code == 200 || resp.code == 300) {
                toast.info("No encontro facturas para la compra con ID "+compra.idcompra, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else {
                if (resp.data.length>0) {
                    facturas = facturas.concat(resp.data)
                }
            }
        }
        
        return facturas;
    }

    verModalDetalles = () => {
        this.setState({ modalbuscarcompra: true })
    }

    eliminarCompra = async (compra) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.tablaComprasRelacionadas;
        this.state.tablaComprasRelacionadas.listacompras = aux.listacompras.filter((item) => !(item.idcompra == compra.idcompra));
        
        //se elimina la factura de los seleccionados
        var auxFacturasRemove = this.state.listafacturascomprasSeleccionadas.filter((f)=> f.idcompra==compra.idcompra)
        auxFacturasRemove.map((f)=>{
            this.seleccionarFactura(f)
        })

        //se elimina de la tabla facturas
        var auxFacturas = this.state.listafacturascompras;
        this.state.listafacturascompras = auxFacturas.filter((item) => !(item.idcompra == compra.idcompra));

        let totalesCalculados = this.calcularTotales( this.state.tablaComprasRelacionadas.listacompras)

        this.state.tablaComprasRelacionadas.subtotal = totalesCalculados.subtotal;
        this.state.tablaComprasRelacionadas.descuento = totalesCalculados.descuento;
        this.state.tablaComprasRelacionadas.total = totalesCalculados.total;

        var aux = this.state.tablaComprasRelacionadas;
        var aux2 = this.state.listafacturascompras
        await this.setState({ 
            tablaComprasRelacionadas: aux,
            listafacturascompras: aux2
        })
    }

    calcularTotales (listacompras) {
        var total = 0, descuento = 0, subtotal = 0;
        listacompras.map((compra)=>{
            subtotal = parseFloat(subtotal) + parseFloat(compra.precio);
            if(compra.descuento!=undefined){
                descuento = parseFloat(descuento) + parseFloat(compra.descuento);
                total = parseFloat(total) + (parseFloat(compra.precio)-parseFloat(compra.descuento))
            }else{
                total = parseFloat(total) + (parseFloat(compra.precio))
            }
            
        })
        let calculados = {
            total: total,
            subtotal: subtotal,
            descuento: descuento
        }

        return calculados;
    }

    setFormatDateSave = (fecha) => {
        var fechaseteada = "";
        if(fecha!=null){
            var [year, month, day] = fecha.split("-");
        
            if(day!=undefined && month!=undefined && year!=undefined){
                fechaseteada = `${day}/${month}/${year}`
                return fechaseteada;
            }else{
                fechaseteada = fecha;
            }
        }
           
        return fechaseteada;
    }

    guardarCambios = async () => {
        let request = {
            idcompra: this.props.idcompra,
            comprasrelacionadas: [],
            facturasrelacionadas: []
        }

        var auxComprasSave = [];
        this.state.tablaComprasRelacionadas.listacompras.map((compra)=>{
            let compraAux = {
                idcompra: compra.idcompra
            }
            auxComprasSave.push(compraAux)
        })

        var auxFacturasSeve = [];
        this.state.listafacturascomprasSeleccionadas.map((factura)=>{
            let facturaAux = {
                idfactura: factura.idfactura
            }
            auxFacturasSeve.push(facturaAux)
        })

        request.comprasrelacionadas = auxComprasSave;
        request.facturasrelacionadas = auxFacturasSeve;

       
        this.setLoadingSpinner(true)
        var resp = await guardarPagoGlobalCompras(request)

        if (resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            this.setLoadingSpinner(false)
            return;
        }
        else if (resp.code == 200 || resp.code == 300) {
            this.setLoadingSpinner(false)
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else {
            this.showModal({accion: 3, resp: resp})
        }

    }

    seleccionarFactura = async (item) => {
        if(this.state.idslistafacturascomprasSeleccionadas.indexOf(item.idfactura)!=-1){
            this.state.idslistafacturascomprasSeleccionadas.splice(this.state.idslistafacturascomprasSeleccionadas.indexOf(item.idfactura),1);
            var auxFacturas = this.state.listafacturascomprasSeleccionadas.filter((compra) => !(compra.idfactura==item.idfactura));
            await this.setState({listafacturascomprasSeleccionadas: auxFacturas})
        }else{
            this.state.idslistafacturascomprasSeleccionadas.push(item.idfactura);
            this.state.listafacturascomprasSeleccionadas.push(item);
            var auxFacturas = this.state.listafacturascomprasSeleccionadas;
            await this.setState({listafacturascomprasSeleccionadas: auxFacturas})
        }
  
        await this.setState({})
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalbuscarcompra == true) ? (
                        <ModalBuscarCompras 
                        titulomodal={"Buscar compras"}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalbuscarcompra}
                        accion={this.state.accionmodal}
                        listacompras={this.props.listacompras}
                        idcompra={this.props.idcompra}
                        />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({accion: 3, resp: undefined})} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <br />
                            <br />
                            <div className="container-fluid containerTabla " style={{ background: '#EBF9FF', borderRadius: '10px'}}>
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla ">

                                        <div className='row' style={{ marginTop: '20px' }}>
                                            <div className='col'>
                                                <label className="lblEncabezadosTabla letraTitulos"><strong>Compras relacionadas:</strong></label>
                                            </div>
                                            <div className='col-3'>
                                                <Button className='btnAceptarModal' style={{position: 'relative'}}
                                                onClick={() => this.verModalDetalles()}>
                                                    {"+ Agregar"}
                                                </Button>
                                            </div>
                                        </div>
                                        
                                        <Table hover>
                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.tabla.encabezadosCompras.map((titulo, index) => (
                                                        <th
                                                            className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                {
                                                    this.state.tablaComprasRelacionadas.listacompras.map((item, index) => (
                                                        <tr  style={{ borderTop: 'solid 2px black'}} >
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idcompra}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${formatoNumero2(item.precio)}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${item.descuento ? formatoNumero2(item.descuento) : 0}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${(item.descuento ? formatoNumero2(item.precio-item.descuento) : formatoNumero2(item.precio))}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarCompra(item)}>
                                                                    <FaTrashAlt className="icoBotonesTrash iconbtneliminar"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                
                                                <tr style={{ borderTop: 'solid 2px black'}} >
                                                    <td className="containerSubTablaAA" style={{ color: this.state.colorcontenidotabla }}>
                                                        <b>Totales acumulados</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${ formatoNumero2(this.state.tablaComprasRelacionadas.subtotal)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaComprasRelacionadas.descuento)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaComprasRelacionadas.total)}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />
                            
                            <div className="container-fluid containerTabla " style={{ background: '#EBF9FF', borderRadius: '10px'}}>
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla ">

                                        <div className='row' style={{ marginTop: '20px' }}>
                                            <div className='col'>
                                                <label className="lblEncabezadosTabla letraTitulos"><strong>Selecciona las facturas que aplican para este pago:</strong></label>
                                            </div>
                                        </div>
                                        
                                        <Table hover>
                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.tabla.encabezadosCompras2.map((titulo, index) => (
                                                        <th
                                                            className={(index > 1) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones2[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                {
                                                    this.state.listafacturascompras.map((item, index) => (
                                                        <tr style={{ borderTop: 'solid 2px black'}} onClick={() => this.seleccionarFactura(item)} className={this.state.idslistafacturascomprasSeleccionadas.indexOf(item.idfactura) != -1 ? "trFondoActivoGreen" : ""}>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idcompra}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idfactura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.fechafactura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${formatoNumero2(item.monto)}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.factura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                <a href={item.rutaFactura} download>
                                                                    <div className='divBotonesTrash'>
                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.accion == 3 || this.state.accion == 9 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({accion: 3, resp: undefined})}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }

}
