import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNuevaRequisicion.css';
import { Button } from 'reactstrap';
import { IoMdClose } from 'react-icons/io';
import { BiSearchAlt2 } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { FaCalendar } from 'react-icons/fa'
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloColorError, estiloBtnActualizar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloBtnEditar } from '../../Services/PaletaDeColores';
import ModalFormularioZonasDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioZonasDisponibles';
import ModalFormularioGuardarRequisicion from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioGuardarRequisicion';
import ModalFormularioAsignarProveedor from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioAsignarProveedor';
import Zona from './Zona';
import TablaAbastecimientoStock from './TablaAbastecimientoStock';
import { BsFillCalendarFill } from "react-icons/bs";
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getListaTipoRequisicion, getListaEstatusRequesicion, getInformacionRequesicion, guardarNuevaRequesicion, agregarProductoEmergente,
  getListaZonas, getListaRanchosDisponibles, getListaLotes, getListaTablaCultivos, getLista2Productos, getComboFamiliaProducto, getComboTipoProducto, getComboUnidadMedida, getListaActividades, getListaTipoActividades, buscarPeriodo
} from '../../Services/Api';
import { validarLetrasNumerosEspacios } from '../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import ModalSeleccionAnio from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalSeleccionAnio';
import ModalSeleccionMeses from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalSeleccionMeses';
import ModalSeleccionActividades from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalSeleccionActividades';

import ModalFormularioAgregarDestino from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioAgregarDestino';

import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatusContrasena';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { MdAutoFixHigh } from 'react-icons/md';
registerLocale('es', es)

export default class CatalogoNuevaRequisicion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      plananual: false,
      txtnombrerequisicion: '',
      txttiporequesicion: -1,
      txtfechaestimadacompra: '',
      txtanio: '' + new Date().getFullYear(),
      idperiodo: '',
      txtactividad: undefined,
      txtmes: undefined,
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      redireccionandoarequisicion: false,

      vermsgerrortxtnombrerequisicion: false,
      msgerrortxtnombrerequisicion: "",
      vermsgerrortxttiporequesicion: false,
      msgerrortxttiporequesicion: "",
      vermsgerrortxtfechaestimadacompra: false,
      msgerrortxtfechaestimadacompra: "",

      vermsgerrortxtactividad: false,
      msgerrortxtactividad: "",

      vermsgerrorplananual: false,
      msgerrorplananual: "",


      idrequisicion: -1,
      opcioneditar: false,
      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,

      cargadeinformacion: false,

      listatiporequesicion: [],
      listaestatus: [],
      zonas: [],
      listanuevosproductos: [],
      inforequisicion: {},

      requestbodyrequisicion: {},
      tablasrequestbody: [],
      stockrequestbody: {
        idstock: "0",
        productos: [
        ]
      },
      stock: {
        productos: [
        ]
      },
      stockactivo: false,
      productosenstockvalidos: false,
      productosentablasvalidos: false,

      confirmarenvio: false,
      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Proveedor",
          ""
        ],
        dimensiones: [
          "30%",
          "20%",
          "15%",
          "30%",
          "5%",
        ],
        mindimensiones: [
          "130px",
          "170px",
          "120px",
          "120px",
          "30px",
        ],
        elementos: [
        ],
        total: ""
      },
      productoresumenseleccionado: undefined,
      modalguardar: false,
      modaldestino: false,
      modalasignarproveedor: false,
      modalseleccionanio: false,
      modalmeses: false,
      modalactividades: false,
      anioseleccionado: new Date().getFullYear(),

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,



      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colormsgerror: estiloColorError().msgerror,

      //lista catálogos
      listazonas: [],
      listaranchos: [],
      listalotes: [],
      listatablas: [],
      listaproductos: [],
      listafamiliaproductos: [],
      listatipoproductos: [],
      listaunidadesmedida: [],
      listaactividades: [],
      listatipoactividad: [],
      productosresumeninfo: [],

      fechaestimadacompra: new Date(),
      estatuseditable: false,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (localStorage.getItem('req') == null || localStorage.getItem('req') == undefined) {
      this.setState({ salir: true })
    }

    this.setState({
      opcioneditar: JSON.parse(localStorage.getItem("req")).opcion,
      idrequisicion: JSON.parse(localStorage.getItem("req")).idreq
    })
    var resptiporequesicion = await getListaTipoRequisicion();
    if (this.manejadorLlamadasApis(resptiporequesicion)) {
      return;
    }

    var resplistaestatusrequisicion = await getListaEstatusRequesicion();
    if (this.manejadorLlamadasApis(resplistaestatusrequisicion)) {
      return;
    }

    //************************************************** */
    //Se carga la información necesaria para los modales
    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      return;
    }
    var resplistaranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }

    var resplistalotes = await getListaLotes();
    if (this.manejadorLlamadasApis(resplistalotes)) {
      return;
    }

    var resplistatablas = await getListaTablaCultivos();
    if (this.manejadorLlamadasApis(resplistatablas)) {
      return;
    }

    var respproductos = await getLista2Productos();
    if (this.manejadorLlamadasApis(respproductos)) {
      return;
    }

    var respfamiliaproductos = await getComboFamiliaProducto();
    if (this.manejadorLlamadasApis(respfamiliaproductos)) {
      return;
    }

    var resptipoproductos = await getComboTipoProducto();
    if (this.manejadorLlamadasApis(resptipoproductos)) {
      return;
    }
    var respunidades = await getComboUnidadMedida();
    if (this.manejadorLlamadasApis(respunidades)) {
      return;
    }

    var resplistaactividades = await getListaActividades();
    if (this.manejadorLlamadasApis(resplistaactividades)) {
      return;
    }

    var resplistatipoactividades = await getListaTipoActividades();
    if (this.manejadorLlamadasApis(resplistatipoactividades)) {
      return;
    }
    //************************************************** */
    var respperiodo = await buscarPeriodo("" + this.state.anioseleccionado)
    if (this.manejadorLlamadasApis(respperiodo)) {
      return;
    }
    await this.setState({
      idperiodo: respperiodo.data.id_periodo
    })

    if (this.state.opcioneditar && !this.state.cargadeinformacion) {

      var inforequisicion = await getInformacionRequesicion(this.state.idrequisicion);
      if (this.manejadorLlamadasApis(inforequisicion)) {
        return;
      }
      var stockactivoaux = false;
      let existproduct = inforequisicion.data.stock.hasOwnProperty("productos");
      var stockrequestbodyaux = this.state.stockrequestbody;
      if (existproduct) {
        stockrequestbodyaux.idstock = inforequisicion.data.stock.idstock;
        stockactivoaux = true;
      }
      var [year, month, day] = inforequisicion.data.fechaestimada_compra.split("/");
      var fecha = day + "-" + month + "-" + year;
      await this.setState({
        inforequisicion: inforequisicion.data,
        zonas: inforequisicion.data.zonas,
        stock: inforequisicion.data.stock,
        cargadeinformacion: true,
        stockactivo: stockactivoaux,
        idperiodo: inforequisicion.data.idperiodo,
        productosresumeninfo: inforequisicion.data.Resumen.productos
      })
    }

    await this.setState({
      listatiporequesicion: resptiporequesicion.data,
      listaestatus: resplistaestatusrequisicion.data,
      listazonas: resplistazonas.data,
      listaranchos: resplistaranchos.data,
      listalotes: resplistalotes.data,
      listatablas: resplistatablas.data,
      listaproductos: respproductos.data,
      listafamiliaproductos: respfamiliaproductos.data,
      listatipoproductos: resptipoproductos.data,
      listaunidadesmedida: respunidades.data,
      listaactividades: resplistaactividades.data,
      listatipoactividad: resplistatipoactividades.data,
    })
    this.cambiarFecha({ llave: "fechaestimadacompra", valor: this.state.fechaestimadacompra });
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 200) {
      this.setState({ loadingspinners: false })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return true;
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  calcularResumen = async (listazonasagregadas) => {
    var listazonas = listazonasagregadas;
    var listaproductosresumen = [];
    var tresumen = this.state.tablaresumen;

    var listatablasrequestbody = [];
    for (let index = 0; index < listazonas.length; index++) {
      var listaranchos = listazonas[index].ranchos;

      for (let index = 0; index < listaranchos.length; index++) {
        var listalotes = listaranchos[index].lotes;

        for (let index = 0; index < listalotes.length; index++) {
          var listatablas = listalotes[index].tablas;

          for (let index = 0; index < listatablas.length; index++) {
            var listaproductos = listatablas[index].productos;

            var tabla = listatablas[index];
            var tablaaux = {
              "idtabla": tabla.idtabla,
              "productos": []
            }

            var productos = [];
            for (let index = 0; index < listaproductos.length; index++) {
              var producto = listaproductos[index];

              if (producto.cantidad != "" && parseInt(producto.cantidad, 10) > 0) {
                var indexproducto = listaproductosresumen.findIndex((item => item.idproducto == producto.idproducto));
                if (indexproducto > -1) {
                  var prodcutoenresumen = listaproductosresumen[indexproducto];

                  var productoaux = {
                    "idproducto": producto.idproducto,
                    "Producto": producto.Producto,
                    "Unidadmedida": producto.Unidadmedida,
                    "cantidad": parseFloat(producto.cantidad) + parseFloat(prodcutoenresumen.cantidad),
                    //  "precio": parseFloat(producto.precio) + parseFloat(prodcutoenresumen.precio),
                    "proveedorsugerido": "Sin proveedor",
                    "idproveedorsugerido": 0
                  };

                  var idproductoaux = "" + producto.idproducto;
                  if (idproductoaux.toString().includes("nuevo")) {
                    productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
                  }

                  listaproductosresumen[indexproducto] = productoaux;
                } else {
                  var productoaux = {
                    "idproducto": producto.idproducto,
                    "Producto": producto.Producto,
                    "Unidadmedida": producto.Unidadmedida,
                    "cantidad": producto.cantidad,
                    //  "precio": producto.precio,
                    "proveedorsugerido": "Sin proveedor",
                    "idproveedorsugerido": 0
                  };
                  var idproductoaux = "" + producto.idproducto;
                  if (idproductoaux.includes("nuevo")) {
                    productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
                  }

                  listaproductosresumen.push(productoaux)
                }

                var productodetabla = {
                  "idproducto": producto.idproducto,
                  "cantidad": producto.cantidad,
                }
                productos.push(productodetabla)
              }
            }

            tablaaux.productos = productos;
            listatablasrequestbody.push(tablaaux);
          }
        }
      }
    }
    var productosstock = [];
    let existproduct = this.state.stock.hasOwnProperty("productos");
    if (existproduct) {
      productosstock = this.state.stock.productos;
    }

    var productosstockrqb = [];
    for (let index = 0; index < productosstock.length; index++) {
      var producto = productosstock[index];

      if (producto.cantidad != "" && parseInt(producto.cantidad, 10) > 0) {
        var indexproducto = listaproductosresumen.findIndex((item => item.idproducto == producto.idproducto));
        if (indexproducto > -1) {
          var prodcutoenresumen = listaproductosresumen[indexproducto];

          var productoaux = {
            "idproducto": producto.idproducto,
            "Producto": producto.Producto,
            "Unidadmedida": producto.Unidadmedida,
            "cantidad": parseFloat(producto.cantidad) + parseFloat(prodcutoenresumen.cantidad),
            // "precio": parseFloat(producto.precio) + parseFloat(prodcutoenresumen.precio),
            "proveedorsugerido": "Sin proveedor",
            "idproveedorsugerido": 0
          };

          var idproductoaux = "" + producto.idproducto;
          if (idproductoaux.toString().includes("nuevo")) {
            productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
          }

          listaproductosresumen[indexproducto] = productoaux;
        } else {
          var productoaux = {
            "idproducto": producto.idproducto,
            "Producto": producto.Producto,
            "Unidadmedida": producto.Unidadmedida,
            "cantidad": producto.cantidad,
            //  "precio": producto.precio,
            "proveedorsugerido": "Sin proveedor",
            "idproveedorsugerido": 0
          };
          var idproductoaux = "" + producto.idproducto;
          if (idproductoaux.includes("nuevo")) {
            productoaux.idcatalogo_unidadmedida = producto.Idunidadmedida
          }

          listaproductosresumen.push(productoaux)
        }
        var productodestock = {
          "idproducto": producto.idproducto,
          "cantidad": producto.cantidad,
        }
        productosstockrqb.push(productodestock)
      }
    }

    tresumen.elementos = listaproductosresumen;

    tresumen.elementos = await this.actualizarProveedoresResumen(listaproductosresumen);

    var stockrequestbodyaux = this.state.stockrequestbody;
    stockrequestbodyaux.productos = productosstockrqb;
    await this.setState({
      tablaresumen: tresumen,
      tablasrequestbody: listatablasrequestbody,
      stockrequestbody: stockrequestbodyaux
    });

  }

  validarDatos = async () => {
    var validacionescorrectas = true;
    this.setState({ vermsgerrortxtnombrerequisicion: false })
    this.setState({ vermsgerrortxttiporequesicion: false })
    this.setState({ vermsgerrortxtfechaestimadacompra: false })
    this.setState({ vermsgerrorplananual: false })
    this.setState({ vermsgerrortxtactividad: false })

    if (!this.state.opcioneditar) {
      let longitudcincuenta = { longitudminima: 1, longitudmaxima: 100 };
      let nombre = validarLetrasNumerosEspacios(this.state.txtnombrerequisicion.trim(), longitudcincuenta);

      if (nombre.codigo == "invalid") {
        this.setState({ msgerrortxtnombrerequisicion: nombre.mensaje })
        this.setState({ vermsgerrortxtnombrerequisicion: true })
        validacionescorrectas = false;
      }

      if (this.state.txttiporequesicion == -1 || this.state.txttiporequesicion == "seleccionar") {
        this.setState({ msgerrortxttiporequesicion: "Selecciona un tipo de requisición" })
        this.setState({ vermsgerrortxttiporequesicion: true })
        validacionescorrectas = false;
      }

      if (this.state.txtfechaestimadacompra == "" || this.state.txtfechaestimadacompra == null) {
        this.setState({ msgerrortxtfechaestimadacompra: "Selecciona una fecha" })
        this.setState({ vermsgerrortxtfechaestimadacompra: true })
        validacionescorrectas = false;
      }

      if (this.state.plananual) {
        if (this.state.txtanio == "") {
          validacionescorrectas = false;
          this.setState({ msgerrorplananual: "Estos campos son obligatorios" })
          this.setState({ vermsgerrorplananual: true })
        }

        if (this.state.txtmes == undefined) {
          validacionescorrectas = false;
          this.setState({ msgerrorplananual: "Estos campos son obligatorios" })
          this.setState({ vermsgerrorplananual: true })
        }
        if (this.state.txtactividad == undefined) {
          validacionescorrectas = false;
          this.setState({ msgerrorplananual: "Estos campos son obligatorios" })
          this.setState({ vermsgerrorplananual: true })
        }
      }


      if (this.state.txtactividad == undefined) {
        validacionescorrectas = false;
        this.setState({ msgerrortxtactividad: "Selecciona una actividad" })
        this.setState({ vermsgerrortxtactividad: true })
      }



    }

    if (this.state.tablaresumen.elementos.length < 1) {
      validacionescorrectas = false;
    }



    var productosvalidos = await this.validarProductos();

    if (!this.state.productosentablasvalidos) {
      validacionescorrectas = false;
    }

    if (this.state.stockactivo && !this.state.productosenstockvalidos) {
      validacionescorrectas = false;
    }

    if (!this.state.stockactivo && !this.state.productosentablasvalidos) {
      validacionescorrectas = false;
    }

    if (this.state.zonas.length < 1 && (this.state.productosenstockvalidos && this.state.stockactivo)) {
      validacionescorrectas = true;
    }


    if (validacionescorrectas && this.state.productosenstockvalidos) {

      var productosresumen = this.obtenerListaTablaResumen();
      var requestbody = {};
      let existeproductostock = this.state.stockrequestbody.hasOwnProperty("productos");

      if (existeproductostock && this.state.stockrequestbody.productos.length > 0) {
        var listaproductosstock = this.obtenerListaProductosStockResumen();
        var stockrequestbodyaux = this.state.stockrequestbody;
        stockrequestbodyaux.productos = listaproductosstock;
        requestbody = {
          idcatalogo_requisicion: this.state.opcioneditar ? this.state.inforequisicion.idcatalogo_requisicion : 0,
          nombre: this.state.opcioneditar ? this.state.inforequisicion.nombre : this.state.txtnombrerequisicion,
          idtiporequisicion: this.state.opcioneditar ? this.state.inforequisicion.idtiporequisicion : this.state.txttiporequesicion,
          fechaestimada_compra: this.state.opcioneditar ? this.state.inforequisicion.fechaestimada_compra : this.state.txtfechaestimadacompra,
          idestatusrequisicion: 1,
          solicitarautorizacion: 1,
          tablas: this.state.tablasrequestbody,
          stock: this.state.stockrequestbody,
          Resumen: {
            productos: productosresumen
          }
        }
      } else {
        requestbody = {
          idcatalogo_requisicion: this.state.opcioneditar ? this.state.inforequisicion.idcatalogo_requisicion : 0,
          nombre: this.state.opcioneditar ? this.state.inforequisicion.nombre : this.state.txtnombrerequisicion,
          idtiporequisicion: this.state.opcioneditar ? this.state.inforequisicion.idtiporequisicion : this.state.txttiporequesicion,
          fechaestimada_compra: this.state.opcioneditar ? this.state.inforequisicion.fechaestimada_compra : this.state.txtfechaestimadacompra,
          idestatusrequisicion: 1,
          solicitarautorizacion: 1,
          tablas: this.state.tablasrequestbody,
          stock: [],
          Resumen: {
            productos: productosresumen
          }
        }
      }
      requestbody.idactividad = this.state.opcioneditar ? this.state.inforequisicion.idactividad : this.state.txtactividad.idactividad;

      if (this.state.plananual) {
        if (this.state.idperiodo != "") {
          requestbody.idperiodo = this.state.idperiodo;
        } else {
          requestbody.periodo = this.state.anioseleccionado;
        }
        requestbody.id_mes = this.state.txtmes.id_mes;
      }
      if (this.state.opcioneditar) {
        if (this.state.inforequisicion.idperiodo != "") {
          requestbody.idperiodo = this.state.inforequisicion.idperiodo;
        }
        if (this.state.inforequisicion.id_mes != "") {
          requestbody.id_mes = this.state.inforequisicion.id_mes;
        }

      }


      await this.setState({ requestbodyrequisicion: requestbody });
      this.verModalGuardar({ opcion: "1" })
    } else {
      toast.info("Datos incompletos", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }

  }


  validarProductos = async () => {
    var listazonaauxiliar = [...this.state.zonas];
    var productosvalidos = true;
    await this.setState({
      productosenstockvalidos: true,
      productosentablasvalidos: true
    })

    if (this.state.stockactivo) {
      if (this.state.stock.productos.length > 0) {
        for (let index = 0; index < this.state.stock.productos.length; index++) {
          var producto = this.state.stock.productos[index];

          if (producto.cantidad <= 0 || producto.cantidad == "") {
            await this.setState({
              productosenstockvalidos: false
            })
            return productosvalidos = false;
          }

        }
      } else {
        await this.setState({
          productosenstockvalidos: false
        })
        return productosvalidos = false;
      }
    }

    if (listazonaauxiliar.length > 0) {
      for (let index = 0; index < listazonaauxiliar.length; index++) {
        var zona = listazonaauxiliar[index];
        if (zona.ranchos.length > 0) {
          for (let index = 0; index < zona.ranchos.length; index++) {
            var rancho = zona.ranchos[index];
            if (rancho.lotes.length > 0) {
              for (let index = 0; index < rancho.lotes.length; index++) {
                var lote = rancho.lotes[index];
                if (lote.tablas.length > 0) {
                  for (let index = 0; index < lote.tablas.length; index++) {
                    var tabla = lote.tablas[index];
                    if (tabla.productos.length > 0) {
                      for (let index = 0; index < tabla.productos.length; index++) {
                        var producto = tabla.productos[index];

                        if (producto.cantidad <= 0 || producto.cantidad == "") {
                          await this.setState({
                            productosentablasvalidos: false
                          })
                          return productosvalidos = false;
                        }

                      }
                    } else {
                      await this.setState({
                        productosentablasvalidos: false
                      })
                      return productosvalidos = false;
                    }
                  }
                } else {
                  await this.setState({
                    productosentablasvalidos: false
                  })
                  return productosvalidos = false;
                }
              }
            } else {
              await this.setState({
                productosentablasvalidos: false
              })
              return productosvalidos = false;
            }
          }
        } else {
          await this.setState({
            productosentablasvalidos: false
          })
          return productosvalidos = false;
        }

      }
    } else {
      await this.setState({
        productosentablasvalidos: false
      })
      return productosvalidos = false;
    }

    return productosvalidos;

  }

  obtenerListaTablaResumen() {
    var listaproductos = [];
    this.state.tablaresumen.elementos.forEach(producto => {
      var productoaux = {
        "idproducto": producto.idproducto,
        "cantidad": producto.cantidad,
        "idproveedorsugerido": producto.idproveedorsugerido
      }
      listaproductos.push(productoaux)
    });
    return listaproductos;
  }
  obtenerListaProductosStockResumen() {
    var listaproductos = [];
    this.state.stockrequestbody.productos.forEach(producto => {
      var productoaux = {
        "idproducto": producto.idproducto,
        "cantidad": producto.cantidad,
      }
      listaproductos.push(productoaux)
    });
    return listaproductos;
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Zonas disponibles', accionmodal: 1 })
    }

    this.setState({ modaldetalles: true });
  }
  verModalAsignarProveedor = (ban) => {
    this.setState({
      productoresumenseleccionado: JSON.parse(JSON.stringify(ban.contenido)),
      modalasignarproveedor: true
    });

  }

  verModalAgregar = () => {
    this.setState({ modaldestino: true })
  }

  verModalGuardar = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Guardar requisición', accionmodal: 1 })
    }

    this.setState({ modalguardar: true });
  }

  cerrarModal = (listazonas) => {
    var listazonasagregadasaux = this.state.zonas;
    listazonasagregadasaux = listazonasagregadasaux.concat(listazonas)
    this.setState({
      modaldetalles: !this.state.modaldetalles,
      zonas: listazonasagregadasaux
    })

  }

  cerrarModalAgregar = (accion) => {
    if (accion == 1) {
      //Agregar zona
      this.setState({
        titulomodal: 'Zonas disponibles',
        accionmodal: 1,
        modaldetalles: true
      })
    } else if (accion == 2) {
      //Agregar Stock
      this.setState({
        stockactivo: true
      })
    }

    this.setState({
      modaldestino: !this.state.modaldestino
    })

  }

  cerrarModalAsignarProveedor = (datos) => {
    if (datos != 0) {
      var tablaresumenaux = this.state.tablaresumen;
      var indextabla = tablaresumenaux.elementos.findIndex((producto => producto.idproducto == this.state.productoresumenseleccionado.idproducto));
      if (indextabla > -1) {
        tablaresumenaux.elementos[indextabla].proveedorsugerido = datos.nombreproveedor;
        tablaresumenaux.elementos[indextabla].idproveedorsugerido = datos.idproveedorsugerido;


        var productosresumeninfoaux = this.state.productosresumeninfo;
        var index = productosresumeninfoaux.findIndex((producto => producto.idproducto == this.state.productoresumenseleccionado.idproducto));
        if (index > -1) {
          productosresumeninfoaux[index].proveedorsugerido = datos.nombreproveedor;
          productosresumeninfoaux[index].idproveedorsugerido = datos.idproveedorsugerido;
        } else {
          productosresumeninfoaux.push(tablaresumenaux.elementos[indextabla])
        }
        this.setState({
          productosresumeninfo: productosresumeninfoaux
        })
      }




    }

    this.setState({
      modalasignarproveedor: !this.state.modalasignarproveedor
    })

  }

  cancelarFormulario = async () => {
    if (this.state.opcioneditar) {
      this.setState({ loadingspinners: true })
      var inforequisicion = await getInformacionRequesicion(this.state.idrequisicion);
      if (this.manejadorLlamadasApis(inforequisicion)) {
        this.setState({ loadingspinners: false })
        return;
      }
      var stockactivoaux = false;
      let existproduct = inforequisicion.data.stock.hasOwnProperty("productos");
      var stockrequestbodyaux = this.state.stockrequestbody;
      if (existproduct) {
        stockrequestbodyaux.idstock = inforequisicion.data.stock.idstock;
        stockactivoaux = true;
      }

      await this.setState({
        inforequisicion: inforequisicion.data,
        zonas: inforequisicion.data.zonas,
        stock: inforequisicion.data.stock,
        cargadeinformacion: true,
        stockactivo: stockactivoaux,
        productosresumeninfo: inforequisicion.data.Resumen.productos,
        loadingspinners: false
      })

      await this.calcularResumen(inforequisicion.data.zonas);
      toast.info("Se borraron los cambios recientes", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  cerrarModalGuardar = async (datos) => {

    var actualizacionids = true;
    this.setState({
      modalguardar: !this.state.modalguardar, loadingspinners: false
    })

    var request = this.state.requestbodyrequisicion;
    if (datos != 0) {
      request.solicitarautorizacion = datos.solicitarautorizacion;
      request.idestatusrequisicion = datos.idestatusrequisicion;

      this.activarSpinner(true);

      for (let index = 0; index < this.state.tablaresumen.elementos.length; index++) {
        const producto = this.state.tablaresumen.elementos[index];

        if (producto.idproducto.toString().includes("nuevo")) {
          var datanuevoproducto = {
            "idcatalogoproducto": 0,
            "nombrecomercial": producto.Producto,
            "idcatalogo_unidadmedida": producto.idcatalogo_unidadmedida
          };

          var dataproducto = await agregarProductoEmergente(datanuevoproducto)

          if (dataproducto.code == 1000 || dataproducto == -1) {
            this.activarSpinner(false);
            actualizacionids = false;
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            break;
          } else if (dataproducto.code == 400) {
            this.activarSpinner(false);
            actualizacionids = false;
            toast.error(dataproducto.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
          else if (dataproducto.code == 200 || dataproducto.code == 300) {
            this.activarSpinner(false);
            actualizacionids = false;
            toast.error(dataproducto.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            break;
          } else {
           
            var indexproductoresumen = request.Resumen.productos.findIndex((item => item.idproducto == producto.idproducto));
            //actualizar ids de productos en resumen
            if (indexproductoresumen > -1) {
              request.Resumen.productos[indexproductoresumen].idproducto = dataproducto.data.idcatalogo_producto;
            }

            //actualizar ids de productos en tablas
            request.tablas.forEach(tabla => {
              var indexproducto = tabla.productos.findIndex((item => item.idproducto == producto.idproducto));
              if (indexproducto > -1) {
                tabla.productos[indexproducto].idproducto = dataproducto.data.idcatalogo_producto;
              }
            });

            
            let existestock = request.hasOwnProperty("stock");
            if (existestock) {
              var indexproductostock = request.stock.productos.findIndex((item => item.idproducto == producto.idproducto));
              if (indexproductostock > -1) {
                request.stock.productos[indexproductostock].idproducto = dataproducto.data.idcatalogo_producto;
              }
            }

          }
        }
      }

      if (actualizacionids) {
        var resp = await guardarNuevaRequesicion(this.state.requestbodyrequisicion);
        if (resp.code == 1000 || resp == -1) {
          this.activarSpinner(false);
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
        }
        else if (resp.code == 200 || resp.code == 300) {
          this.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
        } else if (resp.code == 400) {
          this.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        } else if (resp.code == 0) {
         

          this.setState({
            idrequisicion: resp.data.idcatalogo_requisicion,
            opcioneditar: true
          })

          var inforequisicion = await getInformacionRequesicion(resp.data.idcatalogo_requisicion);
          if (this.manejadorLlamadasApis(inforequisicion)) {
            return;
          }
          var stockactivoaux = false;
          let existproduct = inforequisicion.data.stock.hasOwnProperty("productos");
          var stockrequestbodyaux = this.state.stockrequestbody;
          if (existproduct) {
            stockrequestbodyaux.idstock = inforequisicion.data.stock.idstock;
            stockactivoaux = true;
          }

          this.setState({
            inforequisicion: inforequisicion.data,
            zonas: inforequisicion.data.zonas,
            stock: inforequisicion.data.stock,
            cargadeinformacion: true,
            stockactivo: stockactivoaux,
            productosresumeninfo: inforequisicion.data.Resumen.productos
          })

          this.activarSpinner(false);

          toast.success("Requisición guardada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })

          setTimeout(() => {
            this.setState({redireccionandoarequisicion:true})
          }, 1000);
        


        }
      } else {
        this.activarSpinner(false);
        toast.error("Error al guardar requisición", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      }

    }

  }









  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados
    var listazonas = this.state.zonas.filter(zona => zona.idzona != elementoeliminado.idzona);
    this.calcularResumen(listazonas);

    this.setState({
      zonas: listazonas,
    })
  }

  eliminarElementoStock = async () => {
    var stock = this.state.stock;
    stock.productos = []

    this.calcularResumen(this.state.zonas);

    this.setState({
      stock: stock,
      stockactivo: false
    })
  }

  actualizarContenido = async (zona) => {
    var listazonas = this.state.zonas;

    var index = listazonas.findIndex((obj => obj.idzona == zona.idzona));
    if (index > -1) {
      listazonas[index] = zona;
    }

    this.calcularResumen(listazonas);

    this.setState({
      zonas: listazonas,
    })
  }

  actualizarContenidoStock = async (productos) => {
    var stock = this.state.stock;
    stock.productos = productos
    this.calcularResumen(this.state.zonas);

    await this.setState({
      stock: stock
    })
  }

  actualizarListaNuevosProductos = async (nuevoproducto) => {
    var auxnuevosproductos = this.state.listanuevosproductos;
    var indexproducto = auxnuevosproductos.findIndex((item => item.idproducto == nuevoproducto.idproducto));
    if (indexproducto == -1) {
      auxnuevosproductos.push(nuevoproducto);
      await this.setState({
        listanuevosproductos: auxnuevosproductos
      })
    }
  }

  actualizarProveedoresResumen = async (listaproductosresumen) => {
    var productostablaresumen = listaproductosresumen;
    var productosresumeninfo = this.state.productosresumeninfo;
    for (let index = 0; index < productostablaresumen.length; index++) {
      var element = productostablaresumen[index];
      var indexproducto = productosresumeninfo.findIndex((item => item.idproducto == element.idproducto));
      if (indexproducto != -1) {
        element.proveedorsugerido = productosresumeninfo[indexproducto].proveedorsugerido;
        element.idproveedorsugerido = productosresumeninfo[indexproducto].idproveedorsugerido == "" ? 0 : productosresumeninfo[indexproducto].idproveedorsugerido;
      }
    }
    return productostablaresumen;
  }

  escribir = (prop) => (event) => {

    this.setState({ [prop]: event.target.value });

  }

  regresaFecha = (fecha) => {
    var fechaf = "";
    if (fecha != null) {
      var [day, month, year] = fecha.split("/");

      if (day != undefined && month != undefined && year != undefined) {
        fechaf = `${year}-${month}-${day}`
        return fechaf;
      } else {
        fechaf = fecha;
      }
    }

    return fechaf;
  }

  borrarBusqueda = () => {
    this.setState({
      txtnombrerequisicion: '',
    })
  }

  seleccionarTipoRquesicion(tipo) {
    this.setState({
      txttiporequesicion: tipo,
    })
  }

  seleccionarEstatus(estatus) {
    this.setState({
      txtestatus: estatus,
    })
  }

  verModalSeleccionAnio = () => {
    this.setState({
      titulomodal: 'Selección de año',
      accionmodal: 1,
      modalseleccionanio: true
    })

  }

  cerrarModalSeleccionAnio = async ({ accion, anioseleccionado }) => {
    this.setState({ modalseleccionanio: !this.state.modalseleccionanio })
    if (accion != 0) {
      if (this.state.anioseleccionado != anioseleccionado) {
        this.setState({
          txtanio: anioseleccionado,
          anioseleccionado: anioseleccionado
        })
      }
      var respperiodo = await buscarPeriodo("" + anioseleccionado);
      if (this.manejadorLlamadasApis(respperiodo)) {
        return;
      }
      await this.setState({
        idperiodo: respperiodo.data.id_periodo
      })
    }


  }


  verModalMeses = (ban) => {

    this.setState({ titulomodal: 'Selecciona un mes', accionmodal: 1 })
    this.setState({ modalmeses: true });
  }

  cerrarModalMeses = async (mes) => {
    this.setState({ modalmeses: false });

    if (mes != 0) {
      this.setState({ txtmes: mes });
    }


  }


  verModalActividades = () => {

    this.setState({ titulomodal: 'Agregar actividades', accionmodal: 1, modalactividades: true })
  }

  cerrarModalActividades = (actividad) => {
    this.setState({
      modalactividades: !this.state.modalactividades,
    })

    if (actividad != 0) {
      this.setState({
        txtactividad: actividad
      })
    }

  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: day + '/' + month + '/' + year })

    // this.consultarCatalogoCompra(['txt' + item.llave], year + '-' + month + '-' + day)
  }


  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  verModalFormularioAccionEstatus = (ban) => {
    const aux = this.state.inforequisicion
    //console.log(aux)
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.idestatusrequisicion*/ }
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
    else if (ban.id == 2) {
      this.setState({ titulomodal: 'Aprobar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
  }

  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      const aux = this.state.inforequisicion
     // console.log(aux);
      var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion, idestatusrequisicion: 3 /* id de autorizar */ }
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = async (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })


    if (opciones.accion == 1) {
      const aux = this.state.inforequisicion

      await this.setState({
        idrequisicion: aux.idcatalogo_requisicion,
        opcioneditar: true
      })

      var inforequisicion = await getInformacionRequesicion(aux.idcatalogo_requisicion);
      if (this.manejadorLlamadasApis(inforequisicion)) {
        return;
      }
      var stockactivoaux = false;
      let existproduct = inforequisicion.data.stock.hasOwnProperty("productos");
      var stockrequestbodyaux = this.state.stockrequestbody;
      if (existproduct) {
        stockrequestbodyaux.idstock = inforequisicion.data.stock.idstock;
        stockactivoaux = true;
      }

      await this.setState({
        inforequisicion: inforequisicion.data,
        zonas: inforequisicion.data.zonas,
        stock: inforequisicion.data.stock,
        cargadeinformacion: true,
        stockactivo: stockactivoaux,
        productosresumeninfo: inforequisicion.data.Resumen.productos
      })

      toast.success("Requisición guardada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })

      setTimeout(() => {
         this.setState({redireccionandoarequisicion:true})
      }, 1000);
     

    }
  }

  cerrarModalFormularioAccionEstatus = (opciones) => {
    this.setState({ modalaccionestatus: !this.state.modalaccionestatus })
    if (opciones.accion == 1) {
      this.verModalFormularioAccionEstatusContrasena(opciones)
    }
    else if (opciones.accion == 2) {
      this.verModalFormularioAccionEstatusContrasena({ id: 2, item: opciones.item })
    }
  }










  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionandoarequisicion) ? (<Acciones bandera={'irarequisicion'} />) : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioZonasDisponibles titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal}
            show={this.state.modaldetalles} accion={this.state.accionmodal} listazonasagregadas={this.state.zonas} listazonas={this.state.listazonas} />
        ) : null}

        {(this.state.modalguardar == true) ? (
          <ModalFormularioGuardarRequisicion titulomodal={this.state.titulomodal} cerrarModalGuardar={this.cerrarModalGuardar}
            activarSpinner={this.activarSpinner}
            show={this.state.modalguardar} accion={this.state.accionmodal} listazonasagregadas={this.state.zonas} />
        ) : null}

        {(this.state.modaldestino) ? (
          <ModalFormularioAgregarDestino titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalAgregar}
            show={this.state.modaldestino} accion={this.state.accionmodal} listazonasagregadas={this.state.zonas}
            stockactivo={this.state.stockactivo} />
        ) : null}

        {(this.state.modalasignarproveedor == true) ? (
          <ModalFormularioAsignarProveedor titulomodal={this.state.titulomodal} cerrarModalAsignarProveedor={this.cerrarModalAsignarProveedor}
            activarSpinner={this.activarSpinner}
            show={this.state.modalasignarproveedor} accion={this.state.accionmodal} producto={this.state.productoresumenseleccionado} />
        ) : null}

        {this.state.modalseleccionanio ? (
          <ModalSeleccionAnio titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionAnio} show={this.state.modalseleccionanio}
            accion={this.state.accionmodal} setLoadingSpinner={this.activarSpinner} anioseleccionado={this.state.anioseleccionado}
          />
        ) : null}

        {this.state.modalmeses ? (
          <ModalSeleccionMeses titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMeses}
            show={this.state.modalmeses} accion={this.state.accionmodal}
            activarSpinner={this.activarSpinner}
            messeleccionado={this.state.txtmes}

          />
        ) : null}
        {(this.state.modalactividades == true) ? (
          <ModalSeleccionActividades titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalActividades}
            show={this.state.modalactividades} accion={this.state.accionmodal}
            activarSpinner={this.activarSpinner}
            listaactividades={JSON.parse(JSON.stringify(this.state.listaactividades))}
            listatipoactividad={JSON.parse(JSON.stringify(this.state.listatipoactividad))}
            actividadseleccionada={this.state.txtactividad}

          />
        ) : null}



        <Navbar />
        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        {(this.state.opcioneditar) ?
          (<div className="container-fluid" >
            <div className="container-fluid anchoMaximo ">

              <div className='row paddingEncabezados align-items-end contenedorEncabezado'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.inforequisicion.nombre}</label>
                </div>

                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}> {this.state.inforequisicion.periodo != "" ? "Plan anual " + this.state.inforequisicion.periodo + ", " + this.state.inforequisicion.mes : ""} </label>

                </div>
                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'></div>



                <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.actividad}</label>
                </div>

                <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                </div>

                <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.tiporequisicion}</label>
                </div>

                <div className='col-5 col-sm-5 col-md-5 col-lg-5 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.fechaestimada_compra}</label>
                </div>


                <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.solicitante}</label>
                </div>

                <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.fechacreacion}</label>
                </div>


                <div className='col-7 col-sm-7 col-md-7 col-lg-7 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                  <label className='lblTexto letraTitulos' >{this.state.inforequisicion.Estatusrequisicion}</label>

                </div>

                <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                  <label className='lblTexto letraTitulos' >
                    {this.state.inforequisicion.fechaactualizacion ? this.state.inforequisicion.fechaactualizacion : this.state.inforequisicion.fechacreacion}
                  </label>
                </div>

                {
                  this.state.inforequisicion.comentario != "" ?
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-2 pr-2 seccionAviso text-center'>
                      <label className='lblTexto letraTitulos text-center'>{this.state.inforequisicion.comentario}</label>
                    </div> : null
                }

              </div>

              <div className='row paddingEncabezados align-items-end '>
                <div className='col containerSubTabla'></div>
                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <button className='btnNuevo letraNormal'
                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    onClick={this.verModalAgregar}>+ Agregar</button>
                </div>
              </div>
            </div>

          </div>)
          : (<div className="container-fluid" >
            <div className="container-fluid anchoMaximo ">

              <div className='row paddingEncabezados align-items-end contenedorEncabezado d-flex'>

                <br />

                <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-12 containerSubTabla paddingBottomEncabezados contenedorEncabezado'>
                  <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Nueva requisición de agroquímico</label>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 checkPlanAnual">
                  <div className='' onClick={() => this.setState({ plananual: !this.state.plananual })}>
                    <input type="checkbox" checked={this.state.plananual} /> Plan anual
                  </div>

                </div>
                {
                  this.state.plananual ?
                    <div className='row'>
                      <div className="col-auto customBorderRadius paddingBottomEncabezados d-flex"
                        style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}
                      >
                        <div className='col-auto '>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Año</label>
                          <label className='lblTexto letraTitulos'>{this.state.txtanio}</label>
                        </div>

                        <div className='col-auto ' style={{ padding: '0px 12px 0px 12px' }}>
                          <button className="btnDateInput letraNormal"
                            style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
                            onClick={this.verModalSeleccionAnio}
                          >
                            <FaCalendar className="icoBotones" />
                          </button>
                        </div>
                      </div>


                      <div className='col-auto paddingBottomEncabezados customBorderRadius' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }} >
                        <div className='col-auto ' onClick={this.verModalActividades}>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Actividad</label>
                          <label className='lblTexto letraTitulos'>{this.state.txtactividad != undefined ? this.state.txtactividad.actividad : "Selecciona actividad"}</label>
                          <BiSearchAlt2 className='icoBuscar' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                        </div>
                      </div>

                      <div className="col-auto customBorderRadius paddingBottomEncabezados d-flex" style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }} >
                        <div className='col-auto '>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Mes</label>
                          <label className='lblTexto letraTitulos'>{this.state.txtmes != undefined ? this.state.txtmes.mes : ""}</label>
                        </div>

                        <div className='col-auto ' style={{ padding: '0px 12px 0px 12px' }}>
                          <button className="btnDateInput letraNormal"
                            style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
                            onClick={this.verModalMeses}
                          >
                            <FaCalendar className="icoBotones" />
                          </button>
                        </div>
                      </div>
                    </div> : null
                }
                {this.state.vermsgerrorplananual ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrorplananual}</p> : null}

                <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezados'>

                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Nombre</label>
                  <input id='Search' type="search" onChange={this.escribir("txtnombrerequisicion")} value={this.state.txtnombrerequisicion}
                    style={{ color: this.state.colortxtbuscar }}
                    className="txtBuscador letraNormal inputBasico"
                    placeholder=""></input>
                  {this.state.txtnombrerequisicion ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }}
                    onClick={this.borrarBusqueda} />) : null}

                  {this.state.vermsgerrortxtnombrerequisicion ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombrerequisicion}</p> : null}
                </div>

                {
                  !this.state.plananual ?
                    <div className='col-12 col-sm-12 col-md-12 '>
                      <div className=' col-sm-12 col-md-6 col-lg-6 col-xl-6 paddingBottomEncabezados customBorderRadius2' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }} >
                        <div className='col-auto ' onClick={this.verModalActividades}>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Actividad</label>
                          <label className='w-75 lblTexto letraTitulos'>{this.state.txtactividad != undefined ? this.state.txtactividad.actividad : "Selecciona actividad"}</label>
                          <BiSearchAlt2 className='btnFloatRight icoBuscar' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                        </div>

                      </div>
                      {this.state.vermsgerrortxtactividad ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtactividad}</p> : null}
                    </div>
                    : null
                }

                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <div className='divComboRequisicion' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Tipo requisición:</label>
                    <div className="selectBoxRequisición ">
                      <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                        onChange={(event) => this.seleccionarTipoRquesicion(event.target.value)}>
                        {this.state.txttiporequesicion == "seleccionar" ?
                          <option selected="true" value='seleccionar'>Seleccionar</option> : <option value='seleccionar'>Seleccionar</option>}
                        {this.state.listatiporequesicion.map((i, index) => (
                          <option value={i.idtipo_requisicion} key={"tipo_" + index}  >{i.tipo} </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.vermsgerrortxttiporequesicion ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttiporequesicion}</p> : null}
                </div>


                <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                  <div className='contenedorFechaR' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                    <div className='subcontenedorIconoCalendarioReq'>
                      <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                    </div>
                    <div className='w-100 d-flex'>
                      <div className=' col-sm-6 col-md-6'>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha estimada de entrega:</label>
                      </div>
                      <div className=' col-sm-6 col-md-6' style={{ marginLeft: '10px' }}>
                        <div className='w-100 col-auto' style={{ margin: '0px', padding: '0px' }}>
                          <DatePicker
                            className='float-right'
                            selected={this.state.fechaestimadacompra}
                            onChange={(date) => this.cambiarFecha({ llave: "fechaestimadacompra", valor: date })}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={
                              <DateButton />
                            }
                          />
                        </div>
                      </div>
                    </div>


                    {/*<input className='btnSinBorde letraNormal'
                      type={'date'} value={this.regresaFecha(this.state.txtfechaestimadacompra)} onChange={this.escribir("txtfechaestimadacompra")}></input> */}
                  </div>
                  {this.state.vermsgerrortxtfechaestimadacompra ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfechaestimadacompra}</p> : null}
                </div>


              </div>
              <div className='row paddingEncabezados align-items-end '>
                <div className='col containerSubTabla'></div>

                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <button className='btnNuevo letraNormal'
                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                    onClick={this.verModalAgregar}>+ Agregar</button>
                </div>

              </div>
            </div>

          </div>)
        }


        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <div className="container-fluid">
          <div className="container-fluid anchoMaximo">
            <div className="container-fluid">
              {this.state.zonas.length > 0 ?
                this.state.zonas.map((item, index) => (
                  <Zona key={"zona" + item.idzona} itemzona={item} actualizarContenido={this.actualizarContenido}
                    eliminarElemento={this.eliminarElemento}
                    actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                    opcioneditar={this.state.opcioneditar} accion={this.state.opcioneditar ? 2 : 1}
                    listaranchos={this.state.listaranchos}
                    listalotes={this.state.listalotes}
                    listatablas={this.state.listatablas}
                    listaproductos={this.state.listaproductos}
                    listafamiliaproductos={this.state.listafamiliaproductos}
                    listatipoproductos={this.state.listatipoproductos}
                    listaunidadesmedida={this.state.listaunidadesmedida}
                    listanuevosproductos={this.state.listanuevosproductos}
                  />
                ))
                : <div></div>
              }
            </div>

            <br />
            <div className="container-fluid">
              {this.state.stockactivo ?
                <div className='spacePaddingBottom'>
                  <TablaAbastecimientoStock key={"tabla_stock"} accion={this.state.accion}
                    itemtabla={this.state.stock.hasOwnProperty("productos") ? this.state.stock : { productos: [] }}
                    actualizarContenidoStock={this.actualizarContenidoStock}
                    actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                    eliminarElementoStock={this.eliminarElementoStock}
                    listaproductos={this.state.listaproductos}
                    listafamiliaproductos={this.state.listafamiliaproductos}
                    listatipoproductos={this.state.listatipoproductos}
                    listaunidadesmedida={this.state.listaunidadesmedida}
                    listanuevosproductos={this.state.listanuevosproductos}
                  />
                </div> : null
              }
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
        <br />
        {
          this.state.tablaresumen.elementos.length > 0 ?
            (<div className="container-fluid containerTabla contenedorResumenNR">
              <div className="container-fluid  ">
                <div className='col-auto containerSubTabla minPaddingTopBottom d-flex align-items-center'>
                  <label className='letraTitulos'><b>Resumen</b></label>
                </div>
                <div className="containerSubTabla">
                  <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                      <tr>
                        {this.state.tablaresumen.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index}
                            className={(index == 1 || index == 2) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                            style={{
                              minWidth: this.state.tablaresumen.mindimensiones[index],
                              width: this.state.tablaresumen.dimensiones[index],
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {
                        this.state.tablaresumen.elementos.map((item, index) => (
                          <tr key={"tabla" + index}>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                              {item.Producto}
                            </td>
                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                              {item.Unidadmedida}
                            </td>
                            <td className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign: "right" }}>
                              {parseFloat(item.cantidad).toFixed(2)}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                              {item.proveedorsugerido != "" ? item.proveedorsugerido : "Sin proveedor"}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                              <button className='btnEditar' onClick={this.verModalAsignarProveedor.bind(this, { contenido: item })}
                                style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}>
                                <BiEdit className="icoBotones" />
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>) : null
        }

        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}
        {
          (this.state.inforequisicion.idestatusrequisicion == 1 ||
            this.state.inforequisicion.idestatusrequisicion == 2 ||
            this.state.inforequisicion.idestatusrequisicion == 4 ||
            this.state.inforequisicion.idestatusrequisicion == null) ?
            (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={()=>this.setState({redireccionandoarequisicion:true}) /*this.cancelarFormulario*/}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    onClick={this.validarDatos}
                    disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>

            ) : null

        }

        {(this.state.inforequisicion.idestatusrequisicion == 2 && this.state.inforequisicion.idestatusrequisicion != null) ? (
          <>
            <br />

            <div className="divBtnContenedorRequisicion" >
              <Button className="btnRechazar" onClick={this.verModalFormularioAccionEstatus.bind(this, { id: 1 })}>Rechazar requisición</Button>
              <Button className="btnAceptar" onClick={this.verModalFormularioAccionEstatusContrasena.bind(this, { id: 2, accion: 1 })}>Aprobar requisición</Button>
            </div>

            <br />
          </>
        ) : null}



      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));

function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  } else if (bandera == "irarequisicion") {
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)

  }
}
