import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioProducto.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasMayusculasYNumeros, validarLetrasNumerosEspacios, validarRegistroCOFEPRIS, validarLetrasConEspacios, validarIntervaloSeguridad } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaInformacionGeneralUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const todaslasopcionesdisponibles = -1

export default class ModalFormularioProducto extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            listafamiliaproducto: props.listafamiliaproducto,
            listatipoproducto: props.listatipoproducto,
            listaunidadesmedida: props.listaunidadesmedida,
            arrayestatus: props.estatus,

            txtclave: (props.accion == 2) ? (props.informacionproducto.clave) : '',
            vermsgerrortxtclave: false,
            msgerrortxtclave: "",

            txtcomercial: (props.accion == 2) ? (props.informacionproducto.nombrecomercial) : '',
            vermsgerrortxtcomercial: false,
            msgerrortxtcomercial: "",

            idfamiliaproducto: (props.accion == 2) ? (props.informacionproducto.idfamiliaproducto) : todaslasopcionesdisponibles,
            vermsgerroridfamiliaproducto: false,
            msgerroridfamiliaproducto: "",

            idtipoproducto: (props.accion == 2) ? (props.informacionproducto.idtipoproducto) : todaslasopcionesdisponibles,
            vermsgerroridtipoproducto: false,
            msgerroridtipoproducto: "",

            idunidadmedida: (props.accion == 2) ? (props.informacionproducto.idcatalogo_unidadmedida) : todaslasopcionesdisponibles,
            vermsgerroridunidadmedida: false,
            msgerroridunidadmedida: "",

            txtregistrocofepris: (props.accion == 2) ? (props.informacionproducto.registrocofepris) : '',
            vermsgerrortxtregistrocofepris: false,
            msgerrortxtregistrocofepris: "",

            txtbandatoxicologica: (props.accion == 2) ? (props.informacionproducto.bandatoxicologica) : '',
            vermsgerrortxtbandatoxicologica: false,
            msgerrortxtbandatoxicologica: "",

            txtintervaloseguridad: (props.accion == 2) ? (props.informacionproducto.intervaloseguridad) : '',
            vermsgerrortxtintervaloseguridad: false,
            msgerrortxtintervaloseguridad: "",

            txttiemporeentrada: (props.accion == 2) ? (props.informacionproducto.tiemporeentrada) : '',
            vermsgerrortxttiemporeentrada: false,
            msgerrortxttiemporeentrada: "",

            txtdosisminima: (props.accion == 2) ? (props.informacionproducto.dosisminima) : '',
            vermsgerrortxtdosisminima: false,
            msgerrortxtdosisminima: "",

            txtdosismaxima: (props.accion == 2) ? (props.informacionproducto.dosismaxima) : '',
            vermsgerrortxtdosismaxima: false,
            msgerrortxtdosismaxima: "",

            txtvolumenagua: (props.accion == 2) ? (props.informacionproducto.volumenagua) : '',
            vermsgerrortxtvolumenagua: false,
            msgerrortxtvolumenagua: "",

            idestatus: (props.accion == 2) ? (props.informacionproducto.idestatus) : 1,
            vermsgerroridestatus: false,
            msgerroridestatus: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo
        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarTipo = (campo, id) => {
        if (campo == 'idfamiliaproducto') {
            this.setState({ [campo]: id, idtipoproducto: todaslasopcionesdisponibles })
        } else {
            this.setState({ [campo]: id })
        }
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrortxtclave: false })
        this.setState({ vermsgerrortxtcomercial: false })
        this.setState({ vermsgerroridfamiliaproducto: false })
        this.setState({ vermsgerroridtipoproducto: false })
        this.setState({ vermsgerroridunidadmedida: false })
        this.setState({ vermsgerrortxtregistrocofepris: false })
        this.setState({ vermsgerrortxtbandatoxicologica: false })
        this.setState({ vermsgerrortxtintervaloseguridad: false })
        this.setState({ vermsgerrortxttiemporeentrada: false })
        this.setState({ vermsgerrortxtdosisminima: false })
        this.setState({ vermsgerrortxtdosismaxima: false })
        this.setState({ vermsgerrortxtvolumenagua: false })
        this.setState({ vermsgerroridestatus: false })
        let longitudvalidacincuenta = { longitudminima: 1, longitudmaxima: 50 };
        let longitudvalidacien = { longitudminima: 1, longitudmaxima: 100 };
        let longitudvalidadiez = { longitudminima: 1, longitudmaxima: 10 };
        let longitudvalidaveinte = { longitudminima: 1, longitudmaxima: 20 };
        let validacionescorrectas = true

/*        let txtclave = validarLetrasMayusculasYNumeros(this.state.txtclave.trim(), longitudvalidadiez);
        if (txtclave.codigo == "invalid") {
            validacionescorrectas = false;
            this.setState({
                msgerrortxtclave: txtclave.mensaje == "Solo se permite letras mayúsculas y números sin espacios" ? "Solo se permiten letras mayúsculas y números" : txtclave.mensaje,
                vermsgerrortxtclave: true
            })
        }
*/
        let txtcomercial = validarLetrasNumerosEspacios(this.state.txtcomercial.trim(), longitudvalidacien);
        if (txtcomercial.codigo == "invalid") {
            validacionescorrectas = false;
            this.setState({
                msgerrortxtcomercial: txtcomercial.mensaje,
                vermsgerrortxtcomercial: true
            })
        }

        if (this.state.idfamiliaproducto == todaslasopcionesdisponibles) {
            validacionescorrectas = false;
            this.setState({
                msgerroridfamiliaproducto: "Seleccione una familia de agroquímicos",
                vermsgerroridfamiliaproducto: true
            })
        }

        if (this.state.idtipoproducto == todaslasopcionesdisponibles) {
            validacionescorrectas = false;
            this.setState({
                msgerroridtipoproducto: "Seleccione un tipo de agroquímico",
                vermsgerroridtipoproducto: true
            })
        }

        if (this.state.idunidadmedida == todaslasopcionesdisponibles) {
            validacionescorrectas = false;
            this.setState({
                msgerroridunidadmedida: "Seleccione una unidad de medida",
                vermsgerroridunidadmedida: true
            })
        }

        if (this.state.txtregistrocofepris.trim().length > 0) {
            let txtregistrocofepris = validarRegistroCOFEPRIS(this.state.txtregistrocofepris.trim(), longitudvalidacien);
            if (txtregistrocofepris.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtregistrocofepris: txtregistrocofepris.mensaje,
                    vermsgerrortxtregistrocofepris: true
                })
            }
        }

        if (this.state.txtbandatoxicologica.trim().length > 0) {
            let txtbandatoxicologica = validarLetrasConEspacios(this.state.txtbandatoxicologica.trim(), longitudvalidaveinte);
            if (txtbandatoxicologica.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtbandatoxicologica: txtbandatoxicologica.mensaje,
                    vermsgerrortxtbandatoxicologica: true
                })
            }
        }

        if (this.state.txtintervaloseguridad.trim().length > 0) {
            let txtintervaloseguridad = validarIntervaloSeguridad(this.state.txtintervaloseguridad.trim(), longitudvalidacincuenta);
            if (txtintervaloseguridad.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtintervaloseguridad: txtintervaloseguridad.mensaje,
                    vermsgerrortxtintervaloseguridad: true
                })
            }
        }

        if (this.state.txttiemporeentrada.trim().length > 0) {
            let txttiemporeentrada = validarLetrasNumerosEspacios(this.state.txttiemporeentrada.trim(), longitudvalidacincuenta);
            if (txttiemporeentrada.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxttiemporeentrada: txttiemporeentrada.mensaje,
                    vermsgerrortxttiemporeentrada: true
                })
            }
        }

        if (this.state.txtdosisminima.trim().length > 0) {
            let txtdosisminima = validarLetrasNumerosEspacios(this.state.txtdosisminima.trim(), longitudvalidacincuenta);
            if (txtdosisminima.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtdosisminima: txtdosisminima.mensaje,
                    vermsgerrortxtdosisminima: true
                })
            }
        }

        if (this.state.txtdosismaxima.trim().length > 0) {
            let txtdosismaxima = validarLetrasNumerosEspacios(this.state.txtdosismaxima.trim(), longitudvalidacincuenta);
            if (txtdosismaxima.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtdosismaxima: txtdosismaxima.mensaje,
                    vermsgerrortxtdosismaxima: true
                })
            }
        }

        if (this.state.txtvolumenagua.trim().length > 0) {
            let txtvolumenagua = validarLetrasNumerosEspacios(this.state.txtvolumenagua.trim(), longitudvalidacincuenta);
            if (txtvolumenagua.codigo == "invalid") {
                validacionescorrectas = false;
                this.setState({
                    msgerrortxtvolumenagua: txtvolumenagua.mensaje,
                    vermsgerrortxtvolumenagua: true
                })
            }
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true)
            let json = {
                "idcatalogoproducto": this.state.accion == 1 ? ("0") : (this.props.informacionproducto.idcatalogoproducto),//#MOD
                "clave": this.state.txtclave,
                "nombrecomercial": this.state.txtcomercial,
                "idtipoproducto": this.state.idtipoproducto,
                "idcatalogo_unidadmedida": this.state.idunidadmedida,
                "registrocofepris": this.state.txtregistrocofepris,
                "bandatoxicologica": this.state.txtbandatoxicologica,
                "intervaloseguiridad": this.state.txtintervaloseguridad,
                "tiemporeentrada": this.state.txttiemporeentrada,
                "dosisminima": this.state.txtdosisminima,
                "dosismaxima": this.state.txtdosismaxima,
                "volumenagua": this.state.txtvolumenagua,
                "idestatus": this.state.idestatus,
            }
            var resp = await getListaInformacionGeneralUpdate(json); // #MOD: getListaProductosUpdate

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                               {/* <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Clave</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtclave} onChange={this.escribir("txtclave")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtclave}</label>}

                                {this.state.vermsgerrortxtclave ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtclave}</p> : null}
*/}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre comercial</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcomercial} onChange={this.escribir("txtcomercial")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcomercial}</label>}

                                {this.state.vermsgerrortxtcomercial ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcomercial}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Familia agroquímico</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("idfamiliaproducto", event.target.value)}>
                                                    {<option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={todaslasopcionesdisponibles == this.state.idfamiliaproducto} >Seleccionar</option>}
                                                    {this.state.listafamiliaproducto.map((i, index) => (
                                                        <option value={i.idfamilia_productos} key={"tipo_" + index} selected={i.idfamilia_productos == this.state.idfamiliaproducto} >{i.familia} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerroridfamiliaproducto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridfamiliaproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo agroquímico</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("idtipoproducto", event.target.value)}>
                                                    {<option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={this.state.idtipoproducto == todaslasopcionesdisponibles} >
                                                        {this.state.idfamiliaproducto == todaslasopcionesdisponibles ? "Seleccione primero una familia" : "Seleccionar"}
                                                    </option>}
                                                    {this.state.idfamiliaproducto != todaslasopcionesdisponibles && this.state.listatipoproducto
                                                        .filter((e) => e.idfamilia_productos == this.state.idfamiliaproducto)
                                                        .map((i, index) => (
                                                            <option value={i.idtipo_producto} key={"tipo_" + index} selected={i.idtipo_producto == this.state.idtipoproducto} >{i.tipo} </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerroridtipoproducto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridtipoproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Unidad de medida</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("idunidadmedida", event.target.value)}>
                                                    <option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={todaslasopcionesdisponibles == this.state.idunidadmedida} >Seleccionar </option>
                                                    {this.state.listaunidadesmedida.map((i, index) => (
                                                        <option value={i.idcatalogo_unidadmedida} key={"tipo_" + index} selected={i.idcatalogo_unidadmedida == this.state.idunidadmedida} >{i.unidadmedida} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerroridunidadmedida ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroridunidadmedida}</p> : null}



                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Registro COFEPRIS</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtregistrocofepris} onChange={this.escribir("txtregistrocofepris")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtregistrocofepris}</label>}

                                {this.state.vermsgerrortxtregistrocofepris ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtregistrocofepris}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Banda toxicológica</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtbandatoxicologica} onChange={this.escribir("txtbandatoxicologica")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtbandatoxicologica}</label>}

                                {this.state.vermsgerrortxtbandatoxicologica ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtbandatoxicologica}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Intervalo de seguridad</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtintervaloseguridad} onChange={this.escribir("txtintervaloseguridad")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtintervaloseguridad}</label>}

                                {this.state.vermsgerrortxtintervaloseguridad ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtintervaloseguridad}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tiempo de reentrada</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttiemporeentrada} onChange={this.escribir("txttiemporeentrada")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttiemporeentrada}</label>}

                                {this.state.vermsgerrortxttiemporeentrada ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttiemporeentrada}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Dosis mínima</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdosisminima} onChange={this.escribir("txtdosisminima")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdosisminima}</label>}

                                {this.state.vermsgerrortxtdosisminima ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdosisminima}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Dosis máxima</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdosismaxima} onChange={this.escribir("txtdosismaxima")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdosismaxima}</label>}

                                {this.state.vermsgerrortxtdosismaxima ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdosismaxima}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Volumen agua</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtvolumenagua} onChange={this.escribir("txtvolumenagua")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtvolumenagua}</label>}

                                {this.state.vermsgerrortxtvolumenagua ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtvolumenagua}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("idestatus", event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.idestatus == this.state.idestatus} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.informacionproducto.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.informacionproducto.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

