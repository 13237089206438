import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoReportesDetalles.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { format } from 'date-fns';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioTipoClientes from '../../Complements/Modales/ModalFormularioTipoClientes/ModalFormularioTipoClientes';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoProveedor, getEstatus, getListaTipoCliente, getListaTipoProveedor, getReportes, getReportesDetalles } from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from 'react-icons/fa';
import ExportarCSV from '../../Complements/exportarcsv/exportarcsv';
import ExportarCSVreporte from '../../Complements/exportarcsv/exportarCSVreporte';


export default class CatalogoReportesDetalles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,
            jsoncsv: [],
            verCSV: false,

            tablaGenerales: {
                encabezados: [
                    "ID",
                    "Requisición",
                    "Fecha compra",
                    "Total",
                    "Adeudo",
                    "Descuento",
                    "Estatus"
                ],
                dimensiones: [
                    "10%",
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "15%",
                    "20%"
                ],
                mindimensiones: [
                    "60px",
                    "100px",
                    "90px",
                    "80px",
                    "80px",
                    "80px",
                    "110px"
                ],
                elementos: []
            },


            regresarnominas: false,
            nameproveedor: "sssss",
            fechacompraini: "01/01/2023",
            fechacomprafin: "31/12/2023",


            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,
            colorverlbl: estiloBtnVer().colorverlbl,
            borderver: estiloBtnVer().borderver,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
            btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
            btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

        }
    }

    async componentDidMount() {
        this.setState({ loadingspinners: true });
        this.setState({ txtbuscador: "" })
        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
            return;
        }

        if (localStorage.getItem('report') == null || localStorage.getItem('report') == undefined) {
            this.setState({ regresarnominas: true })
            return;
        }

        var json2 = JSON.parse(localStorage.getItem("report"))
        this.setState({ nameproveedor: json2.proveedor })
        this.setState({ fechacompraini: json2.fechainicio })
        this.setState({ fechacomprafin: json2.fechafin })


        var json = {
            "fechainicio": json2.fechainicio,
            "fechafin": json2.fechafin,
            "idproveedor": json2.idproveedor,
            "tipo": "Todos"
        }
        var resp = await getReportesDetalles(json);
        if (this.manejadorLlamadasApis(resp)) {
            return;
        }
        //console.log(JSON.stringify(resp))
        var res = resp.data

        var tablaPC = this.state.tablaGenerales;
        tablaPC.elementos = res;
        this.setState({ tablaGenerales: tablaPC });



        setTimeout(() => {
            this.crearCSV()
        }, 500);

        this.dimensiones();
        window.addEventListener("resize", this.dimensiones);


    }

    crearCSV = () => {

        ////
        var contentPrincipal = [];
        var jsontablaGeneralesEncabezados = JSON.parse(JSON.stringify(this.state.tablaGenerales.encabezados));

        var jsontablaGenerales = this.state.tablaGenerales.elementos;
        var contentablaGenerales = [];
        var contentablaGenerales2 = [];
        for (let i = 0; i < jsontablaGenerales.length; i++) {

            contentablaGenerales2 = [];
            contentablaGenerales2.push(jsontablaGenerales[i].idcompra);
            contentablaGenerales2.push(jsontablaGenerales[i].requisicion);
            contentablaGenerales2.push(jsontablaGenerales[i].fechacompra);
            contentablaGenerales2.push(jsontablaGenerales[i].total);
            contentablaGenerales2.push(jsontablaGenerales[i].adeudo);
            contentablaGenerales2.push(jsontablaGenerales[i].descuento);
            contentablaGenerales2.push(jsontablaGenerales[i].estatus);

            contentablaGenerales.push(contentablaGenerales2)
        }


        contentPrincipal.push(jsontablaGeneralesEncabezados)

        for (let i = 0; i < contentablaGenerales.length; i++) {
            contentPrincipal.push(contentablaGenerales[i])
        }

        //console.log(JSON.stringify(contentPrincipal))

        this.setState({ jsoncsv: contentPrincipal })
        setTimeout(() => {
            this.setState({ verCSV: true })
        }, 500);

        this.setState({ loadingspinners: false });

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    actualizar = async () => {

    }


    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }


    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }


    verModalDetalles = (ban) => {
    }

    cerrarModal = (accion) => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
            this.componentDidMount();
        }
    }



    limpiarInputBusqueda = () => {
        this.setState({ txtbuscador: '' })
        this.setState({ filtrolistapaginada: [] });
        this.cambiarContenido(1);
    }




    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        // //console.log("Entro en cambiar fecha")
        // //console.log(item)

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }

    format_moneda = (number) => {
        try {
            var dato = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
            if (dato == "$NaN" || dato == undefined) {
                return number
            }
            else {
                return dato
            }
        } catch (error) {
            return number;

        }
    }

    format_moneda_sin_logo = (number) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
    }

    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                    : null}

                {(this.state.regresarnominas == true) ? (<>
                    <SpinnerModal tipo="full" show={true} />
                    <Acciones bandera={"regresar"} />
                </>
                ) : null}

                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}


                <Navbar />
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: "10px" }}>
                                    Detalles Reporte de proveedor: <b>{this.state.nameproveedor}</b>
                                </label>
                                <br />

                                <label className='lblTexto letraTitulos' style={{ color: this.state.colorcontenidotabla, paddingLeft: "20px" }}>
                                    Fecha inicio: <b>{this.state.fechacompraini}</b>
                                </label>
                                <br />

                                <label className='lblTexto letraTitulos' style={{ color: this.state.colorcontenidotabla, paddingLeft: "20px" }}>
                                    Fecha fin: <b>{this.state.fechacomprafin}</b>
                                </label>

                            </div>






                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={() => this.setState({ regresarnominas: !this.state.regresarnominas })}>Regresar a reportes</button>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                {/* <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button> */}


                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                    {(this.state.verCSV) ? (
                                        <ExportarCSVreporte arraycsv={this.state.jsoncsv} totalitems={this.state.jsoncsv.length}
                                        />
                                    ) : null
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>



                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo " style={{ width: "98%" }}>
                        <br />
                        <br />
                        <br />

                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <div className="containerSubTabla" style={{ backgroundColor: this.state.fondotitulosencabezados, borderRadius: "8px" }}>

                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaGenerales.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaGenerales.mindimensiones[index], width: this.state.tablaGenerales.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>

                                    {this.state.tablaGenerales.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[0], width: this.state.tablaGenerales.dimensiones[0] }}>
                                                {item.idcompra}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[1], width: this.state.tablaGenerales.dimensiones[1] }}>
                                                {item.requisicion}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[2], width: this.state.tablaGenerales.dimensiones[2] }}>
                                                {item.fechacompra}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[3], width: this.state.tablaGenerales.dimensiones[3] }}>
                                                {this.format_moneda(item.total)}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[4], width: this.state.tablaGenerales.dimensiones[4] }}>
                                                {this.format_moneda(item.adeudo)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[5], width: this.state.tablaGenerales.dimensiones[5] }}>
                                                {this.format_moneda(item.descuento)}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaGenerales.mindimensiones[6], width: this.state.tablaGenerales.dimensiones[6] }}>
                                                {item.estatus}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                <br />
                            </Table>


                        </div>
                        <br />






                    </div>
                </div>

            </>
        )
    }
}

function Acciones({ bandera }) {

    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'regresar') {
        return (<Redirect from='/' to={"/catalogoreportes"} />)
    }


}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label>
    </button>
));


