import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare,BsTelephoneFill } from 'react-icons/bs';
import { RiRefreshLine } from 'react-icons/ri';
import "./ModalFormularioUsuarios.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {  validarLetrasNumerosEspacios , validateEmail, validarTelefono2, validarCodigoPostal, validarLetrasConEspacios} from '../../../Services/Validaciones';
import { getListaCatalogoUsuariosUpdate, setGenerarNombreUsuario, setVerificarNombreUsuario } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { ladapaises } from '../ModalFormularioVentanaProveedor/Util';

export default class ModalFormularioUsuarios extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            
            area: (props.accion == 2) ? (props.item.idarea) : '',
            puesto: (props.accion == 2) ? (props.item.idpuesto) : '',
            tipousuario: (props.accion == 2) ? (props.item.idtipo) : '',

            txtnombre: (props.accion == 2) ? (props.item.nombres) : '',
            txtapellido: (props.accion == 2) ? (props.item.apellidos) : '',
            txtemail: (props.accion == 2) ? (props.item.email) : '',


            idpais: (props.accion == 2) ?
            ((ladapaises.find(item => item.lada == props.item.telefono.substring(0,props.item.telefono.length-10))) ?
            ladapaises.find(item => item.lada == props.item.telefono.substring(0,props.item.telefono.length-10)).idpais : -1) : -1,

            txttelefono: (props.accion == 2) ?
            ((props.item.telefono && (props.item.telefono.length >= 10)) ?
            props.item.telefono.substring(props.item.telefono.length-10, props.item.telefono.length) : props.item.telefono ) : '',

            txtcalle: (props.accion == 2) ? (props.item.calle) : '',
            txtnoexterior: (props.accion == 2) ? (props.item.numeroexterior) : '',
            txtnointerior: (props.accion == 2) ? (props.item.numerointerior) : '',
            txtcolonia: (props.accion == 2) ? (props.item.colonia) : '',
            txtcodigopostal: (props.accion == 2) ? (props.item.codigopostal) : '',
            txtlocalidad: (props.accion == 2) ? (props.item.localidad) : '',
            txtmunicipio: (props.accion == 2) ? (props.item.municipio) : '',
            txtestado: (props.accion == 2) ? (props.item.estado) : '',
            txtpais: (props.accion == 2) ? (props.item.pais) : '',
            txtreferencia: (props.accion == 2) ? (props.item.referencia) : '',

            accesoalsistema: (props.accion == 2) ? (props.item.acceso) : 0,
            nombreusuario: (props.accion == 2) ? (props.item.usuario) : "",
            nombreusuariovalido: false,
            

            idestatus: 1,
            arrayestatus: props.estatus,

            vermsgerrorarea: false,
            vermsgerrorpuesto: false,
            vermsgerrortipousuario: false,

            vermsgerrortxtnombre: false,
            vermsgerrortxtapellido: false,
            vermsgerrortxtemail: false,

            vermsgerrortxtlada: false,
            vermsgerrortxttelefono: false,

            vermsgerrortxtcalle: false,
            vermsgerrortxtnoexterior: false,
            vermsgerrortxtnointerior: false,
            vermsgerrortxtcolonia: false,
            vermsgerrortxtcodigopostal: false,
            vermsgerrortxtlocalidad: false,
            vermsgerrortxtmunicipio: false,
            vermsgerrortxtestado: false,
            vermsgerrortxtpais: false,
            vermsgerrortxtreferencia: false,
            vermsgerrornombreusuario: false,



            msgerrorarea: "",
            msgerrorpuesto: "",
            msgerrortipousuario: "",

            msgerrortxtnombre: "",
            msgerrortxtapellido: "",
            msgerrortxtemail: "",

            msgerrortxtlada: "",
            msgerrortxttelefono: "",

            msgerrortxtcalle: "",
            msgerrortxtnoexterior: "",
            msgerrortxtnointerior: "",
            msgerrortxtcolonia: "",
            msgerrortxtcodigopostal: "",
            msgerrortxtlocalidad: "",
            msgerrortxtmunicipio: "",
            msgerrortxtestado: "",
            msgerrortxtpais: "",
            msgerrortxtreferencia: "",
            msgerrornombreusuario: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            arraytipo: props.arraytipos,
            listapuesto: this.props.listapuesto,
            filtrolistapuesto: [],
            listaarea: this.props.listaarea,
            listatipousuario: props.listatipousuario != undefined ? props.listatipousuario:[]
        }

    }
    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)

        if(this.props.accion == 2){
        
            var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == this.state.area)
            await this.setState({ 
                filtrolistapuesto: resplistapuestos,
            })
           
        }
        
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => async (event) => {
        this.setState({ [prop]: event.target.value });

        if(prop == "nombreusuario"){

            let json = {
                "username":event.target.value
            }
            var usuario = await setVerificarNombreUsuario(json);
            if (usuario.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else if (usuario.code == 200 || usuario.code == 300) {
                toast.error(usuario.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                
            }else if(usuario.code == 0){
                this.setState({ nombreusuario: event.target.value,
                    nombreusuariovalido: true })
            }else{
                this.setState({ nombreusuario: "" })
            }
        }
    };


    cambiarTipo = async (prop,id) => {

        if(prop=="area"){
            var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == id)
            await this.setState({ 
                filtrolistapuesto: resplistapuestos,
                puesto: "",
              })
        }
      
        await this.setState({ [prop]: id })
    }

    cambiarLada = (band) => {

        if (band.lada) {
          let encontrado = ladapaises.find(x => x.idpais == band.lada);
          if (encontrado.idpais != 0) {
            this.refInput.focus();
          }
          this.setState({ idpais: encontrado.idpais })
        }
        else if (band.pais) {
          this.setState({ txtpais: band.pais });
        }
      }

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {
        let longitudcincuentavalida = {longitudminima: 1, longitudmaxima: 50};
        let longitudcienvalida = {longitudminima: 1, longitudmaxima: 100};
        let longituddiezvalida = {longitudminima: 1, longitudmaxima: 10};
    
        
        this.setState({ vermsgerrorarea: false })
        this.setState({ vermsgerrorpuesto: false })
        this.setState({ vermsgerrortipousuario: false })

        this.setState({ vermsgerrortxtnombre: false })
        this.setState({ vermsgerrortxtapellido: false })
        this.setState({ vermsgerrortxtemail: false })

        this.setState({ vermsgerrortxtlada: false })
        this.setState({ vermsgerrortxttelefono: false })

        this.setState({ vermsgerrortxtcalle: false })
        this.setState({ vermsgerrortxtnoexterior: false })
        this.setState({ vermsgerrortxtnointerior: false })
        this.setState({ vermsgerrortxtcolonia: false })
        this.setState({ vermsgerrortxtcodigopostal: false })
        this.setState({ vermsgerrortxtlocalidad: false })
        this.setState({ vermsgerrortxtmunicipio: false })
        this.setState({ vermsgerrortxtlocalidad: false })
        this.setState({ vermsgerrortxtestado: false })
        this.setState({ vermsgerrortxtpais: false })
        this.setState({ vermsgerrortxtreferencia: false })
        this.setState({ vermsgerrornombreusuario: false })

        
        let validacionescorrectas = true

        let txtnombre = validarLetrasConEspacios(this.state.txtnombre.trim(), longitudcincuentavalida);
        if (txtnombre.codigo == "invalid") {
            this.setState({ msgerrortxtnombre: txtnombre.mensaje })
            this.setState({ vermsgerrortxtnombre: true })
            validacionescorrectas = false;
        }

        let txtapellido = validarLetrasConEspacios(this.state.txtapellido.trim(), longitudcincuentavalida);
        if (txtapellido.codigo == "invalid") {
            this.setState({ msgerrortxtapellido: txtapellido.mensaje })
            this.setState({ vermsgerrortxtapellido: true })
            validacionescorrectas = false;
        }

        if (this.state.txtemail.trim().length > 0) {
            let txtemail = validateEmail(this.state.txtemail.trim())
            if (txtemail.codigo == "invalid") {
              this.setState({ msgerrortxtemail: txtemail.mensaje })
              this.setState({ vermsgerrortxtemail: true })
              validacionescorrectas = false;
            }
        }else{
            if(this.state.accesoalsistema == 1 ){
                validacionescorrectas = false;
                this.setState({ 
                    msgerrortxtemail: "Este campo es obligatorio",
                    vermsgerrortxtemail: true 
                 })
            }
        }

        if(this.state.txttelefono.trim().length > 0){
            // idpais asocia tanto a la lada como al país que pertenece
            if (this.state.idpais < 0) {
                this.setState({ msgerrortxtlada: "Selecciona una lada" })
                this.setState({ vermsgerrortxtlada: true })
                validacionescorrectas = false;
            }
            let txttelefono = validarTelefono2(this.state.txttelefono.trim())
            if (txttelefono.codigo == "invalid") {
                this.setState({ msgerrortxttelefono: txttelefono.mensaje })
                this.setState({ vermsgerrortxttelefono: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtcalle.trim().length > 0){
            let txtcalle = validarLetrasNumerosEspacios(this.state.txtcalle.trim(), longitudcincuentavalida);
            if (txtcalle.codigo == "invalid") {
                this.setState({ msgerrortxtcalle: txtcalle.mensaje })
                this.setState({ vermsgerrortxtcalle: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtnoexterior.trim().length > 0){
            let txtnoexterior = validarLetrasNumerosEspacios(this.state.txtnoexterior.trim(), longituddiezvalida);
            if (txtnoexterior.codigo == "invalid") {
                this.setState({ msgerrortxtnoexterior: txtnoexterior.mensaje })
                this.setState({ vermsgerrortxtnoexterior: true })
                validacionescorrectas = false;
            }
        }
        

        if(this.state.txtnointerior.trim().length > 0){
            if(this.state.txtnointerior.trim().length > 0){
                let txtnointerior = validarLetrasNumerosEspacios(this.state.txtnointerior.trim(), longituddiezvalida);
                if (txtnointerior.codigo == "invalid") {
                    this.setState({ msgerrortxtnointerior: txtnointerior.mensaje })
                    this.setState({ vermsgerrortxtnointerior: true })
                    validacionescorrectas = false;
                }
            }
        }
        
        
        if(this.state.txtcolonia.trim().length > 0){
            let txtcolonia = validarLetrasNumerosEspacios(this.state.txtcolonia.trim(), longitudcincuentavalida);
            if (txtcolonia.codigo == "invalid") {
                this.setState({ msgerrortxtcolonia: txtcolonia.mensaje })
                this.setState({ vermsgerrortxtcolonia: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtcodigopostal.trim().length > 0){
            let txtcodigopostal = validarCodigoPostal(this.state.txtcodigopostal.trim())
            if (txtcodigopostal.codigo == "invalid") {
              this.setState({ msgerrortxtcodigopostal: txtcodigopostal.mensaje })
              this.setState({ vermsgerrortxtcodigopostal: true })
              validacionescorrectas = false;
            }
        }
        
        if(this.state.txtlocalidad.trim().length > 0){
            let txtlocalidad = validarLetrasNumerosEspacios(this.state.txtlocalidad.trim(), longitudcincuentavalida);
            if (txtlocalidad.codigo == "invalid") {
                this.setState({ msgerrortxtlocalidad: txtlocalidad.mensaje })
                this.setState({ vermsgerrortxtlocalidad: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtmunicipio.trim().length > 0){
            let txtmunicipio = validarLetrasNumerosEspacios(this.state.txtmunicipio.trim(), longitudcincuentavalida);
            if (txtmunicipio.codigo == "invalid") {
                this.setState({ msgerrortxtmunicipio: txtmunicipio.mensaje })
                this.setState({ vermsgerrortxtmunicipio: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtestado.trim().length > 0){
            let txtestado = validarLetrasNumerosEspacios(this.state.txtestado.trim(), longitudcincuentavalida);
            if (txtestado.codigo == "invalid") {
                this.setState({ msgerrortxtestado: txtestado.mensaje })
                this.setState({ vermsgerrortxtestado: true })
                validacionescorrectas = false;
            }
        }
        
        if(this.state.txtpais.trim().length > 0){
            let txtpais = validarLetrasNumerosEspacios(this.state.txtpais.trim(), longitudcincuentavalida);
            if (txtpais.codigo == "invalid") {
                this.setState({ msgerrortxtpais: txtpais.mensaje })
                this.setState({ vermsgerrortxtpais: true })
                validacionescorrectas = false;
            }
        }
        

        if (this.state.txtreferencia.trim().length > 0) {
            let txtreferencia = validarLetrasNumerosEspacios(this.state.txtreferencia.trim(), longitudcienvalida);
            if (txtreferencia.codigo == "invalid") {
                this.setState({ msgerrortxtreferencia: txtreferencia.mensaje })
                this.setState({ vermsgerrortxtreferencia: true })
                validacionescorrectas = false;
            }
        }
        
        if (this.state.area.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorarea: "Seleccione un área" })
            this.setState({ vermsgerrorarea: true })
        }

        if (this.state.puesto.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorpuesto: "Seleccione un puesto" })
            this.setState({ vermsgerrorpuesto: true })
        }

        if (this.state.tipousuario.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortipousuario: "Seleccione un tipo de usuario" })
            this.setState({ vermsgerrortipousuario: true })
        }

        if(this.state.nombreusuario.length < 1 && this.state.accesoalsistema == 1){
            this.setState({ msgerrornombreusuario: "Este campo es obligatorio" })
            this.setState({ vermsgerrornombreusuario: true })
        }

        if (validacionescorrectas) {
            this.props.activarSpinner(true);

            let json = {
                "idcatalogousuarios":this.state.accion == 1 ? (0) : (this.props.item.idcatalogousuarios),
                "nombres":this.state.txtnombre,
                "apellidos":this.state.txtapellido,
                "email": this.state.txtemail,
                "telefono": this.state.txttelefono != ""? ladapaises[this.state.idpais].lada + this.state.txttelefono : "",
                "calle": this.state.txtcalle,
                "numeroexterior": this.state.txtnoexterior,
                "numerointerior": this.state.txtnointerior,
                "codigopostal": this.state.txtcodigopostal,
                "colonia":this.state.txtcolonia,
                "localidad": this.state.txtlocalidad,
                "municipio": this.state.txtmunicipio,
                "estado": this.state.txtestado,
                "pais": this.state.txtpais,
                "referencia": this.state.txtreferencia,
                "idpuesto": this.state.puesto,
                "idtipousuario":this.state.tipousuario,
                "idestatus": this.state.idestatus,
                "acceso": this.state.accesoalsistema,
                "usuario": this.state.nombreusuario
            }

            var resp = await getListaCatalogoUsuariosUpdate(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    async generarUsuario(){
        let json = {
            "nombres":this.state.txtnombre,
            "apellidos":this.state.txtapellido
        }

        var usuario = await setGenerarNombreUsuario(json);
     
            if (usuario.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else if (usuario.code == 200 || usuario.code == 300) {
             //   toast.error(usuario.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.setState({ msgerrornombreusuario: usuario.message})
                this.setState({ vermsgerrornombreusuario: true })
                
            }else{
                this.setState({ nombreusuario: usuario.data.username,
                nombreusuariovalido: true })
            }
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre(s)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombre} onChange={this.escribir("txtnombre")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombre}</label>}

                                {this.state.vermsgerrortxtnombre ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombre}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Apellido(s)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtapellido} onChange={this.escribir("txtapellido")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtapellido}</label>}

                                {this.state.vermsgerrortxtapellido ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtapellido}</p> : null}

                                

                               

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Teléfono</p>

                                    <div className="row anchoDivTelefono" style={{ borderBottom: this.state.estilosmodal.colorborderbottom }}>

                                    <div className='col-auto' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                        <BsTelephoneFill />
                                    </div>

                                    <div className='col-auto estiloRowPhone'>

                                        {(ladapaises.length > 0) ? (
                                        <select className="comboTelefono letraNormal" onChange={(event) => this.cambiarLada({ lada: event.target.value })}>
                                            {this.state.idpais < 0 || this.state.idpais >= ladapaises.length ?
                                            <option className='phoneSelection' value={"X"} key={"pais_X"} selected={true}>Lada</option> : null
                                            }
                                            {ladapaises.map((i, index) => (

                                            (i.idpais != "x") ? (
                                                <>
                                                {(this.state.idpais == i.idpais) ? (
                                                    <option className='phoneSelection' value={i.idpais} key={"pais_" + index} selected={i.idpais == this.state.idpais}>{i.lada}</option>
                                                ) :
                                                    <option value={i.idpais} key={"pais_" + index} selected={i.idpais == this.state.idpais}>{i.pais}</option>}
                                                </>
                                            ) : null
                                            ))}
                                        </select>
                                        ) : null}
                                    </div>

                                    <div className='col'>
                                        <input ref={(input) => { this.refInput = input; }} className='lblTextoModal letraNormal' style={{ marginBottom: '7px', color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none' }}
                                        value={this.state.txttelefono} onChange={this.escribir("txttelefono")}></input>
                                    </div>
                                </div>

                                {(this.state.vermsgerrortxttelefono || this.state.vermsgerrortxtlada) ?
                                <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>
                                    {this.state.vermsgerrortxtlada ? (this.state.msgerrortxtlada) : ""}
                                    {this.state.vermsgerrortxttelefono && this.state.vermsgerrortxtlada ? ". " : ""}
                                    {this.state.vermsgerrortxttelefono ? (this.state.msgerrortxttelefono) : ""}
                                </p> : null}


                                

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Calle</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, 
                                        border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcalle} onChange={this.escribir("txtcalle")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcalle}</label>}

                                {this.state.vermsgerrortxtcalle ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcalle}</p> : null}

                                <div className='row'>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Exterior</p>

                                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'} value={this.state.txtnoexterior} onChange={this.escribir("txtnoexterior")}></input>

                                        {this.state.vermsgerrortxtnoexterior ? <p className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnoexterior}</p> : null}
                                    </div>

                                    <div className='col-6'>
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Interior</p>

                                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            type={'text'} value={this.state.txtnointerior} onChange={this.escribir("txtnointerior")}></input>


                                        {this.state.vermsgerrortxtnointerior ? <p className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnointerior}</p> : null}
                                    </div>

                                </div>
                                

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Colonia</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcolonia} onChange={this.escribir("txtcolonia")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcolonia}</label>}

                                {this.state.vermsgerrortxtcolonia ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcolonia}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Código postal</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcodigopostal} onChange={this.escribir("txtcodigopostal")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcodigopostal}</label>}

                                {this.state.vermsgerrortxtcodigopostal ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcodigopostal}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Localidad</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtlocalidad} onChange={this.escribir("txtlocalidad")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtlocalidad}</label>}

                                {this.state.vermsgerrortxtlocalidad ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlocalidad}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Municipio</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtmunicipio} onChange={this.escribir("txtmunicipio")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmunicipio}</label>}

                                {this.state.vermsgerrortxtmunicipio ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmunicipio}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estado</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtestado} onChange={this.escribir("txtestado")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtestado}</label>}

                                {this.state.vermsgerrortxtestado ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtestado}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>País</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtpais} onChange={this.escribir("txtpais")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtpais}</label>}

                                {this.state.vermsgerrortxtpais ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpais}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Referencia</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtreferencia} onChange={this.escribir("txtreferencia")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtreferencia}</label>}

                                {this.state.vermsgerrortxtreferencia ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtreferencia}</p> : null}

                                {/* ////////////////////   Inicio Sección selector área   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("area",event.target.value)}>
                                                      {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}  
                                                    {this.state.listaarea.map((i, index) => (
                                                       this.state.accion == 1 ?
                                                        <option value={i.idcatalogo_areas} key={"tipo_" + index} >{i.area} </option>:
                                                        <option value={i.idcatalogo_areas} key={"tipo_" + index} selected={i.idcatalogo_areas==this.state.area ? true:false} >{i.area} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorarea ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorarea}</p> : null}


                                {/* ////////////////////   Inicio Sección selector puesto   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Puesto</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("puesto",event.target.value)}>
                                                      { this.state.accion == 1 || this.state.puesto == "" ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}  
                                                    {this.state.filtrolistapuesto.map((i, index) => (
                                                       this.state.accion == 1 ?
                                                        <option value={i.idcatalogo_puestos} key={"tipo_" + index} >{i.puesto} </option>:
                                                        <option value={i.idcatalogo_puestos} key={"tipo_" + index} selected={i.idcatalogo_puestos==this.state.puesto ? true:false} >{i.puesto} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorpuesto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpuesto}</p> : null}

                                {/* ////////////////////   Inicio Sección selector tipo usuario   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo usuario</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("tipousuario",event.target.value)}>
                                                      { this.state.accion == 1  ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}  
                                                    {this.state.listatipousuario.map((i, index) => (
                                                       this.state.accion == 1 ?
                                                        <option value={i.idtipo_usuario} key={"tipo_" + index} >{i.tipo} </option>:
                                                        <option value={i.idtipo_usuario} key={"tipo_" + index} selected={i.idtipo_usuario==this.state.tipousuario ? true:false} >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrortipousuario ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipousuario}</p> : null}

                                <br />
                                <div className="checkAccesoAlsistema letraNormal" onClick={() => this.setState({ accesoalsistema: this.state.accesoalsistema == 0 ? 1 : 0  })}>
                                    <input type="checkbox" checked={this.state.accesoalsistema} /> Acceso al sistema
                                </div>
                                <br />
                              
                               
                                    
                                    <div>
                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>E-mail</p>
                                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtemail} onChange={this.escribir("txtemail")}></input>
                                        
                                        {this.state.vermsgerrortxtemail ? <p className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtemail}</p> : null}


                                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de usuario</p>
                                        <div className='lblSubTitulosModal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom, display: "flex" }}>
                                        <input className='lblTextoNombreUsuario letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none'}}
                                        type={'text'} value={this.state.nombreusuario} onChange={this.escribir("nombreusuario")}></input>
                                        <RiRefreshLine className='btnGenerarUsuario' onClick={() => this.generarUsuario()}/>
                                        </div>
                                        {this.state.vermsgerrornombreusuario ? <p className="msgError letraNormal"
                                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombreusuario}</p> : null}



                                    </div>
                               

                                


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }


                            </div>
                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

