import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioDetalleOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumeros } from '../../../Services/Validaciones';
import { getDetallesOrdenCosechaGuardarTabla } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioNuevoSeguridadOrdenCosecha extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,

            accion: props.accion,
            titulo: props.titulomodal,

            rancho: 0,
            lote: 0,
            supervisorcosecha: 0,
            txtnumflete: '',
            txtvehiculos: '',
            txtplacas: '',
            txthorasalida: '',
            txthoraentrega: '',
            personaentrega: 0,
            txtpersonarecibe: '',
            txttotalrejascortadas: '',
            txttotaltoneladascortadas: '',
            txtrejaspiso: '',
            txttoneladaspiso: '',
            txtrejasenviadas: '',
            txttoneladasenviadas: '',
            txtsaldorejaspiso: '',
            txtobservaciones: '',

            itemseleccionado: this.props.accion==2 ? this.props.item : undefined,

            idordecosecha: this.props.idordecosecha != undefined ? this.props.idordecosecha : 0,
            listalotes: this.props.listalotes != undefined ? this.props.listalotes : [],
            listaranchos: this.props.listaranchos != undefined ? this.props.listaranchos : [],
            listausuarios: this.props.listausuarios != undefined ? this.props.listausuarios : [],

            vermsgerrorrancho: false,
            vermsgerrorlote: false,
            vermsgerrorsupervisorcosecha: false,
            vermsgerrornumflete: false,
            vermsgerrorvehiculos: false,
            vermsgerrorplacas: false,
            vermsgerrorhorasalida: false,
            vermsgerrorhoraentrega: false,
            vermsgerrorpersonaentrega: false,
            vermsgerrorpersonarecibe: false,
            vermsgerrortotalrejascortadas: false,
            vermsgerrortotaltoneladascortadas: false,
            vermsgerrorrejaspiso: false,
            vermsgerrortoneladaspiso: false,
            vermsgerrortotalrejasenviadas: false,
            vermsgerrortotaltoneladasenviadas: false,
            vermsgerrorsaldorejaspiso: false,
            vermsgerrorobservaciones: false,

            msgerrorrancho: "",
            msgerrorlote: "",
            msgerrorsupervisor: "",
            msgerrornumflete: "",
            msgerrorvehiculos: "",
            msgerrorplacas: "",
            msgerrorhorasalida: "",
            msgerrorhoraentrega: "",
            msgerrorpersonaentrega: "",
            msgerrorpersonarecibe: "",
            msgerrortotalrejascortadas: "",
            msgerrortotaltoneladascortadas: "",
            msgerrorrejaspiso: "",
            msgerrortoneladaspiso: "",
            msgerrortotalrejasenviadas: "",
            msgerrortotaltoneladasenviadas: "",
            msgerrorsaldorejaspiso: "",
            msgerrorobservaciones: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }

    async componentDidMount() {
        if(this.state.accion==2){
            this.setState({
                rancho: this.state.itemseleccionado.idrancho,
                lote: this.state.itemseleccionado.idlote,
                supervisorcosecha: this.state.itemseleccionado.idusuario,
                txtnumflete: this.state.itemseleccionado.numflete,
                txtvehiculos: this.state.itemseleccionado.vehiculo,
                txtplacas: this.state.itemseleccionado.placa,
                txthorasalida: this.state.itemseleccionado.horasalida,
                txthoraentrega: this.state.itemseleccionado.horaentrega,
                personaentrega: this.state.itemseleccionado.idusuario,
                txtpersonarecibe: this.state.itemseleccionado.personarecibe,
                txttotalrejascortadas: this.state.itemseleccionado.rejascortadas,
                txttotaltoneladascortadas: this.state.itemseleccionado.toneladascortadas,
                txtrejaspiso: this.state.itemseleccionado.rejaspiso,
                txttoneladaspiso: this.state.itemseleccionado.toneladaspiso,
                txtrejasenviadas: this.state.itemseleccionado.rejasenviadas,
                txttoneladasenviadas: this.state.itemseleccionado.toneladasenviadas,
                txtsaldorejaspiso: this.state.itemseleccionado.saldorejas,
                txtobservaciones: this.state.itemseleccionado.observaciones,
              });
        }
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    guardarCambios = async () => {
        /*let longitudnuevevalida = { longitudminima: 1, longitudmaxima: 9 };
        let validacionescorrectas = true;

        this.setState({ vermsgerrorlote: false })
        this.setState({ vermsgerrortabla: false })
        this.setState({ vermsgerrorcultivo: false })
        this.setState({ vermsgerrortxtrejas: false })

        if (this.state.lote < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorlote: "Seleccione un lote" })
            this.setState({ vermsgerrorlote: true })
        }

        if (this.state.tabla < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortabla: "Seleccione una tabla" })
            this.setState({ vermsgerrortabla: true })
        }

        if (this.state.cultivo < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorcultivo: "Seleccione un cultivo" })
            this.setState({ vermsgerrorcultivo: true })
        }

        let vtxtrejasestimadas = validarNumeros(this.state.txtrejas, longitudnuevevalida);
        if (vtxtrejasestimadas.codigo == "invalid") {
            this.setState({ msgerrortxtrejas: vtxtrejasestimadas.mensaje })
            this.setState({ vermsgerrortxtrejas: true })
            validacionescorrectas = false;
        }


        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })

            let json = {
                "idcatalogo_ordenes_cosecha": this.state.idordecosecha,
                "idcatalogo_lotes_cultivo_tabla": this.state.tabla,
                //"idlote": this.state.lote,
                //"idcultivo": this.state.cultivo,
                "rejasestimadas": this.state.txtrejas,
            }

            /*toast.info("API en desarrollo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            this.setState({ loadingspinners: false })
            this.showModal('1')*/

            /*var resp = await getDetallesOrdenCosechaGuardarTabla(json);
            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.props.activarSpinner(false);
                return;
            } else if (resp.code == 200 || resp.code == 300) {
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            } else {
                this.showModal('1')
            }
        }*/

        toast.info("API en desarrollo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal('1')
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Inicio Sección selector rancho   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("rancho")}>
                                            {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                {this.state.listaranchos!=undefined || this.state.listaranchos.length>0 ? this.state.listaranchos.map((i, index) => (
                                                    this.state.accion == 1 ?
                                                        <option value={i.idcatalogo_rancho} key={"tipo_" + index} >{i.rancho} </option> : 
                                                        this.state.accion == 2 ?
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + index} selected={i.idcatalogo_rancho == this.state.rancho ? true : false} >{i.rancho} </option> : null
                                                )) : null}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorrancho ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p> : null}

                                {/* ////////////////////   Inicio seleccion lote  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("lote")}>
                                                {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                {this.state.listalotes.map((i, index) => (
                                                    this.state.accion == 1 &&  i.idcatalogo_rancho==this.state.rancho ?
                                                        <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index} >{i.lote} </option> : 
                                                        this.state.accion == 2 &&  i.idcatalogo_rancho==this.state.rancho ?
                                                            <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index} selected={i.idcatalogo_ranchos_lotes_cultivo == this.state.lote ? true : false} >{i.lote} </option> : null
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorlote ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorlote}</p> : null}

                                
                                {/* ////////////////////   Inicio Sección supervisor  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Supervisor de cosecha</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("supervisorcosecha")}>
                                                {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                {this.state.listausuarios.map((i, index) => (
                                                    this.state.accion == 1 ?
                                                        <option value={i.idcatalogousuarios} key={"tipo_" + index} >{i.nombres} </option> : 
                                                        this.state.accion == 2 &&  i.idcatalogousuarios==this.state.supervisorcosecha ?
                                                            <option value={i.idcatalogousuarios} key={"tipo_" + index} selected={i.idcatalogousuarios == this.state.supervisorcosecha ? true : false} >{i.nombres} </option> : null
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorpersonaentrega ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpersonaentrega}</p> : null}


                                {/* ////////////////////   Inicio Sección campo numero de flete  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Numero de flete</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnumflete} onChange={this.escribir("txtnumflete")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnumflete}</label>}

                                {this.state.vermsgerrornumflete ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrornumflete}</p> : null}

                                
                                {/* ////////////////////   Inicio Sección campo vehiculos  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Vehículos</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtvehiculos} onChange={this.escribir("txtvehiculos")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtvehiculos}</label>}

                                {this.state.vermsgerrorvehiculos ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorvehiculos}</p> : null}

                                {/* ////////////////////   Inicio Sección campo placas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Placas</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtplacas} onChange={this.escribir("txtplacas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtplacas}</label>}

                                {this.state.vermsgerrorplacas ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorplacas}</p> : null}

                                {/* ////////////////////   Inicio Sección campo hora salida  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Hora salida</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txthorasalida} onChange={this.escribir("txthorasalida")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txthorasalida}</label>}

                                {this.state.vermsgerrorhorasalida ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorhorasalida}</p> : null}

                                {/* ////////////////////   Inicio Sección campo hora entrega  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Hora entrega</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txthoraentrega} onChange={this.escribir("txthoraentrega")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txthoraentrega}</label>}

                                {this.state.vermsgerrorhoraentrega ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorhoraentrega}</p> : null}


                                {/* ////////////////////   Inicio Sección persona que entrega  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Persona que entrega</p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("personaentrega")}>
                                                {/* ////////////////////   Inicio Sección selector rancho   ////////////////////////////////////// 
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i) => (
                                                    <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}>{i.rancho}</option>
                                                ))}
                                                */}
                                                {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                {this.state.listausuarios.map((i, index) => (
                                                    this.state.accion == 1 ?
                                                        <option value={i.idcatalogousuarios} key={"tipo_" + index} >{i.usuario} </option> : 
                                                        this.state.accion == 2 ?
                                                            <option value={i.idcatalogousuarios} key={"tipo_" + index} selected={i.idcatalogousuarios == this.state.personaentrega ? true : false} >{i.usuario} </option> : null
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorpersonaentrega ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpersonaentrega}</p> : null}


                                {/* ////////////////////   Inicio Sección campo persona que recibe  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Persona que recibe</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtpersonarecibe} onChange={this.escribir("txtpersonarecibe")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtpersonarecibe}</label>}

                                {this.state.vermsgerrorpersonarecibe ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpersonarecibe}</p> : null}

                                {/* ////////////////////   Inicio Sección campo total de rejas cortadas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Total de rejas cortadas</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttotalrejascortadas} onChange={this.escribir("txttotalrejascortadas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttotalrejascortadas}</label>}

                                {this.state.vermsgerrortotalrejascortadas ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortotalrejascortadas}</p> : null}

                                {/* ////////////////////   Inicio Sección campo total de toneladas cortadas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Total de toneladas cortadas</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttotaltoneladascortadas} onChange={this.escribir("txttotaltoneladascortadas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttotaltoneladascortadas}</label>}

                                {this.state.vermsgerrortotaltoneladascortadas ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortotaltoneladascortadas}</p> : null}

                                {/* ////////////////////   Inicio Sección campo rejas en piso  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rejas en piso</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtrejaspiso} onChange={this.escribir("txtrejaspiso")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtrejaspiso}</label>}

                                {this.state.vermsgerrorrejaspiso ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrejaspiso}</p> : null}

                                {/* ////////////////////   Inicio Sección campo toneladas en piso  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Toneladas en piso</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttoneladaspiso} onChange={this.escribir("txttoneladaspiso")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttoneladaspiso}</label>}

                                {this.state.vermsgerrortoneladaspiso? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortoneladaspiso}</p> : null}

                                {/* ////////////////////   Inicio Sección campo total rejas enviadas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Total rejas enviadas</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtrejasenviadas} onChange={this.escribir("txtrejasenviadas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtrejasenviadas}</label>}

                                {this.state.vermsgerrortotalrejasenviadas? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortotalrejasenviadas}</p> : null}

                                {/* ////////////////////   Inicio Sección campo total toneladas enviadas  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Total toneladas enviadas</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttoneladasenviadas} onChange={this.escribir("txttoneladasenviadas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttoneladasenviadas}</label>}

                                {this.state.vermsgerrortotaltoneladasenviadas? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortotaltoneladasenviadas}</p> : null}

                                {/* ////////////////////   Inicio Sección campo saldo rejas en piso  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Saldo rejas en piso</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtsaldorejaspiso} onChange={this.escribir("txtsaldorejaspiso")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtsaldorejaspiso}</label>}

                                {this.state.vermsgerrorsaldorejaspiso? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorsaldorejaspiso}</p> : null}

                                {/* ////////////////////   Inicio Sección campo observaciones  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Observaciones</p>
                                {this.state.accion == 1 || this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtobservaciones} onChange={this.escribir("txtobservaciones")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtobservaciones}</label>}

                                {this.state.vermsgerrorobservaciones? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorobservaciones}</p> : null}

                            </div>
                        </div>

                        {this.state.accion == 1 || this.state.accion == 2 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

