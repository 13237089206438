import React from 'react'
import "./ModalFormularioMoverComprasAlmacenAgroquimicos.css";
import { estiloFiltroEncabezados, estiloBtnActualizar, estilosModal } from '../../../Services/PaletaDeColores';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';


export default class ModalFormularioMoverComprasAlmacenAgroquimicosTablaMovimiento extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            titulo: props.titulomodal,
            total: 0,
            tabla: {
                encabezados: [
                    "Producto",
                    "Unidad de medida",
                    "Cantidad movida",
                ],
                dimensiones: [
                    "40%",
                    "30%",
                    "30%",
                ],
                mindimensiones: [
                    "300px",
                    "200px",
                    "200px",
                ]
            },

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            estilosmodal: estilosModal(),

            colapsartabla: true,

        }

    }



    render() {
        return (

            <>
                {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
                <div className="marginTopSeparator" >
                    <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                        <div className='row flexNoWrap'>
                                            <div className='col-1 containerSubTablaAA minPaddingTopBottom' >
                                                <button className='btnCollapsar letraNormal'
                                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                                    onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                                            </div>
                                            <div className='col-8 containerSubTablaAA minPaddingTopBottom'>
                                                <p className='sinMargen'><b>{this.props.contenido.nombrealmacen}</b></p>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={this.state.colapsartabla} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <div className='tablaProductoCotizaciones'>
                                                    {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                                    <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
                                                        <div className="container-fluid">
                                                            <div className='row'>
                                                                <div>
                                                                    <Table hover>
                                                                        <thead style={{ verticalAlign: "bottom" }}>
                                                                            <tr style={{ borderBottomWidth: "1px" }}>
                                                                                {this.state.tabla.encabezados.map((titulo, index) => (
                                                                                    <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                                        style={{
                                                                                            minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                                            textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                                                        }}>
                                                                                        {titulo}
                                                                                    </th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ verticalAlign: "bottom" }}>
                                                                            {this.props.contenido.productos.map((item, index) => (

                                                                                <tr style={{ borderBottomWidth: "1px" }}>
                                                                                    <td key={"col-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                        {item.producto}
                                                                                    </td>
                                                                                    <td key={"col-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                        {item.unidadmedida}                                                                                                </td>
                                                                                    <td key={"col-03"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                        {item.cantidad}
                                                                                        {(item.activo) ? (
                                                                                            (item.activo == 1) ? (<><br/>(Activo fijo)</>) : (null)
                                                                                        ) : (null)}
                                                                                    </td>

                                                                                </tr>
                                                                            ))}

                                                                            <br /><br />
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

            </>
        )
    }

}

