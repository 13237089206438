import React from 'react'

import './CatalogoPropuestaAnual.css';

import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { estiloBtnActualizar, estiloBtnEditar, estilosModal, estiloFiltroEncabezados,estiloBtnNuevo} from '../../Services/PaletaDeColores';


import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ModalSeleccionMeses from '../../Complements/Modales/ModalFormularioPropuestaAnual/ModalSeleccionMeses';
import ModalAgregarTablas from '../../Complements/Modales/ModalFormularioPropuestaAnual/ModalAgregarTablas';
import cloneDeep from 'lodash/cloneDeep';

export default class TablaActividad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion: props.accion, // 1 Nuevo, 2 editar
      opcioneditar: props.accion == 1 ? false : true,

      tabla: {
        encabezados: [
          "Cultivo",
          "Tabla",
          "Hectárea",
          "Áboles"], 
        dimensiones: [
          "10%",
          "10%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "110px",
          "120px",
          "100px",
          "60px"
        ],
        mindimensionesmeses: [
          "80px"
        ],
  
      },
      itemactividad: this.props.actividad,
      listaproductosgregados: [],
      listacatalogotablasagregadas: this.props.actividad.tablas,

      listatotales:[],
      tablameses: [],
      modalmeses: false,
      modaltablas: false,
  
      colapsartabla:true,
      eliminartabla:false,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,

      btnbordereditar: estiloBtnEditar().btnbordereditar,

      estilosmodal: estilosModal(),
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }
    this.calcularTotales(this.props.actividad);
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if(resp.code == 300 || resp.code == 200){
      if(!this.state.errordeacceso){
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
        
      this.setState({ 
        loadingspinners: false,
        errordeacceso:true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if (resp.code== 401) {
      this.setState({ salir: true })
      return true;
    }
    return false
  }

  componentWillReceiveProps(nextProps) {
    // ¡Esto borrará cualquier actualización del estado local!
    const tablameses = cloneDeep(nextProps.actividad.tablas);
    this.setState({ itemactividad: nextProps.actividad,
      tablameses: tablameses
     });
    this.calcularTotales(nextProps.actividad);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }


  actualizarListaNuevosProductos = (nuevoproducto) =>{
    this.props.actualizarListaNuevosProductos(nuevoproducto);
  }

  verModalMeses = (ban) => {

    if (ban.opcion == 1) { 
      this.setState({ titulomodal: 'Seleccionar meses', accionmodal: 1 })
    }

    this.setState({ modalmeses: true });
  }

  cerrarModalMeses =  (meses,accion) => {
    this.setState({ modalmeses: false });

    if(accion != 0){
      var respaldoinfo = this.props.respaldoinfopropuesta;
      var actividadaux = this.state.itemactividad;
      for (let index = 0; index < actividadaux.tablas.length; index++) {
          var tabla = actividadaux.tablas[index];
           const mesestabla = cloneDeep(tabla.meses)
          for (let indexj = 0; indexj < mesestabla.length; indexj++) {
            const mes = mesestabla[indexj];
            var indexmesa =  meses.findIndex((obj => obj.idmes == mes.idmes));
            if(indexmesa < 0){
              var mesesx = tabla.meses.filter(obj => obj.idmes != mes.idmes);
              
              tabla.meses = mesesx;
            }
          }
          actividadaux.tablas[index]= tabla;

          for (let indexk = 0; indexk < meses.length; indexk++) {
            const mes = meses[indexk];
          
           var idmestablaactividad_presupuestorancho = -1;
           if(JSON.stringify(respaldoinfo) !== '{}'){
            var indexact =  respaldoinfo.actividades.findIndex((obj => obj.idactividad == actividadaux.idactividad));
            if(indexact > -1){
              var actividadresp = respaldoinfo.actividades[indexact];
              var indextabla =  actividadresp.tablas.findIndex((obj => obj.idtabla == tabla.idtabla));
              if(indextabla > -1){
                var tablaresp = actividadresp.tablas[indextabla];
                var indexmes =  tablaresp.meses.findIndex((obj => obj.idmes == mes.idmes));
                if(indexmes > -1){
                  idmestablaactividad_presupuestorancho = tablaresp.meses[indexmes].idmestablaactividad_presupuestorancho;
                
                }
              }
            }
           }
          
           var nuevomes = {
            "idmestablaactividad_presupuestorancho":idmestablaactividad_presupuestorancho != -1 ? idmestablaactividad_presupuestorancho : 0,
            "idmes":meses[indexk].idmes,
            "mes":meses[indexk].mes,
            "presupuesto":meses[indexk].presupuesto
         }
            var indexmesb =  tabla.meses.findIndex((obj => obj.idmes == mes.idmes));
            if(indexmesb < 0){
              
              tabla.meses.push(nuevomes)
            }
          }
          
          tabla.meses.sort(function(a,b) {
            return a.idmes - b.idmes;
          });

          actividadaux.tablas[index] = tabla;
      }
      this.setState({ 
        modaltablas: !this.state.modalmeses,
        itemactividad: actividadaux
      })

      setTimeout(async () => {
      await  this.props.actualizarContenido(actividadaux)
      await  this.calcularTotales(actividadaux);
    }, 200);
    }
    
   
   
  }

  escribirPresupuesto = async (indextabla, indexmes, prop, event) => {
    var longitud = {enterosMinimo: 1, enterosMaximos: 7, decimalesMinimo: 0, decimalesMaximo: 3};
    
    var valores = event.target.value.split('.')
    var valorreal = "";
    if(valores.length > 1){
      valorreal = valores[0].slice(0,8) + "." +valores[1].slice(0,2)
    }else{
      valorreal = valores[0].slice(0,8)
    }
    
    //actualizamos los valores de presupuesto por mes
    this.state.itemactividad.tablas[indextabla].meses[indexmes][prop] = valorreal;
    var itemactividadaux = this.state.itemactividad
      
    this.setState({ itemactividad: itemactividadaux });
    
    await  this.props.actualizarContenido(itemactividadaux)

    this.calcularTotales(itemactividadaux);
     
  }

 
  calcularTotales = async (itemactividad) => {
   
    var itemactividadaux = itemactividad;
    var listatotalmeses = [];
      
      for (let indextabla = 0; indextabla < itemactividadaux.tablas.length; indextabla++) {
        const tabla = itemactividadaux.tablas[indextabla];
        tabla.meses.sort(function(a,b) {
          return a.idmes - b.idmes;
        });
        tabla.meses.forEach(element => {
          var valorp = element.presupuesto == 0 ? element.presupuesto : element.presupuesto.replaceAll(',','')
          var valorpresupuesto = valorp != "" ? parseFloat(valorp)  : 0 
         var mes = {
            idmes: element.idmes,
            totalpresupuesto: valorpresupuesto
          }
          var indexmes =  listatotalmeses.findIndex((mes => mes.idmes == element.idmes));
          if(indexmes > -1){
            listatotalmeses[indexmes].totalpresupuesto = listatotalmeses[indexmes].totalpresupuesto +  valorpresupuesto;
          }else{
            listatotalmeses.push(mes)
          }

          
        });
        
      }

      this.setState({ listatotales: listatotalmeses });
      
  
  }

  verModalTablas = (ban) => {

    if (ban.opcion == 1) { 
      this.setState({ titulomodal: 'Agregar Tablas a Herbicidas', accionmodal: 1 })
    }
    if(this.props.ranchoseleccionado != "TodosLosRanchos"){
      this.setState({ modaltablas: true });
    }else{
      toast.info("Deberá seleccionar un rancho", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }
    
  }

  cerrarModalTablas = async (tablas,accion) => {
    this.setState({ 
      modaltablas: !this.state.modaltablas
    })
    if(accion != 0){
      var actividadaux = this.state.itemactividad;
      actividadaux.tablas = tablas;
      this.setState({ 
        itemactividad: actividadaux
      })
      
      await this.props.actualizarContenido(actividadaux)
      this.calcularTotales(actividadaux);
    }
    
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }
  render() {
    return (

      <>
       {(this.state.modalmeses == true) ? (
          <ModalSeleccionMeses titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalMeses}
            show={this.state.modalmeses} accion={this.state.accionmodal}  
            activarSpinner={this.activarSpinner}
            tablameses={this.state.tablameses}
            
            />
        ) : null}

        {(this.state.modaltablas == true) ? (
          <ModalAgregarTablas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalTablas}
            show={this.state.modaltablas} accion={this.state.accionmodal}  
            listalotes={this.props.listalotes}
            listacatalogotablas={this.props.listacatalogotablas}
            listacatalogotablasagregadas={this.props.actividad.tablas}
            activarSpinner={this.activarSpinner}
            ranchoseleccionado={this.props.ranchoseleccionado}
            idactividad={this.props.actividad.idactividad}
            respaldoinfopropuesta={this.props.respaldoinfopropuesta}
            />
        ) : null}
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <br/>
            <div className="">
              <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                        <div className='row flexNoWrap'>
                          <div className='col-auto minPaddingTopBottom'>
                            <button className='btnCollapsar letraNormal'
                              style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                              onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                          </div>
                          <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                          <p className='sinMargen'><b>{this.props.actividad.actividad}</b></p>
                          </div>
                        </div>
                          
                      </TableCell>
                  
                      <TableCell align="right" className='displayFlex nonePaddingTopBottom' >
                        <div className='row flexNoWrap'>
                          <div className='col-auto minPaddingTopBottom'>
                            <button className='btnNuevo letraNormal'
                              style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.fondobtnnuevo}}
                              onClick={this.verModalTablas.bind(this, { opcion: "1" })}
                            >+ Tablas</button>
                          </div>
                          <div className='col-auto minPaddingTopBottom'>
                            <button className='btnNuevo letraNormal'
                              style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.fondobtnnuevo}}
                              onClick={this.verModalMeses.bind(this, { opcion: "1" })}
                              disabled={this.props.actividad.tablas.length > 0 ? false: true}
                             >+ Meses</button>
                          </div>
                         
                        </div>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                       
                              <div className="container-fluid containerTabla" style={{ backgroundColor: '#ffffff' }}>
                                <div className="container-fluid anchoMaximo">
                                  <div className='row contenedorNowrap' >
                                    <div className='col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 contenedorTPrincipal'>
                                        
                                      <Table hover>
                                          <thead style={{ verticalAlign: "bottom" }}>
                                              <tr>
                                                {
                                                  this.state.itemactividad.tablas.length > 0 ?
                                                  this.state.tabla.encabezados.map((titulo, index) => (
                                                    <th key={"tabla" + index} className={(index != 0 && index != 1) ? "centerText  letraTitulos" : " letraTitulos"}
                                                      style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                      {titulo}
                                                    </th>
                                                  )): <div className='letraNormal lblSubtabla'>Sin información</div>
                                                }
                                                
                                              </tr>
                                          </thead>

                                          <tbody className='tablaActividad' style={{ verticalAlign: "bottom" }}>
                                            {this.state.itemactividad.tablas.map((item, index) => (
                                              <tr key={index}  >
                                                <td key={"col-"+index + "-1"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                  {item.cultivo}
                                                </td>
                                                <td key={"col-"+index + "-2"} className="letraNormal lblSubtabla text-truncate" style={{ color: this.state.colorcontenidotabla }}>
                                                  {item.tabla}
                                                </td>
                                                <td key={"col-"+index + "-3"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                                                  {item.hectareas}
                                                </td>
                                                <td key={"col-"+index + "-4"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                                                  {item.arboles}
                                                </td>
                                            
                                              </tr>
                                            
                                            ))}
                                          </tbody>
                                          {
                                             this.state.itemactividad.tablas.length > 0 ?
                                             <thead style={{ verticalAlign: "bottom" }} className='tablaActividad'>    
                                            <tr key={"tabla-total" }  >
                                                        <th className=" lblEncabezadosTabla letraTitulos border-0"  style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                                                            Total
                                                        </th>
                                                        <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}>
                                                            
                                                        </th>
                                                        <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}>
                                                            
                                                        </th>
                                                        <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}>
                                                        
                                                        </th>
                                            </tr>
                                          </thead>: null
                                          }
                                          
                                      </Table>
                                    </div>
                                    <div className='col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 contenedorMeses'>

                                      {
                                        this.state.itemactividad.tablas.length > 0 ? 
                                        <Table hover>

                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.itemactividad.tablas[0].meses.map((mes, index) => (
                                                      <th key={"th" + index} className={ "centerText  letraTitulos"}
                                                        style={{ minWidth: this.state.tabla.mindimensionesmeses[0], width: this.state.tabla.mindimensionesmeses[0], color: this.state.colorencabezadostabla }}>
                                                        {mes.mes}
                                                      </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            
                                            <tbody className='tablaActividad ' style={{ verticalAlign: "bottom" }}>
                                              {this.state.itemactividad.tablas.map((itemtabla, indextabla) => (
                                                itemtabla.meses.length > 0 ?
                                                <tr key={indextabla}  >
                                                {  itemtabla.meses.map((itemmes, indexmes) => (
                                                    <td key={"td-"+indexmes} className="letraNormal celdasMeses">
                                                  <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla"
                                                  style={{textAlign:"right"}}
                                                  value={itemmes.presupuesto == 0 ? itemmes.presupuesto : itemmes.presupuesto.replaceAll(',','')} 
                                                  onChange={(e) => this.escribirPresupuesto(indextabla,indexmes, 'presupuesto', e)}></input>
                                                  </td>
                                                  ))}
                                                 
                                                </tr>: null
                                              
                                              ))}
                                            </tbody>
                                            <thead style={{ verticalAlign: "bottom" }} className='tablaActividad'>
                                                    
                                                    <tr key={"tabla-total" }  >
                                                       {this.state.listatotales.map((totalmes, index) => (
                                                          <th key={"td-"+index} className="letraNormal  celdasMeses">
                                                        <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla " value={parseFloat(totalmes.totalpresupuesto).toFixed(2)} 
                                                        style={{textAlign:"right"}}
                                                        readonly disabled
                                                        ></input>
                                                        </th>
                                                        ))}
                                                             
                                                      </tr>
                                                </thead>
                                        </Table>
                                        : null
                                      }
                                        
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br/>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}