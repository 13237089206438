import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';

export default class ModalBuscarActividadesServicios extends React.Component {

  constructor(props) {
    super(props)
    console.log("ModalBuscarActividadesServicios", props)
    this.state = {
      show: props.show,    
      accion: props.accion, // 1 Nuevo, 2 editar
      confirmarenvio: false,
      txtbuscador: "",
      tabla: {
        encabezados: [
          "Actividad",
          "Tipo actividad",
          "Descripción"
        ],
        dimensiones: [
          "40%",
          "20%",
          "40%"
        ],
        mindimensiones: [
          "200px",
          "200px",
          "200px"
        ],
        elementos: [],
      },
    
      actividadseleccionada: undefined,
      idactividadseleccionada: 0,
      filtrotipoactividad: "TodasLasActividades",
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      listatipoactividades: this.props.listatipoactividades,
      listaactividadesfiltradas: [],
    }

  }

  async componentDidMount() {
    var tabla = this.state.tabla;
      tabla.elementos = this.props.listaactividades;    
 
    this.setState({
      txtbuscador: '',
      tabla: tabla,
      listaactividadesfiltradas: tabla.elementos
    })

  }


  saveInputLocacion = async (e) => {
    this.setState({ txtbuscador: e.target.value });
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    
    var listafiltrotipo = data;

    if (this.state.filtrotipoactividad != "TodasLasActividades") {
      listafiltrotipo = data.filter((item) => item.idtipoactividad == this.state.filtrotipoactividad)
    } 

    if (e.target.value.trim().length > 2) {
      const filtros = listafiltrotipo.filter((item) => 
      item.actividad.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length < 1) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ listaactividadesfiltradas: filtros });
    } else if (e.target.value.trim().length < 1) {
      this.setState({ listaactividadesfiltradas: listafiltrotipo });
    }

  }

  filtrarTipoActividad = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      filtrotipoactividad: itemfiltrado
    })

    var filtrofinal = [];
    var listafiltrotipo = [];

    listafiltrotipo = data;
    if (itemfiltrado != "TodasLasActividades") {
      listafiltrotipo = data.filter((item) => item.idtipoactividad == itemfiltrado)
    }
   
    filtrofinal = listafiltrotipo;

    if (this.state.txtbuscador.length > 2) {
      filtrofinal = listafiltrotipo.filter((item) => item.actividad.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    }else{
      filtrofinal=listafiltrotipo
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    await this.setState({
      listaactividadesfiltradas: filtrofinal
    })

  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      filtrotipoactividad: "TodasLasActividades",
      listaactividadesfiltradas: this.state.tabla.elementos
    });
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);  
  }

  guardarCambios = async () => {
    this.showModal(this.state.actividadseleccionada)
  }

  seleccionarActividad = async (item) => {
    console.log("seleccionarActividad", item)
    await this.setState({
      actividadseleccionada: item,
      confirmarenvio: true,
      idactividadseleccionada: item.idactividad
    })
  }





  render() {
    return (

      <>
       
        <ToastContainer />

       
       
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.props.titulomodal}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>
               
                  <div className='col-4 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarTipoActividad(event.target.value)}>
                          {this.state.filtrotipoactividad == "TodasLasActividades" ?
                            <option selected="true" value='TodasLasActividades'>Todas</option> : <option value='TodasLasActividades'>Todas</option>}
                          {this.state.listatipoactividades.map((i, index) => (
                            <option value={i.idtipoactividad} key={"tipo_" + index}  >{i.tipo} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                    <div className='txtBuscadorCN' >
                      <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                      <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                        placeholder="Buscar clave/nombre"></input>
                      {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                        onClick={this.borrarBusqueda} />) : null}
                    </div>
                  </div>

                </div>
               

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla tablaMaxHeight">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {
                            this.state.listaactividadesfiltradas.map((item, index) => (
                              <tr key={"tabla" + index}
                                onClick={() => this.seleccionarActividad(item)}
                                className={this.state.idactividadseleccionada==item.idactividad ?
                                
                                   "trFondoActivoGreen" : ""}>
                                
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.actividad}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.tipoactividad}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.descripcion}
                                </td>
                              </tr>
                            ))

                          }

                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

