import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNivelesAutorizacion.css';
import { BsListUl } from "react-icons/bs";
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { estiloBtnActualizar, estiloBtnEditar, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalRelacionNivelesAutorizacion from '../../Complements/Modales/ModalNivelesAutorizacion/ModalRelacionNivelesAutorizacion';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getCatalogoNivelesAutorizacion, getComboAreasDeTrabajo, getListaPuestosDeTrabajo } from '../../Services/Api';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


export default class CatalogoNivelesAutorizacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,

      listaareastrabajo: [],
      listapuestostrabajo: [],

      tabla: {
        encabezados: [
          "Nivel de autorización",
          "Puestos",
          "Descripción"],
        dimensiones: [
          "30%",
          "20%",
          "30%"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px"
        ],
        elementos: []
      },
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
      return;
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var respcatalogo = await getCatalogoNivelesAutorizacion();
    if (this.manejadorLlamadasApis(respcatalogo)) {
      return;
    }

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    var resplistapuestos = await getListaPuestosDeTrabajo();
    if (this.manejadorLlamadasApis(resplistapuestos)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = respcatalogo.data;

    await this.setState({ 
      listaareastrabajo: resplistaareas.data,
      listapuestostrabajo: resplistapuestos.data, 
      tabla: tabla 
    });

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Relación nivel', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Puestos agregados exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Puestos editados exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
      });
    }, 0);
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalRelacionNivelesAutorizacion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal}
            show={this.state.modaldetalles} accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} 
            item={this.state.itemseleccionado}
            listaareastrabajo={this.state.listaareastrabajo}
            listapuestostrabajo={this.state.listapuestostrabajo}
            />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end'>
              <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Niveles de autorización</label>
              </div>

              <div className='col-auto offset-lg-4 offset-xl-5 containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {
                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nivel}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            <div className='row'>
                              <div className='col-6 anchopuestos'>
                                {item.puestos.length}
                              </div>
                              <div className='col-6 anchopuestos2'>
                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                                  backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                }}><BsListUl className="icoBotones" /></button>
                              </div>
                            </div>
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>
                      </tr>
                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.tabla.elementos}

            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
