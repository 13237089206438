import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { coloresTxtBuscador, estiloColorError, estiloBtnActualizar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloBtnEditar } from '../../Services/PaletaDeColores';
import { Button } from 'reactstrap';
import ContenedorTabla from './ContenedorTabla';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getInformacionRequisicionServicios, } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicionServicio/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicionServicio/ModalFormularioAccionEstatusContrasena';
registerLocale('es', es)

export default class AutorizarRequisicionServicios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      plananual: false,
      txtnombrerequisicion: '',
      txttiporequesicion: -1,
      txtfechaestimadacompra: '',
      txtanio: '' + new Date().getFullYear(),
      idperiodo: '',
      txtactividad: undefined,
      txtmes: undefined,
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,


      idrequisicion: -1,
      opcioneditar: false,
      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,

      cargadeinformacion: false,

      zonas: [],
      listanuevosproductos: [],
      inforequisicion: {},

      requestbodyrequisicion: {},
      tablasrequestbody: [],
      stockrequestbody: {
        idstock: "0",
        productos: [
        ]
      },
      stock: {
        productos: [
        ]
      },
      stockactivo: false,

      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Proveedor",
          ""
        ],
        dimensiones: [
          "30%",
          "20%",
          "15%",
          "30%",
          "5%",
        ],
        mindimensiones: [
          "130px",
          "170px",
          "120px",
          "120px",
          "30px",
        ],
        elementos: [
        ],
        total: ""
      },
      productoresumenseleccionado: undefined,
      modalguardar: false,
      modaldestino: false,
      anioseleccionado: new Date().getFullYear(),

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colormsgerror: estiloColorError().msgerror,

      //lista catálogos
      productosresumeninfo: [],


      fechaestimadacompra: new Date(),

      redireccionando: false,

      estatuseditable: false,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }


    var idrequisicion = await JSON.parse(localStorage.getItem("dato"));
    var inforequisicion = await JSON.parse(localStorage.getItem("req"));


    this.setState({
      idrequisicion: idrequisicion,
      // idrequisicion: JSON.parse(localStorage.getItem("req")).dato//idreq
    })


    if (/*this.state.opcioneditar &&*/ !this.state.cargadeinformacion) {

      var inforequisicion = await getInformacionRequisicionServicios({ idrequisicion: idrequisicion });
      if (this.manejadorLlamadasApis(inforequisicion)) {
        return;
      }

      // Define si los botones de Aprobar o Rechazar requisición se incluirán
      var estatuseditable = inforequisicion.data[0].idestatusrequisicion == 2

      var [year, month, day] = inforequisicion.data[0].fechaestimada_entrega.split("/");
      var fecha = day + "-" + month + "-" + year;
      await this.setState({
        inforequisicion: inforequisicion.data[0],
        zonas: inforequisicion.data[0].zonas,
        stock: inforequisicion.data[0].stock,
        cargadeinformacion: true,
        estatuseditable: estatuseditable,
        idperiodo: inforequisicion.data[0].idperiodo,
        productosresumeninfo: inforequisicion.data[0].servicios
      })
    }

    this.cambiarFecha({ llave: "fechaestimadacompra", valor: this.state.fechaestimadacompra });
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }


  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: day + '/' + month + '/' + year })

    // this.consultarCatalogoCompra(['txt' + item.llave], year + '-' + month + '-' + day)
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }



  editar = () => {
    console.log("editar", this.state.inforequisicion)
    localStorage.setItem("req", JSON.stringify({ idreq: this.state.inforequisicion.idcatalogo_requisicion, opcion: "Requisicion" }))
    this.setState({ redireccionandoarequisicion: true })
  }





  
  //elementos para aprobar y rechazar la requisición 
  verModalFormularioAccionEstatus = (ban) => {
    const aux = this.state.inforequisicion
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.idestatusrequisicion*/ }
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
    else if (ban.id == 2) {
      this.setState({ titulomodal: 'Aprobar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatus = (opciones) => {
    this.setState({ modalaccionestatus: !this.state.modalaccionestatus })
    if (opciones.accion == 1) {
      this.verModalFormularioAccionEstatusContrasena(opciones)
    }
    else if (opciones.accion == 2) {
      this.verModalFormularioAccionEstatusContrasena({ id: 2, item: opciones.item })
    }
  }

  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      const aux = this.state.inforequisicion
      var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion, idestatusrequisicion: 3 /* id de autorizar */ }
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.accion == 1) {
      this.redireccionando()
    }
  }




  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />

          </>
          : null}

        {(this.state.redireccionandoarequisicion) ? (<Acciones bandera={'irarequisicion'} />) : null}
        {(this.state.redireccionando) ? (<Acciones bandera={'catalogoreq'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}





{(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}






        <Navbar />
        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        {<div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">

            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.inforequisicion.nombre}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.tiporequisicion}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechaestimada_entrega}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Solicitante: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.solicitante}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechacreacion}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Estatus: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.estatusrequisicion}</label>
              </div>

              <div className='col-6 col-sm-6 col-md-6 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos'>{this.state.inforequisicion.fechaactualizacion}</label>
              </div>

            </div>
          </div>

        </div>
        }
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <br />
        <br />
        <div className="container-fluid">
          <div className="container-fluid anchoMaximo">
            <div className="container-fluid">
              <div className='spacePaddingBottom'>
                <ContenedorTabla key={"tabla_stock"} accion={this.state.accion}

                  itemtabla={this.state.inforequisicion.servicios ? this.state.inforequisicion : { servicios: [] }}

                />
              </div>
            </div>

          </div>


          {parseInt(this.state.inforequisicion.idestatusrequisicion) == 2 && //this.state.estatuseditable==true &&
            <div className="divBtnContenedorRequisicion" >
              <Button className="btnRechazar" onClick={this.verModalFormularioAccionEstatus.bind(this, { id: 1 })}>Rechazar requisición</Button>
              <Button className="btnAceptar" onClick={this.verModalFormularioAccionEstatusContrasena.bind(this, { id: 2, accion: 1 })}>Aprobar requisición</Button>
            </div>
          }




          {parseInt(this.state.inforequisicion.idestatusrequisicion) == 1 || parseInt(this.state.inforequisicion.idestatusrequisicion) == 2
            || parseInt(this.state.inforequisicion.idestatusrequisicion) == 4 ?
            <div className="divBtnContenedorRequisicion" >
              <Button className="btnAceptar" onClick={() => this.editar()}>Editar requisición </Button>
            </div>
            : null
          }
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}



      </>
    )
  }
}



function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  } else if (bandera == "catalogoreq") {
    return (<Redirect from='/' to={"/catalogorequisicionesservicios"} />)

  }else if (bandera == 'irarequisicion') {
    return (<Redirect from='/' to={"/editarrequisicionservicios"} />)
  }
}
