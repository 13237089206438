import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoReportes.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { format } from 'date-fns';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioTipoClientes from '../../Complements/Modales/ModalFormularioTipoClientes/ModalFormularioTipoClientes';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoProveedor, getEstatus, getListaTipoCliente, getListaTipoProveedor, getReportes } from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from 'react-icons/fa';
import ExportarCSV from '../../Complements/exportarcsv/exportarcsv';
import { IoEye } from "react-icons/io5";
import ExportarCSVreporte from '../../Complements/exportarcsv/exportarCSVreporte';


export default class CatalogoReportes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,


            tablaGenerales: {
                encabezados: [
                    "Total general",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaProveedor: {
                encabezados: [
                    "Total proveedor",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"

                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposGenerales: {
                encabezados: [
                    "Total generales",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposAgroquimicos: {
                encabezados: [
                    "Total agroquímicos",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposCombustibles: {
                encabezados: [
                    "Total combustibles",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////
            tablaTiposDirectas: {
                encabezados: [
                    "Total C. directas",
                    "Total compras",
                    "Total",
                    "Adeudo",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            ////////////////////////////////////////

            tabla: {
                encabezados: [
                    "Tipo cliente",
                    "Descripción",
                    "Estatus",
                    ""
                ],
                dimensiones: [
                    "20%",
                    "20%",
                    "15%",
                    "15%",
                    "12%"
                ],
                mindimensiones: [
                    "90px",
                    "90px",
                    "80px",
                    "80px",
                    "90px"
                ],
                elementos: []
            },
            estatus: [],
            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,
            errordeacceso: false,
            jsoncsv: [],        
            verCSV: false,

            fechacompraini: new Date(),
            fechacomprafin: new Date(),
            gtotalCompras: "0.00",
            gtotal: "0.00",
            gadeudo: "0.00",

            idprovedorselect: -1,
            nameprovedorselect: "",
            arrayprovedor: [],
            //paginacion
            activepage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,
            colorverlbl: estiloBtnVer().colorverlbl,
            borderver: estiloBtnVer().borderver,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
            btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
            btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

        }
    }

    async componentDidMount() {

        this.setState({ txtbuscador: "" })
        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
            return;
        }

        if (!this.state.loadingspinners) {
            this.setState({ loadingspinners: true });
        }

        var resp = await getComboTipoProveedor();
        if (this.manejadorLlamadasApis(resp)) {
            return;
        }
        this.setState({ arrayprovedor: resp.data })
        this.actualizar();
        this.dimensiones();
        window.addEventListener("resize", this.dimensiones);

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    actualizar = async () => {

        this.setState({ loadingspinners: true })
        this.setState({ verCSV: false })

        var fechaStrat = "";
        var fechaEnd = "";
        if (localStorage.getItem('report') == null || localStorage.getItem('report') == undefined) {
            fechaStrat = format(this.state.fechacompraini, 'dd/MM/yyyy');
            fechaEnd = format(this.state.fechacomprafin, 'dd/MM/yyyy');
        }
        else {
            var json2 = JSON.parse(localStorage.getItem("report"))
            fechaStrat = json2.fechainicio
            fechaEnd = json2.fechafin

            var parse_fechaStrat = fechaStrat.split("/");
            var parse_fechafin = fechaEnd.split("/");


            const fechaStartfin = new Date(parse_fechaStrat[2], parse_fechaStrat[1] - 1, parse_fechaStrat[0]);
            const fechaEndfin = new Date(parse_fechafin[2], parse_fechafin[1] - 1, parse_fechafin[0]);

            this.setState({ fechacompraini: fechaStartfin, fechacomprafin: fechaEndfin })
            localStorage.removeItem("report")
        }

        let json = {
            "fechainicio": fechaStrat,
            "fechafin": fechaEnd,
            "tipo": "Todos"
            //"idproveedor":this.state.idprovedorselect
        }
        if (this.state.idprovedorselect != -1) {
            json.idproveedor = this.state.idprovedorselect;
        }

        var resp = await getReportes(json);
        if (this.manejadorLlamadasApis(resp)) {
            return;
        }

        var res = resp.data
        var tabla = this.state.tabla;
        tabla.elementos = res;

        var tablaP = this.state.tablaProveedor;
        tablaP.elementos = res.proveedores;

        var tablaTP = this.state.tablaTiposGenerales;
        tablaTP.elementos = res.tipos.Generales;

        var tablaPA = this.state.tablaTiposAgroquimicos;
        tablaPA.elementos = res.tipos.Agroquímicos;

        var tablaPC = this.state.tablaTiposCombustibles;
        tablaPC.elementos = res.tipos.Combustibles;


        var tablaPTD = this.state.tablaTiposDirectas;
        tablaPTD.elementos = res.tipos.Directas;



        this.setState({
            // arrayprovedor: resp.data,
            gtotalCompras: res.general.totalCompras,
            gtotal: res.general.total,
            gadeudo: res.general.adeudo,
            tablaProveedor: tablaP,
            tablaTiposGenerales: tablaTP,
            tablaTiposAgroquimicos: tablaPA,
            tablaTiposCombustibles: tablaPC,
            tablaTiposDirectas: tablaPTD

        })

      
        setTimeout(() => {  
            this.crearCSV()
           
        }, 500);

        this.setState({ loadingspinners: false })
    }


    crearCSV = () => {

        var json = JSON.parse(JSON.stringify(this.state.tablaGenerales.encabezados));
        json.pop()
        var arrayGeneral = [];
        var arraytempp = [];
        arraytempp.push("");
        arraytempp.push(this.state.gtotalCompras);
        arraytempp.push(this.state.gtotal);
        arraytempp.push(this.state.gadeudo);
        

        var arrayspacer = [];
        for (let i = 0; i < 4; i++) {
            arrayspacer.push("");
        }
        ////
        var jsontablaProveedor = JSON.parse(JSON.stringify(this.state.tablaProveedor.encabezados));
        jsontablaProveedor.pop()
        var jsonProveedor = this.state.tablaProveedor.elementos;
        var contenProveedor = [];
        var contenProveedor2 = [];
        for (let i = 0; i < jsonProveedor.length; i++) {

            contenProveedor2 = [];
            contenProveedor2.push(jsonProveedor[i].proveedor);
            contenProveedor2.push(jsonProveedor[i].totalCompras);
            contenProveedor2.push(jsonProveedor[i].total);
            contenProveedor2.push(jsonProveedor[i].adeudo);

            contenProveedor.push(contenProveedor2)
        }
        /////////////////// tablaTiposGenerales //////////////////////////////////////////////
        var jsontablaTiposGenerales = JSON.parse(JSON.stringify(this.state.tablaTiposGenerales.encabezados));
        jsontablaTiposGenerales.pop()
        var jsonTiposGenerales = this.state.tablaTiposGenerales.elementos;
        var contenTG = [];
        var contenTG2 = [];
        for (let i = 0; i < jsonTiposGenerales.length; i++) {
            contenTG2 = [];
            contenTG2.push(jsonTiposGenerales[i].proveedor);
            contenTG2.push(jsonTiposGenerales[i].totalCompras);
            contenTG2.push(jsonTiposGenerales[i].total);
            contenTG2.push(jsonTiposGenerales[i].adeudo);

            contenTG.push(contenTG2)
        }
        //////////////////// tablaTiposAgroquimicos ///////////////////////////////////////////

        var jsontablaTiposAgroquimicos = JSON.parse(JSON.stringify(this.state.tablaTiposAgroquimicos.encabezados));
        jsontablaTiposAgroquimicos.pop()
        var jsonTiposAgroquimicos = this.state.tablaTiposAgroquimicos.elementos;
        var contenTA = [];
        var contenTA2 = [];
        for (let i = 0; i < jsonTiposAgroquimicos.length; i++) {
            contenTA2 = [];
            contenTA2.push(jsonTiposAgroquimicos[i].proveedor);
            contenTA2.push(jsonTiposAgroquimicos[i].totalCompras);
            contenTA2.push(jsonTiposAgroquimicos[i].total);
            contenTA2.push(jsonTiposAgroquimicos[i].adeudo);

            contenTA.push(contenTA2)
        }
        ///////////////////// tablaTiposCombustibles  ////////////////////////////////////////////

        var jsontablaTiposCombustibles = JSON.parse(JSON.stringify(this.state.tablaTiposCombustibles.encabezados));
        jsontablaTiposCombustibles.pop()
        var jsonTiposCombustibles = this.state.tablaTiposCombustibles.elementos;
        var contenTC = [];
        var contenTC2 = [];
        for (let i = 0; i < jsonTiposCombustibles.length; i++) {
            var contenTC2 = [];
            contenTC2.push(jsonTiposCombustibles[i].proveedor);
            contenTC2.push(jsonTiposCombustibles[i].totalCompras);
            contenTC2.push(jsonTiposCombustibles[i].total);
            contenTC2.push(jsonTiposCombustibles[i].adeudo);

            contenTC.push(contenTC2)
        }


        ////////////////////// tablaTiposDirectas ///////////////////////////////////////////

        var jsontablaTiposDirectas = JSON.parse(JSON.stringify(this.state.tablaTiposDirectas.encabezados));
        jsontablaTiposDirectas.pop()
        var jsonTiposDirectas = this.state.tablaTiposDirectas.elementos;
        var contenTD = [];
        var contenTD2 = [];
        for (let i = 0; i < jsonTiposDirectas.length; i++) {
            contenTD2 = [];
            contenTD2.push(jsonTiposDirectas[i].proveedor);
            contenTD2.push(jsonTiposDirectas[i].totalCompras);
            contenTD2.push(jsonTiposDirectas[i].total);
            contenTD2.push(jsonTiposDirectas[i].adeudo);

            contenTD.push(contenTD2)
        }

        /////////////////////////////////////////////////////////////////
        arrayGeneral.push(json);
        arrayGeneral.push(arraytempp);
        arrayGeneral.push(arrayspacer);
        arrayGeneral.push(jsontablaProveedor);

        for (let i = 0; i < contenProveedor.length; i++) {
            arrayGeneral.push(contenProveedor[i]);
        }
        ///// 
        arrayGeneral.push(arrayspacer);
        arrayGeneral.push(jsontablaTiposGenerales);
        for (let i = 0; i < contenTG.length; i++) {
            arrayGeneral.push(contenTG[i]);
        }
        /////
        arrayGeneral.push(arrayspacer);
        arrayGeneral.push(jsontablaTiposAgroquimicos);
        for (let i = 0; i < contenTA.length; i++) {
            arrayGeneral.push(contenTA[i]);
        }
        /////
        arrayGeneral.push(arrayspacer);
        arrayGeneral.push(jsontablaTiposCombustibles);
        for (let i = 0; i < contenTC.length; i++) {
            arrayGeneral.push(contenTC[i]);
        }
        /////
        arrayGeneral.push(arrayspacer);
        arrayGeneral.push(jsontablaTiposDirectas);
        for (let i = 0; i < contenTD.length; i++) {
            arrayGeneral.push(contenTD[i]);
        }


        this.setState({ jsoncsv: arrayGeneral })
        setTimeout(() => {
            this.setState({ verCSV: true })
        }, 500);



    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({ errordeacceso: false });
        this.componentDidMount();
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    saveInputLocacion = async (e) => {

        this.setState({ txtbuscador: e.target.value });

        if (e.target.value.trim().length >= 3) {
            var data1 = JSON.parse(JSON.stringify(this.state.tabla))
            var data = data1.elementos;
            const filtros = data.filter((item) => item.tipo.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.filtro(1, filtros)
        }
        else {
            this.setState({ filtrolistapaginada: [] });
        }

    }

    verModalDetalles = (item) => {


        const fechaStrat = format(this.state.fechacompraini, 'dd/MM/yyyy');
        const fechaEnd = format(this.state.fechacomprafin, 'dd/MM/yyyy');

        // const fechaEnd = format(this.state.fechacomprafin, 'dd/MM/yyyy');
        let json = {

            "fechainicio": fechaStrat,
            "fechafin": fechaEnd,
            "idproveedor": item.idproveedor,
            "proveedor": item.proveedor,
            "tipo": "Todos"
        }

        localStorage.setItem("report", JSON.stringify(json))
        this.setState({ modaldetalles: true });

    }

    cerrarModal = (accion) => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
            this.componentDidMount();
        }
    }

    filtro = (pagenumber, array) => {
        setTimeout(() => {
            this.setState({
                elementosfiltrados: array,
                activepage: pagenumber,
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
            });
        }, 0);
    }



    limpiarInputBusqueda = () => {
        this.setState({ txtbuscador: '' })
        this.setState({ filtrolistapaginada: [] });
        this.cambiarContenido(1);
    }




    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        // //console.log("Entro en cambiar fecha")
        // //console.log(item)

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    }

    filtrarCombo = async (e) => {

        //console.log(e)
        let datos = this.state.arrayprovedor[e];
        //console.log(JSON.stringify(datos))
        this.setState({ idprovedorselect: datos.idtipo_proveedor })
        this.setState({ nameprovedorselect: datos.tipo })



    }

    render() {
        return (

            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesVista bandera={'salir'} />
                    </>
                    : null}

                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesVista bandera={'detalles'} />
                    </>
                ) : null}

                <Navbar />
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Cuentas por pagar</label>
                            </div>


                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>

                                <div className='' >
                                    <div className='row contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, paddingRight: "5px" }}>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>

                                            <DatePicker
                                                selected={this.state.fechacompraini}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                                        </div>

                                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                            <DatePicker
                                                selected={this.state.fechacomprafin}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>


                            {/*
                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Provedor:</label>
                                    <div className="selectBox ">
                                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                            onChange={(event) => this.filtrarCombo(event.target.value)} >
                                            {<option selected={this.state.idprovedorselect == -1} value={-1}>Todos</option>}
                                            {this.state.arrayprovedor.map((i, index) => (
                                                <option value={index} key={index} selected={this.state.idprovedorselect == i.idtipo_proveedor} >{i.tipo} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            */}





                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.actualizar}>Buscar</button>

                                {/*
 <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.actualizar}>Buscar</button>
*/}


                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                {(this.state.verCSV) ? (
                                    <ExportarCSVreporte arraycsv={this.state.jsoncsv} totalitems={this.state.jsoncsv.length}
                                    />
                                ) : null
                                }
                            </div>

                        </div>
                    </div>
                </div>



                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo " style={{ width: "98%" }}>
                        <br />
                        <br />
                        <br />

                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <div className="containerSubTabla" style={{ backgroundColor: this.state.fondotitulosencabezados, borderRadius: "8px" }}>

                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaGenerales.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaGenerales.mindimensiones[index], width: this.state.tablaGenerales.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>

                                    <tr key={"ari"} >
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>

                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                            {this.state.gtotalCompras}
                                        </td>

                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                            {this.state.gtotal}
                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                            {this.state.gadeudo}
                                        </td>
                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                            {/*<button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                style={{
                                                    marginLeft: '0px',
                                                    backgroundColor: this.state.btnfondoeditar,
                                                    color: this.state.btncoloreditar,
                                                    border: this.state.btnbordereditar
                                                }}>
                                                <IoEye  className="icoBotones" /> Detalles</button>
                                                */}


                                        </td>

                                    </tr>



                                </tbody>

                            </Table>
                            <br />

                        </div>


                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <br />
                        <div className="containerSubTabla">
                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaProveedor.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaProveedor.mindimensiones[index], width: this.state.tablaProveedor.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>






                                <tbody style={{ verticalAlign: "bottom" }}>



                                    {this.state.tablaProveedor.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>
                                                {item.proveedor}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                                {item.totalCompras}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                                {item.total}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                                {item.adeudo}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                    style={{
                                                        marginLeft: '0px',
                                                        backgroundColor: this.state.btnfondoeditar,
                                                        color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                    }}>
                                                    <IoEye className="icoBotones" /> Detalles</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <br />
                        </div>


                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <br />
                        <div className="containerSubTabla" style={{ backgroundColor: this.state.fondotitulosencabezados, borderRadius: "8px" }}>
                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaTiposGenerales.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaTiposGenerales.mindimensiones[index], width: this.state.tablaTiposGenerales.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>






                                <tbody style={{ verticalAlign: "bottom" }}>



                                    {this.state.tablaTiposGenerales.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>
                                                {item.proveedor}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                                {item.totalCompras}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                                {item.total}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                                {item.adeudo}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                    style={{
                                                        marginLeft: '0px',
                                                        backgroundColor: this.state.btnfondoeditar,
                                                        color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                    }}>
                                                    <IoEye className="icoBotones" /> Detalles</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <br />
                        </div>
                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <br />
                        <div className="containerSubTabla">
                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaTiposAgroquimicos.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaTiposAgroquimicos.mindimensiones[index], width: this.state.tablaTiposAgroquimicos.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>






                                <tbody style={{ verticalAlign: "bottom" }}>



                                    {this.state.tablaTiposAgroquimicos.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>
                                                {item.proveedor}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                                {item.totalCompras}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                                {item.total}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                                {item.adeudo}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                    style={{
                                                        marginLeft: '0px',
                                                        backgroundColor: this.state.btnfondoeditar,
                                                        color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                    }}>
                                                    <IoEye className="icoBotones" /> Detalles</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <br />
                        </div>




                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <br />
                        <div className="containerSubTabla" style={{ backgroundColor: this.state.fondotitulosencabezados, borderRadius: "8px" }}>
                            <br />
                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaTiposCombustibles.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaTiposCombustibles.mindimensiones[index], width: this.state.tablaTiposCombustibles.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>






                                <tbody style={{ verticalAlign: "bottom" }}>



                                    {this.state.tablaTiposCombustibles.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>
                                                {item.proveedor}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                                {item.totalCompras}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                                {item.total}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                                {item.adeudo}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                    style={{
                                                        marginLeft: '0px',
                                                        backgroundColor: this.state.btnfondoeditar,
                                                        color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                    }}>
                                                    <IoEye className="icoBotones" /> Detalles</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <br />
                        </div>




                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                        <br />
                        <div className="containerSubTabla">
                            <br />

                            <Table>

                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tablaTiposDirectas.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tablaTiposDirectas.mindimensiones[index], width: this.state.tablaTiposDirectas.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>






                                <tbody style={{ verticalAlign: "bottom" }}>



                                    {this.state.tablaTiposDirectas.elementos.map((item, index) => (

                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[0], width: this.state.tablaProveedor.dimensiones[0] }}>
                                                {item.proveedor}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[1], width: this.state.tablaProveedor.dimensiones[1] }}>
                                                {item.totalCompras}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[2], width: this.state.tablaProveedor.dimensiones[2] }}>
                                                {item.total}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[3], width: this.state.tablaProveedor.dimensiones[3] }}>
                                                {item.adeudo}
                                            </td>

                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tablaProveedor.mindimensiones[4], width: this.state.tablaProveedor.dimensiones[4] }}>
                                                <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)}
                                                    style={{
                                                        marginLeft: '0px',
                                                        backgroundColor: this.state.btnfondoeditar,
                                                        color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                    }}>
                                                    <IoEye className="icoBotones" /> Detalles</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <br />
                            <br />
                            <br />
                        </div>





                    </div>
                </div>

            </>
        )
    }
}

function AccionesVista({ bandera }) {
    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

    else if (bandera == 'detalles') {
        return (<Redirect from='/' to={"/catalogoreportesdetalles"} />)
    }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ backgroundColor: 'white', padding: '0px 0px 0px 0px' }}>

        <label style={{ minWidth: '80px' }}>{value}</label>
    </button>
));


