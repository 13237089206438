import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalagoSalarios.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { FaKey, FaCheck } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde, estiloBtnRojo, estiloBtnRestablecer } from '../../Services/PaletaDeColores';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaCatalogoUsuarios, getComboAreasDeTrabajo, getListaPuestosDeTrabajo, getListaTipoUsuarios, setRestablecerUsuario, setEstatusUsuario, getListaSalarios } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ModalSalarios from '../../Complements/Modales/ModalSalarios/ModalSalarios';


export default class CatalogoSalarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      loadingspinners: true,

      tabla: {
        encabezados: [
          "Nombre(s)",
          "Area",
          "Puesto",
          "Estatus",
          "Salario",
          "Tardeada",
          "",
          ""],
        dimensiones: [

          "15%",
          "10%",
          "15%",
          "15%",
          "5%",
          "15%",
          "15%",
          "150px"
        ],
        mindimensiones: [
          "130px",
          "100px",
          "100px",
          "100px",
          "100px",
          "50px",
          "100px",
          "100px",
          "120px",

        ],
        elementos: []
      },
      estatus: [],
      listaelementosfiltrados: [],
      areaseleccionado: "TodasLasAreas",
      puestoseleccionado: "TodosLosPuestos",
      listatipousuario: [],
      listaarea: [],
      listapuesto: [],
      listafiltropuesto: [],
      listafiltroarea: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      errordeacceso: false,

      //paginacion
      datostardeada: 0,
      datossalario: 0,
      idusuario: 0,
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
      colorbtnverde: estiloBtnVerde().colorbtnverde,
      colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

      colorfondobtnrojo: estiloBtnRojo().colorfondobtnrojo,
      colorbtnrojo: estiloBtnRojo().colorbtnrojo,
      colorbordebtnrojo: estiloBtnRojo().colorbordebtnrojo,

      colorfondobtnrestablecer: estiloBtnRestablecer().colorfondobtnrestablecer,
      colorbtnrestablecer: estiloBtnRestablecer().colorbtnrestablecer,
      colorbordebtnrestablecer: estiloBtnRestablecer().colorbordebtnrestablecer,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    var resplistapuestos = await getListaPuestosDeTrabajo();
    if (this.manejadorLlamadasApis(resplistapuestos)) {
      return;
    }

    var resplistatipousuario = await getListaTipoUsuarios();
    if (this.manejadorLlamadasApis(resplistatipousuario)) {
      return;
    }

    var respcatalogousuarios = await getListaSalarios();
    if (this.manejadorLlamadasApis(respcatalogousuarios)) {
      return;
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = respcatalogousuarios.data;
    await this.setState({
      txtbuscador: '',
      tabla: tabla,
      estatus: estatus.data,
      listaarea: resplistaareas.data,
      listapuesto: resplistapuestos.data,
      listafiltropuesto: resplistapuestos.data,
      listatipousuario: resplistatipousuario.data
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({
      loadingspinners: true,
      txtbuscador: '',
      puestoseleccionado: "TodosLosPuestos",
      areaseleccionado: "TodasLasAreas",
      errordeacceso: false,
    })
    this.componentDidMount();
  }

  cambiarValor = () => {
    //console.log("AAA");
  }


  filtrarArea = async (itemfiltrado, accion) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      areaseleccionado: itemfiltrado
    })

    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];

    filtroarea = data;
    if (itemfiltrado != "TodasLasAreas") {

      var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == itemfiltrado)

      await this.setState({
        listafiltropuesto: resplistapuestos,
        txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }

      filtroarea = data.filter((item) => item.idcatalogo_areas == itemfiltrado)
    } else {
      await this.setState({
        listafiltropuesto: this.state.listapuesto,
        txtbuscador: ""
      })

      if (accion == 0) {
        await this.setState({
          puestoseleccionado: "TodosLosPuestos"
        })
      }
    }

    if (this.state.puestoseleccionado != "TodosLosPuestos") {

      filtropuesto = filtroarea.filter((item) => item.idcatalogo_puestos == this.state.puestoseleccionado)
    } else {
      filtropuesto = filtroarea;
    }

    if (this.state.txtbuscador.length >= 3) {
      filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else {
      filtrofinal = filtropuesto;
      await this.setState({
        txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrofinal)
  }

  filtrarPuesto = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      puestoseleccionado: itemfiltrado
    })

    var filtroarea = [];
    var filtropuesto = [];
    var filtrofinal = [];
    filtroarea = data;
    if (this.state.areaseleccionado != "TodasLasAreas") {

      filtroarea = data.filter((item) => item.idcatalogo_areas == this.state.areaseleccionado)
    }

    filtropuesto = filtroarea;
    if (itemfiltrado != "TodosLosPuestos") {

      filtropuesto = filtroarea.filter((item) => item.idcatalogo_puestos == itemfiltrado)
    }

    if (this.state.txtbuscador.length >= 3) {
      filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador)
        || item.apellido.toLocaleLowerCase().includes(this.state.txtbuscador))
    } else {
      filtrofinal = filtropuesto;
      await this.setState({
        txtbuscador: ""
      })
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(1, filtrofinal)

  }

  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value });

    var filtropuesto = [];
    var filtroarea = [];
    var filtrofinal = [];

    if (tamanocadenain >= 3 || banderacasosespeciales) {
      filtroarea = data;
      if (this.state.areaseleccionado != "TodasLasAreas") {
        filtroarea = data.filter((item) => item.idcatalogo_areas == this.state.areaseleccionado)
      }
      filtropuesto = filtroarea;

      if (this.state.puestoseleccionado != "TodosLosPuestos") {
        filtropuesto = filtroarea.filter((item) => item.idcatalogo_puestos == this.state.puestoseleccionado)
      } else { filtropuesto = filtroarea; }

      filtrofinal = filtropuesto;
      if (e.target.value.toLocaleLowerCase().length >= 3) {
        filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
          || item.apellido.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      }
      if (filtrofinal.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtrofinal)

    }

  }

  setEstatusUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios,
      "idestatus": ban.estatus
    }
    this.activarSpinner(true);
    var resp = await setEstatusUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    }
    else {
      this.activarSpinner(false);
      this.actualizarTabla();

    }
  }

  restablecerUsuario = async (ban) => {

    let json = {
      "idcatalogousuarios": ban.idcatalogousuarios
    }
    this.activarSpinner(true);
    var resp = await setRestablecerUsuario(json);
    if (resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.activarSpinner(false);
      return;
    }
    else if (resp.code == 200 || resp.code == 300) {
      this.activarSpinner(false);
      this.setState({ confirmarenvio: !this.state.confirmarenvio })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    } else {
      this.activarSpinner(false);
    }

  }

  verModalDetalles = (ban) => {

    this.setState({ titulomodal: 'Editar salario' });
    this.setState({ idusuario: ban.idusuario })
    this.setState({ datossalario: ban.salario })
    this.setState({ datostardeada: ban.tardeada })
    
    this.setState({ modaldetalles: true });

  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.setState({ loadingspinners: true })
      this.setState({ txtbuscador: '' })
      this.componentDidMount();
      this.filtrarArea("TodasLasAreas", 0)
    }

  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {

    this.setState({
      activepage: pagenumber,
      listapaginada: [],
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.listaelementosfiltrados),
      });
    }, 0);

  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
    })
    this.filtrarArea(this.state.areaseleccionado, 1)
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }


  format_moneda = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  format_moneda_sin_logo = (number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalSalarios titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} idusuario={this.state.idusuario} salario={this.state.datossalario} tardeada ={this.state.datostardeada}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado}
          />

        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Salarios</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Área</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarArea(event.target.value, 0)}>
                      {this.state.areaseleccionado == "TodasLasAreas" ?
                        <option selected="true" value='TodasLasAreas'>Todas</option> : <option value='TodasLasAreas'>Todas</option>}
                      {this.state.listaarea.map((i, index) => (
                        <option value={i.idcatalogo_areas} key={"tipo_" + index}  >{i.area} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Puesto</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarPuesto(event.target.value)}>
                      {this.state.puestoseleccionado == "TodosLosPuestos" ?
                        <option selected="true" value='TodosLosPuestos'>Todos</option> : <option value='TodosLosPuestos'>Todos</option>}
                      {this.state.listafiltropuesto.map((i, index) => (
                        <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar Nombre"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>


              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
              </div>

              {/*<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, "1")}>+ Nuevo</button>
              </div>*/}

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoTabCultivo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3
                    && this.state.puestoseleccionado == 'TodosLosPuestos'
                    && this.state.areaseleccionado == 'TodasLasAreas' ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombre} {item.apellido}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.area}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.puesto}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.format_moneda(item.salario)}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.format_moneda(item.tardeada)}
                        </td>

                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" />Editar</button>
                        </td>


                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombre} {item.apellido}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.area}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.puesto}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.format_moneda(item.salario)}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {this.format_moneda(item.tardeada)}
                        </td>

                        <td className="letraNormal > lblSubtablaBotones itemVerticalCenter" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, item)} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" />Editar</button>
                        </td>


                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Paginacion   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}

            totalitemscount={this.state.txtbuscador.length < 3
              && this.state.puestoseleccionado == 'TodosLosPuestos'
              && this.state.areaseleccionado == 'TodasLasAreas' ? this.state.tabla.elementos : this.state.listaelementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
        {/* ////////////////////   Fin Sección Paginacion   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}