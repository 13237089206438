import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioCuadrillas.css";
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitudV2, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { guardarEditarCuadrilla } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdClose } from 'react-icons/io';
import ModalTiposCultivo from '../ModalTiposCultivo/ModalTiposCultivo';

export default class ModalFormularioCuadrillas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            arrayestatus: props.estatus,
            idestatus: (props.accion == 2) ? (props.item.idestatus) : 1,
            txtnombre: (props.accion == 2) ? (props.item.nombre) : '',
            txtcomentarios: (props.accion == 2) ? (props.item.comentarios) : '',
            cultivos: (props.accion == 2) ? (props.item.cultivos) : [],

            vermsgerrornombre: false,
            vermsgerrorcomentarios: false,
            vermsgerrorcultivos: false,

            msgerrornombre: "",
            msgerrorcomentarios: "",
            msgerrortxtcultivos: "",
            modalcultivos: false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
        }
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    cambiarTipo = (item) => {
        this.setState({ [item.tipo]: item.valor })
    }

    ////////////////// Tipos de cultivo ///////////////////
    borrarTipocultivo = (idtipo_cultivo) => {
        let lista = this.state.cultivos;
        let nuevalista = [];
        lista.forEach(item => {
            if (item.idtipo_cultivo !== idtipo_cultivo) {
                nuevalista.push(item)
            }
        });
        this.setState({ cultivos: nuevalista })
    }

    cerrarModalBuscadorCultivos = (datos) => {
        if (datos != 0) {
            let listaencargados = this.state.cultivos;
            for (let i = 0; i < datos.length; i++) {
                const existe = listaencargados.some((item) => {
                    return item.idtipo_cultivo == datos[i].idtipo_cultivo;
                });
                if (existe) {
                    toast.info("El tipo cultivo " + datos[i].tipo + " ya existe", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                } else {
                    listaencargados.push({idtipo_cultivo: datos[i].idtipo_cultivo, tipo:datos[i].tipo})
                }
            }
            this.setState({
                cultivos: listaencargados
            })
        }
        this.setState({
            modalcultivos: false,
        })
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrornombre: false })
        this.setState({ vermsgerrorcomentarios: false })
        this.setState({ vermsgerrorcultivos: false })

        let validacionescorrectas = true

        let longitudvalida = { longitudminima: 1, longitudmaxima: 200 };
        let tipocorrecto = validarLetrasNumerosLongitudV2(this.state.txtnombre.trim(), longitudvalida)
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrornombre: tipocorrecto.mensaje })
            this.setState({ vermsgerrornombre: true })
            validacionescorrectas = false;
        }

        let listacultivos = []
        if (this.state.cultivos.length == 0) {
            this.setState({ msgerrortxtcultivos: "Selecciona al menos un tipo de cultivo" })
            this.setState({ vermsgerrorcultivos: true })
            validacionescorrectas = false;
        }else {
            this.state.cultivos.forEach(item => {
                listacultivos.push({ idtipo_cultivo: item.idtipo_cultivo })
            });
        }

        if (this.state.txtcomentarios.trim().length > 0) {
            let longitudvalida = { longitudminima: 1, longitudmaxima: 200 };
            let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtcomentarios.trim(), longitudvalida);
            if (descorrecto.codigo == "invalid") {
                this.setState({ msgerrorcomentarios: descorrecto.mensaje })
                this.setState({ vermsgerrorcomentarios: true })
                validacionescorrectas = false;
            }
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            if (this.state.accion == 1) {
                let json = {
                    "idcatalogo_cuadrillas": 0,
                    "nombre": this.state.txtnombre,
                    "flete": 0,
                    "cosecha": 0,
                    "cultivos": this.state.cultivos,
                    "comentarios": this.state.txtcomentarios,
                    "idestatus": this.state.idestatus
                }
                resp = await guardarEditarCuadrilla(json);
            }
            else if (this.state.accion == 2) {
                let json = {
                    "idcatalogo_cuadrillas": this.props.item.idcatalogo_cuadrillas,
                    "nombre": this.state.txtnombre,
                    "flete": 0,
                    "cosecha": 0,
                    "cultivos": this.state.cultivos,
                    "comentarios": this.state.txtcomentarios,
                    "idestatus": this.state.idestatus
                }
                resp = await guardarEditarCuadrilla(json);
            }

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de modal de cultivos y mensajes toast ////////*/}
                <ToastContainer />

                {this.state.modalcultivos == true ?
                    <ModalTiposCultivo
                        setLoadingSpinner={this.setLoadingSpinner}
                        show={this.state.modalcultivos}
                        cerrarModalBuscadorCultivos={this.cerrarModalBuscadorCultivos}
                    />
                :null}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*/////////////// Sección de nombre de representante ///////////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de representante</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        value={this.state.txtnombre}
                                        onChange={this.escribir("txtnombre")}
                                    >
                                    </input>
                                :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtnombre}
                                    </label>
                                }

                                {this.state.vermsgerrornombre ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombre}</p>
                                :null}

                                {/*/////////////// Sección de tipos de cultivo ///////////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                    Tipos de cultivos
                                    <button
                                        onClick={() => this.setState({ modalcultivos: true })}
                                        style={{ background: "white", marginLeft: "calc(100% - 170px)", borderRadius: "30px", width: "32px", border: "2px solid blue" }}
                                    >
                                        +
                                    </button>
                                </p>

                                {this.state.cultivos.length == 0 ?
                                    <label className='lblTextoModal letraNormal'>
                                        Selecciona al menos uno
                                    </label>
                                :
                                    this.state.cultivos.map((item, index) => (
                                        <div style={{ display: "flex" }}>
                                            <label
                                                className='lblTextoModal letraNormal'
                                                style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}
                                            >
                                                {item.tipo}
                                            </label>
                                            {this.state.cultivos.length > 1 ?
                                                <IoMdClose
                                                    className='icoInputBuscarClose'
                                                    style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                    onClick={() => this.borrarTipocultivo(item.idtipo_cultivo)}
                                                />
                                            :null}
                                        </div>
                                    ))
                                }

                                {this.state.vermsgerrorcultivos ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcultivos}</p>
                                :null}

                                {/*/////////////// Sección de comentarios ///////////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentarios</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <input
                                        className='lblTextoModal letraNormal'
                                        style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'}
                                        value={this.state.txtcomentarios}
                                        onChange={this.escribir("txtcomentarios")}
                                    >
                                    </input>
                                :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtcomentarios}
                                    </label>
                                }

                                {this.state.vermsgerrorcomentarios ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcomentarios}</p>
                                :null}
                            </div>
                        </div>
                        <br />

                        {/*/////////////// Sección de botones ///////////////*/}
                        {this.state.accion == 2 || this.state.accion == 1 ?
                            <>
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        :null}

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

