import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
//--import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';

import { Table } from 'reactstrap';


export default class ModalEquiposYHerramientas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            item: props.item,
            accion: props.accion,

            listaoperacionesestatus: props.listaalamcenproductosgenerales,
            listaequiposinventario: props.listaequiposinventario,
            idoperacionseleccionada: 0,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            idseleccionado: '',

            almacenproductos: '',
            almacenequipo: '',
            opcionalamacen: '',

            itemseleccionado: null,
            tabla: {
                encabezados: [
                    "Nombre",
                    "Marca",
                    "Modelo"
                ],
                dimensiones: [
                    "40%",
                    "30%",
                    "30%",
                ],
                mindimensiones: [
                    "150px",
                    "100px",
                    "100px"
                ],
                elementos: []
            },

        }

    }
    async componentDidMount() {
        //console.log(this.props.listaalamcenproductosgenerales)
        if (this.props.listadestinos) {
            var listadestinos = this.props.listadestinos
            var item = listadestinos.find(elemento => elemento.idtipo_destino == 2)
            var listahijos = item.hijos
            var itemhijo1 = listahijos.find(elemento => elemento.idtipo_destino == 4)
            var itemhijo2 = listahijos.find(elemento => elemento.idtipo_destino == 5)
            this.setState({ almacenproductos: itemhijo1.tipo, almacenequipo: itemhijo2.tipo })
        }

    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (opcion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(opcion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    seleccionarOperacion = async ({ item, campo }) => {
        this.setState({ idoperacionseleccionada: item[campo] })

        this.setState({ itemseleccionado: item })

    }

    render() {
        return (

            <>
                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div>
                            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
                        </div>

                        <br />

                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>

                                    {
                                        this.state.listaequiposinventario.length > 0 ? (
                                            " Selecciona el equipo de " + this.props.itemrancho.rancho
                                        ) : (
                                            "El rancho " + this.props.itemrancho.rancho + " no cuenta con equipos en almacén"
                                        )

                                    }

                                </p>


                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                                    <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                                    <div className="containerSubTabla tablaMaxHeight">

                                        <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                                            <Table hover>
                                                <thead>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((item, index) => (
                                                            <th key={"tabla" + index} className={(index > 1) ? " lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {item}
                                                            </th>
                                                        ))}


                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaequiposinventario.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion({ item: item, campo: 'idequipo_inventarioequipo' })}
                                                                className={
                                                                    (this.state.idoperacionseleccionada == item.idequipo_inventarioequipo)
                                                                        ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla}}>
                                                                    {item.nombreclave}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.marca}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla}}>
                                                                    {item.modelo}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.cantidad}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>


                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="divBtnContenedorModal spacePaddingBottom" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal"
                                        disabled={this.state.itemseleccionado ? false : true}
                                        onClick={() => this.showModal({ accion: 1, item: this.state.itemseleccionado/*'resp.data.idcatalogo_proveedores'*/ })}>
                                        Continuar
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </ModalBody>

                </Modal >
            </>
        )
    }
}
