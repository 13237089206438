import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { validarPasswordEliminar } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import {
  deleteRequisicionDocumento,
  getRequisicionesUpdate
} from '../../../Services/Api';
import { Table } from 'reactstrap';


export default class ModalEstatusRequisicion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      item: props.item,
      accion: props.accion,

      listaoperacionesestatus: [
        { nombre: "Rechazar", id: 1, mostrar: [2] },
        { nombre: "Cancelar", id: 2, mostrar: [1, 4] },
        { nombre: "Aprobar requisición", id: 3, mostrar: [2] },
        { nombre: "Solicitar autorización", id: 4, mostrar: [1, 4] }
      ],
      idoperacionseleccionada: 0,
      // id: 1, Disponible; id: 2, Espera de autorización; id: 3, Autorizada; id: 4, Rechazada; id: 5, Cancelada
      idestatusactual: props.item.idestatusrequisicion,

      txtpassword: "",
      vermsgerrortxtpassword: false,
      msgerrortxtpassword: "",

      txtprincipal: "",
      vermsgerrortxtprincipal: false,
      msgerrortxtprincipal: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortitulo: estiloFiltroEncabezados().colortitulos

    }

  }
  async componentDidMount() {
    /*setTimeout(async () => {
      this.refInput.focus();
    }, 500);*/
  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  showModal = (opcion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opcion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  seleccionarOperacion = async (item) => {
    this.setState({ idoperacionseleccionada: item.id })
    if (item.id == 1) {
      var item = this.state.item
      item.seleccion = 4//rechazar
      this.showModal({ id: 1, item: this.state.item })
    }
    else if (item.id == 2) {
      var item = this.state.item
      item.seleccion = 5//Cancelar
      this.showModal({ id: 2, item: this.state.item, accion: 2 })
      this.setState({ loadingspinners: true })
    }
    else if (item.id == 3) {
      var item = this.state.item
      item.seleccion = 3// Autorizada
      this.showModal({ id: 3, item: this.state.item, accion: 3 })
    }
    else if (item.id == 4) {
      var item = this.state.item
      item.seleccion = 2// Esperando autorización

      this.setState({ loadingspinners: true })
      var resp = {};
      var json = {};
      json = {
        "idcatalogo_requisicion": this.state.item.idcatalogo_requisicion,
        "password": '',
        "idestatusrequisicion": 2,//this.state.item.idestatusrequisicion,
        "comentario": '', // Requerido cuando cuando el estatus es Autorizado o Rechazado
        "condicion": 0, // Requerido cuando estatus es Autorizado
        "nuevoprecio": 0.0 // Requerido cuando condición sea igual a 1
      }
      resp = await getRequisicionesUpdate(json);

      if (resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setState({ loadingspinners: false })
        return;
      }
      else if (resp.code == 200 || resp.code == 300) {
        this.setState({ loadingspinners: false })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        this.showModal({ id: 4, item: this.state.item, accion: 1 })
      }

    }
  }


  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo} #{this.state.item.idcatalogo_requisicion}</label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Selecciona nuevo estatus</p>


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {
                            this.state.listaoperacionesestatus.filter((itemfilter) => itemfilter.mostrar.includes(this.state.idestatusactual) ).map((item, index) => (
                              <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion(item)}
                                className={this.state.idoperacionseleccionada == item.id ? "trFondoActivoGreen" : ""}>
                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.nombre}
                                </td>
                              </tr>
                            ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }
}
