import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { Button } from 'reactstrap';
import './AutorizarCotizaciones.css';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados
} from '../../Services/PaletaDeColores';
import { FaCheck } from 'react-icons/fa'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getAutorizacionCotizacionesInformacionx } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Cotizacion from './Cotizacion'
import ModalFormularioResumen from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalFormularioResumen'
import ModalFormularioAutorizacion from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalFormularioAutorizacion'
import ModalRechazarCotizacion from '../../Complements/Modales/ModalAutorizacionCotizaciones/ModalRechazarCotizacion'
import { formatoNumero, formatoNumeroTecla } from '../../Services/Validaciones';

export default class AutorizarCotizaciones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cuantosdecimales:2,
      salir: false,
      txtnombrerequisicion: '',
      txttiporequesicion: '',
      txtfechaestimadacompra: '',
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,

      itemseleccionado: null,

      listatiporequesicion: [],
      listaestatus: [],

      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      contenidorequisicion: [],

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      tablaresumenrequisicion: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Pendientes",
          "",
          "Proveedor sugerido",
          ""
        ],
        dimensiones: [
          "20%",
          "15%",
          "10%",
          "10%",
          "5%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "160px",
          "170px",
          "120px",
          "120px",
          "50px",
          "185px",
          "185px"
        ],
        maxdimensiones: [
          "150px",
          "180px",
          "130px",
          "130px",
          "60px",
          "190px",
          "185px"
        ],
        elementos: [],
        total: 0,
      },
      listastock: [],

      tablaresumencompra: {
        encabezados: [
          "Proveedor",
          "Estatus",
          /*"Fecha compra",*/
          "Total"
        ],
        dimensiones: [
          "45%",
          "20%",
          /*"15%",*/
          "15%"
        ],
        mindimensiones: [
          "240px",
          "220px",
          /*"220px",*/
          "160px"
        ],
        maxdimensiones: [
          "240px",
          "260px",
          /*"290px",*/
          "180px"
        ],
        elementos: [],
        informacion: {},
        total: 0,
       
      },


      // Mdal documentos
      modaldetallesdocumentos: false,

      // Modal de acciones rechazar/aprobar requisición
      modalaccionestatus: false,

      // Modal de solicitud de contraseñas para rechazar/aprobar requisición
      modalaccionestatuscontrasena: false,

      // Modal formulario resumen
      modalresumen: false,

      // Modal  (todos)
      modalautorizacion: false,

      // Modal formulario rechazar cotización (todos)
      modalrechazarcotizacion: false,

      redireccionando: false,
      redireccionandobandera: "",

      idcotizacion: 0,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,



      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idrequisicion = await localStorage.getItem('dato')
    if (idrequisicion != null) {
      var respautorizacioncotizacion = await getAutorizacionCotizacionesInformacionx({ idcatalogo_requisicion: idrequisicion });
      if (this.manejadorLlamadasApis(respautorizacioncotizacion)) {
        return;
      }

      if (respautorizacioncotizacion.code == 200) {
        await toast.error(respautorizacioncotizacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var informacionrequisicion = this.state.informacionrequisicion
      informacionrequisicion = respautorizacioncotizacion.data ? respautorizacioncotizacion.data : {};

      var tablaresumenrequisicion = this.state.tablaresumenrequisicion
      tablaresumenrequisicion.elementos = informacionrequisicion.Resumen ? informacionrequisicion.Resumen : []

      var contenidorequisicion = this.state.contenidorequisicion
      contenidorequisicion = informacionrequisicion.cotizaciones

      var tablaresumencompra = this.state.tablaresumencompra
      tablaresumencompra.informacion = informacionrequisicion.resumencompras ? informacionrequisicion.resumencompras : {}
      tablaresumencompra.elementos = tablaresumencompra.informacion.compras ? tablaresumencompra.informacion.compras : []

      var stock = informacionrequisicion.stock ? informacionrequisicion.stock : []
      var listastock = stock.productos ? stock.productos : []

      contenidorequisicion=this.calcularAcumulados(contenidorequisicion)
      console.log(contenidorequisicion);
      
      this.setState({
        informacionrequisicion: informacionrequisicion,
        contenidorequisicion: contenidorequisicion,
        tablaresumenrequisicion: tablaresumenrequisicion,
        listastock: listastock,
        idcotizacion: informacionrequisicion.idcotizacion
      });
    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }


  calcularAcumulados(cotizaciones){
    let nuevacotizacion=[];
    
    cotizaciones.forEach(coti => {
      let sumaprecios=0;
    let sumarivas=0;
    let sumarieps=0;
      coti.productos.forEach(prod => {
        sumaprecios=sumaprecios+parseFloat(prod.precio);
        sumarivas= sumarivas+parseFloat(prod.iva);
        sumarieps=sumarieps+parseFloat(prod.ieps_calculado);
      });

      nuevacotizacion.push({
       
          "idcotizacion_proveedor": coti.idcotizacion_proveedor,
          "idproveedor": coti.idproveedor,
          "proveedor": coti.proveedor,
          "fechacompra": coti.fechacompra,
          "idestatuscotizacion": coti.idestatuscotizacion,
          "estatuscotizacion": coti.estatuscotizacion,
          "mensaje_estatuscotizacion": coti.mensaje_estatuscotizacion,
          "iva_activo": coti.iva_activo,
          "idieps": coti.idieps,
          "productos": coti.productos,
          "total": parseFloat(sumarivas + sumarieps + sumaprecios).toFixed(4),
          "totalivas":parseFloat(sumarivas).toFixed(4),
          "totalieps":parseFloat(sumarieps).toFixed(4),
          "totalprecios":parseFloat(sumaprecios).toFixed(4),
    
      });

    });
    return nuevacotizacion

  }
  

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  verModalFormularioResumen = (ban) => {
    const aux = this.state.informacionrequisicion
    var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.Idestatisrequisocion*/ }
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar productos sugeridos', accionmodal: ban.id, modalresumen: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioResumen = (opciones) => {
    this.setState({ modalresumen: !this.state.modalresumen })
    console.log(opciones)
    if (opciones.accion == 1) {
      this.verModalAutorizacion(opciones)
    }
  }

  verModalAutorizacion = (ban) => {
    console.log("verModalAutorizacion", ban);
    if (ban.accion == 1) {// Rechazar productos
      //toast.error('Pendiente de implementar', { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
      //this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: 'item' });
    }
    else if (ban.id == 2) {// Aprobar productos
      localStorage.setItem("dato", [this.state.idcotizacion]);
      this.setState({ redireccionando: true, redireccionandobandera: "rectificarproductos" })
    }
    else if (ban.id == 3) {// Rechazar cotización
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 4) {// Aprobar cotización
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
    }
  }
  //
  cerrarModalAutorizacion = (opciones) => {
    console.log("cerrarModalAutorizacion",opciones)
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (opciones.accion == 3) {
      this.componentDidMount()
    }
    else if (opciones.accion == 4) {
      this.componentDidMount()
    }else if (opciones.accion == 1) {
      this.componentDidMount()
    }
  }

  verModalRechazarCotizacion = (ban) => {
    if (ban.accion == 1) { //Nuevo
      this.setState({ titulomodal: 'Rechazar cotización', accionmodal: 1, itemseleccionado: JSON.parse(JSON.stringify(ban.item)) })
    }
    this.setState({ modalrechazarcotizacion: true });
  }

  cerrarModalRechazarCotizacion = (datos) => {
    this.setState({ modalrechazarcotizacion: !this.state.modalrechazarcotizacion })
    if (datos.id == 1) {
      this.verModalAutorizacion({ id: 3, item: datos.item })
    }
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  comprobarProductosSustitutos = (listaproductoresumen) => {
    return listaproductoresumen.filter(item => item.sustitutode != '').length > 0
  }

  getProductoSustituir (item) {
    var indexpd = this.state.tablaresumenrequisicion.elementos.findIndex((pd) => pd.idproducto==item.sustitutode)
    var nombrepd = "";
    if(indexpd > -1){
      nombrepd = this.state.tablaresumenrequisicion.elementos[indexpd].producto;
    }

    return nombrepd;
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={this.state.redireccionandobandera} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalresumen == true) ? (
          <ModalFormularioResumen titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioResumen} estatus={this.state.estatus}
            show={this.state.modalresumen} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalautorizacion == true) ? (
          <ModalFormularioAutorizacion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalAutorizacion} estatus={this.state.estatus}
            show={this.state.modalautorizacion} accion={this.state.accionmodal} item={this.state.itemseleccionado} idcotizacion={this.state.idcotizacion}
            setloadingspinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modalrechazarcotizacion == true) ? (
          <ModalRechazarCotizacion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalRechazarCotizacion}
            show={this.state.modalrechazarcotizacion} accion={this.state.accionmodal} item={{ sw: 12/*this.state.itemseleccionado*/ }}
            /*listatipomovimientos={this.state.listatipomovimientos} listamovimientos={this.state.listamovimientos}¨*/
            informacion={this.state.itemseleccionado}
            setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.informacionrequisicion.nombre}</label>
              </div>

              {this.state.informacionrequisicion.periodo && this.state.informacionrequisicion.mes ?
                <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>
                    {'Plan anual ' + this.state.informacionrequisicion.periodo + ', ' + this.state.informacionrequisicion.mes}
                  </label>
                </div> : null}

                <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.actividad}</label>
              </div>            

              
              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.tiporequisicion}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 col-lg-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaestimada_compra}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.solicitante}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechacreacion}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.estatusrequisicion}</label>
              </div>
              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >
                  {this.state.informacionrequisicion.fechaactualizacion ? this.state.informacionrequisicion.fechaactualizacion : this.state.informacionrequisicion.fechacreacion}
                </label>
              </div>

            </div>
            <br />
            <br />

          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}


        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
        <div className='container-fluid contenedorResumen' style={{ background: "white", textAlign: "right" }}>
          <label className='lblTexto letraTitulos' >
            <button className='btncomprasCotizaciones' onClick={() => this.setState({ cuantosdecimales: (this.state.cuantosdecimales == 2) ? (4) : (2) })}>Mostrar formato con {(this.state.cuantosdecimales == 2) ? (4) : (2)} decimales</button>
          </label>
        </div>
        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>

          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1050px', maxWidth: '1200px' }}>
                <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                  <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Resumen requisición</b></label>
                </div>
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablaresumenrequisicion.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaresumenrequisicion.mindimensiones[index],
                              maxWidth: this.state.tablaresumenrequisicion.maxdimensiones[index],
                              width: this.state.tablaresumenrequisicion.dimensiones[index],
                              textAlign: index > 0 ? 'center' : '',
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.tablaresumenrequisicion.elementos.map((item, index) => (
                        <tr key={"tabla" + index}
                          style={{
                            borderBottom: (index == this.state.tablaresumenrequisicion.elementos.length - 1) ? 'solid 1px #91b9f9' : '',
                            color: item.pendientes == '0' && item.sustituir!=1 ? '#007bff' : '', background: item.sustituir==1 ? "#b9b9b9" : item.sustitutode!="" ? "#a1c2ff": ""
                          }}>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.producto}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {item.unidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                            {item.cantidad}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'right' }}>
                            {item.pendientes}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'center' }}>
                            {item.pendientes == '0' && item.sustituir!=1 ?  <FaCheck className="icoCheck" /> : ''}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                            {item.proveedor_recomendado ? item.proveedor_recomendado : 'Sin proveedor'}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'center' }}>
                            {item.sustitutode != '' ?
                              'Sustituto de ' + this.getProductoSustituir(item) : item.sustituir==1 ? "Producto a cambiar" : ''}
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>

                {this.state.informacionrequisicion.permisoautorizarproductos!=0 && this.state.informacionrequisicion.estatusautorizarproductos==1 && this.comprobarProductosSustitutos(this.state.tablaresumenrequisicion.elementos) && <div className='row paddingEncabezados align-items-end' style={{ padding: '20px 12px 20px 12px' }}>
                  <div className='col'></div>
                  <div className='col-auto paddingBottomEncabezados'>
                    <Button className="btnRechazarProd" style={{ padding: '4px 12px' }} onClick={this.verModalFormularioResumen.bind(this, { id: 1 })}>
                      Rechazar cambio de productos
                    </Button>
                  </div>
                  <div className='col-auto paddingBottomEncabezados'>
                    <Button className="btnAceptarProd" style={{ padding: '4px 12px' }} onClick={this.verModalAutorizacion.bind(this, { id: 2 })}>
                      Aprobar cambio de productos
                    </Button>
                  </div>
                </div>}

              </div>
            </div>
          </div>
          <br />

          <div className="container-fluid containerTabla">
            <div className="container-fluid anchoMaximo">
              <div>
                {this.state.contenidorequisicion.length > 0 ?
                  this.state.contenidorequisicion.map((item, index) => (
                    <Cotizacion key={"tipo_" + index} item={item} listastock={JSON.parse(JSON.stringify(item.productos))}
                      vermodalrechazarcotizacion={this.verModalRechazarCotizacion}
                      vermodalautorizacion={this.verModalAutorizacion}
                      permisoautorizacion={this.state.informacionrequisicion.permisoautorizacion}
                      fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} 
                      cuantosdecimales={this.state.cuantosdecimales}
                      />
                  ))
                  : <div></div>
                }
              </div>
            </div>
          </div>
          <br />

          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1050px', maxWidth: '1200px' }}>
                {(this.state.tablaresumencompra.elementos.length != 0) ?
                  <>
                    <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                      <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Resumen compras</b></label>
                    </div>
                    <div className='divMinWidthAutoCot' style={{ aamarginBottom: '0px' }}>
                      <Table style={{ marginBottom: '0px' }}>
                        <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                          <tr>
                            {this.state.tablaresumencompra.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tablaresumencompra.mindimensiones[index],
                                  maxWidth: this.state.tablaresumencompra.maxdimensiones[index],
                                  width: this.state.tablaresumencompra.dimensiones[index],
                                  textAlign: (index > 1) ? 'center' : '',
                                  color: this.state.colorencabezadostabla
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {this.state.tablaresumencompra.elementos.map((item, index) => (
                            <tr key={"tabla" + index} style={{ borderBottom: (index == this.state.tablaresumencompra.elementos.length - 1) ? 'solid 1px #91b9f9' : '' }}>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.idproveedorcompra} &nbsp;&nbsp; {item.proveedorcompra}
                              </td>
                              <td className={"letraNormal lblSubtabla stlEstatusCompra" + ([1, 2, 3].includes(item.idestatuscompra) ? 1 : item.idestatuscompra == 4 ? 2 : '')} style={{ fontWeight: 'bold' }}>
                                {item.estatuscompra}
                              </td>{/*
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.fechacompra}
                              </td>*/}
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E', textAlign: 'right' }}>
                                ${formatoNumeroTecla(item.total, this.state.cuantosdecimales)}
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <td className="lblEncabezadosTabla letraTitulos" colSpan={2}
                              style={{ color: this.state.colorencabezadostabla }}>
                              <b>Total a pagar por requisición</b>
                            </td>
                            <td className="lblEncabezadosTablaa letraTitulos"
                              style={{ textAlign: 'right', color: this.state.colorencabezadostabla }}>
                              <b>${formatoNumeroTecla(this.state.tablaresumencompra.informacion.totalgeneral, this.state.cuantosdecimales)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </> :
                  <div className='col-auto minPaddingTopBottomAutoCot divMinWidthAutoCot align-items-center'>
                    <label className='letraTitulos' style={{ padding: '15px', textAlign: 'center' }}><b>Sin cotizaciones</b></label>
                  </div>}
              </div>
            </div>
          </div>

          <br />
        </div>
        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}

      </>
    )
  }
}

function AccionesVista({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'rectificarproductos') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/rectificaragroquimicos"} />)
  }else /*(bandera == 'goreferencias')*/ {
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)
  }
}
