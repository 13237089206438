import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioInventarioAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';

import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioBuscarProductos extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            txtbuscador: "",
            tabla: {
                encabezados: (this.props.origen == "inventariogenerales") ? ([

                    "Nombre comercial",
                    "Disponibles",
                    "Tipo producto",
                ])
                    : (
                        (this.props.origen == "inventarioequipos") ? ([
                            "Nombre/Clave",
                            "Marca",
                            "Modelo",
                            "Tipo",
                            "Disponibles"
                        ])
                            : (

                                (this.props.origen == "productosactivos") ? (
                                    [
                                        "Nombre comercial",
                                        "Disponibles",
                                        "Tipo producto",
                                    ]
                                )
                                    : (
                                        [
                                            "Nombre comercial",
                                            "Familia",
                                            "Disponibles",
                                            "Tipo producto",
                                        ]
                                    )



                            )
                    ),
                dimensiones: (this.props.origen == "inventariogenerales") ? (
                    [
                        "50%",
                        "25%",
                        "25%",
                    ]
                ) : (this.props.origen == "inventarioequipos") ? ([
                    "30%",
                    "20%",
                    "20%",
                    "15%",
                    "15%"
                ])
                    :(
                    [
                        
                        "55%",
                        "15%",
                        "15%",
                    ]
                ),
                mindimensiones: (this.props.origen == "inventariogenerales") ? (
                    [
                        "100px",
                        "100px",
                        "100px",
                    ]
                ) : (
                    [
                        "100px",
                        "100px",
                        "100px",
                    ]
                ),
                elementos: [],
            },

            todoslosproductosalmacen: this.props.todoslosproductosalmacen,
            listaproductosalmacen: this.props.listaproductosalmacen,
            productoseleccionado: undefined,
            idproductoseleccionado: 0,
            filtrofamilia: "TodasLasFamilias",
            filtrotipoproducto: "TodasLosProductos",

            idestatus: 1,
            msgerror: '',
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listafamiliaproductos: [],
            listatipoproductos: [],
            listatipoproductosfiltrados: [],
            listaproductosfiltrados: [],



            filtrotipoproductogeneral: "TodasLosProductosGenerales",
            listatipoproductosgenerales: [],
            listatipoproductosgeneralesfiltrados: [],

            filtrotipoequipos: "TodosLosEquipos",
            listatipoequipos: [],
        }

    }
    async componentDidMount() {

        this.setState({ loadingspinners: true })

        var tabla = this.state.tabla;
        if (this.state.todoslosproductosalmacen) {
            tabla.elementos = this.props.respproductos;
        } else {
            tabla.elementos = this.filtrarProductosdeAlmacen(this.props.respproductos)
        }

        this.setState({
            txtbuscador: '',
            tabla: tabla,
            listafamiliaproductos: this.props.respfamiliaproductos,
            listatipoproductos: this.props.resptipoproductos,
            listatipoproductosfiltrados: this.props.resptipoproductos,
            listaproductosfiltrados: tabla.elementos,

            listatipoproductosgenerales: this.props.resptipoproductosgenerales,
            listatipoproductosgeneralesfiltrados: this.props.resptipoproductosgenerales,

            listatipoequipos: this.props.resptipoequipos,

        })
        this.setState({ loadingspinners: false })
    }

    filtrarProductosdeAlmacen(respproductos) {
        var productosdealmacen = [];

        this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
            var producto = (this.props.origen == "inventariogenerales") ? (
                respproductos.filter((productogeneral) => productogeneral.idcatalogo_producto_general == productoalmacenrecibido.idcatalogoproductogeneral)
            ) : (
                //respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
                (this.props.origen == "inventarioequipos") ? (
                    respproductos.filter((productogeneral) => productogeneral.idequipo == productoalmacenrecibido.idequipo)
                ) : (
                    (this.props.origen == "productosactivos") ? (
                        respproductos.filter((productogeneral) => productogeneral.idcatalogo_producto_general == productoalmacenrecibido.idproducto)
                    ) : (
                        respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
                    )
                )

            )
            if (producto != undefined) {
                productosdealmacen = productosdealmacen.concat(producto)
            }
        });

        return productosdealmacen;
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        this.showModal(this.state.productoseleccionado)

    }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];

        listafiltrofamilia = data;


        if (this.state.filtrofamilia != "TodasLasFamilias") {
            listafiltrofamilia = listafiltrofamilia.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
        }

        if (this.state.filtrotipoproducto != "TodasLosProductos") {
            listafiltrotipo = listafiltrofamilia.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
        } else {
            listafiltrotipo = listafiltrofamilia;
        }



        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => 
            item.nombrecomercial.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ listaproductosfiltrados: filtros });
        } else if (e.target.value.trim().length < 1) {
            //this.filtrarFamilia(this.state.filtrofamilia)
            this.setState({ listaproductosfiltrados: listafiltrotipo });
        }

    }



    saveInputLocacionEquipos = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        var listafiltrotipo = [];
        listafiltrotipo = data;

        if (this.state.filtrotipoequipos != "TodosLosEquipos") {
            listafiltrotipo = listafiltrotipo.filter((item) => item.idtipo_equipo == this.state.filtrotipoequipos)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => item.nombreclave.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ listaproductosfiltrados: filtros });
        } else if (e.target.value.trim().length < 1) {
            this.filtrarTipoEquipo(this.state.filtrotipoequipos)
        }

    }





    filtro = (array) => {
        this.setState({
            listaproductosfiltrados: []
        });

        setTimeout(() => {
            this.setState({
                listaproductosfiltrados: array
            });
        }, 0);

    }

    filtrarFamilia = async (idfamilia) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            filtrofamilia: idfamilia
        })

        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];
        var cambiodefiltrofamiia = false;

        listafiltrofamilia = data;
        if (idfamilia != "TodasLasFamilias") {

            var listatipoproductosfiltrados = this.state.listatipoproductos.filter((item) => item.idfamilia_productos == idfamilia)
            cambiodefiltrofamiia = true;
            await this.setState({
                listatipoproductosfiltrados: listatipoproductosfiltrados,
                filtrotipoproducto: "TodasLosProductos"
            })
            listafiltrofamilia = data.filter((item) => item.idfamiliaproducto == idfamilia)
        } else {
            await this.setState({
                listatipoproductosfiltrados: this.state.listatipoproductos,
                filtrotipoproducto: "TodasLosProductos"
            })
        }

        if (this.state.filtrotipoproducto != "TodasLosProductos" && !cambiodefiltrofamiia) {
            listafiltrotipo = listafiltrofamilia.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
        } else { listafiltrotipo = listafiltrofamilia; }

        filtrofinal = listafiltrotipo;

        if (this.state.txtbuscador.length > 2) {
            filtrofinal = listafiltrotipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
                || item.clave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        if (cambiodefiltrofamiia) {
            this.filtro(filtrofinal)
        } else {
            this.filtro(filtrofinal)
        }

    }

    filtrarTipoProducto = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            filtrotipoproducto: itemfiltrado
        })

        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];

        listafiltrotipo = data;
        if (itemfiltrado != "TodasLosProductos") {

            listafiltrotipo = data.filter((item) => item.idtipoproducto == itemfiltrado)
        }

        if (this.state.filtrofamilia != "TodasLasFamilias") {
            listafiltrofamilia = listafiltrotipo.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
        } else { listafiltrofamilia = listafiltrotipo; }

        filtrofinal = listafiltrofamilia;

        if (this.state.txtbuscador.length > 2) {
            filtrofinal = listafiltrofamilia.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
                || item.clave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaproductosfiltrados: filtrofinal
        })

    }

    filtrarTipoProductoGeneral = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            filtrotipoproductogeneral: itemfiltrado
        })

        var filtrofinal = [];
        var listafiltrotipo = [];

        if (this.props.origen == "inventariogenerales") {
            if (itemfiltrado != "TodasLosProductosGenerales") {
                listafiltrotipo = data.filter((item) => item.idtipo_producto_general == itemfiltrado)
            } else { listafiltrotipo = data; }
        } else if (this.props.origen == "productosactivos") {
            if (itemfiltrado != "TodasLosProductosGenerales") {
                listafiltrotipo = data.filter((item) => item.idtipo_producto_general == itemfiltrado)
            } else { listafiltrotipo = data; }
        }


        filtrofinal = listafiltrotipo;

        if (this.state.txtbuscador.length > 2) {
            filtrofinal = listafiltrotipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
                || item.clave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaproductosfiltrados: filtrofinal
        })

    }

    /////////////////////////// 
    //filtrar tipo equipo

    filtrarTipoEquipo = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;
        
        await this.setState({
            filtrotipoequipos: itemfiltrado
        })

        var filtrofinal = [];
        var listafiltrotipo = [];

        if (itemfiltrado != "TodosLosEquipos") {

            listafiltrotipo = data.filter((item) => item.idtipo_equipo == itemfiltrado)
        } else { listafiltrotipo = data; }

        filtrofinal = listafiltrotipo;

        if (this.state.txtbuscador.length > 2) {
            filtrofinal = listafiltrotipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
                || item.clave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaproductosfiltrados: filtrofinal
        })

    }










    borrarBusqueda = () => {
        this.setState({
            txtbuscador: '',
            filtrofamilia: "TodasLasFamilias",
            filtrotipoproducto: "TodasLosProductos",
            listaproductosfiltrados: this.state.tabla.elementos
        });
    }

    seleccionarProducto = async (item) => {
        
        await this.setState({
            productoseleccionado: item,
            confirmarenvio: true,
            idproductoseleccionado: (this.props.origen == "inventariogenerales") ? (item.idcatalogo_producto_general) : (

                (this.props.origen == "inventarioequipos") ? (item.idequipo) : (
                    (this.props.origen == "productosactivos") ? (item.idcatalogo_producto_general) : (
                        item.idcatalogoproducto
                    )
                )

            )
        })
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Buscar productos</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                                {
                                    <div className='row'>
                                        {(this.props.origen == "inventarioequipos") ? (

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                                    <div className="selectBox ">
                                                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                            onChange={(event) => this.filtrarTipoEquipo(event.target.value)}>
                                                            {this.state.filtrotipoequipos == "TodosLosEquipos" ?
                                                                <option selected="true" value='TodosLosEquipos'>Todos</option> : <option value='TodasLosProductosGenerales'>Todos</option>}
                                                            {this.state.listatipoequipos.map((i, index) => (
                                                                <option value={i.idtipoequipo} key={"tipo_" + index}  >{i.tipo} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        ) : (null)}






                                        {(this.props.origen == "inventariogenerales") ? (
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                                    <div className="selectBox ">
                                                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                            onChange={(event) => this.filtrarTipoProductoGeneral(event.target.value)}>
                                                            {this.state.filtrotipoproductogeneral == "TodasLosProductosGenerales" ?
                                                                <option selected="true" value='TodasLosProductosGenerales'>Todos</option> : <option value='TodasLosProductosGenerales'>Todos</option>}
                                                            {this.state.listatipoproductosgeneralesfiltrados.map((i, index) => (
                                                                <option value={i.idtipo_producto_general} key={"tipo_" + index}  >{i.tipo} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            null

                                        )}

                                        {(this.props.origen == "productosactivos") ? (
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                                    <div className="selectBox ">
                                                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                            onChange={(event) => this.filtrarTipoProductoGeneral(event.target.value)}>
                                                            {this.state.filtrotipoproductogeneral == "TodasLosProductosGenerales" ?
                                                                <option selected="true" value='TodasLosProductosGenerales'>Todos</option> : <option value='TodasLosProductosGenerales'>Todos</option>}
                                                            {this.state.listatipoproductosgeneralesfiltrados.map((i, index) => (
                                                                <option value={i.idtipo_producto_general} key={"tipo_" + index}  >{i.tipo} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            null

                                        )}

                                        {(this.props.origen != "inventariogenerales" && this.props.origen != "inventarioequipos" && this.props.origen != "productosactivos") ? (
                                            <>
                                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Familia</label>
                                                        <div className="selectBox ">
                                                            <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                onChange={(event) => this.filtrarFamilia(event.target.value)}>
                                                                {this.state.filtrofamilia == "TodasLasFamilias" ?
                                                                    <option selected="true" value='TodasLasFamilias'>Todos</option> : <option value='TodasLasFamilias'>Todos</option>}
                                                                {this.state.listafamiliaproductos.map((i, index) => (
                                                                    <option value={i.idfamilia_productos} key={"tipo_" + index}  >{i.familia} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                                        <div className="selectBox ">
                                                            <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                onChange={(event) => this.filtrarTipoProducto(event.target.value)}>
                                                                {this.state.filtrotipoproducto == "TodasLosProductos" ?
                                                                    <option selected="true" value='TodasLosProductos'>Todos</option> : <option value='TodasLosProductos'>Todos</option>}
                                                                {this.state.listatipoproductosfiltrados.map((i, index) => (
                                                                    <option value={i.idtipo_producto} key={"tipo_" + index}  >{i.tipo} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>

                                        ) : (null)}

                                        {(this.props.origen == "inventarioequipos") ? (<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                            <div className='txtBuscadorCN' >
                                                <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                                <input id='Search' type="search" onChange={this.saveInputLocacionEquipos} value={this.state.txtbuscador}

                                                    className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                    placeholder="Buscar clave/nombre"></input>
                                                {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                    onClick={this.borrarBusqueda} />) : null}
                                            </div>
                                        </div>) : (
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='txtBuscadorCN' >
                                                    <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                                    <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                        placeholder="Buscar clave/agroquímico"></input>
                                                    {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                        onClick={this.borrarBusqueda} />) : null}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                }

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTabla">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo} 
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                    {
                                                        this.state.listaproductosfiltrados.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)} className={this.state.idproductoseleccionado == ((this.props.origen == "inventariogenerales" || this.props.origen == "productosactivos") ? (item.idcatalogo_producto_general) : ((this.props.origen == "inventarioequipos") ? (item.idequipo) : (item.idcatalogoproducto))) ? "trFondoActivoGreen" : ""}>

                                                                {(this.props.origen == "inventariogenerales") ? (

                                                                    <>
                                                                       
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.nombrecomercial}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {this.props.listaproductosalmacen.filter((i) => i.idcatalogoproductogeneral == item.idcatalogo_producto_general)[0].existencias}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.tipoproducto_general}
                                                                        </td>
                                                                    </>
                                                                ) : (

                                                                    (this.props.origen == "inventarioequipos") ? (

                                                                        <>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {item.nombreclave}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {item.marca}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {item.modelo}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {item.tipoequipo}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {this.props.listaproductosalmacen.filter((i) => i.idequipo == item.idequipo)[0].existencias}
                                                                            </td>

                                                                        </>
                                                                    ) : (
                                                                        (this.props.origen == "productosactivos") ? (
                                                                            <>
                                                                               
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {item.nombrecomercial}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {this.props.listaproductosalmacen.filter((i) => i.idproducto == item.idcatalogo_producto_general)[0].existencias}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {item.tipoproducto_general}
                                                                                </td>
                                                                            </>

                                                                        ) : (

                                                                            <>
                                                                              
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {item.nombrecomercial}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {item.familiaproducto}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {this.props.listaproductosalmacen.filter((i) => i.idcatalogoproducto == item.idcatalogoproducto)[0].existencias}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {item.tipoproducto}
                                                                                </td>
                                                                            </>
                                                                        )


                                                                    )
                                                                )}

                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>


                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

