import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesOrdenCosecha.css';
import { Table } from 'reactstrap';
import { BiEdit } from "react-icons/bi";
import {estiloComboBox, estiloFiltroEncabezados, estiloBtnNuevo,estiloBtnEditar} from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getListaLotes, getListaTablaCultivos, getListaCuadrillas, getDetallesOrdenCosecha, getDetallesOrdenCosechaGuardarCuadrilla, getDetallesOrdenCosechaGuardarTabla, getListaTiposPago } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import ModalFormularioNuevoPlanCosecha from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalFormularioNuevoPlanCosecha';
import ModalFormularioNuevoPlanCuadrilla from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalFormularioNuevoPlanCuadrilla';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';

export default class DetallesOrdenCosecha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      loadingspinners: true,
      errordeacceso: false,
      redireccionando: false,
      redireccionandobandera: "",

      altura: window.innerHeight,
      anchura: window.innerWidth,

      informaciondetallescosecha: {
        cuadrillas: [],
        tablas: [],
        ordencosecha: {},
      },

      tablasplanes: {
        encabezados: [
          "Tabla",
          "Cultivo",
          "",
          "",
        ],
        encabezados2: [
          "Cuadrilla",
          "Cortadores#",
          "Viáticos",
          "Apoyo cosecha",
          "Tipo pago",
          "Tipo corte",
          "Precio unidad",
          "Pago por día",
          "",
          ""
        ],
        dimensiones: [
          "25%",
          "25%",
          "25%",
          "25%",
          "25%",
          "25%",
          "25%",
          "25%",
          "15%",
          "10%"
        ],
        mindimensiones: [
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "200px",
          "130px",
          "130px"
        ],
        maxdimensiones: [
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "150px",
          "200px",
          "130px",
          "130px"
        ],
        dimensiones2: [
          "40%",
          "50%",
          "10%",
          "10%"
        ],
        mindimensiones2: [
          "200px",
          "130px",
          "130px",
          "50px"
        ],
        maxdimensiones2: [
          "200px",
          "130px",
          "130px",
          "50px"
        ]
      },

      listalotes: [],
      listatablas: [],
      listacuadrillas: [],
      listatipospago: [],

      accionmodal: 1,
      titulomodal: '',
      itemeliminar: undefined,
      modaladdplancosecha: false,
      modaladdplancuadrilla: false,
      modalconfirmaraccion: false,
      itemseleccionado:undefined,
      idcatalogo:0,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    var resptipospago = await getListaTiposPago();
    if (this.manejadorLlamadasApis(resptipospago)) {
      return;
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var ordencosechaaux = await localStorage.getItem('ordencosecha')
    var ordencosecha = JSON.parse(ordencosechaaux)
    var idcatalogo_ordenes_cosecha = ordencosecha.idcatalogo_ordenes_cosecha
    if (idcatalogo_ordenes_cosecha != null) {
      var respdetallescosecha = await getDetallesOrdenCosecha({"idcatalogo_ordenes_cosecha": idcatalogo_ordenes_cosecha});
      if (this.manejadorLlamadasApis(respdetallescosecha, 1)) {
        return;
      }

      var resplistalotes = await getListaLotes();
      if ( this.manejadorLlamadasApis (resplistalotes,0) ) {
        return;
      }

      var respcatalogotablacultivos = await getListaTablaCultivos();
      if ( this.manejadorLlamadasApis (respcatalogotablacultivos,0) ) {
        return;
      }

      var respcatalogocuadrillas = await getListaCuadrillas();
      if ( this.manejadorLlamadasApis (respcatalogocuadrillas,0) ) {
        return;
      }

      var informaciondetallescosecha = this.state.informaciondetallescosecha
      informaciondetallescosecha = respdetallescosecha.data ? respdetallescosecha.data : {};
      informaciondetallescosecha.ordencosecha = ordencosecha;

      this.setState({
        informaciondetallescosecha: informaciondetallescosecha,
        listalotes: resplistalotes.data,
        listatablas: respcatalogotablacultivos.data,
        listacuadrillas: respcatalogocuadrillas.data,
        listatipospago:resptipospago.data
      });
    }
    else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  manejadorLlamadasApis = (resp, detalles) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 200 && detalles == 1) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      setTimeout(async () => {
        this.redireccionando();
      }, 3000);
      return;
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  format_numero_decimales = (number) => {
    return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
  }

  format_moneda = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  }

  verModal = (ban) => {
    if (ban.opcion == 1) { //Nuevo plan cosecha
      this.setState({ titulomodal: 'Nuevo plan de cosecha', accionmodal: 1, modaladdplancosecha: true })
    }
    else if (ban.opcion == 6) { //Editar plan cosecha
      this.setState({ titulomodal: 'Editar plan de cosecha', accionmodal: 6, modaladdplancosecha: true, itemseleccionado:ban.item})
    }
    else if (ban.opcion == 2) { //Nuevo plan cuadrilla
      this.setState({ titulomodal: 'Nuevo plan de cuadrilla', accionmodal: 1, modaladdplancuadrilla: true });
    }
    else if (ban.opcion == 5) { //editar plan cuadrilla
      this.setState({ titulomodal: 'Editar plan de cuadrilla', accionmodal: 5, modaladdplancuadrilla: true, itemseleccionado:ban.item});
    }
    else if (ban.opcion == 3) { //confirmar accion eliminar cuadrilla
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "cuadrilla", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
    else if (ban.opcion == 4) { //confirmar accion eliminar cuadrilla
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "tabla", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
  }

  cerrarModalCosecha = (accion) => {
    this.setState({ modaladdplancosecha: !this.state.modaladdplancosecha })
    if(accion == 1){
      toast.success("Plan de cosecha creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if(accion == 2){
      toast.success("Plan de cosecha editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  cerrarModalCuadrilla = (accion) => {
    this.setState({ modaladdplancuadrilla: !this.state.modaladdplancuadrilla })
    if(accion == 1){
      toast.success("Plan de cuadrillas creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if(accion == 2){
      toast.success("Plan de cuadrillas editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    if(elemento.accion==1){
      this.setState({ loadingspinners: true })
      if(elemento.plan=="cuadrilla"){
        let json = {
          "idcatalogo_ordenes_cosecha_cuadrillas": elemento.item.idcatalogo_ordenes_cosecha_cuadrillas,
          "idcatalogo_ordenes_cosecha": this.state.informaciondetallescosecha.ordencosecha.idcatalogo_ordenes_cosecha,
          "idcatalogo_cuadrillas": elemento.item.idcatalogo_cuadrillas,
          "cortadores": elemento.item.cortadores,
          "costoflete": elemento.item.costoflete,
          "costococecha": elemento.item.costococecha,
          "idcatalogo_tipo_pago_corte": elemento.item.idcatalogo_tipo_pago_corte,
          "costounidad": elemento.item.costounidad,
          "costodia": elemento.item.costodia,
          "eliminar": 1
        }
        var resp = await getDetallesOrdenCosechaGuardarCuadrilla(json);
      }
      else if(elemento.plan=="tabla"){
        let json = {
          "idcatalogo_ordenes_cosecha_tablas": elemento.item.idcatalogo_ordenes_cosecha_tablas,
          "idcatalogo_ordenes_cosecha": this.state.informaciondetallescosecha.ordencosecha.idcatalogo_ordenes_cosecha,
          "idcatalogo_lotes_cultivo_tabla":elemento.item.idcatalogo_ranchos_lotes_cultivo,
          "eliminar": 1
        }
        var resp = await getDetallesOrdenCosechaGuardarTabla(json);
      }

      if(resp.code == 0){
        this.setState({ loadingspinners: false })
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else if(resp.code == 200){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 300){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
        :null}

        {(this.state.redireccionando) ?
          (<Acciones bandera={'goreferencias'} />)
        :null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        :null}

        {(this.state.modaladdplancosecha == true) ?
          <ModalFormularioNuevoPlanCosecha
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalCosecha}
            show={this.state.modaladdplancosecha}
            accion={this.state.accionmodal}
            listalotes={this.state.listalotes}
            listatablas={this.state.listatablas}
            idordecosecha={this.state.informaciondetallescosecha.ordencosecha.idcatalogo_ordenes_cosecha}
            infodetalles={this.state.informaciondetallescosecha}
            setLoadingSpinner={this.setLoadingSpinner}
            itemseleccionado={this.state.itemseleccionado}
          />
        :null}

        {(this.state.modaladdplancuadrilla == true) ?
          <ModalFormularioNuevoPlanCuadrilla
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalCuadrilla}
            show={this.state.modaladdplancuadrilla}
            accion={this.state.accionmodal}
            listacuadrillas={this.state.listacuadrillas}
            idordecosecha={this.state.informaciondetallescosecha.ordencosecha.idcatalogo_ordenes_cosecha}
            listatipopago={this.state.listatipospago}
            setLoadingSpinner={this.setLoadingSpinner}
            itemseleccionado={this.state.itemseleccionado}
          />
        :null}

        {(this.state.modalconfirmaraccion == true) ?
          <ModalConfirarAccion
            titulomodal={this.state.titulomodal}
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion}
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
          />
        :null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/* ////////////////////  Sección de encabezazdo con información general //////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{"Detalles de orden cosecha"}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.fechacosecha}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Zona: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.zona}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.idestatus==1 ? "Activo" : ""}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Rancho: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.rancho}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estimado: </label>
                <label className='lblTexto letraTitulos' >{this.format_moneda(this.state.informaciondetallescosecha.ordencosecha.estimado)}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Lote: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.lote}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo corte: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.tipocorte}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Comentarios: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.comentarios}</label>
              </div>

            </div>
            <br />
            <br />
          </div>
        </div>

        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>

          {/* ////////////////////   Sección de plan de cosechas  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>

                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Plan de cosecha</b></label>
                  </div>

                  <div className='col-2 paddingBottomEncabezados  ' >
                    <button className='btnNuevo letraNormal'
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                      onClick={this.verModal.bind(this, { opcion: "1" })}>+ Agregar</button>
                  </div>
                </div>

                {/* ////////////////////   Tabla de plan de cosechas  ////////////////////////////////////// */}
                <div className='divMinWidthAutoCot'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablasplanes.encabezados.map((titulo, index) => (
                          <th
                            key={"tabla" + index}
                            className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablasplanes.mindimensiones2[index],
                              maxWidth: this.state.tablasplanes.maxdimensiones2[index],
                              width: this.state.tablasplanes.dimensiones2[index],
                              color: this.state.colorencabezadostabla
                            }}
                          >
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.state.informaciondetallescosecha.tablas.map((item, index) => (
                        <tr key={"tabla" + index}
                          style={{
                            borderBottom: (index == this.state.informaciondetallescosecha.tablas.length - 1) ? 'solid 1px #91b9f9' : ''
                          }}
                        >
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.tabla}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                            {item.cultivo}
                          </td>
                          <td key={"col-" + index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            <button
                              className='btnEditar'
                              style={{backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                              onClick={this.verModal.bind(this, { opcion: "6", item })}
                            >
                              <BiEdit className="icoBotones"/> Editar
                            </button>
                          </td>
                          <td key={"col-"+index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            <div className="modalDetallesCheck">
                              <button
                                className='btnEliminarFila'
                                onClick={this.verModal.bind(this, { opcion: "4", item })}
                              >
                                <FaTrashAlt className="icoBotones" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <br />

          {/* ////////////////////   Sección de cuadrillas  ////////////////////////////////////// */}
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">
              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>

                <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
                  <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Plan de cuadrillas</b></label>
                  </div>

                  <div className='col-2 paddingBottomEncabezados  ' >
                    <button className='btnNuevo letraNormal'
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                      onClick={this.verModal.bind(this, { opcion: "2" })}>+ Agregar</button>
                  </div>
                </div>
                {/* ////////////////////   Tabla de cuadrillas  ////////////////////////////////////// */}
                <div className="container-fluid anchoMaximo containerTabla">
                  <div className="container-fluid anchoMaximo2 ">
                    <div className="containerSubTabla">
                      <Table>
                        <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                          <tr>
                            {this.state.tablasplanes.encabezados2.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tablasplanes.mindimensiones[index],
                                  maxWidth: this.state.tablasplanes.maxdimensiones[index],
                                  width: this.state.tablasplanes.dimensiones[index],
                                  textAlign: index > 0 ? 'center' : '',
                                  color: this.state.colorencabezadostabla
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {this.state.informaciondetallescosecha.cuadrillas.map((item, index) => (
                            <tr
                              key={"tabla" + index}
                              style={{ borderBottom: (index == this.state.informaciondetallescosecha.cuadrillas.length - 1) ? 'solid 1px #91b9f9' : ''}}
                            >

                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.nombre}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right', paddingRight:'30px'}}>
                                {this.format_numero(item.cortadores)}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right', paddingRight:'20px'}}>
                                {this.format_moneda(item.costoflete)}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                                {this.format_moneda(item.costococecha)}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {item.tipopago}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                {this.state.informaciondetallescosecha.ordencosecha.tipocorte}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right',paddingRight:'40px' }}>
                                {this.format_moneda(item.costounidad)}
                              </td>
                              <td  className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                                {this.format_moneda(item.costodia)}
                              </td>
                              <td key={"col-" + index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                <button
                                  className='btnEditar'
                                  style={{backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                                  onClick={this.verModal.bind(this, { opcion: "5", item })}
                                >
                                  <BiEdit className="icoBotones" /> Editar
                                </button>
                              </td>
                              <td key={"col-"+index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                <div className="modalDetallesCheck" >
                                  <button
                                    className='btnEliminarFila'
                                    onClick={this.verModal.bind(this, { opcion: "3", item })}
                                  >
                                    <FaTrashAlt className="icoBotones" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </>
    )
  }
}

{/*///////// Sección de acciones de redireccionamiento ////////*/}
function Acciones({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordencosecha"} />)
  }else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
