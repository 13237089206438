import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioProducto.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales3 } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaPresentacionesUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const todaslasopcionesdisponibles = -1

export default class ModalFormularioPresentaciones extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            unidadmedida: props.unidadmedida,
            listapresentacion: props.listapresentaciones,

            idcatalogoempaque: (props.accion == 2) ? (props.item.idcatalogo_empaque) : todaslasopcionesdisponibles,
            vermsgerrorcampoidcatalogoempaque: false,
            msgerrorcampoidcatalogoempaque: "",

            txtcantidad: (props.accion == 2) ? (props.item.cantidad) : '',
            vermsgerrorcampotxtcantidad: false,
            msgerrorcampotxtcantidad: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo
        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarOpcion = (campo, id) => {
        this.setState({ [campo]: id })
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrorcampoidcatalogoempaque: false })
        this.setState({ vermsgerrorcampotxtcantidad: false })
        let validacionescorrectas = true

        if (this.state.idcatalogoempaque == todaslasopcionesdisponibles) {
            validacionescorrectas = false;
            this.setState({ msgerrorcampoidcatalogoempaque: "Seleccione una presentación" })
            this.setState({ vermsgerrorcampoidcatalogoempaque: true })
        }

        let longitudvalida = { longitudminimaentera: 0, longitudmaximaentera: 7, longitudminimadecimal: 1, longitudmaximadecimal: 2 }
        let txtcantidad = validarNumerosDecimales3(this.state.txtcantidad.trim(), longitudvalida);
        if (txtcantidad.codigo == "invalid") {
            this.setState({ msgerrorcampotxtcantidad: txtcantidad.mensaje })
            this.setState({ vermsgerrorcampotxtcantidad: true })
            validacionescorrectas = false;
        }


        if (validacionescorrectas) {
            this.setLoadingSpinner(true)
            let json = {
                "idcatalogoproducto_empaque": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogoproducto_empaque),
                "idcatalogoproducto": this.props.item.idcatalogoproducto,
                "idcatalogo_empaque": this.state.idcatalogoempaque,
                "cantidad": this.state.txtcantidad,
                "descripcion": ""
            }
            var resp = await getListaPresentacionesUpdate(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Presentación</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarOpcion("idcatalogoempaque", event.target.value)}>
                                                    {<option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={todaslasopcionesdisponibles == this.state.idcatalogoempaque} >Seleccionar </option>}
                                                    {this.state.listapresentacion.map((i, index) => (
                                                        <option value={i.idcatalogo_empaque} key={"tipo_" + index} selected={i.idcatalogo_empaque == this.state.idcatalogoempaque} >{i.empaque} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorcampoidcatalogoempaque ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcampoidcatalogoempaque}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cantidad ({this.state.unidadmedida})</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcantidad} onChange={this.escribir("txtcantidad")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcantidad}</label>}

                                {this.state.vermsgerrorcampotxtcantidad ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcampotxtcantidad}</p> : null}

                            </div>
                            {/*this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null*/}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

