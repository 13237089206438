import React from "react";
import { Table } from 'reactstrap';
import { estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import './CatalogoProducto';

export default class TablaContenidoUnico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

    }
  }

  render() {
    return (
      <Table>
        <thead style={{ verticalAlign: "bottom", backgroundColor: this.state.fondotitulosencabezados }}>
          <tr key={"tabla" + 1}>
            {this.props.opcion == "informaciongeneral1" ? this.props.tabla.encabezados[0].map((titulo, index) => (
              <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][index],
                  width: this.props.tabla.dimensiones[0][index], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {titulo} 
              </th>
            )) : null}
            {this.props.opcion == "informaciongeneral2" ? this.props.tabla.encabezados[1].map((titulo, index) => (
              <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[1][index],
                  width: this.props.tabla.dimensiones[1][index], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {titulo}
              </th>
              )) : null}
          </tr>
        </thead>


        <tbody style={{ verticalAlign: "bottom" }}>
          {(this.props.opcion == "informaciongeneral1" /*&& this.props.tabla.elementos.length > 0*/) ?
            <tr>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.nombrecomercial}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.familiaproducto}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.tipoproducto}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.registrocofepris}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.estatus}
              </td>
            </tr>
            :
            (this.props.opcion == "informaciongeneral2" /*&& this.props.tabla.elementos.length > 0*/) ?
              <tr>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.unidadmedida}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.bandatoxicologica}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.intervaloseguridad}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.tiemporeentrada}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.dosisminima}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.dosismaxima}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos.volumenagua}
                </td>
              </tr> : null}

        </tbody>
      </Table>
    )
  }
}
