import React from 'react'
import './CatalogoNuevaRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { estiloBtnAgregar, estiloBtnActualizar, estiloBtnNuevo,estilosModal } from '../../Services/PaletaDeColores';
import ModalFormularioLotesDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioLotesDisponibles';

import { Redirect } from 'react-router-dom';
import Lote from './Lote';
import 'react-toastify/dist/ReactToastify.css';


export default class Rancho extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion:  props.accion, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,
      rancho: this.props.itemrancho,
      //listalotesagregados: [],

      colapsartabla:true,
      eliminartabla:false,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();


    var lotesdisponibles = this.props.listalotes.filter((item) => item.idcatalogo_rancho == this.props.itemrancho.idrancho );
    setTimeout(() => {
      this.setState({ 
        listalotesdisponibles:  lotesdisponibles
     })
   }, 200);
    

    window.addEventListener("resize", this.dimensiones);
    
  }
 
  
  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Lotes disponibles', accionmodal: 1 })
    }
    

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (listalotes) => {
    var listalotesagregadosaux = this.props.itemrancho.lotes;
    listalotesagregadosaux = listalotesagregadosaux.concat(listalotes)
    this.setState({ 
      modaldetalles: !this.state.modaldetalles,
      colapsartabla: true,
   //   listalotesagregados: listalotesagregadosaux
    })

    var auxrancho = this.state.rancho;
    auxrancho.lotes = listalotesagregadosaux;
    this.props.actualizarContenido(auxrancho);

  }

  actualizarContenido = (lote) => {
    var listalotes = this.props.itemrancho.lotes;

    var index =  listalotes.findIndex((obj => obj.idlote == lote.idlote));
    if(index > -1){
      listalotes[index] = lote;
    }
    var auxrancho = this.state.rancho;
    auxrancho.lotes = listalotes;

    this.props.actualizarContenido(auxrancho);
  }


  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var lotes =  this.props.itemrancho.lotes.filter(lote => lote.idlote != elementoeliminado.idlote);
    
    var auxrancho = this.state.rancho;
    auxrancho.lotes = lotes;

    this.props.actualizarContenido(auxrancho);
  }

  seleccionarElementoaEliminar = () => {
    this.props.eliminarElemento(this.state.rancho)
  }
  
  actualizarListaNuevosProductos = (nuevoproducto) =>{
    this.props.actualizarListaNuevosProductos(nuevoproducto);
  }
  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  render() {
    return (

      <>
        {(this.state.modaldetalles == true) ? (
          <ModalFormularioLotesDisponibles titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} 
            show={this.state.modaldetalles} accion={this.state.accionmodal} rancho={this.props.itemrancho} listalotesdisponibles={this.state.listalotesdisponibles}/>
        ) : null}
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                            <p className='sinMargen'><b>Rancho:</b>{this.props.itemrancho.rancho}</p>
                            </div>
                          </div>
                      </TableCell>
                  
                      <TableCell align="right" className='displayFlex nonePaddingTopBottom' >
                        <div className='row flexNoWrap'>
                          {
                            <div className='col-auto minPaddingTopBottom'>
                            <button className='btnNuevo letraNormal'
                              style={{ color: this.state.colorbtnagregar, backgroundColor: this.state.colorfondobtnragregar, border: this.state.borderbtnactualizar}}
                              onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Agregar</button>
                          </div>
                          }
                         
                         
                          <div className="col-auto modalDetallesCheck minPaddingTopBottom" >
                              <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar()}
                              ><FaTrashAlt className="icoBotones" /></button>
                          </div>
                          
                        </div>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemrancho.lotes.length > 0 ?
                              this.props.itemrancho.lotes.map((item, index) => (
                                <div className='spacePaddingBottom'>
                                <Lote key={"lote_" + item.idlote} accion={this.state.accion} itemlote={item}
                                  actualizarContenido={this.actualizarContenido}
                                  eliminarElemento={this.eliminarElemento}
                                  actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                                  listatablas={this.props.listatablas}
                                  listaproductos={this.props.listaproductos}
                                  listafamiliaproductos={this.props.listafamiliaproductos}
                                  listatipoproductos={this.props.listatipoproductos}
                                  listaunidadesmedida={this.props.listaunidadesmedida}
                                  listanuevosproductos={this.props.listanuevosproductos}
                                 />
                                </div>
                              ))
                              : <div></div>
                            }
                           
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


      
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

