import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioTablaCultivo.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosConDosDecimales, validarLetrasNumerosEspacios, validarNumeros, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { getListaCatalogoTablaCultivosUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioTablaCultivo extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            txtnombretabla: (props.accion == 2) ? (props.item.tabla) : '',
            zona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '',
            rancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '',
            lote: (props.accion == 2) ? (props.item.idcatalogo_ranchos_lotes_cultivo) : '',
            tiposuelo: (props.accion == 2) ? (props.item.idtipo_suelo) : '0',
            txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
            txtlotetotal: (props.accion == 2) ? (props.item.areatotal) : '',
            txtloteefectivo: (props.accion == 2) ? (props.item.areaefectiva) : '',
            txtplantastotales: (props.accion == 2) ? (props.item.plantastotales) : '',
            txtplantel: (props.accion == 2) ? (props.item.plantel) : '',
            txtdesarrollo: (props.accion == 2) ? (props.item.desarrollo) : '',
            txtproductivas: (props.accion == 2) ? (props.item.productivas) : '',
            txtfallas: (props.accion == 2) ? (props.item.fallas) : '',
            estatussiembra: (props.accion == 2) ? (props.item.idcatalogo_estatus_cultivo) : '',
            idestatus: 1,
            arrayestatus: props.estatus,
            vermsgerrortxtnombretabla: false,
            vermsgerrorzona: false,
            vermsgerrorrancho: false,
            vermsgerrorlote: false,
            vermsgerrortxtdescripcion: false,
            vermsgerrortxtlotetotal: false,

            vermsgerrortiposuelos: false,

            vermsgerrortxtloteefectivo: false,
            vermsgerrortxtplantastotales: false,
            vermsgerrortxtplantel: false,
            vermsgerrortxtdesarrollo: false,
            vermsgerrortxtproductivas: false,
            vermsgerrortxtfallas: false,
            vermsgerrorestatussiembra: false,

            msgerrortxtnombretabla: "",
            msgerrorzona: "",
            msgerrorrancho: "",
            msgerrorlote: "",

            msgerrortiposuelo: "",

            msgerrortxtdescripcion: "",
            msgerrortxtlotetotal: "",
            msgerrortxtloteefectivo: "",
            msgerrortxtplantastotales: "",
            msgerrortxtplantel: "",
            msgerrortxtdesarrollo: "",
            msgerrortxtproductivas: "",
            msgerrortxtfallas: "",
            msgerrorestatussiembra: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            arraytipo: props.arraytipos,
            listazona: props.listazona,

            listatiposuelos: props.listatiposuelos,

            listarancho: this.props.listarancho,
            filtrolistarancho: this.props.listarancho,
            listalote: this.props.listalote,
            filtrolistalote: this.props.listalote,
            listaestatussiembra: props.listaestatussiembra != undefined ? props.listaestatussiembra : []
        }

    }
    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)

        if (this.props.accion == 2) {

            var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == this.state.zona)
            var resplistalotes = this.state.listalote.filter((item) => item.idcatalogo_rancho == this.state.rancho)
            await this.setState({
                filtrolistarancho: resplistaranchos,
                filtrolistalote: resplistalotes,
            })

        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };


    cambiarTipo = async (prop, id) => {

        if (prop == "zona") {
            var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == id)
            await this.setState({
                filtrolistarancho: resplistaranchos,
                filtrolistalote: [],
                rancho: "",
                lote: ""
            })
        }
        if (prop == "rancho") {
            var resplistalotes = this.state.listalote.filter((item) => item.idcatalogo_rancho == id)
            await this.setState({
                filtrolistalote: resplistalotes,
                lote: ""
            })
        }

        await this.setState({ [prop]: id })
    }

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {
        let longitudcincuentavalida = { longitudminima: 1, longitudmaxima: 50 };
        let longitudcienvalida = { longitudminima: 1, longitudmaxima: 100 };
        let longitudnuevevalida = { longitudminima: 1, longitudmaxima: 9 };
        let decimalvalido = {
            longitudenterominimo: 1,
            longitudenteromaximo: 9,
            longituddecimalminimo: 1,
            longituddecimalmaximo: 2
        }


        this.setState({ vermsgerrortxtnombretabla: false })
        this.setState({ vermsgerrorzona: false })
        this.setState({ vermsgerrorrancho: false })
        this.setState({ vermsgerrorlote: false })

        this.setState({ vermsgerrortiposuelos: false })

        this.setState({ vermsgerrortxtdescripcion: false })
        this.setState({ vermsgerrortxtlotetotal: false })
        this.setState({ vermsgerrortxtloteefectivo: false })
        this.setState({ vermsgerrortxtplantastotales: false })
        this.setState({ vermsgerrortxtplantel: false })
        this.setState({ vermsgerrortxtdesarrollo: false })
        this.setState({ vermsgerrortxtproductivas: false })
        this.setState({ vermsgerrortxtfallas: false })
        this.setState({ vermsgerrorestatussiembra: false })



        let validacionescorrectas = true
        let txtnombretabla = validarLetrasNumerosEspacios(this.state.txtnombretabla.trim(), longitudcincuentavalida);
        if (txtnombretabla.codigo == "invalid") {
            this.setState({ msgerrortxtnombretabla: txtnombretabla.mensaje })
            this.setState({ vermsgerrortxtnombretabla: true })
            validacionescorrectas = false;
        }

        if (this.state.zona.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorzona: "Seleccione una zona" })
            this.setState({ vermsgerrorzona: true })
        }

        if (this.state.rancho.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorrancho: "Seleccione una rancho" })
            this.setState({ vermsgerrorrancho: true })
        }

        if (this.state.lote.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorlote: "Seleccione un lote" })
            this.setState({ vermsgerrorlote: true })
        }

        if (this.state.tiposuelo < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortiposuelo: "Seleccione un tipo de suelo" })
            this.setState({ vermsgerrortiposuelos: true })
        }

        if (this.state.txtdescripcion.length > 0) {
            let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudcienvalida);
            if (txtdescripcion.codigo == "invalid") {
                this.setState({ msgerrortxtdescripcion: txtdescripcion.mensaje })
                this.setState({ vermsgerrortxtdescripcion: true })
                validacionescorrectas = false;
            }
        }
        let txtlotetotal = validarNumerosConDosDecimales(this.state.txtlotetotal.trim(), decimalvalido);
        if (txtlotetotal.codigo == "invalid") {
            this.setState({ msgerrortxtlotetotal: txtlotetotal.mensaje })
            this.setState({ vermsgerrortxtlotetotal: true })
            validacionescorrectas = false;
        }

        let txtloteefectivo = validarNumerosConDosDecimales(this.state.txtloteefectivo.trim(), decimalvalido);
        if (txtloteefectivo.codigo == "invalid") {
            this.setState({ msgerrortxtloteefectivo: txtloteefectivo.mensaje })
            this.setState({ vermsgerrortxtloteefectivo: true })
            validacionescorrectas = false;
        }

        let txtplantastotales = validarNumeros(this.state.txtplantastotales, longitudnuevevalida);
        if (txtplantastotales.codigo == "invalid") {
            this.setState({ msgerrortxtplantastotales: txtplantastotales.mensaje })
            this.setState({ vermsgerrortxtplantastotales: true })
            validacionescorrectas = false;
        }

        let txtplantel = validarNumeros(this.state.txtplantel, longitudnuevevalida);
        if (txtplantel.codigo == "invalid") {
            this.setState({ msgerrortxtplantel: txtplantel.mensaje })
            this.setState({ vermsgerrortxtplantel: true })
            validacionescorrectas = false;
        }

        let txtdesarrollo = validarNumeros(this.state.txtdesarrollo, longitudnuevevalida);
        if (txtdesarrollo.codigo == "invalid") {
            this.setState({ msgerrortxtdesarrollo: txtdesarrollo.mensaje })
            this.setState({ vermsgerrortxtdesarrollo: true })
            validacionescorrectas = false;
        }

        let txtproductivas = validarNumeros(this.state.txtproductivas, longitudnuevevalida);
        if (txtproductivas.codigo == "invalid") {
            this.setState({ msgerrortxtproductivas: txtproductivas.mensaje })
            this.setState({ vermsgerrortxtproductivas: true })
            validacionescorrectas = false;
        }

        let txtfallas = validarNumeros(this.state.txtfallas, longitudnuevevalida);
        if (txtfallas.codigo == "invalid") {
            this.setState({ msgerrortxtfallas: txtfallas.mensaje })
            this.setState({ vermsgerrortxtfallas: true })
            validacionescorrectas = false;
        }

        if (this.state.estatussiembra.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorestatussiembra: "Seleccione un status" })
            this.setState({ vermsgerrorestatussiembra: true })
        }


        if (validacionescorrectas) {
            this.props.activarSpinner(true);

            let json = {
                "idtabla": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogo_lotecultivo_tabla),
                "tabla": this.state.txtnombretabla,
                "idlote": this.state.lote,
                "descripcion": this.state.txtdescripcion,
                "lotetotal": this.state.txtlotetotal,
                "idtiposuelo": this.state.tiposuelo,
                "loteefectivo": this.state.txtloteefectivo,
                "plantastotales": this.state.txtplantastotales,
                "plantel": this.state.txtplantel,
                "desarrollo": this.state.txtdesarrollo,
                "productivas": this.state.txtproductivas,
                "fallas": this.state.txtfallas,
                "idestatuscultivo": this.state.estatussiembra,
                "idestatus": this.state.idestatus

            }

            var resp = await getListaCatalogoTablaCultivosUpdate(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.props.activarSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.props.activarSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre tabla</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombretabla} onChange={this.escribir("txtnombretabla")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombretabla}</label>}

                                {this.state.vermsgerrortxtnombretabla ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombretabla}</p> : null}

                                {/* ////////////////////   Inicio Sección selector zona   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("zona", event.target.value)}>
                                                    {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                    {this.state.listazona.map((i, index) => (
                                                        this.state.accion == 1 ?
                                                            <option value={i.idcatalogo_zona} key={"tipo_" + index} >{i.zona} </option> :
                                                            <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.zona ? true : false} >{i.zona} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorzona ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p> : null}


                                {/* ////////////////////   Inicio Sección selector rancho   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("rancho", event.target.value)}>
                                                    {this.state.rancho == "" ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                    {this.state.filtrolistarancho.map((i, index) => (
                                                        this.state.accion == 1 ?
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + index} >{i.rancho} </option> :
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + index} selected={i.idcatalogo_rancho == this.state.rancho ? true : false} >{i.rancho} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorrancho ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p> : null}

                                {/* ////////////////////   Inicio Sección selector rancho   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Lote</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("lote", event.target.value)}>
                                                    {this.state.lote == "" ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                    {this.state.filtrolistalote.map((i, index) => (
                                                        this.state.accion == 1 ?
                                                            <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index} >{i.lote} </option> :
                                                            <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index} selected={i.idcatalogo_ranchos_lotes_cultivo == this.state.lote ? true : false} >{i.lote} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorlote ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorlote}</p> : null}


                                {/* ////////////////////   Inicio Sección campo tipo suelo ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo de suelo</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tiposuelo")}>
                                                    <option disabled="disabled">Seleccionar</option>
                                                    {this.state.listatiposuelos == undefined || this.state.listatiposuelos.length == 0 ? null : this.state.listatiposuelos.map((i) => (
                                                        <option value={i.idtipo_suelo} key={"tipo_" + i.idtipo_suelo} selected={i.idtipo_suelo == this.state.tiposuelo ? true : false} >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tiposuelo")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listatiposuelos == undefined || this.state.listatiposuelos.length == 0 ? null : this.state.listatiposuelos.map((i) => (
                                                    <option value={i.idtipo_suelo} key={"tipo_" + i.idtipo_suelo}>{i.tipo}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.vermsgerrortiposuelos ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortiposuelo}</p> : null}





                                {/* ////////////////////   Inicio Sección campo descripción ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                                {this.state.vermsgerrortxtdescripcion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripcion}</p> : null}

                                {/* ////////////////////   Inicio Sección campo área total  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área total (hectáreas)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtlotetotal} onChange={this.escribir("txtlotetotal")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtlotetotal}</label>}

                                {this.state.vermsgerrortxtlotetotal ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlotetotal}</p> : null}

                                {/* ////////////////////   Inicio Sección campo área efectiva  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área efectiva (hectáreas)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtloteefectivo} onChange={this.escribir("txtloteefectivo")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtloteefectivo}</label>}

                                {this.state.vermsgerrortxtloteefectivo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtloteefectivo}</p> : null}
                                {/* ////////////////////   Inicio Sección campo Plantas totales   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Plantas totales (unidades)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtplantastotales} onChange={this.escribir("txtplantastotales")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtplantastotales}</label>}

                                {this.state.vermsgerrortxtplantastotales ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtplantastotales}</p> : null}
                                {/* ////////////////////   Inicio Sección campo Plantel   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Plantel (unidades)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtplantel} onChange={this.escribir("txtplantel")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtplantel}</label>}

                                {this.state.vermsgerrortxtplantel ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtplantel}</p> : null}
                                {/* ////////////////////   Inicio Sección campo Desarrollo   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Desarrollo (unidades)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdesarrollo} onChange={this.escribir("txtdesarrollo")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdesarrollo}</label>}

                                {this.state.vermsgerrortxtdesarrollo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdesarrollo}</p> : null}
                                {/* ////////////////////   Inicio Sección campo Productivas   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Productivas (unidades)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtproductivas} onChange={this.escribir("txtproductivas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtproductivas}</label>}

                                {this.state.vermsgerrortxtproductivas ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtproductivas}</p> : null}
                                {/* ////////////////////   Inicio Sección campo Fallas   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fallas (unidades)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtfallas} onChange={this.escribir("txtfallas")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfallas}</label>}

                                {this.state.vermsgerrortxtfallas ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtfallas}</p> : null}

                                {/* ////////////////////   Inicio Sección selector estatus de siembra   ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus de siembra</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo("estatussiembra", event.target.value)}>
                                                    {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                    {this.state.listaestatussiembra.map((i, index) => (
                                                        this.state.accion == 1 ?
                                                            <option value={i.idcatalogo_estatus_cultivo} key={"tipo_" + index} >{i.estatuscultivo} </option> :
                                                            <option value={i.idcatalogo_estatus_cultivo} key={"tipo_" + index} selected={i.idcatalogo_estatus_cultivo == this.state.estatussiembra ? true : false} >{i.estatuscultivo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorestatussiembra ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorestatussiembra}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                            {/*this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null*/}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

