import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { validarPasswordEliminar } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { postAutorizarVentaCorridaLimon } from '../../../Services/Api'

export default class ModalAutorizarVenta extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
        show: props.show,
        titulo: props.titulomodal,
        accion:props.accion,
        idcatalogo_corridas_limon:this.props.idcatalogo_corridas_limon,

        colormsgerror: estiloColorError().msgerror,
        estilosmodal: estilosModal(),
        colortitulo: estiloFiltroEncabezados().colortitulos,

        txtpassword: "",
        vermsgerrortxtpassword: false,
        msgerrortxtpassword: "",

        txtprincipal: "",
        vermsgerrortxtprincipal: false,
        msgerrortxtprincipal: ""

        }
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
        this.props.cerrarModalAtutorizacion(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    autorizarVenta = async () => {

    let validacionescorrectas = true
    this.setState({ vermsgerrortxtpassword: false })
    this.setState({ vermsgerrortxtprincipal: false })

    let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
    if (txtpassword.codigo == "invalid") {
        this.setState({ msgerrortxtpassword: txtpassword.mensaje })
        this.setState({ vermsgerrortxtpassword: true })
        validacionescorrectas = false;
    }

    if (validacionescorrectas) {
        this.setLoadingSpinner(true);
        var resp = {};

        let json = {
            idcatalogo_corridas_limon: this.state.idcatalogo_corridas_limon,
            password: this.state.txtpassword
        }

        var resp = await postAutorizarVentaCorridaLimon(json);

        if(resp.code == 0){
            this.showModal('1')
        }
        else if(resp.code == 200){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if(resp.code == 300){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if(resp.code == 400){
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if (resp.code == 1000) {
            this.setLoadingSpinner(false);
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            return;
        }else{
            this.setLoadingSpinner(false);
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    }
}


    render() {
        return (

        <>
            <ToastContainer />

            <Modal isOpen={this.state.show}
            size="md"
            aria-labelledby=""
            centered>
            <ModalBody>
                <div className="divTituloModal">
                <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                </div>

                <br />

                <div className="divModalEliminar">
                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                    {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}

                    {(!this.state.vermsgerrortxtpassword && this.state.vermsgerrortxtprincipal) ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtprincipal}</p> : null}


                </div>
                </div>

                <div className="divBtnContenedorModal" >
                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                <Button className="btnAceptarModal" onClick={() => this.autorizarVenta()} >Continuar</Button>
                </div>

            </ModalBody>

            </Modal>
        </>
        )
    }
}
