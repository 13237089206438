import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { Table } from 'reactstrap';


export default class ModalAlmacenes extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      item: props.item,
      accion: props.accion,

      listaoperacionesestatus: props.listaalamcenproductosgenerales,
      listaalmacenequipos: props.listaalmacenequipos,
      idoperacionseleccionada: 0,
      idestatusactual: props.item.idestatusrequisicion,
      estilosmodal: estilosModal(),

      almacenproductos: '',
      almacenequipo: '',
      opcionalamacen: '',

      itemseleccionado: null
    }

  }
  async componentDidMount() {
    if (this.props.listadestinos) {
      var listadestinos = this.props.listadestinos
      var item = listadestinos.find(elemento => elemento.idtipo_destino == 2)
      var listahijos = item.hijos
      var itemhijo1 = listahijos.find(elemento => elemento.idtipo_destino == 4)
      var itemhijo2 = listahijos.find(elemento => elemento.idtipo_destino == 5)
      this.setState({ almacenproductos: itemhijo1.tipo, almacenequipo: itemhijo2.tipo })
    }
  
  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  showModal = (opcion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opcion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  seleccionarOperacion = async ({ item, campo }) => {
    this.setState({ idoperacionseleccionada: item[campo] })
    if (campo == 'idalmacenequipos') {
      this.setState({ opcionalamacen: 'idalmacenequipos' })
    } else if (campo == 'idalmacengenerales'){
      this.setState({ opcionalamacen: 'idalmacengenerales' })
    } else {
      this.setState({ opcionalamacen: 'sinalmacen' })
    }
    
    this.setState({ itemseleccionado: item })
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                  Selecciona almacén de rancho {this.props.itemrancho ? this.props.itemrancho.rancho : '0'}
                </p>


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {
                            this.state.listaoperacionesestatus
                              .filter((itemfilter) => itemfilter.idrancho == (this.props.itemrancho ? this.props.itemrancho.idcatalogo_rancho : -1))
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion({ item: item, campo: 'idalmacengenerales' })}
                                  className={
                                    (this.state.idoperacionseleccionada == item.idalmacengenerales && this.state.opcionalamacen == 'idalmacengenerales')
                                      ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {this.state.almacenproductos}
                                  </td>
                                </tr>
                              ))
                          }{
                            this.state.listaalmacenequipos
                              .filter((itemfilter) => itemfilter.idrancho == (this.props.itemrancho ? this.props.itemrancho.idcatalogo_rancho : -1))
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion({ item: item, campo: 'idalmacenequipos' })}
                                  className={
                                    (this.state.idoperacionseleccionada == item.idalmacenequipos && this.state.opcionalamacen == 'idalmacenequipos')
                                      ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {this.state.almacenequipo}
                                  </td>
                                </tr>
                              ))
                          }
                          {
                            <tr onClick={() => this.seleccionarOperacion({ item: this.props.itemrancho , campo: 'sinalmacen' })}
                              className={
                                (this.state.opcionalamacen == 'sinalmacen')
                                  ? "trFondoActivoGreen" : ""}>
                              <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                Sin almacén
                              </td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    disabled={this.state.itemseleccionado ? false : true}
                    onClick={() => this.showModal({ accion: 1, item: this.state.itemseleccionado })}>
                    Continuar
                  </Button>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal >
      </>
    )
  }
}
