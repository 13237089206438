import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoVentas.css';
import { BiSearchAlt2 } from "react-icons/bi";
import { FaCalendar } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsListUl } from "react-icons/bs";
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioDetallesVenta from '../../Complements/Modales/ModalesFormularioVentas/ModalFormularioDetallesVenta';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getCatalogoVentas } from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillCalendarFill } from "react-icons/bs";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export default class CatalogoVentas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,
            errordeacceso: false,

            txtfechaventaini: "",
            txtfechaventafin: "",
            fechaventaini: new Date(),
            fechaventafin: new Date(),

            tabla: {
                encabezados: [
                    "Cliente",
                    "Venta",
                    "Fecha",
                    "Total",
                    "Adeudo",
                    "Estatus",
                    "Detalles"
                ],
                dimensiones: [
                    "20%",
                    "15%",
                    "12%",
                    "10%",
                    "10%",
                    "15%",
                    "10%",
                ],
                mindimensiones: [
                    "140px",
                    "160px",
                    "100px",
                    "120px",
                    "120px",
                    "120px",
                    "120px",
                    "100px"
                ],
                elementos: []
            },
            modaldetallesventa:false,
            accionmodal: 1,
            titulomodal: '',
            itemseleccionado: null,

            //paginacion
            activepage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar

        }
    }

    async componentDidMount() {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }

        var tabla = this.state.tabla;
        tabla.elementos = [];

        var fechaactualFin = new Date();
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);
        const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

        var fechaactualIni = new Date();
        fechaactualIni.setDate(fechaactualIni.getDate() - 7);
        const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

        await this.setState({
            txtbuscador: '',
            txtfechaventaini: defaultValueFechaInicial,
            txtfechaventafin: defaultValue,
            fechaventaini: fechaactualIni,
            tabla: tabla
        })

        setTimeout(() => {
            this.consultarCatalogoVenta();
            this.dimensiones();
            window.addEventListener("resize", this.dimensiones);
            this.setState({ loadingspinners: false })
        }, 300);
    }

    consultarCatalogoVenta = async (llave, valor) => {
        var formatofechaini = "";
        var formatofechafin = "";
        var fechainiaux = 'txtfechaventaini' == llave ? valor : this.state.txtfechaventaini
        var fechafinaux = 'txtfechaventafin' == llave ? valor : this.state.txtfechaventafin

        if (fechainiaux.length > 0) {
            var [year, month, day] = fechainiaux.split("-");
            formatofechaini = `${day}/${month}/${year}`
        }

        if (fechafinaux.length > 0) {
            var [year, month, day] = fechafinaux.split("-");
            formatofechafin = `${day}/${month}/${year}`
        }

        var requestbody = {
            fechainicio: formatofechaini,
            fechafin: formatofechafin,
        }

        var fechasvalidas = await this.validarFechasDeVenta(formatofechaini, formatofechafin)
        if (fechasvalidas == 1) {
            this.setState({ loadingspinners: true })
            var catalogoventas = await getCatalogoVentas(requestbody);

            if (this.manejadorLlamadasApis(catalogoventas)) {
                this.setState({ loadingspinners: false })
                return;
            }

            var tabla = this.state.tabla;
            tabla.elementos = catalogoventas.data;

            await this.setState({
                txtbuscador: '',
                tabla: tabla
            })
            this.cambiarContenido(1);
            this.setState({ loadingspinners: false })
        }
    }

    validarFechasDeVenta = async (formatofechaini, formatofechafin) => {
        var fechainicio = new Date();
        var fechafin = new Date();
        var fechainiciovalido = false;
        var fechafinvalido = false;
        if (formatofechaini.length > 0) {
            fechainiciovalido = true;
            var fechaventaini = formatofechaini.split("/");
            fechainicio.setFullYear(fechaventaini[2], fechaventaini[1], fechaventaini[0]);
        }

        if (formatofechafin.length > 0) {
            fechafinvalido = true;
            var fechaventafin = formatofechafin.split("/");
            fechafin.setFullYear(fechaventafin[2], fechaventafin[1], fechaventafin[0]);
        }

        if (fechainiciovalido && fechafinvalido) {
            if (fechainicio <= fechafin) {
                return 1;
            }
            else {
                toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                var tabla = this.state.tabla;
                tabla.elementos = [];

                await this.setState({
                    txtbuscador: '',
                    tabla: tabla
                })
                this.cambairContenido(1);
                return 2;
            }
        }
        return 0;
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({ loadingspinners: true, errordeacceso: false })
        this.componentDidMount();
    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });

        if (e.target.value.trim().length > 2) {
            var data1 = JSON.parse(JSON.stringify(this.state.tabla))
            var data = data1.elementos;
            const filtros = data.filter((item) => item.cliente.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || item.cliente.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.filtro(1, filtros)
        }
        else {
            this.setState({ filtrolistapaginada: [] });
        }
    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 5) {
            this.setState({ titulomodal: 'Detalles de venta', accionmodal: 5, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
            this.setState({ modaldetallesventa: true });
        }
    }


    filtro = (pagenumber, array) => {

        this.setState({
            activepage: pagenumber,
            filtrolistapaginada: []
        });

        setTimeout(() => {
            this.setState({
                elementosfiltrados: array,
                activepage: pagenumber,
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
            });
        }, 0);

    }

    cambiarContenido = (pagenumber) => {
        this.setState({
            activepage: pagenumber,
            filtrolistapaginada: [],
            listapaginada: []
        });

        setTimeout(() => {
        this.setState({
            activepage: pagenumber,
            listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
            filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
        });
        }, 0);

    }

    borrarBusqueda = () => {
        this.setState({ txtbuscador: '' })
        this.cambiarContenido(1);
        this.setState({ filtrolistapaginada: [] });
    }

    activarSpinner = (accion) => {
        this.setState({ loadingspinners: accion })
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

        this.consultarCatalogoVenta(['txt' + item.llave], year + '-' + month + '-' + day)
    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 1) {
            this.setState({ titulomodal: 'Detalles de venta', accionmodal: 5, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
            this.setState({ modaldetallesventa: true });
        }
    }

    cerrarModalDetalles = (accion) => {
        if (accion == 5) {
            this.setState({ modaldetallesventa: !this.state.modaldetallesventa })
            this.consultarCatalogoVenta();
        } else {
            this.setState({ modalmoveraalmacen: !this.state.modalmoveraalmacen })
            if (accion == 1 || accion == 0) {
                this.consultarCatalogoVenta();
            }
        }
    }


    render() {
        return (
            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <Acciones bandera={'salir'} />
                    </>
                :null}

                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                {(this.state.modaldetallesventa == true) ?
                    <ModalFormularioDetallesVenta
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalDetalles}
                        show={this.state.modaldetallesventa}
                        accion={this.state.accionmodal}
                        item={this.state.itemseleccionado}
                        listaalmacenes={this.state.listaalmacenes}
                        activarSpinner={this.activarSpinner}
                        listacompras={this.state.tabla.elementos}
                    />
                :null}

                <Navbar />
                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximo">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Ventas</label>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                                    <div className='subcontenedorIconoCalendarioCompras'>
                                        <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                                    </div>
                                    <div className='row'>
                                        <div className=' col-sm-4 col-md-4'>
                                            <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha venta de:</label>
                                        </div>
                                        <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <DatePicker
                                                selected={this.state.fechaventaini}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechaventaini", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                                />
                                            </div>
                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                                            </div>

                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                <DatePicker
                                                selected={this.state.fechaventafin}
                                                onChange={(date) => this.cambiarFecha({ llave: "fechaventafin", valor: date })}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                customInput={
                                                    <DateButton />
                                                }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='txtBuscadorAncho'>
                                    <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                    <input
                                        id='Search'
                                        type="search"
                                        onChange={this.saveInputLocacion}
                                        value={this.state.txtbuscador}
                                        className="txtBuscador letraNormal"
                                        style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                        placeholder="Buscar cliente">
                                    </input>
                                    {this.state.txtbuscador ?
                                        <IoMdClose
                                            className='icoInputBuscarClose'
                                            style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                            onClick={this.borrarBusqueda}/>
                                    :null}
                                </div>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                <div className="container-fluid containerTabla">
                    <div className="container-fluid anchoMaximo ">
                        <div className="containerSubTabla">
                            <Table>
                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index > 3 ? 'center' : '' }}>
                                            {titulo}
                                        </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>

                                    {this.state.txtbuscador.length < 3 ?

                                        this.state.listapaginada.map((item, index) => (

                                            <tr key={"tabla" + index}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                    {item.cliente}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                    {item.venta}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                    {item.fecha}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                                                    {this.format_moneda(item.total)}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                                                    {this.format_moneda(item.adeudo)}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'center', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                                                    <label>
                                                        {item.estatus != undefined ?
                                                            item.estatus
                                                        :
                                                            "Desconocido"}
                                                    </label>
                                                </td>

                                                <td className="letraNormal" style={{ verticalAlign: "middle", minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7]}} >
                                                    <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "1", contenido: item })}
                                                        style={{
                                                        backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                        }}><BsListUl className="icoBotones" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    :
                                        this.state.filtrolistapaginada.map((item, index) => (

                                            <tr key={"tabla" + index}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                    {item.cliente}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                    {item.venta}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                    {item.fecha}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }} >
                                                    {item.total}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                                                    {item.adeudo}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ textAlign: 'center', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                                                    <label>
                                                        {item.estatus != undefined ?
                                                            item.estatus
                                                        :
                                                            "Desconocido"}
                                                    </label>
                                                </td>

                                                <td className="letraNormal" style={{ verticalAlign: "middle", minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7]}} >
                                                    <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "1", contenido: item })}
                                                        style={{
                                                        backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar,
                                                        border: this.state.btnbordereditar
                                                        }}><BsListUl className="icoBotones" />
                                                    </button>
                                                </td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

                <div className="panelPaginationSection">
                    <Paginacion
                        activepage={this.state.activepage}
                        itemscountperpage={this.state.itemsperpage}
                        totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
                        pagerangedisplayed={this.state.rangepages}
                        cambiarcontenido={(e) => this.cambiarContenido(e)}
                    />
                </div>
            </>
        )
    }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
    >
        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));

function Acciones({ bandera }) {
    const history = useHistory();
    const location = useLocation();
    history.push(location.pathname);

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }
}