import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';

import { estiloComboBox, estiloTabla, estilosModal, estiloColorError } from '../../../Services/PaletaDeColores';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';
import { validarNumerosDecimales3 } from '../../../Services/Validaciones';
import { getAlmacenesEquiposInformaciónLista } from '../../../Services/Api';



export default class ModalMoverAgroquimicosCompra extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            accion: props.accion,
            hubocambio: false,

            tabla: {
                encabezados: props.encabezados,
                dimensiones: props.dimensiones,
                mindimensiones: props.mindimensiones,
            },

            listaalmacenes: [],
            almacenesfiltrados: [],
            listaproductosguardar: [],
            equiposderancho: [],

            masdeunprincipal: false,
            idalmacenseleccionado: 0,
            cargafirstime: true,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colormsgerror: estiloColorError().msgerror,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            colorencabezadostabla: estiloTabla().colorencabezadostabla,


            temporalmarca: "",
            temporalmodelo: "",
        }
    }

    async componentDidMount() {
        this.llenaralmacenes();
        this.iniciarProductos();
    }

    llenaralmacenes = () => {
        if (this.props.listaalmacenes.length > 0) {
            //revisar si son agroquimicos o generales


            var aux = this.props.listaalmacenes//.filter((item) => item.idtipoalmacen == 1 && item.idestatus == 1)
            if (aux.length > 1) {
                this.setState({ masdeunprincipal: true, almacenesfiltrados: aux })
            } else if (aux.length == 1) {
                var almacenaux = this.props.listaalmacenes[0];
                this.setState({ idalmacenseleccionado: almacenaux.idalmacen, almacenesfiltrados: aux, masdeunprincipal: false })
            }

        }
    }

    showModal = (accion) => {
        var bandera = false;

        if (accion == "10") {

            if (this.state.idalmacenseleccionado == 0 || this.state.idalmacenseleccionado == '') {
                toast.info("Selecciona un almacén", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return
            }

            var aux = this.state.listaproductosguardar;
            var lista = [];

            aux.map((prod) => {


                if (parseFloat(prod.cantidad) > 0) {

                    //agregamos dos decimales si no lo traen
                    var newCantidad = "" + prod.cantidad;
                    var auxCantidad = newCantidad.split('.');
                    if (auxCantidad.length > 1) {
                        newCantidad = auxCantidad[0].slice(0, 7) + "." + auxCantidad[1].slice(0, 2)
                    } else {
                        newCantidad = auxCantidad[0].slice(0, 7) + ".00"
                    }
                    if (newCantidad > 0 && this.props.tipoalmacendestino == 2 && prod.idequipo == 0) {
                        toast.info("Selecciona equipo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                        bandera = true;
                    }

                    

                    if (newCantidad > 0 && this.props.tipoalmacendestino == 6 && !prod.idequipo) {
                        toast.info("Selecciona equipo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                        bandera = true;
                    }
               


                    let jsonProd1 = null;

                    // caso guardar agroquímicos
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) == 1) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                        }
                    ) : (jsonProd1 = jsonProd1)

                    // caso guardar en almacén de productos generales (1)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 1) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                        }
                    ) : (jsonProd1 = jsonProd1)



                    // caso guardar en almacén de equipos (2)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 2) ? ( // cuando el destino es un almacén de equipos
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                            "idequipo": prod.idequipo,
                            "equipo": prod.equipo
                        }
                    ) : (jsonProd1 = jsonProd1)

                    // caso guardar en áreas (3)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 3) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                            "activo": prod.activo
                        }
                    ) : (jsonProd1 = jsonProd1)


                    //caso guardar en maquinaria y vehículos (4)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 4) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                        }
                    ) : (jsonProd1 = jsonProd1)





                    // caso guardar en rancho (5)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 5) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                            "activo": prod.activo
                        }
                    ) : (jsonProd1 = jsonProd1)


                    // caso guardar en equipos (6)
                    jsonProd1 = (parseInt(this.props.sonagroquimicos) != 1 && this.props.tipoalmacendestino == 6) ? (
                        {
                            "idproducto": prod.idproducto,
                            "producto": prod.producto,
                            "unidadmedida": prod.unidadmedida,
                            "cantidad": newCantidad,
                            "idequipo": prod.idequipo,
                            "equipo": prod.equipo
                        }
                    ) : (jsonProd1 = jsonProd1)


                    /*
                                        let jsonProd = (parseInt(this.props.sonagroquimicos) != 1) ? ( // casos de productos generales
                                            (this.props.tipoalmacendestino == 2) ? ( // cuando el destino es un almacén de equipos
                                                {
                                                    "idproducto": prod.idproducto,
                                                    "producto": prod.producto,
                                                    "unidadmedida": prod.unidadmedida,
                                                    "cantidad": newCantidad,
                                                    "idequipo": prod.idequipo,
                                                    "equipo": prod.equipo
                                                }
                                            ) : (
                    
                                                (this.props.tipoalmacendestino == 3 || this.props.tipoalmacendestino == 5) ? ( // cuando el destino es un almacén de área o rancho
                                                    {
                                                        "idproducto": prod.idproducto,
                                                        "producto": prod.producto,
                                                        "unidadmedida": prod.unidadmedida,
                                                        "cantidad": newCantidad,
                                                        "activo": prod.activo
                                                    }
                                                ) : ( // cuando el destino es de maquinaria o de productos generales 
                                                    {
                                                        "idproducto": prod.idproducto,
                                                        "producto": prod.producto,
                                                        "unidadmedida": prod.unidadmedida,
                                                        "cantidad": newCantidad,
                                                    }
                                                )
                    
                                            )
                                        ) : ( // cuando el destino es agroquímico
                                            {
                                                "idproducto": prod.idproducto,
                                                "producto": prod.producto,
                                                "unidadmedida": prod.unidadmedida,
                                                "cantidad": newCantidad,
                                            }
                                        )
                    
                    */
                    lista.push(jsonProd1);
                }
            })
            let auxCoti = [];
            auxCoti.productos = {
                idalmacen: this.state.idalmacenseleccionado,
                nombrealmacen:
                    this.state.almacenesfiltrados.find(x => x.idalmacen == this.state.idalmacenseleccionado).nombrealmacen,
                idcompra: this.props.idcompra,
                idmovimiento: 7,
                productos: lista
            };

            //console.log("guardar info", auxCoti.productos)

            if (bandera == false) {
                if (lista.length > 0) {
                    setTimeout(() => {
                        this.props.verAprobarMovimiento({ sonagroquimicos: this.props.sonagroquimicos, objeto: auxCoti.productos })
                    }, 200);
                }
                else {
                    toast.info("Seleccione al menos un agroquímico", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }


        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarmodal({ origen: "modalmoveragroquimicos", respuesta: accion });
            }, 200);
        }
    }

    iniciarProductos = () => {
        var aux = this.props.productos;

        var lista = [];
        if (aux.length > 0) {
            aux.map((item, index) => (


                lista.push(
                    (this.props.sonagroquimicos != 1) ? (
                        (this.props.tipoalmacendestino == 2) ? (
                            {
                                idproducto: item.idproducto,
                                producto: item.producto,
                                unidadmedida: item.unidadmedida,
                                pendientes: item.pendientes,
                                cantidad: 0,
                                idtipoequipo: 0,
                                idequipo: 0,
                            }
                        ) : (

                            (this.props.tipoalmacendestino == 3 || this.props.tipoalmacendestino == 5) ? (
                                {
                                    idproducto: item.idproducto,
                                    producto: item.producto,
                                    unidadmedida: item.unidadmedida,
                                    pendientes: item.pendientes,
                                    cantidad: 0,
                                    activo: false

                                }
                            ) : (
                                {
                                    idproducto: item.idproducto,
                                    producto: item.producto,
                                    unidadmedida: item.unidadmedida,
                                    pendientes: item.pendientes,
                                    cantidad: 0,
                                }
                            )

                        )
                    ) : (
                        {
                            idproducto: item.idproducto,
                            producto: item.producto,
                            unidadmedida: item.unidadmedida,
                            pendientes: item.pendientes,
                            cantidad: 0,
                        }
                    )



                )

            ));
        }
        this.setState({ listaproductosguardar: lista, cargafirstime: false })
    }

    escribirCantidadPrecio = async (index, event) => {
        //console.log("escribircantidadprecio", index,event.target.value, this.state.listaproductosguardar[index])

        if (this.state.listaproductosguardar[index]['pendientes'] == 0
            && parseFloat(event.target.value) > this.state.listaproductosguardar[index]['pendientes']
            || this.state.listaproductosguardar[index]['pendientes'] > 0
            && parseFloat(event.target.value) > this.state.listaproductosguardar[index]['pendientes']) {
            toast.error("La cantidad supera los productos disponibles: " + this.state.listaproductosguardar[index]['pendientes'], { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        else {
            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 2 };
            let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
            var updateValor = event.target.value;

            if (tipocorrecto.codigo == 'invalid') {
                var auxUpdateValor = updateValor.split('.');
                if (auxUpdateValor.length > 1) {
                    updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 2)
                } else {
                    updateValor = auxUpdateValor[0].slice(0, 7)
                }
                if (event.target.value != 0 && event.target.value != "") {
                    toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                }

            }
            this.state.listaproductosguardar[index]["cantidad"] = updateValor;
            var dummy = this.state.listaproductosguardar
            this.setState({ listaproductosguardar: dummy, hubocambio: true });
        }
    };





    seleccionartipoequipo(objeto) {

        let nuevalista = [];
        this.state.listaproductosguardar.forEach(item => {
            nuevalista.push({
                idproducto: item.idproducto,
                producto: item.producto,
                unidadmedida: item.unidadmedida,
                pendientes: item.pendientes,
                cantidad: item.cantidad,
                idtipoequipo: (objeto.idprod == item.idproducto) ? (objeto.idtipo) : (item.idtipoequipo),
                idequipo: (objeto.idprod == item.idproducto) ? (0) : (item.idequipo),
                equipo: (objeto.idprod == item.idproducto) ? ("") : (item.equipo),
            });
        });
        //console.log("guardartipoequipo", nuevalista)

        this.setState({ listaproductosguardar: nuevalista })
    }


    seleccionarequipo(objeto) {
        let filtro = this.props.listaequipos.filter((item) => item.idequipo == objeto.id)

        let nuevalista = [];
        this.state.listaproductosguardar.forEach(item => {
            nuevalista.push({
                idproducto: item.idproducto,
                producto: item.producto,
                unidadmedida: item.unidadmedida,
                pendientes: item.pendientes,
                cantidad: item.cantidad,
                idtipoequipo: item.idtipoequipo,
                idequipo: (objeto.idprod == item.idproducto) ? (objeto.id) : (item.idequipo),
                equipo: (objeto.idprod == item.idproducto) ? (filtro[0].nombreclave) : (item.equipo),
            });
        });

        //console.log("guardarequipo", nuevalista)
        this.setState({ listaproductosguardar: nuevalista })

    }




    cambiarActivo = (item) => {
        var lista = this.state.listaproductosguardar;
        var nuevo = [];
        lista.forEach(elemento => {

            if (item.idproducto == elemento.idproducto) {
                nuevo.push({
                    idproducto: elemento.idproducto,
                    producto: elemento.producto,
                    unidadmedida: elemento.unidadmedida,
                    pendientes: elemento.pendientes,
                    cantidad: elemento.cantidad,
                    activo: !elemento.activo
                })
            }
            else {
                nuevo.push(elemento)
            }

        });

        this.setState({ listaproductosguardar: nuevo })

    }


    async cambiaralmacenparaequipos(seleccionado) {
        //console.log("cambiar almacen para equipos", seleccionado, this.props)
        this.setState({ idalmacenseleccionado: seleccionado.idalmacenseleccionado })

        //buscar los equios de ese rancho
        if (parseInt(this.props.tipoalmacendestino) == 6) {
            this.props.activarSpinner(true);
            let equipos = await getAlmacenesEquiposInformaciónLista(seleccionado.idalmacenseleccionado)
            if (this.manejadorLlamadasApis(equipos)) {
                return;
            }
            this.props.activarSpinner(false);
            this.setState({ equiposderancho: equipos.data.productos })            
            this.actualizarListaDeEquiposAGuardar();
        }
    }


    actualizarListaDeEquiposAGuardar(){
        let lista=this.state.listaproductosguardar
        lista.map((item)=>{
            if(item.idequipo){
                item.idequipo=0
                item.marca=""
                item.modelo=""  
            }
        })

        this.setState({listaproductosguardar:lista})
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        }
        else if (resp.code == 200) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 300) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            this.setState({
                errordeacceso: true
            })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.props.activarSpinner(false);
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        return false
    }

    equipoactivo(objeto) {

        let nuevalista = [];
        this.state.listaproductosguardar.forEach(item => {
            let equipo = JSON.parse(objeto.infoequipo)

            //console.log("objetos", JSON.parse(objeto.infoequipo), item)
            nuevalista.push({
                idproducto: item.idproducto,
                producto: item.producto,
                unidadmedida: item.unidadmedida,
                pendientes: item.pendientes,
                cantidad: item.cantidad,

                idequipo: (objeto.idprod == item.idproducto) ? (equipo.idequipo) : (item.idequipo),
                equipo: (objeto.idprod == item.idproducto) ? (equipo.nombreclave) : (item.equipo),
                marca: (objeto.idprod == item.idproducto) ? (equipo.marca != "" ? equipo.marca : "Sin marca") : (item.marca),
                modelo: (objeto.idprod == item.idproducto) ? (equipo.modelo != "" ? equipo.modelo : "Sin modelo") : (item.modelo),
            });
        });

        //console.log("guardarequipo", nuevalista)
        this.setState({ listaproductosguardar: nuevalista })

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.props.titulo}</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>

                                            {
                                                this.state.masdeunprincipal == false ? (
                                                    <div className='col-auto containerSubTabla '>
                                                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Almacén: </label>
                                                        <label className='lblTexto letraTitulos'>
                                                            {this.state.almacenesfiltrados.map((i, index) => (i.nombrealmacen))}
                                                        </label>
                                                    </div>
                                                ) :
                                                    <>
                                                        <div className='col-auto containerSubTabla '>
                                                            <div className='contenedorFechaCompras' style={{ width: "400px", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, width: "260px" }}>Almacén destino</label>
                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                        onChange={(event) => this.cambiaralmacenparaequipos({ idalmacenseleccionado: event.target.value })}>
                                                                        <option disabled="disabled" selected={this.state.idalmacenseleccionado == 0}>Seleccionar</option>
                                                                        {this.state.almacenesfiltrados.map((i, index) => (
                                                                            <option value={i.idalmacen} key={"tipo_" + index} selected={i.idalmacen == this.state.idalmacenseleccionado} >{i.nombrealmacen}  </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                            }

                                        </div>
                                        <div className='row paddingEncabezados'>
                                            <div className='col-auto containerSubTabla '>
                                                <label className='lblTexto letraTitulos'>{this.props.instruccion} </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu">
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {
                                                            this.state.tabla.encabezados.map((titulo, index) => (
                                                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        (this.state.listaproductosguardar.length > 0) ? (
                                                            this.state.listaproductosguardar.map((item, index) => (

                                                                parseFloat(item.pendientes) > 0 ? (
                                                                    <tr key={"tabla_" + index}
                                                                        className={(this.props.tipoalmacendestino == 2) ? (item.cantidad > 0 && item.idequipo != 0 ? "trFondoActivoGreen" : "trFondoIncompleto") : (item.cantidad <= 0 ? "trFondoIncompleto" : "trFondoActivoGreen")}>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.producto}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.unidadmedida}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {JSON.stringify(item.pendientes)}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                                                                            <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla" value={item.cantidad}
                                                                                onChange={(e) => this.escribirCantidadPrecio(index, e)}></input>
                                                                        </td>
                                                                        { // caso almacén de equipos
                                                                            (this.props.tipoalmacendestino == 2) ? (
                                                                                <>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                                                                                        <div className='contenedorFechaCompras' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                                                            <div className="selectBox ">
                                                                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                                                    onChange={(event) => this.seleccionartipoequipo({ idtipo: event.target.value, idprod: item.idproducto })}>
                                                                                                    <option disabled="disabled" selected={item.idtipoequipo == 0}>Selecciona tipo </option>
                                                                                                    {this.props.listatipoequipos.map((i, index) => (
                                                                                                        <option value={i.idtipoequipo} key={"tipo_" + index} selected={i.istipoequipo == item.idtipoequipo} >{i.tipo} </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                                                                                        <div className='contenedorFechaCompras' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                                                            <div className="selectBox ">
                                                                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                                                    onChange={(event) => this.seleccionarequipo({ id: event.target.value, idprod: item.idproducto })}>
                                                                                                    <option disabled="disabled" selected={item.idequipo == 0}>{(item.idtipoequipo == 0) ? ("Selecciona tipo ") : ("Selecciona equipo ")}</option>
                                                                                                    {(item.idtipoequipo != 0) ? (
                                                                                                        this.props.listaequipos.map((i, index) => (
                                                                                                            (item.idtipoequipo == i.idtipoequipo) ? (
                                                                                                                <option value={i.idequipo} key={"tipo_" + index} selected={i.idequipo == item.idequipo} >{i.nombreclave}</option>
                                                                                                            ) : (null)
                                                                                                        ))
                                                                                                    ) : (null)
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                </>
                                                                            ) : (null)


                                                                        }
                                                                        {
                                                                            (this.props.tipoalmacendestino == 3 || this.props.tipoalmacendestino == 5) ? (
                                                                                <>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                        <div className='lblTexto letraTitulos' >
                                                                                            <input type="checkbox" style={{ width: "20px", height: "20px" }} checked={item.activo}
                                                                                                onClick={() => this.cambiarActivo(item)} />
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            ) : (null)


                                                                        }

                                                                        { //caso agregar directo a equipos
                                                                            (this.props.tipoalmacendestino == 6) ? (
                                                                                <>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>

                                                                                        <div className='contenedorFechaCompras' style={{ width: "100%", color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                                                            <div className="selectBox ">
                                                                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                                                                    onChange={(event) => this.equipoactivo({ infoequipo: event.target.value, idprod: item.idproducto })}>
                                                                                                    <option disabled="disabled" selected={ !item.idequipo || item.idequipo == 0}>Selecciona equipo </option>
                                                                                                    {this.state.equiposderancho.map((i, index) => (
                                                                                                        <option value={JSON.stringify(i)} key={"tipo_" + index} selected={i.idequipo == item.idequipo} >{i.nombreclave} {item.idequipo} </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                        {item.marca}
                                                                                    </td>
                                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                        {item.modelo}
                                                                                    </td>

                                                                                </>
                                                                            ) : (null)


                                                                        }
                                                                    </tr>
                                                                ) : null
                                                            ))

                                                        ) : null
                                                    }
                                                </tbody>





                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <>
                            <br />
                            <div className="divBtnContenedor" >
                                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.showModal('10')} disabled={!this.state.hubocambio} >Guardar</Button>
                            </div>
                        </>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}