import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalRelacionNivelesAutorizacion.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnNuevo } from '../../../Services/PaletaDeColores';
import ReactTooltip from "react-tooltip";
import { guardarNivelesAutorizacion} from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import ModalFormularioNuevaRelacion from './ModalFormularioNuevaRelacion';
import ModalAutorizacion from './ModalAutorizacion';

export default class ModalRelacionNivelesAutorizacion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            modaldetallesau: false,
            modaldetalles: false,

            txtbuscador: "",
            txtidarea: 0,
            txtpassword: "",

            tabla: {
                encabezados: [
                    "Puesto",
                    "Descripcion",
                    "",
                ],
                dimensiones: [
                    "10%",
                    "10%",
                    "5%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "50px",
                ],
                elementos: [],
            },

            listapuestostrabajo: this.props.listapuestostrabajo,
            listaareastrabajo: this.props.listaareastrabajo,
            nivelseleccionado: this.props.item,
            /*nivelseleccionado: {
                "idnivel": 1,
                "nivel": "Director",
                "descripcion": "",
                "puestos": [
                    {
                        "idpuesto": 4,
                        "puesto": "Director General4",
                        "descripcion": "Dummy4",
                        "idarea": 4,
                        "area": "Area 4"
                    },
                    {
                        "idpuesto": 2,
                        "puesto": "Director General2",
                        "descripcion": "Dummy2",
                        "idarea": 2,
                        "area": "Area 2"
                    },
                    {
                        "idpuesto": 3,
                        "puesto": "Director General3",
                        "descripcion": "Dummy3",
                        "idarea": 3,
                        "area": "Area 3"
                    }
                ]
            },*/


            nivelseleccionadoFiltrado: [],

            listapuestosguardar: [],

            idestatus: 1,
            msgerror: '',

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs
        }
    }

    async componentDidMount() {
        var aux = []
        this.state.nivelseleccionado.puestos.forEach((puesto)=>{
            let p = {
                idpuesto: puesto.idpuesto,
                descripcion: puesto.descripcion
            }
            aux.push(p);
        })
        var data1 = JSON.parse(JSON.stringify(this.state.nivelseleccionado.puestos))
        await this.setState({ listapuestosguardar: aux, nivelseleccionadoFiltrado: data1 })
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    verModalDetalles = (ban) => {
        if(ban.opcion==3){
            this.setState({ titulomodal: 'Autorizar acción', accionmodal: 3, modaldetallesau: true })
        }else{
            this.setState({ titulomodal: 'Crear relación', accionmodal: 2, modaldetalles: true })
        }
    }

    cerrarModal = async (ban) => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (ban != 0 && ban != undefined) {
            
            var puestoencontrado = this.state.listapuestosguardar.filter((item) => item.idpuesto==ban.idpuesto);

            if(puestoencontrado.length<1){
                var aux = this.state.listapuestosguardar
                let pguardar = {
                    idpuesto: ban.idpuesto,
                    descripcion: ban.descripcion,
                }
                aux.push(pguardar)
                await this.setState({ listapuestosguardar: aux })

                var areatrabajo = this.state.listaareastrabajo.filter((item) => item.idcatalogo_areas==ban.idarea);
                var puestotrabajo = this.state.listapuestostrabajo.filter((item) => item.idcatalogo_puestos==ban.idpuesto);

                let p = {
                    idpuesto: ban.idpuesto,
                    idarea: ban.idarea,
                    descripcion: ban.descripcion,
                    area: areatrabajo[0].area,
                    puesto: puestotrabajo[0].puesto
                }

                this.state.nivelseleccionado.puestos.push(p)
                var aux2 = this.state.nivelseleccionado
                await this.setState({nivelseleccionado:aux2})

                this.actualizarTabla()
            }
        }
    }

    filtrarLista = async (e) => {
        var data1 = JSON.parse(JSON.stringify(this.state.nivelseleccionado))
        var data = data1;

        var filtropuesto = {};
    
        if(e.target.id=='Search'){
            this.setState({ txtbuscador: e.target.value });

            if(this.state.txtidarea!=0){
                data.puestos = data.puestos.filter((item) => item.idarea==this.state.txtidarea)
            }
            data.puestos = data.puestos.filter((item) => item.puesto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            filtropuesto = data;

        }else if(e.target.id=='filtroarea'){
            this.setState({ txtidarea: e.target.value });

            if(e.target.value!=0){
                data.puestos = data.puestos.filter((item) => item.idarea==e.target.value)
            }

            data.puestos = data.puestos.filter((item) => item.puesto.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))

            filtropuesto = data;

        }else if(e.target.id=='CSearch'){
            this.setState({ txtbuscador: '' })

            if(this.state.txtidarea!=0){
                data.puestos = data.puestos.filter((item) => item.idarea==this.state.txtidarea)
            }

            filtropuesto = data;
        }

        if (filtropuesto.puestos.length < 1) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        this.setState({ nivelseleccionadoFiltrado: filtropuesto.puestos })

    }

    eliminarPuesto = async (puesto, index) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listapuestosguardar.filter((item) => !(item.idpuesto==puesto.idpuesto));
        await this.setState({listapuestosguardar:aux})

        //se elimina de la interfaz el puesto
        this.state.nivelseleccionado.puestos = this.state.nivelseleccionado.puestos.filter((item) => !((item.idpuesto==puesto.idpuesto)));
        var aux2 = this.state.nivelseleccionado
        await this.setState({nivelseleccionado:aux2})

        this.actualizarTabla()
    }

    actualizarTabla = async () => {
        this.setState({ txtidarea: 0,  txtbuscador: "" })
        this.componentDidMount();
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    guardarCambios = async (ban) => {
        this.setState({ modaldetallesau: !this.state.modaldetallesau })
        if (ban != 0 && ban != undefined) {

            this.setState({txtpassword: ban})

            let validacionescorrectas = true

            /*if (this.state.listapuestosguardar.length < 1) {
                validacionescorrectas = false;
                toast.error("Lista de puestos vacia", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }*/
            
            if (validacionescorrectas) {
                this.setLoadingSpinner(true);
                let json = {
                    "idnivelautorizacion": this.state.nivelseleccionado.idnivel,
                    "password": ban,
                    "puestos": this.state.listapuestosguardar
                }

                var resp = await guardarNivelesAutorizacion(json);
                if(resp.code == 0){
                    if (this.state.accion == 1) {
                        this.showModal('1')
                    }
                    else if (this.state.accion == 2) {
                        this.showModal('2')
                    }
                }
                else if(resp.code == 200){
                    this.setLoadingSpinner(false);
                    this.setState({ confirmarenvio: !this.state.confirmarenvio })
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 300){
                    this.setLoadingSpinner(false);
                    this.setState({ confirmarenvio: !this.state.confirmarenvio })
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 400){
                    this.setLoadingSpinner(false);
                    this.setState({ confirmarenvio: !this.state.confirmarenvio })
                    toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if (resp.code == 1000) {
                    this.setLoadingSpinner(false);
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    return;
                }else{
                    this.setLoadingSpinner(false);
                    this.setState({ confirmarenvio: !this.state.confirmarenvio })
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modaldetalles == true) ? (
                    <ModalFormularioNuevaRelacion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal}
                        show={this.state.modaldetalles} accion={this.state.accionmodal} setLoadingSpinner={this.setLoadingSpinner} 
                        nivelseleccionado={this.state.nivelseleccionado.nivel}
                        listaareas={this.state.listaareastrabajo}
                        listapuestos={this.state.listapuestostrabajo}/>
                ) : null}

                {(this.state.modaldetallesau == true) ? (
                    <ModalAutorizacion titulomodal={this.state.titulomodal} cerrarModal={this.guardarCambios}
                        show={this.state.modaldetallesau} accion={this.state.accionmodal}/>
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{"Relación nivel "+this.state.nivelseleccionado.nivel}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid containerTabla" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid anchoMaximo">
                                        <div className='row paddingEncabezados'>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Área</label>
                                                    <div className="selectBox ">
                                                        <select id='filtroarea' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                        onChange={this.filtrarLista}>
                                                            <option selected="true" value='0'>Todas</option>
                                                            {this.state.listaareastrabajo!=undefined ? this.state.listaareastrabajo.map((i, index) => (
                                                                <option value={i.idcatalogo_areas} key={"tipo_" + index}  >{i.area} </option>
                                                            )) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='txtBuscadorCN'>
                                                    <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                                    <input id='Search' type="search" value={this.state.txtbuscador} onChange={this.filtrarLista}
                                                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                        placeholder="Buscar puesto"></input>
                                                    {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                       onClick={this.filtrarLista} />) : null}
                                                </div>
                                            </div>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnNuevo letraNormal'
                                                style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                                onClick={this.verModalDetalles.bind(this, { opcion: "2" })}>+ Agregar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu">
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.nivelseleccionadoFiltrado.length>0 ? this.state.nivelseleccionadoFiltrado.map((puesto, index) => (
                                                            <tr key={"tabla" + index}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {puesto.puesto}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {puesto.descripcion}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <div className="modalDetallesCheck" >
                                                                        <button className='btnEliminarFila'
                                                                        ><FaTrashAlt className="icoBotones" onClick={() => this.eliminarPuesto(puesto, index)}/></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                                ) : null}

                            </div>
                        </div>

                        {this.state.accion == 2? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={this.verModalDetalles.bind(this, { opcion: "3" })} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}