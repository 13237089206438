import React from 'react'
import './EditarRequisicion.css';
import './RequisicionGeneral.css';
import { estiloBtnActualizar, estiloBtnEditar, estiloTabla } from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalSeleccionarDestino from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalSeleccionarDestino'
import ModalAreas from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalAreas'
import ModalRanchos from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalRancho'
import ModalVehiculo from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalVehiculo'
import ModalEquiposYHerramientas from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalEquiposYHerramientas';
import {
  getAlmacenesEquiposInformaciónLista, getListaActividades, getListaTipoActividades,
  getComboAreasDeTrabajo, getListaZonas, getListaRanchosDisponibles, getListaLotes, getListaMaquinarias,
  getListaAlmacenEquipos
} from '../../Services/Api';
import ModalBuscarActividadesServicios from '../../Complements/Modales/ModalFormularioRequisicionServicios/ModalBuscarActividadesServicios';
import ModalRanchoLotes from '../../Complements/Modales/ModalFormularioRequisicionGeneral/ModalRanchoLotes';
import { validarLetrasNumerosPuntoComa } from '../../Services/Validaciones';
const coloresporestatus = {
  1: 'btnDisponible', 2: 'btnEsperaAutorizacion', 3: 'btnAutorizado', 4: 'btnRechazado', 5: 'btnCancelado',
  6: 'btnDisponible', 7: 'btnDisponible', 8: 'btnCompletado', 9: 'btnCompletado'
}
const destinos = { 1: 'Área', 2: 'Rancho', 3: "Vehículo", 4: 'Almacén productos', 5: 'Almacén equipos', 6: "Equipo" }

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      opcioneditar: true,

      tabla: {
        encabezados: [
          "Actividad",
          "Tipo actividad",
          "Descripcion",
          "Destino",
          "",
          ""
        ],
        dimensiones: [
          "20%",
          "15%",
          "25%",
          "20%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "180px",
          "180px",
          "180px",
          "180px",
          "120px",
          "100px"
        ],
        elementos: []
      },

      //algo asi entregara el arreglo de la api informacion
      datosDummy: props.productos,

      actualizartablalocal: false,


      listaimcompletos: [],
      listadetenidos: [],

      respfamiliaproductos: [],
      listatipoactividades: [],
      listaactividades: [],
      todoslosproductosalmacen: true,
      listaproductosalmacen: [],
      listaequiposinventario: [],

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",

      modalselecciondestino: false,
      modalseleccionarea: false,
      modalseleccionrancho: false,
      modalseleccionalmacen: false,
      modalseleccionaequipo: false,
      modalseleccionvehiculo: false,

      listaguardados: this.props.listaguardados ? this.props.listaguardados : {},
      listadestinos: this.props.listadestinosauxiliar ? this.props.listadestinosauxiliar : {},

      destinoseleccionado: { nombre: 'Seleccionar destino', iddestino: -1, idtipo_destino: -1 },

      listaproductosgenerales: {},
      contador: this.props.listaguardados.length != undefined ? this.props.listaguardados.length : 0,
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }





    //Se asigna atributos auxiliares
    this.setAtributosAux();

    //Se validan datos incompletos para marcar fila de tabla en rojo
    this.actualizarTabla();

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setAtributosAux = async () => {
    //agregamos los atributos, detener, eliminar, editable a los elementos de las tablas
    var aux = this.state.datosDummy;
    aux.map((item) => {
      item.eliminar = false;
    })

    //a estos indices se le colocara la fila en rojo
    await this.setState({ datosDummy: aux })
  }

  actualizarTabla = async () => {
    //validaremos si los datos estan incompletos para pintar la fila en rojo
    var aux = []

    // --------------- 
    this.props.productos.map((item, index) => {
      if (item.actividad.length < 0 || item.actividad == "") {
        //guardamos el indice del elemento con datos incompletos
        aux.push(index)
      }
    })

    //a estos indices se le colocara la fila en rojo
    await this.setState({ listaimcompletos: aux })

  }

  generarListaProductosGuardar = async () => {
    //llamar función de actualización general
    this.props.actualizarContenido(this.state.datosDummy);

  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }



  //al precionar el boton agregar
  agregarElemento = async (ban) => {


    this.setState({ loadingspinners: true })


    var resplistaactividades = await getListaActividades();
    if (this.manejadorLlamadasApis(resplistaactividades)) {
      return;
    }

    var resplistatipoactividades = await getListaTipoActividades();
    if (this.manejadorLlamadasApis(resplistatipoactividades)) {
      return;
    }



    // Obtener un arreglo de los tipos presentes en arreglo1
    let tipos = resplistatipoactividades.data.map(item => item.idtipoactividad);

    // Filtrar arreglo2 basándose en los tipos obtenidos de arreglo1
    let listaactividadesactivas = resplistaactividades.data.filter(item => tipos.includes(item.idtipoactividad));


    this.setState({
      loadingspinners: false,
      listatipoactividades: resplistatipoactividades.data,
      listaactividades: listaactividadesactivas//resplistaactividades.data,
    })



    this.setState({ titulomodal: 'Buscar actividades/servicios', accionmodal: 1, modalnuevoproducto: true })
  }


  cerrarModalNuevoProducto = async (itemseleccionado) => {

    //si guardar entonces agregar a lista 
    if (itemseleccionado != 0) {
      let contador = this.state.contador
      var json = {
        idactividad: itemseleccionado.idactividad,
        cantidad: "",
        tipoactividad: itemseleccionado.tipoactividad,
        actividad: itemseleccionado.actividad,
        tipodestino: "",
        destino: "",
        descripcion: "",
        eliminar: false,
        idcatalogo_requisicion_actividad: -contador++
      }
      this.state.datosDummy.push(json)
      var dummy = this.state.datosDummy
      this.setState({ datosDummy: dummy, contador: contador++ });
      // -----------------------


      var productosaux = this.props.productos
      var listaproductosgenerales = {}
      productosaux.forEach((item) => {
        listaproductosgenerales[item.idcatalogo_requisicion_actividad] = item
      })
      listaproductosgenerales[json.idcatalogo_requisicion_actividad] = json


      this.setState({ listaproductosgenerales: listaproductosgenerales })

      this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)
      await this.actualizarTabla();

    }
    this.setState({ modalnuevoproducto: !this.state.modalnuevoproducto, loadingspinners: false })
  }


  eliminarElemento = async (elemento) => {
    var productosaux = this.props.productos
    var listaproductosgenerales = {}
    productosaux.forEach((item) => {
      if (item.idcatalogo_requisicion_actividad != elemento.item.idcatalogo_requisicion_actividad) {
        listaproductosgenerales[item.idcatalogo_requisicion_actividad] = item
      }
    })

    // ------------------------------------------------------------
    var listaguardados = this.state.listaguardados
    listaguardados[elemento.item.idcatalogo_requisicion_actividad] = false
    this.setState({ listaguardados: listaguardados })
    // ------------------------------------------------------------
    // ------------------------------------------------------------
    var listadestinos = this.state.listadestinos
    delete listadestinos[elemento.item.idcatalogo_requisicion_actividad]
    this.setState({ listadestinos: listadestinos })
    // ------------------------------------------------------------

    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)

    //validamos datos incompletos
    this.actualizarTabla();

  }

  //seleccionar el chec eliminar
  checkEliminar = async (index, check) => {
    this.state.datosDummy[index].eliminar = check;
    var dummy = this.state.datosDummy

    this.setState({ datosDummy: dummy });

    this.generarListaProductosGuardar();
  }

  verModalSeleccionarDestino = (ban) => {
    if (ban.opcion == 1) {
      this.setState({
        titulomodal: 'Seleccionar destino:', accionmodal: 1, modalselecciondestino: true,
        itemseleccionado: JSON.parse(JSON.stringify(ban.item))
      });
    }
  }

  cerrarModalSeleccionarDestino = (item) => {
    this.setState({ modalselecciondestino: !this.state.modalselecciondestino, loadingspinners: true })
    if (item.accion == 1) {

      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.idtipo_destino = item.id
      this.setState({ destinoseleccionado: destinoseleccionado })
      if (item.id == 1 /*Área*/) {
        this.verModalSeleccionarArea(item)
      } else if (item.id == 2) {
        this.verModalSeleccionarRancho(item)
      } else if (item.id == 3) {
        this.verModalSeleccionarVehiculo(item)
      }
      else if (item.id == 6) { // equipos
        this.verModalSeleccionarRancho(item)
      }
    }
  }

  verModalSeleccionarArea = async (ban) => {

    //cargar datos de área
    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    this.setState({
      loadingspinners: false,
      listaareas: resplistaareas.data,
      titulomodal: 'Seleccionar áreas', accionmodal: 1, modalseleccionarea: true,
    })
  }

  cerrarModalSeleccionarArea = (datos) => {
    this.setState({ modalseleccionarea: !this.state.modalseleccionarea })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.nombre = datos.item.area
      destinoseleccionado.iddestino = datos.item.idcatalogo_areas
      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idcatalogo_requisicion_actividad] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  verModalSeleccionarRancho = async (ban) => {

    // cargar datos
    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      return;
    }
    var resplistaranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      return;
    }

    var resplistalotes = await getListaLotes();
    if (this.manejadorLlamadasApis(resplistalotes)) {
      return;
    }

    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      return;
    }

    this.setState({
      loadingspinners: false,
      listazonas: resplistazonas.data,
      listaranchos: resplistaranchos.data,
      listalotes: resplistalotes.data,
      listaareas: resplistaareas.data,
      titulomodal: 'Seleccionar destino:', accionmodal: 1, modalseleccionrancho: true
    })
  }

  cerrarModalSeleccionarRancho = (datos) => {

    this.setState({ modalseleccionrancho: !this.state.modalseleccionrancho })
    if (datos.accion == 1) {
      if (this.state.destinoseleccionado.idtipo_destino == 2) {
        this.verModalSeleccionarLoteRancho(datos.item)
      } else {
        this.verModalSeleccionarEquipoHerramienta(datos.item)
      }

    }
  }

  verModalSeleccionarLoteRancho = (item) => {
    this.setState({
      titulomodal: 'Selección de lotes/rancho', accionmodal: 1, modalseleccionalmacen: true,
      itemrancho: item
    })
  }

  cerrarModalSeleccionarLotesRanchos = (datos) => {
    this.setState({ modalseleccionalmacen: !this.state.modalseleccionalmacen })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      if (datos.item.idcatalogo_ranchos_lotes_cultivo) {
        destinoseleccionado.idtipo_destino = 7
        destinoseleccionado.iddestino = datos.item.idcatalogo_ranchos_lotes_cultivo
        destinoseleccionado.nombre = 'Lote ' + datos.item.lote + ' (' + datos.item.rancho + ')'
      } else {
        destinoseleccionado.idtipo_destino = 2
        destinoseleccionado.iddestino = datos.item.idcatalogo_rancho
        destinoseleccionado.nombre = destinos[2] + ' ' + datos.item.rancho //destinos[2]
      }

      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idcatalogo_requisicion_actividad] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  verModalSeleccionarEquipoHerramienta = async (item) => {

    var resplistaalmacenequipos = await getListaAlmacenEquipos();
    if (this.manejadorLlamadasApis(resplistaalmacenequipos)) {
      return;
    }


    //llamar api para obtener equipos de ese rancho 
    let idcatalogoinventarioequipos = resplistaalmacenequipos.data.filter(
      (itemfilter) => itemfilter.idrancho == item.idcatalogo_rancho)

    if (idcatalogoinventarioequipos.length > 0) {
      this.setState({ loadingspinners: true })
      let equipos = await getAlmacenesEquiposInformaciónLista(idcatalogoinventarioequipos[0].idalmacenequipos)
      if (this.manejadorLlamadasApis(equipos)) {
        return;
      }
      this.setState({ loadingspinners: false })
      this.setState({
        titulomodal: 'Selección de equipo o herramienta', accionmodal: 1, modalseleccionaequipo: true,
        itemrancho: item, listaequiposinventario: equipos.data.productos

      })
    } else {
      this.setState({
        titulomodal: 'Selección de equipo o herramienta', accionmodal: 1, modalseleccionaequipo: true,
        itemrancho: item, listaequiposinventario: []

      })
    }

  }

  cerrarModalSeleccionarEquipoHerramienta = (datos) => {

    this.setState({ modalseleccionaequipo: !this.state.modalseleccionaequipo })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.idtipo_destino = 6
      destinoseleccionado.iddestino = datos.item.idequipo
      destinoseleccionado.nombre = datos.item.nombreclave


      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idcatalogo_requisicion_actividad] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })

    }
  }


  verModalSeleccionarVehiculo = async (ban) => {

    //llamar api para obtener vehículos
    var resplistavehiculos = await getListaMaquinarias()
    if (this.manejadorLlamadasApis(resplistavehiculos)) {
      return;
    }

    this.setState({
      loadingspinners: false,
      listavehiculos: resplistavehiculos.data,
      titulomodal: 'Seleccionar vehículo', accionmodal: 1, modalseleccionvehiculo: true,
    })
  }

  cerrarModalSeleccionarVehiculo = (datos) => {
    this.setState({ modalseleccionvehiculo: !this.state.modalseleccionvehiculo })
    if (datos.accion == 1) {
      var destinoseleccionado = this.state.destinoseleccionado
      destinoseleccionado.nombre = datos.item.nombreclave
      destinoseleccionado.iddestino = datos.item.idmaquinaria
      this.setState({ destinoseleccionado: destinoseleccionado })
      var listadestinos = this.state.listadestinos
      var itemseleccionado = this.state.itemseleccionado ? this.state.itemseleccionado : {}
      listadestinos[itemseleccionado.idcatalogo_requisicion_actividad] = JSON.parse(JSON.stringify(destinoseleccionado))
      this.setState({ listadestinos: listadestinos })
    }
  }

  guardarRegistro = ({ item, index }) => {
    // Si el egistro que se quiere guardar está incompleto entonces: true
    var band = this.state.listaimcompletos.indexOf(index) != -1 || !(this.state.listadestinos[item.idcatalogo_requisicion_actividad])
    if (band) {
      toast.error("Complete el registro", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return
    }

    //validar la descripción no más de 250 caracteres y solo letras números, comas, etc

    if (item.descripcion.length > 0) {
      let longitudvalidacomentario = { longitudminima: 1, longitudmaxima: 200 };
      let campocomentario = validarLetrasNumerosPuntoComa(item.descripcion.trim(), longitudvalidacomentario);
      if (campocomentario.codigo == "invalid") {
        toast.error("Descrippción:" + campocomentario.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return
      }
    }



    var listaguardados = this.state.listaguardados
    listaguardados[item.idcatalogo_requisicion_actividad] = true

    this.setState({ listaguardados: listaguardados })
    var listaproductosgenerales = this.state.listaproductosgenerales
    var producto = {
      idactividad: item.idactividad,
      actividad: item.actividad,
      idtipodestino: this.state.listadestinos[item.idcatalogo_requisicion_actividad].idtipo_destino,
      iddestino: this.state.listadestinos[item.idcatalogo_requisicion_actividad].iddestino,
      tipoactividad: item.tipoactividad,
      descripcion: item.descripcion,
      idcatalogo_requisicion_actividad: item.idcatalogo_requisicion_actividad
    }

    listaproductosgenerales[item.idcatalogo_requisicion_actividad] = producto //item


    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, listaguardados)
  }

  editarRegistro = ({ item }) => {

    var listaguardados = this.state.listaguardados
    listaguardados[item.idcatalogo_requisicion_actividad] = false
    this.setState({ listaguardados: listaguardados })

    var productosaux = this.props.productos
    var listaproductosgenerales = {}
    productosaux.forEach((item, index) => {
      listaproductosgenerales[item.idcatalogo_requisicion_actividad] = item
    })

    
    this.setState({ listaproductosgenerales: listaproductosgenerales })
    this.actualizarContenidoGeneral(listaproductosgenerales, this.state.listadestinos, this.state.listaguardados)
  }

  actualizarContenidoGeneral = (productos, listadestinos, listaguardados) => {
    this.props.actualizarContenidoGeneral(productos, listadestinos, listaguardados)
  }


  async descripcion(info) {
    
    var productosaux = this.props.productos

    productosaux[info.index].descripcion = info.texto

    var listaproductosgenerales = {}
    productosaux.forEach((item, index) => {
      listaproductosgenerales[item.idcatalogo_requisicion_actividad] = item
    })

   // this.setState({ listaproductosgenerales: productosaux })
    this.props.actualizarDescripcion(productosaux)
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {/* Listo */}
        {(this.state.modalselecciondestino == true) ? (
          <ModalSeleccionarDestino titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarDestino} estatus={this.state.estatus}
            show={this.state.modalselecciondestino} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            listadestinos={this.props.listadestinos}
            arraytipos={JSON.parse(JSON.stringify({}))} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionarea == true) ? (
          <ModalAreas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarArea} estatus={this.state.estatus}
            show={this.state.modalseleccionarea} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}))}
            listaareas={this.state.listaareas} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionrancho == true) ? (
          <ModalRanchos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarRancho} estatus={this.state.estatus}
            show={this.state.modalseleccionrancho} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}))}
            listaareas={this.state.listaareas}
            listazonas={this.state.listazonas} listaranchos={this.state.listaranchos} />
        ) : null}

        {/* Listo para seleccionr almacenes de algún rancho*/}
        {(this.state.modalseleccionalmacen == true) ? (
          <ModalRanchoLotes
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalSeleccionarLotesRanchos}
            estatus={this.state.estatus}
            show={this.state.modalseleccionalmacen}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}))}
            listalotes={this.state.listalotes}
            listadestinos={this.props.listadestinos}
            itemrancho={this.state.itemrancho} />
        ) : null}
        {/*listo, para seleccionar equipos*/}
        {(this.state.modalseleccionaequipo == true) ? (
          <ModalEquiposYHerramientas titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarEquipoHerramienta} estatus={this.state.estatus}
            show={this.state.modalseleccionaequipo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            listaareas={this.props.listaareas}
            listaalamcenproductosgenerales={this.props.listaalamcenproductosgenerales}
            listaalmacenequipos={this.state.listaalmacenequipos}
            listadestinos={this.props.listadestinos}
            listaequiposinventario={this.state.listaequiposinventario}

            itemrancho={this.state.itemrancho}
            listazonas={this.props.listazonas} listaranchos={this.props.listaranchos} />
        ) : null}

        {/* Listo */}
        {(this.state.modalseleccionvehiculo == true) ? (
          <ModalVehiculo titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalSeleccionarVehiculo} estatus={this.state.estatus}
            show={this.state.modalseleccionvehiculo} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify({}/*this.state.arraytipos*/))}
            //listaareas={this.props.listaareas}
            listavehiculos={this.state.listavehiculos} listaranchos={this.props.listaranchos} />
        ) : null}

        {/* Listo */}
        {(this.state.modalnuevoproducto == true) ? (
          <ModalBuscarActividadesServicios titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalNuevoProducto}

            show={this.state.modalnuevoproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listatipoactividades={this.state.listatipoactividades}
            listaactividades={this.state.listaactividades}
            listaproductosagregados={this.props.productos}//this.state.datosDummy}
          />
        ) : null}

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla" style={{ backgroundColor: 'white' }}>
          <div className="container-fluid anchoMaximo">
            <div className='row justify-content-center' >
              <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>

                <Table hover>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className={(index > 1) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                          style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.props.productos.sort((a, b) => a.idactividad - b.idactividad).map((item, index) => (
                      <tr key={index}
                        className={
                          item.detener ? "trFondoDetener" : ''} >
                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.actividad}
                        </td>
                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoactividad}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {!this.state.listaguardados[item.idcatalogo_requisicion_actividad] ?
                            <textarea id='Search' type="search"
                              onChange={(event) => this.descripcion({ item: item, index: index, texto: event.target.value })} value={item.descripcion}
                              style={{ color: this.state.colortxtbuscar, maxWidth: '510px' }}
                              className="txtBuscador letraNormal "
                              placeholder=""></textarea>
                            :
                            item.descripcion
                          }
                        </td>
                       <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {!this.state.listaguardados[item.idcatalogo_requisicion_actividad] ?
                            <button className={'btnEstandar btnBase ' + coloresporestatus[1]}
                              onClick={this.verModalSeleccionarDestino.bind(this, { opcion: "1", item: item, index: index })}
                              style={{ minWidth: "186px" }}>
                              {this.state.listadestinos[item.idcatalogo_requisicion_actividad] ?
                                (this.state.listadestinos[item.idcatalogo_requisicion_actividad].nombre) : 'Selecciona destino'}
                            </button> :
                            this.state.listadestinos[item.idcatalogo_requisicion_actividad] ?
                              (this.state.listadestinos[item.idcatalogo_requisicion_actividad].nombre) : ''
                          }
                        </td>


                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {
                            (!this.state.listaguardados[item.idcatalogo_requisicion_actividad]) ?
                              <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'}
                                onClick={this.eliminarElemento.bind(this, { opcion: "1", item: item, index: index })}
                                style={{ minWidth: "120px", border: 'solid 2px #bebdbc' }}>
                                Cancelar
                              </button>
                              :
                              <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'}
                                onClick={this.editarRegistro.bind(this, { opcion: "1", item: item, index: index })}
                                style={{ minWidth: "120px", backgroundColor: '#0061FE', border: 'solid 2px #0061FE', color: 'white' }}>
                                Editar
                              </button>}
                        </td>

                        <td key={"col-" + index + "-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(!this.state.listaguardados[item.idcatalogo_requisicion_actividad]) ?
                            <button className={'btnEstandar btnBase ' + coloresporestatus[1] + 'AA'}
                              onClick={this.guardarRegistro.bind(this, { opcion: "1", item: item, index: index })}
                              style={{ minWidth: "100px", backgroundColor: '#0061FE', border: 'solid 2px #0061FE', color: 'white' }}>
                              Guardar
                            </button>
                            : <div className="modalDetallesCheck" >
                              <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, { item, index })}
                              ><FaTrashAlt className="icoBotones" /></button>
                            </div>}
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </Table>

                <div className='row paddingEncabezados' style={{ marginBottom: "6px", padding: "8px" }}>
                  <div className="col-auto">
                    <button className='btnActualizar letraNormal' onClick={this.agregarElemento.bind(this, { opcion: "1" })}
                      style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                    >+ Agregar</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
