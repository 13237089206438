import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { guardarEditarBitacoraCosecha } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";

export default class ModalFormularioBitacorasCosecha extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,

            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '0',
            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            txtfechacosecha: (props.accion == 2) ? (props.item.fecha) : '',
            fechacosecha: (props.accion == 2) ? (this.convertirFechaCompleta(props.item.fecha)) : '',

            arrayestatus: props.estatus,
            idestatus: 1,

            vermsgerrorfechacosecha: false,
            vermsgerrorzonas: false,
            vermsgerrorranchos: false,
            vermsgerrorlote:false,

            msgerrorzona: "",
            msgerrorrancho: "",
            msgerrorfechacosecha: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colorbordertipo: estiloComboBox().colorbordercombo,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
        }

    }

    convertirFechaCompleta = (fecha) => {
        const fechagen = new Date();
        var [year, month, day] = fecha.split("-");
        var fechaseteada=fechagen.setFullYear(year, month -1 , day);

        return fechaseteada;
    }

    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if (prop == "txtzona") {
            this.setState({ txtrancho: 0 })
        }
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorfechacosecha: false })
        this.setState({ vermsgerrorranchos: false })
        this.setState({ vermsgerrorzonas: false })

        let validacionescorrectas = true
        let longitudvalida = ""

        longitudvalida = { longitudminima: 1, longitudmaxima: 50 };

        if (this.state.txtzona < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorzona: "Selecciona una zona" })
            this.setState({ vermsgerrorzonas: true })
        }

        if (this.state.txtrancho < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorrancho: "Seleccione un rancho" })
            this.setState({ vermsgerrorranchos: true })
        }

        if (this.state.txtfechacosecha == "" || this.state.txtfechacosecha == null) {
            validacionescorrectas = false;
            this.setState({ msgerrorfechacosecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfechacosecha: true })
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            if (this.state.accion == 1) {
                let json = {
                    "idcatalogo_bitacora_cosecha":0,
                    "idcatalogo_rancho": this.state.txtrancho,
                    "fecha": this.state.txtfechacosecha,
                }

                resp = await guardarEditarBitacoraCosecha(json);
            } else if (this.state.accion == 2) {
                let json = {
                    "idcatalogo_bitacora_cosecha": this.props.item.idcatalogo_bitacora_cosecha,
                    "idcatalogo_rancho": this.state.txtrancho,
                    "fecha": this.state.txtfechacosecha,
                }

                resp = await guardarEditarBitacoraCosecha(json);
            }

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else{
                this.setLoadingSpinner(false);
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de spinner ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*///////// Sección de zona ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                {this.state.accion == 2 ?
                                    <>
                                        <div className='col lblSubTitulosModal '>
                                            {this.props.item.zona}
                                        </div>
                                    </>
                                :
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                        <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona}>{i.zona}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.vermsgerrorzonas ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p>
                                :null}

                                {/*///////// Sección de rancho ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                {this.state.accion == 2 ?
                                    <>
                                        <div className='col lblSubTitulosModal '>
                                            {this.props.item.rancho}
                                        </div>
                                    </>
                                :
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                        this.state.txtzona == i.idcatalogo_zona ?
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}>{i.rancho}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.vermsgerrorranchos ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p>
                                :null}

                                {/*///////// Sección de fecha ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha de cosecha</p>
                                {this.state.accion == 2 || this.state.accion == 1 ?
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechacosecha}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechacosecha", valor: date })}
                                            //dateFormat="dd/MM/yyyy"
                                            dateFormat="yyyy-MM-dd"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                                :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>
                                        {this.state.txtfechacosecha}
                                    </label>
                                }

                                {this.state.vermsgerrorfechacosecha ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechacosecha}</p>
                                :null}

                            </div>
                        </div>
                        <br />

                        {/*///////// Sección de botones ////////*/}
                        {this.state.accion == 2 || this.state.accion == 1 ?
                            <>
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        :null}

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

{/*///////// Botón de calendario ////////*/}
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));
