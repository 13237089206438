import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import "./ModalBuscarProveedorCotizacion.css";
import { estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalBuscarProveedorCotizacions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,

            txtbuscador: "",

            tabla: {
                encabezados: [
                    "Proveedor",
                    "Tipo"
                ],
                dimensiones: [
                    "15%",
                    "15%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                ],
                elementos: [],
            },

            listaproveedores: this.props.listaproveedores,
            listatipoproveedores: this.props.listatipoproveedores,

            proveedorseleccionado: undefined,
            idproveedorseleccionado: 0,

            filtrotipoproveedor: 0,
            listaproveedoresfiltrados: this.props.listaproveedores,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

        }

    }

    async componentDidMount() {

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        this.showModal(this.state.proveedorseleccionado)

    }

    filtrarTipoProveedor = async (e) => {
    
        var data1 = JSON.parse(JSON.stringify(this.state.listaproveedores))
        var data = data1;

        var filtronombre = [];
        var filtrostipo = [];

        if (e.target.id == 'Search') {
            this.setState({ txtbuscador: e.target.value });

            if (e.target.value.trim().length < 3) {
                return;
            }

            if (this.state.filtrotipoproveedor == 0) {
                filtrostipo = data;
            } else {
                filtrostipo = data.filter((item) => item.idtipo_proveedor == this.state.filtrotipoproveedor)
            }
            filtronombre = filtrostipo.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

        } else if (e.target.id == 'selecttipo') {
            this.setState({ filtrotipoproveedor: e.target.value });

            if (e.target.value == 0) {
                filtrostipo = data;
            } else {
                filtrostipo = data.filter((item) => item.idtipo_proveedor == e.target.value)
            }

            if (this.state.txtbuscador.trim().length > 2) {
                filtronombre = filtrostipo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))

            } else {
                filtronombre = filtrostipo;
            }

        } else if (e.target.id == 'CSearch') {
            this.setState({ txtbuscador: "" });
            if (this.state.filtrotipoproveedor == 0) {
                filtrostipo = data;
            } else {
                filtrostipo = data.filter((item) => item.idtipo_proveedor == this.state.filtrotipoproveedor)
            }

            filtronombre = filtrostipo;
        }

        if (filtronombre.length < 1) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
           

        } 
        
        await 
            this.setState({ listaproveedoresfiltrados: filtronombre })
        


    }

    seleccionarProveedor = async (item) => {
        await this.setState({
            proveedorseleccionado: item,
            idproveedorseleccionado: item.idcatalogo_proveedores,
            confirmarenvio: true,
        })
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div className='row'>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                            <div className="selectBox ">
                                                <select id="selecttipo" className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={this.filtrarTipoProveedor} >
                                                    {this.state.filtrotipoproveedor == 0 ?
                                                        <option selected="true" value="0" >Todos</option> : <option value='0'>Todos</option>}
                                                    {this.state.listatipoproveedores.map((i, index) => (
                                                        <option value={i.idtipo_proveedor} key={"tipo_" + index}  >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.filtrarTipoProveedor} value={this.state.txtbuscador}
                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                placeholder="Buscar proveedor"></input>
                                            {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.filtrarTipoProveedor} />) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                    <label className='lblTexto letraTitulos'>Selecciona un proveedor: </label>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaproveedoresfiltrados.length > 0 ? this.state.listaproveedoresfiltrados.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProveedor(item)} className={this.state.idproveedorseleccionado == item.idcatalogo_proveedores ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.nombre}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tipoproveedor}
                                                                </td>
                                                            </tr>
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>CANCELAR</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >SELECCIONAR</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

