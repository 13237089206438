import React from 'react'
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalCompraDirecta.css";
import { estiloComboBox, estiloBtnActualizar, estiloFiltroEncabezados, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { FaTrashAlt, FaCalendar } from 'react-icons/fa';
import { formatoNumeroTecla, validarNumerosDecimales3 } from '../../../Services/Validaciones';
import DatePicker from "react-datepicker";
import ModalBuscarProductosRequisicion from '../ModalFormularioRequisicionGeneral/ModalBuscarProductosRequisicion';
import ModalAutorizacion from '../ModalNivelesAutorizacion/ModalAutorizacion';
import ModalBuscarProveedorCotizacions from '../ModalCotizaciones/ModalBuscarProveedorCotizacion';
import {
    getComboTipoProveedor, getProveedoresActivos, getListaTipoProductosGeneral, getListaProductosGenerales,
    guardarCompraDirecta, getInformacionCompraDirecta, getCambiarEstatusCompraDirecta
} from '../../../Services/Api';


export default class ModalCompraDirecta extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tipoedicion: props.tipoedicion,
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,
            hubocambio: false,

            tabla: {
                encabezados:

                    [
                        "Producto general",
                        "Unidad de medida",
                        "Cantidad ",
                        "Precio unitario",
                        "Subtotal",
                        "Eliminar"
                    ]
                ,
                dimensiones: [
                    "20%",
                    "20%",
                    "20%",
                    "20%",
                    "10%",
                    "10%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                ],
                elementos: [],
            },

            nuevaCotizacion: {
                "idcotizacion_proveedor": 0,
                "idproveedor": 0,
                "proveedor": "",
                "fechacompra": "",
                "idestatuscotizacion": 0,
                "estatuscotizacion": "Editado",
                "mensaje_estatuscotizacion": "",
                "productos": [],
                "total": 0.00
            },

            // nuevos
            listaproductos: [],
            listaproveedores: [],
            listatipoproveedores: [],

            listaproductosguardar: [],
            listaproductosseleccionado: [],

            proveedorselect: undefined,
            modalproveedores: false,

            modalnuevoproducto: false,
            listaproductosalmacen: [],
            listanuevosproductos: [],

            cargafirstime: true,
            loadingspinners: false,

            fechacompra: new Date(),
            fechaentrega: new Date(),
            informacioncompra: undefined,

            solicitarautorizacion: true,
            modaldetallesau: false,
            password: "",

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            //para tablas
            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colortipolbl: estiloComboBox().colorcombolbl,
            banderadidmount: false
        }
    }


    async componentDidMount() {
        this.setState({ loadingspinners: true })
        //obtiene lista de tipo proveedores
        var resptipoprovedores = await getComboTipoProveedor();
        if (this.manejadorLlamadasApis(resptipoprovedores)) {
            return;
        }

        //obtiene lista de proveedores
        var respproveedores = await getProveedoresActivos();
        if (this.manejadorLlamadasApis(respproveedores)) {
            return;
        }

        //obtiene lista de tipo productos
        var resptipoproductos = await getListaTipoProductosGeneral();
        if (this.manejadorLlamadasApis(resptipoproductos)) {
            return;
        }

        //obtiene lista de tipo productos
        var respproductos = await getListaProductosGenerales();
        if (this.manejadorLlamadasApis(respproductos)) {
            return;
        }

        this.setState({
            listaproveedores: respproveedores.data, listatipoproveedores: resptipoprovedores.data,
            listatipoproductos: resptipoproductos.data,
            listaproductos: respproductos.data,
            loadingspinners: false
        })

        if (this.state.tipoedicion == 2) { // es una edicion
            await this.cargarinformacioncompra(this.props.idcomprabuscar)
        }
        this.setState({ banderadidmount: true })
    }

    async cargarinformacioncompra(idcompra) {
        //obtiene lista de tipo productos
        var respinfoalmacen = await getInformacionCompraDirecta({ idcompra: idcompra });
        if (this.manejadorLlamadasApis(respinfoalmacen)) {
            return;
        }

        var informacion = respinfoalmacen.data;
        let infoproveedor = this.state.listaproveedores.filter((item) => ((item.idcatalogo_proveedores == informacion.idproveedor)))

        let infotabla = []
        infotabla = this.state.tabla;

        //verificar cantidad de decimales
        let productosverificados = [];

        informacion.productos.forEach((item, index) => {
            productosverificados.push({
                cantidad: parseFloat(item.cantidad).toFixed(2),
                idproducto: item.idproducto,
                precio: parseFloat(item.precio).toFixed(2),
                producto: item.producto,
                unidadmedida: item.unidadmedida,
            })
        });

        this.setState({
            listaproductosalmacen: productosverificados, informacioncompra: informacion,
            proveedorselect: infoproveedor[0],
            fechacompra: this.formatofechas(informacion.fechacompra),
            fechaentrega: this.formatofechas(informacion.fechaentrega),
            tabla: infotabla,
            loadingspinners: false,
            tipoedicion: 2
        })
    }


    formatofechas(fecha) {
        var cadenas = fecha.split("/");
        var formato = cadenas[1] + "/" + cadenas[0] + "/" + cadenas[2];
        return new Date(formato)
    }


    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 200 || resp.code == 300) {

            this.setState({ loadingspinners: false })
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }


    showModal = async (accion) => {
        
        if (accion == "10") {
            var aux = this.state.listaproductosalmacen;
            var lista = [];
            var cantidadvacia = 0;
            var preciovacio = 0;

            if (this.state.proveedorselect == undefined ) {
                toast.info("Selecciona un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            } else {

                if (aux.length > 0) {
                    aux.map(async (prod) => {
                        //agregamos dos decimales si no lo traen
                        var newCantidad = "" + prod.cantidad;
                        var auxCantidad = newCantidad.split('.');
                        if (auxCantidad.length > 1) {
                            newCantidad = auxCantidad[0].slice(0, 7) + "." + auxCantidad[1].slice(0, 2)
                        } else {
                            newCantidad = auxCantidad[0].slice(0, 7) + ".00"
                        }

                        var newPrecio = "" + prod.precio;
                        var auxPrecio = newPrecio.split('.');
                        if (auxPrecio.length > 1) {
                            newPrecio = auxPrecio[0].slice(0, 7) + "." + auxPrecio[1].slice(0, 2)
                        } else {
                            newPrecio = auxPrecio[0].slice(0, 7) + ".00"
                        }

                        if (prod.cantidad <= 0 || prod.cantidad == "") {
                            cantidadvacia = 1;
                        }
                        if (prod.precio <= 0 || prod.precio == "") {
                            preciovacio = 1;
                        }

                        let jsonProd = {
                            "idproducto": prod.idproducto,
                            "cantidad": newCantidad,
                            "precio": newPrecio
                        }
                        lista.push(jsonProd);
                    })
                    var jsonfinal = {
                        "idcompra": (this.state.tipoedicion == 2) ? (this.state.informacioncompra.idcompra) : 0,
                        "fechacompra": this.formatofecha(this.state.fechacompra),
                        "fechaentrega": this.formatofecha(this.state.fechaentrega),
                        "idproveedor": this.state.proveedorselect.idcatalogo_proveedores,
                        "solicitarautorizacion": 0,
                        "productos": lista
                    }

                    if (cantidadvacia == 0 && preciovacio == 0) {
                        if (Date.parse(this.state.fechaentrega) < Date.parse(this.state.fechacompra)) {
                            toast.info("La fecha de entrega no puede ser anterior a la fecha de compra", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                        } else {
                            this.setState({ loadingspinners: true })

                            let respguardarcompra = await guardarCompraDirecta(jsonfinal);
                            if (this.manejadorLlamadasApis(respguardarcompra)) {
                                return;
                            }

                            toast.success("Compra directa guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                            await this.cargarinformacioncompra(respguardarcompra.data.idcompra)
                        }

                    } else {
                        toast.info("Debe asignar alguna cantidad y precio", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    }
                } else {
                    toast.info("Seleccione al menos un producto", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                }
            }

        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }

    formatofecha(fecha) {
        let day = `0${fecha.getDate()}`.slice(-2)
        let month = `0${fecha.getMonth() + 1}`.slice(-2)
        let year = fecha.getFullYear()
        var formato = day + "/" + month + "/" + year
        return formato
    }

    verModalBuscadores = (ban) => {
        if (ban.opcion == 1) {
            this.setState({ titulomodal: 'Buscar proveedor', accionmodal: 2, modalproveedores: true })
        }
    }

    cerrarModalProveedor = async (prov) => {
        this.setState({ modalproveedores: !this.state.modalproveedores })
        let auxCoti = this.state.nuevaCotizacion;
        auxCoti.idproveedor = prov.idcatalogo_proveedores;
        auxCoti.proveedor = prov.nombre;
        this.setState({ proveedorselect: prov, nuevaCotizacion: auxCoti, hubocambio: true })
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })        
    }

    agregarElemento = async (ban) => {
        this.setState({ titulomodal: 'Nuevo producto', accionmodal: 1, modalnuevoproducto: true })
    }

    cerrarModalNuevoProducto = async (itemseleccionado) => {
        //si en la modal de nuevo le dio en guardar y no cancel, agregamos el json a la tabla
        if (itemseleccionado != 0) {
            var aux = this.state.listaproductosalmacen.filter((item) => { //this.state.datosDummy.filter((item) => {
                return item.idproducto == itemseleccionado.idcatalogo_producto_general
            })

            //si no existe en la tabla, lo agregamos
            if (aux.length < 1) {
                var json = {
                    idproducto: itemseleccionado.idcatalogo_producto_general,
                    cantidad: "",
                    unidadmedida: itemseleccionado.claveunidadmedida ? itemseleccionado.claveunidadmedida : itemseleccionado.unidadmedida,
                    tipoproducto: itemseleccionado.tipoproducto_general,
                    producto: itemseleccionado.nombrecomercial,
                    idcatalogo_unidadmedida: itemseleccionado.idcatalogo_unidadmedida,
                    precio: "",
                    eliminar: false,
                }

                var nuevalista = this.state.listaproductosalmacen;
                nuevalista.push(json);

                this.setState({ listaproductosalmacen: nuevalista });
                // -----------------------
                var productosaux = nuevalista;
                var listaproductosgenerales = {}
                productosaux.forEach((item) => {
                    listaproductosgenerales[item.idproducto] = item
                }) 
                listaproductosgenerales[json.idproducto] = json 
                this.setState({ listaproductosgenerales: listaproductosgenerales })

                if (json.idproducto.toString().includes("nuevo")) {
                    json.nombrecomercial = json.producto;
                    json.idcatalogo_producto_general = json.idproducto
                    json.clave = json.idproducto + " *";
                    json.tipoproducto = "";
                    json.idcatalogoproducto = json.idproducto;
                    json.unidadmedida = json.unidadmedida;
                    json.idcatalogo_unidadmedida = json.idcatalogo_unidadmedida;
                    await this.actualizarListaNuevosProductos(json)
                } else {
                    //console.log('Otra opción')
                }

                this.actualizarContenidoGeneral(listaproductosgenerales)
                await this.actualizarTabla();
            } else {
                toast.error("El producto ya existe en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }
        this.setState({ modalnuevoproducto: !this.state.modalnuevoproducto, loadingspinners: false })
    }

    actualizarListaNuevosProductos = async (nuevoproducto) => {
        var auxnuevosproductos = this.state.listanuevosproductos;
        var indexproducto = auxnuevosproductos.findIndex((item => item.idproducto == nuevoproducto.idproducto));
        if (indexproducto == -1) {
            auxnuevosproductos.push(nuevoproducto);
            await this.setState({
                listanuevosproductos: auxnuevosproductos
            })
        }
    }

    actualizarContenidoGeneral = async (productos) => {
        var listaproductosalmacen = Object.values(productos)
        this.setState({
            listaproductosalmacen: listaproductosalmacen,
        })
    }

    actualizarTabla = async () => {
        //validaremos si los datos estan incompletos para pintar la fila en rojo
        var aux = []
        this.state.listaproductosalmacen.map((item, index) => {
            if (item.producto.length < 0 || item.producto == "" ||
                item.cantidad <= 0 || item.cantidad == "") {
                //guardamos el indice del elemento con datos incompletos
                aux.push(index)
            }
        })

        //a estos indices se le colocara la fila en rojo
        await this.setState({ listaimcompletos: aux })
    }


    agregaDecimales = (valor) => {
        var newValor = "" + valor;
        var auxTotal = newValor.split('.');
        if (auxTotal.length > 1) {
            newValor = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
        } else {
            newValor = auxTotal[0].slice(0, 7) + ".00"
        }

        return newValor;
    }

    escribirCantidadPrecio = async (index, prop, event) => {
        if (prop == 'cantidad' && parseFloat(event.target.value) < 0
            || prop == 'precio' && parseFloat(event.target.value) < 0) {
            toast.error("La cantidad no puede ser menor a cero: " + this.cantidaddisponible(index), { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        else {
            var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 2 };
            let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
            var updateValor = event.target.value;

            if (tipocorrecto.codigo == 'invalid') {
                var auxUpdateValor = updateValor.split('.');
                if (auxUpdateValor.length > 1) {
                    updateValor = auxUpdateValor[0].slice(0, 7) + "." + auxUpdateValor[1].slice(0, 2)
                } else {
                    updateValor = auxUpdateValor[0].slice(0, 7)
                }
                toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            this.state.listaproductosalmacen[index][prop] = updateValor;
            var dummy = this.state.listaproductosalmacen
            this.setState({ listaproductosalmacen: dummy, hubocambio: true });
        }
    };

    eliminarProducto = async (producto, index) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listaproductosalmacen.filter((item) => !(item.idproducto == producto.idproducto));
        await this.setState({ listaproductosalmacen: aux, hubocambio: true })
    }

    sumarPrecios2 = () => {
        var totalaux = 0
        this.state.listaproductosalmacen.map((producto) => {
            var valor = (isNaN(parseFloat(producto.precio))) ? (0) : (parseFloat(producto.precio) * parseInt(producto.cantidad))
            totalaux = totalaux + valor;
        })

        var newTotal = "" + totalaux;
        var auxTotal = newTotal.split('.');
        if (auxTotal.length > 1) {
            newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
        } else {
            newTotal = auxTotal[0].slice(0, 7) + ".00"
        }
        return newTotal;
    }

    async convertirEstatus(elemento) {
        var nuevoidestatuscompra = undefined;
        var comentario = ""

        if (elemento.cambiarestatus == "disponible") {
            nuevoidestatuscompra = 4
        }

        if (elemento.cambiarestatus == "solicitarautorizacion") {
            nuevoidestatuscompra = 5
        }

        if (elemento.cambiarestatus == "rechazar") {
            nuevoidestatuscompra = 6
        }

        if (elemento.cambiarestatus == "aprobar") {
            nuevoidestatuscompra = 7
        }

        var jsonfinal = {
            "idcompra": this.state.informacioncompra.idcompra,
            "idestatuscompra": nuevoidestatuscompra,
            "password": elemento.password,
            "comentario": comentario
        }

        this.setState({ loadingspinners: true })

        let respcambiarestatus = await getCambiarEstatusCompraDirecta(jsonfinal);
        if (this.manejadorLlamadasApis(respcambiarestatus)) {
            return;
        }

        if (respcambiarestatus.code == 0) {
            toast.success("Estatus cambiado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            await this.cargarinformacioncompra(this.state.informacioncompra.idcompra)
        }
    }


    cambiarActivo = () => {
        var solicitud = this.state.solicitarautorizacion;
        this.setState({ solicitarautorizacion: !solicitud })
    }

    async autorizar(elemento) {
        this.setState({
            titulomodal: elemento.titulo,
            modaldetallesau: true,
            accionmodal: elemento.accion,
        })
    }

    guardaraprobacion = (objeto) => {
        this.setState({ modaldetallesau: false, password: objeto })
        if (objeto.cambiarestatus && objeto.cambiarestatus == "rechazar") {
        }
        else {
            if (this.state.accionmodal == 2) {
                this.convertirEstatus({ cambiarestatus: "aprobar", password: objeto });
            } else {
                this.convertirEstatus({ cambiarestatus: "rechazar", password: objeto });
            }
        }
    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalproveedores == true) ? (
                    <ModalBuscarProveedorCotizacions
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModalProveedor}
                        show={this.state.modalproveedores}
                        accion={this.state.accionmodal}
                        listaproveedores={this.state.listaproveedores}
                        listatipoproveedores={this.state.listatipoproveedores} />
                ) : null}

                {(this.state.modalnuevoproducto == true) ? (
                    <ModalBuscarProductosRequisicion titulomodal={"Buscar productos"} cerrarModal={this.cerrarModalNuevoProducto}
                        todoslosproductosalmacen={true}
                        listaproductosalmacen={this.state.listaproductosalmacen}
                        show={this.state.modalnuevoproducto} accion={1} item={this.state.itemseleccionado}

                        resptipoproductos={this.state.listatipoproductos}
                        respproductos={this.state.listaproductos}
                        listaunidadmedida={[]}
                        listaproductosnuevos={this.state.listanuevosproductos}
                        listaproductosagregados={this.state.listaproductosalmacen}//this.state.datosDummy}
                        ocultarnuevoproducto={1}
                    />
                ) : null}

                {(this.state.modaldetallesau == true) ? (
                    <ModalAutorizacion titulomodal={this.state.titulomodal} cerrarModal={this.guardaraprobacion}
                        show={this.state.modaldetallesau} accion={this.state.accionmodal} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.props.titulomodalcompradirecta}</label>


                        {/* ////////////////////   Inicio Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}
                        <br />
                        <div className="container-fluid containerTabla">
                            <div className="container-fluid anchoMaximo ">
                                <div className="containerSubTablaAA">

                                    <div className="marginTopSeparator" >
                                        <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                                            <div className='row flexNoWrap'>

                                                                <div className='col-9 containerSubTablaAA minPaddingTopBottom'>
                                                                    <p className='sinMargen'>

                                                                        {(this.state.tipoedicion == 1) ? (
                                                                            <button className='btnActualizar letraNormal botonesB' style={{ width: "200px" }}
                                                                                onClick={this.verModalBuscadores.bind(this, { opcion: "1" })}><b>
                                                                                    Proveedor: {(this.state.proveedorselect) ? (this.state.proveedorselect.nombre)
                                                                                        : ("Selecciona uno")}
                                                                                </b>
                                                                            </button>

                                                                        ) : (
                                                                            (this.state.banderadidmount == true) ? (
                                                                                (this.state.informacioncompra.idestatuscompra == 4) ? (
                                                                                    <button className='btnActualizar letraNormal botonesB'
                                                                                        onClick={this.verModalBuscadores.bind(this, { opcion: "1" })}><b>
                                                                                            Proveedor: {(this.state.proveedorselect) ? (this.state.proveedorselect.nombre)
                                                                                                : ("Selecciona uno")}
                                                                                        </b>
                                                                                    </button>

                                                                                ) : (
                                                                                    <label style={{ minWidth: "120px", }}><b>Proveedor: {this.state.proveedorselect.nombre}</b></label>
                                                                                )
                                                                            ) : (null)
                                                                        )}

                                                                        <br /><br />
                                                                        <div style={{ display: "flex" }}>
                                                                            <label style={{ minWidth: "120px" }}>Fecha de compra:</label>

                                                                            {
                                                                                (this.state.tipoedicion == 1) ? (
                                                                                    <div className='col-auto' style={{ margin: '0px', padding: '0px', marginLeft: "10px" }}>
                                                                                        <DatePicker
                                                                                            selected={this.state.fechacompra}
                                                                                            onChange={(date) => this.cambiarFecha({ llave: "fechacompra", valor: date })}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            locale="es"
                                                                                            customInput={
                                                                                                <DateButton />
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ) : (

                                                                                    (this.state.banderadidmount == true) ? (
                                                                                        (this.state.informacioncompra.idestatuscompra == 4 || this.state.informacioncompra == []) ? (

                                                                                            <div className='col-auto' style={{ margin: '0px', padding: '0px', marginLeft: "10px" }}>
                                                                                                <DatePicker
                                                                                                    selected={this.state.fechacompra}
                                                                                                    onChange={(date) => this.cambiarFecha({ llave: "fechacompra", valor: date })}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    locale="es"
                                                                                                    customInput={
                                                                                                        <DateButton />
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ) : (
                                                                                            <label style={{ minWidth: "120px" }}>{this.state.informacioncompra.fechacompra}</label>
                                                                                        )
                                                                                    ) : (null)
                                                                                )
                                                                            }
                                                                        </div>


                                                                        <div style={{ display: "flex" }}>
                                                                            <label style={{ minWidth: "120px" }}>Fecha de entrega:</label>

                                                                            {
                                                                                (this.state.tipoedicion == 1) ? (
                                                                                    <div className='col-auto' style={{ margin: '0px', padding: '0px', marginLeft: "10px" }}>
                                                                                        <DatePicker
                                                                                            selected={this.state.fechaentrega}
                                                                                            onChange={(date) => this.cambiarFecha({ llave: "fechaentrega", valor: date })}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            locale="es"
                                                                                            customInput={
                                                                                                <DateButton />
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    (this.state.banderadidmount == true) ? (
                                                                                        (this.state.informacioncompra.idestatuscompra == 4) ? (
                                                                                            <div className='col-auto' style={{ margin: '0px', padding: '0px', marginLeft: "10px" }}>
                                                                                                <DatePicker
                                                                                                    selected={this.state.fechaentrega}
                                                                                                    onChange={(date) => this.cambiarFecha({ llave: "fechaentrega", valor: date })}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    locale="es"
                                                                                                    customInput={
                                                                                                        <DateButton />
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ) : (
                                                                                            <label style={{ minWidth: "120px" }}>{this.state.informacioncompra.fechaentrega}</label>
                                                                                        )
                                                                                    ) : (null)
                                                                                )
                                                                            }
                                                                        </div>


                                                                    </p>
                                                                </div>
                                                                <div className='col-3 containerSubTablaAA minPaddingTopBottom'>

                                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                                                        {(this.state.tipoedicion == 2 && this.state.banderadidmount == true) ? (
                                                                            this.state.informacioncompra.estatuscompra
                                                                        )
                                                                            : ("Nueva compra")
                                                                        }
                                                                    </label>

                                                                    {(this.state.tipoedicion == 2 && this.state.banderadidmount == true) ? (

                                                                        <>
                                                                            {
                                                                                (this.state.informacioncompra.idestatuscompra == 4 || this.state.informacioncompra == []) ? (
                                                                                    <Button className="btnAceptarModal"
                                                                                        onClick={() => this.convertirEstatus({ cambiarestatus: "solicitarautorizacion" })}
                                                                                    >Solicitar autorización</Button>

                                                                                ) : ((this.state.informacioncompra.idestatuscompra == 5) ? (
                                                                                    <Button className="btnAceptarModal"
                                                                                        onClick={() => this.convertirEstatus({ cambiarestatus: "disponible" })}
                                                                                    >Regresar a disponible</Button>

                                                                                ) : (
                                                                                    (this.state.informacioncompra.idestatuscompra == 6) ? (
                                                                                        <Button className="btnAceptarModal"
                                                                                            onClick={() => this.convertirEstatus({ cambiarestatus: "disponible" })}
                                                                                        >Regresar a disponible</Button>

                                                                                    ) : ((this.state.informacioncompra.idestatuscompra == 7) ? (
                                                                                        <Button className="btnAceptarModal"
                                                                                            onClick={() => this.convertirEstatus({ cambiarestatus: "comprar" })}
                                                                                        >Comprar</Button>
                                                                                    ) : (null))
                                                                                ))
                                                                            }
                                                                        </>

                                                                    )
                                                                        : (null)
                                                                    }
                                                                </div>

                                                            </div>
                                                            <div className='row flexNoWrap'>
                                                                <div className='col-12'>
                                                                    <div style={{ display: "flex", width: "100%" }}>
                                                                        {(this.state.tipoedicion == 2 && this.state.banderadidmount && this.state.informacioncompra.idestatuscompra == 6) ? (

                                                                            <div className='col-12  letraNormal containerSubTablaAA minPaddingTopBottom' style={{ color: '#000000', paddinfRight: '8px', paddingLeft: '8px', textAlign: 'left', background: '#f9a9a9', borderRadius: '5px', marginBottom: '12px' }}>
                                                                                <p className='sinMargen'>Motivo rechazo: {this.state.informacioncompra.mensajeautorizacion}</p>
                                                                            </div>

                                                                        ) : (null)}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                    <div className='tablaProductoCotizaciones'>
                                                                        <br />

                                                                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                                                        <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
                                                                            <div className="container-fluid">
                                                                                <div className='row'>
                                                                                    <div>

                                                                                        <Table hover>
                                                                                            <thead style={{ verticalAlign: "bottom" }}>

                                                                                                <tr>
                                                                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                                                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"

                                                                                                            style={{
                                                                                                                minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                                                                textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                                                                            }}>

                                                                                                            {
                                                                                                                (this.state.tipoedicion == 2 && this.state.informacioncompra) ?
                                                                                                                    (this.state.informacioncompra.idestatuscompra && this.state.informacioncompra.idestatuscompra != 4 && index == 5) ?
                                                                                                                        null :
                                                                                                                         titulo 
                                                                                                                    :
                                                                                                                     titulo 
                                                                                                            }

                                                                                                        </th>
                                                                                                    ))}
                                                                                                </tr>
                                                                                            </thead>

                                                                                            <tbody style={{ verticalAlign: "bottom" }}>

                                                                                                {this.state.listaproductosalmacen.map((item, index) => (

                                                                                                    <tr key={index}>
                                                                                                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                                            {item.producto}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {item.unidadmedida}
                                                                                                        </td>
                                                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {
                                                                                                                (this.state.tipoedicion == 2 && this.state.informacioncompra.idestatuscompra != 4) ?
                                                                                                                    this.agregaDecimales(item.cantidad)
                                                                                                                    :
                                                                                                                    <input style={{ textAlign: "center" }} type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla" value={item.cantidad}
                                                                                                                        onChange={(e) => this.escribirCantidadPrecio(index, 'cantidad', e)}></input>
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {
                                                                                                                (this.state.tipoedicion == 2 && this.state.informacioncompra.idestatuscompra != 4) ?
                                                                                                                    "$" + this.agregaDecimales(item.precio)
                                                                                                                    :
                                                                                                                    <input style={{ textAlign: "center" }} type={'number'} className="inputSubtabla"
                                                                                                                        value={item.precio} onChange={(e) => this.escribirCantidadPrecio(index, 'precio', e)}></input>
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            ${this.agregaDecimales(item.precio * item.cantidad)}
                                                                                                        </td>

                                                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {
                                                                                                                (this.state.tipoedicion == 2 && this.state.informacioncompra.idestatuscompra != 4) ?
                                                                                                                    null
                                                                                                                    :
                                                                                                                    <button className='btnEliminarFila' >
                                                                                                                        <FaTrashAlt className="icoBotones" onClick={() => this.eliminarProducto(item, index)} />
                                                                                                                    </button>
                                                                                                            }

                                                                                                        </td>
                                                                                                    </tr>                                                                                                  
                                                                                                )
                                                                                                )
                                                                                                }

                                                                                                {this.state.tipoedicion == 1 || this.state.tipoedicion == 2 && this.state.banderadidmount == true && this.state.informacioncompra.idestatuscompra == 4 ? (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className=" txttotal" colSpan={3} style={{ color: this.state.colorcontenidotabla }}>
                                                                                                                <button className='btnActualizar letraNormal botonesB' onClick={this.agregarElemento.bind(this, { opcion: "1" })}
                                                                                                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                                                                                                                >+ Agregar</button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                ) : null
                                                                                                }

                                                                                                <br /><br />

                                                                                                <tr>
                                                                                                    <td className=" txttotal" colSpan={4} style={{ color: this.state.colorcontenidotabla }}>
                                                                                                        {"Total"}
                                                                                                    </td>
                                                                                                    <td className=" txttotal" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                        ${formatoNumeroTecla(this.sumarPrecios2(), 2)}
                                                                                                    </td>
                                                                                                </tr>

                                                                                            </tbody>
                                                                                        </Table>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row'>

                                                                            <br></br>
                                                                            <br></br>
                                                                        </div>
                                                                    </div>
                                                                    <br></br>
                                                                    <br></br>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ////////////////////   Fin Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}

                        {this.state.tipoedicion == 1 || this.state.tipoedicion == 2 && this.state.banderadidmount == true && this.state.informacioncompra.idestatuscompra == 4 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')}  >Guardar</Button>
                                </div>
                            </>
                        ) : null
                        }

                    </ModalBody>
                </Modal>
            </>
        )
    }
}


const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
    >
        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));