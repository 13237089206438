import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoInventarioEquiposHerramientas.css';
import { BiSearchAlt2 } from "react-icons/bi";
import { AiFillCheckSquare } from "react-icons/ai";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo,
  estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getAlmacenesEquiposInformación } from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Este Modal de momento no existe
import ModalMovimiento from '../../Complements/Modales/ModalMovimiento/ModalMovimiento';
import { formatoNumero2 } from '../../Services/Validaciones';

export default class CatalogoInventarioEquiposHerramientas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      errordeacceso: false,
      tabla: {
        encabezados: [
          "Nombre/clave",
          "Marca",
          "Modelo",
          "Tipo equipo",
          "Unidades",
        ],
        dimensiones: [
          "15%",
          "15%",
          "15%",
          "10%",
          "10%",
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "100px",
          "150px",
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      nuevosdatosinventarioalmacen: undefined,
      datosinventarioalmacen: undefined,
      redireccionando: false,
      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de un almacen seleccionado 
    var datosalmacen = await localStorage.getItem('datos')

    if (datosalmacen != null) {
      var data = JSON.parse(datosalmacen)

      var estatus = await getEstatus();
      if (this.manejadorLlamadasApis(estatus)) {
        return;
      }

      var respcatalogoalmacenes = await getAlmacenesEquiposInformación(data);
      if (this.manejadorLlamadasApis(respcatalogoalmacenes)) {
        await this.setState({
          redireccionando: true,
        })
        return;
      }

      console.log("api consumida: ", respcatalogoalmacenes.data.rancho);
      var tabla = this.state.tabla;
      tabla.elementos = respcatalogoalmacenes.data.productos;

      await this.setState({
        txtbuscador: '',
        estatus: estatus.data,
        tabla: tabla,
        datosinventarioalmacen: respcatalogoalmacenes.data
      })

      this.dimensiones();
      this.cambiarContenido(1);
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    } else {
      await this.setState({
        redireccionando: true,
      })
    }

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true, errordeacceso: false })
    this.componentDidMount();
  }

  saveInputLocacion = async (e) => {

    this.setState({ txtbuscador: e.target.value })

    if (e.target.value.trim().length > 2) {
      var data1 = JSON.parse(JSON.stringify(this.state.tabla))
      var data = data1.elementos;
      const filtros = data.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || item.clave.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length < 1) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtros)
    }
    else {
      this.setState({ filtrolistapaginada: [] })
    }

  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo movimiento', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar movimiento', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = async (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.componentDidMount();
    }


    if (accion != 0) {
      await this.setState({
        //Datos reciibidos de modal movimiento
        nuevosdatosinventarioalmacen: accion
      });
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      })
    }, 0)
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      })
    }, 0)
  }

  borrarBusqueda = () => {
    this.setState({ txtbuscador: '' })
    this.cambiarContenido(1)
    this.setState({ filtrolistapaginada: [] })
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  render() {
    return (

      <>

        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesInventarioAlmacen bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesInventarioAlmacen bandera={'iralmacen'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalMovimiento titulomodal={"Nuevo Movimiento"} cerrarModal={this.cerrarModal} estatus={this.state.estatus} show={this.state.modaldetalles}
            activarSpinner={this.activarSpinner}
            datosinventarioalmacen={this.state.datosinventarioalmacen}
            origen={"inventarioequipos"}
            almacenorigen={this.state.datosinventarioalmacen.rancho}
            idalmacenorigen={this.state.datosinventarioalmacen.idcatalogoalmacenequipo}
            encabezados={[
              "Nombre/Clave",
              "Marca",
              "Modelo",
              "Tipo",
              "Disponibles",
              "Cantidad",
              ""]}
            dimensiones={[
              "10%",
              "35%",
              "15%",
              "20%",
              "25%",
              "40px"
            ]}

            mindimensiones={[
              "100px",
              "100px",
              "100px",
              "100px",
              "100px",
              "40px"
            ]}
          />
        ) : null}



        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Inventario Almacén de equipos y herramientas de {(this.state.datosinventarioalmacen) ? (this.state.datosinventarioalmacen.rancho) : ("")}</label>
                <div className='descripcionAlmacen'>
                  <div></div>
                  <div>
                    <AiFillCheckSquare className={this.state.datosinventarioalmacen != undefined && this.state.datosinventarioalmacen.estatus == "Activo" ? "icoAlmacenPrincipalActivo" : "icoAlmacenPrincipalInactivo"} />  Activo
                  </div>

                 
                </div>
              </div>

              <div className='col containerSubTabla'></div>
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar clave/nombre"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>



              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo movimiento</button>
              </div>}

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoequipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.existencias)}
                        </td>


                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreclave}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.marca}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.modelo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoequipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.existencias)}
                        </td>
                      </tr>

                    ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}


function AccionesInventarioAlmacen({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'iralmacen') {
    history.push(location.pathname)
    return (<Redirect from='/' to={"/catalogoalmacenesequiposyherramientas"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

