import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { estiloColorError, estiloComboBox, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import {
    validarPasswordEliminar
} from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';

export default class ModalFormularioAccionEstatusContrasenaGenerica extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion ? props.accion : 1, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            item: props.item,

            // Modales de aprobación
            txtpassword: "",
            vermsgerrortxtpassword: false,
            msgerrortxtpassword: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colortitulo: estiloFiltroEncabezados().colortitulos,
        }

    }

    showModal = (opciones) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(opciones);
        }, 200);
    }

    escribir = (prop) => (event) => {
        if ('txtclabe' == prop) {
            this.setState({ [prop]: event.target.value.toUpperCase() });
        } else {
            this.setState({ [prop]: event.target.value });
        }
    }

    guardarCambios = async () => {
        let validacionescorrectas = true
        if (this.state.accion >= 1 && this.state.accion <= 4) {
            this.setState({ vermsgerrortxtpassword: false })

            let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
            if (txtpassword.codigo == "invalid") {
                this.setState({ msgerrortxtpassword: txtpassword.mensaje })
                this.setState({ vermsgerrortxtpassword: true })
                validacionescorrectas = false;
            }
        }
        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })
            this.showModal({ accion: 1, id: this.state.accion, password: this.state.txtpassword })
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div>
                            <div className="divTituloModal">
                                <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                            </div>
                        </div>
                        <br />

                        {(this.state.accion >= 1 && this.state.accion <= 4) ?
                            <div className="divModalEliminar">
                                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                                    {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}
                                </div>
                            </div>
                            : null}
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal({ accion: 0, id: this.state.accion })}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}>Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

