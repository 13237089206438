import React from 'react'
import { InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioCambioPasswordPerfilUsuario.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarPasswordPerfilUsuario } from '../../../Services/Validaciones';
import { cambiarPasswordPerfilUsuario } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioCambioPasswordPerfilUsuario extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,
            showpasswordactual: false,
            showpasswordnueva: false,
            showpasswordrepnueva: false,
            txtpasswordactual: '',
            txtnuevopassword: '',
            txtrepnuevopassword: '',
            txtiduser: props.iduser,
            arrayestatus: [],
            idestatus: 1,
            vermsgerrornuevopassword: false,
            vermsgerrorrepnuevopassword: false,
            vermsgerrorpasswordactual: false,
            msgerror: '',
            msgerrorpasswordactual: "",
            msgerrornuevopassword: "",
            msgerrorrepnuevopassword: "",
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

        }

    }
    async componentDidMount() {
        
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }
    
    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorpasswordactual: false })
        this.setState({ vermsgerrornuevopassword: false })
        this.setState({ vermsgerrorrepnuevopassword: false })

        let longitudvalida = {longitudminima: 6, longitudmaxima: 15};
        let pass = validarPasswordPerfilUsuario(this.state.txtpasswordactual.trim(), longitudvalida);
        let validacionescorrectas = true
        if (pass.codigo == "invalid") {
            this.setState({ msgerrorpasswordactual: pass.mensaje })
            this.setState({ vermsgerrorpasswordactual: true })
            validacionescorrectas = false;
        }

        let passnuevo = validarPasswordPerfilUsuario(this.state.txtnuevopassword.trim(), longitudvalida);
        if (passnuevo.codigo == "invalid") {
            this.setState({ msgerrornuevopassword: passnuevo.mensaje })
            this.setState({ vermsgerrornuevopassword: true })
            validacionescorrectas = false;
        }

        let passrep = validarPasswordPerfilUsuario(this.state.txtrepnuevopassword.trim(), longitudvalida);
        if (passrep.codigo == "invalid") {
            this.setState({ msgerrorrepnuevopassword: passrep.mensaje })
            this.setState({ vermsgerrorrepnuevopassword: true })
            validacionescorrectas = false;
        }

        let passiguales = this.state.txtrepnuevopassword.trim()==this.state.txtnuevopassword.trim();
        if (!passiguales) {
            this.setState({ msgerrorrepnuevopassword: "La nueva contraseña no coincide con la contraseña repetida" })
            this.setState({ vermsgerrorrepnuevopassword: true })
            validacionescorrectas = false;
        }
        

        if (validacionescorrectas) {
            
            this.setLoadingSpinner(true);
            let json = {
               // "idusuario": this.state.txtiduser,
                "passwordactual": this.state.txtpasswordactual,
                "nuevopassword": this.state.txtnuevopassword
            }

            var resp = await cambiarPasswordPerfilUsuario(json);
            if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
              }
            else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else {
                this.showModal('1')
            }
        }

    }

    verPasswordActual = () => {
        this.setState({ showpasswordactual: !this.state.showpasswordactual });
    };

    ocultarPasswordActual = (event) => {
        event.preventDefault();
    };

    verPasswordNew = () => {
        this.setState({ showpasswordnueva: !this.state.showpasswordnueva });
    };

    ocultarPasswordNew = (event) => {
        event.preventDefault();
    };

    verPasswordRepNew = () => {
        this.setState({ showpasswordrepnueva: !this.state.showpasswordrepnueva });
    };

    ocultarPasswordRepNew = (event) => {
        event.preventDefault();
    };

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Contraseña actual</p>
                                {this.state.accion == 8 ? (
                                    <Input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={this.state.showpasswordactual ? "text" : "password"} onChange={this.escribir("txtpasswordactual")} 
                                        value={this.state.txtpasswordactual} 
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verPasswordActual}
                                                onMouseDown={this.ocultarPasswordActual}>
                                                    {this.state.showpasswordactual ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }></Input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtpasswordactual}</label>}

                                {this.state.vermsgerrorpasswordactual ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpasswordactual}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nueva contraseña</p>
                                {this.state.accion == 8 ? (
                                    <Input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={this.state.showpasswordnueva ? "text" : "password"} onChange={this.escribir("txtnuevopassword")}
                                        value={this.state.txtnuevopassword} 
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verPasswordNew}
                                                onMouseDown={this.ocultarPasswordNew}>
                                                    {this.state.showpasswordnueva ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }></Input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnuevopassword}</label>}

                                {this.state.vermsgerrornuevopassword ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrornuevopassword}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Repetir nueva contraseña</p>
                                {this.state.accion == 8 ? (
                                    <Input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={this.state.showpasswordrepnueva ? "text" : "password"} onChange={this.escribir("txtrepnuevopassword")}
                                        value={this.state.txtrepnuevopassword} 
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verPasswordRepNew}
                                                onMouseDown={this.ocultarPasswordRepNew}>
                                                    {this.state.showpasswordrepnueva ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }></Input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtrepnuevopassword}</label>}

                                {this.state.vermsgerrorrepnuevopassword ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrepnuevopassword}</p> : null}


                                
                            </div>
                        </div>

                        {this.state.accion == 8 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

