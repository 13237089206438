import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioTablasDisponibles extends React.Component {

    constructor(props) {
        super(props)
console.log("ModalFormularioTablasDisponibles")
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
           
            estilosmodal: estilosModal(),

            listatablasdisponibles: props.listatablasdisponibles,
            listatablasseleccionadas: [],
            listatablasagregadas: this.props.lote.tablas,
            listatablasduplicadas: [],

            tabla: {
                encabezados: [
                    "Selecciona una tabla"
                ],
                dimensiones: [
                    "15%"
                ],
                mindimensiones: [
                    "100px",
                ],
                elementos: [],
            },

            itemtablatodas: {
                tabla: "Todas",
                idcatalogo_lotecultivo_tabla: -1,
                idcatalogo_ranchos_lotes_cultivo: -1
            }
        }

    }
    async componentDidMount() {

       
        

    }



    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    continuarProceso = async () => {
        if(this.state.listatablasseleccionadas.length > 0){
            var tablarepetida=false;
            var listatablasrepetidas=[];
           
            this.state.listatablasseleccionadas.forEach(element => {
                var itemfound = this.state.listatablasagregadas.find(item => item.idtabla == element.idcatalogo_lotecultivo_tabla);
                if(itemfound != undefined){
                    listatablasrepetidas.push(element)
                    tablarepetida = true; 
                }
            });
            if(!tablarepetida){
                var listatablasseleccionadasaux = []
                this.state.listatablasseleccionadas.forEach(element => {
                    var nuevatabla= {
                        idtabla: element.idcatalogo_lotecultivo_tabla,
                        tabla: element.tabla,
                        eliminar: false,
                        productos: []
                    }
                    listatablasseleccionadasaux.push(nuevatabla);
                });
                
                this.showModal(listatablasseleccionadasaux)
            }else{
                setTimeout(() => {
                    this.setState({
                        listatablasduplicadas: listatablasrepetidas
                    })
                }, 200);
               
                toast.info("Las tablas ya se encuentran agregadas", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            
        }else{
            toast.info("Favor de seleccionar al menos una tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
    }

    seleccionarItem = async (itemseleccionado) => {
        var listatablasseleccionadasaux = this.state.listatablasseleccionadas;
        var itemfound = listatablasseleccionadasaux.find(item => item.idcatalogo_lotecultivo_tabla == itemseleccionado.idcatalogo_lotecultivo_tabla);

        if(itemfound == undefined){
            listatablasseleccionadasaux.push(itemseleccionado)
        }else{
            var index = listatablasseleccionadasaux.indexOf(itemseleccionado)
            if(index > -1){
                listatablasseleccionadasaux.splice(index,1)
            }
        }
        await this.setState({
            listatablasseleccionadas: listatablasseleccionadasaux,
            confirmarenvio: true

        })
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

               
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal([])} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                        <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTabla tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        <th key={"tabla" + 1} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                                                                Selecciona una tabla:
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                   
                                                     {
                                                        this.state.listatablasdisponibles.length > 0 ?
                                                            this.state.listatablasdisponibles.map((item, index) => (
                                                            (<tr key={"tabla" + index} onClick={() => this.seleccionarItem(item)} 
                                                                className={this.props.lote.tablas.find(tabla => tabla.idtabla == item.idcatalogo_lotecultivo_tabla) != undefined ? "trFondoInactivoGray" : this.state.listatablasseleccionadas.find(selected => selected.idcatalogo_lotecultivo_tabla == item.idcatalogo_lotecultivo_tabla) == undefined ? ""
                                                               : "trFondoActivoGreen"}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tabla}
                                                                </td>
                                                            </tr>)
                                                            ))
                                                        :<p>No hay tablas disponibles para este lote</p> 

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal([])}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.continuarProceso()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

