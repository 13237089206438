import React from 'react'
import './CatalogoNuevaRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';
import { estiloBtnAgregar,coloresTxtBuscador, estiloBtnActualizar,  estiloBtnNuevo, estilosModal } from '../../Services/PaletaDeColores';
import ModalFormularioTablasDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioTablasDisponibles';

import { Redirect } from 'react-router-dom';
import Tabla from './Tabla';
import 'react-toastify/dist/ReactToastify.css';


export default class Lote extends React.Component {
  constructor(props) {
    super(props);
console.log("lote", props)
    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,
      lote: this.props.itemlote,
      
      colapsartabla:true,
      eliminartabla:false,

      listatablasdisponibles:[],

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
   
    var tablasdelote = this.props.listatablas.filter((item) => item.idcatalogo_ranchos_lotes_cultivo == this.props.itemlote.idlote );
    setTimeout(() => {
      this.setState({ 
        listatablasdisponibles:  tablasdelote
     })
   }, 200);
   
    window.addEventListener("resize", this.dimensiones);
    
  }

 
  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Tablas disponibles', accionmodal: 1 })
    }
    

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (listatablas) => {
    var listatablasagregadasaux = this.props.itemlote.tablas;
    listatablasagregadasaux = listatablasagregadasaux.concat(listatablas)
    this.setState({ 
      modaldetalles: !this.state.modaldetalles,
      colapsartabla: true,
    //  listatablasagregadas: listatablasagregadasaux
    })

    var auxlote = this.state.lote;
    auxlote.tablas = listatablasagregadasaux;
    this.props.actualizarContenido(auxlote);
  }

  
  actualizarContenido = (tabla) => {
    var listatablas = this.props.itemlote.tablas;

    var index =  listatablas.findIndex((obj => obj.idtabla == tabla.idtabla));
    if(index > -1){
      listatablas[index] = tabla;
    }

    var auxlote = this.state.lote;
    auxlote.tablas = listatablas;

    this.props.actualizarContenido(auxlote);
  }

  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var tablas =  this.props.itemlote.tablas.filter(tabla => tabla.idtabla != elementoeliminado.idtabla);
    
    var auxlote = this.state.lote;
    auxlote.tablas = tablas;

    this.props.actualizarContenido(auxlote);
  }

  seleccionarElementoaEliminar = () => {
    this.props.eliminarElemento(this.state.lote)
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  actualizarListaNuevosProductos = (nuevoproducto) =>{
    this.props.actualizarListaNuevosProductos(nuevoproducto);
  }
  
  render() {
    return (

      <>
      {(this.state.modaldetalles == true) ? (
          <ModalFormularioTablasDisponibles titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} 
            show={this.state.modaldetalles} accion={this.state.accionmodal} lote={this.props.itemlote} listatablasdisponibles={this.state.listatablasdisponibles}/>
        ) : null}
    
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                              <p className='sinMargen'><b>Lote:</b>{this.props.itemlote.lote}</p>
                            </div>
                          </div>
                      </TableCell>
                      <TableCell align="right" className='displayFlex nonePaddingTopBottom' >
                        <div className='row flexNoWrap'>
                          {
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnNuevo letraNormal'
                                style={{ color: this.state.colorbtnagregar, backgroundColor: this.state.colorfondobtnragregar, border: this.state.borderbtnactualizar}}
                                onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Agregar</button>
                            </div>
                          }
                           <div className="col-auto modalDetallesCheck minPaddingTopBottom" >
                              <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar()}
                              ><FaTrashAlt className="icoBotones" /></button>
                          </div>
                          
                        </div>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemlote.tablas.length > 0 ?
                              this.props.itemlote.tablas.map((item, index) => (
                                <div className='spacePaddingBottom'>
                                <Tabla key={"tabla_" + item.idtabla} accion={this.state.accion} itemtabla={item} 
                                actualizarContenido={this.actualizarContenido}
                                eliminarElemento={this.eliminarElemento}
                                actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                                listaproductos={this.props.listaproductos}
                                listafamiliaproductos={this.props.listafamiliaproductos}
                                listatipoproductos={this.props.listatipoproductos}
                                listaunidadesmedida={this.props.listaunidadesmedida}
                                listanuevosproductos={this.props.listanuevosproductos}
                                />
                                </div>
                              ))
                              : <div></div>
                            }
                           
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


      
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

