import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import ModalFormularioNuevoProductoRequisicion from './ModalFormularioNuevoProductoRequisicion';
import { getComboFamiliaProducto, getComboTipoProducto } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioBuscarProductosRequisicion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            modalnuevoproducto: false,
            txtbuscador: "",
            tabla: {
                encabezados: [
                    "Nombre comercial",
                    "Familia",
                    "Tipo agroquímico",
                    //"Descripción"
                ],
                dimensiones: [
                    "40%",
                    "30%",
                    "30%",
                    //"20%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                ],
                elementos: [],
            },
            listaunidadmedida: props.listaunidadmedida,
            todoslosproductosalmacen: this.props.todoslosproductosalmacen,
            listaproductosalmacen: this.props.listaproductosalmacen,
            productoseleccionado: undefined,
            idproductoseleccionado: 0,
            filtrofamilia: "TodasLasFamilias",
            filtrotipoproducto: "TodasLosProductos",
            idestatus: 1,
            msgerror: '',
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listafamiliaproductos: this.props.respfamiliaproductos,
            listatipoproductos: this.props.resptipoproductos,
            listatipoproductosfiltrados: this.props.resptipoproductos,
            listaproductosfiltrados: [],
        }

    }
    async componentDidMount() {

        this.setState({ loadingspinners: true })

        var tabla = this.state.tabla;
        if(this.state.todoslosproductosalmacen){ 
            var todoslosproductosaux = this.props.listaproductosnuevos.concat(this.props.respproductos)     
            tabla.elementos =  todoslosproductosaux;
        }else{
            tabla.elementos = this.filtrarProductosdeAlmacen( this.props.respproductos)
        }
        
        this.setState({
            txtbuscador: '',
            tabla: tabla,
            listaproductosfiltrados: tabla.elementos
        })
        this.setState({ loadingspinners: false })

    }

    filtrarProductosdeAlmacen(respproductos){
        var productosdealmacen = [];

        this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
            var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
            if(producto != undefined){
                productosdealmacen = productosdealmacen.concat(producto)
            }
        });

        return productosdealmacen;
    }

   

    showModal = (accion) => {
        
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        this.showModal(this.state.productoseleccionado)
        
    }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];
        
        listafiltrofamilia = data;
    
        if(this.state.filtrofamilia != "TodasLasFamilias"){
            listafiltrofamilia = listafiltrofamilia.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
        }

        if(this.state.filtrotipoproducto != "TodasLosProductos"){
            listafiltrotipo = data.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
        }else{
            listafiltrotipo =listafiltrofamilia;
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => 
            item.nombrecomercial.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ listaproductosfiltrados: filtros });
        }else if(e.target.value.trim().length < 1){
            //this.filtrarFamilia(this.state.filtrofamilia)
            this.setState({ listaproductosfiltrados: listafiltrotipo });
        }

    }
    filtro = (array) => {
        this.setState({
          listaproductosfiltrados: []
        });
    
        setTimeout(() => {
          this.setState({
            listaproductosfiltrados: array
          });
        }, 0);
    
      }
    
    filtrarFamilia = async (idfamilia) => {
       
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;
    
        await this.setState({ 
            filtrofamilia: idfamilia 
        })
    
        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];
        var cambiodefiltrofamiia = false;
        
        //filtrar familia producto 
        listafiltrofamilia = data;
        if(idfamilia != "TodasLasFamilias"){
    
            var listatipoproductosfiltrados = this.state.listatipoproductos.filter((item) => item.idfamilia_productos == idfamilia)
            cambiodefiltrofamiia = true;
            await this.setState({ 
                listatipoproductosfiltrados: listatipoproductosfiltrados,
                filtrotipoproducto: "TodasLosProductos"
            })
            listafiltrofamilia = data.filter((item) => item.idfamiliaproducto == idfamilia)
    
        }else{
            await this.setState({ 
                listatipoproductosfiltrados: this.state.listatipoproductos,
                filtrotipoproducto: "TodasLosProductos"
            })
        }

        // filtrar tipo producto 
        if(this.state.filtrotipoproducto != "TodasLosProductos" && !cambiodefiltrofamiia){
            listafiltrotipo = listafiltrofamilia.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
        }else{listafiltrotipo = listafiltrofamilia;}

        filtrofinal = listafiltrotipo;
            
        //filtrar palabra 
        if(this.state.txtbuscador.length>2){
            filtrofinal = listafiltrotipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }
    
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

       if(cambiodefiltrofamiia){
        this.filtro( filtrofinal)
       }else{
        this.filtro( filtrofinal)
       }

  }

  filtrarTipoProducto = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
  
    await this.setState({ 
        filtrotipoproducto: itemfiltrado
    })
  
    var filtrofinal = [];

    var listafiltrofamilia = [];
    var listafiltrotipo = [];
    
    listafiltrotipo = data;
    if(itemfiltrado != "TodasLosProductos"){
  
      listafiltrotipo = data.filter((item) => item.idtipoproducto == itemfiltrado)
    }

    if(this.state.filtrofamilia != "TodasLasFamilias"){
        listafiltrofamilia = listafiltrotipo.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
    }else{listafiltrofamilia = listafiltrotipo;}

    filtrofinal = listafiltrofamilia;

    if(this.state.txtbuscador.length>2){
        filtrofinal = listafiltrofamilia.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    }
    
   if (filtrofinal.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    await this.setState({
        listaproductosfiltrados: filtrofinal
    })
  
  }

    borrarBusqueda = () => {
        this.setState({
            txtbuscador: '',
            filtrofamilia: "TodasLasFamilias",
            filtrotipoproducto: "TodasLosProductos",
            listaproductosfiltrados: this.state.tabla.elementos
        });
    }

    seleccionarProducto = async (item) => {
        await this.setState({
            productoseleccionado: item,
            confirmarenvio: true,
            idproductoseleccionado: item.idcatalogoproducto
        })
    }

    showModalNuevoProducto = async () => {
        await this.setState({
           modalnuevoproducto:true
        })
    }

    cerrarModalNuevoProducto =  (nuevoproducto) => {
         this.setState({
            modalnuevoproducto:false,
         })
         
        if(nuevoproducto != '0'){
         this.showModal(nuevoproducto)
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalnuevoproducto == true) ? (
                <ModalFormularioNuevoProductoRequisicion titulomodal={"Nuevo agroquímico"} cerrarModal={this.cerrarModalNuevoProducto}
                    show={this.state.modalnuevoproducto} accion={1} listaunidadmedida={this.state.listaunidadmedida} setLoadingSpinner={this.setLoadingSpinner}
                    cantidadproductosnuevos={this.props.listaproductosnuevos.length}/>
                ) : null}
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Buscar agroquímicos</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col-4 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Familia</label>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                   onChange={(event) => this.filtrarFamilia(event.target.value)}>
                                                    {this.state.filtrofamilia == "TodasLasFamilias" ?
                                                        <option selected="true" value='TodasLasFamilias'>Todos</option> : <option value='TodasLasFamilias'>Todos</option>}
                                                    {this.state.listafamiliaproductos.map((i, index) => (
                                                        <option value={i.idfamilia_productos} key={"tipo_" + index}  >{i.familia} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarTipoProducto(event.target.value)}>
                                                    {this.state.filtrotipoproducto == "TodasLosProductos" ?
                                                        <option selected="true" value='TodasLosProductos'>Todos</option> : <option value='TodasLosProductos'>Todos</option>}
                                                    {this.state.listatipoproductosfiltrados.map((i, index) => (
                                                        <option value={i.idtipo_producto} key={"tipo_" + index}  >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-sm-12 col-md-12 col-lg-12 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                placeholder="Buscar clave/nombre"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='row'>
                                    {<div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                        <div className='btnNuevoProducto' >
                                        <Button className="btnAceptarModal"  onClick={() => this.showModalNuevoProducto()} >Nuevo</Button>
                                        </div>
                                    </div>}
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTabla tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                    {
                                                        this.state.listaproductosfiltrados.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)} className={ this.props.listaproductosagregados.find(producto => producto.idproducto == item.idcatalogoproducto) != undefined ? "trFondoInactivoGray" : this.state.idproductoseleccionado == item.idcatalogoproducto ? "trFondoActivoGreen" : ""}>
                                                              
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.nombrecomercial}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.familiaproducto}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tipoproducto}
                                                                </td>
                                                                {/*<td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.descripcion}
                                                                </td>*/}

                                                            </tr>
                                                        ))

                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>


                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

