import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
//--import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estilosModal } from '../../../Services/PaletaDeColores';

import { Table } from 'reactstrap';


export default class ModalConfirmarMoverProductos extends React.Component {

    constructor(props) {
        super(props)     

        this.state = {
            show: props.show,
            titulo: "Confirmar movimiento de productos ",
            listaproductos: [
                {
                    cantidad: 1,
                    producto: "Productos generales"
                },
                {
                    cantidad: 2,
                    producto: "Equipos"
                },
            ],
            estilosmodal: estilosModal(),
        }
    }

    showModal = (opcion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarmodal({ 
                origen: "modalconfirmarmoverproductos", 
                respuesta: opcion });
        }, 200);
    }

    seleccionarOperacion = async (item) => {
        this.setState({ idoperacionseleccionada: item.idopcion })
        this.setState({ idseleccionado: item.idopcion })
    }

    render() {
        return (

            <>
                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div>
                            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
                        </div>

                        <br />
                        <label ><b >Destino: {this.props.almacen}</b ></label >

                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                                    <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                                        <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                                            <Table hover>
                                                <thead>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th style={{ textAlign: 'center' }}>Cantidad</th>
                                                        {(this.props.tipoalmacendestino == 2) ? (
                                                            <th style={{ textAlign: 'center' }}>Equipo</th>
                                                        ) : (null)}
                                                         {(this.props.tipoalmacendestino == 6) ? (
                                                            <th style={{ textAlign: 'center' }}>Equipo</th>
                                                        ) : (null)}
                                                        {(this.props.tipoalmacendestino == 3 || this.props.tipoalmacendestino == 5) ? (
                                                            <th style={{ textAlign: 'center' }}>Activo fijo</th>
                                                        ) : (null)}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.props.contenido.objeto.productos
                                                            .map((item, index) => (
                                                                <tr key={"tabla" + index} >
                                                                    <td className="letraNormal " style={{ color: this.state.colorcontenidotabla, }}>
                                                                        {item.producto}
                                                                    </td>
                                                                    <td className="letraNormal " style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                                                        {item.cantidad} ({item.unidadmedida})
                                                                    </td>
                                                                    {(this.props.tipoalmacendestino == 2 || this.props.tipoalmacendestino==6) ? (
                                                                        <td className="letraNormal " style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                                                            {item.equipo}
                                                                        </td>
                                                                    ) : (null)}
                                                                    {(this.props.tipoalmacendestino == 3 || this.props.tipoalmacendestino == 5 ) ? (
                                                                        <td className="letraNormal " style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                                                            {(item.activo==true)?("Sí"):("No")}
                                                                        </td>
                                                                    ) : (null)}
                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="divBtnContenedorModal spacePaddingBottom" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal"

                                        onClick={() => this.showModal('10')}>
                                        Continuar
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </ModalBody>

                </Modal >
            </>
        )
    }
}
