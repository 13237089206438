import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioMaquinarias.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {
  validarLetrasNumerosLongitud, validarSoloLetrasNumeros, validarLetrasNumerosEspaciosGuionesProducto,
  validarNumeros, validarLetrasNumerosPuntoComa
} from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { guardarMaquinaria } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

const sinseleccion = -1

export default class ModalFormularioMaquinarias extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      loadingspinners: false,

      txtnombreclave: (props.accion == 2) ? (props.item.nombreclave) : '',
      txtmarca: (props.accion == 2) ? (props.item.marca) : '',
      txtmodelo: (props.accion == 2) ? (props.item.modelo) : '',
      txtnoserie: (props.accion == 2) ? (props.item.noserie) : '',
      txtcolor: (props.accion == 2) ? (props.item.color) : '',
      txtplaca: (props.accion == 2) ? (props.item.placa) : '',
      txtnocilindros: (props.accion == 2) ? (props.item.nocilindros) : '',
      txtcapacidadtotal: (props.accion == 2) ? (props.item.capacidad) : '',
      txtobservaciones: (props.accion == 2) ? (props.item.descripcion) : '',

      txttipo: (props.accion == 2) ? (props.item.idtipomaquinaria) : '',
      txtcombustible: (props.accion == 2) ? (props.item.idcombustible) : '',
      txtzona: (props.accion == 2) ? (props.item.idzona) : '',
      txtrancho: (props.accion == 2) ? (props.item.idrancho_area) : '',


      listatipo: props.listatipomaquinarias,
      listacombustibles: props.listacombustible,
      listazona: props.listazonas,
      listarancho: props.listaranchos,
      listaareas: props.listaareas,
      arrayestatus: props.estatus,

      idestatus: 1,

      vermsgerrortxtnombreclave: false,
      msgerrortxtnombreclave: "",
      vermsgerrortxtmarca: false,
      msgerrortxtmarca: "",
      vermsgerrortxtmodelo: false,
      msgerrortxtmodelo: "",
      vermsgerrortxtnoserie: false,
      msgerrortxtnoserie: "",
      vermsgerrortxtcolor: false,
      msgerrortxtcolor: "",
      vermsgerrortxtplaca: false,
      msgerrortxtplaca: "",
      vermsgerrortxtnocilindros: false,
      msgerrortxtnocilindros: "",
      vermsgerrortxtcapacidadtotal: false,
      msgerrortxtcapacidadtotal: "",
      vermsgerrortxtobservaciones: false,
      msgerrortxtobservaciones: "",

      vermsgerrortxttipo: false,
      msgerrortxttipo: "",
      vermsgerrortxtcombustible: false,
      msgerrortxtcombustible: "",
      vermsgerrortxtzona: false,
      msgerrortxtzona: "",
      vermsgerrortxtrancho: false,
      msgerrortxtrancho: "",

      idarea: (props.accion == 2) ? (props.item.idrancho_area) : '',
      vermsgerroridarea: false,
      msgerroridarea: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,
      listatipocultivo: props.listatipocultivos,

      iddestino: (props.accion == 2) ? (
        (props.item.idtipo_destino==2)?(0):(props.item.idtipo_destino)        
        ) : sinseleccion,
      vermsgerroriddestino: false,
      msgerroriddestino: "",
      listadestinos: [
        {
          valor: 0,
          nombre: 'Tipo Área'
        },
        {
          valor: 1,
          nombre: 'Tipo Rancho'
        }
      ]
    }

  }
  async componentDidMount() {
    this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
  }

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera)
  }

  showModal = (accion) => {
    this.setState({ show: false })
    setTimeout(() => {
      this.props.cerrarModal(accion)
    }, 200)

  }

  escribir = (prop) => (event) => {
    if (prop == 'txtzona' || prop == 'iddestino') {
      this.setState({ txtzona:"", txtrancho: "", idarea:"" })
    }
    this.setState({ [prop]: event.target.value })
  }

  cambiarValor = (id) => {
    var array = this.state.arrayestatus;
    array.forEach(function (item) {
      item.activo = false;
      if (item.idestatus == id) {
        item.activo = true;
      }
    })
    this.setState({ idestatus: id })
  }

  guardarCambios = async () => {
    this.setState({ vermsgerrortxtnombreclave: false })
    this.setState({ vermsgerrortxtmarca: false })
    this.setState({ vermsgerrortxtmodelo: false })
    this.setState({ vermsgerrortxtnoserie: false })
    this.setState({ vermsgerrortxtcolor: false })
    this.setState({ vermsgerrortxtplaca: false })
    this.setState({ vermsgerrortxtnocilindros: false })
    this.setState({ vermsgerrortxtcapacidadtotal: false })
    this.setState({ vermsgerrortxtobservaciones: false })
    this.setState({ vermsgerrortxttipo: false })
    this.setState({ vermsgerrortxtcombustible: false })
    this.setState({ vermsgerrortxtzona: false })
    this.setState({ msgerrortxtrancho: false })
    this.setState({ vermsgerroridarea: false })
    this.setState({ vermsgerroriddestino: false })

    let validacionescorrectas = true

    if (this.state.txtobservaciones.length > 0) {
      let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
      let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtobservaciones.trim(), longitudvalida);
      if (descorrecto.codigo == "invalid") {
        this.setState({ msgerrortxtobservaciones: descorrecto.mensaje })
        this.setState({ vermsgerrortxtobservaciones: true })
        validacionescorrectas = false;
      }
    }

    if (this.state.txttipo < 1) {
      validacionescorrectas = false;
      this.setState({ msgerrortxttipo: "Seleccione un tipo de maquinaria" })
      this.setState({ vermsgerrortxttipo: true })
    }

    if (this.state.txtcombustible < 1) {
      validacionescorrectas = false;
      this.setState({ msgerrortxtcombustible: "Seleccione un tipo de combustible" })
      this.setState({ vermsgerrortxtcombustible: true })
    }

    if (![0, 1, '0', '1'].includes(this.state.iddestino)) {
      validacionescorrectas = false;
      this.setState({ msgerroriddestino: "Seleccione un tipo de destino" })
      this.setState({ vermsgerroriddestino: true })
    } else if (this.state.iddestino == 0) {
      if (this.state.idarea < 1) {
        validacionescorrectas = false;
        this.setState({ msgerroridarea: "Seleccione un área" })
        this.setState({ vermsgerroridarea: true })
      }
    } else if (this.state.iddestino == 1) {
      if (this.state.txtzona < 1) {
        validacionescorrectas = false;
        this.setState({ msgerrortxtzona: "Seleccione una zona" })
        this.setState({ vermsgerrortxtzona: true })
      }
      if (this.state.txtrancho < 1) {
        validacionescorrectas = false;
        this.setState({ msgerrortxtrancho: "Seleccione un rancho" })
        this.setState({ vermsgerrortxtrancho: true })
      }
    }

    let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
    let valmensaje = validarLetrasNumerosLongitud(this.state.txtnombreclave.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtnombreclave: valmensaje.mensaje })
      this.setState({ vermsgerrortxtnombreclave: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 200 };
    valmensaje = validarLetrasNumerosLongitud(this.state.txtmarca.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtmarca: valmensaje.mensaje })
      this.setState({ vermsgerrortxtmarca: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 50 };
    valmensaje = validarLetrasNumerosLongitud(this.state.txtmodelo.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtmodelo: valmensaje.mensaje })
      this.setState({ vermsgerrortxtmodelo: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 100 };
    valmensaje = validarSoloLetrasNumeros(this.state.txtnoserie.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtnoserie: valmensaje.mensaje })
      this.setState({ vermsgerrortxtnoserie: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 20 };
    valmensaje = validarLetrasNumerosLongitud(this.state.txtcolor.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtcolor: valmensaje.mensaje })
      this.setState({ vermsgerrortxtcolor: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 20 };
    valmensaje = validarLetrasNumerosEspaciosGuionesProducto(this.state.txtplaca.trim(), longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtplaca: valmensaje.mensaje })
      this.setState({ vermsgerrortxtplaca: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 2 };
    valmensaje = validarNumeros(this.state.txtnocilindros, longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtnocilindros: valmensaje.mensaje })
      this.setState({ vermsgerrortxtnocilindros: true })
      validacionescorrectas = false;
    }

    longitudvalida = { longitudminima: 0, longitudmaxima: 5 };
    valmensaje = validarNumeros(this.state.txtcapacidadtotal, longitudvalida);
    if (valmensaje.codigo == "invalid") {
      this.setState({ msgerrortxtcapacidadtotal: valmensaje.mensaje })
      this.setState({ vermsgerrortxtcapacidadtotal: true })
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      this.setLoadingSpinner(true);
      let json = {
        "idmaquinaria": this.state.accion == 1 ? ("0") : (this.props.item.idmaquinaria),
        "idtipomaquinaria": this.state.txttipo,
        "nombreclave": this.state.txtnombreclave,
        "marca": this.state.txtmarca,
        "modelo": this.state.txtmodelo,
        "no_serie": this.state.txtnoserie,
        "color": this.state.txtcolor,
        "placas": this.state.txtplaca,
        "no_cilindros": this.state.txtnocilindros == '' ? null : this.state.txtnocilindros,
        "capacidad": this.state.txtcapacidadtotal == '' ? null : this.state.txtcapacidadtotal,
        "idcombustible": this.state.txtcombustible,

        "idrancho_area": (this.state.iddestino == 1) ? this.state.txtrancho : this.state.idarea,
        "idtipo_destino": (this.state.iddestino == 0) ? 2 : this.state.iddestino,


        "descripcion": this.state.txtobservaciones,
        "idestatus": this.state.idestatus
      }



      var resp = await guardarMaquinaria(json);

      if(resp.code == 0){
        if (this.state.accion == 1) {
            this.showModal('1')
        }
        else if (this.state.accion == 2) {
            this.showModal('2')
        }
      }
      else if(resp.code == 200){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 300){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if(resp.code == 400){
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      }
      else if (resp.code == 1000) {
          this.setLoadingSpinner(false);
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          return;
      }else{
          this.setLoadingSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    }
  }

  render() {

    return (

      <>
      

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre / Clave</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnombreclave} onChange={this.escribir("txtnombreclave")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombreclave}</label>}

                {this.state.vermsgerrortxtnombreclave ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombreclave}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Marca</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtmarca} onChange={this.escribir("txtmarca")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmarca}</label>}

                {this.state.vermsgerrortxtmarca ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmarca}</p> : null}

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Modelo</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtmodelo} onChange={this.escribir("txtmodelo")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmodelo}</label>}

                {this.state.vermsgerrortxtmodelo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmodelo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. serie</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnoserie} onChange={this.escribir("txtnoserie")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnoserie}</label>}

                {this.state.vermsgerrortxtnoserie ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnoserie}</p> : null}

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Color</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtcolor} onChange={this.escribir("txtcolor")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcolor}</label>}

                {this.state.vermsgerrortxtcolor ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcolor}</p> : null}

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Placa</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtplaca} onChange={this.escribir("txtplaca")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtplaca}</label>}

                {this.state.vermsgerrortxtplaca ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtplaca}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. cilindros</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnocilindros} onChange={this.escribir("txtnocilindros")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnocilindros}</label>}

                {this.state.vermsgerrortxtnocilindros ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnocilindros}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Capacidad total</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtcapacidadtotal} onChange={this.escribir("txtcapacidadtotal")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcapacidadtotal}</label>}

                {this.state.vermsgerrortxtcapacidadtotal ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcapacidadtotal}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo</p>
                {this.state.accion == 2 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={this.escribir("txttipo")}>
                          <option disabled="disabled">Seleccionar</option>
                          {this.state.listatipo != undefined ? this.state.listatipo.map((i, index) => (
                            <option value={i.idtipomaquinaria} key={"tipo_" + index} selected={i.idtipomaquinaria == this.state.txttipo ? true : false} >{i.tipo} </option>
                          )) : null}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <div className='col lblSubTitulosModal '>
                  <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                    <div className="selectBox ">
                      <select className="cbxTexto letraNormal" onChange={this.escribir("txttipo")}>
                        <option disabled="disabled" selected="true">Seleccionar</option>
                        {this.state.listatipo != undefined ? this.state.listatipo.map((i, index) => (
                          <option value={i.idtipomaquinaria} key={"tipo_" + index}>{i.tipo}</option>
                        )) : null}
                      </select>
                    </div>
                  </div>
                </div>}

                {this.state.vermsgerrortxttipo ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttipo}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Combustible</p>
                {this.state.accion == 2 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={this.escribir("txtcombustible")}>
                          <option disabled="disabled">Seleccionar</option>
                          {this.state.listacombustibles != undefined ? this.state.listacombustibles.map((i, index) => (
                            <option value={i.idtipocombustible} key={"tipo_" + index} selected={i.idtipocombustible == this.state.txtcombustible ? true : false} >{i.tipo} </option>
                          )) : null}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <div className='col lblSubTitulosModal '>
                  <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                    <div className="selectBox ">
                      <select className="cbxTexto letraNormal" onChange={this.escribir("txtcombustible")}>
                        <option disabled="disabled" selected="true">Seleccionar</option>
                        {this.state.listacombustibles != undefined ? this.state.listacombustibles.map((i, index) => (
                          <option value={i.idtipocombustible} key={"tipo_" + index}>{i.tipo}</option>
                        )) : null}
                      </select>
                    </div>
                  </div>
                </div>}

                {this.state.vermsgerrortxtcombustible ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcombustible}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ubicación</p>
                {this.state.accion == 2 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal" onChange={this.escribir('iddestino')}>
                          <option disabled="disabled">Seleccionar</option>
                          {this.state.listadestinos != undefined ? this.state.listadestinos.map((i, index) => (
                            <option value={i.valor} key={"tipo_" + index} selected={i.valor == this.state.iddestino} >{i.nombre} </option>
                          )) : null}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : <div className='col lblSubTitulosModal '>
                  <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                    <div className="selectBox ">
                      <select className="cbxTexto letraNormal" onChange={this.escribir('iddestino')}>
                        <option disabled="disabled" selected="true">Seleccionar</option>
                        {this.state.listadestinos != undefined ? this.state.listadestinos.map((i, index) => (
                          <option value={i.valor} key={"tipo_" + index} selected={i.valor == this.state.iddestino} >{i.nombre}</option>
                        )) : null}
                      </select>
                    </div>
                  </div>
                </div>}

                {this.state.vermsgerroriddestino ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerroriddestino}</p> : null}


                {(this.state.iddestino == 0) ?
                  <>
                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área</p>
                    {this.state.accion == 2 ? (
                      <div className='col lblSubTitulosModal '>
                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                          <div className="selectBox ">
                            <select className="cbxTexto letraNormal" onChange={this.escribir("idarea")}>
                              <option disabled="disabled" selected={this.state.idarea==""}>Seleccionar</option>
                              {this.state.listaareas != undefined ? this.state.listaareas.map((i, index) => (
                                <option value={i.idcatalogo_areas} key={"tipo_" + index} selected={i.idcatalogo_areas == this.state.idarea} >{i.area} </option>
                              )) : null}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : <div className='col lblSubTitulosModal '>
                      <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                        <div className="selectBox ">
                          <select className="cbxTexto letraNormal" onChange={this.escribir("idarea")}>
                            <option disabled="disabled" selected={['', -1].includes(this.state.idarea)}>Seleccionar</option>
                            {this.state.listaareas != undefined ? this.state.listaareas.map((i, index) => (
                              <option value={i.idcatalogo_areas} key={"tipo_" + index} selected={i.idcatalogo_areas == this.state.txtrancho}>{i.area}</option>
                            )) : null}
                          </select>
                        </div>
                      </div>
                    </div>}

                    {this.state.vermsgerroridarea ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerroridarea}</p> : null}

                  </> :
                  (this.state.iddestino == 1) ?
                    <><p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                      {this.state.accion == 2 ? (
                        <div className='col lblSubTitulosModal '>
                          <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                            <div className="selectBox ">
                              <select className="cbxTexto letraNormal" onChange={this.escribir("txtzona")}>
                                <option disabled="disabled" selected={this.state.txtzona==""}>Seleccionar</option>
                                {this.state.listazona != undefined ? this.state.listazona.map((i, index) => (
                                  <option value={i.idcatalogo_zona} key={"tipo_" + index} 
                                  selected={i.idcatalogo_zona == this.state.txtzona ? true : false} >
                                    {i.zona} 
                                    </option>
                                )) : null}
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : <div className='col lblSubTitulosModal '>
                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                          <div className="selectBox ">
                            <select className="cbxTexto letraNormal" onChange={this.escribir("txtzona")}>
                              <option disabled="disabled" selected="true">Seleccionar</option>
                              {this.state.listazona != undefined ? this.state.listazona.map((i, index) => (
                                <option value={i.idcatalogo_zona} key={"tipo_" + index}>{i.zona}  </option>
                              )) : null}
                            </select>
                          </div>
                        </div>
                      </div>}

                      {this.state.vermsgerrortxtzona ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtzona}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                      {this.state.accion == 2 ? (
                        <div className='col lblSubTitulosModal '>
                          <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                            <div className="selectBox ">
                              <select className="cbxTexto letraNormal" onChange={this.escribir("txtrancho")}>
                                <option disabled="disabled" selected={this.state.txtrancho == 0}>
                                  {this.state.txtzona < 1 ? 'Seleccionar zona' : 'Seleccionar'}</option>
                                {this.state.listarancho == undefined || this.state.listarancho.length == 0 ? null : this.state.listarancho.map((i) => (
                                  this.state.txtzona == i.idcatalogo_zona ?
                                    <option
                                      value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}
                                      selected={i.idcatalogo_rancho == this.state.txtrancho}
                                    >{i.rancho} </option> : null
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : <div className='col lblSubTitulosModal '>
                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                          <div className='selectBox'>
                            <select className='cbxTexto letraNormal' onChange={this.escribir('txtrancho')}>
                              <option disabled="disabled" selected={['', -1].includes(this.state.txtrancho)}>{this.state.txtzona < 1 ? 'Seleccionar zona' : 'Seleccionar'}</option>
                              {this.state.listarancho == undefined || this.state.listarancho.length == 0 ? null : this.state.listarancho
                                .filter(e => e.idcatalogo_zona == this.state.txtzona)
                                .map((i) => (
                                  <option value={i.idcatalogo_rancho} key={'tipo_' + i.idcatalogo_rancho} selected={i.idcatalogo_rancho == this.state.txtrancho}>
                                    {i.rancho} 
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>}

                      {this.state.vermsgerrortxtrancho ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrancho}</p> : null}
                    </> : null
                }


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Observaciones</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtobservaciones} onChange={this.escribir("txtobservaciones")}></input>
                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtobservaciones}</label>}

                {this.state.vermsgerrortxtobservaciones ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtobservaciones}</p> : null}


                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                {this.state.accion == 2 || this.state.accion == 1 ? (
                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarValor(event.target.value)}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) :
                  <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                }
              </div>
              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

