import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { ImDownload3 } from 'react-icons/im'
import ModalBuscarVentas from './ModalBuscarVentas';
import { buscarFacturasVentaSeleccionadaPagoGlobal, getDetallesVentaPagoGlobal, guardarPagoGlobalVentas } from '../../../Services/Api';
import { formatoNumero2 } from '../../../Services/Validaciones';

export default class ModalFormularioGuardarPagoGlobal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: false,
            
            estilosmodal: estilosModal(),

            tabla: {
                encabezadosCompras: [
                    "ID venta",
                    "Subtotal",
                    "Descuento",
                    "Total",
                    ""
                ],
                encabezadosCompras2: [
                    "ID venta",
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "10px"
                ],
                dimensiones2: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "20%",
                    "5%"
                ],
                mindimensiones2: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "150px",
                    "10px"
                ]
            },

            tablaVentasRelacionadas: {
                subtotal: 0.00,
                descuento: 0.00,
                total: 0.00,
                listaventas: []
            },

            listafacturasventas: [],
            listafacturasventasSeleccionadas: [],
            idslistafacturasventasSeleccionadas: [],

        }

    }
    
    async componentDidMount() {
        if(this.props.accion==9){
            this.setLoadingSpinner(true)
            let request = {
                idventa: this.props.idventa,
            }

            var resp = await getDetallesVentaPagoGlobal(request)
            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false)
                return;
            }else if (resp.code == 200 || resp.code == 300) {
                this.setLoadingSpinner(false)
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }else{
                var auxlistaVentas = this.state.tablaVentasRelacionadas
                auxlistaVentas.listaventas = resp.data.ventasrelacionadas

                let totalesCalculados = this.calcularTotales(auxlistaVentas.listaventas)

                auxlistaVentas.subtotal = totalesCalculados.subtotal;
                auxlistaVentas.descuento = totalesCalculados.descuento;
                auxlistaVentas.total = totalesCalculados.total;

                var auxlistaFacturas = resp.data.facturas_relacionadas

                setTimeout(() => {
                    this.setState({
                        tablaVentasRelacionadas: auxlistaVentas,
                        listafacturasventas: auxlistaFacturas
                    });
                }, 0);

                this.setLoadingSpinner(false)
            }
        }
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    cerrarModal = async (item) => {
        if(item!=0){

            var auxVentasSeleccionada = [];
            var agregarVentas = false;
            //validamos que exista la venta en la tabla
            item.map((ventaSeleccionada)=>{
                var indexVenta = this.state.tablaVentasRelacionadas.listaventas.findIndex((venta)=>venta.idventa==ventaSeleccionada.idventa)
                if(indexVenta > -1){
                    toast.info("La venta con ID "+ventaSeleccionada.idventa+" ya está en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                }else{
                    auxVentasSeleccionada.push(ventaSeleccionada)
                    agregarVentas = true;
                }
            })

            if(agregarVentas){
                this.setLoadingSpinner(true)
                var auxlistaVentas = this.state.tablaVentasRelacionadas
                auxlistaVentas.listaventas = auxlistaVentas.listaventas.concat(auxVentasSeleccionada)

                let totalesCalculados = this.calcularTotales(auxlistaVentas.listaventas)

                auxlistaVentas.subtotal = totalesCalculados.subtotal;
                auxlistaVentas.descuento = totalesCalculados.descuento;
                auxlistaVentas.total = totalesCalculados.total;

                var resp = await this.getFacturasVentas(auxlistaVentas.listaventas)

                setTimeout(() => {
                    this.setState({
                        tablaVentasRelacionadas: auxlistaVentas,
                        listafacturasventas: resp
                    });
                }, 0);

                this.setLoadingSpinner(false)
                
            }
        }
        this.setState({ modalbuscarcompra: !this.state.modalbuscarcompra })
    }

    getFacturasVentas = async (listaventas) => {
        var facturas = [];

        for (let index = 0; index < listaventas.length; index++){
            var venta = listaventas[index];
            var resp = await buscarFacturasVentaSeleccionadaPagoGlobal({idventa: venta.idventa});

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }else if (resp.code == 200 || resp.code == 300) {
                toast.info("No encontro facturas para la venta con ID "+venta.idventa, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else if (resp.code == 400) {
                toast.error("Error.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else {
                if (resp.data.length>0) {
                    facturas = facturas.concat(resp.data)
                }
            }
        }
        
        return facturas;
    }

    verModalDetalles = () => {
        this.setState({ modalbuscarcompra: true })
    }

    eliminarVenta = async (venta) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.tablaVentasRelacionadas;
        this.state.tablaVentasRelacionadas.listaventas = aux.listaventas.filter((item) => !(item.idventa == venta.idventa));
        
        //se elimina la factura de los seleccionados
        var auxFacturasRemove = this.state.listafacturasventasSeleccionadas.filter((f)=> f.idventa==venta.idventa)
        auxFacturasRemove.map((f)=>{
            this.seleccionarFactura(f)
        })

        //se elimina de la tabla facturas
        var auxFacturas = this.state.listafacturasventas;
        this.state.listafacturasventas = auxFacturas.filter((item) => !(item.idventa == venta.idventa));

        let totalesCalculados = this.calcularTotales( this.state.tablaVentasRelacionadas.listaventas)

        this.state.tablaVentasRelacionadas.subtotal = totalesCalculados.subtotal;
        this.state.tablaVentasRelacionadas.descuento = totalesCalculados.descuento;
        this.state.tablaVentasRelacionadas.total = totalesCalculados.total;

        var aux = this.state.tablaVentasRelacionadas;
        var aux2 = this.state.listafacturasventas
        await this.setState({ 
            tablaVentasRelacionadas: aux,
            listafacturasventas: aux2
        })
    }

    calcularTotales (listaventas) {
        var total = 0, descuento = 0, subtotal = 0;
        listaventas.map((compra)=>{
            subtotal = parseFloat(subtotal) + parseFloat(compra.precio);
            if(compra.descuento!=undefined){
                descuento = parseFloat(descuento) + parseFloat(compra.descuento);
                total = parseFloat(total) + (parseFloat(compra.precio)-parseFloat(compra.descuento))
            }else{
                total = parseFloat(total) + (parseFloat(compra.precio))
            }
            
        })
        let calculados = {
            total: total,
            subtotal: subtotal,
            descuento: descuento
        }

        return calculados;
    }

    setFormatDateSave = (fecha) => {
        var fechaseteada = "";
        if(fecha!=null){
            var [year, month, day] = fecha.split("-");
        
            if(day!=undefined && month!=undefined && year!=undefined){
                fechaseteada = `${day}/${month}/${year}`
                return fechaseteada;
            }else{
                fechaseteada = fecha;
            }
        }
           
        return fechaseteada;
    }

    guardarCambios = async () => {
        let request = {
            idventa: this.props.idventa,
            ventasrelacionadas: [],
            facturasrelacionadas: []
        }

        var auxVentasSave = [];
        this.state.tablaVentasRelacionadas.listaventas.map((venta)=>{
            let compraAux = {
                idventa: venta.idventa
            }
            auxVentasSave.push(compraAux)
        })

        var auxFacturasSeve = [];
        this.state.listafacturasventasSeleccionadas.map((factura)=>{
            let facturaAux = {
                idfactura: factura.idfactura
            }
            auxFacturasSeve.push(facturaAux)
        })

        request.ventasrelacionadas = auxVentasSave;
        request.facturasrelacionadas = auxFacturasSeve;

        this.setLoadingSpinner(true)
        var resp = await guardarPagoGlobalVentas(request)

        if(resp.code == 0){
            if(this.props.accion  == 3){
                toast.success("Pago global creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }else if(this.props.accion == 9){
                toast.success("Pago global editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.showModal({accion: 3, resp: resp})
        }
        else if(resp.code == 200 || resp.code == 300){
            this.setLoadingSpinner(false)
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if(resp.code == 400){
            this.setLoadingSpinner(false)
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return;
        }
        else if (resp.code == 1000) {
            this.setLoadingSpinner(false)
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            return;
        }

    }

    seleccionarFactura = async (item) => {
        if(this.state.idslistafacturasventasSeleccionadas.indexOf(item.idfactura)!=-1){
            this.state.idslistafacturasventasSeleccionadas.splice(this.state.idslistafacturasventasSeleccionadas.indexOf(item.idfactura),1);
            var auxFacturas = this.state.listafacturasventasSeleccionadas.filter((compra) => !(compra.idfactura==item.idfactura));
            await this.setState({listafacturasventasSeleccionadas: auxFacturas})
        }else{
            this.state.idslistafacturasventasSeleccionadas.push(item.idfactura);
            this.state.listafacturasventasSeleccionadas.push(item);
            var auxFacturas = this.state.listafacturasventasSeleccionadas;
            await this.setState({listafacturasventasSeleccionadas: auxFacturas})
        }
  
        await this.setState({})
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalbuscarcompra == true) ? (
                    <ModalBuscarVentas 
                        titulomodal={"Buscar ventas"}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalbuscarcompra}
                        accion={this.state.accionmodal}
                        listaventas={this.props.listaventas}
                        idventa={this.props.idventa}
                    />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({accion: 3, resp: undefined})} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <br />
                            <br />
                            <div className="container-fluid containerTabla " style={{ background: '#EBF9FF', borderRadius: '10px'}}>
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla ">

                                        <div className='row' style={{ marginTop: '20px' }}>
                                            <div className='col'>
                                                <label className="lblEncabezadosTabla letraTitulos"><strong>Ventas relacionadas:</strong></label>
                                            </div>
                                            <div className='col-3'>
                                                <Button className='btnAceptarModal' style={{position: 'relative'}}
                                                onClick={() => this.verModalDetalles()}>
                                                    {"+ Agregar"}
                                                </Button>
                                            </div>
                                        </div>
                                        
                                        <Table hover>
                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.tabla.encabezadosCompras.map((titulo, index) => (
                                                        <th
                                                            className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                {
                                                    this.state.tablaVentasRelacionadas.listaventas.map((item, index) => (
                                                        <tr  style={{ borderTop: 'solid 2px black'}} >
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idventa}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${formatoNumero2(item.precio)}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${item.descuento ? formatoNumero2(item.descuento) : 0}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${(item.descuento ? formatoNumero2(item.precio-item.descuento) : formatoNumero2(item.precio))}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarVenta(item)}>
                                                                    <FaTrashAlt className="icoBotonesTrash iconbtneliminar"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                
                                                <tr style={{ borderTop: 'solid 2px black'}} >
                                                    <td className="containerSubTablaAA" style={{ color: this.state.colorcontenidotabla }}>
                                                        <b>Totales acumulados</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${ formatoNumero2(this.state.tablaVentasRelacionadas.subtotal)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaVentasRelacionadas.descuento)}</b>
                                                    </td>
                                                    <td className="containerSubTablaAA " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                        <b>${formatoNumero2(this.state.tablaVentasRelacionadas.total)}</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />
                            
                            <div className="container-fluid containerTabla " style={{ background: '#EBF9FF', borderRadius: '10px'}}>
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla ">

                                        <div className='row' style={{ marginTop: '20px' }}>
                                            <div className='col'>
                                                <label className="lblEncabezadosTabla letraTitulos"><strong>Selecciona las facturas que aplican para este pago:</strong></label>
                                            </div>
                                        </div>
                                        
                                        <Table hover>
                                            <thead style={{ verticalAlign: "bottom" }}>
                                                <tr>
                                                    {this.state.tabla.encabezadosCompras2.map((titulo, index) => (
                                                        <th
                                                            className={(index > 1) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones2[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                {
                                                    this.state.listafacturasventas.map((item, index) => (
                                                        <tr style={{ borderTop: 'solid 2px black'}} onClick={() => this.seleccionarFactura(item)} className={this.state.idslistafacturasventasSeleccionadas.indexOf(item.idfactura) != -1 ? "trFondoActivoGreen" : ""}>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idventa}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.idfactura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.fechafactura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla " style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                                                                ${formatoNumero2(item.monto)}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                {item.factura}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                <a href={item.rutaFactura} download>
                                                                    <div className='divBotonesTrash'>
                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                    </div>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.accion == 3 || this.state.accion == 9 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal({accion: 3, resp: undefined})}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }

}
