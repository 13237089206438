import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from 'reactstrap';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { FaCalendar } from "react-icons/fa";
import { RiRefreshLine } from 'react-icons/ri';
import {getCatalogoCotizacionNaranja, getListaClientes } from '../../Services/Api';
import ModalFormularioCotizacionNaranja from '../../Complements/Modales/ModalFormularioCotizacionNaranja/ModalFormularioCotizacionNaranja';

export default class CatalogoCotizacionNaranja extends React.Component {
    constructor(props) {
    super(props);

    this.state = {
        salir: false,
        listaclientes:[],

        fecha: new Date(),
        fechabusquedainicio: new Date(),
        fechabusquedafin: new Date(),
        txtfechabusquedainicio: "",
        txtfechabusquedafin: "",

        txtbuscador:'',
        loadingspinners:true,

        modal:false,
        accionmodal: 1,
        titulomodal: '',
        itemseleccionado: null,
        modalconfirmaraccion:false,
        itemeliminar: undefined,
        tabla: {
            encabezados: [
                "Fecha",
                "Rancho",
                "Cliente",
                "Fletes",
                "Kilogramos",
                "Total",
                "Estatus",
                ""
            ],
            dimensiones: [
                "10%",
                "15%",
                "15%",
                "13%",
                "13%",//
                "5%",//
                "10%",//
                "12%"
            ],
            mindimensiones: [
                "90px",
                "90px",
                "80px",
                "80px",
                "90px",
                "90px",
                "90px",
                "90px"
            ],
            elementos: []
        },

        //paginacion
        activepage: 1,
        itemsperpage: 20,
        rangepages: 20,
        listapaginada: [],
        filtrolistapaginada: [],
        fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
        colortitulo: estiloFiltroEncabezados().colortitulos,
        colortipolbl: estiloComboBox().colorcombolbl,
        colorcbxtipolbl: estiloComboBox().colorcbxlbl,
        colorbordertipo: estiloComboBox().colorbordercombo,
        colorfondotipo: estiloComboBox().colorfondocombo,

        colorencabezadostabla: estiloTabla().colorencabezadostabla,
        colorcontenidotabla: estiloTabla().colorcontenidotabla,
        colorverlbl: estiloBtnVer().colorverlbl,
        borderver: estiloBtnVer().borderver,

        colorlupa: coloresTxtBuscador().colorlupa,
        fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
        fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
        colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
        bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


        fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
        colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
        borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
        colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
        fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
        borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

        btnfondoeditar: estiloBtnEditar().btnfondoeditar,
        btncoloreditar: estiloBtnEditar().btncoloreditar,
        btnbordereditar: estiloBtnEditar().btnbordereditar,

        btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
        btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
        btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,
    }
}


    async componentDidMount() {
        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }

    //obtiene lista de ranchos
    var respclientes = await getListaClientes();
    if ( this.manejadorLlamadasApis (respclientes) ) {
        return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = [];

    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

    await this.setState({
        txtfechabusquedainicio: defaultValueFechaInicial,
        txtfechabusquedafin: defaultValue,
        fechabusquedainicio: fechaactualIni,
        tabla: tabla,
        listaclientes:respclientes.data
    })

    setTimeout(() => {
        this.consultarCatalogoCotizacionNaranja()
        this.dimensiones();
        window.addEventListener("resize", this.dimensiones);
        this.setState({ loadingspinners: false })
    }, 300);
  }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    consultarCatalogoCotizacionNaranja = async (llave, valor) => {
        var formatofechaini = "";
        var formatofechafin = "";
        var fechainiaux = 'txtfechabusquedainicio' == llave ? valor : this.state.txtfechabusquedainicio
        var fechafinaux = 'txtfechabusquedafin' == llave ? valor : this.state.txtfechabusquedafin

        if (fechainiaux.length > 0) {
        var [year, month, day] = fechainiaux.split("-");
        formatofechaini = `${day}/${month}/${year}`
        }

        if (fechafinaux.length > 0) {
        var [year, month, day] = fechafinaux.split("-");
        formatofechafin = `${day}/${month}/${year}`
        }

        var requestbody = {
            fechainicio: fechainiaux,
            fechafin: fechafinaux,
        }
        
        var fechasvalidas = await this.validarFechas(formatofechaini, formatofechafin)
        
        if (fechasvalidas == 1) {
            this.setState({ loadingspinners: true })
            var respcotizacionnaranja = await getCatalogoCotizacionNaranja(requestbody);
            if (this.manejadorLlamadasApis(respcotizacionnaranja)) {
                return;
            }

            var tabla = this.state.tabla;
            tabla.elementos = respcotizacionnaranja.data;
            this.setState({
                tabla: tabla
            })
            //Fin
            this.cambiarContenido(1);
            this.setState({ loadingspinners: false })
        }

    }

    validarFechas = async (formatofechaini, formatofechafin) => {
        var fechainicio = new Date();
        var fechafin = new Date();
        var fechainiciovalido = false;
        var fechafinvalido = false;
        if (formatofechaini.length > 0) {
            fechainiciovalido = true;
            var fechacompraini = formatofechaini.split("/");
            fechainicio.setFullYear(fechacompraini[2], fechacompraini[1], fechacompraini[0]);
        }

        if (formatofechafin.length > 0) {
            fechafinvalido = true;
            var fechacomprafin = formatofechafin.split("/");
            fechafin.setFullYear(fechacomprafin[2], fechacomprafin[1], fechacomprafin[0]);
        }

        if (fechainiciovalido && fechafinvalido) {
            if (fechainicio <= fechafin) {
                return 1;
            }
            else {
                toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                var tabla = this.state.tabla;
                tabla.elementos = [];

                await this.setState({
                    tabla: tabla
                })
                this.cambairContenido(1);
                return 2;
            }
        }
        return 0;
    }

    cambiarContenido = (pagenumber) => {
        this.setState({
            activepage: pagenumber,
            filtrolistapaginada: [],
            listapaginada: []
        });
        setTimeout(() => {
            this.setState({
                activepage: pagenumber,
                listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
                //filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
            });
        }, 0);

    }

    actualizarTabla = async () => {
        this.setState({ loadingspinners: true, errordeacceso: false })
        this.componentDidMount();
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

        this.consultarCatalogoCotizacionNaranja(['txt' + item.llave], year + '-' + month + '-' + day)
    }

    verModal = async (ban) => {
        if (ban.opcion == 1) { //Nuevo
            this.setState({ titulomodal: 'Nueva cotización de naranja', accionmodal: 1, modal: true })
        }
        this.setState({ loadingspinners: false });
        this.actualizarTabla();
    }

    cerrarModal = (accion) => {
        this.setState({ modal: !this.state.modal, loadingspinners:false })
        if (accion == 1) {
            this.setState({loadingspinners:false})
            toast.success("Registro creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.componentDidMount();
            this.actualizarTabla();
        }
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    redireccionar = ( item ) => {
        localStorage.setItem("dato", JSON.stringify({ id: item }))
        this.setState({ redireccionando: true })
    }

    format_numero_decimales = (number) => {
        return new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2 }).format(number);
    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    format_kilogramos = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'unit', unit: 'kilogram',minimumFractionDigits: 2 }).format(number);
    }

    format_numero = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    }

    render() {
        return (

        <>

            {
            this.state.salir ?
                <>
                <SpinnerModal tipo="full" show={true} />
                <Acciones bandera={'salir'} />
                </>
                : null
            }

            <ToastContainer />
            {(this.state.redireccionando) ? 
                <Acciones bandera={"verDetalles"}/> 
            :null}

            {(this.state.loadingspinners == true) ? (
                <SpinnerModal 
                    tipo="full" 
                    show={this.state.loadingspinners}
                />
            ) : null}
            {(this.state.modal == true) ? 
                (<ModalFormularioCotizacionNaranja
                    show={this.state.modal}
                    titulomodal={this.state.titulomodal}
                    accion={this.state.accionmodal}
                    cerrarModal={this.cerrarModal}
                    listaclientes={this.state.listaclientes}
                    setloadingspinner={this.setLoadingSpinner}
                />)
            :null}

            <Navbar />
            <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <div className="container-fluid anchoMaximo">
                <div className='row paddingEncabezados align-items-end'>
                    <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                        <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Cotización de naranja</label>
                    </div>
                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                        <div className='' >
                            <div className='row contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, paddingRight: "5px" }}>

                                <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                    <label className='lblTexto letraTitulos ' style={{ color: this.state.colortipolbl, paddingLeft: "8px" }}>Fecha de:</label>
                                </div>

                                <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>

                                    <DatePicker
                                        selected={this.state.fechabusquedainicio}
                                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedainicio", valor: date })}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>

                                <div className='col-auto' style={{ margin: '0px', padding: '0px', textAlign: 'center' }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, textAlign: 'center' }}>a:</label>
                                </div>

                                <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                    <DatePicker
                                        selected={this.state.fechabusquedafin}
                                        onChange={(date) => this.cambiarFecha({ llave: "fechabusquedafin", valor: date })}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                        <button
                            className='btnActualizar letraNormal'
                            style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                            onClick={this.actualizarTabla}
                        >
                            <RiRefreshLine /> Refrescar lista
                        </button>
                    </div>

                    <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                        <button
                            className='btnNuevo letraNormal'
                            style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                            onClick={this.verModal.bind(this, { opcion: "1" })}
                        >
                            + Nuevo
                        </button>
                    </div>
                </div>
            </div>
            </div>

            {/* ////////////////////   Tabla   ////////////////////////////////////// */}

            <br />
            <div className="container-fluid anchoMaximo containerTabla">
            <div className="container-fluid anchoMaximo2 ">
                <div className="containerSubTabla">
                <Table>
                    <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                        {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                            {titulo}
                        </th>
                        ))}
                    </tr>
                    </thead>

    
                    <tbody style={{ verticalAlign: "bottom" }}>

                    {this.state.listapaginada.map((item, index) => (
                        <tr key={"tabla" + index}>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                                {item.fecha}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                                {item.rancho}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla }}>
                                {item.cliente}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla }}>
                                {item.flete}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla, textAlign: 'right', paddingRight:'40px' }}>
                                {this.format_kilogramos(item.kilogramos)}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], color: this.state.colorencabezadostabla, textAlign: 'right',paddingRight:'60px'  }}>
                                {this.format_moneda(item.total)}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], color: this.state.colorencabezadostabla }}>
                                {item.estatus}
                            </td>
                            <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }}>
                                <button
                                    className='btnEditar'
                                    style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                                    onClick={this.redireccionar.bind(this,   item.idcatalogo_cotizacion_naranja)} 
                                >
                                    Ver detalles
                                </button>
                            </td>
                        </tr>
                        ))
                    }
                    </tbody>
                </Table>
                </div>
            </div>
            </div>
            <div className="panelPaginationSection">
            <Paginacion
                activepage={this.state.activepage}
                itemscountperpage={this.state.itemsperpage}
                totalitemscount={this.state.tabla.elementos}
                pagerangedisplayed={this.state.rangepages}
                cambiarcontenido={(e) => this.cambiarContenido(e)}
            />
            </div>
        </>
        )
    }
}

function Acciones({ bandera }) {
    const history = useHistory();
    const location = useLocation();
    if (bandera == 'salir') {
      localStorage.clear();
      return (<Redirect from='/' to={"/"} />)
    }else if(bandera == "verDetalles"){
      history.push(location.pathname);
      return (<Redirect from='/' to={"/detallecotizacionnaranja"} />)
    }
  }
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
    >
        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));