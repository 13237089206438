import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import { FaCheck, FaCalendar } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsEye, BsTrash } from "react-icons/bs";
import { Table } from 'reactstrap';
import { ImDownload3 } from 'react-icons/im'

import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { estiloBtnMasInformacion, coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';

import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getProveedoresActivos, getComboTipoProveedor,
  getListaTipoCombustibles, getComboAreasDeTrabajo, getListaZonas, getListaRanchosDisponibles,
  getListaAlmacenesCombustible,
  getListaTipoMaquinarias, getListaMaquinarias, getCatalogoValesCombustible,
  cancelarValeCombustible
} from '../../Services/Api';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillCalendarFill } from "react-icons/bs";
import { formatoNumero2 } from '../../Services/Validaciones';
import ModalFormularioValeCombustible from '../../Complements/Modales/ModalFormularioValeCombustible/ModalFormularioValeCombustible';

import ModalVerImagen from '../../Complements/Modales/ModalFormularioCompras/ModalVerImagen';
import ModalConfirarAccion from '../../Complements/Modales/ModalConfirmarAccion/ModalConfirmarAccion';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)


export default class CatalogoValesCombustibles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      errordeacceso: false,

      txtfechacompraini: "",
      txtfechacomprafin: "",

      tabla: {
        encabezados: [
          "Fecha ",
          "Folio",
          "Proveedor",
          "Combustible",
          "Litros",
          "Importe",
          "Destino",
          "Vale",
          "Ticket",
          "En almacén",
          "Facturado",
          "",
        ],
        dimensiones: [
          "8%",
          "8%",
          "8%",
          "8%",
          "8%",
          "8%",
          "8%",
          "5%",
          "5%",
          "8%",
          "8%",
          "8%"
        ],
        mindimensiones: [
          "110px",
          "70px",
          "200px",
          "110px",
          "120px",
          "170px",
          "170px",
          "170px",
          "170px",
          "120px",
          "90px",
          "244px"
        ],
        elementos: []
      },
      accionmodal: 1,
      titulomodal: '',
      redireccionandoainventario: false,
      redireccionandoaalertas: false,
      modalmoveraalmacen: false,
      itemseleccionado: null,

      listaalmacenes: [],
      //listaequipos: [],
      informacioncompra: [],

      modaldetallecompranormal: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 5,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      fechacompraini: new Date(),
      fechacomprafin: new Date(),

      modalcompradirecta: false,
      titulomodalcompradirecta: "",
      modalcompradirectaver: false,
      modalconfirmar: false,
      titulomodalconfirmar: "",
      cuerpomodalconfirmar: "",
      modaldetalles:false,

      listaproveedores: [],
      listatiposproveedores: [],
      listacombustibles: []
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var tabla = this.state.tabla;
    tabla.elementos = [];

    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');


    var resplistaproveedores = await getProveedoresActivos();
    if (this.manejadorLlamadasApis(resplistaproveedores)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var resplistatiposproveedores = await getComboTipoProveedor();
    if (this.manejadorLlamadasApis(resplistatiposproveedores)) {
      this.setState({ loadingspinners: false })
      return;
    }


    var resplistacombustibles = await getListaTipoCombustibles();
    if (this.manejadorLlamadasApis(resplistacombustibles)) {
      this.setState({ loadingspinners: false })
      return;
    }


    var resplistaareas = await getComboAreasDeTrabajo();
    if (this.manejadorLlamadasApis(resplistaareas)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var resplistazonas = await getListaZonas();
    if (this.manejadorLlamadasApis(resplistazonas)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var resplistaranchos = await getListaAlmacenesCombustible();
    if (this.manejadorLlamadasApis(resplistaranchos)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var resplistatipomaquinarias = await getListaTipoMaquinarias();
    if (this.manejadorLlamadasApis(resplistatipomaquinarias)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var resplistamaquinarias = await getListaMaquinarias();
    if (this.manejadorLlamadasApis(resplistamaquinarias)) {
      this.setState({ loadingspinners: false })
      return;
    }



    await this.setState({
      txtbuscador: '',
      txtfechacompraini: defaultValueFechaInicial,
      txtfechacomprafin: defaultValue,
      fechacompraini: fechaactualIni,
      tabla: tabla,

      listaproveedores: resplistaproveedores.data,
      listatiposproveedores: resplistatiposproveedores.data,
      listacombustibles: resplistacombustibles.data,
      listaareas: resplistaareas.data,
      listazonas: resplistazonas.data,
      listaranchos: resplistaranchos.data,
      listatipomaquinarias: resplistatipomaquinarias.data,
      listamaquinarias: resplistamaquinarias.data,

    })

    setTimeout(() => {
      this.consultarCatalogoVales();
      this.dimensiones();
      this.cambiarContenido(1);
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    }, 300);

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 ) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  consultarCatalogoVales = async (llave, valor) => {
    var formatofechaini = "";
    var formatofechafin = "";
    var fechainiaux = 'txtfechacompraini' == llave ? valor : this.state.txtfechacompraini
    var fechafinaux = 'txtfechacomprafin' == llave ? valor : this.state.txtfechacomprafin

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${day}/${month}/${year}`
    }

    if (fechafinaux.length > 0) {
      var [year, month, day] = fechafinaux.split("-");
      formatofechafin = `${day}/${month}/${year}`
    }

    var requestbody = {
      fechainicio: formatofechaini,
      fechafin: formatofechafin,
    }

    var fechasvalidas = await this.validarFechasDeCompra(formatofechaini, formatofechafin)
    if (fechasvalidas == 1) {
      this.setState({ loadingspinners: true })
      var catalogocompras = await getCatalogoValesCombustible(requestbody);
      if (this.manejadorLlamadasApis(catalogocompras)) {
        this.setState({ loadingspinners: false })
        return;
      }

      var tabla = this.state.tabla;
      tabla.elementos = catalogocompras.data;

      await this.setState({
        txtbuscador: '',
        tabla: tabla
      })
      this.cambiarContenido(1);
      this.setState({ loadingspinners: false })
    }
  }

  validarFechasDeCompra = async (formatofechaini, formatofechafin) => {
    var fechainicio = new Date();
    var fechafin = new Date();
    var fechainiciovalido = false;
    var fechafinvalido = false;
    if (formatofechaini.length > 0) {
      fechainiciovalido = true;
      var fechacompraini = formatofechaini.split("/");
      fechainicio.setFullYear(fechacompraini[2], fechacompraini[1], fechacompraini[0]);
    }

    if (formatofechafin.length > 0) {
      fechafinvalido = true;
      var fechacomprafin = formatofechafin.split("/");
      fechafin.setFullYear(fechacomprafin[2], fechacomprafin[1], fechacomprafin[0]);
    }

    if (fechainiciovalido && fechafinvalido) {
      if (fechainicio <= fechafin) {
        return 1;
      } else {
        toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        var tabla = this.state.tabla;
        tabla.elementos = [];

        await this.setState({
          txtbuscador: '',
          tabla: tabla
        })
        this.cambairContenido(1);
        return 2;
      }
    }
    return 0;
  }



  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    this.consultarCatalogoVales(['txt' + item.llave], year + '-' + month + '-' + day)
  }


  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }




  verModalDetalles = (ban) => {
    
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo vale de combustible', accionmodal: 1, itemseleccionado: {}, modaldetalles: true })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar vale de combustible', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modaldetalles: true });
    }
    else if (ban.opcion== 3){
      this.setState({ titulomodal: 'Ver vale de combustible', accionmodal: 3, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modaldetalles: true });
    }
    else if (ban.opcion == 5) {// ver modal de imagen ticket y vale
      this.setState({
        titulomodal: ban.titulo,
        accionmodal: 5,
        modalverimg: true,
        rutaseleccionada: ban.img
      })
    }
  }

  cerrarModal = (opciones) => {
    
    this.setState({ modaldetalles: false, modalverimg: false })
    if (opciones.accion == 1) {
      toast.success("Vale guardado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla()
    }
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true, errordeacceso: false })
    this.componentDidMount();
  }




  saveInputLocacion = async (e) => {
    this.setState({ txtbuscador: e.target.value });
    if (e.target.value.trim().length > 2) {
      var data1 = JSON.parse(JSON.stringify(this.state.tabla))
      var data = data1.elementos;
      const filtros = data.filter((item) => item.folio.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ listaelementosfiltrados: filtros });
      this.filtro(1, filtros)
    }

  }

  filtro = (pagenumber, array) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        listaelementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  borrarBusqueda = () => {
    this.setState({ txtbuscador: '' })
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }




  cancelarVale = async (item) => {
    this.setState({
      itemseleccionado: item,
      titulomodalconfirmar:  "¿Cancelar vale " + item.folio + "?",
      cuerpomodalconfirmar:
        "¿Cancelar vale " + item.folio + "?",
      modalconfirmar: true,
    })

  }

  cerrarModalConfirmar = async (accion) => {

    this.setState({
      modalconfirmar: false,
    })

    if (accion == 1) {
      var respcancelar = await cancelarValeCombustible({ idvale: this.state.itemseleccionado.idvale });
      if (this.manejadorLlamadasApis(respcancelar)) {
        this.setState({ loadingspinners: false })
        return;
      }

      toast.success("Vale eliminado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.consultarCatalogoVales();
    }

  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesAlmacen bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionandoainventario) ? (<AccionesAlmacen bandera={'verinventario'} />) : null}
        {(this.state.redireccionandoaalertas) ? (<AccionesAlmacen bandera={'veralertas'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioValeCombustible titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal} estatus={[]}
            show={this.state.modaldetalles}

            listaproveedores={this.state.listaproveedores}
            listatiposproveedores={this.state.listatiposproveedores}
            listacombustibles={this.state.listacombustibles}

            listaareas={this.state.listaareas}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            listatipomaquinarias={this.state.listatipomaquinarias}
            listamaquinarias={this.state.listamaquinarias}

            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            arrayzonas={JSON.parse(JSON.stringify([]))} setLoadingSpinner={this.activarSpinner} />
        ) : null}


        {(this.state.modalverimg == true) ? (
          <ModalVerImagen
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            show={this.state.modalverimg}
            accion={this.state.accionmodal}
            img={this.state.rutaseleccionada} />
        ) : null}

        {(this.state.modalconfirmar == true) ? (
          <ModalConfirarAccion
            show={this.state.modalconfirmar}
            item={this.state.itemseleccionado}
            titulomodal={this.state.titulomodalconfirmar}
            cuerpomodal={this.state.cuerpomodalconfirmar}
            cerrarModalConfirmar={this.cerrarModalConfirmar}
          />
        ) : null}


        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Vales de combustibles</label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                  <div className='subcontenedorIconoCalendarioCompras'>
                    <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                  </div>
                  <div className='row'>
                    <div className=' col-sm-4 col-md-4'>
                      <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha vales de:</label>
                    </div>
                    <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechacompraini}
                          onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                      </div>

                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fechacomprafin}
                          onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                    </div>
                  </div>


                </div>

              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar folio"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: 1 })}
                >+ Nuevo vale </button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla anchoMaximo">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (

                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tabla.mindimensiones[index],
                          width: this.state.tabla.dimensiones[index],
                          color: this.state.colorencabezadostabla,
                          textAlign: (index == 4 || index == 5 || index == 7 || index == 8 || index == 9 || index == 10) ? ("center") : ("left")
                        }}
                      >
                        {titulo}
                        {/*(index==5)?(
                          <button style={{border:"1px gray solid", color:"gray", borderRadius:"5px", marginLeft:"5px"}}>...</button>
                        ):null*/}
                        
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 ?

                    this.state.listapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.fechasolicitud}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.folio}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.proveedor}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.combustible}
                        </td>
                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[4],
                            width: this.state.tabla.dimensiones[4],
                            textAlign: "right"
                          }} >
                            {item.litros}
                        </td>
                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[5],
                            width: this.state.tabla.dimensiones[5],
                            textAlign: "right"
                          }} >
                          ${formatoNumero2(item.importe)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >

                          {(item.tipodestino == 1) ? (item.area) : (null)}
                          {(item.tipodestino == 2) ? (item.rancho + " (" + item.zona + ")") : (null)}
                          {(item.tipodestino == 3) ? (item.maquinaria) : (null)}
                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[7],
                            width: this.state.tabla.dimensiones[7],
                            textAlign: "center"
                          }} >

                          <div style={{ display: "flex", width: "100%" }}>

                            <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.imagenvale, titulo: "Comprobante " + item.folio })}
                              style={{ color: this.state.colorverlbl, margin: "auto" }} >
                              <u>
                                <BsEye className="icoBotones" />
                                <b className='btnVerTabla'>Ver </b></u>
                            </label>
                            <a href={item.imagenvale} download style={{ width: "50px !important", margin: "auto" }}>
                              <div className='divBotonesTrash' style={{ width: "50px" }}>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>

                          </div>


                        </td>

                        <td className="letraNormal lblSubtabla" style={{
                          color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[8],
                          width: this.state.tabla.dimensiones[8], textAlign: "center"
                        }} >

                          {(item.imagenticket != "") ? (
                            <div style={{ display: "flex", width: "100%" }}>

                              <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.imagenticket, titulo: "Comprobante " + item.folio })}
                                style={{  color: this.state.colorverlbl, margin: "auto" }}  >
                                <u>
                                  <BsEye className="icoBotones" />
                                  <b className='btnVerTabla'>Ver </b></u>
                              </label>
                              <a href={item.imagenticket} download style={{ width: "50px !important", margin: "auto" }}>
                                <div className='divBotonesTrash' style={{ width: "50px" }}>
                                  <ImDownload3 className="icoBotonesTrash" />
                                </div>
                              </a>

                            </div>
                          ) : (null)}



                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[9],
                            width: this.state.tabla.dimensiones[9],
                            textAlign: "center"
                          }} >

                          {(item.enalmacen == 1) ? (<FaCheck style={{ color: this.state.fondobtnnuevo, width: "20px", height: "20px" }} />) : (null)}
                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[10],
                            width: this.state.tabla.dimensiones[10],
                            textAlign: "center"
                          }} >
                          {(item.facturado == 1) ? (<FaCheck style={{ color: this.state.fondobtnnuevo, width: "20px", height: "20px" }} />) : (null)}
                        </td>

                        {/*<td className="letraNormal lblSubtabla" className="letraNormal > lblSubtablaBotones itemVerticalCenter" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }} >

                          </td>*/}
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }}>
                          {(item.facturado == 1) ? (
                            <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                              style={{
                                backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                              }}><BiEdit className="icoBotones" /> Editar </button>
                          ) : (
                            <>
                              <button className='btnEditar'
                                onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                                style={{
                                  backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                }}><BiEdit className="icoBotones" /> Editar </button>

                              {(item.enalmacen != 1 && item.facturado != 1) ? (
                                <button className='btnEditar' onClick={this.cancelarVale.bind(this, item)}
                                  style={{
                                    backgroundColor: "white", color: "rgb(242, 85, 111) ", border: "1px solid rgb(242, 85, 111)"
                                  }}><BsTrash className="icoBotones" /> Cancelar</button>
                              ) : (null)
                              }

                            </>
                          )}


                        </td>

                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.fechasolicitud}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.folio}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.proveedor}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.combustible}
                        </td>
                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[4],
                            width: this.state.tabla.dimensiones[4],
                            textAlign: "right"
                          }} >
                          {formatoNumero2(item.litros)}
                        </td>
                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[5],
                            width: this.state.tabla.dimensiones[5],
                            textAlign: "right"
                          }} >
                           ${formatoNumero2(item.importe)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >

                          {(item.tipodestino == 1) ? (item.area) : (null)}
                          {(item.tipodestino == 2) ? (item.rancho + " (" + item.zona + ")") : (null)}
                          {(item.tipodestino == 3) ? (item.maquinaria) : (null)}
                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[7],
                            width: this.state.tabla.dimensiones[7],
                            textAlign: "center"
                          }} >

                          <div style={{ display: "flex", width: "100%" }}>

                            <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.imagenvale, titulo: "Comprobante " + item.folio })}
                              style={{  color: this.state.colorverlbl, margin: "auto" }} >
                              <u>
                                <BsEye className="icoBotones" />
                                <b className='btnVerTabla'>Ver </b></u>
                            </label>
                            <a href={item.imagenvale} download style={{ width: "50px !important", margin: "auto" }}>
                              <div className='divBotonesTrash' style={{ width: "50px" }}>
                                <ImDownload3 className="icoBotonesTrash" />
                              </div>
                            </a>

                          </div>


                        </td>

                        <td className="letraNormal lblSubtabla" style={{
                          color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[8],
                          width: this.state.tabla.dimensiones[8], textAlign: "center"
                        }} >

                          {(item.imagenticket != "") ? (
                            <div style={{ display: "flex", width: "100%" }}>

                              <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.imagenticket, titulo: "Comprobante " + item.folio })}
                                style={{  color: this.state.colorverlbl, margin: "auto" }}  >
                                <u>
                                  <BsEye className="icoBotones" />
                                  <b className='btnVerTabla'>Ver </b></u>
                              </label>
                              <a href={item.imagenticket} download style={{ width: "50px !important", margin: "auto" }}>
                                <div className='divBotonesTrash' style={{ width: "50px" }}>
                                  <ImDownload3 className="icoBotonesTrash" />
                                </div>
                              </a>

                            </div>
                          ) : (null)}



                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[9],
                            width: this.state.tabla.dimensiones[9],
                            textAlign: "center"
                          }} >

                          {(item.enalmacen == 1) ? (<FaCheck style={{ color: this.state.fondobtnnuevo, width: "20px", height: "20px" }} />) : (null)}
                        </td>

                        <td className="letraNormal lblSubtabla"
                          style={{
                            color: this.state.colorcontenidotabla,
                            minWidth: this.state.tabla.mindimensiones[10],
                            width: this.state.tabla.dimensiones[10],
                            textAlign: "center"
                          }} >
                          {(item.facturado == 1) ? (<FaCheck style={{ color: this.state.fondobtnnuevo, width: "20px", height: "20px" }} />) : (null)}
                        </td>

                        {/*<td className="letraNormal lblSubtabla" className="letraNormal > lblSubtablaBotones itemVerticalCenter" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }} >

                          </td>*/}
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[11], width: this.state.tabla.dimensiones[11] }}>
                          {(item.facturado == 1) ? (
                            <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                            style={{
                              backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                            }}><BiEdit className="icoBotones" /> Editar</button>
                          ) : (
                            <>
                              <button className='btnEditar'
                                onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                                style={{
                                  backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                                }}><BiEdit className="icoBotones" /> Editar</button>

                              {(item.enalmacen != 1 && item.facturado != 1) ? (
                                <button className='btnEditar' onClick={this.cancelarVale.bind(this, item)}
                                  style={{
                                    backgroundColor: "white", color: "rgb(242, 85, 111) ", border: "1px solid rgb(242, 85, 111)"
                                  }}><BsTrash className="icoBotones" /> Cancelar</button>
                              ) : (null)
                              }

                            </>
                          )}


                        </td>

                      </tr>

                    ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>

        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));

function AccionesAlmacen({ bandera }) {
  const history = useHistory();
  const location = useLocation();
  history.push(location.pathname);
  if (bandera == 'verinventario') {
    return (<Redirect from='/' to={"/catalogoinventarioalmacen"} />)
  } else if (bandera == "veralertas") {
    return (<Redirect from='/' to={"/catalogoalertasalmacen"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}