import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CotizacionProductoGeneral.css';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import { BsCheckLg } from "react-icons/bs";
import { estiloComboBox, estiloFiltroEncabezados } from '../../Services/PaletaDeColores';
import EditarTablaCotizacion from './EditarTablaCotizacion';
import GuardarTablaCotizacion from './GuardarTablaCotizacion';
import AutorizacionTablaCotizacion from './AutorizacionTablaCotizacion';
import AutorizadoTablaCotizacion from './AutorizadoTablaCotizacion';
import CompraRealizadaTablaCotizacion from './CompraRealizadaTablaCotizacion';
import RechazadoTablaCotizacion from './RechazadoTablaCotizacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  comprarCotizacionServicio,
  cambiarStatusCotizacionServicios,
  getComboTipoProveedor,
  getProveedoresActivos,
  getInformacionCotizacionServicios,
  guardarCotizacionServicios
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ModalEliminarCotizacion from '../../Complements/Modales/ModalCotizaciones/ModalEliminarCotizacion';
import ModalFormularioAutorizacion from '../../Complements/Modales/ModalAutorizacionCotizacionServicios/ModalFormularioAutorizacion';
import ModalRechazarCotizacionServicios from '../../Complements/Modales/ModalAutorizacionCotizacionServicios/ModalRechazarCotizacion';

import { formatoNumeroTecla } from '../../Services/Validaciones';
import ModalAsignarServicioCotizacion from '../../Complements/Modales/ModalCotizacionesServicios/ModalAsignarServicioCotizacion';

export default class CotizacionServicios extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,
      estatuseditable: false,
      redireccionando: false,
      estatus: [],

      accionmodal: 1,
      titulomodal: '',

      itemseleccionado: null,

      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      tablaresumen: {
        encabezados: [
          "Actividad",
          "Tipo",
          "Destino",
          "Descripcion",
          "Completados",
        ],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "20%",
          "15%"
        ],
        mindimensiones: [
          "150px",
          "170px",
          "130px",
          "170px",
          "150px"
        ],
        maxdimensiones: [
          "150px",
          "170px",
          "200px",
          "130px",
          "120px",
          "150px"
        ],
        elementos: [],
        total: 0.00,
      },

      tablaresumenCompra: {
        encabezados: [
          "ID Cotización",
          "Proveedor",
          "Fecha de compra",
          "Estatus",
          "Total"],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "100px",
          "170px",
          "170px",
          "170px",
          "200px"
        ],
        maxdimensiones: [
          "100px",
          "170px",
          "170px",
          "170px",
          "100px"
        ],
        elementos: [],
        total: 0.00,
      },

      //tarjetas cotizacion
      contenidorequisicion: [],
      idcotizacionGeneral: 0,

      //modales
      modalasignarproducto: false,
      modaleliminartarjeta: false,

      listaproveedores: [],
      listatipoproveedores: [],

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      //cantidaddecimales
      cuantosdecimales: 2
    }
  }

  async componentDidMount() {


    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idrequisicion = await localStorage.getItem('dato')

    if (idrequisicion != null) {

      this.setState({ loadingspinners: true })

      //obtiene lista de tipo proveedores
      var resptipoprovedores = await getComboTipoProveedor();
      if (this.manejadorLlamadasApis(resptipoprovedores)) {
        return;
      }

      //obtiene lista de proveedores
      var respproveedores = await getProveedoresActivos();
      if (this.manejadorLlamadasApis(respproveedores)) {
        return;
      }

      var resprequisicioninformacion = await getInformacionCotizacionServicios(idrequisicion);

      if (this.manejadorLlamadasApis(resprequisicioninformacion)) {
        return;
      }


      if (resprequisicioninformacion.code == 200) {
        await toast.error(resprequisicioninformacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 1000);
        return;
      }

      var informacionrequisicion = this.state.informacionrequisicion
      informacionrequisicion = resprequisicioninformacion.data ? resprequisicioninformacion.data : {};

      //--- Tabla Resumen de productos
      var tablaresumen = this.state.tablaresumen
      var objetoresumen = informacionrequisicion.Resumen ? informacionrequisicion.Resumen : {}
      tablaresumen.elementos = objetoresumen ? objetoresumen : {}


      //--- Tabla Resumen general
      var tablaresumenCompra = this.state.tablaresumenCompra
      var objetoresumenGeneral = informacionrequisicion.resumencompras ? informacionrequisicion.resumencompras : {}
      tablaresumenCompra.elementos = objetoresumenGeneral.compras ? objetoresumenGeneral.compras : {}
      tablaresumenCompra.total = objetoresumenGeneral.totalgeneral ? objetoresumenGeneral.totalgeneral : 0.0

      //--- Tarjetas cotizaciones
      var contenidorequisicion = this.state.contenidorequisicion
      contenidorequisicion = informacionrequisicion.cotizaciones ? informacionrequisicion.cotizaciones : {}
      contenidorequisicion = this.calcularAcumulados(contenidorequisicion)

      this.setState({
        informacionrequisicion: informacionrequisicion,
        contenidorequisicion: contenidorequisicion,
        tablaresumen: tablaresumen,
        tablaresumenCompra: tablaresumenCompra,
        listaproveedores: respproveedores.data,
        listatipoproveedores: resptipoprovedores.data,
        estatuseditable: false,
        idcotizacionGeneral: informacionrequisicion.idcotizacion,
        idcotizacion: informacionrequisicion.idcotizacion
      });


      setTimeout(() => {
        this.actualizarResumenGeneral();
        this.actualizarResumenPrincipal();
      }, 300);

    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }



  calcularAcumulados(cotizaciones) {
    let nuevacotizacion = [];

    cotizaciones.forEach(coti => {
      let sumaprecios = 0;
      let sumarivas = 0;
      let sumarisr = 0;
      coti.actividades.forEach(prod => {
        sumaprecios = sumaprecios + parseFloat(prod.precio);
        sumarivas = sumarivas + parseFloat(prod.iva);
        sumarisr = sumarisr + parseFloat(prod.isr);
      });

      nuevacotizacion.push({

        "idcotizacion_proveedor": coti.idcotizacion_proveedor,
        "idproveedor": coti.idproveedor,
        "proveedor": coti.proveedor,
        "fechacompra": coti.fechacompra,
        "idestatuscotizacion": coti.idestatuscotizacion,
        "estatuscotizacion": coti.estatuscotizacion,
        "mensaje_estatuscotizacion": coti.mensaje_estatuscotizacion,
        "iva_activo": coti.iva_activo,
        "isr_activo": coti.isr_activo,
        "productos": coti.actividades,
        "total": parseFloat(sumarivas + sumaprecios - sumarisr).toFixed(4),
        "totalivas": parseFloat(sumarivas).toFixed(4),
        "totalisr": parseFloat(sumarisr).toFixed(4),
        "totalprecios": parseFloat(sumaprecios).toFixed(4),

      });
    });


    return nuevacotizacion

  }


  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  vermodalAsignarProducto = (ban) => {
    if (ban.accion == 1) {
      //ASIGNAR PRODUCTO
      this.setState({ accionmodal: 1, modalasignarproducto: true });
    }
  }

  cerrarModalAsignarProducto = async (opciones) => {

    if (opciones != "0") {

      var aux = this.state.contenidorequisicion;

      var total = 0.00;
      opciones.productos.map((p) => {
        total = total + parseFloat(p.precio)
      })

      var newTotal = "" + total;
      var auxTotal = newTotal.split('.');
      if (auxTotal.length > 1) {
        newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
      } else {
        newTotal = auxTotal[0].slice(0, 7) + ".00"
      }


      if (opciones.btnEditado) {
        var indexcotizacioneditar = aux.findIndex((item => item.idcotizacion_proveedor == opciones.idcotizacion_proveedor))
        if (indexcotizacioneditar > -1) {
          aux[indexcotizacioneditar] = opciones;
        } else {
          toast.info("No se encontró la tarjeta a editar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
      } else {
        opciones.idcotizacion_proveedor = aux.length + 1;
        opciones.esdummy = 1;
        aux.push(opciones)
      }

      this.state.contenidorequisicion = aux;
      await this.setState({ estatuseditable: true })

      this.actualizarResumenPrincipal();
      this.actualizarResumenGeneral();
    }

    this.setState({ modalasignarproducto: !this.state.modalasignarproducto })
  }

  actualizarContenido = (tarjetaCotizacion) => {
    this.setState({ accionmodal: 4, modalasignarproducto: true, itemseleccionado: tarjetaCotizacion });
  }

  actualizarResumenGeneral = async () => {

    var resumenAux = [];
    var totalResumen = 0.0;
    if (this.state.contenidorequisicion.length > 0) {
      this.state.contenidorequisicion.map((tarjeta, index) => {

        var newTotal2 = "" + tarjeta.total;
        var auxTotal = newTotal2.split('.');
        if (auxTotal.length > 1) {
          newTotal2 = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 4)
        } else {
          newTotal2 = auxTotal[0].slice(0, 7) + ".00"
        }

        let json = {
          "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
          "idproveedorcompra": tarjeta.idproveedor,
          "proveedorcompra": tarjeta.proveedor,
          "idestatuscompra": tarjeta.idestatuscotizacion,
          "estatuscompra": tarjeta.estatuscotizacion,
          "fechacompra": tarjeta.fechacompra,
          "total": newTotal2
        }
        if (tarjeta.total != undefined) {
          totalResumen = totalResumen + parseFloat(newTotal2);
        }

        resumenAux.push(json)
      })
    }

    var newTotal = "" + totalResumen;
    var auxTotal = newTotal.split('.');
    if (auxTotal.length > 1) {
      newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 4)
    } else {
      newTotal = auxTotal[0].slice(0, 7) + ".00"
    }

    this.state.tablaresumenCompra.elementos = resumenAux;
    this.state.tablaresumenCompra.total = newTotal;

    var tablaresumencompra = this.state.tablaresumenCompra;

    await this.setState({ tablaresumenCompra: tablaresumencompra })
  }

  actualizarResumenPrincipal = async () => {

    var resumenAux = [];

    if (this.state.tablaresumen.elementos.length > 0) {
      this.state.tablaresumen.elementos.map((producto) => {
        var asignada = false;
        this.state.contenidorequisicion.forEach((tarjeta) => {
          tarjeta.productos.forEach((p) => {
            if (p.idcatalogo_requisicion_actividad == producto.idcatalogo_requisicion_actividad) {
              asignada = true;
            }
          })
        })

        producto.asignada = asignada

        resumenAux.push(producto)
      })
    }

    this.state.tablaresumen.elementos = resumenAux;

    var tablaresumenAux = this.state.tablaresumen;

    await this.setState({ tablaresumen: tablaresumenAux })
  }

  vermodalEliminar = (tarjetaCotizacion) => {
    this.setState({ accionmodal: 5, modaleliminartarjeta: true, itemseleccionado: tarjetaCotizacion });
  }

  eliminarTarjeta = async (tarjetaCotizacion) => {
    await this.setState({ modaleliminartarjeta: !this.state.modaleliminartarjeta })
    if (tarjetaCotizacion != "0") {
      var aux = this.state.contenidorequisicion.filter((item) => !(item.idcotizacion_proveedor == tarjetaCotizacion.idcotizacion_proveedor));

      await this.setState({ contenidorequisicion: aux })

      this.actualizarResumenPrincipal();
      this.actualizarResumenGeneral();
      this.setState({ estatuseditable: true })
      await this.setState({})
    }
  }

  // --------------------- modificaciones por productos sustitutos que ya no se aplicara
  cambiarStatus = async (tarjeta) => {
    if (!this.state.estatuseditable) {
      //si no hay cambios sin guardar, se hace el cambio de estatus
      this.setState({ loadingspinners: true })
      let json = {
        "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
        "idestatuscotizacion": 2,
        "password": "",
        "comentario": ""
      }

      var resp = await cambiarStatusCotizacionServicios(json);
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }

      toast.success("Autorizción solicitada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  comprar = async (tarjeta) => {
    if (!this.state.estatuseditable) {
      //si no hay cambios sin guardar, se hace el cambio de estatus
      this.setState({ loadingspinners: true })

      var resp = await comprarCotizacionServicio(tarjeta.idcotizacion_proveedor);
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      toast.success("Compra realizada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()


    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  cambiarStatusDisponible = async (tarjeta) => {
    if (!this.state.estatuseditable) {

      this.setState({ loadingspinners: true })

      let json = {
        "idcotizacion_proveedor": tarjeta.idcotizacion_proveedor,
        "idestatuscotizacion": 1,
        "password": "",
        "comentario": ""
      }

      var resp = await cambiarStatusCotizacionServicios(json);
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }

      toast.success("Estatus de cotización cambiado correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();

    } else {
      toast.info("Debes guardar tus cambios", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
  }

  guardarCambios = async () => {

    var validaciones = true;

    var resumen = [];
    if (this.state.informacionrequisicion.Resumen.length > 0) {
      this.state.informacionrequisicion.Resumen.map((item) => {

        let json = {
          "idcatalogo_requisicion_actividad": item.idcatalogo_requisicion_actividad,
          "idactividad": item.idcatalogo_requisicion_actividad,
          "asignada": item.cantidad,
        }

        resumen.push(json)
      })
    }

    var cotizaciones = [];

    this.state.contenidorequisicion.map((item, index) => {
      let elemento = {
        idcotizacion_proveedor: (this.state.contenidorequisicion[index].esdummy == 1) ? (0) : (this.state.contenidorequisicion[index].idcotizacion_proveedor),
        idproveedor: this.state.contenidorequisicion[index].idproveedor,
        total: this.state.contenidorequisicion[index].total ? this.state.contenidorequisicion[index].total : 0.0,
        actividades: [],
        iva_activo: this.state.contenidorequisicion[index].iva_activo,
        isr_activo: this.state.contenidorequisicion[index].isr_activo,
      }
      if (item.productos.length > 0) {
        item.productos.map((p, index2) => {
          let producto = {
            idactividad_cotizacionproveedor: this.state.contenidorequisicion[index].productos[index2].idactividad_cotizacionproveedor,
            idcatalogo_requisicion_actividad: this.state.contenidorequisicion[index].productos[index2].idcatalogo_requisicion_actividad,
            precio: this.state.contenidorequisicion[index].productos[index2].precio,
            iva: this.state.contenidorequisicion[index].productos[index2].iva,
            isr: this.state.contenidorequisicion[index].productos[index2].isr,
          }
          elemento.actividades.push(producto)
        })
      } else {
        validaciones = false;
        toast.error("No puede haber una cotización sin productos", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      }
      cotizaciones.push(elemento);
    })
    //  }

    var compras = [];

    if (this.state.tablaresumenCompra.elementos.length > 0) {
      this.state.tablaresumenCompra.elementos.map((item, index) => {
        let elemento = {
          "idcotizacion_proveedor": (this.state.contenidorequisicion[index].esdummy == 1) ? (0) : (item.idcotizacion_proveedor),
          "idproveedorcompra": item.idproveedorcompra,
          "idestatuscompra": (item.idestatuscompra == 0 || item.idestatuscompra == 5) ? (1) : item.idestatuscompra,
          "fechacompra": item.fechacompra == "" ? "" : "",
          "total": item.total ? item.total : 0.0
        }
        compras.push(elemento);
      })
    }
    this.state.informacionrequisicion.resumencompras = { "compras": compras, "totalgeneral": this.state.tablaresumenCompra.total };

    let json = {
      "idcatalogo_requisicion": this.state.informacionrequisicion.idcatalogo_requisicion,
      "idcotizacion": this.state.informacionrequisicion.idcotizacion,
      "cotizaciones": cotizaciones,
      "resumencompras": this.state.informacionrequisicion.resumencompras
    }

    if (validaciones) {
      this.setState({ loadingspinners: true })
      var resp = await guardarCotizacionServicios(json);

      if (this.manejadorLlamadasApis(resp)) {
        return;
      }

      toast.success("Cotización guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      this.componentDidMount();

    }
  }




  //// rechazar y autorizar cotizacion

  verModalAutorizacion = (ban) => {
    this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalautorizacion: true, itemseleccionado: ban.item });
  }

  cerrarModalAutorizacion = (opciones) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (opciones.accion == 9) {
      toast.success("Cotización rechazada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()
    }
    if (opciones.accion == 8) {
      toast.success("Cotización aprobada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount()
    }
    //this.componentDidMount()
  }

  verModalRechazarCotizacion = (ban) => {

    if (ban.accion == 1) { //Nuevo
      this.setState({ titulomodal: 'Rechazar cotización', accionmodal: 1, itemseleccionado: JSON.parse(JSON.stringify(ban.item)) })
    }
    this.setState({ modalrechazarcotizacion: true });
  }

  cerrarModalRechazarCotizacion = (datos) => {
    this.setState({ modalrechazarcotizacion: !this.state.modalrechazarcotizacion })
    if (datos.id == 1) {
      this.verModalAutorizacion({ id: 9, item: datos.item }) // id 9 rechazará la cotización
    }
  }


  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalasignarproducto == true) ? (
          <ModalAsignarServicioCotizacion titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalAsignarProducto}
            estatus={this.state.estatus}
            show={this.state.modalasignarproducto}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listaproveedores={this.state.listaproveedores}
            listatipoproveedores={this.state.listatipoproveedores}
            listaproductosResumen={this.state.tablaresumen.elementos}
            listacotizaciones={this.state.contenidorequisicion}
            isProductoGeneral={true} />
        ) : null}

        {(this.state.modaleliminartarjeta == true) ? (
          <ModalEliminarCotizacion titulomodal={"Eliminar tarjeta de cotización"}
            cerrarModal={this.eliminarTarjeta}
            show={this.state.modaleliminartarjeta}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado} />
        ) : null}


        {(this.state.modalautorizacion == true) ? (
          <ModalFormularioAutorizacion
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalAutorizacion}
            show={this.state.modalautorizacion}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            idcotizacion={this.state.idcotizacion}
            setloadingspinner={this.setLoadingSpinner} />
        ) : null}

        {(this.state.modalrechazarcotizacion == true) ? (
          <ModalRechazarCotizacionServicios
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalRechazarCotizacion}
            show={this.state.modalrechazarcotizacion}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            informacion={this.state.itemseleccionado}
            listaproductosResumen={this.state.tablaresumen.elementos}
            setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección informacion   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.informacionrequisicion.nombre}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>{this.state.informacionrequisicion.idperiodo != undefined && this.state.informacionrequisicion.idperiodo != "" ? "Plan anual " + this.state.informacionrequisicion.periodo + ", " + this.state.informacionrequisicion.mes : null}</label>
              </div>



              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.tiporequisicion}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaestimada_entrega}</label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.solicitante}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechacreacion}</label>
              </div>

              <div className='col-8 col-sm-8 col-md-8 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.estatuscotizacion != "" ? this.state.informacionrequisicion.estatuscotizacion : this.state.informacionrequisicion.estatusrequisicion}</label>
              </div>

              <div className='col-4 col-sm-4 col-md-4 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaactualizacion == "" ? this.state.informacionrequisicion.fechacreacion : this.state.informacionrequisicion.fechaactualizacion}</label>
              </div>



            </div>
            <br />
            <br />

          </div>
        </div>
        {/* ////////////////////   Fin Sección informacion   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla  Resumen requisicion  ////////////////////////////////////// */}
        <div className='container-fluid contenedorResumen' style={{ background: "white", textAlign: "right" }}>
          <label className='lblTexto letraTitulos' >
            <button className='btncomprasCotizaciones' onClick={() => this.setState({ cuantosdecimales: (this.state.cuantosdecimales == 2) ? (4) : (2) })}>Mostrar formato con {(this.state.cuantosdecimales == 2) ? (4) : (2)} decimales</button>
          </label>
        </div>
        <br />
        <div className="container-fluid containerTabla ">
          <div className="container-fluid contenedorResumen">
            <div className='containerSubTablaAA divMinWidth'>
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                      index != (this.state.tablaresumen.encabezados.length - 1) ?
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos" colSpan={index == 2 ? 2 : 1}
                          style={{
                            minWidth: this.state.tablaresumen.mindimensiones[index],
                            maxWidth: this.state.tablaresumen.maxdimensiones[index],
                            width: this.state.tablaresumen.dimensiones[index],
                            color: this.state.colorencabezadostabla
                          }}>



                          {index == 0 ? "Resumen" : index == 3 ?
                            <>
                              {parseInt(this.state.informacionrequisicion.idestatusrequisicion) != 8 ?
                                <Button className="btnAzulCotizaciones"
                                  onClick={this.vermodalAsignarProducto.bind(this, { id: 2, accion: 1 })}>
                                  Asignar servicios
                                </Button>
                                : null}
                            </>
                            : null}


                        </th>
                        : null
                    ))}
                  </tr>
                  <tr style={{ borderBottom: 'solid 2px #91b9f9' }}>
                    {this.state.tablaresumen.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{
                          minWidth: this.state.tablaresumen.mindimensiones[index],
                          maxWidth: this.state.tablaresumen.maxdimensiones[index],
                          width: this.state.tablaresumen.dimensiones[index],
                          color: this.state.colorencabezadostabla,
                          paddingBottom: '8px', paddingLeft: '8px'
                        }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {/* ////////////////////   lista de productos resumen  ////////////////////////////////////// */}
                  {
                    this.state.tablaresumen.elementos.map((item, index) => (
                      <tr key={"tabla" + index} className={item.pendientes == 0 ? "sinPendientes" : ""} style={{ borderBottom: (index == this.state.tablaresumen.elementos.length - 1) ? 'solid 2px #91b9f9' : '' }}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.actividad}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px' }}>
                          {item.tipoactividad}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px' }}>
                          {item.destino}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px' }}>
                          {item.descripcion}
                        </td>

                        <td className="letraNormal lblSubtabla" style={{ color: "#0061fe", paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                          {item.asignada == true ? <BsCheckLg className="icoBotones" /> : ""}
                        </td>
                      </tr>
                    ))
                  }
                  <br></br>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla  Resumen requisicion ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTablaAA">
              {this.state.contenidorequisicion != undefined && this.state.contenidorequisicion.length > 0 ?
                this.state.contenidorequisicion.map((item, index) => (
                  item.idestatuscotizacion == 0 ? <EditarTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cuantosdecimales={this.state.cuantosdecimales} listaproductosResumen={this.state.tablaresumen.elementos} />
                    : item.idestatuscotizacion == 1 ? <GuardarTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} actualizarContenido={this.actualizarContenido} eliminarTarjeta={this.vermodalEliminar} cambiarStatus={this.cambiarStatus} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales} listaproductosResumen={this.state.tablaresumen.elementos} />
                      : item.idestatuscotizacion == 2 ? <AutorizacionTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} eliminarTarjeta={this.vermodalEliminar} cambiarStatusDisponible={this.cambiarStatusDisponible} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales} vermodalrechazarcotizacion={this.verModalRechazarCotizacion} listaproductosResumen={this.state.tablaresumen.elementos}
                        vermodalautorizacion={this.verModalAutorizacion} permisoautorizacion={this.state.informacionrequisicion.permisoautorizacion} />
                        : item.idestatuscotizacion == 3 ? <AutorizadoTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} eliminarTarjeta={this.vermodalEliminar} cambiarStatusDisponible={this.cambiarStatusDisponible} comprar={this.comprar} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales} listaproductosResumen={this.state.tablaresumen.elementos} />
                          : item.idestatuscotizacion == 4 ? <CompraRealizadaTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} cuantosdecimales={this.state.cuantosdecimales} listaproductosResumen={this.state.tablaresumen.elementos} />
                            : item.idestatuscotizacion == 5 ? <RechazadoTablaCotizacion key={"tipo_" + index} indexTarjeta={index} itemzona={item} eliminarTarjeta={this.vermodalEliminar} cambiarStatusDisponible={this.cambiarStatusDisponible} fechaestimadacompra={this.state.informacionrequisicion.fechaestimada_compra} cuantosdecimales={this.state.cuantosdecimales} listaproductosResumen={this.state.tablaresumen.elementos} />
                              : null
                ))
                : <div></div>
              }
            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}



        {/* ////////////////////   Inicio Sección Tabla  Resumen compra  ////////////////////////////////////// */}
        <br />
        <br />
        {this.state.contenidorequisicion != undefined && this.state.contenidorequisicion.length > 0 ?
          <div className="container-fluid containerTabla ">
            <div className="container-fluid contenedorResumen">
              <div className='containerSubTablaAA divMinWidth'>
                <Table>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tablaresumenCompra.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaresumenCompra.mindimensiones[index],
                            maxWidth: this.state.tablaresumenCompra.maxdimensiones[index],
                            width: this.state.tablaresumenCompra.dimensiones[index],
                            color: this.state.colorencabezadostabla
                          }}>
                          {index == 0 ? "Resumen de compra" : null}
                        </th>
                      ))}
                    </tr>
                    <tr style={{ borderBottom: 'solid 2px #91b9f9' }}>
                      {this.state.tablaresumenCompra.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tablaresumenCompra.mindimensiones[index],
                            maxWidth: this.state.tablaresumenCompra.maxdimensiones[index],
                            width: this.state.tablaresumenCompra.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index == 4) ? 'center' : '',
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {(this.state.tablaresumenCompra.elementos.length > 0) ?
                      this.state.tablaresumenCompra.elementos.map((item, index) => (
                        <tr key={"tabla" + index} style={{ borderBottom: (index == this.state.tablaresumenCompra.elementos.length - 1) ? 'solid 2px #91b9f9' : '' }}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.idcotizacion_proveedor}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.proveedorcompra}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.fechacompra}
                          </td>
                          <td className={item.idestatuscompra == 5 ? "letrasRojas" : item.idestatuscompra == 4 || item.idestatuscompra == 3 ? "letrasVerdes" : item.idestatuscompra == 2 || item.idestatuscompra == 1 || item.idestatuscompra == 0 ? "letrasAzules" : "letraNormal lblSubtabla"} style={{ color: this.state.colorcontenidotabla }}>
                            {item.estatuscompra == "Compra realizada" ? <button className='btncomprasCotizaciones'>Compra realizada</button> : item.estatuscompra}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                            ${formatoNumeroTecla(item.total, this.state.cuantosdecimales)}
                          </td>
                        </tr>
                      )
                      ) : null
                    }
                    <tr>
                      <td className="lblEncabezadosTabla letraTitulos" colSpan={4} style={{ color: this.state.colorencabezadostabla }}>
                        <b>{"Total a pagar por requisición"}</b>
                      </td>

                      <td className="lblEncabezadosTabla letraTitulos" style={{ color: this.state.colorencabezadostabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                        <b>${formatoNumeroTecla(this.state.tablaresumenCompra.total, this.state.cuantosdecimales)}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          : null
        }
        {/* ////////////////////   Fin Sección Tabla  Resumen compra ////////////////////////////////////// */}



        <br />
        {parseInt(this.state.informacionrequisicion.idestatusrequisicion) != 8 ?
          <div className="divBtnContenedorRequisicion" >
            <Button className="btnRechazar" onClick={() => this.componentDidMount()}>Cancelar</Button>
            <Button className="btnAceptar" onClick={() => this.guardarCambios()} disabled={!this.state.estatuseditable}>Guardar</Button>
          </div>
          : null}
        <br />
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogorequisicionesservicios"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
