import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './Requisicion.css';
import './EditarRequisicion.css';
import TablaProductos from './TablaProductos';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box, Typography } from '@material-ui/core';

import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estilosModal } from '../../Services/PaletaDeColores';
//import ModalFormularioEditarRequisicion from '../../Complements/Modales/ModalFormularioEditarRequisicion/ModalFormularioEditarRequisicion';

import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboUnidadMedida } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class Tabla extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      opcioneditar: props.accion == 1 ? false : true,

      listaproductos: props.itemtabla.productos ? props.itemtabla.productos : [],

      colapsartabla: true,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);

  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row flexNoWrap'>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom'>
                        <button className='btnCollapsar letraNormal'
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                          onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                      </div>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom d-flex align-items-center'>
                        <p className='sinMargen'><b>Tabla: </b>{this.props.itemtabla.tabla}</p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={this.state.colapsartabla} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <TablaProductos key={"tabla_" + 1} listaproductos={this.state.listaproductos} accion={this.state.accion} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}