import React from "react";
import { Table } from 'reactstrap';
import { estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import './VentanaProveedor';

export default class TablaContenidoUnico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

    }
  }

  render() {
    return (
      <Table>
        <thead style={{ verticalAlign: "bottom", backgroundColor: this.state.fondotitulosencabezados }}>
          <tr key={"tabla" + 1}>
            {this.props.opcion == "infogeneral" ? this.props.tabla.encabezados.map((titulo, index) => (
              <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[index],
                width: this.props.tabla.dimensiones[index], color: this.state.colorencabezadostabla, border: "none" }}>
                {titulo}
              </th>
            )) : null}
            {this.props.opcion == "datosfiscales0" ?
            <>
              {this.props.tabla.elementos.length > 0 && this.props.tabla.elementos[0].apellidos == "" ?
                <th className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][0],
                    width: this.props.tabla.dimensiones[0][0], color: this.state.colorencabezadostabla, border: "none"
                  }}>
                  {this.props.tabla.encabezados[0][0]}
                </th>
                :
                <>
                  <th className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][3],
                      width: this.props.tabla.dimensiones[0][3], color: this.state.colorencabezadostabla, border: "none"
                    }}>
                    {this.props.tabla.encabezados[0][3]}
                  </th>
                  <th className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][4],
                      width: this.props.tabla.dimensiones[0][4], color: this.state.colorencabezadostabla, border: "none"
                    }}>
                    {this.props.tabla.encabezados[0][4]}
                  </th>
                </>
              }
              <th className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][1],
                  width: this.props.tabla.dimensiones[0][1], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {this.props.tabla.encabezados[0][1]}
              </th>
              <th className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[0][2],
                  width: this.props.tabla.dimensiones[0][2], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {this.props.tabla.encabezados[0][2]}
              </th>
            </>: null}
            {this.props.opcion == "datosfiscales1" ? this.props.tabla.encabezados[1].map((titulo, index) => (
              <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[1][index],
                  width: this.props.tabla.dimensiones[1][index], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {titulo} 
              </th>
            )) : null}
            {this.props.opcion == "datosfiscales2" ? this.props.tabla.encabezados[2].map((titulo, index) => (
              <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal" style={{ minWidth: this.props.tabla.mindimensiones[2][index],
                  width: this.props.tabla.dimensiones[2][index], color: this.state.colorencabezadostabla, border: "none"
                }}>
                {titulo}
              </th>
              )) : null}
          </tr>
        </thead>


        <tbody style={{ verticalAlign: "bottom" }}>
          {this.props.opcion == "infogeneral" ?
            <tr>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.clave}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.nombre}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.tipoproveedor}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.estatus}
              </td>
              <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                {this.props.tabla.elementos.comentario}
              </td>
            </tr>
            :
            (this.props.opcion == "datosfiscales0" && this.props.tabla.elementos.length > 0)?
              <tr>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos[0].nombrerazonsocial}
                </td>
                {this.props.tabla.elementos && this.props.tabla.elementos[0].apellidos !="" ?
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].apellidos}
                  </td> : null
                }
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos[0].rfc}
                </td>
                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                  {this.props.tabla.elementos[0].regimenfiscal}
                </td>
              </tr>
              :
              (this.props.opcion == "datosfiscales1" && this.props.tabla.elementos.length > 0) ?
                <tr>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].codigopostal}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].tipovialidad}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].nombrevialidad}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].numeroexterior}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].numerointerior}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].colonia}
                  </td>
                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                    {this.props.tabla.elementos[0].localidad}
              </td>
                </tr>
                :
                (this.props.opcion == "datosfiscales2" && this.props.tabla.elementos.length > 0) ?
                  <tr>
                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                      {this.props.tabla.elementos[0].municipio}
                    </td>
                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                      {this.props.tabla.elementos[0].estado}
                    </td>
                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                      {this.props.tabla.elementos[0].entrecalleuno}
                    </td>
                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, border: "none" }}>
                      {this.props.tabla.elementos[0].entrecalledos}
                    </td>
                  </tr> : null}

        </tbody>
      </Table>
    )
  }
}
