import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioProducto.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspaciosGuionesProducto, validarPorcentajeConcentracion, validarLetrasNumerosEspaciosGuionesProductoPuntoComa } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaIngredientesActivosUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const todaslasopcionesdisponibles = -1

export default class ModalFormularioIngredienteActivo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            listaingredientesactivos: props.listaingredientesactivos,

            idingredienteactivo: (props.accion == 2) ? (props.item.idcatalogo_ingredientesactivos) : todaslasopcionesdisponibles,
            vermsgerrorcampoidingredienteactivo: false,
            msgerrorcampoidingredienteactivo: "",

            txtconcentracion: (props.accion == 2) ? (props.item.concentracion) : '',
            vermsgerrorcampoconcentracion: false,
            msgerrorcampotxtconcentracion: "",

            txtcomentario: (props.accion == 2) ? (props.item.descripcion) : '',
            vermsgerrorcampotxtcomentario: false,
            msgerrorcampotxtcomentario: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo
        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    cambiarTipo = (id) => {
        this.setState({ idingredienteactivo: id })
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrorcampoidingredienteactivo: false })
        this.setState({ vermsgerrorcampoconcentracion: false })
        this.setState({ vermsgerrorcampotxtcomentario: false })
        let validacionescorrectas = true

        if (this.state.idingredienteactivo == todaslasopcionesdisponibles) {
            validacionescorrectas = false;
            this.setState({ msgerrorcampoidingredienteactivo: "Seleccione un ingrediente" })
            this.setState({ vermsgerrorcampoidingredienteactivo: true })
        }

        if (this.state.txtconcentracion.trim().length > 0) {
            let longitudvalidatxtconcentracion = { longitudminima: 1, longitudmaxima: 50 };
            let txtconcentracion = validarPorcentajeConcentracion(this.state.txtconcentracion.trim(), longitudvalidatxtconcentracion);
            if (txtconcentracion.codigo == "invalid") {
                this.setState({ msgerrorcampotxtconcentracion: txtconcentracion.mensaje })
                this.setState({ vermsgerrorcampoconcentracion: true })
                validacionescorrectas = false;
            }
        }

        if (this.state.txtcomentario.length > 0) {
            let longitudvalidacomentario = { longitudminima: 1, longitudmaxima: 100 };
            let campocomentario = validarLetrasNumerosEspaciosGuionesProductoPuntoComa(this.state.txtcomentario.trim(), longitudvalidacomentario);
            if (campocomentario.codigo == "invalid") {
                this.setState({ msgerrorcampotxtcomentario: campocomentario.mensaje })
                this.setState({ vermsgerrorcampotxtcomentario: true })
                validacionescorrectas = false;
            }
        }


        if (validacionescorrectas) {
            this.setLoadingSpinner(true)
            let json = {
                "idcatalogoproducto_ingredienteactivo": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogoproducto_ingredienteactivo),
                "idcatalogoproducto": this.props.item.idcatalogoproducto,
                "idcatalogo_ingredientesactivos": this.state.idingredienteactivo,
                "concentracion": this.state.txtconcentracion,
                "descripcion": this.state.txtcomentario,
            }
            var resp = await getListaIngredientesActivosUpdate(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingrediente activo</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarTipo(event.target.value)}>
                                                    {<option value={todaslasopcionesdisponibles} key={"tipo_x"} selected={todaslasopcionesdisponibles == this.state.idingredienteactivo} >Seleccionar</option>}
                                                    {this.state.listaingredientesactivos.map((i, index) => (
                                                        <option value={i.idcatalogo_ingredientesactivos} key={"tipo_" + index} selected={i.idcatalogo_ingredientesactivos == this.state.idingredienteactivo} >{i.ingredienteactivo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorcampoidingredienteactivo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcampoidingredienteactivo}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Concentración %</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtconcentracion} onChange={this.escribir("txtconcentracion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtconcentracion}</label>}

                                {this.state.vermsgerrorcampoconcentracion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcampotxtconcentracion}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcomentario} onChange={this.escribir("txtcomentario")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcomentario}</label>}

                                {this.state.vermsgerrorcampotxtcomentario ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcampotxtcomentario}</p> : null}

                            </div>
                            {/*this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null*/}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

