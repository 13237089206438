import React from 'react'
import './Requisicion.css'
import './EditarRequisicion.css'
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core'
import {
  estiloBtnAgregar, estiloBtnActualizar, estiloBtnNuevo, estiloFiltroEncabezados, estilosModal
} from '../../Services/PaletaDeColores';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class Stock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso: false,
      accion: 1, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,

      tablastock: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad"
        ],
        dimensiones: [
          "26%",
          "22%",
          "17%"
        ],
        mindimensiones: [
          "140px",
          "200px",
          "130px"
        ],
        elementos: []
      },
      listastock: props.listastock,

      colapsartabla: true,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="marginTopSeparator" >
          <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row flexNoWrap'>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom' >
                        <button className='btnCollapsar letraNormal'
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                          onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                      </div>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom d-flex align-items-center'>
                        <p className='sinMargen'><b>Abastecimiento Stock</b></p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    <Collapse in={this.state.colapsartabla} timeout="auto" >
                      <Box margin={1}>
                        <div className="marginTopSeparator" style={{ marginBottom: '20px', padding: '20px 48px'/*(top-bottom) (left-right)*/, backgroundColor: 'white' }} >
                          <div className="container-fluid containerTablaAA anchoTablaProducto">
                            <div className="container-fluid">
                              <div className='row'>
                                <div>

                                  <Table hover>
                                    <thead style={{ verticalAlign: "bottom" }}>
                                      <tr>
                                        {this.state.tablastock.encabezados.map((titulo, index) => (
                                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                            style={{
                                              minWidth: this.state.tablastock.mindimensiones[index], width: this.state.tablastock.dimensiones[index], color: this.state.colorencabezadostabla,
                                              textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                            }}>
                                            {titulo}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>

                                    <tbody style={{ verticalAlign: "bottom" }}>
                                      {this.state.listastock.map((item, index) => (
                                        <tr key={index} style={{ borderTop: !index ? 'solid 1px #dee2e6' : '', borderBottom: 'solid 1px #dee2e6' }}
                                          className={''} >
                                          <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px' }}>
                                            {item.Producto}
                                          </td>
                                          <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                            {item.Unidadmedida}
                                          </td>
                                          <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                            {item.cantidad}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Collapse>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

