import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoLotesCultivo.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaLoteCultivos, getListaTipoSueloDisponibles, getListaPatronInjertoDisponibles, getListaCultivosDisponibles, getListaTipoCultivosDisponibles, getListaZonas, getListaRanchosDisponibles } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalFormularioLoteCultivos from '../../Complements/Modales/ModalFormularioLoteCultivos/ModalFormularioLoteCultivos';
import { formatoNumero, formatoNumero2 } from '../../Services/Validaciones';

export default class CatalogoLotesCultivo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      txtselected: '0',
      txtselectedzona: '0',
      txtselectedrancho: '0',
      txtselectedtipocultivo: '0',
      txtselectedcultivo: '0',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: false,
      errordeacceso: false,

      listazonas: [],
      listaranchos: [],
      listatipocultivo: [],
      listacultivo: [],
      listatiposuelo: [],
      listainjerto: [],

      tabla: {
        encabezados: [
          "Nombre lote",
          "Zona",
          "Rancho",
          "Descripción",
          "Tipo de cultivo",
          "Cultivo",
          "Área total (ha)", 
          "Área efectiva (ha)",
          "Área no efectiva (ha)",
          "Plantas totales (ud)",
         
          "Desarrollo (ud)",
          "Productivos (ud)",
          "Fallas (ud)",
          "Estatus",
          ""],
        dimensiones: [
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "120px"
        ],
        mindimensiones: [
          "130px",
          "100px",
          "100px",
          "150px",
          "178px",
          "100px",
          "150px",
          "185px",
          "200px",
          "185px",
          "150px",
          "170px",
          "130px",
          "130px",
          "120px"
        ],
        elementos: []
      },

      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      redireccionando: false,
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    this.setState({ txtbuscador: '' })
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if ( !this.state.loadingspinners ) {
      this.setState({ loadingspinners: true });
    }
    
    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis (estatus)) {
      return;
    }

    //obtiene catalogo de lotes cultivos
    var resp = await getListaLoteCultivos(); 
    if ( this.manejadorLlamadasApis (resp) ) {
      return;
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if ( this.manejadorLlamadasApis (respzonas) ) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if ( this.manejadorLlamadasApis (respranchos) ) {
      return;
    }

    //obtiene catalogo de tipo cultivos
    var resptipocultivo = await getListaTipoCultivosDisponibles();
    if ( this.manejadorLlamadasApis (resptipocultivo) ) {
      return;
    }

    //obtiene catalogo de cultivos
    var respcultivo = await getListaCultivosDisponibles();
    if ( this.manejadorLlamadasApis (respcultivo) ) {
      return;
    }

    //obtiene catalogo de patron injerto
    var resppatroninjerto = await getListaPatronInjertoDisponibles();
    if ( this.manejadorLlamadasApis (resppatroninjerto) ) {
      return;
    }

    //obtiene catalogo de tipo suelo
    var resptiposuelo = await getListaTipoSueloDisponibles();
    if ( this.manejadorLlamadasApis (resptiposuelo) ) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;
    //tabla.elementos = this.state.areasCultivosDummy;

    await this.setState({ txtbuscador: '', 
    estatus: estatus.data, 
    listacultivo: respcultivo.data, 
    listatipocultivo: resptipocultivo.data, 
    listazonas: respzonas.data, 
    listaranchos: respranchos.data, 
    listainjerto: resppatroninjerto.data, 
    listatiposuelo: resptiposuelo.data, 
    tabla: tabla})

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ txtselectedtipocultivo: '0' })
    this.setState({ txtselectedcultivo: '0' })
    this.setState({ errordeacceso:false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselectedzona: '0' })
    this.setState({ txtselectedrancho: '0' })
    this.setState({ txtselectedtipocultivo: '0' })
    this.setState({ txtselectedcultivo: '0' })
    this.setState({ errordeacceso:false })
    this.setState({ loadingspinners: bandera });
  }

  //Funcion para filtrar por los select y texto en buscador
  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrozonas = [];
    var filtroranchos = [];
    var filtrotipocultivo = [];
    var filtrocultivo = [];
    var filtronombre = [];

    if(e.target.id=='Search'){
      await this.setState({ txtbuscador: e.target.value });

      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      filtronombre = filtrocultivo.filter((item) => item.lote.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
    }else if(e.target.id=='zonas'){
      await this.setState({
        txtselectedzona: e.target.value,
        txtselectedrancho:0,
        txtselectedtipocultivo:0,
        txtselectedcultivo:0
      });

      if(e.target.value==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==e.target.value)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.lote.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }

    }else if(e.target.id=='ranchos'){
      await this.setState({
        txtselectedrancho: e.target.value,
        txtselectedtipocultivo:0,
        txtselectedcultivo:0
      });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(e.target.value==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==e.target.value)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.lote.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='tipocultivos'){
      await this.setState({
        txtselectedtipocultivo: e.target.value,
        txtselectedcultivo:0
      });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(e.target.value==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==e.target.value)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.lote.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='cultivos'){
      await this.setState({ txtselectedcultivo: e.target.value });
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(e.target.value==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==e.target.value)
      }
      if(this.state.txtbuscador.length < 3) {
        filtronombre = filtrocultivo;
      }else{
        filtronombre = filtrocultivo.filter((item) => item.lote.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
      }
    }else if(e.target.id=='CSearch'){
      if(this.state.txtselectedzona==0){
        filtrozonas = data;
      }else{
        filtrozonas = data.filter((item) => item.idcatalogo_zona==this.state.txtselectedzona)
      }
      if(this.state.txtselectedrancho==0){
        filtroranchos = filtrozonas;
      }else{
        filtroranchos = filtrozonas.filter((item) => item.idcatalogo_rancho==this.state.txtselectedrancho)
      }
      if(this.state.txtselectedtipocultivo==0){
        filtrotipocultivo = filtroranchos;
      }else{
        filtrotipocultivo = filtroranchos.filter((item) => item.idtipo_cultivo==this.state.txtselectedtipocultivo)
      }
      if(this.state.txtselectedcultivo==0){
        filtrocultivo = filtrotipocultivo;
      }else{
        filtrocultivo = filtrotipocultivo.filter((item) => item.idcatalogo_cultivo==this.state.txtselectedcultivo)
      }
      filtronombre = filtrocultivo;
      this.setState({ txtbuscador: '' })
    }

    if (filtronombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    }else{
      this.filtro(1, filtronombre)
    }
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo lote', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar lote', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Lote creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Lote editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  render() {
    return (

      <>

        {
          this.state.salir ? 
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesClientes bandera={'salir'} />
          </> 
          : null
        }

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioLoteCultivos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} 
            listazonas={this.state.listazonas} 
            listaranchos={this.state.listaranchos}
            listacultivos={this.state.listacultivo}
            listatipocultivos={this.state.listatipocultivo}
            listatiposuelos={this.state.listatiposuelo}
            listainjerto={this.state.listainjerto} setLoadingSpinner={this.setLoadingSpinner}/>
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end'>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-1 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Lotes</label>
              </div>

                {/* ////////////////////   Filtro zonas ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                  <div className="selectBox ">
                      <select id='zonas' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedzona == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}  
                            {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((item, index) => {
                              return (<option key={"z_"+index} value={item.idcatalogo_zona}>{item.zona}</option>);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro ranchos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                  <div className="selectBox ">
                      <select id='ranchos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedrancho == 0 ? <option selected="true" value='0'>Todos</option> : <option value='0'>Todos</option>}  
                            {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((item, index) => {
                              return (item.idcatalogo_zona==this.state.txtselectedzona || this.state.txtselectedzona==0 ? <option key={"r_"+index} value={item.idcatalogo_rancho}>{item.rancho}</option> : null);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro tipo cultivos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo2' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto2 letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo cultivo</label>
                  <div className="selectBox ">
                      <select id='tipocultivos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedtipocultivo == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}  
                            {this.state.listatipocultivo == undefined || this.state.listatipocultivo.length == 0 ? null : this.state.listatipocultivo.map((item, index) => {
                              return (<option key={"tc_"+index} value={item.idtipo_cultivo}>{item.tipo}</option>);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro cultivos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Cultivo</label>
                  <div className="selectBox ">
                      <select id='cultivos' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                            {this.state.txtselectedcultivo == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}  
                            {this.state.listacultivo == undefined || this.state.listacultivo.length == 0 ? null : this.state.listacultivo.map((item, index) => {
                              return (item.idtipo_cultivo==this.state.txtselectedtipocultivo || this.state.txtselectedtipocultivo==0 ? <option key={"c_"+index} value={item.idcatalogo_cultivo}>{item.cultivo}</option> : null);
                            })}
                      </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro buscador  ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar lote"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.saveInputLocacion} />) : null}
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

              
            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 && this.state.txtselectedtipocultivo == 0 && this.state.txtselectedcultivo == 0?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.lote}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>                        
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipocultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.cultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areatotal)}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.plantastotales)}
                        </td>
                       
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.desarrollo)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.productivas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.fallas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item})} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.lote}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.zona}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.rancho}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.descripcion}
                        </td>                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipocultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.cultivo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areatotal)}
                        </td>  
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {formatoNumero2(item.areaefectiva)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {parseFloat(item.areatotal-item.areaefectiva).toFixed(2)} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {(item.plantastotales)}
                        </td>
                      
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.desarrollo)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.productivas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {(item.fallas)}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item})} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.txtselectedzona == 0 && this.state.txtselectedrancho == 0 && this.state.txtselectedtipocultivo == 0 && this.state.txtselectedcultivo == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}
function AccionesClientes({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}