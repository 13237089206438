import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';

export default class ModalConfirarAccion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpo,
            item: props.item,

            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,
        }
    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            item: this.state.item,
            plan: this.state.cuerpo
        }

        setTimeout(() => {
            this.props.cerrarModalConfirmar(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                        </div>

                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                                <p className='lblSubTitulosModal ' >
                                    ¿Está seguro que desea eliminar este registro de {this.state.cuerpo}?
                                </p>
                            </div>
                        </div>

                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.showModal(1)} >Continuar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}
