import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './Bienvenido.css'
import { Table } from 'reactstrap';
import { estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

export default class CatalogoRequisiciones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,

      datosdummy: {
        nombre: 'Alberto Casas Rosas',
        direccioncorreo: 'acasas@email.com',
        listanotificaciones: [
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 1', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 1. Esto es una cadena de texto como prueba para la extención del mensaje 1. Esto es una cadena de texto como prueba para la extención del mensaje 1. Esto es una cadena de texto como prueba para la extención del mensaje 1' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 2', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 2. Esto es una cadena de texto como prueba para la extención del mensaje 2. Esto es una cadena de texto como prueba para la extención del mensaje 2. Esto es una cadena de texto como prueba para la extención del mensaje 2' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 3', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 3' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 4', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 4. Esto es una cadena de texto como prueba para la extención del mensaje 4. Esto es una cadena de texto como prueba para la extención del mensaje 4. Esto es una cadena de texto como prueba para la extención del mensaje 4' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 5', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 5' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 1b', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 1a. sto es una cadena de texto como prueba para la extención del mensaje 1a. sto es una cadena de texto como prueba para la extención del mensaje 1a. sto es una cadena de texto como prueba para la extención del mensaje 1a' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 2b', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 2a' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 3b', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 3a, Esto es una cadena de texto como prueba para la extención del mensaje 3a. Esto es una cadena de texto como prueba para la extención del mensaje 3a. Esto es una cadena de texto como prueba para la extención del mensaje 3a' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 4b', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 4a. Esto es una cadena de texto como prueba para la extención del mensaje 4a. Esto es una cadena de texto como prueba para la extención del mensaje 4a. Esto es una cadena de texto como prueba para la extención del mensaje 4a. Esto es una cadena de texto como prueba para la extención del mensaje 4a' },
          { fecha: '21/05/2023 08:45:40', titulo: 'Titulo 5b', contenido: 'Esto es una cadena de texto como prueba para la extención del mensaje 5a' }
        ]
      },
      estatus: [],

      errordeacceso: false,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,

      colorcontenidotabla: estiloTabla().colorcontenidotabla

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    this.dimensiones();
    document.body.style.height = '100vh'
    document.body.style.backgroundColor = '#f7f5f2'
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  componentWillUnmount() {
    document.body.style.backgroundColor = 'white'
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  render() {
    return (
      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesRequisiciones bandera={'salir'} />
          </>
          : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados, marginNAN: '0px', flexNAN: '1' }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end' >
              <p className='letraTitulos' style={{ textAlign: 'center', marginTop: '70px' }}>
                Bienvenido(a) <span style={{ textAlign: 'center', color: '#2371fd' }}>{this.state.datosdummy.nombre}</span> <br />
                {this.state.datosdummy.direccioncorreo}
              </p>
            </div>
            <div className='row paddingEncabezados align-items-end ' style={{ textAlign: 'center', margin: '0px', padding: '0px' }}>
              <p className='letraTitulos' style={{ textAlign: 'center', marginTop: '10px', paddingTop: '0px' }}>
                Tienes <span style={{ color: '#2371fd', textDecoration: 'underline' }}>{this.state.datosdummy.listanotificaciones.length}</span> notificaciones
              </p>
            </div>

          </div>
        </div>


        <br />
        <div className="container-fluid anchoMaximoBienv containerTabla"
          style={{ overflowY: 'auto', height: '590px' }}>
          <div className="container-fluid anchoMaximoBienv ">
            <div className="containerSubTabla">
              <Table>
                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.datosdummy.listanotificaciones.map((item, index) => (
                    <tr key={"tabla" + index} style={{ borderBottom: '#2371fd solid 1.5px', borderTop: index == 0 ? '1.5px solid #2371fd' : '' }}>
                      <td className="letraNormal lblSubtabla" style={{
                        color: this.state.colorcontenidotabla, minWidth: '100', width: '600px',
                        paddingLeft: '22px', paddingRight: '22px'
                      }}>
                        <p className='letraTitulos' style={{ textAlign: 'left', margin: '0px', padding: '0px' }}>
                          <span style={{ textAlign: 'center', color: '#2371fd' }}>{item.fecha + ' - ' + item.titulo}</span> <br />
                          {item.contenido}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

          </div>
        </div>

        <br />
        <br />
      </>
    )
  }
}


function AccionesRequisiciones({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}
