import React from 'react'
import './CatalogoNuevaRequisicion.css';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box } from '@material-ui/core';
import { estiloBtnActualizar, estiloFiltroEncabezados, estilosModal } from '../../Services/PaletaDeColores';
import Rancho from './Rancho';
import 'react-toastify/dist/ReactToastify.css';


export default class Zona extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      accion: props.accion,
      titulomodal: "",

      zona: this.props.itemzona,

      colapsartabla:true,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
   
    window.addEventListener("resize", this.dimensiones);
    
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarContenido = (rancho) => {
    var listaranchos = this.props.itemzona.ranchos;

    var index =  listaranchos.findIndex((obj => obj.idrancho == rancho.idrancho));
    if(index > -1){
      listaranchos[index] = rancho;
    }

    var auxzona = this.state.zona;
    auxzona.ranchos = listaranchos;

    this.props.actualizarContenido(auxzona);

  }

  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var ranchos =  this.props.itemzona.ranchos.filter(rancho => rancho.idrancho != elementoeliminado.idrancho);
    
    var auxzona = this.state.zona;
    auxzona.ranchos = ranchos;

    this.props.actualizarContenido(auxzona);
  }

  render() {
    return (

      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator" >
              <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                    <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                            <p className='sinMargen'><b>Zona:</b>{this.props.itemzona.zona}</p>
                            </div>
                          </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemzona.ranchos.length > 0 ?
                              this.props.itemzona.ranchos.map((item) => (
                                <Rancho key={"rancho_" + item.idrancho} 
                                accion={this.state.accion} 
                                itemrancho={item} 
                                actualizarContenido={this.actualizarContenido}
                                eliminarElemento={this.eliminarElemento}
                                listalotes={this.props.listalotes}
                                listatablas={this.props.listatablas}
                                listaprodresumen={this.props.listaprodresumen}
                                />
                              ))
                              : <div></div>
                            }
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
