import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa} from '../../../Services/Validaciones';
import { guardarDocumentoPerfilUsuario, getListaClienteDocumentoUpdate, getListaProveedorDocumentoUpdate} from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioNuevoDocumentoUsuario extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      
      //////////////////// Empieza la definición de datos para la Tabla Documentos ////////////////////
      archivocargado: null,
      txtnombrearchivo: 'Buscar',
      vermsgerrortxtarchivo: false,
      msgerrortxtarchivo: '',

      idtipodocumento: todoslosfiltrosdisponibles,
      vermsgerrortxttipodocumento: false,
      msgerrortxttipodocumento: '',

      idcatalogodocumento: todoslosfiltrosdisponibles,
      vermsgerrortxtcatalogodocumento: false,
      msgerrortxtcatalogodocumento: '',

      txtdescripciondoc: '',
      vermsgerrortxtdescripciondoc: false,
      msgerrortxtdescripciondoc: '',
      //////////////////// Termina la definición de datos para la Tabla Documentos ////////////////////

      // Datos generales
      show: props.show,
      accion: props.accion, // 7
      titulo: props.titulomodal,
      confirmarenvio: false,
      idestatus: 1,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colorbordertipo: estiloComboBox().colorbordercombo,

    }

  }

  async componentDidMount() {

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };  

  setLoadingSpinner = (bandera) => {
    this.props.setLoadingSpinner(bandera);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarmodal(accion);
    }, 200);
  }

  cambiarTipo = (item) => {
    if (item.tipo == "idtipodocumento") {
      this.setState({ [item.tipo]: item.valor, idcatalogodocumento: todoslosfiltrosdisponibles });
    }
    else {
      this.setState({ [item.tipo]: item.valor });
    }
  }

  cambiarEstatus = (id) => {
    var array = this.state.arrayestatus;
    array.forEach(function (item) {
      item.activo = false;
      if (item.idestatus == id) {
        item.activo = true;
      }
    });
    this.setState({ idestatus: id })
  }

  guardarCambios = async () => {

    let validacionescorrectas = true
   
    /////////////////////////////////////////////////////////////////////////////
    if (this.props.accion == 7) {

      this.setState({ vermsgerrortxtarchivo: false })
      this.setState({ vermsgerrortxtcatalogodocumento: false })
      this.setState({ vermsgerrortxttipodocumento: false })
      this.setState({ vermsgerrortxtdescripciondoc: false })

      if (this.state.archivocargado == null) {
        this.setState({ msgerrortxtarchivo: "Selecciona un archivo" })
        this.setState({ vermsgerrortxtarchivo: true })
        validacionescorrectas = false;
      }

      if (this.state.idtipodocumento == todoslosfiltrosdisponibles) {
        this.setState({ msgerrortxttipodocumento: "Selecciona un tipo" })
        this.setState({ vermsgerrortxttipodocumento: true })
        validacionescorrectas = false;
      }

      if (this.state.idcatalogodocumento == todoslosfiltrosdisponibles) {
        this.setState({ msgerrortxtcatalogodocumento: "Selecciona un documento" })
        this.setState({ vermsgerrortxtcatalogodocumento: true })
        validacionescorrectas = false;
      }

      if (this.state.txtdescripciondoc.trim().length > 0) {
        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtdescripciondoc = validarLetrasNumerosPuntoComa(this.state.txtdescripciondoc.trim(), longitudvalida);
        if (txtdescripciondoc.codigo == "invalid") {
          this.setState({ msgerrortxtdescripciondoc: txtdescripciondoc.mensaje })
          this.setState({ vermsgerrortxtdescripciondoc: true })
          validacionescorrectas = false;
        }
      }

    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true);
      var resp = {};
      var json = {};

      if (this.props.accion == 7) {
        const formData = new FormData();
        formData.append('idcatalogousuarios_documentos', 0)
        formData.append('idcatalogousuarios', this.props.item.idcatalogousuarios)
        formData.append('idcatalogo_documentos', this.state.idcatalogodocumento)
        formData.append('archivo', this.state.archivocargado)
        formData.append('descripcion', this.state.txtdescripciondoc.trim())

        resp = await guardarDocumentoPerfilUsuario(formData)
      }

      if(resp.code == 0){
        this.showModal('1')
      }
      else if(resp.code == 200){
        this.setLoadingSpinner(false)
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 300){
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false)
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }

    }
  }

  async seleccionarArchivo(event) {
    try {
      event.persist();
      var archi = event.target.files[0];
      if (event.target.files.length > 0) {
        this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
      }
    } catch (error) { }
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
            <br />

            <br />

            {this.state.accion == 7 ?
                      <div className="divCuerpoModal">

                        <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Archivo</p>
                          <div className='centrarBotonFile' >
                            <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".txt, .pdf, .csv, .xsl, .doc, .docx, .jpg, .jpeg, .png"
                              onChange={(event) => this.seleccionarArchivo(event)} />
                            <label for="file-2"><MdCloudUpload />
                              <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                            </label>
                          </div>

                          {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}
                          <br />


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo</p>

                          <div className='col lblSubTitulosModal '>
                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                              <div className="selectBox ">
                                <select className="cbxTexto letraNormal"
                                  onChange={(event) => this.cambiarTipo({ tipo: "idtipodocumento", valor: event.target.value })}>
                                  {<option value={todoslosfiltrosdisponibles} selected={this.state.idtipodocumento == todoslosfiltrosdisponibles} >Seleccionar </option>}
                                  {this.props.arraytipodocumentos.map((i, index) => (
                                    <option value={i.idtipo_documento} key={"tipo_" + index} selected={i.idtipo_documento == this.state.idtipodocumento} >{i.tipo} </option>
                                  ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          {this.state.vermsgerrortxttipodocumento ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttipodocumento}</p> : null}


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Documento</p>

                          <div className='col lblSubTitulosModal '>
                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                              <div className="selectBox ">
                                <select className="cbxTexto letraNormal"
                                  onChange={(event) => this.cambiarTipo({ tipo: "idcatalogodocumento", valor: event.target.value })}>
                                  {<option value={todoslosfiltrosdisponibles} selected={this.state.idcatalogodocumento == todoslosfiltrosdisponibles} >
                                    {this.state.idtipodocumento == todoslosfiltrosdisponibles ? "Seleccione primero un tipo" : "Seleccionar"}
                                  </option>}
                                  {this.state.idtipodocumento != todoslosfiltrosdisponibles && this.props.arraylistacatalogodocumentos
                                    .filter((e) => e.idtipo_documento == this.state.idtipodocumento)
                                    .map((i, index) => (
                                      <option value={i.idcatalogo_documentos} key={"tipo_" + index} selected={i.idcatalogo_documentos == this.state.idcatalogodocumento} >{i.documento} </option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          {this.state.vermsgerrortxtcatalogodocumento ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcatalogodocumento}</p> : null}


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtdescripciondoc}
                            onChange={this.escribir("txtdescripciondoc")}></input>

                          {this.state.vermsgerrortxtdescripciondoc ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripciondoc}</p> : null}

                        </div>

                      </div>
                      : null}

            <br />
            <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
              <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
            </div>
            <br />
            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

