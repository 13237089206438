import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoNuevaRequisicion.css';
import './EditarRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import TablaProductos from './TablaProductos';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { estiloBtnActualizar, estiloBtnEditar, estilosModal } from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


export default class Tabla extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso:false,
      accion: props.accion, // 1 Nuevo, 2 editar
      opcioneditar: props.accion == 1 ? false : true,

      tabla: props.itemtabla,
      listaproductosgregados: [],
  
      colapsartabla:true,
      eliminartabla:false,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  actualizarContenido = (listaproductos) => {

    this.setState({ 
     // colapsartabla: true,
     listaproductosgregados: listaproductos
    })

    var auxtabla = this.state.tabla;
    auxtabla.productos = listaproductos;

    this.props.actualizarContenido(auxtabla);
  }

  seleccionarElementoaEliminar = () => {
    this.props.eliminarElemento(this.state.tabla)
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }
  actualizarListaNuevosProductos = (nuevoproducto) =>{
    this.props.actualizarListaNuevosProductos(nuevoproducto);
  }
  render() {
    return (

      <>
       
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                        <div className='row flexNoWrap'>
                          <div className='col-auto minPaddingTopBottom'>
                            <button className='btnCollapsar letraNormal'
                              style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                              onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                          </div>
                          <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                          <p className='sinMargen'><b>Tabla: </b>{this.props.itemtabla.tabla}</p>
                          </div>
                        </div>
                          
                      </TableCell>
                  
                      <TableCell align="right" className='displayFlex nonePaddingTopBottom' >
                        <div className='row flexNoWrap'>
                          
                          <div className="col-auto modalDetallesCheck minPaddingTopBottom" >
                              <button className='btnEliminarFila' onClick={() => this.seleccionarElementoaEliminar()}
                              ><FaTrashAlt className="icoBotones" /></button>
                          </div>
                        </div>
                        
                      </TableCell>
                    </TableRow>
                    
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          
                        {
                            this.props.itemtabla.idtabla == -1 ?
                            <div className="container-fluid containerTabla">
                            <div className="container-fluid anchoMaximo">
                              <div className='row justify-content-center' >
                                <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                  <div className='row d-flex justify-content-center tablaTodosProductos letraNormal'>
                                    <label>Todos los productos de esta lista se dividen en cantidades equitativas</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            : null
                          }
                          <Box margin={1}>
                            <TablaProductos key={"tabla_" + 1} accion={this.state.accion}
                             productos={this.props.itemtabla.productos}
                             actualizarContenido={this.actualizarContenido}
                             actualizarListaNuevosProductos={this.actualizarListaNuevosProductos}
                             listaproductos={this.props.listaproductos}
                             listafamiliaproductos={this.props.listafamiliaproductos}
                             listatipoproductos={this.props.listatipoproductos}
                             listaunidadesmedida={this.props.listaunidadesmedida}
                             listanuevosproductos={this.props.listanuevosproductos}
                             />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}


      
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}