import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoAlertasAlmacen.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { FaTrashAlt } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import { BsQuestionSquare } from 'react-icons/bs';
import { AiFillCheckSquare } from "react-icons/ai";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioAlertasAlmacen from '../../Complements/Modales/ModalFormularioAlertasAlmacen/ModalFormularioAlertasAlmacen';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaAlertasInformacion, getComboFamiliaProducto, getComboTipoProducto, eliminarAlerta, getLista2Productos } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalContrasenaSimple from '../../Complements/Modales/ModalContrasenaSimple/ModalContrasenaSimple';


export default class CatalogoAlertasAlmacen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: false,
      errordeacceso: false,

      listaproductoscatalogo: [],
      listafamilia: [],
      listatipoproductos: [],
      listaproductosalmacen: [],
      alertaunidadmedida: [],
      alertapresentacion: [],

      informacion: '',
      txtselectedfamiliaproducto: '0',
      txtselectedtipoproducto: '0',
itemeliminar:null,
      tabla: {
        encabezados: [
          "Nombre comercial",
          "Familia",
          "Tipo",
          "Presentación",
          "Cantidad mínima",
          "Umbral de notificación",
          "",
          ""
        ],
        dimensiones: [
          "15%",
          "15%",
          "10%",
          "10%",
          "15%",
          "15%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "180px",
          "120px",
          "120px",
          "150px",
          "170px",
          "210px",
          "120px",
          "100px"
        ],
        elementos: []
      },

      redireccionando: false,

      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      vermodalcontrasena:false,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if ( !this.state.loadingspinners ) {
        this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis (estatus)) {
      return;
    }

    //obtiene catalogo de tipo productos
    var resptipoproductos = await getComboTipoProducto();
    if (this.manejadorLlamadasApis(resptipoproductos)) {
      return;
    }

    //obtiene catalogo de familia productos
    var respfamilia = await getComboFamiliaProducto();
    if (this.manejadorLlamadasApis(respfamilia)) {
      return;
    }

    //obtiene catalogo de productos catalogo
    var respproductoscatalogo = await getLista2Productos();
    if (this.manejadorLlamadasApis(respproductoscatalogo)) {
      return;
    }

    ///////////////// Se recuperan y asignan datos de un almacen seleccionado 
    var datosalmacen = await localStorage.getItem('datos')

    if(datosalmacen==null){
      this.redireccionando();
    }

    var idalmacen = JSON.parse(datosalmacen)

    // se obtienen las informacion del almacen seleccionado
    var respcatalogoalmacenes = await getListaAlertasInformacion(idalmacen);
    if ( this.manejadorLlamadasApis (respcatalogoalmacenes) ) {
      return;
    }

    if (respcatalogoalmacenes.code == 200) {
      await toast.error(respcatalogoalmacenes.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      setTimeout(async () => {
        this.redireccionando();
      }, 3000);
      return;
    }

    var tabla = this.state.tabla;
    //tabla.elementos = this.state.alertasPDummy;
    tabla.elementos = respcatalogoalmacenes.data.alertas;
    var unidadenorepetidas = [];
    var presentacionesnorepetidas = [];
let nuevatabla=[]
    tabla.elementos.forEach((alerta, index)=>{
      var unidadmedida = []
      var presentaciones = []
      alerta.presentaciones.forEach((presentacion)=>{
        if(!unidadmedida.includes(presentacion.claveunidadmedida)){
          unidadmedida.push(presentacion.claveunidadmedida)
          unidadmedida.push(',')
        }
        if(!presentaciones.includes(presentacion.empaque)){
          presentaciones.push(presentacion.empaque)
          presentaciones.push(',')
        }
      })
      unidadenorepetidas.push(unidadmedida)
      presentacionesnorepetidas.push(presentaciones)
     
     
    })
    
    

    this.setState({ 
      txtbuscador: '',
      estatus: estatus.data,
      listafamilia: respfamilia.data,
      listatipoproductos: resptipoproductos.data,
      listaproductoscatalogo: respproductoscatalogo.data,
      listaproductosalmacen: respcatalogoalmacenes.data.productos,
      alertaunidadmedida: unidadenorepetidas,
      alertapresentacion: presentacionesnorepetidas,
      informacion: respcatalogoalmacenes.data,
      tabla: tabla,
      idinventario:idalmacen,
    })

    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.message == "Token no encontrado") {
      this.setState({ salir: true })
      return true
    } else if(resp.code == 300 || resp.code == 200){
      if(!this.state.errordeacceso){
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
        
      this.setState({ 
        loadingspinners: false,
        errordeacceso:true })
      return true
    }
    else if (resp.code == 200) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ txtselectedtipoproducto: '0' })
    this.setState({ txtselectedfamiliaproducto: '0' })
    this.setState({ errordeacceso:false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ txtselected: '0' })
    this.setState({ errordeacceso:false })
    this.setState({ loadingspinners: bandera });
  }

  saveInputLocacion = async (e) => {

    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrosfamilia = [];
    var filtrostipo = [];
    var filtrosnombre = [];

    if (e.target.id == 'Search') {
      this.setState({ txtbuscador: e.target.value });
      if (e.target.value.trim().length < 3) {
        return;
      }
      if (this.state.txtselectedfamiliaproducto == 0) {
        filtrosfamilia = data;
      } else {
        filtrosfamilia = data.filter((item) => item.idfamiliaproducto==this.state.txtselectedfamiliaproducto)
      }
      if (this.state.txtselectedtipoproducto == 0) {
        filtrostipo= filtrosfamilia;
      } else {
        filtrostipo = filtrosfamilia.filter((item) => item.idtipoproducto==this.state.txtselectedtipoproducto)
      }
      filtrosnombre = filtrostipo.filter((item) => item.nombreproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      if (filtrosnombre.length < 1) {
        filtrosnombre = filtrostipo.filter((item) => item.claveproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
      }
    } else if (e.target.id == 'familia') {
      this.setState({ txtselectedfamiliaproducto: e.target.value });
      if (e.target.value == 0) {
        filtrosfamilia = data;
      } else {
        filtrosfamilia = data.filter((item) => item.idfamiliaproducto==e.target.value)
      }
      if (this.state.txtselectedtipoproducto == 0) {
        filtrostipo= filtrosfamilia;
      } else {
        filtrostipo = filtrosfamilia.filter((item) => item.idtipoproducto==this.state.txtselectedtipoproducto)
      }
      if(this.state.txtbuscador.length < 3) {
        filtrosnombre = filtrostipo;
      }else{
        filtrosnombre = filtrostipo.filter((item) => item.nombreproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
        if (filtrosnombre.length < 1) {
            filtrosnombre = filtrostipo.filter((item) => item.claveproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
        }
      }
    } else if (e.target.id == 'tipoproducto') {
      this.setState({ txtselectedtipoproducto: e.target.value });
      if (this.state.txtselectedfamiliaproducto == 0) {
        filtrosfamilia = data;
      } else {
        filtrosfamilia = data.filter((item) => item.idfamiliaproducto==this.state.txtselectedfamiliaproducto)
      }
      if (e.target.value == 0) {
        filtrostipo= filtrosfamilia;
      } else {
        filtrostipo = filtrosfamilia.filter((item) => item.idtipoproducto==e.target.value)
      }
      if(this.state.txtbuscador.length < 3) {
        filtrosnombre = filtrostipo;
      }else{
        filtrosnombre = filtrostipo.filter((item) => item.nombreproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
        if (filtrosnombre.length < 1) {
            filtrosnombre = filtrostipo.filter((item) => item.claveproducto.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
        }
      }
    } else if (e.target.id == 'CSearch') {
      if (this.state.txtselectedfamiliaproducto == 0) {
        filtrosfamilia = data;
      } else {
        filtrosfamilia = data.filter((item) => item.idfamiliaproducto==this.state.txtselectedfamiliaproducto)
      }
      if (this.state.txtselectedtipoproducto == 0) {
        filtrostipo= filtrosfamilia;
      } else {
        filtrostipo = filtrosfamilia.filter((item) => item.idtipoproducto==this.state.txtselectedtipoproducto)
      }
      filtrosnombre = filtrostipo;
      this.setState({ txtbuscador: '' })
    }

    if (filtrosnombre.length < 1) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.filtro(1, [])
    } else {
      this.filtro(1, filtrosnombre)
    }

  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nueva alerta', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar alerta', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }

    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.componentDidMount();
    }
  }

  cerrarModalContrasena = (accion) => {
    
    this.setState({ vermodalcontrasena:false })
    if (accion.accion == 1) {
      this.eliminar({password:accion.password});
    }
  }

  filtro = (pagenumber, array) => {

    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: []
    });

    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);

  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      filtrolistapaginada: [],
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);

  }

  borrarBusqueda = () => {
    this.setState({ txtbuscador: '' })
    this.cambiarContenido(1);
    this.setState({ filtrolistapaginada: [] });
  }

  eliminar = async (pos) => {
    
    var respeliminaralerta= await eliminarAlerta({idalertaproductoinventario:this.state.itemeliminar, password: pos.password});
    if ( this.manejadorLlamadasApis (respeliminaralerta) ) {
      return;
    }
    toast.success("Alerta eliminada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    this.componentDidMount();

    };

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  render() {
    return (

      <>

        {
          this.state.salir ? 
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesClientes bandera={'salir'} />
          </> 
          : null
        }

        {(this.state.redireccionando) ? (<AccionesClientes bandera={'goAlmacenes'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioAlertasAlmacen 
          titulomodal={this.state.titulomodal} 
          cerrarModal={this.cerrarModal} 
          show={this.state.modaldetalles} 
          accion={this.state.accionmodal}
          estatus={this.state.estatus}
          item={this.state.itemseleccionado} 
          idinventario={this.state.idinventario}
          listaproductoscatalogo={this.state.listaproductoscatalogo}
          listaproductosalmacen={this.state.listaproductosalmacen}
          listafamilia={this.state.listafamilia} 
          listatipoproductos={this.state.listatipoproductos} 
          setLoadingSpinner={this.setLoadingSpinner} />
        ) : null}

        
{(this.state.vermodalcontrasena) ? (
          <ModalContrasenaSimple
            show={this.state.vermodalcontrasena}
            titulomodal={"Eliminar alerta"}
            cerrarModal={this.cerrarModalContrasena}

          />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Alertas de {this.state.informacion != undefined? this.state.informacion.nombrealmacen:""}</label>
                <div className='descripcionAlmacen'>
                  <div>{this.state.informacion != undefined ? this.state.informacion.tipoalmacen : ""}</div>
                  <div>
                    {
                      <>
                          <AiFillCheckSquare className={this.state.informacion != undefined && this.state.informacion.estatus == "Activo" ? "icoAlmacenPrincipalActivo" : "icoAlmacenPrincipalInactivo"} />  Activo
                      </>
                     
                    }
                  </div>
                                  
                </div>
              </div>

              {/* ////////////////////   Filtro familia productos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Familia</label>
                  <div className="selectBox ">
                    <select id='familia' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedfamiliaproducto == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}
                      {this.state.listafamilia.map((item) => {
                        return (<option key={item.familia} value={item.idfamilia_productos}>{item.familia}</option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro tipo productos ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                  <div className="selectBox ">
                    <select id='tipoproducto' className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} onChange={this.saveInputLocacion}>
                      {this.state.txtselectedtipoproducto == 0 ? <option selected="true" value='0'>Todas</option> : <option value='0'>Todas</option>}
                      {this.state.listatipoproductos.map((item) => {
                        return (this.state.txtselectedfamiliaproducto == 0 || item.idfamilia_productos == this.state.txtselectedfamiliaproducto ? <option key={item.tipo} value={item.idtipo_producto}>{item.tipo}</option> : null);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* ////////////////////   Filtro buscador  ////////////////////////////////////// */}
              <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar clave/nombre"></input>
                  {this.state.txtbuscador ? (<IoMdClose id='CSearch' className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.saveInputLocacion} />) : null}
                </div>
              </div>

              

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nueva alerta</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>
 
                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.txtselectedfamiliaproducto == 0 && this.state.txtselectedtipoproducto == 0 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.familiaproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {
                            this.state.alertapresentacion == undefined || this.state.alertapresentacion.length == 0 ? "Sin presentación" : this.state.alertapresentacion[index]
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {item.cantidadminima}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {item.umbralnotificacion}
                        </td>
                      
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                              <button className='btnEliminar' /*onClick={this.eliminar.bind(this, { index, eliminar: item.idalertaproductoinventario })}*/
                              onClick={() => this.setState({vermodalcontrasena:true, itemeliminar:item.idalertaproductoinventario})}
                              style={{
                                backgroundColor: this.state.colorfondobtneliminar, color: this.state.colorbtneliminar, border: this.state.colorbordebtneliminar
                              }}><FaTrashAlt className="icoBotones" /></button>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                      
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.nombreproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.familiaproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          { /*item.presentaciones == undefined || item.presentaciones.length == 0 ? "Sin presentación" : <>{item.presentaciones.map((i)=>(<>{i.empaque+','}</>))}</>*/
                            this.state.alertapresentacion == undefined || this.state.alertapresentacion.length == 0 ? "Sin presentación" : this.state.alertapresentacion[index]
                          }
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {item.cantidadminima}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"right", color: this.state.colorcontenidotabla }}>
                          {item.umbralnotificacion}
                        </td>
                      
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                              <button className='btnEliminar' /*onClick={this.eliminar.bind(this, { index, eliminar: item.idalertaproductoinventario })}*/
                              onClick={() => this.setState({vermodalcontrasena:true, itemeliminar:item.idalertaproductoinventario})}
                              style={{
                                backgroundColor: this.state.colorfondobtneliminar, color: this.state.colorbtneliminar, border: this.state.colorbordebtneliminar
                              }}><FaTrashAlt className="icoBotones" /></button>
                        </td>
                      </tr>

                    ))
                  }
                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.txtselectedfamiliaproducto == 0 && this.state.txtselectedtipoproducto == 0 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}
function AccionesClientes({ bandera }) {
  if (bandera == 'goAlmacenes') {
    return (<Redirect from='/' to={"/catalogoalmacenes"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}