import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
//--import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { validarPasswordEliminar } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import {
  deleteRequisicionDocumento,
  getRequisicionesUpdate
} from '../../../Services/Api';
import { Table } from 'reactstrap';


export default class ModalRanchoLotes extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      item: props.item,
      accion: props.accion,

      listalotes: props.listalotes,
      idloteseleccionado: 0,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortitulo: estiloFiltroEncabezados().colortitulos,

      idseleccionado: '',
      opcionalamacen: '',

      itemseleccionado: null
    }

  }
  /*  async componentDidMount() {
      if (this.props.listadestinos) {
        var listadestinos = this.props.listadestinos
        var item = listadestinos.find(elemento => elemento.idtipo_destino == 2)
        var listahijos = item.hijos
  
      }
   
    }*/


  showModal = (opcion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opcion);
    }, 200);
  }


  seleccionarOperacion = async ({ item, campo }) => {
    this.setState({ idloteseleccionado: item[campo] })
    if (campo == 'idcatalogo_ranchos_lotes_cultivo') {
      this.setState({ opcionalamacen: 'idcatalogo_ranchos_lotes_cultivo' })
    } else {
      this.setState({ opcionalamacen: 'rancho' })
    }
    this.setState({ itemseleccionado: item })
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>
                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                  Selecciona un lugar
                </p>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {
                            <tr onClick={() => this.seleccionarOperacion({ item: this.props.itemrancho, campo: 'rancho' })}
                              className={
                                (this.state.opcionalamacen == 'rancho')
                                  ? "trFondoActivoGreen" : ""}>
                              <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                <b>{this.props.itemrancho.rancho} </b>
                              </td>
                            </tr>
                          }

                          {
                            this.state.listalotes
                              .filter((itemfilter) => itemfilter.idcatalogo_rancho == (this.props.itemrancho ? this.props.itemrancho.idcatalogo_rancho : -1))
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion({ item: item, campo: 'idcatalogo_ranchos_lotes_cultivo' })}
                                  className={
                                    (this.state.idloteseleccionado == item.idcatalogo_ranchos_lotes_cultivo && this.state.opcionalamacen == 'idcatalogo_ranchos_lotes_cultivo')
                                      ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {item.lote}
                                  </td>
                                </tr>
                              ))
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    disabled={this.state.itemseleccionado ? false : true}
                    onClick={() => this.showModal({ accion: 1, item: this.state.itemseleccionado/*'resp.data.idcatalogo_proveedores'*/ })}>
                    Continuar
                  </Button>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal >
      </>
    )
  }
}
