import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesUsuario.css';
import {
  estiloBtnActualizar, estiloBtnEditar, estiloColorError, estiloBtnNuevo,
  estilosModal, estiloComboBox, estiloFiltroEncabezados, coloresTxtBuscador,
} from '../../Services/PaletaDeColores';
import { BiEdit } from "react-icons/bi";
import { FaKey,FaCheck } from "react-icons/fa";
import { AiFillCloseSquare } from 'react-icons/ai';
import ModalFormularioUsuarios from '../../Complements/Modales/ModalFormularioUsuarios/ModalFormularioUsuarios';
import ModalFormularioNuevoDocumentoUsuario from '../../Complements/Modales/ModalDetallesUsuario/ModalFormularioNuevoDocumentoUsuario';
import ModalDeleteDocumentoPerfilUsuario from '../../Complements/Modales/ModalDetallesUsuario/ModalDeleteDocumentoPerfilUsuario';
import ModalConfirmarRestablecerContraseña from '../../Complements/Modales/ModalDetallesUsuario/ModalConfirmarRestablecerContraseña';
import { Table } from 'reactstrap';
import { AiOutlineFileDone, AiOutlineFileImage, AiOutlineFileWord } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { BsTrash } from "react-icons/bs"
import { ImDownload3 } from 'react-icons/im'
import { GrDocumentPdf, GrDocumentTxt } from 'react-icons/gr';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {getEstatus, setRestablecerUsuario,getComboAreasDeTrabajo, getListaPuestosDeTrabajo, getListaTipoUsuarios,getDetallesUsuario,getListaCatalogoTipoDocumento, getListaCatalogoDocumentos} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

export default class DetallesUsuario extends React.Component {
    constructor(props) {
    super(props);

    this.state = {
        show: props.show,
            confirmarenvio: false,
            idusuario:0,
            loadingspinners: true,
            item:props.item,

            txtarea: "",
            txtpuesto: "",
            txttipousuario: "",
            txtusuario: "",
            txtpassword: "",
            txtultimasesion: "",
            txttelefono: "",
            txtemail: "",
            txtdireccion: "",
            txtreferencia: "",
            txtiduser: 0,
            txtacceso: '',

            arraytipos: [],
            arraytipodocumentos: [],

            tabladocumentos: {
                encabezados: [
                    "Documento",
                    "Tipo",
                    "Archivo",
                    "Descripción",
                    "Creado",
                    ""
                ],
                dimensiones: [
                    "14%",
                    "14%",
                    "20%",
                    "20%",
                    "20%",
                    "12%"
                ],
                mindimensiones: [
                    "130px",
                    "130px",
                    "180px",
                    "180px",
                    "180px",
                    "150px"
                ],
                titulo: "Documentos",
                elementos: []
            },
            modalrestablecercontraseña:false,
            modalnuevodocumento:false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
            borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

            detallesusuario:[],
            listaarea: [],
            listapuesto: [],
            listatipousuario:[],
            estatus: []
        }
    }

    async componentDidMount() {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
            this.setState({ salir: true })
        }

        if (!this.state.loadingspinners) {
            this.setState({ loadingspinners: true });
        }

        var resplistaareas = await getComboAreasDeTrabajo();
        if ( this.manejadorLlamadasApis (resplistaareas) ) {
            return;
        }

        var resplistapuestos = await getListaPuestosDeTrabajo();
        if ( this.manejadorLlamadasApis (resplistapuestos) ) {
            return;
        }

        var resplistatipousuario = await getListaTipoUsuarios();
        if ( this.manejadorLlamadasApis (resplistatipousuario) ) {
            return;
        }

        var resptipodocumento = await getListaCatalogoTipoDocumento();
        if (this.manejadorLlamadasApis(resptipodocumento)) {
            return;
        }

        var estatus = await getEstatus();
        if ( this.manejadorLlamadasApis (estatus) ) {
            return;
        }

        var arraytemp = JSON.parse(JSON.stringify(resptipodocumento.data));
        this.setState({ arraytipodocumentos: arraytemp })

        var resp = await getListaCatalogoDocumentos();
        if (this.manejadorLlamadasApis(resp)) {
            return;
        }
        arraytemp = JSON.parse(JSON.stringify(resp.data));
        this.setState({ arraylistacatalogodocumentos: arraytemp })

        var id = await localStorage.getItem('idusuario')
        var idusuario = Number(id)
        if (idusuario != null) {
            this.setState({idusuario:id})
            let jsonfile = {
                "idusuario": idusuario
            };

            var respdetallesusuario = await getDetallesUsuario(jsonfile);
            if ( this.manejadorLlamadasApis (respdetallesusuario) ) {
                return;
            }

            this.cargarinfo(respdetallesusuario.data)

            ///////////////// Se asignan datos de tabla Documentos
            var tabladocumentos = this.state.tabladocumentos;
            tabladocumentos.elementos = respdetallesusuario.data.documentos ? respdetallesusuario.data.documentos : [];

            this.setState({
                detallesusuario:respdetallesusuario.data,
                tabladocumentos: tabladocumentos,
                listaarea: resplistaareas.data,
                listapuesto: resplistapuestos.data,
                listatipousuario: resplistatipousuario.data,
                estatus: estatus.data
            });
            this.setState({ loadingspinners: true })
        }

        await this.setState({
            loadingspinners: false
        })
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    cargarinfo = (resp) => {
        if(resp!=undefined){
            //Validar telefono
            let telefono;
            if(resp.telefono  == "" || resp.telefono  == null || resp.telefono  == undefined ){
                telefono = "Sin asignar";
            }else{
                telefono = resp.telefono
            }

            //Validar telefono
            let email;
            if(resp.email  == "" || resp.email  == null || resp.email  == undefined ){
                email = "Sin asignar";
            }else{
                email = resp.email
            }

            //Validar usuario
            let usuario;
            if(resp.usuario  == "" || resp.usuario  == null || resp.usuario  == undefined ){
                usuario = "Sin asignar";
            }else{
                usuario = resp.usuario
            }

            //Validar referencia
            let referencia;
            if(resp.referencia  == "" || resp.referencia  == null || resp.referencia  == undefined || resp.referencia.length  < 1 ){
                referencia = "Sin asignar";
            }else{
                referencia = resp.referencia
            }

            //Validar calle
            let calle;
            if(resp.calle){
                calle = resp.calle;
            }else{
                calle = ""
            }

            //Validar numero exterior
            let numeroexterior;
            if (resp.numeroexterior) {
                if(calle){
                    numeroexterior = ", " + resp.numeroexterior;
                }else{
                    numeroexterior = resp.numeroexterior;
                }
            } else {
                numeroexterior = "";
            }

            //Validar numero interior
            let numerointerior;
            if (resp.numerointerior) {
                if(calle || numeroexterior){
                    numerointerior = ", " + resp.numerointerior;
                }else{
                    numerointerior = resp.numerointerior;
                }
            } else {
                numerointerior = "";
            }

            //Validar colonia
            let colonia;
            if (resp.colonia) {
                if(calle || numeroexterior || numerointerior){
                    colonia = ", " + resp.colonia;
                }else{
                    colonia = resp.colonia;
                }
            } else {
                colonia = "";
            }

            //Validar codigo postal
            let codigopostal;
            if (resp.codigopostal) {
                if(calle || numeroexterior || numerointerior || colonia){
                    codigopostal = ", " + resp.codigopostal;
                }else{
                    codigopostal = resp.codigopostal;
                }
            } else {
                codigopostal = "";
            }

            //Validar localidad
            let localidad;
            if (resp.localidad) {
                if(calle || numeroexterior || numerointerior || colonia || codigopostal){
                    localidad = ", " + resp.localidad;
                }else{
                    localidad = resp.localidad;
                }
            } else {
                localidad = "";
            }

            //Validar municipio
            let municipio;
            if (resp.municipio) {
                if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad){
                    municipio = ", " + resp.municipio;
                }else{
                    municipio = resp.municipio;
                }
            } else {
                municipio = "";
            }

            //Validar estado
            let estado;
            if (resp.estado) {
                if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad || municipio){
                    estado = ", " + resp.estado;
                }else{
                    estado = resp.estado;
                }
            } else {
                estado = "";
            }

            //Validar pais
            let pais;
            if (resp.pais) {
                if(calle || numeroexterior || numerointerior || colonia || codigopostal || localidad || municipio || estado){
                    pais = ", " + resp.pais;
                }else{
                    pais = resp.pais;
                }
            } else {
                pais = "";
            }

            let direccion = calle + numeroexterior + numerointerior + colonia + codigopostal + localidad + municipio + estado + pais + "."
            if (direccion == ".") {
                direccion = "Sin asignar";
            }

            this.setState({
                txtnombre:resp.nombres+" "+resp.apellidos,
                txtestatus:resp.estatus,
                txtarea: resp.area,
                txtpuesto: resp.puesto,
                txttipousuario: resp.tipousuario,
                txtusuario: usuario,
                txttelefono: telefono,
                txtemail: email,
                txtdireccion: direccion,
                txtreferencia: referencia,
                txtacceso: resp.acceso
            })
        }
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({ errordeacceso: false });
        this.componentDidMount();
    }

    restablecerUsuario = () =>{
        this.setState({ titulomodal: 'Restablecer contraseña'})
        this.setState({ modalrestablecercontraseña: true });
    }

    verModal = (ban) => {

        if (ban.opcion === 1) { //Documentos nuevo
            this.setState({ titulomodal: 'Agregar documento', accionmodal: 7, itemseleccionado: { idcatalogousuarios: this.state.idusuario } })
            this.setState({ modalnuevodocumento: true });
        }
        else if (ban.opcion === 2) { //Eliminar documento
            this.setState({ titulomodal: 'Eliminar archivo', accionmodal: 9, itemseleccionado: { idcatalogousuarios_documentos: ban.contenido.idcatalogousuarios_documentos }})
            this.setState({ modaleliminardocumento: true });
        }
        else if (ban.opcion === 3) { //Editar usuario
            this.setState({ titulomodal: 'Editar usuario', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(this.state.detallesusuario)),modalformulariousuario: true });
        }

    }

    cerrarModalEditar = (accion) => {

        this.setState({ modalformulariousuario: !this.state.modalformulariousuario})
        if (accion == 2) {
            toast.success("Usuario editado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.componentDidMount();
        }

    }

    cerrarModalRestablecerContraseña = async (accion) => {
        this.setState({ modalrestablecercontraseña: !this.state.modalrestablecercontraseña})
        if (accion == 1) {
            this.setState({loadingspinners: true });

            let json = {
                "idcatalogousuarios": this.state.idusuario
            }

            var resp = await setRestablecerUsuario(json);

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setState({loadingspinners: false });
                return;
            }
            else if (resp.code == 200 || resp.code == 300) {
                this.setState({loadingspinners: false });
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp === -1) {
                this.setState({loadingspinners: false });
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }else{
                this.setState({loadingspinners: false });
                toast.success("Contraseña restablecida exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }
    }

    cerrarModalDocumento = (accion) => {
        this.setState({ modalnuevodocumento: !this.state.modalnuevodocumento, loadingspinners: false })
        if (accion == 1) {
            this.componentDidMount();
            toast.success("Documento agregado exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    }

    cerrarModalEliminarDoc = (accion) => {
        this.setState({ modaleliminardocumento: !this.state.modaleliminardocumento, loadingspinners: false })
        if (accion == 1) {
            this.componentDidMount();
            toast.success("Se borró el documento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
    }

    render() {
        return (

        <>
            {this.state.salir ?
            <>
                <SpinnerModal tipo="full" show={true} />
                <AccionesVista bandera={'salir'} />
            </>
            : null}

            <ToastContainer />

            {(this.state.loadingspinners == true) ? (
                <SpinnerModal tipo="full" show={this.state.loadingspinners} />
            ) : null}

            {(this.state.modalformulariousuario == true) ?
                <ModalFormularioUsuarios
                    titulomodal={this.state.titulomodal}
                    cerrarModal={this.cerrarModalEditar}
                    estatus={this.state.estatus}
                    show={this.state.modalformulariousuario}
                    accion={this.state.accionmodal}
                    item={this.state.detallesusuario}
                    activarSpinner={this.setLoadingSpinner}
                    listaarea={JSON.parse(JSON.stringify(this.state.listaarea))}
                    listapuesto={JSON.parse(JSON.stringify(this.state.listapuesto))}
                    listatipousuario={JSON.parse(JSON.stringify(this.state.listatipousuario))}
                />
            :null}
            {(this.state.modalrestablecercontraseña == true) ? (
                <ModalConfirmarRestablecerContraseña
                    show={this.state.modalrestablecercontraseña}
                    titulomodal={this.state.titulomodal}
                    nombres={this.state.txtnombre}
                    idusuario={this.state.idusuario}
                    cerrarmodal={this.cerrarModalRestablecerContraseña}
                    setLoadingSpinner={this.setLoadingSpinner}
                />
            ) : null}
            {(this.state.modalnuevodocumento == true) ? (
                <ModalFormularioNuevoDocumentoUsuario
                    titulomodal={this.state.titulomodal}
                    cerrarmodal={this.cerrarModalDocumento}
                    show={this.state.modalnuevodocumento}
                    accion={this.state.accionmodal}
                    item={this.state.itemseleccionado}
                    setLoadingSpinner={this.setLoadingSpinner}
                    arraytipodocumentos={JSON.parse(JSON.stringify(this.state.arraytipodocumentos))}
                    arraylistacatalogodocumentos={JSON.parse(JSON.stringify(this.state.arraylistacatalogodocumentos))}/>
            ) : null}

            {(this.state.modaleliminardocumento == true) ? (
                <ModalDeleteDocumentoPerfilUsuario
                    item={this.state.itemseleccionado}
                    show={this.state.modaleliminardocumento}
                    titulomodal={this.state.titulomodal}
                    cerrarModalAtutorizacion={this.cerrarModalEliminarDoc}
                    setloadingspinner={this.setLoadingSpinner}
                    accion={this.state.accionmodal}
                />
            ) : null}

            <Navbar />
            <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                <div className="container-fluid anchoMaximo">
                    <div className='row paddingEncabezados align-items-end '>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                            <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Detalles de usuario</label>
                            <br />
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.txtnombre}</label>
                            <br />
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >Estatus: {this.state.txtestatus} </label>
                        </div>

                        <div className='col containerSubTabla'></div>

                        <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                            <button
                                className='btnNuevo letraNormal'
                                style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                onClick={this.verModal.bind(this, { opcion: 3 })}
                            >
                                <BiEdit className="icoBotones" /> Editar
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* ////////////////////   Tabla   ////////////////////////////////////// */}

            <br />
            <div className="container-fluid containerTabla">
                <div className="container-fluid anchoMaximo">
                    <div className='row' style={{ marginLeft: "0px", marginRight: "0px" }}>
                        {/* ////////////////////   Perfil de usuario  ////////////////////////////////////// */}
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                        <Table>
                            <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos"  colSpan="3">
                                    {"Perfil de usuario"}
                                </td>
                            </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Área"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtarea}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Puesto"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtpuesto}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Tipo usuaro"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txttipousuario}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>

                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'></div>

                        {/* ////////////////////   Sesion  ////////////////////////////////////// */}
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                        <Table>
                            <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos">
                                    {"Sesión"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla,textAlign:'right' }} colSpan={2}>
                                    {this.state.txtacceso ?
                                        <div className='iconoAcceso' >
                                            <label className="letraNormal lblSubtabla" style={{ color: this.state.btncoloreditar }}>
                                                Acceso al sistema
                                            </label>
                                            &nbsp;
                                            <FaCheck className="icoBotones" style={{ color: this.state.btncoloreditar }} />
                                        </div>
                                    :
                                        <div className='iconoAcceso' >
                                            <label className="letraNormal lblSubtabla lblSinDatos" style={{ color: this.state.btncoloreditar }}>
                                                Sin acceso al sistema
                                            </label>
                                            &nbsp;
                                            <AiFillCloseSquare className="icoBotones lblSinDatos" />
                                        </div>
                                    }
                                </td>
                            </tr>
                            </thead>

                            <tbody style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Usuario"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtusuario == "Sin asignar" ?
                                        <i className='lblSinDatos'>{this.state.txtusuario}</i>
                                    :
                                        this.state.txtusuario
                                    }
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Contraseña"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtacceso ?
                                        <button
                                            className='btnEstandar btnRestablecerModal'
                                            onClick={this.restablecerUsuario.bind(this)}
                                            style={{backgroundColor: this.state.colorfondobtnrestablecer, color: this.state.btncoloreditar, border: this.state.colorbordebtnrestablecer}}
                                        >
                                            <FaKey className="icoBotones" /> Restablecer
                                        </button>
                                    :
                                        null
                                    }
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>

                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'></div>

                        {/* ////////////////////   Informacion de contacto  ////////////////////////////////////// */}
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                        <Table>
                            <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos" colSpan="3">
                                    {"Informacion de contacto"}
                                </td>
                            </tr>
                            </thead>

                            <tbody style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Télefono"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txttelefono == "Sin asignar" ?
                                        <i className='lblSinDatos'>{this.state.txttelefono}</i>
                                    :
                                        this.state.txttelefono
                                    }
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"E-mail"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtemail == "Sin asignar" ?
                                        <i className='lblSinDatos'>{this.state.txtemail}</i>
                                    :
                                        this.state.txtemail
                                    }
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>

                        <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'></div>

                        {/* ////////////////////   Direccion ////////////////////////////////////// */}
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                        <Table>
                            <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td className="letraTitulos" colSpan="3">
                                    {"Dirección"}
                                </td>
                            </tr>
                            </thead>

                            <tbody style={{ verticalAlign: "bottom" }}>
                            <tr>
                                <td colSpan="3" className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtdireccion == "Sin asignar" ?
                                        <i className='lblSinDatos'>{this.state.txtdireccion}</i>
                                    :
                                        this.state.txtdireccion
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="letraTitulos lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {"Referencia"}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {this.state.txtreferencia == "Sin asignar" ?
                                        <i className='lblSinDatos'>{this.state.txtreferencia}</i>
                                    :
                                        this.state.txtreferencia
                                    }
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>

                    </div>
                </div>
            </div>


            {/* ////////////////////   Tabla documentos  ////////////////////////////////////// */}

            <div className="container-fluid containerTabla">
                <div className="container-fluid anchoMaximo" style={{ minWidth: "1000px", backgroundColor: this.state.fondotitulosencabezados }} >
                    <div style={{ marginBottom: "15px" }}>
                        <br />

                        <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                            <div className="col-auto">
                                <label className="lblTituloHoja letraTitulos">{this.state.tabladocumentos.titulo}</label>
                            </div>
                            <div className='col '></div>
                            <div className="col-auto align-items-end">
                                <button
                                    className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.verModal.bind(this, { opcion: 1 })}
                                    >+ Nuevo</button>
                            </div>
                        </div>

                        <div className="containerSubTabla" style={{ padding: "4px" }}>
                            <Table >
                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {(this.state.tabladocumentos && this.state.tabladocumentos.elementos.length > 0) ?
                                            this.state.tabladocumentos.encabezados.map((titulo, index) => (
                                                <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal"
                                                style={{
                                                    minWidth: this.state.tabladocumentos.mindimensiones[index], width: this.state.tabladocumentos.dimensiones[index],
                                                    color: this.state.colorencabezadostabla
                                                }}>
                                                {titulo}
                                                </th>
                                            ))
                                        :
                                            <th className="lblEncabezadosTablaProv letraNormal"
                                                style={{ minWidth: "100%", width: "900px", color: this.state.colorencabezadostabla }}>
                                                {"Sin documentos"}
                                            </th>
                                        }
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>
                                    {this.state.tabladocumentos.elementos && this.state.tabladocumentos.elementos.map((item, index) => (
                                        <tr key={"tabla" + index}>
                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabladocumentos.mindimensiones[0], width: this.state.tabladocumentos.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                                {item.documento}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabladocumentos.mindimensiones[1], width: this.state.tabladocumentos.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                                {item.tipo_documento}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabladocumentos.mindimensiones[2], width: this.state.tabladocumentos.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                                {item.extension == "pdf" ? <GrDocumentPdf /> :
                                                    item.extension == "txt" ? <GrDocumentTxt /> :
                                                        item.extension == "csv" ? <RiFileExcel2Line /> :
                                                            item.extension == "doc" || item.extension == "docx" ? <AiOutlineFileWord /> :
                                                                item.extension == "jpg" || item.extension == "jpeg" || item.extension == "png" ? <AiOutlineFileImage /> : <AiOutlineFileDone />
                                                }
                                                {"Documento_" + item.idcatalogousuarios + "_" + item.idcatalogousuarios_documentos+"."+item.extension}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabladocumentos.mindimensiones[3], width: this.state.tabladocumentos.dimensiones[3], color: this.state.colorcontenidotabla }}>
                                                {item.descripcion}
                                            </td>
                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabladocumentos.mindimensiones[4], width: this.state.tabladocumentos.dimensiones[4], color: this.state.colorcontenidotabla }}>
                                                {item.fechacreacion}
                                            </td>
                                            <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: "150px", width: "12%" }}>
                                                <div style={{ display: 'inline-flex' }}>
                                                    <a href={item.rutadocumento} download>
                                                        <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonDescargar'>
                                                            <ImDownload3 className="icoBotonesTrash" />
                                                        </div>
                                                    </a>
                                                    <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonEliminar'
                                                        onClick={this.verModal.bind(this, { opcion: 2,contenido: item, eliminar: 2 })}>
                                                        <BsTrash className="icoBotonesTrash" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

function AccionesVista({ bandera }) {
    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }
}
