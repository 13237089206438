import * as React from 'react';
import { Button} from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';


export default function CustomActionBar(props) {
  const { onAccept, onCancel, className } = props;

  return (
    <DialogActions className={className}>
      <div className="divBtnContenedorBotonesReloj" >
        <Button 
          className="btnCancelModal" 
          onClick={() => {
            onCancel();
          }}>
            Cancelar
        </Button>
        <Button 
          className="btnAceptarModal" 
          onClick={() => {
            onAccept();
          }}>
            Continuar
        </Button>
      </div>
    </DialogActions>
  );
}