import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { getComboTipoProveedor, getProveedoresActivos } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioProveedoresDisponibles extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            txtbuscador: "",
            tabla: {
                encabezados: [
                    "Selecciona un proveedor",
                    ""
                ],
                dimensiones: [
                    "50%",
                    "40%"
                ],
                mindimensiones: [
                    "100px",
                    "100px"
                ],
                elementos: [],
            },
            listaproveedores: [],
            proveedorseleccionado: undefined,
            idproveedorseleccionado: 0,

            filtrotipoproveedor: -1,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listatipoproveedores: [],
            listatipoproveedoresfiltrados: [],
            listaproveedoresfiltrados: [],
        }

    }
    async componentDidMount() {

        this.setState({ loadingspinners: true })

        var resptipoproveedores = await getComboTipoProveedor();
        if (this.manejadorLlamadasApis(resptipoproveedores)) {
            return;
        }

        var respcatalogoproveedores = await getProveedoresActivos();
        if (this.manejadorLlamadasApis(respcatalogoproveedores)) {
            return;
        }


        var tabla = this.state.tabla;
        tabla.elementos = respcatalogoproveedores.data;

        this.setState({
            txtbuscador: '',
            tabla: tabla,
            listatipoproveedores: resptipoproveedores.data,
            listatipoproveedoresfiltrados: resptipoproveedores.data,
            listaproveedoresfiltrados: tabla.elementos
        })
        this.setState({ loadingspinners: false })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 200) {
            this.setState({ loadingspinners: false })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true;
        } else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalProveedores(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        this.showModal(this.state.proveedorseleccionado)

    }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        var filtrofinal = [];


        var listafiltrotipo = data;

        if (this.state.filtrotipoproveedor != -1) {
            listafiltrotipo = data.filter((item) => item.idtipo_proveedor == this.state.filtrotipoproveedor)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ listaproveedoresfiltrados: filtros });
        } else {
            this.filtrarTipoProveedor(this.state.filtrotipoproveedor)
        }

    }
    filtro = (array) => {
        this.setState({
            listaproveedoresfiltrados: []
        });

        setTimeout(() => {
            this.setState({
                listaproveedoresfiltrados: array
            });
        }, 0);

    }


    filtrarTipoProveedor = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            filtrotipoproveedor: itemfiltrado
        })

        var filtrofinal = [];

        var listafiltrotipo = [];

        listafiltrotipo = data;
        if (itemfiltrado != -1) {

            listafiltrotipo = data.filter((item) => item.idtipo_proveedor == itemfiltrado)
        }


        filtrofinal = listafiltrotipo;

        if (this.state.txtbuscador.length > 2) {
            filtrofinal = listafiltrotipo.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaproveedoresfiltrados: filtrofinal
        })

    }

    borrarBusqueda = () => {
        this.setState({
            txtbuscador: '',
            filtrotipoproveedor: -1,
            listaproveedoresfiltrados: this.state.tabla.elementos
        });
    }

    seleccionarProveedor = async (item) => {
        await this.setState({
            proveedorseleccionado: item,
            confirmarenvio: true,
            idproveedorseleccionado: item.idcatalogo_proveedores
        })
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Proveedores disponibles</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row '>

                                    <div className=' containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelectModal' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarTipoProveedor(event.target.value)}>
                                                    {this.state.filtrotipoproveedor == -1 ?
                                                        <option selected="true" value={-1}>Todos</option> : <option value={-1}>Todos</option>}
                                                    {this.state.listatipoproveedoresfiltrados.map((i, index) => (
                                                        <option value={i.idtipo_proveedor} key={"tipo_" + index}  >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorContenedor' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorModal letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                placeholder="Buscar proveedor"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>

                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                    {
                                                        this.state.listaproveedoresfiltrados.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarProveedor(item)} className={this.state.idproveedorseleccionado == item.idcatalogo_proveedores ? "trFondoActivoGreen" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.nombre}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tipoproveedor}
                                                                </td>


                                                            </tr>
                                                        ))

                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

