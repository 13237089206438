//ModalEncargadosRancho
import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa'
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioRanchosCultivo.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnNuevo } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getComboFamiliaProducto, getComboTipoProducto, 
  getListaPuestosEncargados, getListaEncargados, updateEncargadosRanchos } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

import ModalBuscarUusarios from './ModalBuscarUsuarios'

const todoslosfiltrosdisponibles = -1

export default class ModalEncargadosRancho extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      txtbuscador: "",
      tabla: {
        encabezados: [
          "Nombre(s)",
          "Apellidos",
          "Puesto",
          ""
        ],
        dimensiones: [
          "30%",
          "30%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "120px",
          "120px",
          "120px",
          "120px",
        ],
        elementos: [],
      },

      todoslosproductosalmacen: props.todoslosproductosalmacen,//false
      listaproductosalmacen: props.listaproductosalmacen ? props.listaproductosalmacen : [],
      productoseleccionado: undefined,
      idproductoseleccionado: 0,
      productosseleccionados: {},
      //arrayidproductosseleccionados: 0,
      idestatus: 1,
      msgerror: '',

      // Modal deEncargados
      itemseleccionado: props.itemseleccionado,
      idareaseleccionada: todoslosfiltrosdisponibles,
      idpuestoseleccionada: todoslosfiltrosdisponibles,
      listaareas: props.listaareas,
      modalbuscarusuarios: false,
      titulomodal: '',
      accionmodal: 1,
      // 
      resplistapuestos: [],
      resplistausuarios: [],
      listaoriginalids: [],

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }

  async componentDidMount() {

    this.setState({ loadingspinners: true })

    var tabla = this.state.tabla;
    if (this.state.todoslosproductosalmacen) {
      tabla.elementos = this.state.itemseleccionado.encargados //this.props.respproductos;
    } else {
      tabla.elementos = this.filtrarProductosdeAlmacen(this.state.itemseleccionado.encargados)
    }
    var listaoriginalids = JSON.parse(JSON.stringify(tabla.elementos))
    listaoriginalids = listaoriginalids.map(i => i.idcatalogousuarios)

    this.setState({
      txtbuscador: '',
      tabla: tabla,
      listafamiliaproductos: this.props.listaareas,
      listatipoproductos: this.props.listapuestos,
      listatipoproductosfiltrados: this.props.listapuestos,
      listaproductosfiltrados: tabla.elementos,
      listaoriginalids: listaoriginalids
    })
    
    this.setState({ loadingspinners: false })
    
    await this.apisproductos();

  }

  apisproductos = async () => {
    if (this.state.resplistausuarios.length == 0) {
      var resplistausuarios = await getListaEncargados();
      if (this.manejadorLlamadasApis(resplistausuarios)) {
        return;
      }
      this.setState({ resplistausuarios: resplistausuarios.data })
    }

    if (this.state.listatipoproductos.length == 0) {
      var resplistapuestos = await getListaPuestosEncargados()
      if (this.manejadorLlamadasApis(resplistapuestos)) {
        return;
      }
      this.setState({ resplistapuestos: resplistapuestos.data })
    }else{
      this.setState({ resplistapuestos: this.state.listatipoproductos })
      
    }
  }

  filtrarProductosdeAlmacen(respproductos) {
    var productosdealmacen = [];

    this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
      var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogousuarios == productoalmacenrecibido.idcatalogousuarios)
      if (producto != undefined) {
        productosdealmacen = productosdealmacen.concat(producto)
      }
    });

    return productosdealmacen;
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    // Mandar datos del item de producto seleccionado
    //updateEncargadosRanchos
    const listaencargadosact = this.state.tabla.elementos
    const hizocambios = listaencargadosact
      .filter(i => this.state.listaoriginalids.includes(i.idcatalogousuarios))
      .length != listaencargadosact.length
    
    if (hizocambios) {
      var listaencargadosids = listaencargadosact
        .map(i => ({ idcatalogousuarios: i.idcatalogousuarios }))
      
      const idcatalogo_rancho = this.state.itemseleccionado.idcatalogo_rancho
      var json = {
        idcatalogo_rancho: idcatalogo_rancho,
        encargados: listaencargadosids
      }
      var resp = await updateEncargadosRanchos(json);

      if (resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return;
      }
      else if (resp.code == 200 || resp.code == 300) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        this.showModal({ accion: '1' })
      }
    }

    this.showModal(this.state.productosseleccionados)//productoseleccionado)
  }

  filtrarComboArea = async (idarea) => {

    await this.setState({ idareaseleccionada: idarea })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idarea, this.state.idpuestoseleccionada, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  filtrarComboPuesto = async (idpuesto) => {

    await this.setState({ idpuestoseleccionada: idpuesto })

    var filtroranchoscultivo = this.filtrarBusqueda(
      this.state.idareaseleccionada, idpuesto, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtroranchoscultivo = this.filtrarBusqueda(
        this.state.idareaseleccionada, this.state.idpuestoseleccionada, cadenain.toLocaleLowerCase())

      if (filtroranchoscultivo.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtroranchoscultivo)
    }
  }

  filtrarBusqueda = (idarea, idpuesto, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrozonasdisponibles = tabla.elementos;

    if (idarea != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idcatalogo_areas == idarea)
    }
    if (idpuesto != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idcatalogo_puestos == idpuesto)
    }

    if (textobusqueda.length < 3) {
      return filtrozonasdisponibles
    }

    return filtrozonasdisponibles.filter(
      (item) => item.nombres.toLocaleLowerCase().includes(textobusqueda))

  }

  filtro = (pagenumber, array) => {

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listaproductosfiltrados: array
      });
    }, 0);

  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      listaproductosfiltrados: this.state.tabla.elementos
    });
  }

  seleccionarProducto = async (item) => {
    var productosseleccionados = this.state.productosseleccionados
    var productoseleccionado = productosseleccionados[item.idcatalogousuarios]
    if (productoseleccionado === undefined) {
      productosseleccionados[item.idcatalogousuarios] = item
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: true, //idproductoseleccionado: item.idcatalogousuarios
      })
    } else {
      delete productosseleccionados[item.idcatalogousuarios]
      var confirmarenvio = Object.keys(productosseleccionados).length != 0
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: confirmarenvio, //idproductoseleccionado: item.idcatalogousuarios
      })
    }
    /*this.setState({
      productoseleccionado: item,
      confirmarenvio: true,
      idproductoseleccionado: item.idcatalogousuarios
    })*/
  }
  
  eliminar = (pos) => {
    var tabla = this.state.tabla;
    var elementos = tabla.elementos
    elementos.splice(pos, 1)
    tabla.elementos = elementos
    this.setState({ tabla: tabla })

  };

  verModalBuscarUsuarios = () => {
    this.setState({ titulomodal: 'Buscar usuarios de ranchos', accionmodal: 1, modalbuscarusuarios: true });
  }

  cerrarModalBuscarUsuarios = (ban) => {
    if (!(ban == 0) && !(ban == undefined)) {
      var tabla = this.state.tabla
      var idsregistrados = []
      tabla.elementos.forEach(item => idsregistrados.push(item.idcatalogousuarios))
      Object.values(ban)
        .filter(item1 => !idsregistrados.includes(item1.idcatalogousuarios))
        .forEach(item => tabla.elementos.push(item))
      this.setState({ tabla: tabla })
    }
    this.setState({ modalbuscarusuarios: !this.state.modalbuscarusuarios })
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}
        
        {(this.state.modalbuscarusuarios == true) ? (
          <ModalBuscarUusarios titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalBuscarUsuarios}
            show={this.state.modalbuscarusuarios} accion={this.state.accionmodal}
            listapuestos={this.state.resplistapuestos} listausuarios={this.state.resplistausuarios}
          />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados' style={{ maxWidth: '280px' }}>
                    <div className='divComboSelect' style={{ maxWidth: '270px', color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Área</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarComboArea(event.target.value)}>
                          <option selected={this.state.idareaseleccionada == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todas</option>
                          {this.state.listaareas.map((i, index) => (
                            <option value={i.idcatalogo_areas} key={"area_" + index}  >{i.area} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados' style={{ maxWidth: '280px' }}>
                    <div className='divComboSelect' style={{ maxWidth: '270px', color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Puesto</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarComboPuesto(event.target.value)}>
                          <option selected={todoslosfiltrosdisponibles == this.state.idpuestoseleccionada} value={todoslosfiltrosdisponibles}>Puesto</option>
                          {this.state.listatipoproductosfiltrados.map((i, index) => (
                            <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='txtBuscadorCN' >
                      <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                      <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                        placeholder="Buscar nombre"></input>
                      {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                        onClick={this.borrarBusqueda} />) : null}
                    </div>
                  </div>

                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <button className='btnNuevo letraNormal' onClick={this.verModalBuscarUsuarios}
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}>
                      <b>+</b> Buscar
                    </button>
                  </div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {
                            this.state.listaproductosfiltrados.map((item, index) => (
                              <tr key={"tabla" + index} onClick={() => this.seleccionarProducto(item)}>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.nombres}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.apellidos}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.puesto}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                  <button className='btnEliminarFila' onClick={this.eliminar.bind(this, index)}>
                                    <FaTrashAlt className="icoBotones" />
                                  </button>
                                </td>
                                {/*<td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.descripcion}
                                </td>*/}

                              </tr>
                            ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

              {/*this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.itemseleccionado.fechacreacion}
                    <br />
                    Actualizado: {this.props.itemseleccionado.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null*/}
            </div>


            <br />
            <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
              <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
            </div>
            <br />
            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
            </div>
            {/*<div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
            </div>*/}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

