import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoRequisicionesGenerales.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { BsFillEyeFill } from 'react-icons/bs';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo,
  estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estiloBtnVerde
} from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getCatalogoRequisicionesGenerales, getListaTipoRequisicionGeneral,

  getEstatus, getComboTipoDocumentos, getListaCatalogoDocumentos,
  getListaEstatusRequisicion
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import ModalEstatusRequisicion from '../../Complements/Modales/ModalFormularioRequisicionesGenerales/ModalEstatusRequisicion'
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalFormularioRequisicionesGenerales/ModalFormularioAccionEstatus'
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalFormularioRequisicionesGenerales/ModalFormularioAccionEstatusContrasena'

const todaslasopcionesdisponibles = -1
const coloresporestatus = {
  1: 'btnDisponible', 2: 'btnEsperaAutorizacionreqs', 3: 'btnAutorizado', 4: 'btnRechazado', 5: 'btnCancelado',
  6: 'btnDisponible', 7: 'btnDisponible', 8: 'btnCompletado', 9: 'btnCompletado', 11:'btnAutorizado', 10: 'btnRechazado'
}
const coloresestatuscompra = { 1: 'lblPendiente', 2: 'lblEnProceso', 3: 'lblFinalizado', 4: 'lblDetenido' }

export default class CatalogoRequisicionesGenerales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtbuscador: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      filtrolistapaginada: [],
      elementosfiltrados: [],
      loadingspinners: true,
      idtiporequisicionseleccionada: todaslasopcionesdisponibles,
      arraytipos: [],

      tabla: {
        encabezados: [
          "Nombre",
          "Tipo",
          "Entrega",
          "Actualizado",
          "Estatus de requisición",
          "",
          ""
        ],
        dimensiones: [
          "30%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "300px",
          "150px",
          "100px",
          "100px",
          "200px",
          "160px",
          "180px"
        ],
        elementos: []
      },
      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      //modaldetalles: false,
      //modaldetallesdocumentos: false,
      itemseleccionado: null,

      // Documentos para modal: lista de documentos
      listatiposdocumento: [],
      listadocumentos: [],

      // Modal estatus requisicion
      modalestatusrequisicion: false,
      // Modal accion seleccionada de estatus requisición
      modalaccionestatus: false,
      // Modal de solicitud de contraseñas para rechazar/aprobar requisición
      modalaccionestatuscontrasena: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],


      redireccionando: false,
      redireccionandobandera: '',
      errordeacceso: false,


      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

      colorfondobtnverde: estiloBtnVerde().colorfondobtnverde,
      colorbtnverde: estiloBtnVerde().colorbtnverde,
      colorbordebtnverde: estiloBtnVerde().colorbordebtnverde,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }
    this.setState({ txtbuscador: '' })

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var respestatusrequisiciones = await getListaEstatusRequisicion()
    if (this.manejadorLlamadasApis(respestatusrequisiciones)) {
      //return;
    }
    //console.log(respestatusrequisiciones)
    var resptiporequisiciones = await getListaTipoRequisicionGeneral()
    if (this.manejadorLlamadasApis(resptiporequisiciones)) {
      return;
    }

     // Modal agregar documentos
    // Inicia seción de APIs para selectores del modal Documentos
    var resptipodocumento = await getComboTipoDocumentos();
    if (this.manejadorLlamadasApis(resptipodocumento)) {
      return;
    }
    var respdocumento = await getListaCatalogoDocumentos();
    if (this.manejadorLlamadasApis(respdocumento)) {
      return;
    }

    var respcatalogorequicisiones = await getCatalogoRequisicionesGenerales()
    if (this.manejadorLlamadasApisComodin(respcatalogorequicisiones)) {
      return;
    }

    const auxiliarcatalogorequisiciones = respcatalogorequicisiones.data.map((elemento) => {
      elemento.idcatalogo_requisicion = parseInt(elemento.idcatalogo_requisicion, 10)
      elemento.idestatusrequisicion = parseInt(elemento.idestatusrequisicion, 10)
      elemento.idtiporequisicion = parseInt(elemento.idtiporequisicion, 10)
      return elemento
    })
    var tabla = this.state.tabla;
    tabla.elementos = auxiliarcatalogorequisiciones

    await this.setState({ tabla: tabla, estatus: estatus.data, arraytipos: resptiporequisiciones.data })

   
    this.setState({
      listatiposdocumento: resptipodocumento.data,
      listadocumentos: respdocumento.data
    })

    this.setState({ loadingspinners: false })
    this.dimensiones();
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
   
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 ||resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 200) {
      this.setState({ loadingspinners: false })
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      return;
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }


  manejadorLlamadasApisComodin = (resp) => {
    if (resp == -1 ||resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ loadingspinners: true });
    this.setState({ txtbuscador: '' });
    this.setState({ idtiporequisicionseleccionada: todaslasopcionesdisponibles });
    this.setState({ filtrolistapaginada: [] });
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  filtrarCombo = async (idtipo) => {

    await this.setState({ idtiporequisicionseleccionada: idtipo })

    var filtrorequisiciones = this.filtrarBusqueda(
      idtipo, this.state.txtbuscador.trim().toLocaleLowerCase())

    if (filtrorequisiciones.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtrorequisiciones)

  }

  saveInputLocacion = async (e) => {

    var cadenain = e.target.value.trim()
    var tamanocadenain = cadenain.length
    var tamanocadenast = this.state.txtbuscador.trim().length
    var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

    this.setState({ txtbuscador: e.target.value })
    if (tamanocadenain >= 3 || banderacasosespeciales) {

      var filtrorequisiciones = this.filtrarBusqueda(
        this.state.idtiporequisicionseleccionada, cadenain.toLocaleLowerCase())

      if (filtrorequisiciones.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.filtro(1, filtrorequisiciones)
    }

  }

  filtrarBusqueda = (idtipo, textobusqueda) => {

    var tabla = JSON.parse(JSON.stringify(this.state.tabla))
    var filtrotiporequisicion = tabla.elementos;

    if (idtipo != todaslasopcionesdisponibles) {
      filtrotiporequisicion = filtrotiporequisicion.filter((item) => item.idtiporequisicion == idtipo)
    }

    if (textobusqueda.length < 3) {
      return filtrotiporequisicion
    }

    return filtrotiporequisicion.filter(
      (item) => item.nombre.toLocaleLowerCase().includes(textobusqueda)
        || new String(item.clave).toLocaleLowerCase().includes(textobusqueda))

  }

  verModalEstatusRequisicion = (ban) => {
    if (ban.opcion == 1) {
      this.setState({ titulomodal: 'Cambiar estatus de requisición', accionmodal: 1, modalestatusrequisicion: true, itemseleccionado: JSON.parse(JSON.stringify(ban.item)) });
    }
  }

  cerrarModalEstatusRequisicion = (item) => {
    this.setState({ modalestatusrequisicion: !this.state.modalestatusrequisicion })
    if (item.id == 1) {
      this.verModalFormularioAccionEstatus(item)
    }
    else if (item.id == 2) {
      this.verModalFormularioAccionEstatusContrasena(item)
    }
    else if (item.id == 3) {
      this.verModalFormularioAccionEstatusContrasena(item)
    }
    else if (item.id == 4) {
      if (item.accion == 1) {
        this.actualizarTabla()
      }
    }
  }

  verModalFormularioAccionEstatus = (ban) => {
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2) {
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatus: true });
    }
    else if (ban.id == 3) {
      this.setState({ titulomodal: 'Aprobar requisición', accionmodal: ban.id, modalaccionestatus: true });
    }
    else if (ban.id == 4) {
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatus: true });
    }
  }

  cerrarModalFormularioAccionEstatus = (opciones) => {
    this.setState({ modalaccionestatus: !this.state.modalaccionestatus })
    if (opciones.accion == 1) {
      this.verModalFormularioAccionEstatusContrasena(opciones)
    }
  }

  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.accion >= 1 && ban.accion <= 4) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.id == 1) {
      if (opciones.accion == 1) {
        this.actualizarTabla()
      }
    }
    else if (opciones.id == 2) {
      if (opciones.accion == 1) {
        this.actualizarTabla()
      }
    }
    else if (opciones.id == 3) {
      if (opciones.accion == 1) {
        this.actualizarTabla()
      }
    }
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  borrarBusqueda = () => {
    this.saveInputLocacion({ target: { value: '' } })
  }

  redireccionar = ({ idrequisicion, opcion, opcioneditar }) => {

    if (['AutorizarCotizacion', 'Cotizacion', 'Requisicion'].includes(opcion)) {
      localStorage.setItem("dato", [idrequisicion]);
      this.setState({ redireccionando: true, redireccionandobandera: opcion })
    } else if (opcion == 'EditarRequisicion') {
      localStorage.setItem("req", JSON.stringify({ idreq: idrequisicion, opcion: opcioneditar }))
      this.setState({ redireccionando: true, redireccionandobandera: opcion })
    }
  }

  actualizarListaDocumentos = async (idcatalogorequisicion) => {
    this.setState({ loadingspinners: true, errordeacceso: false })

    await this.componentDidMount()

    var elementos = this.state.tabla.elementos
    var items = elementos.filter((item) => item.idcatalogo_requisicion == idcatalogorequisicion)
    var item = items[0] ? items[0] : {}

    this.setState({ itemseleccionado: JSON.parse(JSON.stringify(item)), loadingspinners: false })
    return item
  }

  render() {
    return (
      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesRequisiciones bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? <AccionesRequisiciones bandera={this.state.redireccionandobandera} /> : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalestatusrequisicion == true) ? (
          <ModalEstatusRequisicion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalEstatusRequisicion} estatus={this.state.estatus}
            show={this.state.modalestatusrequisicion} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            arraytipos={JSON.parse(JSON.stringify(this.state.arraytipos))} />
        ) : null}

        {(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados' style={{ minWidth: '200px' }} >
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Requisiciones productos generales </label>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipos</label>
                  <div className="selectBox ">
                    <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                      onChange={(event) => this.filtrarCombo(event.target.value)} >
                      {<option selected={this.state.idtiporequisicionseleccionada == todaslasopcionesdisponibles} value={todaslasopcionesdisponibles}>Todos</option>}
                      {this.state.arraytipos.map((i, index) => (
                        <option value={i.idtipo_requisicion_general} key={"tipo_" + index} selected={this.state.idtiporequisicionseleccionada == i.idtipo_requisicion_general} >{i.tipo} </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar nombre"></input>
                  {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                    onClick={this.borrarBusqueda} />) : null}
                </div>
              </div>

              <div className='col containerSubTabla'></div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
              </div>

              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}

                  onClick={this.redireccionar.bind(this, { idrequisicion: '0', opcion: 'EditarRequisicion', opcioneditar: false })}
                >+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid anchoMaximoConScrollCRC containerTabla">
          <div className="container-fluid anchoMaximoConScrollCRC ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>

                  {this.state.txtbuscador.length < 3 && this.state.idtiporequisicionseleccionada == todaslasopcionesdisponibles ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                      
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombre}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.tiporequisicion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.fechaestimada_entrega}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.ultimaactualizacion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          <div className='celdaDosElementos' >
                            {(item.idestatusrequisicion != 6 && item.idestatusrequisicion != 7) ? (
                              <button className={'btnEstandar btnBase ' + coloresporestatus[item.idestatusrequisicion]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                onClick={this.verModalEstatusRequisicion.bind(this, { opcion: "1", item: item })} style={{ minWidth: "186px" }}> {item.estatusrequisicion}
                              </button>
                            ) : (

                              item.autorizarcotizaciones == 0 ? (
                                <button className={'btnEstandar btnBase ' + coloresporestatus[item.idestatusrequisicion]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                  onClick={this.verModalEstatusRequisicion.bind(this, { opcion: "1", item: item })} style={{ minWidth: "186px" }}> {item.estatusrequisicion}
                                </button>
                              ) : (
                                <button className={'btnEstandar btnBase ' + coloresporestatus[2]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                  style={{ minWidth: "186px" }}> {item.estatuscotizacion}
                                </button>
                              )

                            )}

                          </div>
                        </td>

                        {([3, 6, 7, 8, 10,11].includes(item.idestatusrequisicion)) ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{    verticalAlign: "middle", minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                          <div className='celdaDosElementos' >
                          <button className='btnBase btnEstandar btnDisponible'
                              onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Requisicion' })}
                              style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }} >
                              <BsFillEyeFill className="icoBotones" />
                              <b className='btnVerTabla'>Ver requisición</b>
                            </button>
                          </div>
                           
                          </td>
                        ) : null}
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          {[1, 2, 4].includes(item.idestatusrequisicion) ?
<>{/*<button className='btnEditar' onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'EditarRequisicion', opcioneditar: true })}
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, marginLeft: "4px", width: '160px' }}>
                              <BiEdit className="icoBotones" /> Editar
                            </button>*/}
                            <button className='btnBase btnEstandar btnDisponible'
                              onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Requisicion' })}
                              style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }} >
                              <BsFillEyeFill className="icoBotones" />
                              <b className='btnVerTabla'>Ver requisición</b>
                            </button>
                            </>
                            :
                            ([3, 6, 7,10,11].includes(item.idestatusrequisicion)) ?
                              <div className='celdaDosElementos'>
                                <button className='btnCotizacion' style={{ width: "160px" }}
                                  onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Cotizacion' })}>
                                  Cotización
                                </button>
                               
                              </div>
                              : item.idestatusrequisicion == 8 ?
                                <button className='btnBase btnEstandar btnDisponible'
                                  onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'AutorizarCotizacion' })}
                                  style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }}  >
                                  <BsFillEyeFill className="icoBotones" />
                                  <b className='btnVerTabla'>Ver cotización</b>
                                </button>
                                : null
                          }
                        </td>
                        {([1, 2, 4].includes(item.idestatusrequisicion)) ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >

                          </td>
                        ) : null}
                      </tr>
                    ))
                    :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                       
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                          {item.nombre}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                          {item.tiporequisicion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                          {item.fechaestimada_entrega}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                          {item.ultimaactualizacion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                          <div className='celdaDosElementos' >
                          {(item.idestatusrequisicion != 6 && item.idestatusrequisicion != 7) ? (
                              <button className={'btnEstandar btnBase ' + coloresporestatus[item.idestatusrequisicion]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                onClick={this.verModalEstatusRequisicion.bind(this, { opcion: "1", item: item })} style={{ minWidth: "186px" }}> {item.estatusrequisicion}
                              </button>
                            ) : (

                              item.autorizarcotizaciones == 0 ? (
                                <button className={'btnEstandar btnBase ' + coloresporestatus[item.idestatusrequisicion]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                  onClick={this.verModalEstatusRequisicion.bind(this, { opcion: "1", item: item })} style={{ minWidth: "186px" }}> {item.estatusrequisicion}
                                </button>
                              ) : (
                                <button className={'btnEstandar btnBase ' + coloresporestatus[2]} disabled={![1, 2, 4].includes(item.idestatusrequisicion)}
                                  style={{ minWidth: "186px" }}> {item.estatuscotizacion}
                                </button>
                              )

                            )}
                            
                           
                          </div>
                        </td>
                        {([3, 6, 7, 8,10,11].includes(item.idestatusrequisicion)) ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{verticalAlign: "middle",  minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                            <button className='btnBase btnEstandar btnDisponible'
                              onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Requisicion' })}
                              style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }} >
                              <BsFillEyeFill className="icoBotones" />
                              <b className='btnVerTabla'>Ver requisición</b>
                            </button>
                          </td>
                        ) : null}
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >
                          {[1, 2, 4].includes(item.idestatusrequisicion) ?
                             <>
                            {/*<button className='btnEditar' onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'EditarRequisicion', opcioneditar: true })}
                              style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, marginLeft: "4px", width: '160px' }}>
                              <BiEdit className="icoBotones" /> Editar
                            </button>*/}

                            <button className='btnBase btnEstandar btnDisponible'
                              onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Requisicion' })}
                              style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }} >
                              <BsFillEyeFill className="icoBotones" />
                              <b className='btnVerTabla'>Ver requisición</b>
                            </button>
                             </>
                            :
                            ([3, 6, 7,10,11].includes(item.idestatusrequisicion)) ?
                              <div className='celdaDosElementos'>
                                <button className='btnCotizacion' style={{ width: "160px" }}
                                  onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'Cotizacion' })}>
                                  Cotización
                                </button>
                               
                              </div>
                              : item.idestatusrequisicion == 8 ?
                                <button className='btnBase btnEstandar btnDisponible'
                                  onClick={this.redireccionar.bind(this, { idrequisicion: item.idcatalogo_requisicion, opcion: 'AutorizarCotizacion' })}
                                  style={{ textAlign: "center", color: this.state.colorverlbl, width: "160px", marginLeft: "4px" }}  >
                                  <BsFillEyeFill className="icoBotones" />
                                  <b className='btnVerTabla'>Ver cotización</b>
                                </button>
                                : null
                          }
                        </td>
                        {([1, 2, 4].includes(item.idestatusrequisicion)) ? (
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }} >

                          </td>
                        ) : null}
                      </tr>

                    ))
                  }


                </tbody>
              </Table>
            </div>

          </div>
        </div>
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 && this.state.idtiporequisicionseleccionada == todaslasopcionesdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}


function AccionesRequisiciones({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'Requisicion') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/autorizarrequisiciongeneral"} />)
  }
  // Actualizado
  if (bandera == 'EditarRequisicion') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/editarrequisiciongeneral"} />)
  }
  if (bandera == 'AutorizarCotizacion') {
    history.push(location.pathname);
    return (<Redirect from='/'  to={"/cotizacionproductogeneral"} /*to={"/autorizarcotizacionesproductogeneral"}*/ />)
  }
  if (bandera == 'Cotizacion') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/cotizacionproductogeneral"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}
