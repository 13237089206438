import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalDocumentos.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnNuevo } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

import { AiOutlineFileDone, AiOutlineFileImage, AiOutlineFileWord } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { ImDownload3 } from 'react-icons/im'
import { GrDocumentPdf, GrDocumentTxt } from 'react-icons/gr';


export default class ModalDocumentos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      modalautorizacion: false,
      modalautorizaciontitulo: '',
      itemseleccionado: null,
      accionmodal: 1,
      accionmodaldocumento: 1,
      titulomodal: '',
      modaldetallesdocumentos: false,
      titulomodaldocumento: '',

      // Modal agregar documentos
      listatiposdocumento: props.listatiposdocumento,
      listadocumentos: props.listadocumentos,

      txtbuscador: "",
      tablaX: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Descripción",
          "Creado",
          ""
        ],
        dimensiones: [
          "15%",
          "15%",
          "15%",
          "25%",
          "15%",
          "15%"
        ],
        mindimensiones: [
          "120px",
          "120px",
          "120px",
          "200px",
          "120px",
          "120px"
        ],
        elementos: [],
      },

      tabla: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Descripción",
          "Creado",
          ""
        ],
        dimensiones: [
          "14%",
          "14%",
          "20%",
          "20%",
          "20%",
          "12%"
        ],
        mindimensiones: [
          "140px",
          "130px",
          "180px",
          "200px",
          "200px",
          "150px"
        ],
        titulo: 'Documentos',
        elementos: []
      },

      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    var tabla = this.state.tabla;

    var elementos = [
      {
        documento: "Doc 1",
        tipo_documento: "TipoDoc 1",
        extension: 'pdf',
        idcatalogo_producto: '1',
        idcatalogo_producto_documento: '1',
        archivo: "Documento_1_1.pdf",
        descripcion: "",
        fechacreacion: "12/12/2022",
        rutadocumento: '#'
      },
      {
        documento: "Doc 2",
        tipo_documento: "TipoDoc 2",
        extension: 'pdf',
        idcatalogo_producto: '1',
        idcatalogo_producto_documento: '2',
        archivo: "Documento_1_1.pdf",
        descripcion: "",
        fechacreacion: "12/12/2022",
        rutadocumento: '#'
      }
    ]
    tabla.elementos = elementos;
    this.setState({ txtbuscador: '', tabla: tabla })

    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  guardarCambios = async () => {
    this.showModal('0')
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos"></label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row paddingEncabezados align-items-end' style={{ minWidth: '260px', marginBottom: "6px", padding: "8px" }}>
                  <div className="col-auto">
                    <label className="lblTituloHoja letraTitulos">{this.state.titulo}</label>
                  </div>
                  <div className='col ' ></div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ margin: '0px', padding: '0px' }}>
                    <div className="containerSubTabla" style={{ padding: "4px" }}>
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {this.state.tabla.elementos.map((item, index) => (
                            <tr key={"tabla" + index}>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.documento}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                {item.tipo_documento}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                {item.extension == "pdf" ? <GrDocumentPdf /> :
                                  item.extension == "txt" ? <GrDocumentTxt /> :
                                    item.extension == "csv" ? <RiFileExcel2Line /> :
                                      item.extension == "doc" || item.extension == "docx" ? <AiOutlineFileWord /> :
                                        item.extension == "jpg" || item.extension == "jpeg" || item.extension == "png" ? <AiOutlineFileImage /> : <AiOutlineFileDone />
                                }
                                {"Documento_" + item.idcatalogo_producto + "_" + item.idcatalogo_producto_documento + "." + item.extension}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorcontenidotabla }}>
                                {item.descripcion}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorcontenidotabla }}>
                                {item.fechacreacion}
                              </td>
                              <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: "150px", width: "12%" }}>
                                <div style={{ display: 'inline-flex' }}>
                                  <a href={item.rutadocumento} download>
                                    <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                      <ImDownload3 className="icoBotonesTrash" />
                                    </div>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br />

          </ModalBody>

        </Modal>
      </>
    )
  }

}

