import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioDetalleOrdenCosecha.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumeros } from '../../../Services/Validaciones';
import { getDetallesOrdenCosechaGuardarTabla } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioNuevoPlanCosecha extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,

            accion: props.accion,
            titulo: props.titulomodal,
            rancho:this.props.infodetalles.ordencosecha.idcatalogo_rancho,
            lote: this.props.infodetalles.ordencosecha.idcatalogo_ranchos_lotes_cultivo,
            tabla: (props.accion == 6) ? (props.itemseleccionado.idcatalogo_lotecultivo_tabla) : 0,
            cultivo: 0,
            txtrejas: '',

            idordecosecha: this.props.idordecosecha != undefined ? this.props.idordecosecha : 0,
            idcatalogo:(props.accion == 6) ? (props.itemseleccionado.idcatalogo_ordenes_cosecha_tablas) : 0,
            listalotes: this.props.listalotes != undefined ? this.props.listalotes : [],
            listatablas: this.props.listatablas != undefined ? this.props.listatablas : [],
            //listacultivos: this.props.listacultivos != undefined ? this.props.listacultivos : [],

            vermsgerrorlote: false,
            vermsgerrortabla: false,
            vermsgerrorcultivo: false,
            vermsgerrortxtrejas: false,

            msgerrorlote: "",
            msgerrortabla: "",
            msgerrorcultivo: "",
            msgerrortxtrejas: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }
    async componentDidMount() {
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    guardarCambios = async () => {
        let longitudnuevevalida = { longitudminima: 1, longitudmaxima: 9 };
        let validacionescorrectas = true;

        this.setState({ vermsgerrortabla: false })      

        if (this.state.tabla < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortabla: "Seleccione una tabla" })
            this.setState({ vermsgerrortabla: true })
        }



        if (validacionescorrectas) {
            this.setState({ loadingspinners: true })

            let json = {
                "idcatalogo_ordenes_cosecha_tablas": this.state.idcatalogo,
                "idcatalogo_ordenes_cosecha": this.state.idordecosecha,
                "idcatalogo_lotes_cultivo_tabla": this.state.tabla,
                "eliminar": 0
            }

            var resp = await getDetallesOrdenCosechaGuardarTabla(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 6) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.props.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.props.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                            
                                {/* ////////////////////   Inicio seleccion tabla  ////////////////////////////////////// */}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tabla </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tabla")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listatablas == undefined || this.state.listatablas.length == 0 ? null : this.state.listatablas.map((i) => (
                                                    this.state.lote == i.idcatalogo_ranchos_lotes_cultivo ?
                                                        <option 
                                                            value={i.idcatalogo_lotecultivo_tabla} 
                                                            key={"tipo_" + i.idcatalogo_lotecultivo_tabla}
                                                            selected={this.state.tabla == i.idcatalogo_lotecultivo_tabla ? true: false}>
                                                                {i.tabla}
                                                        </option> : null
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrortabla ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortabla}</p> : null}


                              

                              

                            </div>
                        </div>

                        <br />
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

