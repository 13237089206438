import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal, estiloBtnNuevo } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { AiOutlineFileDone, AiOutlineFileImage, AiOutlineFileWord } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { BsTrash } from "react-icons/bs"
import { ImDownload3 } from 'react-icons/im'
import { GrDocumentPdf, GrDocumentTxt } from 'react-icons/gr';
import ModalAutorizacion from './ModalAutorizacion/ModalAutorizacion';
import ModalFormularioAgregarDocumentos from './ModalFormularioAgregarDocumento';


export default class ModalListaDocumentos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      loadingspinners: false,

      modalautorizacion: false,
      modalautorizaciontitulo: '',
      itemseleccionado: null,
      itemrequisicion: props.item,
      accionmodal: 1,
      accionmodaldocumento: 1,
      titulomodal: '',
      modaldetallesdocumentos: false,
      titulomodaldocumento: '',

      // Modal agregar documentos
      listatiposdocumento: props.listatiposdocumento,
      listadocumentos: props.listadocumentos,
      listadocumentosrequisicion: [],

      tabla: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Descripción",
          "Creado",
          ""
        ],
        dimensiones: [
          "14%",
          "14%",
          "20%",
          "20%",
          "20%",
          "12%"
        ],
        mindimensiones: [
          "140px",
          "130px",
          "180px",
          "200px",
          "200px",
          "150px"
        ],
        titulo: 'Documentos'
      },

      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    var listadocumentosrequisicion = this.state.itemrequisicion ? this.state.itemrequisicion.documentos : []

    this.setState({ listadocumentosrequisicion: listadocumentosrequisicion })
    this.setState({ loadingspinners: false })

  }

  actualizarListaDocumentosChild = async () => {
    this.setLoadingSpinner(false)
    var itemrequisicion = await this.props.actualizarListaDocumentos(this.state.itemrequisicion.idcatalogo_requisicion)
    var listadocumentosrequisicion = itemrequisicion ? itemrequisicion.documentos : []
    this.setState({ itemrequisicion: itemrequisicion, listadocumentosrequisicion: listadocumentosrequisicion })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  guardarCambios = async () => {
    this.showModal('0')
  }

  onNuevoElementoClick = () => {
    //console.log("Agregar nuevo archivo");
  }

  onEliminarClick = (band) => {
    //console.log("Eliminación de archivos")
    this.verModalEliminarItem({ contenido: band.contenido, eliminar: band.eliminar });
  }

  verModalDetallesDocumentos = (ban) => {
    if (ban.opcion == 'agregar_documento') { //Nuevo
      this.setState({ titulomodaldocumento: 'Agregar documento', accionmodaldocumento: 1 })
    }
    this.setState({ modaldetallesdocumentos: true });
  }

  cerrarModalDocumentos = async (accion) => {
    this.setState({ modaldetallesdocumentos: !this.state.modaldetallesdocumentos })
    if (accion == 1) {
      await this.actualizarListaDocumentosChild()
    }
  }

  verModalEliminarItem = async (ban) => {
    this.setState({
      modalautorizaciontitulo: `Eliminar archivo`,
      modalautorizacion: !this.state.modalautorizacion,
      itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)),
      accionmodaldocumento: ban.eliminar
    })
  }

  cerrarModalAtutorizacion = async (accion) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (accion == 1) {
      await this.actualizarListaDocumentosChild()
    }
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetallesdocumentos == true) ? (
          <ModalFormularioAgregarDocumentos
            titulomodal={this.state.titulomodaldocumento}
            cerrarModal={this.cerrarModalDocumentos}
            show={this.state.modaldetallesdocumentos}
            accion={this.state.accionmodaldocumento}
            itemrequisicion={this.state.itemrequisicion}
            setloadingspinner={this.setLoadingSpinner}
            listatiposdocumento={JSON.parse(JSON.stringify(this.state.listatiposdocumento))}
            listadocumentos={JSON.parse(JSON.stringify(this.state.listadocumentos))} />
        ) : null}

        {(this.state.modalautorizacion == true) ? (
          <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
            cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodaldocumento} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos"></label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row paddingEncabezados align-items-end' style={{ minWidth: '260px', marginBottom: "6px", padding: "8px" }}>
                  <div className="col-auto">
                    <label className="lblTituloHoja letraTitulos">{this.state.titulo}</label>
                  </div>
                  <div className='col ' ></div>
                  <div className="col-auto align-items-end" >
                    <button className='btnNuevo letraNormal' onClick={this.verModalDetallesDocumentos.bind(this, { opcion: 'agregar_documento' })}
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}// style={{maxWidth: '1400px !important'}}
                    >+ Nuevo</button>
                  </div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ margin: '0px', padding: '0px' }}>
                    <div className="containerSubTabla" style={{ padding: "4px" }}>
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {this.state.listadocumentosrequisicion.map((item, index) => (
                            <tr key={"tabla" + index}>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.documento}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                {item.tipo_documento}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                {item.extension == "pdf" ? <GrDocumentPdf /> :
                                  item.extension == "txt" ? <GrDocumentTxt /> :
                                    item.extension == "csv" ? <RiFileExcel2Line /> :
                                      item.extension == "doc" || item.extension == "docx" ? <AiOutlineFileWord /> :
                                        item.extension == "jpg" || item.extension == "jpeg" || item.extension == "png" ? <AiOutlineFileImage /> : <AiOutlineFileDone />
                                }
                                {"Documento_" + item.idcatalogo_requisiciones + "_" + item.idcatalogo_requisiciones_documentos + "." + item.extension}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorcontenidotabla }}>
                                {item.descripcion}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorcontenidotabla }}>
                                {item.fechacreacion}
                              </td>
                              <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: "150px", width: "12%" }}>
                                <div style={{ display: 'inline-flex' }}>
                                  <a href={item.rutadocumento} download>
                                    <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                      <ImDownload3 className="icoBotonesTrash" />
                                    </div>
                                  </a>
                                  <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                    <BsTrash onClick={this.verModalEliminarItem.bind(this, { contenido: item, eliminar: 'doc_requisicion' })} className="icoBotonesTrash" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br />

          </ModalBody>

        </Modal>
      </>
    )
  }

}

