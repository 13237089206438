import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
//--import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';

import { Table } from 'reactstrap';


export default class ModalSeleccionarDestino extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      item: props.item,
      accion: props.accion,
      listaoperacionesestatus: props.listadestinos,      
      idoperacionseleccionada: 0,      
      idestatusactual: props.item.idestatusrequisicion,  
      estilosmodal: estilosModal(),
      colortitulo: estiloFiltroEncabezados().colortitulos,
      idseleccionado: '',

    }

  }
  async componentDidMount() {
  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  showModal = (opcion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opcion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  seleccionarOperacion = async (item) => {
    this.setState({ idoperacionseleccionada: item.idtipo_destino })
    this.setState({ idseleccionado: item.idtipo_destino })
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Selecciona un tipo de destino: </p>


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {
                            this.state.listaoperacionesestatus
                            
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion(item)}
                                  className={this.state.idoperacionseleccionada == item.idtipo_destino ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {item.tipo}
                                  </td>
                                </tr>
                              ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    disabled={this.state.idseleccionado ? false : true}
                    onClick={() => this.showModal({ accion: 1, id: this.state.idseleccionado})}>
                    Continuar
                  </Button>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal >
      </>
    )
  }
}
