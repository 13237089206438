import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasConEspacios,validarLetrasNumerosLongitud, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaZonas } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalSeleccionActividades extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            titulo: props.titulomodal,
            txtbuscador:"",
            estilosmodal: estilosModal(),
            filrotipoactividad: -1,
            listatipoactividad: this.props.listatipoactividad,
            listaactividades: this.props.listaactividades,
            listaactividadesfiltradas: this.props.listaactividades,
            listaactividadesseleccionadas: [],
            listaactividadesagregadas: this.props.listaactividadesagregadas,
            actividadseleccionada: props.actividadseleccionada,

            tabla: {
                encabezados: [
                    "Actividad",
                    "Tipo",
                ],
                dimensiones: [
                    "15%",
                    "15%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                ],
                elementos: [],
            },
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }
    async componentDidMount() {

    

    }

    filtrarTipoActividad = async (itemfiltrado) => {
        var data = JSON.parse(JSON.stringify(this.state.listaactividades))
      
        await this.setState({ 
            filrotipoactividad: itemfiltrado
        })
      
        var filtrofinal = [];
    
        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        if(itemfiltrado != -1){
      
          listafiltrotipo = data.filter((item) => item.idtipoactividad == itemfiltrado)
        }
    
        filtrofinal = listafiltrotipo;
    
        if(this.state.txtbuscador.length>2){
            filtrofinal = listafiltrotipo.filter((item) => item.actividad.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }
        
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaactividadesfiltradas: filtrofinal
        })
      
      }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data = JSON.parse(JSON.stringify(this.state.listaactividades))

        var filtrofinal = [];

        var listafiltrofamilia = [];
        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        
    

        if(this.state.filrotipoactividad != -1){
            listafiltrotipo = data.filter((item) => item.idtipoactividad == this.state.filrotipoactividad)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => item.actividad.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
           await this.setState({ listaactividadesfiltradas: filtros });
        }else if(e.target.value.trim().length < 1){
            this.filtrarTipoActividad(this.state.filrotipoactividad)
        }

    }

    borrarBusqueda = async () => {
       await this.setState({
            txtbuscador: ''
        });
        this.filtrarTipoActividad(this.state.filrotipoactividad)
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if (resp.code== 401) {
          this.setState({ salir: true })
          return true;
        }
        else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if(resp.code == 300){
          if(!this.state.errordeacceso){
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
            
          this.setState({ 
            loadingspinners: false,
            errordeacceso:true })
          return true
        }
        return false
      }

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    continuarProceso = async () => {
       
                
        this.showModal( this.state.actividadseleccionada)
          
    }

    seleccionarItem = async (itemseleccionado) => {
        
        await this.setState({
            actividadseleccionada: itemseleccionado,
            confirmarenvio: true
        })
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal(0)} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos lblSelect' style={{ color: this.state.colorlupa }}>Tipo actividad</label>
                                            <div className="selectBox selectOption">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarTipoActividad(event.target.value)}>
                                                    {this.state.filrotipoactividad == -1 ?
                                                        <option selected="true" value={-1}>Todos</option> : <option value={-1}>Todos</option>}
                                                    {this.state.listatipoactividad.map((i, index) => (
                                                        <option value={i.idtipoactividad} key={"tipo_" + index}  >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.colorbordertipo }}
                                                placeholder="Buscar actividad"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='row '>
                                    <div className='col-12 containerSubTabla  '>
                                        <label className='letraTitulos'>Selecciona las actividades: </label>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="container-fluid tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tabla" + index} className={" letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                        
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaactividadesfiltradas.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarItem(item)} 
                                                                className={this.state.actividadseleccionada != undefined && this.state.actividadseleccionada.idactividad == item.idactividad ? "trFondoActivoGreen" : ""
                                                              }>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.actividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tipoactividad}
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                       
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.continuarProceso()}  disabled={!this.state.confirmarenvio}>Agregar</Button>
                                </div>
                            </>
               

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

