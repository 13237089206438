import React from 'react'
import './EditarRequisicion.css';
import './CatalogoNuevaRequisicion.css';
import {estiloBtnActualizar, estiloBtnEditar, estiloTabla} from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalFormularioBuscarProductosRequisicion from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioBuscarProductosRequisicion';

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      opcioneditar: true,

      tabla: {
        encabezados: [
          "Agroquímico",
          "Unidad de medida",
          "Cantidad",
          "Eliminar"],
        dimensiones: [
          "25%",
          "20%",
          "15%",
          "10%"
        ],
        mindimensiones: [
          "130px",
          "180px",
          "120px",
          "100px",
        ],
        elementos: []
      },

      //algo asi entregara el arreglo de la api informacion
      datosDummy: props.productos,

      actualizartablalocal:false,

      //arreglo para guardar nuevos productos
      listanuevosproductos: this.props.listanuevosproductos,



      listaunidadmedida: [],
      listaimcompletos: [],
      listadetenidos: [],

      respfamiliaproductos: [],
      resptipoproductos: [],
      respproductos: [],
      todoslosproductosalmacen: true,
      listaproductosalmacen: [],

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    //Se asigna atributos auxiliares
    this.setAtributosAux();

    //Se validan datos incompletos para marcar fila de tabla en rojo
    this.actualizarTabla();

   // await this.apisproductos();
      

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
    
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setAtributosAux = async () => {
    //agregamos los atributos, detener, eliminar, editable a los elementos de las tablas
    var aux = this.state.datosDummy;
    aux.map((item) => {
      item.eliminar = false;
    })
    
    //a estos indices se le colocara la fila en rojo
    await this.setState({datosDummy: aux})
  }

  actualizarTabla = async () => {
    //validaremos si los datos estan incompletos para pintar la fila en rojo
    var aux = []
    this.state.datosDummy.map((item, index) => {
      if(item.Producto.length<0 || item.Producto=="" || item.cantidad<=0 || item.cantidad==""  || item.Unidadmedida.length<0 || item.Unidadmedida==""){
        //guardamos el indice del elemento con datos incompletos
        aux.push(index)
      }
    })

    this.generarListaProductosGuardar();

    //a estos indices se le colocara la fila en rojo
    await this.setState({listaimcompletos: aux})

  }

  generarListaProductosGuardar = async () => {

    //llamar función de actualización general
    this.props.actualizarContenido(this.state.datosDummy);

  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  cerrarModalNuevoProducto = async (itemseleccionado) => {

    //si en la modal de nuevo le dio en guardar y no cancel, agregamos el json a la tabla
    if(itemseleccionado!=0){
   
      var aux = this.state.datosDummy.filter((item) => item.idproducto==itemseleccionado.idcatalogoproducto)

      //si no existe en la tabla, lo agregamos
      if(aux.length < 1){
        let json = {
          "idproducto": itemseleccionado.idcatalogoproducto,
          "Producto": itemseleccionado.nombrecomercial,
          "Idunidadmedida": itemseleccionado.idcatalogo_unidadmedida,
          "Unidadmedida": itemseleccionado.claveunidadmedida,
          "cantidad": "",
          "precio": "",
          "eliminar": false,
        }

        this.state.datosDummy.push(json)

        var dummy = this.state.datosDummy

        this.setState({ datosDummy: dummy });

        if(json.idproducto.toString().includes("nuevo")){
          json.nombrecomercial = json.Producto;
          json.clave = json.idproducto + " *";
          json.familiaproducto = "";
          json.tipoproducto = "";
          json.idcatalogoproducto=json.idproducto;
          json.claveunidadmedida = json.Unidadmedida;
          json.idcatalogo_unidadmedida = json.Idunidadmedida;
         await this.props.actualizarListaNuevosProductos(json)
        }

        await this.actualizarTabla();
      } else {
        toast.error("El producto ya existe en la tabla", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
    } 
    this.setState({ modalnuevoproducto: !this.state.modalnuevoproducto, loadingspinners: false })
  }

  //al precionar el boton agregar
  agregarElemento = async (ban) => {

      this.setState({ titulomodal: 'Nuevo agroquimico', accionmodal: 1, modalnuevoproducto: true})
    
  }

  escribirCantidadPrecio = async (index, prop, event) => {
   var longitud = {enterosMinimo: 1, enterosMaximos: 7, decimalesMinimo: 0, decimalesMaximo: 3};
   
   //let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
    
      //actualizamos los valores dinamicamente de la fila en modo edicion
      this.state.datosDummy[index][prop] = event.target.value;
      var dummy = this.state.datosDummy
        
      this.setState({ datosDummy: dummy });

      //validamos datos incompletos
      this.actualizarTabla();
    
    
  };

  eliminarElemento = async (elemento) => {
    var aux = this.state.datosDummy.filter((item, ind) => ((item.idproducto!=elemento.item.idproducto) ));
    
     this.setState({datosDummy:aux,
      actualizartablalocal:true
    })

    setTimeout( () => {
       this.actualizarTabla();
    }, 200);
   
  }

  //seleccionar el chec eliminar
  checkEliminar = async (index, check) => {
    this.state.datosDummy[index].eliminar = check;
    var dummy = this.state.datosDummy
      
    this.setState({ datosDummy: dummy });

    this.generarListaProductosGuardar();
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
        : null}

     

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalnuevoproducto == true) ? (
                    <ModalFormularioBuscarProductosRequisicion titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalNuevoProducto}
                        todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
                        show={this.state.modalnuevoproducto} accion={this.state.accionmodal} item={this.state.itemseleccionado}
                        respfamiliaproductos={this.props.listafamiliaproductos} resptipoproductos={this.props.listatipoproductos}
                        respproductos={this.props.listaproductos}
                        listaunidadmedida={this.props.listaunidadesmedida}
                        listaproductosnuevos={this.props.listanuevosproductos}
                        listaproductosagregados={this.state.datosDummy}
                    />
                ) : null}

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <div className='row justify-content-center' >
              <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                  
                <Table hover>
                    <thead style={{ verticalAlign: "bottom" }}>
                        <tr>
                          {this.state.tabla.encabezados.map((titulo, index) => (
                            <th key={"tabla" + index} className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                              style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                              {titulo}
                            </th>
                          ))}
                        </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {this.props.productos.map((item, index) => (
                        <tr key={index} className={item.detener ? "trFondoDetener" : this.state.listaimcompletos.indexOf(index) != -1 ? "trFondoIncompleto" : ""} >
                          <td key={"col-"+index+"-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {item.Producto}
                          </td>
                          <td key={"col-"+index+"-02"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                            {item.Unidadmedida}
                          </td>
                          <td key={"col-"+index+"-03"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                            <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla" value={item.cantidad} onChange={(e) => this.escribirCantidadPrecio(index, 'cantidad', e)} style={{ textAlign:"right" }}></input>
                          </td>
                          
                          <td key={"col-"+index+"-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            <div className="modalDetallesCheck" >
                              <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, {item, index})}
                              ><FaTrashAlt className="icoBotones" /></button>
                              </div>
                          </td>
                        </tr>
                      
                      ))}
                    </tbody>
                </Table>

                <div className='row paddingEncabezados' style={{ marginBottom: "6px", padding: "8px" }}>
                    <div className="col-auto">
                      <button className='btnActualizar letraNormal' onClick={this.agregarElemento.bind(this, { opcion: "1" })}
                        style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.btnbordereditar2 }}
                        >+ Agregar</button>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
