import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioResumen.css";
import { estiloColorError, estiloComboBox, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import {
  validarLetrasNumerosEspacios,
  validarNumerosDecimalesPresupuesto,
  validarPasswordEliminar,
  validarLetrasNumerosPuntoComa
} from '../../../Services/Validaciones';
import { ToastContainer } from 'react-toastify';

export default class ModalFormularioResumen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion ? props.accion : 1, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      item: props.item,

      // Rechazar requisición
      txtmotivo: '',
      vermsgerrortxtmotivo: false,
      msgerrortxtmotivo: '',

      txtpresupuesto: '',
      vermsgerrortxtpresupuesto: false,
      msgerrortxtpresupuesto: '',

      limitarpresupuesto: false,

      txtdescripcion: '',
      vermsgerrordescripcion: false,
      msgerrordescripcion: '',

      // Modales de aprobación
      txtpassword: "",
      vermsgerrortxtpassword: false,
      msgerrortxtpassword: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colortitulo: estiloFiltroEncabezados().colortitulos,
    }

  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  escribir = (prop) => (event) => {
    if ('txtclabe' == prop) {
      this.setState({ [prop]: event.target.value.toUpperCase() });
    } else {
      this.setState({ [prop]: event.target.value });
    }
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    let validacionescorrectas = true
    if (this.state.accion == 1) {
      this.setState({ vermsgerrortxtmotivo: false })

      let longitudvalida = { longitudminima: 1, longitudmaxima: 200 }
      let txtmotivo = validarLetrasNumerosEspacios(this.state.txtmotivo.trim(), longitudvalida)
      if (txtmotivo.codigo == "invalid") {
        this.setState({ msgerrortxtmotivo: txtmotivo.mensaje })
        this.setState({ vermsgerrortxtmotivo: true })
        validacionescorrectas = false
      }
    }
    else if (this.state.accion == 2 || this.state.accion == 4) {
      this.setState({ vermsgerrortxtpassword: false })

      let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
      if (txtpassword.codigo == "invalid") {
        this.setState({ msgerrortxtpassword: txtpassword.mensaje })
        this.setState({ vermsgerrortxtpassword: true })
        validacionescorrectas = false;
      }
    }
    else if (this.state.accion == 3) {
      this.setState({ vermsgerrortxtpresupuesto: false })
      this.setState({ vermsgerrordescripcion: false })

      let longitudvalida = { longitudminima: 1, longitudmaxima: 200 }
      if (this.state.txtdescripcion.trim().length > 0) {
        let txtdescripcion = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida)
        if (txtdescripcion.codigo == "invalid") {
          this.setState({ msgerrordescripcion: txtdescripcion.mensaje })
          this.setState({ vermsgerrordescripcion: true })
          validacionescorrectas = false
        }
      }

      if (this.state.limitarpresupuesto) {
        longitudvalida = { longitudminimaentera: 0, longitudmaximaentera: 8, longitudminimadecimal: 1, longitudmaximadecimal: 2 }
        let txtpresupuesto = validarNumerosDecimalesPresupuesto(this.state.txtpresupuesto.trim(), longitudvalida);

        if (txtpresupuesto.codigo == "invalid") {
          this.setState({ msgerrortxtpresupuesto: txtpresupuesto.mensaje })
          this.setState({ vermsgerrortxtpresupuesto: true })
          validacionescorrectas = false
        }
      }
    }


    if (validacionescorrectas) {
      this.setState({ loadingspinners: true })

      if (this.state.accion == 1) {
        var itemAux = this.state.item
        itemAux.txtmotivo = this.state.txtmotivo
        this.showModal({ accion: 1, item: itemAux })
      }
    }

  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            {(this.state.accion == 1 || this.state.accion == 3) ?
              <>
                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
                <br />
              </>
              :
              (this.state.accion == 2 || this.state.accion == 4) ?
                <div>
                  <div className="divTituloModal">
                    <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                  </div>
                </div> : null}

            <br />

            {this.state.accion == 1 ?
              <div className="divCuerpoModal">

                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Motivo del rechazo</p>
                  <textarea className='areaTextoModal letraNormal ' rows="5" id='comentarios'
                    value={this.state.txtmotivo} onChange={this.escribir("txtmotivo")}></textarea>

                  {this.state.vermsgerrortxtmotivo ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmotivo}</p> : null}

                </div>
              </div>
              :
              (this.state.accion == 2 || this.state.accion == 4) ?
                <div className="divModalEliminar">
                  <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                      type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                    {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}

                    {(!this.state.vermsgerrortxtpassword && this.state.vermsgerrortxtprincipal) ? <p className="msgError letraNormal"
                      style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtprincipal}</p> : null}

                  </div>
                </div>
                :
                this.state.accion == 3 ?
                  <div className="divCuerpoModal">

                    <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                      <div className="modalDetallesCheckContactoPrincipal letraTitulos" onClick={() => this.setState({ limitarpresupuesto: !this.state.limitarpresupuesto })}>
                        <input type="checkbox" checked={this.state.limitarpresupuesto} /> Limitar presupuesto
                      </div>
                      <br />

                      {this.state.limitarpresupuesto ?
                        <>
                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa presupuesto</p>
                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtpresupuesto} onChange={this.escribir("txtpresupuesto")}>
                          </input>

                          {this.state.vermsgerrortxtpresupuesto ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpresupuesto}</p> : null}
                        </> : null}

                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentarios</p>
                      <textarea className='areaTextoModal letraNormal ' rows="5" id='txtdescripcion'
                        value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></textarea>

                      {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}

                    </div>
                  </div> : null}

            {(this.state.accion == 1 || this.state.accion == 3) ?
              <>
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
              </> : null}

            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}
                disabled={(this.state.accion == 1 || this.state.accion == 3) ? !this.state.confirmarenvio : false} >Guardar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

