import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioAlmacenes.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaAlmacenesUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioAlmacenes extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            txtalmacen: (props.accion == 2) ? (props.item.nombrealmacen) : '',
            msgerroralmacen: "",
            vermsgerroralmacen: false,

            txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',
            vermsgerrordescripcion: false,
            msgerrordescripcion: "",

            txttipoalmacen: (props.accion == 2) ? (props.item.idtipoalmacen) : '',
            vermsgerrorencargado: false,
            msgerrorencargado: '',

            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona != undefined ? props.item.idcatalogo_zona : 0) : 0,

            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho != undefined ? props.item.idcatalogo_rancho : 0) : 0,

            vermsgerrorzonas: false,
            vermsgerrorranchos: false,
            msgerrorzona: "",
            msgerrorrancho: "",

            arrayestatus: props.estatus,
            idestatus: 1,
            msgerror: '',
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            listatipoalmacenes: props.listatipoalmacenes,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
        }

    }
    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        if (prop == "txtzona") {
            this.setState({ txtrancho: 0 });
        }
        this.setState({ [prop]: event.target.value });
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerroralmacen: false })
        this.setState({ vermsgerrordescripcion: false })
        this.setState({ vermsgerrorencargado: false })
        this.setState({ vermsgerrorzonas: false })
        this.setState({ vermsgerrorranchos: false })

        let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
        let almacencorrecto = validarLetrasNumerosEspacios(this.state.txtalmacen.trim(), longitudvalida);
        let validacionescorrectas = true
        if (almacencorrecto.codigo == "invalid") {
            this.setState({ msgerroralmacen: almacencorrecto.mensaje })
            this.setState({ vermsgerroralmacen: true })
            validacionescorrectas = false;
        }

        if (this.state.txttipoalmacen.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorencargado: "Selecciona un tipo de almacén" })
            this.setState({ vermsgerrorencargado: true })
        } else {
            if (this.state.txttipoalmacen == 2) {
                if (this.state.txtzona < 1) {
                    validacionescorrectas = false;
                    this.setState({ msgerrorzona: "Selecciona una zona" })
                    this.setState({ vermsgerrorzonas: true })
                }
                if (this.state.txtrancho < 1) {
                    validacionescorrectas = false;
                    this.setState({ msgerrorrancho: "Selecciona un rancho" })
                    this.setState({ vermsgerrorranchos: true })
                }
            }
        }


        longitudvalida = { longitudminima: 0, longitudmaxima: 100 };
        let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
        if (descorrecto.codigo == "invalid") {
            this.setState({ msgerrordescripcion: descorrecto.mensaje })
            this.setState({ vermsgerrordescripcion: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.props.activarSpinner(true);

            let json =
            (this.state.txttipoalmacen==1)?(
                {
                    "idcatalogoalmacen": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogoalmacen),
                    "nomnbrealmacen": this.state.txtalmacen,
                    "descripcion": this.state.txtdescripcion,
                    "idtipoalmacen": this.state.txttipoalmacen,
                    "idestatus": this.state.idestatus,
                }
            ):(
                {
                    "idcatalogoalmacen": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogoalmacen),
                    "nomnbrealmacen": this.state.txtalmacen,
                    "descripcion": this.state.txtdescripcion,
                    "idtipoalmacen": this.state.txttipoalmacen,
                    "idestatus": this.state.idestatus,
                    "idrancho": this.state.txtrancho
                }
            )


            var resp = await getListaAlmacenesUpdate(json);
            if (resp == -1 || resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.props.activarSpinner(false);
                return true
            }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.props.activarSpinner(false);
                return true 
            }
            else {
                this.showModal('1')
            }
        }

    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre almacén</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtalmacen} onChange={this.escribir("txtalmacen")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtalmacen}</label>}

                                {this.state.vermsgerroralmacen ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroralmacen}</p> : null}

                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo</p>
                                {this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={this.escribir("txttipoalmacen")}>
                                                    {this.state.accion == 1 ? <option disabled="disabled" selected="true">Seleccionar</option> : <option disabled="disabled" >Seleccionar</option>}
                                                    {this.state.listatipoalmacenes.map((i, index) => (
                                                        this.state.accion == 1 ?
                                                            <option value={i.idtipoalmacen} key={"tipo_" + index} >{i.tipoalmacen} </option> :
                                                            <option value={i.idtipoalmacen} key={"tipo_" + index} selected={i.idtipoalmacen == this.state.txttipoalmacen ? true : false} >{i.tipoalmacen} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>{this.props.item.tipoalmacen}</label>
                                }
                                {this.state.vermsgerrorencargado ? <p className="msgError "
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorencargado}</p> : null}

                                {this.state.txttipoalmacen == 2 ? (<>

                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                    {this.state.accion == 2 ? (
                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                        <option disabled="disabled" selected={this.state.txtzona == 0 ? true : false}>Seleccionar</option>
                                                        {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                            <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona} selected={i.idcatalogo_zona == this.state.txtzona ? true : false} >{i.zona} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                        <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona}>{i.zona}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.vermsgerrorzonas ?
                                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p> : null}


                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                    {this.state.accion == 2 ? (
                                        <div className='col lblSubTitulosModal '>
                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                <div className="selectBox ">
                                                    <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                        <option disabled="disabled" selected={this.state.txtrancho == 0 ? true : false}>Seleccionar</option>
                                                        {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                            this.state.txtzona == i.idcatalogo_zona ?
                                                                <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho} selected={i.idcatalogo_rancho == this.state.txtrancho ? true : false} >{i.rancho} </option> : null
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                        this.state.txtzona == i.idcatalogo_zona ?
                                                            <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}>{i.rancho}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.vermsgerrorranchos ?
                                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p> : null}


                                </>)
                                    : null}

                                <p className='lblSubTitulosModal letraNormal ' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                                {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}

                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal ' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>

                            {this.state.accion == 2 ? (
                                <>
                                    <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                                    <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                                        Creado: {this.props.item.fechacreacion}
                                        <br />
                                        Actualizado: {this.props.item.fechaactualizacion}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}