import React from "react";
import { Table } from 'reactstrap';
import { BiEdit } from "react-icons/bi";
import { AiFillCheckSquare, AiOutlineFileDone, AiOutlineFileImage, AiOutlineFileWord } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
//import { FaRegEye } from "react-icons/fa"
import { BsTrash } from "react-icons/bs"
import { ImDownload3 } from 'react-icons/im'
import { GrDocumentPdf, GrDocumentTxt } from 'react-icons/gr';
import { estiloBtnEditar, estiloBtnNuevo, estiloTabla } from '../../Services/PaletaDeColores';

const sindatos = { 3:'Sin contactos', 5:'Sin direcciones', 7:'Sin documentos' }

export default class TablaListado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opcionnuevo: this.props.opcion[0],
      opcioneditar: this.props.opcion[1],

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  onChange = (band) => {
    if (band.eliminar === undefined) {
      this.props.vermodaldetalles({ opcion: band.opcion, contenido: band.contenido });
    }
    else {
      this.props.vermodaleliminaritem({ opcion: band.opcion, contenido: band.contenido, eliminar: band.eliminar });
    }
  }

  formatearDireccion = (direccion) => {
    var arraydireccion = [ direccion.calle, direccion.numeroexterior, direccion.numerointerior, direccion.colonia,
      direccion.codigopostal, direccion.localidad, direccion.municipio, direccion.estado, direccion.pais]

    return arraydireccion.filter((item) => item).map((item) => ' ' + item).toString()
  }

  render() {
    return (
      <div className="container-fluid anchoMaximo containerTabla">
        <div className="container-fluid anchoMaximo" style={{ minWidth: "1000px", backgroundColor: this.props.backgroundcolor }} >
          <div style={{ marginBottom: "15px" }}>
            <br />

            <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
              <div className="col-auto">
                <label className="lblTituloHoja letraTitulos">{this.props.tabla.titulo}</label>
              </div>
              <div className='col '></div>
              <div className="col-auto align-items-end">
                <button className='btnNuevo letraNormal' onClick={this.onChange.bind(this, { opcion: this.state.opcionnuevo })}
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                >+ Nuevo</button>
              </div>
            </div>

            <div className="containerSubTabla" style={{ padding: "4px" }}>
              <Table >
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {(this.props.tabla.elementos && this.props.tabla.elementos.length > 0) ?
                      this.props.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTablaProv letraNormal"
                          style={{
                            minWidth: this.props.tabla.mindimensiones[index], width: this.props.tabla.dimensiones[index],
                            color: this.state.colorencabezadostabla
                          }}>
                          {titulo}
                        </th>
                      ))
                      :
                      <th className="lblEncabezadosTablaProv letraNormal"
                        style={{ minWidth: "100%", width: "900px", color: this.state.colorencabezadostabla }}>
                        {sindatos[this.state.opcionnuevo]}
                      </th>
                    }
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.opcionnuevo === 3 ?
                    this.props.tabla.elementos && this.props.tabla.elementos.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[0], width: this.props.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                          {item.nombre}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[1], width: this.props.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                          {item.puesto}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[2], width: this.props.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                          {item.telefono}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[3], width: this.props.tabla.dimensiones[3], color: this.state.colorcontenidotabla }}>
                          {item.email}
                        </td>
                        <td align={"center"} className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[4], width: this.props.tabla.dimensiones[4], color: this.state.colorcontenidotabla }}>
                          {(item.principal == 1) ? <AiFillCheckSquare className="icoRegistroPrincipal" /> : ""}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[5], width: this.props.tabla.dimensiones[5], color: this.state.colorcontenidotabla }}>
                          {item.comentario}
                        </td>
                        <td className="letraNormal" style={{ minWidth: "150px", width: "12%" }}>
                          <div style={{ display: 'inline-flex' }}>
                            <button className='btnEditar' onClick={this.onChange.bind(this, { opcion: this.state.opcioneditar, contenido: item })} style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, marginLeft: "5px" }}>
                              <BiEdit className="icoBotones" /> Editar
                            </button>
                            <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                              <BsTrash onClick={this.onChange.bind(this, { contenido: item, eliminar: 10 })} className="icoBotonesTrash" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                    :
                    this.state.opcionnuevo === 5 ?
                      this.props.tabla.elementos && this.props.tabla.elementos.map((item, index) => (
                        <tr key={"tabla" + index}>
                          <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[0], width: this.props.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                            { /*item.direccion*/ this.formatearDireccion(item)}
                          </td>
                          <td align={"center"} className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[1], width: this.props.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                            {(item.principal == 1) ? <AiFillCheckSquare className="icoRegistroPrincipal" /> : ""}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[2], width: this.props.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                            {item.referencia}
                          </td>
                          <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: "150px", width: "12%" }}>
                            <div style={{ display: 'inline-flex' }}>
                              <button className='btnEditar' onClick={this.onChange.bind(this, { opcion: this.state.opcioneditar, contenido: item })} style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar, marginLeft: "5px" }}>
                                <BiEdit className="icoBotones" /> Editar
                              </button>
                              <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                <BsTrash onClick={this.onChange.bind(this, { contenido: item, eliminar: 11 })} className="icoBotonesTrash" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      :
                      this.state.opcionnuevo === 7 ?
                        this.props.tabla.elementos && this.props.tabla.elementos.map((item, index) => (
                          <tr key={"tabla" + index}>
                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[0], width: this.props.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                              {item.documento}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[1], width: this.props.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                              {item.tipo_documento}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[2], width: this.props.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                              {item.extension == "pdf" ? <GrDocumentPdf /> :
                                  item.extension == "txt" ? <GrDocumentTxt /> :
                                      item.extension == "csv" ? <RiFileExcel2Line /> :
                                          item.extension == "doc" || item.extension == "docx" ? <AiOutlineFileWord /> :
                                              item.extension == "jpg" || item.extension == "jpeg" || item.extension == "png" ? <AiOutlineFileImage /> : <AiOutlineFileDone />
                              }
                              {"Documento_" + item.idcatalogo_proveedores + "_" + item.idcatalogo_proveedores_documentos+"."+item.extension}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[3], width: this.props.tabla.dimensiones[3], color: this.state.colorcontenidotabla }}>
                              {item.descripcion}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.props.tabla.mindimensiones[4], width: this.props.tabla.dimensiones[4], color: this.state.colorcontenidotabla }}>
                              {item.fechacreacion}
                            </td>
                            <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: "150px", width: "12%" }}>
                              <div style={{ display: 'inline-flex' }}>
                                <a href={item.rutadocumento} download>
                                  <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                    <ImDownload3 className="icoBotonesTrash" />
                                  </div>
                                </a>
                                {/*<div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                  <FaRegEye className="icoBotonesTrash" />
                                </div>*/}
                                <div style={{ marginRigth: "6px", marginLeft: "6px" }} className='divBotonesTrash'>
                                  <BsTrash onClick={this.onChange.bind(this, { contenido: item, eliminar: 12 })} className="icoBotonesTrash" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )) : null}

                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}