import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import { validarArchivoCargadoJpgJpegPng } from '../../../Services/Validaciones';
import {postGuardarTicketRecepcionFruta}  from '../../../Services/Api'
export default class ModalFormularioTicketRecepcionFruta extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            accion: props.accion,

            id_catalogo:props.id,
            ticketcargado: null,
            txtnombreticket: (props.accion == 2) ? ("imagen_vale_" + props.item.folio) : 'Buscar',
            vermsgerrortxtticket: false,
            msgerrortxtticket: '',

            confirmarenvio:false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal()
        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    async seleccionarTicket(event) {
        try {
            event.persist();
            var archi = event.target.files[0];
            if (event.target.files.length > 0) {
                this.setState({ ticketcargado: archi, txtnombreticket: archi.name });
            }
        } catch (error) { }
    }

    guardarCambios = async () => {
        this.setState({vermsgerrortxtticket:false});

        let validacionescorrectas = true;

        if (this.state.txtnombreticket == "Buscar") {
            this.setState({ msgerrortxtticket: "Selecciona una imagen de ticket" })
            this.setState({ vermsgerrortxtticket: true })
            validacionescorrectas = false;
        }

        let ticketcargado = validarArchivoCargadoJpgJpegPng(this.state.ticketcargado)
        if (ticketcargado.codigo == "invalid") {
            this.setState({ msgerrortxtticket: ticketcargado.mensaje })
            this.setState({ vermsgerrortxtticket: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;
            const formdata = new FormData();

            formdata.append('idcatalogo_bitacora_traslados_recepcion', this.state.id_catalogo)
            formdata.append('imagenticket', this.state.ticketcargado)

            resp = await postGuardarTicketRecepcionFruta(formdata);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else if (resp == -1) {
                this.setLoadingSpinner(false);
                toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección mensajes toast ////////*/}
                <ToastContainer />

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/*///////// Sección de cargar de ticket ////////*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Imagen del ticket (jpg/jpeg/png)</p>
                                <div className='centrarBotonFile' >
                                    <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".jpg, .jpeg, .png"
                                        onChange={(event) => this.seleccionarTicket(event)} />
                                    <label for="file-2"><MdCloudUpload />
                                        <span className='letraNormal'>{this.state.txtnombreticket}</span>
                                    </label>
                                </div>

                                {this.state.vermsgerrortxtticket ?
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtticket}</p>
                                :null}

                            </div>
                        </div>

                        {/*///////// Sección de botones ////////*/}
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}
