import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioESTraslados.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import {postBitacoraTrasladoGuardar } from '../../../Services/Api';
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ModalFormularioNuevoESTraslados extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,

            idcatalogo_bitacora_traslado: (props.accion == 2) ? (props.item.idcatalogo_bitacora_traslados) : 0,
            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '0',
            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            txtfecha: (props.accion == 2) ? (props.item.fecha) : '',
            fecha: '',

            vermsgerrorzonas: false,
            vermsgerrorranchos: false,
            vermsgerrorfecha: false,

            msgerrorzona: "",
            msgerrorrancho: "",
            msgerrorfecha: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colorbordertipo: estiloComboBox().colorbordercombo,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
        }

    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if(prop=="txtzona"){
            this.setState({ txtrancho: 0 })
        }
    };

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    crearFecha = () => {
        let fecha = this.state.txtfecha.split("-");
        let nuevafecha = new Date(fecha[0],fecha[1]-1,fecha[2]);
        return  nuevafecha
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrorranchos: false })
        this.setState({ vermsgerrorzonas: false })
        this.setState({ vermsgerrorfecha: false })

        let validacionescorrectas = true

        if (this.state.txtzona < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorzona: "Selecciona una zona" })
            this.setState({ vermsgerrorzonas: true })
        }

        if (this.state.txtrancho < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorrancho: "Seleccione un rancho" })
            this.setState({ vermsgerrorranchos: true })
        }

        if(this.state.txtfecha=="" || this.state.txtfecha==null){
            validacionescorrectas = false;
            this.setState({ msgerrorfecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfecha: true })
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            let json = {
                "idcatalogo_bitacora_traslados": this.state.idcatalogo_bitacora_traslado,
                "idcatalogo_rancho": this.state.txtrancho,
                "fecha":this.state.txtfecha
            }

            var resp = await postBitacoraTrasladoGuardar(json);
            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
            else {
                this.setLoadingSpinner(false);
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de spinner ////////*/}
                <ToastContainer />
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}

                <Modal
                    isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid" >
                                    <div className="container-fluid anchoMaximo ">
                                        <div className='row  align-items-end d-flex'>
                                            <div className='row containerSubTabla paddingBottomEncabezados'>

                                                {/*///////// Sección de fecha ////////*/}
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4 col-4' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar }}>
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha</p>
                                                    <div className='' style={{ marginLeft: '10px', minWidth: '250px' }}>
                                                        <div className=' col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                            <DatePicker
                                                                className='float-right'
                                                                selected={ this.state.accion == 2 ? this.crearFecha(): this.state.fecha}
                                                                onChange={(date) => this.cambiarFecha({ llave: "fecha", valor: date })}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="es"
                                                                customInput={
                                                                <DateButton />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.vermsgerrorfecha ?
                                                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfecha}</p>
                                                    :null}

                                                </div>

                                                {/*///////// Sección de zona ////////*/}
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4 col-4'>
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                                    {this.state.accion == 2 ?
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                <div className="selectBox ">
                                                                    <select disabled className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                                        <option disabled="disabled">Seleccionar</option>
                                                                        {this.state.listazonas == undefined || this.state.listazonas.length == 0 ?
                                                                            null
                                                                        :
                                                                            this.state.listazonas.map((i, index) => (
                                                                                <option
                                                                                    value={i.idcatalogo_zona}
                                                                                    key={"tipo_" + index}
                                                                                    selected={i.idcatalogo_zona==this.state.txtzona ? true : false}
                                                                                >
                                                                                    {i.zona}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                                        {this.state.listazonas == undefined || this.state.listazonas.length == 0 ?
                                                                            null
                                                                        :
                                                                            this.state.listazonas.map((i, index) => (
                                                                                <option
                                                                                    value={i.idcatalogo_zona}
                                                                                    key={"tipo_" + index}
                                                                                >
                                                                                    {i.zona}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.vermsgerrorzonas ?
                                                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p>
                                                    :null}

                                                </div>

                                                {/*///////// Sección de rancho ////////*/}
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-4 col-4'>
                                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                                    {this.state.accion == 2 ?
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                <div className="selectBox ">
                                                                    <select disabled className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                                        <option disabled="disabled" selected={this.state.txtrancho==0 ? true : false}>Seleccionar</option>
                                                                        {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ?
                                                                            null
                                                                        :
                                                                            this.state.listaranchos.map((i, index) => (
                                                                                this.state.txtzona == i.idcatalogo_zona ?
                                                                                    <option
                                                                                        value={i.idcatalogo_rancho}
                                                                                        key={"tipo_" + index}
                                                                                        selected={i.idcatalogo_rancho==this.state.txtrancho ? true : false}
                                                                                    >
                                                                                        {i.rancho}
                                                                                    </option>
                                                                                :null
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className='col lblSubTitulosModal '>
                                                            <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                                                <div className="selectBox ">
                                                                    <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                                        <option disabled="disabled" selected="true">Seleccionar</option>
                                                                        {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ?
                                                                            null
                                                                        :
                                                                            this.state.listaranchos.map((i, index) => (
                                                                                this.state.txtzona == i.idcatalogo_zona ?
                                                                                    <option
                                                                                        value={i.idcatalogo_rancho}
                                                                                        key={"tipo_" + index}
                                                                                    >
                                                                                        {i.rancho}
                                                                                    </option>
                                                                                :null
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.vermsgerrorranchos ?
                                                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p>
                                                    :null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        {/*///////// Sección de botones ////////*/}
                        {this.state.accion == 2 || this.state.accion == 1 ?
                            <>
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        :null}

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

{/*///////// Botón de fecha ////////*/}
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686',width: '96%',margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '80%', textAlign: 'left', cursor: 'pointer' }}>{value ? value: 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }}/>
    </button>
));
