import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { buscarVentasMismoCliente } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillCalendarFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { FaCalendar } from "react-icons/fa";
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { buildContent } from '../../Paginacion/Paginacion';
import { Table } from 'reactstrap';
import { formatoNumero2 } from '../../../Services/Validaciones';

export default class ModalBuscarVentas extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion,
            titulo: props.titulomodal,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,

            //paginacion
            activepage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            tabla: {
                encabezados: [
                  "ID venta",
                  "Cliente",
                  "Venta",
                  "Fecha",
                  "Total",
                  "Adeudo acumulado",
                ],
                dimensiones: [
                  "10%",
                  "10%",
                  "25%",
                  "15%",
                  "20%",
                  "20%",
                ],
                mindimensiones: [
                  "120px",
                  "140px",
                  "200px",
                  "120px",
                  "150px",
                  "150px",
                ],
                elementos: []
            },

            txtbuscador: '',
            txtfechaventaini:"",
            txtfechaventafin:"",
            fechaventaini: new Date(),
            fechaventafin: new Date(),

            idventasseleccionadas: [],
            ventasseleccionadas: [],

        }

    }

    async componentDidMount() {
        var fechaactualFin = new Date();
        const fechaa = fechaactualFin.getDate();
        fechaactualFin.setDate(fechaa);
        const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

        var fechaactualIni = new Date();
        fechaactualIni.setDate(fechaactualIni.getDate()-7);
        const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

        await this.setState({ 
            txtbuscador: '',
            txtfechaventaini: defaultValueFechaInicial,
            txtfechaventafin: defaultValue,
            fechaventaini: fechaactualIni
          })

          this.consultarCatalogoVentas();
          this.cambiarContenido(1);
          this.setState({ loadingspinners: false })
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if(resp.code == 300){
          if(!this.state.errordeacceso){
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
            
          this.setState({ 
            loadingspinners: false,
            errordeacceso:true })
          return true
        }
        else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if (resp.code== 401) {
          this.setState({ salir: true })
          return true;
        }
        return false
    }

    cambiarContenido = (pagenumber) => {
        this.setState({
          activepage: pagenumber,
          filtrolistapaginada: [],
          listapaginada: []
        });
    
        setTimeout(() => {
          this.setState({
            activepage: pagenumber,
            listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
            filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
          });
        }, 0);
    
    }

    filtro = (pagenumber, array) => {

        this.setState({
          activepage: pagenumber,
          filtrolistapaginada: []
        });
    
        setTimeout(() => {
          this.setState({
            elementosfiltrados: array,
            activepage: pagenumber,
            filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
          });
        }, 0);
    
    }

    borrarBusqueda = () => {
        this.setState({ txtbuscador: '' })
        this.cambiarContenido(1);
        this.setState({ filtrolistapaginada: [] });
    }

    consultarCatalogoVentas = async (llave, valor) => {

        var formatofechaini = "";
        var formatofechafin = "";
        var fechainiaux = 'txtfechaventaini' == llave ? valor : this.state.txtfechaventaini
        var fechafinaux = 'txtfechaventafin' == llave ? valor : this.state.txtfechaventafin
    
        if(fechainiaux.length > 0){
          var [year, month, day] = fechainiaux.split("-");
          formatofechaini = `${day}/${month}/${year}`
        }
    
        if(fechafinaux.length > 0){
          var [year, month, day] = fechafinaux.split("-");
          formatofechafin = `${day}/${month}/${year}`
        }
       
        var requestbody = {
          fechainicio: formatofechaini,
          fechafin: formatofechafin,
          idventa: this.props.idventa
        }
      
          var fechasvalidas = await this.validarFechasDeVenta(formatofechaini,formatofechafin)
          if(fechasvalidas == 1){
            this.setState({ loadingspinners: true })
            var catalogoventas = await buscarVentasMismoCliente(requestbody);

            if ( this.manejadorLlamadasApis (catalogoventas) ) {
              this.setState({ loadingspinners: false })
              return;
            }
    
            var tabla = this.state.tabla;
            tabla.elementos = catalogoventas.data;
        
            await this.setState({ 
              txtbuscador: '',
              tabla: tabla
            })
            this.cambiarContenido(1);
            this.setState({ loadingspinners: false })
          }
       // 
    }
    
    validarFechasDeVenta = async (formatofechaini,formatofechafin) => {
        var fechainicio=new Date();
        var fechafin=new Date();
        var fechainiciovalido = false;
        var fechafinvalido = false;
        if(formatofechaini.length > 0){
          fechainiciovalido = true;
          var fechacompraini = formatofechaini.split("/");
          fechainicio.setFullYear(fechacompraini[2],fechacompraini[1],fechacompraini[0]);
        }
    
        if(formatofechafin.length > 0){
          fechafinvalido = true;
          var fechacomprafin = formatofechafin.split("/");
          fechafin.setFullYear(fechacomprafin[2],fechacomprafin[1],fechacomprafin[0]);
        }
    
        if(fechainiciovalido && fechafinvalido){
          if (fechainicio <= fechafin){
            return 1;
          }else{
            toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            var tabla = this.state.tabla;
            tabla.elementos = [];
    
            await this.setState({ 
              txtbuscador: '',
              tabla: tabla
            })
            this.cambairContenido(1);
            return 2;
          }
        }
        return 0;
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })
    
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    
        this.consultarCatalogoVentas(['txt' + item.llave], year + '-' + month + '-' + day)
    }

    saveInputLocacion = async (e) => {

        this.setState({ txtbuscador: e.target.value });
    
        if (e.target.value.trim().length > 2) {
          var data1 = JSON.parse(JSON.stringify(this.state.tabla))
          var data = data1.elementos;
          const filtros = data.filter((item) => item.cliente.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
    
          if (filtros.length < 1) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
          this.filtro(1, filtros)
        }
        else {
          this.setState({ filtrolistapaginada: [] });
        }
    
    }

    seleccionarVenta = async (item) => {
      if(this.state.idventasseleccionadas.indexOf(item.idventa)!=-1){
          this.state.idventasseleccionadas.splice(this.state.idventasseleccionadas.indexOf(item.idventa),1);
          var auxVentas = this.state.ventasseleccionadas.filter((venta) => !(venta.idventa==item.idventa));
          await this.setState({ventasseleccionadas: auxVentas})
      }else{
          this.state.idventasseleccionadas.push(item.idventa);
          this.state.ventasseleccionadas.push(item);
          var auxVentas = this.state.ventasseleccionadas;
          await this.setState({ventasseleccionadas: auxVentas})
      }

      await this.setState({})
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid anchoMaximo">

                                        <div className='row paddingEncabezados align-items-end '>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>
                                                
                                                    <div className='subcontenedorIconoCalendarioCompras'>
                                                        <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }}/>  
                                                    </div>
                                                    <div className='row'>
                                                        <div className=' col-sm-4 col-md-4'>
                                                            <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha venta de:</label>
                                                        </div>
                                                        <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                            <DatePicker
                                                                selected={this.state.fechaventaini}
                                                                onChange={(date) => this.cambiarFecha({ llave: "fechaventaini", valor: date })}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="es"
                                                                customInput={
                                                                <DateButton />
                                                                }
                                                            />
                                                            </div>
                                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                                                            </div>

                                                            <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                                                            <DatePicker
                                                                selected={this.state.fechaventafin}
                                                                onChange={(date) => this.cambiarFecha({ llave: "fechaventafin", valor: date })}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="es"
                                                                customInput={
                                                                <DateButton />
                                                                }
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>

                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <div className='txtBuscadorAncho'>
                                                <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                                <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                                                    className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                    placeholder="Buscar"></input>
                                                {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                    onClick={this.borrarBusqueda} />) : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                            <br />
                            <div className="container-fluid containerTabla">
                                <div className="container-fluid anchoMaximo ">
                                    <div className="containerSubTabla">
                                    <Table hover>
                                        <thead style={{ verticalAlign: "bottom" }}>
                                        <tr>
                                            {this.state.tabla.encabezados.map((titulo, index) => (
                                            <th className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: index>3 ? 'center' : '' }}>
                                                {titulo}
                                            </th>
                                            ))}
                                        </tr>
                                        </thead>

                                        <tbody style={{ verticalAlign: "bottom" }}>

                                        {this.state.txtbuscador.length < 3 ?

                                            this.state.listapaginada.map((item, index) => (

                                              <tr onClick={() => this.seleccionarVenta(item)} className={this.state.idventasseleccionadas.indexOf(item.idventa) != -1 ? "trFondoActivoGreen" : ""}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                  {item.idventa}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                  {item.cliente}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                  {item.venta}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                  {item.fecha}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5]}} >
                                                    <label>${formatoNumero2(item.precio)}</label>
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5]}} >
                                                    <label>${formatoNumero2(item.adeudo)}</label>
                                                </td>
                                              </tr>
                                            ))
                                            :
                                            this.state.filtrolistapaginada.map((item, index) => (

                                              <tr onClick={() => this.seleccionarVenta(item)} className={this.state.idventasseleccionadas.indexOf(item.idventa) != -1 ? "trFondoActivoGreen" : ""}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                  {item.idventa}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                  {item.cliente}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                  {item.venta}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3] }}>
                                                  {item.fecha}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5]}} >
                                                    <label>${formatoNumero2(item.precio)}</label>
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{textAlign: 'right', color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5]}} >
                                                    <label>${formatoNumero2(item.adeudo)}</label>
                                                </td>
                                              </tr>

                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                    </div>

                                </div>
                            </div>  
                        </div>

                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal(this.state.ventasseleccionadas)}>Agregar</Button>
                                </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
      style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
    >
      <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
  ));