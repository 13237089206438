import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import { getComboFamiliaProducto, getComboTipoProducto } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioBuscarProductos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      txtbuscador: "",
      tabla: {
        encabezados: [
          "Actividad",
          "Tipo"
        ],
        dimensiones: [
          "65%",
          "35%"
        ],
        mindimensiones: [
          "350px",
          "200px"
        ],
        elementos: props.listaactividades,
      },

      // Listas
      listatipoactividades: props.listatipoactividades,
      listaactividades: props.listaactividades,
      tipoactividadseleccionada: todoslosfiltrosdisponibles,
      listaactividadesfiltradas: props.listaactividades,



      todoslosproductosalmacen: this.props.todoslosproductosalmacen,
      listaproductosalmacen: [],//this.props.listaproductosalmacen,
      productoseleccionado: undefined,
      idproductoseleccionado: 0,
      productosseleccionados: {},
      //arrayidproductosseleccionados: 0,
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    this.setState({
      txtbuscador: '',
      listafamiliaproductos: [],//this.props.respfamiliaproductos,
      listatipoproductos: [],//this.props.resptipoproductos,
      listatipoproductosfiltrados: [],//this.props.resptipoproductos,
      listaproductosfiltrados: [],//tabla.elementos
    })
    this.setState({ loadingspinners: false })

  }

  filtrarProductosdeAlmacen(respproductos) {
    var productosdealmacen = [];

    this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
      var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
      if (producto != undefined) {
        productosdealmacen = productosdealmacen.concat(producto)
      }
    });

    return productosdealmacen;
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {

    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    // Mandar datos del item de producto seleccionado
    this.showModal(this.state.productosseleccionados)//productoseleccionado)
  }

  saveInputLocacion = async (e) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var filtrotipoactividad = [];
    var filtroactividades = [];

    this.setState({ txtbuscador: e.target.value })
    if (e.target.value.trim().length >= 3) {
      filtrotipoactividad = data

      if (this.state.tipoactividadseleccionada != todoslosfiltrosdisponibles) {
        filtrotipoactividad = data.filter((item) => item.idtipoactividad == this.state.tipoactividadseleccionada)
      }

      filtroactividades = filtrotipoactividad.filter((item) => item.actividad.toLocaleLowerCase().includes(e.target.value.trim().toLocaleLowerCase()))
      if (filtroactividades.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(filtroactividades)

    } else if (e.target.value.trim().length < 1) {
      this.filtrarTipoActividades(this.state.tipoactividadseleccionada)
    }
  }

  filtro = (array) => {
    setTimeout(() => {
      this.setState({ listaactividadesfiltradas: array })
    }, 0)

  }

  filtrarTipoActividades = async (tipo) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    await this.setState({ tipoactividadseleccionada: tipo })

    var filtrotipoactividad = [];
    var filtroactividades = [];

    filtrotipoactividad = data;
    if (tipo != "todos") {
      filtrotipoactividad = data.filter((item) => item.idtipoactividad == tipo)
    }
    filtroactividades = filtrotipoactividad.filter((item) => item.actividad.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    if (filtroactividades.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    this.filtro(filtroactividades)
  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      tipoactividadseleccionada: todoslosfiltrosdisponibles,
      listaactividadesfiltradas: this.state.tabla.elementos
    });
  }

  seleccionarProducto = async (item) => {
    var productosseleccionados = this.state.productosseleccionados
    var productoseleccionado = productosseleccionados[item.idactividad]
    if (productoseleccionado === undefined) {
      productosseleccionados = { [item.idactividad]: item }
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: true, //idproductoseleccionado: item.idactividad
      })
    } else {
      productosseleccionados = {}
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: false, //idproductoseleccionado: item.idactividad
      })
    }
    /*this.setState({
      productoseleccionado: item,
      confirmarenvio: true,
      idproductoseleccionado: item.idactividad
    })*/
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Selecciona una actividad</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>
                  <div className='col-6 containerSubTablaMMM paddingBottomEncabezadosMMM'>
                    <div className='divComboSelect'
                      style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarTipoActividades(event.target.value)}>
                          {this.state.filtrotipoproducto == todoslosfiltrosdisponibles ?
                            <option selected="true" value={todoslosfiltrosdisponibles}>Todas</option> : <option value={todoslosfiltrosdisponibles}>Todas</option>}
                          {this.state.listatipoactividades.map((i, index) => (
                            <option value={i.idtipoactividad} key={"tipo_" + index}  >{i.tipo} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 containerSubTabla paddingBottomEncabezados'>
                    <div className='txtBuscadorCN' >
                      <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                      <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                        placeholder="Buscar actividad"></input>
                      {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                        onClick={this.borrarBusqueda} />) : null}
                    </div>
                  </div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {(this.state.tipoactividadseleccionada == todoslosfiltrosdisponibles) && (this.state.txtbuscador.trim().length < 3) ?
                            this.state.tabla.elementos.map((item, index) => (
                              <tr key={"tabla" + index}
                                onClick={() => this.seleccionarProducto(item)}
                                /*className={this.state.idproductoseleccionado == item.idactividad ? "trFondoActivoGreen" : ""}*/
                                className={this.state.productosseleccionados[item.idactividad] != undefined ? "trFondoActivoGreen" : ""} >
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.actividad}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.tipoactividad}
                                </td>

                              </tr>
                            ))
                            : this.state.listaactividadesfiltradas.map((item, index) => (
                              <tr key={"tabla" + index}
                                onClick={() => this.seleccionarProducto(item)}
                                /*className={this.state.idproductoseleccionado == item.idactividad ? "trFondoActivoGreen" : ""}*/
                                className={this.state.productosseleccionados[item.idactividad] != undefined ? "trFondoActivoGreen" : ""} >
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.actividad}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.tipoactividad}
                                </td>

                              </tr>
                            ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Agregar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

