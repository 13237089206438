import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import "./ModalRecuperarPassword.css";
import { estiloColorError, estilosModal, coloresLogin } from '../../../Services/PaletaDeColores';
import { validateEmail } from '../../../Services/Validaciones';
import { setRestablecerUsuario } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ModalRecuperarPassword extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,

            txtcorreo: "",
            vermsgerrorcorreo: false,
            msgerrorcorreo: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),

            btnfondologin: coloresLogin().btnfondologin,
            btncolorlogin: coloresLogin().btncolorlogin,
            fondoinputslogin: coloresLogin().fondoinputs,
            colorinputslogin: coloresLogin().colorinputs,
            borderinputslogin: coloresLogin().borderinputslogin,
        }

    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    guardarCambios = async () => {
        this.setState({ vermsgerrorcorreo: false })
        let validacionescorrectas = true
        
        //console.log("pass>",this.state.txtcorreo)
        let validacion = validateEmail(this.state.txtcorreo)
        //console.log("valid>",validacion)
        if (validacion.codigo == "invalid") {
            this.setState({ msgerrorcorreo: validacion.mensaje })
            this.setState({ vermsgerrorcorreo: true })
            validacionescorrectas = false;
        }
        
        if (validacionescorrectas) {
            
            toast.info("API en construccion, vuelva pronto", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            /*
            this.setLoadingSpinner(true);
            let json = {
                "email": this.state.password
            }
            var resp = await setRestablecerUsuario(json);

            if (resp.code == 1000) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setLoadingSpinner(false);
                return;
            }else if (resp.code == 200 || resp.code == 300) {
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.setLoadingSpinner(false);
                return;
            }
            else {
                this.showModal('1')
            }*/
        }
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div style={{ textAlign: "center" }}>
                            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        </div>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal">
                                <p className='lblSubTitulosModal letraTitulos' style={{ textAlign: "center" }}>Escribe tu correo electrónico para que te enviemos una nueva contraseña</p>
                                <br />
                                <input style={{ backgroundColor: this.state.fondoinputslogin, color: this.state.colorinputslogin, border: this.state.borderinputslogin }}
                                    className='inputsLogin letraNormal' 
                                    type={'email'} value={this.state.txtcorreo} 
                                    onChange={this.escribir("txtcorreo")}>
                                </input>
                           
                                {this.state.vermsgerrorcorreo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcorreo}</p> : null}
                            </div>

                            <div className="divContenedorModal" >
                                <div className="row" style={{ width: "100%", margin: "0px"}}>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                        <button className="btn btn-success btnLogin"
                                            style={{ color: this.state.btncolorlogin, backgroundColor: "#212529" }}
                                            onClick={() => this.showModal('0')}>Cancelar
                                        </button>
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                        <button className="btn btn-success btnLogin" 
                                            style={{ color: this.state.btncolorlogin, backgroundColor: this.state.btnfondologin }}
                                            onClick={() => this.guardarCambios()}>Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

