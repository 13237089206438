import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
//--import "./ModalFormularioRequisicion";
import { CgClose } from 'react-icons/cg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estilosModal } from '../../../Services/PaletaDeColores';

import { Table } from 'reactstrap';


export default class ModalFormularioMoverComprasGeneralesFiltros extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: "Selecciona el tipo de almacén",
      listaoperacionesestatus: [
        {
          idopcion: 1,
          opcion: "Almacenes de productos generales"
        },
        {
          idopcion: 2,
          opcion: "Almacenes de equipos"
        },
       {
          idopcion: 3,
          opcion: "Áreas"
        },       
        {
          idopcion: 5,
          opcion: "Ranchos"
        }, 
        {
          idopcion: 4,
          opcion: "Maquinaria y vehículos"
        },
        {
          idopcion: 6,
          opcion: "Equipos"
        },
      ],
      idoperacionseleccionada: 0,
      idseleccionado: '',
      estilosmodal: estilosModal(),

    }

  }



  showModal = (opcion) => {
    //console.log("showmodal ", opcion)
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarmodal({ origen: "modalseleccionarmovimientogeneral", respuesta: opcion });
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  seleccionarOperacion = async (item) => {
    this.setState({ idoperacionseleccionada: item.idopcion })
    this.setState({ idseleccionado: item.idopcion })
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {
                            this.state.listaoperacionesestatus
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion(item)}
                                  className={this.state.idoperacionseleccionada == item.idopcion ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {item.opcion}
                                  </td>
                                </tr>
                              ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    disabled={this.state.idseleccionado ? false : true}
                    onClick={() => this.showModal(this.state.idseleccionado)}>
                    Continuar
                  </Button>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal >
      </>
    )
  }
}
