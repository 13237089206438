import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
//import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { getComboAreasDeTrabajo, getListaEncargadosRancho, getListaPuestosDeTrabajo } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalBuscadorEncargados extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            // accion: props.accion, // 1 Nuevo, 2 editar
            txtbuscador: "",
            tabla: {
                encabezados: [
                    "Empleado",
                    "Área",
                    "Puesto"

                ],
                dimensiones: [
                    "50%",
                    "20%",
                    "30%"
                ],
                mindimensiones: [
                    "300px",
                    "150px",
                    "150px"
                ],
                elementos: [],
            },
            listaempleados: [],
            empleadoseleccionado: undefined,
            idempleadoseleccionado: 0,

            filtroareasdetrabajo: -1,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listaareasdetrabajo: [],
            listaareasdetrabajofiltrados: [],
            listaempleadosfiltrados: [],

            areaseleccionado: "TodasLasAreas",
            puestoseleccionado: "TodosLosPuestos",
            listaarea: [],
            listapuesto: [],
            listafiltropuesto: [],
        }

    }
    async componentDidMount() {

        this.setState({ loadingspinners: true })

        var respareasdetrabajo = await getComboAreasDeTrabajo();
        if (this.manejadorLlamadasApis(respareasdetrabajo)) {
            return;
        }


        var resplistapuestos = await getListaPuestosDeTrabajo();
        if (this.manejadorLlamadasApis(resplistapuestos)) {
            return;
        }

        var respcatalogousuarios = await getListaEncargadosRancho();
        if (this.manejadorLlamadasApis(respcatalogousuarios)) {
            return;
        }


        var tabla = this.state.tabla;
        tabla.elementos = respcatalogousuarios.data;
        this.setState({
            txtbuscador: '',
            tabla: tabla,
            listaareasdetrabajo: respareasdetrabajo.data,
            listaareasdetrabajofiltrados: respareasdetrabajo.data,
            listapuesto: resplistapuestos.data,
            listafiltropuesto: resplistapuestos.data,
            listaempleadosfiltrados: tabla.elementos
        })
        this.setState({ loadingspinners: false })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalBuscadorEmpleados(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar datos del item de producto seleccionado
        this.showModal(this.state.empleadoseleccionado)

    }


    saveInputLocacion = async (e) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        var cadenain = e.target.value.trim()
        var tamanocadenain = cadenain.length
        var tamanocadenast = this.state.txtbuscador.trim().length
        var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

        this.setState({ txtbuscador: e.target.value });

        var filtropuesto = [];
        var filtroarea = [];
        var filtrofinal = [];

        if (tamanocadenain >= 3 || banderacasosespeciales) {
            filtroarea = data;
            if (this.state.areaseleccionado != "TodasLasAreas") {
                filtroarea = data.filter((item) => item.idarea == this.state.areaseleccionado)
            }
            filtropuesto = filtroarea;

            if (this.state.puestoseleccionado != "TodosLosPuestos") {
                filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
            } else { filtropuesto = filtroarea; }

            filtrofinal = filtropuesto;
            if (e.target.value.toLocaleLowerCase().length >= 3) {
                filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
            }
            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ listaempleadosfiltrados: filtrofinal });
        }

    }



    filtrarPorArea = async (itemfiltrado, accion) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            areaseleccionado: itemfiltrado
        })

        var filtroarea = [];
        var filtropuesto = [];
        var filtrofinal = [];

        filtroarea = data;
        if (itemfiltrado != "TodasLasAreas") {

            var resplistapuestos = this.state.listapuesto.filter((item) => item.idcatalogo_areas == itemfiltrado)

            await this.setState({
                listafiltropuesto: resplistapuestos,
                txtbuscador: ""
            })

            if (accion == 0) {
                await this.setState({
                    puestoseleccionado: "TodosLosPuestos"
                })
            }

            filtroarea = data.filter((item) => item.idarea == itemfiltrado)
        } else {
            await this.setState({
                listafiltropuesto: this.state.listapuesto,
                txtbuscador: ""
            })

            if (accion == 0) {
                await this.setState({
                    puestoseleccionado: "TodosLosPuestos"
                })
            }
        }

        if (this.state.puestoseleccionado != "TodosLosPuestos") {

            filtropuesto = filtroarea.filter((item) => item.idpuesto == this.state.puestoseleccionado)
        } else {
            filtropuesto = filtroarea;
        }

        if (this.state.txtbuscador.length >= 3) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador))
        } else {
            filtrofinal = filtropuesto;
            await this.setState({
                txtbuscador: ""
            })
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        await this.setState({
            listaempleadosfiltrados: filtrofinal
        })
    }

    borrarBusqueda = () => {
        this.setState({
            txtbuscador: '',
            filtroareasdetrabajo: -1,
            areaseleccionado: "TodasLasAreas",
            puestoseleccionado: "TodosLosPuestos",
            listaempleadosfiltrados: this.state.tabla.elementos
        });
    }

    seleccionarEncargado = async (item) => {
        await this.setState({
            empleadoseleccionado: item,
            confirmarenvio: true,
            idempleadoseleccionado: item.idusuario
        })
    }



    filtrarPuesto = async (itemfiltrado) => {
        var data1 = JSON.parse(JSON.stringify(this.state.tabla))
        var data = data1.elementos;

        await this.setState({
            puestoseleccionado: itemfiltrado
        })

        var filtroarea = [];
        var filtropuesto = [];
        var filtrofinal = [];
        filtroarea = data;
        if (this.state.areaseleccionado != "TodasLasAreas") {

            filtroarea = data.filter((item) => item.idarea == this.state.areaseleccionado)
        }


        filtropuesto = filtroarea;
        if (itemfiltrado != "TodosLosPuestos") {

            filtropuesto = filtroarea.filter((item) => item.idpuesto == itemfiltrado)
        }

        if (this.state.txtbuscador.length >= 3) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(this.state.txtbuscador))

        } else {
            filtrofinal = filtropuesto;
            await this.setState({
                txtbuscador: ""
            })
        }

        if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }


        await this.setState({
            listaempleadosfiltrados: filtrofinal
        })

    }






    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Empleados registrados</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row '>

                                    <div className=' containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelectModal' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Área</label>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarPorArea(event.target.value, 0)}>
                                                    {this.state.areaseleccionado == "TodasLasAreas" ?
                                                        <option selected="true" value={"TodasLasAreas"}>Todas</option> : <option value={"TodasLasAreas"}>Todas</option>}
                                                    {this.state.listaareasdetrabajofiltrados.map((i, index) => (
                                                        <option value={i.idcatalogo_areas} key={"tipo_" + index}  >{i.area} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' containerSubTabla paddingBottomEncabezados'>
                                        <div className='divComboSelectModal' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Puesto</label>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarPuesto(event.target.value)}>
                                                    {this.state.puestoseleccionado == "TodosLosPuestos" ?
                                                        <option selected="true" value='TodosLosPuestos'>Todos</option> : <option value='TodosLosPuestos'>Todos</option>}
                                                    {this.state.listafiltropuesto.map((i, index) => (
                                                        <option value={i.idcatalogo_puestos} key={"tipo_" + index}  >{i.puesto} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' containerSubTabla paddingBottomEncabezados'>
                                        <div className='txtBuscadorContenedor' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorModal letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                                placeholder="Buscar empleado"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>

                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                    {

                                                        this.state.listaempleadosfiltrados.length != 0 ? (
                                                            this.state.listaempleadosfiltrados.map((item, index) => (
                                                                <tr key={"tabla" + index} onClick={() => this.seleccionarEncargado(item)} className={this.state.idempleadoseleccionado == item.idusuario ? "trFondoActivoGreen" : ""}>
                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                        {item.nombre}
                                                                    </td>
                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                        {item.area}
                                                                    </td>
                                                                    <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                        {item.puesto}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : "No hay encargados"




                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

