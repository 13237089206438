import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioNuevaRequisicion.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { estiloColorError, estiloBtnActualizar, estilosModal } from '../../../Services/PaletaDeColores';
import { obtenerUltimoProveedorSugerido } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import ModalFormularioProveedoresDisponibles from './ModalFormularioProveedoresDisponibles';

export default class ModalFormularioAsignarProveedor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: true,
            accion: props.accion,
            titulo: props.titulomodal,
            loadingspinners: true,

            opcion1: true,
            opcion2: false,
            opcion3: false,
            opcion4: false,

            idultimacompra: 0,
            nombreultimacompra: "",
            idultimarecomendacion: 0,
            nombreultimarecomendacion: "",
            proveedorseleccionado: undefined,

            modalproveedoresdisponibles: false,

            vermsgerrorpassword: false,
            msgerror: '',
            msgerrorpassword: "",
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
        }
    }
    async componentDidMount() {
        var proveedorsugerido = await obtenerUltimoProveedorSugerido(this.props.producto.idproducto);
        if (this.manejadorLlamadasApis(proveedorsugerido)) {
            return;
        }

        await this.setState({
            idultimacompra: proveedorsugerido.data.idproveedor_compra,
            nombreultimacompra: proveedorsugerido.data.proveedor_compra,
            idultimarecomendacion: proveedorsugerido.data.idproveedor_recomendado,
            nombreultimarecomendacion: proveedorsugerido.data.proveedor_recomendado,
            loadingspinners: false,
        })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 401) {
            this.setState({ salir: true })
            return true;
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        } else if (resp.code == 200) {
            this.setState({ loadingspinners: false })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true;
        } else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.props.activarSpinner(bandera);
    }

    showModal = (datos) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalAsignarProveedor(datos);
        }, 200);
    }

    guardarCambios = async () => {

        let json = {
            "nombreproveedor": "Sin proveedor",
            "idproveedorsugerido": -1
        }
        if (this.state.opcion1) {
            json.idproveedorsugerido = 0;
        } else if (this.state.opcion2) {
            json.nombreproveedor = this.state.nombreultimacompra
            json.idproveedorsugerido = this.state.idultimacompra;
        } else if (this.state.opcion3) {
            json.nombreproveedor = this.state.nombreultimarecomendacion
            json.idproveedorsugerido = this.state.idultimarecomendacion
        } else if (this.state.opcion4) {
            if (this.state.proveedorseleccionado != undefined && this.state.proveedorseleccionado != "0") {
                json.nombreproveedor = this.state.proveedorseleccionado.nombre;
                json.idproveedorsugerido = this.state.proveedorseleccionado.idcatalogo_proveedores;
            } else {
                toast.info("Falta seleccionar un proveedor", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

        if (json.idproveedorsugerido != -1) {
            this.showModal(json)
        }


    }

    cerrarModalProveedores = (datos) => {
        this.setState({
            modalproveedoresdisponibles: false,
            proveedorseleccionado: datos,
            opcion1: false,
            opcion2: false,
            opcion3: false,
            opcion4: true
        })
    }

    seleccionarOpcion = (opcion) => {
        this.setState({
            opcion1: false,
            opcion2: false,
            opcion3: false,
            opcion4: false,
            confirmarenvio: true
        })
        this.setState({ [opcion]: true });
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}
                {(this.state.modalproveedoresdisponibles == true) ? (
                    <ModalFormularioProveedoresDisponibles titulomodal={this.state.titulomodal} showModal={this.showModal}
                        setLoadingSpinner={this.setLoadingSpinner}
                        show={this.state.modalproveedoresdisponibles} accion={this.state.accionmodal}
                        cerrarModalProveedores={this.cerrarModalProveedores} />
                ) : null}
                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Asignar proveedor</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="row divContenedorCkeckModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <div className="col-auto checkModal"   >
                                    <input className='form-check-input' type="radio" name="opcionproveedor" id='sinasignar' checked={this.state.opcion1}
                                        onChange={() => this.seleccionarOpcion("opcion1")}
                                    />
                                    <label class="form-check-label ms-2" for="sinasignar">Sin asignar</label>
                                </div>

                                {this.state.idultimacompra != "" && this.state.nombreultimacompra != "" ?
                                    <div className="col-auto checkModal">
                                        <div className=''>
                                            <input className='form-check-input' type="radio" name="opcionproveedor" id='ultimacompra' checked={this.state.opcion2}
                                                onChange={() => this.seleccionarOpcion("opcion2")} />
                                        </div>
                                        <div className='col6 ms-2'>
                                            <label class="form-check-label" for="ultimacompra">Última compra</label>
                                            <div className='col-12 especialLabel'><p><b>{this.state.nombreultimacompra}</b></p></div>
                                        </div>

                                    </div> : null
                                }

                                {this.state.idultimarecomendacion != "" && this.state.nombreultimarecomendacion != "" ?
                                    <div className="col-auto checkModal">
                                        <div className=''>
                                            <input className='form-check-input' type="radio" name="opcionproveedor" id='ultimarecom' checked={this.state.opcion3}
                                                onChange={() => this.seleccionarOpcion("opcion3")} />
                                        </div>
                                        <div className='col6 ms-2'>
                                            <label class="form-check-label" for="ultimarecom">Última recomendación</label>
                                            <div className='col-12 especialLabel'><p><b>{this.state.nombreultimarecomendacion}</b></p></div>
                                        </div>

                                    </div> : null
                                }


                                <div className="col-auto checkModal" >
                                    <div className='ml-3'>
                                        <input className='form-check-input' type="radio" name="opcionproveedor" id='idproveedor' checked={this.state.opcion4}
                                            onChange={() => this.seleccionarOpcion("opcion4")} />
                                    </div>
                                    <div className='col6 ms-2'>
                                        <label class="form-check-label" for="idproveedor">Otro: {this.state.proveedorseleccionado != undefined ? this.state.proveedorseleccionado.nombre : ""}</label>

                                        <div className='col-12 especialLabel'>
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <button className='btnActualizar letraNormal'
                                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                                    onClick={() => this.setState({ modalproveedoresdisponibles: true })}
                                                ><BiSearchAlt2 />&nbsp;Buscar proveedor
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <>

                            <br />
                            <div className="divBtnContenedor" >
                                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                            </div>
                        </>


                    </ModalBody>

                </Modal>
            </>
        )
    }

}

