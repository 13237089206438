import React from 'react'
import './CotizacionProductoGeneral.css';
import { estiloBtnActualizar, estiloBtnEditar, estiloTabla } from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatoNumero, formatoNumeroTecla, formatoNumero2 } from '../../Services/Validaciones';

export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      listaproductos: props.listaproductos,
      total: 0,

      tabla: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Precio",
          "IVA",
          "Total",
        ],
        dimensiones: [
          "20%",
          "20%",
          "15%",
          "15%",
          "15",
          "15",
        ],
        mindimensiones: [
          "300px",
          "200px",
          "200px",
          "200px",
          "200px",
          "200px"
        ],
        elementos: []
      },

      accionmodal: 1,
      titulomodal: '',
      modalnuevoproducto: false,
      errordeacceso: false,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,

      btnbordereditar: estiloBtnEditar().btnbordereditar,
      btnbordereditar2: "2px solid #0061fe",
    }
  }

  async componentDidMount() {

    this.sumarPrecios();

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);

  }

  sumarPrecios = () => {
    var totalaux = 0
    this.state.listaproductos.map((producto) => {
      totalaux = totalaux + parseFloat(producto.precio);
    })
    this.setState({total: totalaux})
  }

  sumarPrecios2 = () => {
    var totalaux = 0
    this.props.listaproductos.map((producto) => {
      totalaux = totalaux + parseFloat(producto.precio);
    })
    
    var newTotal = ""+totalaux;
    var auxTotal = newTotal.split('.');
    if(auxTotal.length > 1){
        newTotal = auxTotal[0].slice(0,7)+"."+auxTotal[1].slice(0,2)
    }else{
        newTotal = auxTotal[0].slice(0,7)+".00"
    }

    return newTotal;
  }

  agregaDecimales = (valor) => {
        
    var newValor = ""+valor;
    var auxTotal = newValor.split('.');
    if(auxTotal.length > 1){
      newValor = auxTotal[0].slice(0,7)+"."+auxTotal[1].slice(0,2)
    }else{
      newValor = auxTotal[0].slice(0,7)+".00"
    }

    return newValor;
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  render() {
    return (
      <>
        <ToastContainer />

        <br />
        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
        <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
          <div className="container-fluid">
            <div className='row'>
              <div>

                <Table hover>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                            textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px' 
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.props.listaproductos!=undefined ? this.props.listaproductos.map((item, index) => (
                      <tr key={index}>
                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.producto}
                        </td>
                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                          {item.unidadmedida}
                        </td>
                        <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                          {formatoNumero2(item.cantidad)}
                        </td>
                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                          ${formatoNumeroTecla(item.precio,this.props.cuantosdecimales)}
                        </td>
                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                          ${formatoNumeroTecla(item.iva,this.props.cuantosdecimales)}
                        </td>                       
                        <td key={"col-" + index + "-07"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right' }}>
                          ${formatoNumeroTecla(parseFloat(item.precio)+parseFloat(item.iva),this.props.cuantosdecimales)}
                        </td>
                      </tr>
                    )) : null} 
                    
                    <br /><br />
                    <tr>
                    <td className=" txttotal" colSpan={3} style={{ color: this.state.colorcontenidotabla }}>
                          {"Totales acumulados"}
                        </td>
                        
                      
                        <td className=" txttotal" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right'}}>
                          ${formatoNumeroTecla(this.props.totalprecios, this.props.cuantosdecimales)}
                        </td>
                        <td className=" txttotal" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right'}}>
                        ${formatoNumeroTecla(this.props.totalivas, this.props.cuantosdecimales)}
                        </td>
                       
                        <td className=" txttotal" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right'}}>
                        ${formatoNumeroTecla(this.props.totalsuma,this.props.cuantosdecimales)}
                        </td>
                    </tr>
                </tbody>
                </Table>


              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
