import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalDocumentos";
import { estiloColorError, estiloComboBox, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import {
  validarPasswordEliminar
} from '../../../Services/Validaciones';
import { getListaProveedoresUpdate, getRequisicionesUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';

export default class ModalFormularioAccionEstatusContrasena extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion ? props.accion : 1, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      item: props.item,

      // Modales de aprobación
      txtpassword: "",
      vermsgerrortxtpassword: false,
      msgerrortxtpassword: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colortitulo: estiloFiltroEncabezados().colortitulos,
    }

  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  escribir = (prop) => (event) => {
    if ('txtclabe' == prop) {
      this.setState({ [prop]: event.target.value.toUpperCase() });
    } else {
      this.setState({ [prop]: event.target.value });
    }
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    let validacionescorrectas = true
    if (this.state.accion == 1 || this.state.accion == 2) {
      this.setState({ vermsgerrortxtpassword: false })

      let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
      if (txtpassword.codigo == "invalid") {
        this.setState({ msgerrortxtpassword: txtpassword.mensaje })
        this.setState({ vermsgerrortxtpassword: true })
        validacionescorrectas = false;
      }
    }


    if (validacionescorrectas) {
      this.setState({ loadingspinners: true })
      var resp = {};
      var json = {};

      if (this.state.accion == 1) {
        json = {
          "idcatalogo_requisicion": this.state.item.idcatalogo_requisicion,
          "password": this.state.txtpassword,
          "idestatusrequisicion": this.state.item.idestatusrequisicion,//this.state.item.Idestatusrequisocion,
          "comentario": this.state.item.txtmotivo, // Requerido cuando cuando el estatus es Autorizado o Rechazado
          "condicion": 0, // Requerido cuando estatus es Autorizado
          "nuevoprecio": 0.0 // Requerido cuando condición sea igual a 1
        }
        resp = await getRequisicionesUpdate(json);
      }
      else if (this.state.accion == 2) {
        json = {
          "idcatalogo_requisicion": this.state.item.idcatalogo_requisicion,
          "password": this.state.txtpassword,
          "idestatusrequisicion": this.state.item.idestatusrequisicion,//this.state.item.Idestatusrequisocion,
          "comentario": '', // Requerido cuando cuando el estatus es Autorizado o Rechazado
          "condicion": 1, // Requerido cuando estatus es Autorizado
          "nuevoprecio": 0.0 // Requerido cuando condición sea igual a 1
        }
        resp = await getRequisicionesUpdate(json);
      }

      if (resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setState({ loadingspinners: false })
        return;
      }
      else if (resp.code == 200 || resp.code == 300) {
        this.setState({ loadingspinners: false })
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        this.showModal({ accion: 1, id: this.state.accion })
      }
    }

  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <div>
              <div className="divTituloModal">
                <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
              </div>
            </div>
            <br />

            {(this.state.accion == 1 || this.state.accion == 2) ?
              <div className="divModalEliminar">
                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                  {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}

                </div>
              </div>
              : null}


            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}>Guardar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

