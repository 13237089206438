import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsFillEyeFill } from 'react-icons/bs';
import { BiEdit } from "react-icons/bi";
import { FaTrashAlt } from 'react-icons/fa';
import { ImDownload3 } from 'react-icons/im'
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnActualizar } from '../../../Services/PaletaDeColores';
import { getFormasPagoCompras, postEliminarPagoVenta, postEliminarFacturaVenta, getDetallesVenta, eliminarFacturaGlobalGeneral, eliminarPagoGlobalGeneralVentas, eliminarPagoFacturaGlobalCompras, eliminarPagoPagoGlobalVentas } from '../../../Services/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

import { formatoNumero2 } from '../../../Services/Validaciones';

import ModalFormularioGuardarPago from './ModalFormularioGuardarPago';
import ModalFormularioGuardarFactura from './ModalFormularioGuardarFactura';
import ModalFormularioGuardarFacturaGlobal from './ModalFormularioGuardarFacturaGlobal';
import ModalFormularioGuardarPagoGlobal from './ModalFormularioGuardarPagoGlobal';
import ModalPedirPassword from '../ModalRectificacion/ModalPedirPassword';
import ModalVerImagen from './ModalVerImagen';

export default class ModalFormularioDetallesVenta extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            titulo: props.titulomodal,
            total: 0,
            loadingspinners: true,
            tabla: {
                encabezados: [
                    "Formas de pago",
                    "Fecha de pago",
                    "Monto",
                    "Comprobante de pago",
                    "",
                    "",
                    ""
                ],
                encabezadosFactura: [
                    "ID",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    "",
                    "",
                    ""
                ],
                encabezadosPagosGlobal: [
                    "Formas de pago",
                    "Fecha de pago",
                    "Monto",
                    "Comprobante de pago",
                    "",
                    ""
                ],
                encabezadosComprasGlobal: [
                    "ID venta",
                    "Fecha",
                    "Subtotal",
                    "Descuento",
                    "Total"
                ],
                encabezadosFacturaGlobal: [
                    "ID compra",
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    "",
                    ""
                ],
                encabezadosFacturaGlobal2: [
                    "ID factura",
                    "Fecha de factura",
                    "Monto",
                    "Factura",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%",
                    "5%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "140px",
                    "150px",
                    "10px",
                    "10px"
                ],
                mindimensiones2: [
                    "100px",
                    "100px",
                    "100px",
                    "140px",
                    "10px",
                    "10px"
                ],
                mindimensionesFacturasGlobal: [
                    "70px",
                    "70px",
                    "100px",
                    "100px",
                    "140px",
                    "10px",
                    "10px"
                ],
                dimensionesFacturasGlobal: [
                    "10%",
                    "10%",
                    "15%",
                    "15%",
                    "25%",
                    "5%",
                    "5%"
                ],
                elementos: this.props.respproductos,
            },

            condescuento: false,
            txtdescuento: "0.00",
            listaformaspago: [],
            rutaseleccionada: "",

            modalguardarpago: false,
            modalguardarfactura: false,
            modalguardarpagoglobal: false,
            modalguardarfacturaglobal: false,
            modalpedirpassword: false,
            modalverimg: false,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            listaproductosfiltrados: this.props.item.productos,

            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

            infoDetalle: {},
            pagoEliminar: {},
            pagoEliminarEnabled: false,
            facturaEliminar: {},
            facturaEliminarEnabled: false,
            pagofacturaGlobalGeneralEliminar: {},
            facturaGlobaGeneralEliminarEnabled: false,
            pagoGlobaGeneralEliminarEnabled: false,
            haycambios: false,
            pagoGlobalEliminar: {},
            pagoGlobalEliminarEnabled: false,
            pagoPagoGlobalEliminarEnabled: false,

            prueba:false
        }

    }

    async componentDidMount() {

        this.setState({ loadingspinners: true })

        //se obtiene los detalles de la compra
        var respdetallesventa = await getDetallesVenta({ idventa: this.props.item.idventa });

        if (this.manejadorLlamadasApis(respdetallesventa)) {
            this.setState({ loadingspinners: false })
            return;
        }

        //se obtiene las formas de pago
        var respformaspago = await getFormasPagoCompras();
        if (this.manejadorLlamadasApis(respformaspago)) {
            this.setState({ loadingspinners: false })
            return;
        }

        await this.setState({
            listaformaspago: respformaspago.data,
            infoDetalle: respdetallesventa.data,
            haycambios: false
        });

        await this.setState({
            loadingspinners: false,
            condescuento: respdetallesventa.data.tienedescuento,
            txtdescuento: respdetallesventa.data.descuento,
            pagoEliminarEnabled: false,
            facturaEliminarEnabled: false,
            facturaGlobaGeneralEliminarEnabled: false,
            pagoGlobaGeneralEliminarEnabled: false,
            pagoGlobalEliminarEnabled: false,
            pagoPagoGlobalEliminarEnabled: false
        })

        if(respdetallesventa.data.global.ventasrelacionadas === undefined){
            this.setState({prueba:false})
        }else{
            this.setState({prueba:true})
        }
    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 1) {
            this.setState({ titulomodal: 'Guardar pago', accionmodal: 1, modalguardarpago: true })
        } else if (ban.opcion == 2) {
            this.setState({ titulomodal: 'Guardar factura', accionmodal: 2, modalguardarfactura: true })
        } else if (ban.opcion == 3) {
            this.setState({ titulomodal: 'Guardar pago global', accionmodal: 3, modalguardarpagoglobal: true })
        } else if (ban.opcion == 4) {
            this.setState({ titulomodal: 'Guardar factura global', accionmodal: 4, modalguardarfacturaglobal: true })
        } else if (ban.opcion == 5) {
            this.setState({ titulomodal: ban.titulo, accionmodal: 5, modalverimg: true, rutaseleccionada: ban.img })
        } else if (ban.opcion == 6) {
            this.setState({ titulomodal: 'Guardar pago de factura global', accionmodal: 6, modalguardarpago: true })
        } else if (ban.opcion == 7) {
            this.setState({ titulomodal: 'Guardar pago de pago global', accionmodal: 7, modalguardarpago: true })
        } else if (ban.opcion == 8) {
            this.setState({ titulomodal: 'Editar factura global', accionmodal: 8, modalguardarfacturaglobal: true })
        } else if (ban.opcion == 9) {
            this.setState({ titulomodal: 'Editar pago global', accionmodal: 9, modalguardarpagoglobal: true })
        }
    }

    cerrarModal = async (item) => {
        if (item.accion == 1) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarpago: !this.state.modalguardarpago })
        } else if (item.accion == 2) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarfactura: !this.state.modalguardarfactura })
        } else if (item.accion == 3) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarpagoglobal: !this.state.modalguardarpagoglobal })
        } else if (item.accion == 4) {
            if (item.resp != undefined) {
                await this.componentDidMount()
            }
            this.setState({ modalguardarfacturaglobal: !this.state.modalguardarfacturaglobal })
        } else if (item.accion == 5) {
            this.setState({ modalverimg: !this.state.modalverimg })
        }
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    eliminarPagoFactura = (item) => {
        if (item.opcion == 1) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idventa": item.pago.idventa,
                "password": ""
            }
            this.setState({ pagoEliminar: pagoDelete, pagoEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 2) {
            let facturaDelete = {
                "idfactura": item.factura.idfactura,
                "idventa": item.factura.idventa,
                "password": ""
            }
            this.setState({ facturaEliminar: facturaDelete, facturaEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 3) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idventa": item.pago.idventa,
                "password": ""
            }
            this.setState({ pagoGlobalEliminar: pagoDelete, pagoGlobalEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 4) {
            let pagoDelete = {
                "idpago": item.pago.idpago,
                "idventa": item.pago.idventa,
                "password": ""
            }
            this.setState({ pagoGlobalEliminar: pagoDelete, pagoPagoGlobalEliminarEnabled: true, modalpedirpassword: true })
        }
    }

    eliminarPagoFacturaGlobalGeneral = (item) => {
        let request = {
            "idventa": this.props.item.idventa,
            "password": ""
        }
        if (item.opcion == 1) {
            this.setState({ pagofacturaGlobalGeneralEliminar: request, pagoGlobaGeneralEliminarEnabled: true, modalpedirpassword: true })
        } else if (item.opcion == 2) {
            this.setState({ pagofacturaGlobalGeneralEliminar: request, facturaGlobaGeneralEliminarEnabled: true, modalpedirpassword: true })
        }
    }

    cerrarModalPedirPassword = async (accion) => {
        this.setState({ modalpedirpassword: !this.state.modalpedirpassword })
        if (accion != 0) {
            this.setLoadingSpinner(true)
            if (this.state.pagoEliminarEnabled == true) {
                this.state.pagoEliminar.password = accion;

                var resp = await postEliminarPagoVenta(this.state.pagoEliminar)

                let pagoDelete = {
                    "idpago": 0,
                    "idventa": 0,
                    "password": ""
                }
                this.setState({ pagoEliminar: pagoDelete, pagoEliminarEnabled: false })

                if(resp.code == 0){
                    toast.success("Pago eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    await this.componentDidMount()
                }
                else if(resp.code == 300){
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 400){
                    this.setLoadingSpinner(false)
                    toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if (resp.code == 1000) {
                    this.setLoadingSpinner(false)
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    return;
                }
            } else if (this.state.facturaEliminarEnabled == true) {
                this.state.facturaEliminar.password = accion;

                var resp = await postEliminarFacturaVenta(this.state.facturaEliminar)

                let facturaDelete = {
                    "idfactura": 0,
                    "idventa": 0,
                    "password": ""
                }
                this.setState({ facturaEliminar: facturaDelete, facturaEliminarEnabled: false })

                if(resp.code == 200){
                    toast.success("Factura eliminada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    await this.componentDidMount()
                }
                else if(resp.code == 300){
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 400){
                    this.setLoadingSpinner(false)
                    toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if (resp.code == 1000) {
                    this.setLoadingSpinner(false)
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    return;
                }
            } else if (this.state.facturaGlobaGeneralEliminarEnabled == true) {
                this.state.pagofacturaGlobalGeneralEliminar.password = accion;

                var resp = await eliminarFacturaGlobalGeneral(this.state.pagofacturaGlobalGeneralEliminar)

                let request = {
                    "idcompra": 0,
                    "password": ""
                }
                this.setState({ pagofacturaGlobalGeneralEliminar: request, facturaGlobaGeneralEliminarEnabled: false })

                if (resp.code == 1000) {
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    this.setLoadingSpinner(false)
                    return;
                }
                else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else {
                    await this.componentDidMount()
                }
            } else if (this.state.pagoGlobaGeneralEliminarEnabled == true) {
                this.state.pagofacturaGlobalGeneralEliminar.password = accion;

                var resp = await eliminarPagoGlobalGeneralVentas(this.state.pagofacturaGlobalGeneralEliminar)

                let request = {
                    "idventa": 0,
                    "password": ""
                }
                this.setState({ pagofacturaGlobalGeneralEliminar: request, pagoGlobaGeneralEliminarEnabled: false })

                if(resp.code == 0){
                    toast.success("Pago global eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    await this.componentDidMount()
                }
                else if(resp.code == 300){
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 400){
                    this.setLoadingSpinner(false)
                    toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if (resp.code == 1000) {
                    this.setLoadingSpinner(false)
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    return;
                }
            } else if (this.state.pagoGlobalEliminarEnabled == true) {
                this.state.pagoGlobalEliminar.password = accion;
                var resp = await eliminarPagoFacturaGlobalCompras(this.state.pagoGlobalEliminar)

                let request = {
                    "idventa": 0,
                    "idpago": 0,
                    password: ""
                }
                this.setState({ pagoGlobalEliminar: request, pagoGlobalEliminarEnabled: false })

                if (resp.code == 1000) {
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    this.setLoadingSpinner(false)
                    return;
                }
                else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else {
                    await this.componentDidMount()
                }
            } else if (this.state.pagoPagoGlobalEliminarEnabled == true) {
                this.state.pagoGlobalEliminar.password = accion;
                var resp = await eliminarPagoPagoGlobalVentas(this.state.pagoGlobalEliminar)
                let request = {
                    "idventa": 0,
                    "idpago": 0,
                    password: ""
                }
                this.setState({ pagoGlobalEliminar: request, pagoPagoGlobalEliminarEnabled: false })

                if(resp.code == 0){
                    toast.success("Pago de pago global eliminado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    await this.componentDidMount()
                }
                else if(resp.code == 300){
                    this.setLoadingSpinner(false)
                    toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if(resp.code == 400){
                    this.setLoadingSpinner(false)
                    toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                    return;
                }
                else if (resp.code == 1000) {
                    this.setLoadingSpinner(false)
                    toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                    return;
                }
            }
        }
    }

    calcularSubtotal(listacompras) {
        var subtotal = 0.00;
        listacompras.map((compra) => {
            subtotal = subtotal + parseFloat(compra.precio)
        })
        return subtotal;
    }

    calcularDescuento(listacompras) {
        var descuento = 0.00;
        listacompras.map((compra) => {
            descuento = descuento + parseFloat(compra.descuento)
        })
        return descuento;
    }

    calcularTotal(listacompras) {
        var total = 0.00;
        listacompras.map((compra) => {
            total = total + parseFloat(compra.total)
        })
        return total;
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modalguardarpago == true) ? (
                    <ModalFormularioGuardarPago
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarpago}
                        accion={this.state.accionmodal}
                        listaformaspago={this.state.listaformaspago}
                        idventa={this.props.item.idventa}
                        cliente={this.props.item.cliente}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarfactura == true) ? (
                    <ModalFormularioGuardarFactura
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarfactura}
                        accion={this.state.accionmodal}
                        idventa={this.props.item.idventa}
                        cliente={this.props.item.cliente}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarpagoglobal == true) ? (
                    <ModalFormularioGuardarPagoGlobal
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarpagoglobal}
                        accion={this.state.accionmodal}
                        listaproductos={this.props.item.productos}
                        listacompras={this.props.listacompras}
                        idventa={this.props.item.idventa}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalguardarfacturaglobal == true) ? (
                    <ModalFormularioGuardarFacturaGlobal
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalguardarfacturaglobal}
                        accion={this.state.accionmodal}
                        idcompra={this.props.item.idcompra}
                        setLoadingSpinner={this.setLoadingSpinner}
                    />
                ) : null}

                {(this.state.modalpedirpassword == true) ? (
                    <ModalPedirPassword titulomodal={"Autorizar acción"}
                        cerrarModal={this.cerrarModalPedirPassword}
                        show={this.state.modalpedirpassword}
                        accion={this.state.accionmodal} />
                ) : null}

                {(this.state.modalverimg == true) ? (
                    <ModalVerImagen
                        titulomodal={this.state.titulomodal}
                        cerrarModal={this.cerrarModal}
                        show={this.state.modalverimg}
                        accion={this.state.accionmodal}
                        img={this.state.rutaseleccionada} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('5')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo} {this.props.item.idcompra}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal">

                                {/* ////////////////////   Detalles general   ////////////////////////////////////// */}
                                <div className='row' style={{ border: '2px solid #2371FD', borderRadius: '10px' }}>
                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            ID venta
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.props.item.idventa}
                                        </div>
                                    </div>
                                    <div className='col-9 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                            Cliente
                                        </div>
                                        <div className='lblTexto letraTitulos'>
                                            {this.state.infoDetalle != undefined ? this.state.infoDetalle.cliente : null}
                                        </div>
                                    </div>


                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos' style={{ textAlign: 'center' }}>
                                            <label><strong><span style={{ color: this.state.colortipolbl, marginRight: '5px' }}>Total actual: </span><span> ${this.state.infoDetalle != undefined ? formatoNumero2(this.state.infoDetalle.total) : null}</span></strong></label>
                                        </div>
                                    </div>
                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <label><span style={{ color: this.state.colortipolbl }}>Pagado: </span>

                                                {
                                                    this.state.infoDetalle != undefined ? (

                                                        isNaN(parseFloat(this.state.infoDetalle.pagado)) ? (this.state.infoDetalle.pagado) : ("$" + formatoNumero2(this.state.infoDetalle.pagado))

                                                    ) : null

                                                }




                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-4 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <label><span style={{ color: this.state.colortipolbl }}>Pendiente: </span>
                                                {
                                                    this.state.infoDetalle != undefined ? (

                                                        isNaN(parseFloat(this.state.infoDetalle.pendiente)) ? (this.state.infoDetalle.pendiente) : ("$" + formatoNumero2(this.state.infoDetalle.pendiente))

                                                    ) : null

                                                }

                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-3 containerSubTabla paddingBottomEncabezados'>
                                        <div className='lblTexto letraTitulos'>
                                            <Button className="btnAceptarModal" onClick={this.verModalDetalles.bind(this, { opcion: "3" })}>Pago global</Button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                {/* ////////////////////   Tabla pago global  ////////////////////////////////////// */}
                                <br />
                                {   this.state.infoDetalle.pagoglobal == true ?
                                   // this.state.prueba == true ?
                                        (<>

                                            <br />
                                            <div className='row' style={{ background: '#EBF9FF', borderRadius: '10px' }}>
                                                <div className='row' style={{ marginTop: '20px' }}>
                                                    <div className='col-8'>
                                                        <label className="lblEncabezadosTabla letraTitulos"><strong>Pago global</strong></label>
                                                    </div>
                                                    <div className='col-3 containerSubTablaAA'>
                                                        <button className='btnActualizar letraNormal'
                                                            style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: '2px solid #2371FD' }}
                                                            onClick={this.verModalDetalles.bind(this, { opcion: "9" })}>
                                                            <BiEdit className="icoBotones" />Editar</button>
                                                    </div>
                                                    <div className='col-1 containerSubTablaAA'>
                                                        <button className='divBotonesTrash btnEliminarModalDetalles'
                                                            onClick={this.eliminarPagoFacturaGlobalGeneral.bind(this, { opcion: "1" })}>
                                                            <FaTrashAlt className="icoBotones iconbtneliminar" /></button>
                                                    </div>
                                                </div>

                                                <br />
                                                <br />
                                                <div className="container-fluid containerTabla">
                                                    <div className="container-fluid anchoMaximo ">
                                                        <div className="containerSubTabla">

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Ventas relacionadas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosComprasGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                                    {this.state.infoDetalle.global.ventasrelacionadas.length > 0 ?
                                                                        this.state.infoDetalle.global.ventasrelacionadas.map((venta) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {venta.idventa}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {venta.fechaventa}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(venta.precio)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(venta.descuento)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    $ {formatoNumero2(venta.total)}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}

                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="containerSubTablaAA" colSpan={2} style={{ color: this.state.colorcontenidotabla }}>
                                                                            <b>Totales acumulados</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularSubtotal(this.state.infoDetalle.global.ventasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularDescuento(this.state.infoDetalle.global.ventasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>$ {formatoNumero2(this.calcularTotal(this.state.infoDetalle.global.ventasrelacionadas))}</b>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            <br />

                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <label className="containerSubTablaAA"><strong>Pago:</strong></label>
                                                                </div>
                                                                <div className='col-3'>
                                                                    <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                                        onClick={this.verModalDetalles.bind(this, { opcion: "7" })}>
                                                                        {"Agregar pago"}
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosPagosGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.pagos.length > 0 ?
                                                                        this.state.infoDetalle.global.pagos.map((pago) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.tipopago}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.fechapago}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(pago.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {pago.comprobante}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={pago.rutaComprobante} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 4, pago: pago })}>
                                                                                        <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>

                                                            <br />

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Facturas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosFacturaGlobal2.map((titulo, index) => (
                                                                            <th
                                                                                className={(index > 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.facturas.length > 0 ?
                                                                        this.state.infoDetalle.global.facturas.map((factura) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.idfactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.fechafactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(factura.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.factura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={factura.rutaFactura} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </>)
                                        : null
                                }

                                {/* ////////////////////   Tabla factura global  ////////////////////////////////////// */}
                                {
                                    this.state.infoDetalle.facturaglobal == true ?
                                        (<>

                                            <br />
                                            <div className='row' style={{ background: '#EBF9FF', borderRadius: '10px' }}>
                                                <div className='row' style={{ marginTop: '20px' }}>
                                                    <div className='col-8'>
                                                        <label className="lblEncabezadosTabla letraTitulos"><strong>Factura global</strong></label>
                                                    </div>
                                                    <div className='col-3 containerSubTablaAA'>
                                                        <button className='btnActualizar letraNormal'
                                                            style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: '2px solid #2371FD' }}
                                                            onClick={this.verModalDetalles.bind(this, { opcion: "8" })}>
                                                            <BiEdit className="icoBotones" />Editar</button>
                                                    </div>
                                                    <div className='col-1 containerSubTablaAA'>
                                                        <button className='divBotonesTrash btnEliminarModalDetalles'
                                                            onClick={this.eliminarPagoFacturaGlobalGeneral.bind(this, { opcion: "2" })}>
                                                            <FaTrashAlt className="icoBotones iconbtneliminar" /></button>
                                                    </div>
                                                </div>

                                                <br />
                                                <br />
                                                <div className="container-fluid containerTabla">
                                                    <div className="container-fluid anchoMaximo ">
                                                        <div className="containerSubTabla">

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Ventas relacionadas:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosComprasGlobal.map((titulo, index) => (
                                                                            <th
                                                                                className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>

                                                                    {this.state.infoDetalle.global.comprasrelacionadas.length > 0 ?
                                                                        this.state.infoDetalle.global.comprasrelacionadas.map((compra) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.idcompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {compra.fechacompra}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.precio)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.descuento)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(compra.total)}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}

                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="containerSubTablaAA" colSpan={2} style={{ color: this.state.colorcontenidotabla }}>
                                                                            <b>Totales acumulados</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularSubtotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularDescuento(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                        <td className="containerSubTablaAA " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            <b>${formatoNumero2(this.calcularTotal(this.state.infoDetalle.global.comprasrelacionadas))}</b>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            {/*<br />

                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label className="containerSubTablaAA"><strong>Pagos:</strong></label>
                                                            </div>
                                                            <div className='col-3'>
                                                                <Button className='btnAceptarModal' style={{position: 'relative'}}
                                                                onClick={this.verModalDetalles.bind(this, { opcion: "6" })}>
                                                                    {"Agregar pago"} 
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        
                                                        <Table hover>
                                                            <thead style={{ verticalAlign: "bottom" }}>
                                                                <tr>
                                                                    {this.state.tabla.encabezadosPagosGlobal.map((titulo, index) => (
                                                                        <th
                                                                            className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                            style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                            {titulo}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>

                                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.pagos.length>0 ? 
                                                                    this.state.infoDetalle.global.pagos.map((pago)=>(
                                                                        <tr style={{ borderTop: 'solid 2px black'}} >
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.tipopago}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.fechapago}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.monto}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {pago.comprobante}
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                <a href={pago.rutaComprobante} download>
                                                                                    <div className='divBotonesTrash'>
                                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                                    </div>
                                                                                </a>
                                                                            </td>
                                                                            <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 3, pago: pago })}>
                                                                                    <FaTrashAlt className="icoBotonesTrash iconbtneliminar"/>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                : null}
                                                            </tbody>
                                                        </Table>*/}

                                                            <br />

                                                            <div className='col-12 containerSubTablaAA'>
                                                                <b>Factura:</b>
                                                            </div>

                                                            <Table hover>
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        {this.state.tabla.encabezadosFacturaGlobal2.map((titulo, index) => (
                                                                            <th
                                                                                className={(index > 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                                style={{ minWidth: this.state.tabla.mindimensiones2[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                                {titulo}
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {this.state.infoDetalle.global.facturas.length > 0 ?
                                                                        this.state.infoDetalle.global.facturas.map((factura) => (
                                                                            <tr style={{ borderTop: 'solid 2px black' }} >
                                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.idfactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.fechafactura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                                    ${formatoNumero2(factura.monto)}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    {factura.factura}
                                                                                </td>
                                                                                <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                                    <a href={factura.rutaFactura} download>
                                                                                        <div className='divBotonesTrash'>
                                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                        : null}
                                                                </tbody>
                                                            </Table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </>)
                                        : null
                                }


                                {/* ////////////////////   Tabla pagos  ////////////////////////////////////// */}
                                <br />
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className="lblEncabezadosTabla letraTitulos"><strong>Pagos</strong></label>
                                        </div>
                                        <div className='col-4'>
                                            <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>
                                                {"Agregar pago"}
                                            </Button>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="container-fluid containerTabla">
                                        <div className="container-fluid anchoMaximo ">
                                            <div className="containerSubTabla">

                                                <Table hover>
                                                    <thead style={{ verticalAlign: "bottom" }}>
                                                        <tr>
                                                            {this.state.tabla.encabezados.map((titulo, index) => (
                                                                <th
                                                                    className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ verticalAlign: "bottom" }}>

                                                        {
                                                            this.state.infoDetalle.pagos != undefined && this.state.infoDetalle.pagos.length > 0 ?

                                                                this.state.infoDetalle.pagos.map((item, index) => (
                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.tipopago}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.fechapago}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            ${formatoNumero2(item.monto)}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.comprobante}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>

                                                                            {(item.comprobante != "") ? (
                                                                                <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.rutaComprobante, titulo: item.comprobante })}
                                                                                    style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                                                    <u>
                                                                                        <BsFillEyeFill className="icoBotones" />
                                                                                        <b className='btnVerTabla'>Ver </b></u>
                                                                                </label>
                                                                            ) : (null)
                                                                            }

                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {(item.comprobante != "") ? (
                                                                                <a href={item.rutaComprobante} download>
                                                                                    <div className='divBotonesTrash'>
                                                                                        <ImDownload3 className="icoBotonesTrash" />
                                                                                    </div>
                                                                                </a>
                                                                            ) : (null)
                                                                            }


                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 1, pago: item })}>
                                                                                <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                                : null

                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* ////////////////////   Tabla facturas  ////////////////////////////////////// */}
                                <br />
                                <div className='row'>
                                    <div className='row' style={{ marginTop: '20px' }}>
                                        <div className='col'>
                                            <label className="lblEncabezadosTabla letraTitulos"><strong>Facturas</strong></label>
                                        </div>
                                        <div className='col-4'>
                                            <Button className='btnAceptarModal' style={{ position: 'relative' }}
                                                onClick={this.verModalDetalles.bind(this, { opcion: "2" })}>
                                                {"Agregar factura"}
                                            </Button>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="container-fluid containerTabla">
                                        <div className="container-fluid anchoMaximo ">
                                            <div className="containerSubTabla">

                                                <Table hover>
                                                    <thead style={{ verticalAlign: "bottom" }}>
                                                        <tr>
                                                            {this.state.tabla.encabezadosFactura.map((titulo, index) => (
                                                                <th
                                                                    className={(index != 0) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                                                                    style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                    {titulo}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody style={{ verticalAlign: "bottom" }}>

                                                        {
                                                            this.state.infoDetalle.facturas != undefined && this.state.infoDetalle.facturas.length > 0 ?

                                                                this.state.infoDetalle.facturas.map((item, index) => (
                                                                    <tr style={{ borderTop: 'solid 2px black' }} >
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.idfactura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.fechafactura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla " style={{ textAlign: "right", color: this.state.colorcontenidotabla }}>
                                                                            ${formatoNumero2(item.monto)}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {item.factura}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <label onClick={() => this.verModalDetalles({ opcion: 5, img: item.rutaFactura, titulo: item.factura })}
                                                                                style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                                                <u>
                                                                                    <BsFillEyeFill className="icoBotones" />
                                                                                    <b className='btnVerTabla'>Ver </b></u>
                                                                            </label>
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <a href={item.rutaFactura} download>
                                                                                <div className='divBotonesTrash'>
                                                                                    <ImDownload3 className="icoBotonesTrash" />
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                                                                            <button className='divBotonesTrash btnEliminarModalDetalles' onClick={() => this.eliminarPagoFactura({ opcion: 2, factura: item })}>
                                                                                <FaTrashAlt className="icoBotonesTrash iconbtneliminar" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                                : null

                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}

