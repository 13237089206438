import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioProductos.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud, validarLetrasNumerosEspaciosGuiones, validarLetrasMayusculasYNumeros, validarLetrasConEspacios, validarIntervaloSeguridad } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaProductosUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioProductos extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo
            titulo: props.titulomodal,
            loadingspinners: false,
            txtclave: '',
            txtnombrecomercial: '',
            txttipoproducto: '',
            txttipofamilia: '',
            txtunidadmedida: '',
            txtregistrocofepris: '',
            txtbandatoxicologica: '',
            txtintervaloseguridad: '',
            txttiemporeentrada: '',
            txtdosisminima: '',
            txtdosismaxima: '',
            txtvolumenagua: '',
            arrayestatus: props.estatus,
            idestatus: 1,
            vermsgerrorclave: false,
            vermsgerrornombrecomercial: false,
            vermsgerrortipofamilia: false,
            vermsgerrortipoproducto: false,
            vermsgerrorunidadmedida: false,
            vermsgerrorregistrocofepris: false,
            vermsgerrorbandatoxicologica: false,
            vermsgerrorintervaloseguridad: false,
            vermsgerrortiemporeentrada: false,
            vermsgerrordosisminima: false,
            vermsgerrordosismaxima: false,
            vermsgerrorvolumenagua: false,
            msgerror: '',
            msgerrorclave: "",
            msgerrornombrecomercial: "",
            msgerrortipofamilia: "",
            msgerrortipoproducto: "",
            msgerrorunidadmedida: "",
            msgerrorregistrocofepris: "",
            msgerrorbandatoxicologica: "",
            msgerrorintervaloseguridad: "",
            msgerrortiemporeentrada: "",
            msgerrordosisminima: "",
            msgerrordosismaxima: "",
            msgerrorvolumenagua: "",
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            listafamilia: props.listafamilia,
            listatipoproducto: props.listatipoproducto,
            listaunidadmedida: props.listaunidadmedida

        }

    }
    async componentDidMount() {
        this.cambiarValor(this.state.idestatus)
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorclave: false })
        this.setState({ vermsgerrornombrecomercial: false })
        this.setState({ vermsgerrortipoproducto: false })
        this.setState({ vermsgerrortipofamilia: false })
        this.setState({ vermsgerrorunidadmedida: false })
        this.setState({ vermsgerrorregistrocofepris: false })
        this.setState({ vermsgerrorbandatoxicologica: false })
        this.setState({ vermsgerrorintervaloseguridad: false })
        this.setState({ vermsgerrortiemporeentrada: false })
        this.setState({ vermsgerrordosisminima: false })
        this.setState({ vermsgerrordosismaxima: false })
        this.setState({ vermsgerrorvolumenagua: false })
        let validacionescorrectas = true
        let longitudvalida = ""
        let tipocorrecto = ""

        longitudvalida = {longitudminima: 1, longitudmaxima: 10};
        tipocorrecto = validarLetrasMayusculasYNumeros(this.state.txtclave.trim(), longitudvalida);
       /* if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorclave: tipocorrecto.mensaje })
            this.setState({ vermsgerrorclave: true })
            validacionescorrectas = false;
        }*/

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        tipocorrecto = validarLetrasNumerosLongitud(this.state.txtnombrecomercial.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrornombrecomercial: tipocorrecto.mensaje })
            this.setState({ vermsgerrornombrecomercial: true })
            validacionescorrectas = false;
        }

        if (this.state.txttipofamilia.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortipofamilia: "Seleccione una familia de agroquímico" })
            this.setState({ vermsgerrortipofamilia: true })
        }

        if (this.state.txttipoproducto.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortipoproducto: "Seleccione un tipo de agroquímico" })
            this.setState({ vermsgerrortipoproducto: true })
        }

        if (this.state.txtunidadmedida.length < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorunidadmedida: "Seleccione una unidad de medida" })
            this.setState({ vermsgerrorunidadmedida: true })
        }

        longitudvalida = {longitudminima: 0, longitudmaxima: 20};
        tipocorrecto = validarLetrasConEspacios(this.state.txtbandatoxicologica.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorbandatoxicologica: tipocorrecto.mensaje })
            this.setState({ vermsgerrorbandatoxicologica: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 0, longitudmaxima: 50};
        tipocorrecto = validarIntervaloSeguridad(this.state.txtintervaloseguridad.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorintervaloseguridad: tipocorrecto.mensaje })
            this.setState({ vermsgerrorintervaloseguridad: true })
            validacionescorrectas = false;
        }

        tipocorrecto = validarLetrasNumerosLongitud(this.state.txttiemporeentrada.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrortiemporeentrada: tipocorrecto.mensaje })
            this.setState({ vermsgerrortiemporeentrada: true })
            validacionescorrectas = false;
        }

        tipocorrecto = validarLetrasNumerosLongitud(this.state.txtdosismaxima.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrordosismaxima: tipocorrecto.mensaje })
            this.setState({ vermsgerrordosismaxima: true })
            validacionescorrectas = false;
        }

        tipocorrecto = validarLetrasNumerosLongitud(this.state.txtdosisminima.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrordosisminima: tipocorrecto.mensaje })
            this.setState({ vermsgerrordosisminima: true })
            validacionescorrectas = false;
        }

        tipocorrecto = validarLetrasNumerosLongitud(this.state.txtvolumenagua.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorvolumenagua: tipocorrecto.mensaje })
            this.setState({ vermsgerrorvolumenagua: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 0, longitudmaxima: 100};
        tipocorrecto = validarLetrasNumerosEspaciosGuiones(this.state.txtregistrocofepris.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorregistrocofepris: tipocorrecto.mensaje })
            this.setState({ vermsgerrorregistrocofepris: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);

            let json = {
                "idcatalogoproducto": this.state.accion == 1 ? ("0") : (this.props.item.idtipo_producto),
                "clave": "",
                "nombrecomercial": this.state.txtnombrecomercial,
                "idtipoproducto": this.state.txttipoproducto,
                "idcatalogo_unidadmedida": this.state.txtunidadmedida,
                "registrocofepris": this.state.txtregistrocofepris,
                "bandatoxicologica": this.state.txtbandatoxicologica,
                "intervaloseguiridad": this.state.txtintervaloseguridad,
                "tiemporeentrada": this.state.txttiemporeentrada,
                "dosisminima": this.state.txtdosisminima,
                "dosismaxima": this.state.txtdosismaxima,
                "volumenagua": this.state.txtvolumenagua,
                "idestatus": this.state.idestatus
            }

            var resp = await getListaProductosUpdate(json);
            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }

    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                             {/*   <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Clave</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtclave} onChange={this.escribir("txtclave")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtclave}</label>}

                                {this.state.vermsgerrorclave ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorclave}</p> : null}
*/}
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre comercial</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombrecomercial} onChange={this.escribir("txtnombrecomercial")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombrecomercial}</label>}

                                {this.state.vermsgerrornombrecomercial ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombrecomercial}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Familia agroquímico</p>
                                {<div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txttipofamilia")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listafamilia.map((i, index) => (
                                                        <option value={i.idfamilia_productos} key={"tipo_" + index}>{i.familia}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrortipofamilia ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipofamilia}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo agroquímico</p>
                                {<div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txttipoproducto")}>
                                                <option disabled="disabled" value="" selected="true">Seleccionar</option>
                                                    {this.state.listatipoproducto.map((i, index) => (
                                                        this.state.txttipofamilia == i.idfamilia_productos ?
                                                            <option value={i.idtipo_producto} key={"tipo_" + index}>{i.tipo}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                </div>}

                                {this.state.vermsgerrortipoproducto ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipoproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Unidad de medida</p>
                                {<div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txtunidadmedida")}>
                                                <option disabled="disabled" value="" selected="true">Seleccionar</option>
                                                    {this.state.listaunidadmedida.map((i, index) => (
                                                        <option value={i.idcatalogo_unidadmedida} key={"tipo_" + index}>{i.clave}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                </div>}

                                {this.state.vermsgerrorunidadmedida ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorunidadmedida}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Registro COFEPRIS</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtregistrocofepris} onChange={this.escribir("txtregistrocofepris")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtregistrocofepris}</label>}

                                {this.state.vermsgerrorregistrocofepris ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorregistrocofepris}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Banda toxicológica</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtbandatoxicologica} onChange={this.escribir("txtbandatoxicologica")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtbandatoxicologica}</label>}

                                {this.state.vermsgerrorbandatoxicologica ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorbandatoxicologica}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Intervalo de seguridad</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtintervaloseguridad} onChange={this.escribir("txtintervaloseguridad")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtintervaloseguridad}</label>}

                                {this.state.vermsgerrorintervaloseguridad ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorintervaloseguridad}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tiempo de reentrada</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttiemporeentrada} onChange={this.escribir("txttiemporeentrada")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttiemporeentrada}</label>}

                                {this.state.vermsgerrortiemporeentrada ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortiemporeentrada}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Dosis mínima</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdosisminima} onChange={this.escribir("txtdosisminima")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdosisminima}</label>}

                                {this.state.vermsgerrordosisminima? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordosisminima}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Dosis máxima</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdosismaxima} onChange={this.escribir("txtdosismaxima")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdosismaxima}</label>}

                                {this.state.vermsgerrordosismaxima ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordosismaxima}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Volumen agua</p>
                                {this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtvolumenagua} onChange={this.escribir("txtvolumenagua")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtvolumenagua}</label>}

                                {this.state.vermsgerrorvolumenagua ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorvolumenagua}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                        </div>

                        {this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

