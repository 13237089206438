import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { Table } from 'reactstrap';
import "./ModalFormularioTipoUsuarios.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { getPermisosUsuarios, guardarAccesosTipoUsuario } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import ModalFormularioTipoUsuariosNuevoAccesos from './ModalFormularioTipoUsuariosNuevoAccesos';

import { estiloBtnNuevo} from '../../../Services/PaletaDeColores';
import { BsTrash } from "react-icons/bs"


export default class ModalFormularioTipoUsuariosAccesos extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: true,
            txttipousuario: (props.accion == 3) ? (props.item.tipo) : '',
            txtidtipousuario: (props.accion == 3) ? (props.item.idtipo_usuario) : '',
            listatipomodulos: props.listatipomodulos,
            listatipopermisos: props.listatipopermisos,
            listamodulos: props.listamodulos,
            listamodulosseleccionado: [],
            listapermisosguardar: [],
            idestatus: 1,
            vermsgerrordescripcion: false,
            vermsgerrortipo: false,
            msgerror: '',
            msgerrortipo: "",
            msgerrordescripcion: "",
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            accionmodal: 4,
            titulomodal: '',
            modaldetalles: false,

            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
            borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,
        }

    }
    async componentDidMount() {
        var permisosusuarios = await getPermisosUsuarios(this.state.txtidtipousuario);
        if (this.manejadorLlamadasApis (permisosusuarios)) {
            return;
        }else{
            this.setModulosIniciales(permisosusuarios.data)
        }
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    setModulosIniciales = async (permisosusuarios) => {
        var modulosparamodal = [];
        
        permisosusuarios.forEach((tipomodulo)=>{
                tipomodulo.Modulos.forEach((modulo)=>{
                    let json = {
                        "idmodulo": modulo.Idmodulo,
                        "idtipomodulo": tipomodulo.Idtipomodulo,
                        "modulo": modulo.Modulo,
                        "permisosdisponibles": [],
                    }
                    
                    this.state.listamodulos.modulos.forEach((modulosapi)=>{
                        if(modulosapi.idmodulo==modulo.Idmodulo){
                            modulosapi.permisosdisponibles.forEach((apimpd)=>{
                                if(modulo.Permisos.filter((item)=>item.idpermiso==apimpd.idpermiso).length>0){
                                    let auxpermiso = {
                                        "idpermiso": apimpd.idpermiso,
                                        "permiso": apimpd.permiso,
                                        "check": true
                                    }
                                    this.checkPermiso(tipomodulo.Idtipomodulo,modulo.Idmodulo,apimpd.idpermiso, true)
                                    json.permisosdisponibles.push(auxpermiso)
                                }else{
                                    let auxpermiso = {
                                        "idpermiso": apimpd.idpermiso,
                                        "permiso": apimpd.permiso,
                                        "check": false
                                    }
                                    json.permisosdisponibles.push(auxpermiso)
                                }
                            })
                        }
                    })
                    
                    /*modulo.Permisos.forEach((permisomu)=>{
                        let auxpermiso = {
                            "idpermiso": permisomu.idpermiso,
                            "permiso": permisomu.permiso,
                            "check": true
                        }
                        this.checkPermiso(tipomodulo.Idtipomodulo,modulo.Idmodulo,permisomu.idpermiso, true)
                        json.permisosdisponibles.push(auxpermiso)
                    })*/
                    modulosparamodal.push(json)
                })
        })
        await this.setState({ listamodulosseleccionado: modulosparamodal, loadingspinners: false })
    }
    
    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    verModalDetalles = (ban) => {

        if (ban.opcion == 4) { //Nuevo
          this.setState({ titulomodal: 'Accesos disponibles', accionmodal: 4 })
          this.setState({ modaldetalles: true });
        }
      }

    cerrarModal = async (ban) => {
        if (ban == 0 || ban == undefined) {
            this.setState({ modaldetalles: !this.state.modaldetalles })
        }
        else {
            var modulosseleccionados = []
            var moduloagregado = false;

            //se agrega el tipo o modulo a la modal de accesos del usuario
            if(ban.length>0){
                ban.forEach((idmoduloselect)=>{
                    var moduloagregado = false;
                    //se valida que en la modal accesos no tenga ya el tipo y el modulo que se selecciono
                    this.state.listamodulosseleccionado.forEach((modulo)=>{
                        if(modulo.idmodulo==idmoduloselect){
                            moduloagregado=true;
                        }
                    })

                    //se agrega el modulo
                    if(!moduloagregado){
                        this.state.listamodulos.modulos.forEach((modulo)=>{
                            if(modulo.idmodulo==idmoduloselect){
                                modulo.permisosdisponibles.map((item)=>{item.check=false})
                                modulosseleccionados.push(modulo)
                            }
                        })
                    }
                })
                // si ya habian seleccionados o opciones en la modal, se agregan los nuevos seleccionados
                var concatenarModulos = this.state.listamodulosseleccionado.concat(modulosseleccionados)
                                
                await this.setState({ listamodulosseleccionado: concatenarModulos })
            }
            this.setState({ modaldetalles: !this.state.modaldetalles })
        }
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        this.setState({ vermsgerrortipo: false})

        if (this.state.listapermisosguardar.length < 1) {
            this.setState({ msgerrortipo: "Seleccione algun permiso" })
            this.setState({ vermsgerrortipo: true })
        }else{
            this.setLoadingSpinner(true);
            let json = {
                "idtipousuario": this.state.txtidtipousuario,
                "modulos": []
            }
            //listapermisosguardar = [[idtipomodulo, idmodulo, idpermiso]]
            this.state.listamodulos.modulos.forEach((modulo) => {
                var moduloselect = this.state.listapermisosguardar.filter((item) => item[1]==modulo.idmodulo);
                if(moduloselect.length>0){
                    let moduloadd = {
                        "idmodulo": modulo.idmodulo,
                        "permisos": []
                    }
                    moduloselect.forEach((permisos) =>{
                        let permiso = {
                            "idpermiso": permisos[2]
                        }
                        moduloadd.permisos.push(permiso)
                    })
                    json.modulos.push(moduloadd)
                }
            })

            var resp = await guardarAccesosTipoUsuario(json);

            if(resp.code == 0){
                this.showModal('1')
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }
    }

    checkPermiso = async (idtipomodulo, idmodulo, idpermiso, check, pos, permiso) => {
        
        var combinacion = [];
        combinacion.push(idtipomodulo)
        combinacion.push(idmodulo)
        combinacion.push(idpermiso)

        if(pos!=undefined){
            this.setState(this.state.listamodulosseleccionado[pos].permisosdisponibles.map((modulopd) => {
                if(permiso=="ESCRITURA" && check){
                    //si se marca escritura, marcamos lectura
                    if(modulopd.permiso=="LECTURA" || modulopd.permiso=="ESCRITURA"){
                        modulopd.check=check;
                        //como se marcara el check de lectura en automatica, invocamos de nuevo la funcion como si hubieran seleccionado el check de lectura
                        if(modulopd.permiso=="LECTURA"){
                            this.checkPermiso(idtipomodulo, idmodulo, modulopd.idpermiso, check, undefined, modulopd.permiso)
                        }
                    }
                } else if(permiso=="ESCRITURA" && !check){
                    //si se desmarca escritura, solo se desmarca sin desmarcar otro check
                    if(modulopd.idpermiso==idpermiso){
                        modulopd.check=check;
                    }
                } else if(permiso=="LECTURA" && check){
                    //este caso es cuando escritura no esta marcado, solo se marca lectura
                    if(modulopd.idpermiso==idpermiso){
                        modulopd.check=check;
                    }
                }  else if(permiso=="LECTURA" && !check){
                    if(modulopd.idpermiso==idpermiso){
                        //si no esta marcado el check de escritura entonces se puede desmarcar lectura
                        if(this.state.listamodulosseleccionado[pos].permisosdisponibles.filter((item)=>(item.permiso=="ESCRITURA" && item.check)).length<1){
                            modulopd.check=check;
                        }
                    }
                } 
            }))
        }
        

        if(check){
            if(this.state.listapermisosguardar.filter((item) => ((item[0]==combinacion[0]) && (item[1]==combinacion[1]) && (item[2]==combinacion[2]))).length<1){
                await this.state.listapermisosguardar.push(combinacion)
            }
        }else{
            var aux = this.state.listapermisosguardar.filter((item) => !((item[0]==combinacion[0]) && (item[1]==combinacion[1]) && (item[2]==combinacion[2])));
            await this.setState({listapermisosguardar:aux})
        }
    }

    eliminarCheck = async (modulo, idtipomodulo, idmodulo) => {
        //Se elimina de los elementos listos para guardar
        var aux = this.state.listapermisosguardar.filter((item) => !((item[0]==idtipomodulo) && (item[1]==idmodulo)));
        await this.setState({listapermisosguardar:aux})

        //se elimina de la interfaz esos elementos
        var aux2 = this.state.listamodulosseleccionado.filter((item) => !((item.idmodulo==idmodulo) && (item.idtipomodulo==idtipomodulo)));
        await this.setState({listamodulosseleccionado:aux2})
    }

    eliminarTipo = async (tipo) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listapermisosguardar.filter((item) => !(item[0]==tipo));
        await this.setState({listapermisosguardar:aux})

        //se elimina de la interfaz todo el grupo que pertenezcan al tipo de modulo seleccionado
        var aux = this.state.listamodulosseleccionado.filter((item) => !((item.idtipomodulo==tipo)));
        await this.setState({listamodulosseleccionado:aux})
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                    <ModalFormularioTipoUsuariosNuevoAccesos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal}
                            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado}
                            listatipomodulos={this.state.listatipomodulos} listatipopermisos={this.state.listatipopermisos} listamodulos={this.state.listamodulos}/>
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo+' '+this.state.txttipousuario}</label>

                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col containerSubTabla paddingBottomEncabezados'>
                                    </div>

                                    <div className='col containerSubTabla paddingBottomEncabezados'>
                                        <button className='btnNuevo letraNormal btnNuevoAccesos'
                                        style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                                        onClick={this.verModalDetalles.bind(this, { opcion: "4" })}>+ Agregar</button>
                                    </div>
                                </div>
                                

                                <div className="container-fluid containerTabla"> {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                        <div className="container-fluid">
                                            <div className="containerSubTabla2">
                                                <Table>
                                                    {this.state.listatipomodulos.map((i, index) => (
                                                        <Table>
                                                            {/* ////////////////////   Titulos dinamicos   ////////////////////////////////////// */}
                                                            <thead style={{ verticalAlign: "bottom" }}>
                                                                
                                                                {<>{
                                                                    this.state.listamodulosseleccionado.filter((item) => item.idtipomodulo==i.idtipomodulo).length > 0 ? 
                                                                    <tr>
                                                                        <th key={"titulo"+index} className="lblEncabezadosTabla letraTitulos" style={{ color: this.state.colorencabezadostabla }}>
                                                                            {i.tipo}
                                                                        </th>
                                                                        {this.state.listatipopermisos.map((permiso, index2) => (
                                                                            <th key={"per" + (index+index2)} className="lblEncabezadosTabla letraTitulos" style={{ color: this.state.colorencabezadostabla }}>
                                                                                {permiso.permiso}
                                                                            </th>
                                                                        ))}
                                                                        <th key={"delete"+index} className="letraNormal > lblSubtablaBotones" >
                                                                            <button style={{ border: 0}} className='divBotonesTrash'><BsTrash className="icoBotonesTrash" onClick={() => this.eliminarTipo(i.idtipomodulo)}/></button>
                                                                        </th>
                                                                    </tr>
                                                                    : null
                                                                }</>}
                                                                
                                                            </thead>

                                                            {<>{this.state.listamodulosseleccionado.map((moduloselect, indexs)=>(
                                                            <>
                                                            {
                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    {moduloselect.idtipomodulo == i.idtipomodulo ?
                                                                        <tr key={"tabla" + (index+indexs)}>
                                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                {moduloselect.modulo}
                                                                            </td>
                                                                            {<>{this.state.listatipopermisos.map((permiso, index3)=>(
                                                                                <>
                                                                                    {moduloselect.permisosdisponibles.map((perdis) => (
                                                                                        permiso.permiso == perdis.permiso ? 
                                                                                            
                                                                                                <td key={"checktabla" + (index+indexs)} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                                    <div className="modalDetallesCheck" >
                                                                                                    <input type="checkbox" checked={perdis.check} onClick={(event) => this.checkPermiso(moduloselect.idtipomodulo,moduloselect.idmodulo, perdis.idpermiso, event.target.checked, indexs, perdis.permiso)}/>
                                                                                                    </div>
                                                                                                </td>

                                                                                        :   null
                                                                                    ))}
                                                                                    {
                                                                                        moduloselect.permisosdisponibles.filter((pd) => (pd.idpermiso==permiso.idpermiso)).length < 1 ? <td></td> : null
                                                                                    }
                                                                                </>
                                                                            ))}</>}
                                                                            <td className="letraNormal > lblSubtablaBotones" >
                                                                                <button style={{ border: 0}} className='divBotonesTrash' onClick={() => this.eliminarCheck(moduloselect.modulo, moduloselect.idtipomodulo, moduloselect.idmodulo)}><BsTrash className="icoBotonesTrash" /></button>
                                                                            </td>
                                                                        </tr>
                                                                    : null}
                                                                </tbody>
                                                            }
                                                            </>
                                                            ))}</>}         
                                                        </Table>
                                                    ))}
                                                </Table>
                                            </div>
                                        </div>
                                </div> {/* ////////////////////   Termina Tabla   ////////////////////////////////////// */}
                                {this.state.vermsgerrortipo ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipo}</p> : null}
                            </div>
                        </div>

                        {this.state.accion == 3 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}