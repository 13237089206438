import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './VentanaProveedor.css';
import { BiEdit } from "react-icons/bi";
import {
  estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo,
  estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla
} from '../../Services/PaletaDeColores';
import ModalFormularioInformacionProveedores from '../../Complements/Modales/ModalFormularioVentanaProveedor/ModalFormularioInformacionProveedores';
import ModalAutorizacion from '../../Complements/Modales/ModalAutorizacion/ModalAutorizacion';
import TablaContenidoUnico from './TablaContenidoUnico';
import TablaListado from './TablaListado';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {
  getEstatus, getComboTipoProveedor, getListaCatalogoProveedorInformacion,
  getListaCatalogoTipoDocumento, getListaCatalogoDocumentos
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';

const tablaeliminar = ["contacto", "dirección", "archivo"];

export default class VentanaProveedores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

      tablainfogeneral: {
        encabezados: [
          "Clave",
          "Nombre completo",
          "Tipo proveedor",
          "Estatus",
          "Comentario"],
        dimensiones: [
          "15%",
          "30%",
          "15%",
          "15%",
          "25%"
        ],
        mindimensiones: [
          "150px",
          "150px",
          "150px",
          "150px",
          "150px"
        ],
        titulo: "Información general",
        elementos: {}
      },
      tabladatosfiscales: {
        encabezados: [[
          "Razón social",
          "RFC",
          "Régimen fiscal",
          "Nombre(s)",
          "Apellidos"
        ], [
          "Código Postal",
          "Tipo vialidad",
          "Nombre vialidad",
          "No. exterior",
          "No. interior",
          "Colonia",
          "Localidad"
        ], [
          "Municipio",
          "Entidad federativa",
          "Entre calle",
          "Y calle"
        ]],
        dimensiones: [[
          "45%",
          "25%",
          "30%",
          "20%",
          "25%"
        ], [
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%"
        ], [
          "25%",
          "25%",
          "25%",
          "25%"
        ]],
        mindimensiones: [[
          "300px",
          "300px",
          "300px",
          "150px",
          "150px"
        ], [
          "120px",
          "120px",
          "120px",
          "120px",
          "120px",
          "120px",
          "120px"
        ], [
          "200px",
          "200px",
          "200px",
          "200px"
        ]],
        elementos: [],
        titulo: "Datos fiscales",
      },
      tablacontactos: {
        encabezados: [
          "Nombre",
          "Puesto",
          "Teléfono",
          "E-mail",
          "Principal",
          "Comentario",
          ""
        ],
        dimensiones: [
          "20%",
          "16%",
          "12%",
          "16%",
          "5%",
          "20%",
          "11%"
        ],
        mindimensiones: [
          "120px",
          "120px",
          "120px",
          "120px",
          "90px",
          "160px",
          "150px"
        ],
        titulo: "Contactos",
        elementos: []
      },
      tabladirecciones: {
        encabezados: [
          "Dirección",
          "Principal",
          "Referencia",
          ""
        ],
        dimensiones: [
          "56%",
          "5%",
          "28%",
          "10%"
        ],
        mindimensiones: [
          "220px",
          "90px",
          "260px",
          "100px"
        ],
        titulo: "Direcciones",
        elementos: []
      },
      tabladocumentos: {
        encabezados: [
          "Documento",
          "Tipo",
          "Archivo",
          "Descripción",
          "Creado",
          ""
        ],
        dimensiones: [
          "14%",
          "14%",
          "20%",
          "20%",
          "20%",
          "12%"
        ],
        mindimensiones: [
          "140px",
          "130px",
          "180px",
          "200px",
          "200px",
          "150px"
        ],
        titulo: "Documentos",
        elementos: []
      },
      arraytipos: [],
      //arrayfamiliadocumentos: [],
      arraytipodocumentos: [],
      arraylistacatalogodocumentos: [],

      estatus: [],
      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      redireccionando: false,
      modalautorizacion: false,
      modalautorizaciontitulo: "",

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

      btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
      btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
      btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    ///////////////// Se recupera y asigna el estatus /////////////////
    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }
    this.setState({ estatus: estatus.data });

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idcp = await localStorage.getItem('dato')
    if (idcp != null) {
      var respci = await getListaCatalogoProveedorInformacion({
        "idcatalogo_proveedores": parseInt(idcp, 10)
      });
      if (this.manejadorLlamadasApis(respci)) {
        return;
      }

      if (respci.code == 200) {
        await toast.error(respci.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var tablainfogeneral = this.state.tablainfogeneral;
      tablainfogeneral.elementos = respci.data ? respci.data : {};

      ///////////////// Se asignan datos de tabla Datos Fiscales
      var tabladatosfiscales = this.state.tabladatosfiscales;
      tabladatosfiscales.elementos = tablainfogeneral.elementos.datos_fiscales;

      ///////////////// Se asignan datos de tabla Contactos
      var tablacontactos = this.state.tablacontactos;
      tablacontactos.elementos = tablainfogeneral.elementos.contactos;

      ///////////////// Se asignan datos de tabla Direcciones
      var tabladirecciones = this.state.tabladirecciones;
      tabladirecciones.elementos = tablainfogeneral.elementos.direcciones;

      ///////////////// Se asignan datos de tabla Documentos
      var tabladocumentos = this.state.tabladocumentos;
      tabladocumentos.elementos = tablainfogeneral.elementos.documentos ? tablainfogeneral.elementos.documentos : [];

      this.setState({
        tablainfogeneral: tablainfogeneral,
        tabladatosfiscales: tabladatosfiscales,
        tablacontactos: tablacontactos,
        tabladirecciones: tabladirecciones,
        tabladocumentos: tabladocumentos
      });

      var resp = await getComboTipoProveedor();
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      var arraytemp = JSON.parse(JSON.stringify(resp.data));
      this.setState({ arraytipos: arraytemp })

      resp = await getListaCatalogoTipoDocumento();
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      arraytemp = JSON.parse(JSON.stringify(resp.data));
      this.setState({ arraytipodocumentos: arraytemp })

      resp = await getListaCatalogoDocumentos();
      if (this.manejadorLlamadasApis(resp)) {
        return;
      }
      arraytemp = JSON.parse(JSON.stringify(resp.data));
      this.setState({ arraylistacatalogodocumentos: arraytemp })

      this.dimensiones();
      window.addEventListener("resize", this.dimensiones);
      this.setState({ loadingspinners: false })
    }
    else {
      this.redireccionando();
    }
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300 || resp.code == 200) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  obtenerPrincipal = (elementos, clave) => {
    const ids = { contacto: 'idcatalogo_proveedores_contactos', direccion: 'idcatalogo_proveedores_direcciones' }
    let i = 0;
    while (i < elementos.length) {
      if (elementos[i].principal == 1 || elementos[i].principal) {
        return elementos[i][ids[clave]];
      }
      i++;
    }
    return -1;
  }

  obtenerlada = (telefono) => {

    if (!telefono) return
    if (telefono.length <= 10) return

    return telefono.substring(0, telefono.length - 10)

  }

  verModalDetalles = (ban) => {
    var principaltabla;

    if (ban.opcion === 1) { //informacion general
      this.setState({ titulomodal: 'Editar proveedor', accionmodal: 1, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) })
    }
    else if (ban.opcion === 2) { // Datos fiscales
      ban.contenido = ban.contenido ? ban.contenido : {}
      ban.contenido.idcatalogo_proveedores = this.state.tablainfogeneral.elementos.idcatalogo_proveedores
      this.setState({ titulomodal: 'Editar datos fiscales', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) })
    }
    else if (ban.opcion === 3) { //Contactos nuevo
      principaltabla = this.obtenerPrincipal(this.state.tablacontactos.elementos, 'contacto');
      this.setState({ titulomodal: 'Nuevo contacto', accionmodal: 3, itemseleccionado: { principaltabla: principaltabla, idcatalogo_proveedores: this.state.tablainfogeneral.elementos.idcatalogo_proveedores } })
    }
    else if (ban.opcion === 4) { //Contactos editar
      ban.contenido.principaltabla = this.obtenerPrincipal(this.state.tablacontactos.elementos, 'contacto');
      ban.contenido.idcatalogo_proveedores = this.state.tablainfogeneral.elementos.idcatalogo_proveedores
      ban.contenido.idlada = this.obtenerlada(ban.contenido.telefono);
      this.setState({ titulomodal: 'Editar contacto', accionmodal: 4, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) })
    }
    else if (ban.opcion === 5) { //Direcciones nuevo
      principaltabla = this.obtenerPrincipal(this.state.tabladirecciones.elementos, 'direccion');
      this.setState({ titulomodal: 'Nueva dirección', accionmodal: 5, itemseleccionado: { principaltabla: principaltabla, idcatalogo_proveedores: this.state.tablainfogeneral.elementos.idcatalogo_proveedores } })
    }
    else if (ban.opcion === 6) { //Direcciones editar
      ban.contenido.principaltabla = this.obtenerPrincipal(this.state.tabladirecciones.elementos, 'direccion');
      ban.contenido.idcatalogo_proveedores = this.state.tablainfogeneral.elementos.idcatalogo_proveedores
      this.setState({ titulomodal: 'Editar dirección', accionmodal: 6, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) })
    }
    else if (ban.opcion === 7) { //Documentos nuevo
      this.setState({ titulomodal: 'Agregar documento', accionmodal: 7, itemseleccionado: { idcatalogo_proveedores: this.state.tablainfogeneral.elementos.idcatalogo_proveedores } })
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      this.componentDidMount();
    }
  }

  verModalEliminarItem = async (ban) => {
    this.setState({
      modalautorizaciontitulo: `Eliminar ${tablaeliminar[ban.eliminar - 10]}`,
      modalautorizacion: !this.state.modalautorizacion,
      itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)),
      accionmodal: ban.eliminar
    })
  }

  cerrarModalAtutorizacion = (accion) => {
    this.setState({ modalautorizacion: !this.state.modalautorizacion })
    if (accion == 1) {
      this.componentDidMount();
    }
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }


  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalautorizacion == true) ? (
          <ModalAutorizacion item={this.state.itemseleccionado} show={this.state.modalautorizacion} titulomodal={this.state.modalautorizaciontitulo}
            cerrarModalAtutorizacion={this.cerrarModalAtutorizacion} setloadingspinner={this.setLoadingSpinner} accion={this.state.accionmodal} />
        ) : null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioInformacionProveedores titulomodal={this.state.titulomodal} cerrarmodal={this.cerrarModal} estatus={this.state.estatus}
            show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} setloadingspinner={this.setLoadingSpinner}
            arraytipos={JSON.parse(JSON.stringify(this.state.arraytipos))}
            arraytipodocumentos={JSON.parse(JSON.stringify(this.state.arraytipodocumentos))}
            arraylistacatalogodocumentos={JSON.parse(JSON.stringify(this.state.arraylistacatalogodocumentos))} />
        ) : null}

        <Navbar />
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.tablainfogeneral.elementos.nombre}</label>
              </div>
            </div>

          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <div style={{ marginBottom: "15px" }}>
              <div style={{ marginBottom: "2px", paddingLeft: "8px" }}>
                <label className="lblTituloHoja letraTitulos" style={{ color: this.state.colorencabezadostabla }}>{this.state.tablainfogeneral.titulo}</label>
                <BiEdit className="icoEditarTablaModal" onClick={this.verModalDetalles.bind(this, { opcion: 1, contenido: this.state.tablainfogeneral.elementos })} />
              </div>
              <div className="containerSubTabla tablaUnica" style={{ padding: "4px", border: "2px solid #bebdbc" }}>
                <TablaContenidoUnico tabla={this.state.tablainfogeneral} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"infogeneral"} />
              </div>
            </div>
          </div>
        </div>


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo">
            <div style={{ marginBottom: "15px" }}>
              <div style={{ marginBottom: "2px", paddingLeft: "8px" }}>
                <label className="lblTituloHoja letraTitulos" style={{ color: this.state.colorencabezadostabla }}>{this.state.tabladatosfiscales.titulo}</label>
                <BiEdit className="icoEditarTablaModal" onClick={this.verModalDetalles.bind(this, { opcion: 2, contenido: this.state.tabladatosfiscales.elementos[0] })} />
              </div>
              <div className="containerSubTabla tablaUnica" style={{ padding: "4px", border: "2px solid #bebdbc" }}>
                <TablaContenidoUnico tabla={this.state.tabladatosfiscales} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"datosfiscales0"} />
                <TablaContenidoUnico tabla={this.state.tabladatosfiscales} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"datosfiscales1"} />
                <TablaContenidoUnico tabla={this.state.tabladatosfiscales} colores={{
                  colorencabezadostabla: this.state.colorencabezadostabla,
                  fondotitulosencabezados: this.state.fondotitulosencabezados, colorcontenidotabla: this.state.colorcontenidotabla
                }}
                  opcion={"datosfiscales2"} />
              </div>
            </div>
          </div>
        </div>


        <br />

        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <TablaListado vermodaldetalles={this.verModalDetalles} vermodaleliminaritem={this.verModalEliminarItem} opcion={[3, 4]} tabla={this.state.tablacontactos}
          backgroundcolor={this.state.fondotitulosencabezados} editar={"editar"} botonesestilotrash={["eliminar"]} />


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <TablaListado vermodaldetalles={this.verModalDetalles} vermodaleliminaritem={this.verModalEliminarItem} opcion={[5, 6]} tabla={this.state.tabladirecciones}
          backgroundcolor={"#FFF"} editar={"editar"} botonesestilotrash={["eliminar"]} />


        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <TablaListado vermodaldetalles={this.verModalDetalles} vermodaleliminaritem={this.verModalEliminarItem} opcion={[7, -1]} tabla={this.state.tabladocumentos}
          backgroundcolor={this.state.fondotitulosencabezados} botonesestilotrash={["descargar", "ver", "eliminar"]} />

      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoproveedores"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
