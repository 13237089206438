import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { estilosModal } from '../../../Services/PaletaDeColores';
import { getListaTipoCultivo, getComboTipoCultivo } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalTiposCultivo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            tabla: {
                encabezados: [
                    "Tipo cultivo",
                    "Tipo corte"
                ],
                dimensiones: [
                    "30%",
                    "10%"
                ],
                mindimensiones: [
                    "100px",
                    "150px"
                ],
                elementos: [],
            },
            tiposcultivo:[],
            tiposcultivoseleccionados: [],

            estilosmodal: estilosModal()
        }

    }
    async componentDidMount() {

        this.setState({ loadingspinners: true })

        //Esta api se va a ocupar
        var respcultivos2 = await getComboTipoCultivo();
        if (this.manejadorLlamadasApis(respcultivos2)) {
            return;
        }

        //Esta api ya no
        var resptiposcultivos = await getListaTipoCultivo();
        if (this.manejadorLlamadasApis(resptiposcultivos)) {
            return;
        }

        var tabla = this.state.tabla;
        tabla.elementos = resptiposcultivos.data;
        this.setState({ tabla: tabla})
        this.setState({ loadingspinners: false })
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            this.setState({ loadingspinners: false, errordeacceso: true })
            return true
        }
            else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        if (resp.code == 1000) {
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalBuscadorCultivos(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    guardarCambios = async () => {
        // Mandar lista de tipos de cultivo seleccionados.
        this.showModal(this.state.tiposcultivoseleccionados)
    }

    seleccionarTiposCultivo = async (tipo) => {
        const tipos  = this.state.tiposcultivoseleccionados;
        const yaexiste = tipos.some(
            (t) => t.idtipo_cultivo === tipo.idtipo_cultivo
        );
        if(yaexiste){
            const nuevosTipos = tipos.filter(
                (c) => c.idtipo_cultivo !== tipo.idtipo_cultivo
            );
            this.setState({ tiposcultivoseleccionados: nuevosTipos,confirmarenvio: true });
        }
        else{
            const nuevosTipos = [...tipos, tipo];
            this.setState({tiposcultivoseleccionados:nuevosTipos,confirmarenvio: true})
        }
    }

    render() {
        return (
            <>
                {/*///////// Sección de spinner y mensajes toast ////////*/}
                {(this.state.loadingspinners == true) ?
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                :null}
                <ToastContainer />

                <Modal
                    isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">Tipos de cultivos</label>
                        <br />
                        <br />

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                {/* ////////////////////   Sección de tabla   ////////////////////////////////////// */}
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="tablaMaxHeight">

                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {this.state.tabla.elementos.length != 0 ?
                                                        this.state.tabla.elementos.map((tipo, index) => (
                                                            <tr key={"tabla" + index}
                                                                onClick={() => this.seleccionarTiposCultivo(tipo)}
                                                                className={
                                                                    this.state.tiposcultivoseleccionados.some((cultivo) => cultivo.idtipo_cultivo === tipo.idtipo_cultivo)
                                                                        ? "trFondoActivoGreen"
                                                                        : null
                                                                    }
                                                            >
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {tipo.tipo}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {tipo.tipo_corte}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    :
                                                        <tr>
                                                            "No hay tipo de cultivos"
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        {/*/////////////// Sección de botones ///////////////*/}
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

