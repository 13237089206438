import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalPropuestaAnual.css";
import { estiloBtnActualizar,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasConEspacios,validarLetrasNumerosLongitud, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaZonas } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import ModalActividadesDisponibles from './ModalActividadesDisponibles';
import ModalConfirmacion from './ModalConfirmacion';


export default class ModalAgregarActividades extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            titulomodalconfirmacion: "Eliminar actividad",
            mostrarmodalbuscaract: false,
            mostrarmodalconfirmacion: false,
            mensajeconfirmacion: "",
            mensajepreguntaconfirmacion: "¿Eliminar de todos modos?",
            labelbotonconfirmacion: "Eliminar",
            txtbuscador:"",
            estilosmodal: estilosModal(),
            filrotipoactividad: -1,
            listatipoactividad: this.props.listatipoactividad,
            listaactividades: this.props.listaactividades,
            listaactividadesfiltradas: this.props.listaactividadesagregadas,
            listaactividadesagregadas: this.props.listaactividadesagregadas,

            actividadaeliminar: undefined,
            tabla: {
                encabezados: [
                    "Actividad",
                    "Tipo",
                    "",
                ],
                dimensiones: [
                    "20%",
                    "15%",
                    "5%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "20px",
                ],
                elementos: [],
            },
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            btnbordereditar2: "2px solid #0061fe",
        }

    }
    async componentDidMount() {

    

    }

    filtrarTipoActividad = async (itemfiltrado) => {
        var data = JSON.parse(JSON.stringify(this.state.listaactividadesagregadas))
        await this.setState({ 
            filrotipoactividad: itemfiltrado
        })
      
        var filtrofinal = [];
    
        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        if(itemfiltrado != -1){
      
          listafiltrotipo = data.filter((item) => item.idtipoactividad == itemfiltrado)
        }
    
        filtrofinal = listafiltrotipo;
    
        if(this.state.txtbuscador.length>2){
            filtrofinal = listafiltrotipo.filter((item) => item.actividad.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listaactividadesfiltradas: filtrofinal
        })
      
      }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data = JSON.parse(JSON.stringify(this.state.listaactividadesagregadas))

        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        
        if(this.state.filrotipoactividad != -1){
            listafiltrotipo = data.filter((item) => item.idtipoactividad == this.state.filrotipoactividad)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => item.actividad.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
           await this.setState({ listaactividadesfiltradas: filtros });
        }else if(e.target.value.trim().length < 1){
            this.filtrarTipoActividad(this.state.filrotipoactividad)
        }

    }

    borrarBusqueda = async () => {
       await this.setState({
            txtbuscador: ''
        });
        this.filtrarTipoActividad(this.state.filrotipoactividad)
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if (resp.code== 401) {
          this.setState({ salir: true })
          return true;
        }
        else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if(resp.code == 300){
          if(!this.state.errordeacceso){
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
            
          this.setState({ 
            loadingspinners: false,
            errordeacceso:true })
          return true
        }
        return false
      }

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    cerrarModalAgregarActividades = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    mostrarModalActividadesDisponibles = () => {
        this.setState({ mostrarmodalbuscaract: true });
    }
    cerrarModalActividadesDisponibles = async (listaactividades) => {

        var listaactividadesagregadasaux = []
        var respaldoinfo = this.props.respaldoinfopropuesta;
        listaactividades.forEach(async element => {
         /*   var idactividad_presupuestorancho = -1;
            if(JSON.stringify(respaldoinfo) !== '{}'){
                var index =  respaldoinfo.actividades.findIndex((obj => obj.idactividad == element.idactividad));
                if(index > -1){
                  idactividad_presupuestorancho = respaldoinfo.actividades[index].idactividad_presupuestorancho;
                }
            }
          
            console.log("idactividad_presupuestorancho")
            console.log(idactividad_presupuestorancho)
*/
            var actividad= {
                idactividad: element.idactividad,
                actividad: element.actividad,
                tipoactividad: element.tipoactividad,
                idtipoactividad: element.idtipoactividad,
             //   idactividad_presupuestorancho: idactividad_presupuestorancho != -1 ? idactividad_presupuestorancho : 0,
                tablas:[
                ],
               
            }
            listaactividadesagregadasaux.push(actividad);
        });


        var actividades = this.state.listaactividadesagregadas.concat(listaactividadesagregadasaux);
       await this.setState({ 
            mostrarmodalbuscaract: false,
            listaactividadesagregadas: actividades
        });
        
        if(listaactividades.length > 0){
            setTimeout(() => {
                this.filtrarTipoActividad(this.state.filrotipoactividad)
            }, 200);
        }
        
    }

    guardarActividades = async () => {
      
        
        await this.showModal(this.state.listaactividadesagregadas)
    
    }

    mostrarModalConfirmacion = async (item) => {
        await this.setState({
            mostrarmodalconfirmacion: true,
            actividadaeliminar: item,
            mensajeconfirmacion: "Actividad " + item.actividad + " está en uso."
        })
    }

    cerrarModalConfirmacion = async (accion) => {
        if(accion == 1){
           await this.eliminarActividad();
        }
        await this.setState({
            mostrarmodalconfirmacion: false
        })
    }

    eliminarActividad = async () => {
        var listaactividadesagregadasaux = this.state.listaactividadesagregadas.filter(item => item.idactividad != this.state.actividadaeliminar.idactividad);
      
        await this.setState({
            listaactividadesagregadas: listaactividadesagregadasaux
        })
        setTimeout(() => {
            this.filtrarTipoActividad(this.state.filrotipoactividad)
        }, 200);
        
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}
                {(this.state.mostrarmodalbuscaract ) ? (
                <ModalActividadesDisponibles titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalActividadesDisponibles}
                    show={this.state.mostrarmodalbuscaract}   
                    listaactividades={JSON.parse(JSON.stringify(this.state.listaactividades))}
                    listatipoactividad={JSON.parse(JSON.stringify(this.state.listatipoactividad))}
                    listaactividadesagregadas={JSON.parse(JSON.stringify(this.state.listaactividadesagregadas))}
                    
                    />
                ) : null}
                {this.state.mostrarmodalconfirmacion ? (
                <ModalConfirmacion titulomodal={this.state.titulomodalconfirmacion}  mensaje={this.state.mensajeconfirmacion} 
                    cerrarModal={this.cerrarModalConfirmacion}
                    show={this.state.mostrarmodalconfirmacion}
                    mensajepreguntaconfirmacion={this.state.mensajepreguntaconfirmacion}
                    labelbotonconfirmacion={this.state.labelbotonconfirmacion}
                    />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal(this.props.listaactividadesagregadas)} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla paddingBottomEncabezados sinPaddingLeftRight'>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos lblSelect' style={{ color: this.state.colorlupa }}>Tipo actividad</label>
                                            <div className="selectBox selectOption">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtrarTipoActividad(event.target.value)}>
                                                    {this.state.filrotipoactividad == -1 ?
                                                        <option selected="true" value={-1}>Todos</option> : <option value={-1}>Todos</option>}
                                                    {this.state.listatipoactividad.map((i, index) => (
                                                        <option value={i.idtipoactividad} key={"tipo_" + index}  >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla paddingBottomEncabezados sinPaddingLeftRight'>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.colorbordertipo }}
                                                placeholder="Buscar actividad"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>
                                    
                                </div>
                                <br />
                                <div className='row '>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla  '>
                                        <label className='letraTitulos'>Selecciona las actividades: </label>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla '>
                                        <button className='btnActualizar letraNormal btnFloatRight' 
                                         onClick={this.mostrarModalActividadesDisponibles}
                                        style={{ color: this.state.fondobtnactualizar, backgroundColor: this.state.colorbtnactualizar, border: this.state.fondobtnactualizar }}
                                        >Buscar</button>
                                    </div>
                                </div>


                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="container-fluid tablaMaxHeight">
                                            <Table >
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tabla" + index} className={" letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                        
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaactividadesfiltradas.map((item, index) => (
                                                            <tr key={"tabla" + index} >
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.actividad}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tipoactividad}
                                                                </td>
                                                                <td key={"col-"+index+"-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <div className="modalDetallesCheck" >
                                                                    <button className='btnEliminarFila' 
                                                                    onClick={() => this.mostrarModalConfirmacion(item)} 
                                                                    ><IoMdClose className="icoBotones" /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal(this.props.listaactividadesagregadas)}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarActividades()} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

