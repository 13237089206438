import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';

export default class ModalConfirmarRestablecerContraseña extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      nombres:props.nombres,
      idusuario:props.idusuario,
      estilosmodal: estilosModal(),
      colortitulo: estiloFiltroEncabezados().colortitulos,
    }
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarmodal(accion,this.state.idusuario);
    }, 200);
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div className="divTituloModal">
              <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
            </div>

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                <p className='lblSubTitulosModal ' >
                  ¿Está seguro que desea restablecer la contraseña del usuario {this.state.nombres}?
                </p>
              </div>
            </div>

            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.showModal(1)} >Continuar</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}
