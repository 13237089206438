import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalPropuestaAnual.css";
import { estiloColorError,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { getMeses } from '../../../Services/Api';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import ModalConfirmacion from './ModalConfirmacion';
import cloneDeep from 'lodash/cloneDeep';

export default class ModalSeleccionMeses extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            txtbuscador:"",
            estilosmodal: estilosModal(),
            listamesesagregados: this.props.tablameses.length > 0 ? cloneDeep(this.props.tablameses[0].meses) : [],
            listameses:  [],
            listamesesagg:  [],
            actualizardatosmes: false,
            meses:[],
            titulomodalconfirmacion: "Guardar cambios",
            mostrarmodalconfirmacion: false,
            mensajeconfirmacion: "Se perderá la información de los meses que se hayan desmarcado.",
            mensajepreguntaconfirmacion: "",
            labelbotonconfirmacion: "Guardar",

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }
    async componentDidMount() {
        this.setState({ loadingspinners: true })
        if(!this.state.actualizardatosmes){
           await this.actualizarDatosMesesAgregados()
        }

        var respmeses = await getMeses();
        if ( this.manejadorLlamadasApis (respmeses) ) {
          return;
        }
        this.setState({ loadingspinners: false })
       await this.setState({ meses: respmeses.data })
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if (resp.code== 401) {
          this.setState({ salir: true })
          return true;
        }
        else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if(resp.code == 300 || resp.code == 200){
          if(!this.state.errordeacceso){
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
            
          this.setState({ 
            loadingspinners: false,
            errordeacceso:true })
          return true
        }
        return false
      }

    showModal = (mensaje,accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje,accion);
        }, 200);

    }

    actualizarDatosMesesAgregados = () =>{
        var meses = this.props.tablameses.length > 0 ? this.props.tablameses[0].meses: [];
        var idlistamesesaux = [];
        var idlistamesesaux2 = [];
        meses.forEach(mes => {
            idlistamesesaux.push(mes.idmes);
            idlistamesesaux2.push(mes);
        });
        
        this.setState({ 
            listameses: idlistamesesaux, 
            actualizardatosmes: true,
            listamesesagg:idlistamesesaux2
        });
    }

    continuarProceso = async () => {

        var listamesesaux = this.state.listamesesagregados;
        listamesesaux.sort(function(a,b) {
            return a.idmes - b.idmes;
        });
        this.showModal(listamesesaux,1)
      
    }

    cancelar = () =>{
        this.showModal(this.state.listamesesagg,0)
    }

    seleccionarMes = async (indexitemseleccionado) => {
        let listameses = this.state.meses;
        let listamesesseleccionadasaux = cloneDeep( this.state.listamesesagregados);
       // let listamesesseleccionadasaux = this.state.listamesesagregados;

        var index =  this.state.listamesesagregados.findIndex((obj => obj.idmes == listameses[indexitemseleccionado].id_mes));
        if(index > -1){
            listamesesseleccionadasaux.splice(index,1)
        }else{
            let mes = {
                "idmestablaactividad_presupuestorancho":"0",
                "idmes":listameses[indexitemseleccionado].id_mes,
                "mes":listameses[indexitemseleccionado].mes,
                "presupuesto":0
            }
            listamesesseleccionadasaux.push(mes)

        }

        await this.setState({
            listamesesagregados: listamesesseleccionadasaux,
        })
      
    }

    validarMeses = async () => {
        var meseliminado = false;
        //Verifica si se removió algún mes de los que ya se habían agregado
        for (let index = 0; index < this.state.listameses.length; index++) {
            const idmes = this.state.listameses[index];

            var indexmes =  this.state.listamesesagregados.findIndex((obj => obj.idmes == idmes));
            if(indexmes < 0){
                meseliminado=true;
                break;
            }
            
        }

        if(meseliminado){
            await this.setState({
                mostrarmodalconfirmacion:true,
            })
        }else{
            this.continuarProceso();
        }
    }

    cerrarModalConfirmacion = async (accion) => {
        await this.setState({
            mostrarmodalconfirmacion: false
        })
        if(accion == 1){
           this.continuarProceso();
        }
       
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

            {this.state.mostrarmodalconfirmacion ? (
                <ModalConfirmacion titulomodal={this.state.titulomodalconfirmacion}  mensaje={this.state.mensajeconfirmacion} 
                    cerrarModal={this.cerrarModalConfirmacion}
                    show={this.state.mostrarmodalconfirmacion}
                    mensajepreguntaconfirmacion={this.state.mensajepreguntaconfirmacion}
                    labelbotonconfirmacion={this.state.labelbotonconfirmacion}
                    />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.cancelar()} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    {this.state.meses.map((itemmes, index) => (
                                        <div key={index} className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 paddingBottomEncabezados'>
                                        <Button
                                            className={ this.state.listamesesagregados.find(mes => mes.idmes == itemmes.id_mes) != undefined ? "btnMesActivo btnMes": "btnMesInactivo btnMes"}
                                            onClick={(e) => this.seleccionarMes(index)}
                                        >
                                            {itemmes.mes}
                                        </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    
                        <>
                            <br />
                            <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                            </div>
                            <br />
                            <div className="divBtnContenedorModal" >
                                <Button className="btnCancelModal" onClick={() => this.cancelar()}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.validarMeses()}  disabled={!this.state.confirmarenvio}>Aceptar</Button>
                            </div>
                        </>
                      

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

