import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalCotizaciones.css";
import { estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';
import { BsCheckLg, BsXLg } from "react-icons/bs";

export default class ModalRegresarProductoCotizacion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,

            tabla: {
                encabezados: [
                    "Producto",
                    "Cantidad",
                    "Pendientes",
                    "Vendidos"
                ],
                encabezados2: [
                    "Producto",
                    "Cantidad",
                    "Pendientes"
                ],
                encabezados3: [
                    "Proveedor",
                    "Cantidad",
                    "Estatus",
                    "Remover"
                ],
                dimensiones: [
                    "15%",
                    "10%",
                    "10%",
                    "10%"
                ],
                mindimensiones: [
                    "160px",
                    "80px",
                    "80px",
                    "80px"
                ],
                elementos: [],
            },

            productorestaurar: props.item.prodrestaurar,
            proveedores: props.item.proveedores,
            vendidos: props.item.vendidos,
            disponibles: props.item.pendientes,
            listaproductossustitutos: props.item.productos,


            estilosmodal: estilosModal(),
        }
    }

    async componentDidMount() {
    }

    showModal = (accion) => {
        if (accion == "10") {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(this.props.item);
            }, 200);
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }

    renderTablaProveedor = (proveedor) => {
        return (
            <>
                <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                        <th className="lblEncabezadosTabla letraTitulos" 
                            style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla, 
                            textAlign: 'left', paddingBottom: '8px', paddingLeft: '8px' }}>
                            {"Proveedor"}
                        </th>
                        <th className="lblEncabezadosTabla letraTitulos" 
                            style={{minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla,
                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                            {"Cantidad"}
                        </th>
                        <th className="lblEncabezadosTabla letraTitulos" 
                            style={{minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla,
                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                            {"Estaus"}
                        </th>
                        <th className="lblEncabezadosTabla letraTitulos" 
                            style={{minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla,
                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                            {"Remover"}
                        </th>
                    </tr>
                </thead>
      

                <tbody style={{ verticalAlign: "bottom" }}>
                    <tr>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            {proveedor.proveedor}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {proveedor.cantidad}
                        </td>
                        <td className={proveedor.idestatuscotizacion == 5 ? "letrasRojas" : proveedor.idestatuscotizacion == 4 || proveedor.idestatuscotizacion == 3 ? "letrasVerdes" : proveedor.idestatuscotizacion == 2 || proveedor.idestatuscotizacion == 1 || proveedor.idestatuscotizacion == 0 ? "letrasAzules" : "letraNormal lblSubtabla"} 
                            style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {proveedor.estatuscotizacion}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                            {proveedor.idestatuscotizacion != this.props.statusNoborrar ? <BsCheckLg className="icoBotones sinPendientes" /> : <BsXLg className="icoBotones noborrar" />}
                        </td>
                    </tr>
                </tbody>
            </>
          );
    }

    render() {
        return (

            <>

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <label className='lblTexto letraNormal'>{this.state.accion==3 ? "Se reiniciará el siguiente producto:" : this.state.accion==4 ? "Se rehabilitará el siguiente producto:" : null}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="">
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                    textAlign: (index > 0 ) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px' }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        <tr>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla}}>
                                                                {this.state.productorestaurar.producto}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {this.state.productorestaurar.cantidad}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {this.state.disponibles}
                                                            </td>
                                                            <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                {this.state.vendidos}
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <hr></hr>
                                <br />

                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <label className='lblTexto letraNormal'>{this.state.accion==3 ? "Se quitará el producto de las siguientes cotizaciones: " : this.state.accion==4 ? "Se quitarán los siguientes productos del resumen y de las cotizaciones que los estén usando:" : null}</label>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="">
                                            
                                            {
                                                    this.state.listaproductossustitutos ? this.state.listaproductossustitutos.map((prodsus, indez)=>(
                                                        <div className="">
                                                            <Table>
                                                                {/* ////////////////////   TABLA PROUCTOS SUSTITUTOS   ////////////////////////////////////// */}
                                                                <thead style={{ verticalAlign: "bottom" }}>
                                                                    <tr>
                                                                        <th className="lblEncabezadosTabla letraTitulos" 
                                                                            style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla, 
                                                                            textAlign: 'left', paddingBottom: '8px', paddingLeft: '8px' }}>
                                                                            {"Producto"}
                                                                        </th>
                                                                        <th className="lblEncabezadosTabla letraTitulos" 
                                                                            style={{minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla,
                                                                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                                                                            
                                                                        </th>
                                                                        <th className="lblEncabezadosTabla letraTitulos" 
                                                                            style={{minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla,
                                                                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                                                                            {"Cantidad"}
                                                                        </th>
                                                                        <th className="lblEncabezadosTabla letraTitulos" 
                                                                            style={{minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla,
                                                                            textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                                                                            {"Pendientes"}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                                    <tr style={{ borderBottom: 'hidden' }}>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                            {prodsus.producto}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                            
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                            {prodsus.cantidad}
                                                                        </td>
                                                                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                            {prodsus.pendientes}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>

                                                                <br></br>

                                                                {
                                                                    this.state.proveedores.map((proveedor, index) =>(
                                                                            proveedor.idproducto==prodsus.idproducto ? this.renderTablaProveedor(proveedor, index) : null
                                                                    ))
                                                                }
                                                            </Table>
                                                            <br />
                                                            <hr />
                                                            <br /> 
                                                        </div>
                                                    )) : "No hay productos"
                                                }
                                                                                       
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')}>{this.state.accion==3 ? "Sustituir" : "Restablecer"}</Button>
                                </div>
                            </>
                        }
                    </ModalBody>
                </Modal>
            </>
        )
    }
}