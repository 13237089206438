import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioNuevaRequisicion.css";
import { estilosModal } from '../../../Services/PaletaDeColores';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioZonasDisponibles extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
           
            estilosmodal: estilosModal(),

            listazonasdisponibles: this.props.listazonas,
            listazonasseleccionadas: [],
            listazonasagregadas: this.props.listazonasagregadas,
            listazonasduplicadas: [],

            tabla: {
                encabezados: [
                    "Selecciona una zona"
                ],
                dimensiones: [
                    "15%"
                ],
                mindimensiones: [
                    "100px",
                ],
                elementos: [],
            },
        }

    }
    async componentDidMount() {

    }

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    continuarProceso = async () => {
        if(this.state.listazonasseleccionadas.length > 0){
            var zonarepetida=false;
            var listazonasrepetidas=[];
           
            this.state.listazonasseleccionadas.forEach(element => {
                var itemfound = this.state.listazonasagregadas.find(item => item.idzona == element.idcatalogo_zona);
                if(itemfound != undefined){
                    listazonasrepetidas.push(element)
                    zonarepetida = true; 
                }
            });
            if(!zonarepetida){
                var listazonasseleccionadasaux = []
                this.state.listazonasseleccionadas.forEach(element => {
                    var nuevazona= {
                        idzona: element.idcatalogo_zona,
                        zona: element.zona,
                        eliminar: false,
                        ranchos: []
                    }
                    listazonasseleccionadasaux.push(nuevazona);
                });
                
                this.showModal(listazonasseleccionadasaux)
            }else{
                setTimeout(() => {
                    this.setState({
                        listazonasduplicadas: listazonasrepetidas
                    })
                }, 200);
               
                toast.info("La zonas ya se encuentran agregadas", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
            
        }else{
            toast.info("Favor de seleccionar al menos una zona", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
    }

    seleccionarItem = async (itemseleccionado) => {
        var listazonasseleccionadasaux = this.state.listazonasseleccionadas;
        var itemfound = listazonasseleccionadasaux.find(item => item.idcatalogo_zona == itemseleccionado.idcatalogo_zona);

        if(itemfound == undefined){
            listazonasseleccionadasaux.push(itemseleccionado)
        }else{
            var index = listazonasseleccionadasaux.indexOf(itemseleccionado)
            if(index > -1){
                listazonasseleccionadasaux.splice(index,1)
            }
        }
        await this.setState({
            listazonasseleccionadas: listazonasseleccionadasaux,
            confirmarenvio: true
        })
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal([])} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                        <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="container-fluid tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        <th key={"tabla" + 1} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                                                                Selecciona una zona:
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listazonasdisponibles.map((item, index) => (
                                                            <tr key={"tabla" + index} onClick={() => this.seleccionarItem(item)} 
                                                                className={this.props.listazonasagregadas.find(zona => zona.idzona == item.idcatalogo_zona) != undefined ? "trFondoInactivoGray" :this.state.listazonasseleccionadas.find(selected => selected.idcatalogo_zona == item.idcatalogo_zona) == undefined ? ""
                                                               : "trFondoActivoGreen"}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.zona}
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal([])}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.continuarProceso()}  disabled={!this.state.confirmarenvio}>Continuar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

