import React from 'react'
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalCompraDirecta.css";
import { estiloComboBox, estiloFiltroEncabezados, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { FaCalendar } from 'react-icons/fa';
import ModalFormularioAccionEstatus from '../ModalFormularioRequisicionesGenerales/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasenaGenerica from '../ModalFormularioAccionEstatusContraseñaGenerica/ModalFormularioAccionRstatusContrasenaGenerica';

import {
    getInformacionCompraDirecta, getCambiarEstatusCompraDirecta
} from '../../../Services/Api';
import { formatoNumeroTecla } from '../../../Services/Validaciones';

export default class ModalCompraDirectaVer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,
            hubocambio: false,

            tabla: {
                encabezados:
                    [
                        "Producto general",
                        "Unidad de medida",
                        "Cantidad",
                        "Precio",
                        "Subtotal"
                    ]
                ,
                dimensiones: [
                    "30%",
                    "20%",
                    "10%",
                    "20%",
                    "20%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px"
                ],
                elementos: [],
            },

            // nuevos
            listaproveedores: [],
            proveedorselect: undefined,
            listaproductosalmacen: [],
            loadingspinners: false,

            fechacompra: new Date(),
            fechaentrega: new Date(),
            informacioncompra: undefined,
            password: "",
            modalaccionestatus: false,
            modalaccionestatuscontrasena: false,

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,

            //para tablas
            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortipolbl: estiloComboBox().colorcombolbl,
            banderadidmount: false
        }
    }


    async componentDidMount() {
        this.setState({ loadingspinners: true })
        await this.cargarinformacioncompra(this.props.idcomprabuscar)
        this.setState({ banderadidmount: true, loadingspinners: false })
    }

    async cargarinformacioncompra(idcompra) {

        //obtiene lista de tipo productos
        var respinfoalmacen = await getInformacionCompraDirecta({ idcompra: idcompra });
        if (this.manejadorLlamadasApis(respinfoalmacen)) {
            return;
        }

        var informacion = respinfoalmacen.data;
        let infoproveedor = this.state.listaproveedores.filter((item) => ((item.idcatalogo_proveedores == informacion.idproveedor)))

        let infotabla = []
        infotabla = this.state.tabla;

        this.setState({
            listaproductosalmacen: informacion.productos, informacioncompra: informacion,
            proveedorselect: infoproveedor[0],
            fechacompra: this.formatofechas(informacion.fechacompra),
            fechaentrega: this.formatofechas(informacion.fechaentrega),
            tabla: infotabla,
            loadingspinners: false
        })
    }


    formatofechas(fecha) {
        var cadenas = fecha.split("/");
        var formato = cadenas[1] + "/" + cadenas[0] + "/" + cadenas[2];
        return new Date(formato)
    }


    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 200 || resp.code == 300) {

            this.setState({ loadingspinners: false })
            this.setState({ confirmarenvio: !this.state.confirmarenvio })
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }



    showModal = async (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    sumarPrecios2 = () => {
        var totalaux = 0
        this.state.listaproductosalmacen.map((producto) => {
            var valor = (isNaN(parseFloat(producto.precio))) ? (0) : (parseFloat(producto.precio) * parseInt(producto.cantidad))
            totalaux = totalaux + valor;
        })

        var newTotal = "" + totalaux;
        var auxTotal = newTotal.split('.');
        if (auxTotal.length > 1) {
            newTotal = auxTotal[0].slice(0, 7) + "." + auxTotal[1].slice(0, 2)
        } else {
            newTotal = auxTotal[0].slice(0, 7) + ".00"
        }

        return newTotal;
    }

    async convertirEstatus(elemento) {
        var nuevoidestatuscompra = undefined;
        if (elemento.opcion == 1) {
            nuevoidestatuscompra = 6
        }
        if (elemento.opcion == 2) {
            nuevoidestatuscompra = 7
        }
        var jsonfinal = {
            "idcompra": this.state.informacioncompra.idcompra,
            "idestatuscompra": nuevoidestatuscompra,
            "password": elemento.password,
            "comentario": (elemento.opcion == 1) ? (this.state.motivo_rechazo) : ("")
        }
        let respcambiarestatus = await getCambiarEstatusCompraDirecta(jsonfinal);
        if (this.manejadorLlamadasApis(respcambiarestatus)) {
            return;
        }
        if (respcambiarestatus.code == 0) {
            if(elemento.opcion==1){// rechazo
                toast.success("Compra rechazada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            if(elemento.opcion == 2){ // aprobado
                toast.success("Compra aprobada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }           
            this.componentDidMount();
        }
    }


    verModalFormularioAccionEstatus = (ban) => {
        const aux = this.state.informacioncompra
        var item = { idcatalogo_requisicion: aux.idcompra }
        if (ban.id == 1) {
            this.setState({ titulomodal: 'Rechazar compra directa', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
        }
        else if (ban.id == 2) {
            this.setState({ titulomodal: 'Aprobar compra directa', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
        }
    }


    cerrarModalFormularioAccionEstatus = (opciones) => {
        this.setState({
            modalaccionestatus: false,
            motivo_rechazo: (opciones != 0) ? (opciones.item.txtmotivo) : ("")
        });
        if (opciones != 0) {
            this.verModalFormularioAccionEstatusContrasena({ id: 1, accion: opciones.accion, item: opciones.item })
        }
    }

    verModalFormularioAccionEstatusContrasena = (ban) => {
        if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
            this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
        }
        else if (ban.id == 2 && ban.accion == 2) {// 1: Rechazar requisición, 2: Aprobar requisición
            const aux = this.state.informacioncompra
            var item = { idcatalogo_requisicion: aux.idcompra, idestatusrequisicion: 3 /* id de autorizar */ }
            this.setState({
                titulomodal: 'Aprobar acción',
                accionmodal: ban.id,
                modalaccionestatuscontrasena: true,
                itemseleccionado: item
            });
        }
    }

    cerrarModalFormularioAccionEstatusContrasena = async (opciones) => {
        this.setState({ modalaccionestatuscontrasena: false })
        if (opciones.accion == 1) {
            if (opciones.id == 1) {
                //guardar rechazo
                await this.convertirEstatus({ opcion: 1, password: opciones.password })
            }
            else if (opciones.id == 2) {
                //guardar aprobación
                await this.convertirEstatus({ opcion: 2, password: opciones.password })
            }
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalaccionestatus == true) ? (
                    <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
                        show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
                ) : null}

                {(this.state.modalaccionestatuscontrasena == true) ? (
                    <ModalFormularioAccionEstatusContrasenaGenerica titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
                        show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.props.titulomodalcompradirecta}</label>

                        {/* ////////////////////   Inicio Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}
                        <br />
                        <div className="container-fluid containerTabla">
                            <div className="container-fluid anchoMaximo ">
                                <div className="containerSubTablaAA">

                                    <div className="marginTopSeparator" >
                                        <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                                                            <div className='row flexNoWrap'>

                                                                <div className='col-9 containerSubTablaAA minPaddingTopBottom'>
                                                                    <p className='sinMargen'>
                                                                        {
                                                                            (this.state.banderadidmount == true) ? (
                                                                                <label style={{ minWidth: "120px", }}><b>Proveedor: {this.state.informacioncompra.proveedor}</b></label>
                                                                            ) : (null)
                                                                        }
                                                                        <br /><br />
                                                                        <div style={{ display: "flex" }}>
                                                                            <label style={{ minWidth: "120px" }}>Fecha de compra:</label>
                                                                            {
                                                                                (this.state.banderadidmount == true) ? (
                                                                                    <label style={{ minWidth: "120px" }}>{this.state.informacioncompra.fechacompra}</label>
                                                                                ) : (null)
                                                                            }
                                                                        </div>
                                                                        <div style={{ display: "flex" }}>
                                                                            <label style={{ minWidth: "120px" }}>Fecha de entrega:</label>
                                                                            {
                                                                                (this.state.banderadidmount == true) ? (
                                                                                    <label style={{ minWidth: "120px" }}>{this.state.informacioncompra.fechaentrega}</label>
                                                                                ) : (null)
                                                                            }
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                                <div className='col-3 containerSubTablaAA minPaddingTopBottom'>
                                                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>
                                                                        {(this.state.banderadidmount == true) ? (
                                                                            this.state.informacioncompra.estatuscompra
                                                                        )
                                                                            : null
                                                                        }
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            <div className='row flexNoWrap'>
                                                                <div className='col-12'>
                                                                    <div style={{ display: "flex", width: "100%" }}>
                                                                        {(this.state.banderadidmount && this.state.informacioncompra.idestatuscompra == 6) ? (
                                                                            <div className='col-12  letraNormal containerSubTablaAA minPaddingTopBottom' style={{ color: '#000000', paddinfRight: '8px', paddingLeft: '8px', textAlign: 'left', background: '#f9a9a9', borderRadius: '5px', marginBottom: '12px' }}>
                                                                                <p className='sinMargen'>Motivo rechazo: {this.state.informacioncompra.mensajeautorizacion}</p>
                                                                            </div>
                                                                        ) : (null)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={true} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                    <div className='tablaProductoCotizaciones'>
                                                                        <br />
                                                                        {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                                                        <div className="container-fluid containerTablaAA anchoTablaProducto tablaProductoCotizaciones">
                                                                            <div className="container-fluid">
                                                                                <div className='row'>
                                                                                    <div>

                                                                                        <Table hover>
                                                                                            <thead style={{ verticalAlign: "bottom" }}>
                                                                                                <tr>
                                                                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                                                                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                                                            style={{
                                                                                                                minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla,
                                                                                                                textAlign: (index == 1) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                                                                                            }}>
                                                                                                            {titulo}
                                                                                                        </th>
                                                                                                    ))}
                                                                                                </tr>
                                                                                            </thead>

                                                                                            <tbody style={{ verticalAlign: "bottom" }}>
                                                                                                {this.state.listaproductosalmacen.map((item, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                                                            {item.producto}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {item.unidadmedida}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla txtcentrado" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            {formatoNumeroTecla(item.cantidad, 2)}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            ${formatoNumeroTecla(item.precio, 2)}
                                                                                                        </td>
                                                                                                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                            ${formatoNumeroTecla(item.precio * item.cantidad, 2)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}

                                                                                                <br /><br />

                                                                                                <tr>
                                                                                                    <td className=" txttotal" colSpan={4} style={{ color: this.state.colorcontenidotabla }}>
                                                                                                        {"Total"}
                                                                                                    </td>
                                                                                                    <td className=" txttotal" style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center' }}>
                                                                                                        ${formatoNumeroTecla(this.sumarPrecios2(), 2)}
                                                                                                    </td>

                                                                                                </tr>

                                                                                            </tbody>
                                                                                        </Table>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row'>

                                                                            <br></br>
                                                                            <br></br>
                                                                        </div>
                                                                    </div>
                                                                    <br></br>
                                                                    <br></br>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ////////////////////   Fin Sección Tabla COTIZACIONES TARJETAS   ////////////////////////////////////// */}

                        {this.state.banderadidmount == true && this.state.informacioncompra.permisoautorizacion == 1 && this.state.informacioncompra.idestatuscompra == 5 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.verModalFormularioAccionEstatus({ cambiarestatus: "rechazar", titulo: "Rechazar compra directa", accion: 1, id: 1 })}>Rechazar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.verModalFormularioAccionEstatusContrasena({ cambiarestatus: "aprobar", titulo: "Autorizar compra directa", accion: 2, id: 2 })} >Aprobar</Button>
                                </div>
                            </>
                        ) : null
                        }

                    </ModalBody>
                </Modal>
            </>
        )
    }
}


const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
    >
        <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
    </button>
));