import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioGuardarRequisicion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 3 guardar Nuevo, editar
      titulo: props.titulomodal,
      loadingspinners: false,
      txtpassword: '',
      autorizacion: false,
      vermsgerrorpassword: false,
      msgerror: '',
      msgerrorpassword: "",
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal()
    }
  }
  async componentDidMount() {

  }

  setLoadingSpinner = (bandera) => {
    this.props.activarSpinner(bandera)
  }

  showModal = (datos) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModalGuardar(datos)
    }, 200)
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    this.setState({ vermsgerrorpassword: false })
    let json = {
      "solicitarautorizacion": this.state.autorizacion ? 1 : 0,
      "idestatusrequisicion": this.state.autorizacion ? 2 : 1,
    }
    this.showModal(json)
  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">
              <label className="modalLblTituloS letraTitulos" style={{ color: '#868686' }}>Guardar requisición: </label>

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className="modalDetallesCheck" onClick={() => this.setState({ autorizacion: !this.state.autorizacion })}>
                  <input type="checkbox" checked={this.state.autorizacion} /> Solicitar autorización de documento
                </div>
              </div>
            </div>

            {this.state.accion == 1 ? (
              <>
                <br />
                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                  <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                </div>
                <br />
                <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}