import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';


export default class ModalEliminarCompra extends React.Component {

    constructor(props) {
        super(props)
        console.log("ModalEliminarCompra", props)
        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            cuerpo: props.cuerpomodal,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

        }

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModalConfirmar(accion);
        }, 200);
    }



    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                        </div>



                        <div className="divModalEliminar">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                                <p className='lblSubTitulosModal ' >
                                  {this.props.cuerpo}
                                </p>
                                
                                <p> <b>Requisición</b> {this.props.item.requisicion}</p>
                                <p> <b>Proveedor</b> {this.props.item.proveedor}</p>
                                <p><b>Total a pagar</b> ${parseFloat(this.props.item.total).toFixed(2)}</p>
                                <p><b>Fecha compra</b> {this.props.item.fechacompra}</p>
                                <p><b>Estatus pagos</b> {this.props.item.estatus}</p>
                            </div>
                        </div>

                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.showModal(1)} >Continuar</Button>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }
}
