import React from "react";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { coloresLogin, estiloColorError } from "../../Services/PaletaDeColores";
import SpinnerModal from "../../Complements/Modales/SpinnerModal/SpinnerModal";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import './Login.css';
import { validarPassword, validarUser } from "../../Services/Validaciones";
import { login } from "../../Services/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {encode as base64_encode } from 'base-64';
import ModalRecuperarPassword from "../../Complements/Modales/ModalLogin/ModalRecuperarPassword";

export default class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            txtusuario: "",
            txtpassword: "",
            msgerror: false,
            gopanel: false,
            loadingspiner: false,
            showpassword: false,
            loadingspinners: false,
            vermsgerroruser: false,
            msgerroruser: false,
            vermsgerrorpass: false,
            msgerrorpass: false,
            redireccionando: false,
            logologin: coloresLogin().logologin,
            fondologin: coloresLogin().fondogeneral,
            colortitulos: coloresLogin().colortitulos,
            fondoinputslogin: coloresLogin().fondoinputs,
            colorinputslogin: coloresLogin().colorinputs,
            borderinputslogin: coloresLogin().borderinputslogin,
            btnfondologin: coloresLogin().btnfondologin,
            btncolorlogin: coloresLogin().btncolorlogin,
            olvidadecontrasena: coloresLogin().olvidadecontrasena,
            colormsgerror: estiloColorError().msgerror,
        }
    }

    async componentDidMount() {
        if (await localStorage.getItem('ls') != null || await localStorage.getItem('ls') != undefined) {
            this.setState({ redireccionando: true })
            return;
        }
    }

    revisarEnter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.validarLogin()
        }
    }

    verPassword = () => {
        this.setState({ showpassword: !this.state.showpassword });
    };

    ocultarPassword = (event) => {
        event.preventDefault();
    };

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    validarLogin = async () => {

        this.setState({ vermsgerroruser: false })
        this.setState({ vermsgerrorpass: false })

        let usercorrecto = validarUser(this.state.txtusuario.trim());
        let passwordcorrecto = validarPassword(this.state.txtpassword.trim());
        let validacionescorrectas = true;

        if (usercorrecto.codigo == "invalid") {
            this.setState({ msgerroruser: usercorrecto.mensaje })
            this.setState({ vermsgerroruser: true })
            validacionescorrectas = false
        }
        if (passwordcorrecto.codigo == "invalid") {
            this.setState({ msgerrorpass: passwordcorrecto.mensaje })
            this.setState({ vermsgerrorpass: true })
            validacionescorrectas = false
        }

        if (validacionescorrectas) {

            this.setState({ loadingspinners: true });
            var resp = await login(this.state.txtusuario.trim(), this.state.txtpassword.trim());

            this.setState({ loadingspinners: false });
            if (resp.code == 200) {
                toast.warn(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp == -1) {
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                this.setState({ loadingspinners: false })
                return;
            }
            let encoded = base64_encode(resp.data.token + ':');
            await localStorage.setItem("ls", [resp.data.email, resp.data.apellido, resp.data.nombre, 'Basic ' + encoded]);
            this.setState({ redireccionando: true })
        }

    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 1) {
          this.setState({ titulomodal: 'Recuperar contraseña', accionmodal: 1 })
        }
        this.setState({ modaldetalles: true });
    }

    setLoadingSpinner = (bandera) => {
        this.setState({ loadingspinners: bandera });
    }

    cerrarModal = (accion) => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (accion == 1) {
          this.componentDidMount();
        }
    }

    render() {
        return (

            <>
                <ToastContainer />
                {(this.state.redireccionando) ? (<AccionesLogin bandera={'gotipoclientes'} />) : null}

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                        <ModalRecuperarPassword 
                            titulomodal={this.state.titulomodal} 
                            cerrarModal={this.cerrarModal}
                            show={this.state.modaldetalles} 
                            accion={this.state.accionmodal} 
                            setLoadingSpinner={this.setLoadingSpinner} />
                        ) : null}

                <div style={{ backgroundColor: this.state.fondologin }} className='contenedorLogin'>

                    <main className='centrarDiv'>
                        <img className='logoLogin' src={this.state.logologin} alt='logologin' />

                        <div className="divFormulario" >
                            <div>
                                <label style={{ color: this.state.colortitulos }} className="lblTituloLogin letratitulos" htmlFor="username">Usuario:</label><br />
                                <Input style={{ backgroundColor: this.state.fondoinputslogin, color: this.state.colorinputslogin, border: this.state.borderinputslogin }}
                                    className="inputsLogin letraNormal" type={"text"}
                                    onChange={this.escribir("txtusuario")} value={this.state.txtusuario} />
                                {this.state.vermsgerroruser ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerroruser}</p> : null}

                            </div>

                            <br />
                            <div >
                                <label style={{ color: this.state.colortitulos }} className="lblTituloLogin letratitulos" htmlFor="password">Contraseña:</label><br />
                                <Input style={{ backgroundColor: this.state.fondoinputslogin, color: this.state.colorinputslogin, border: this.state.borderinputslogin }}
                                    className="inputsLogin letraNormal" type={this.state.showpassword ? "text" : "password"}
                                    onChange={this.escribir("txtpassword")} onKeyDown={(e) => this.revisarEnter(e)}
                                    value={this.state.txtpassword} endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.verPassword}
                                                onMouseDown={this.ocultarPassword}>
                                                {this.state.showpassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    } />
                                {this.state.vermsgerrorpass ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpass}</p> : null}
                            </div>

                            <button onClick={this.validarLogin} className="btn btn-success btnLogin letratitulos"
                                style={{ color: this.state.btncolorlogin, backgroundColor: this.state.btnfondologin }} type="button">
                                INICIA SESIÓN
                            </button>

                            <spam style={{ color: this.state.olvidadecontrasena }} className='spamContrasena letraNormal' onClick={this.verModalDetalles.bind(this, { opcion: "1"})}>¿Olvidaste tu contraseña?</spam>
                        </div>
                    </main>
                </div>
            </>
        )
    }
}

function AccionesLogin({ bandera }) {
    const history = useHistory();
    const location = useLocation();

    if (bandera == 'gotipoclientes') {
        history.push(location.pathname);
        return (<Redirect from='/' to={"/catalogotipoclientes"} />)
    }

}

