import React from "react";
import { CgClose } from 'react-icons/cg';
import ReactTooltip from "react-tooltip";

export default class CustomTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltip: null,
            listamensajes: props.mensajes,
            id: props.id
        }
    }

    cerrarTooltip = () => {
        this.tooltip.tooltipRef = null;
        ReactTooltip.hide();
    }

    render() {
        return (
            <ReactTooltip
                id={this.state.id}
                clickable={true}
                tooltipRadius='16'
                effect="solid"
                place="top"
                className='tooltipClickeable'
                arrowColor='#d3e3fd'
                ref={elemento => this.tooltip = elemento}
            >
                <div>
                    <CgClose
                        style={{ float: "right" }}
                        onClick={this.cerrarTooltip}
                    />
                </div>
                <div style={{
                    minWidth: '200px',
                    maxWidth: '220px',
                    paddingRight: '20px'
                }}>
                    {console.log(this.state.listamensajes)/*this.state.listamensajes && this.state.listamensajes.map((item) => (
                        <label>{item}</label>
                    ))*/}
                </div>
            </ReactTooltip>
        )
    };
}
