import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioBuscarAgroquimicos extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      txtbuscador: "",
      tabla: {
        encabezados: [
          "Nombre comercial",
          "Familia",
          "Tipo producto",
          "Unidad de medida",
          //"Descripción"
        ],
        dimensiones: [
          "40%",
          "20%",
          "20%",
          "20%",
          //"20%"
        ],
        mindimensiones: [
          "200px",
          "100px",
          "100px",
          "60px",
          //"150px",
        ],
        elementos: [],
      },

      todoslosproductosalmacen: this.props.todoslosproductosalmacen,
      listaproductosalmacen: this.props.listaproductosalmacen,
      productoseleccionado: undefined,
      idproductoseleccionado: 0,
      productosseleccionados: {},
      //arrayidproductosseleccionados: 0,
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      listafamiliaproductos: [],
      listatipoproductos: [],
      listatipoproductosfiltrados: [],
      listaproductosfiltrados: [],
    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })

    var tabla = this.state.tabla;
    if (this.state.todoslosproductosalmacen) {
      tabla.elementos = this.props.respproductos;
    } else {
      tabla.elementos = this.filtrarProductosdeAlmacen(this.props.respproductos)
    }

    //revisar si hay productos seleccionados
    let productosseleccionados = this.state.productosseleccionados;
    this.props.productosseleccionados.forEach(el => {
      productosseleccionados[el.idcatalogoproducto] = el
    });

    this.setState({ productosseleccionados: productosseleccionados, confirmarenvio: true })
    this.setState({
      txtbuscador: '',
      tabla: tabla,
      listafamiliaproductos: this.props.respfamiliaproductos,
      listatipoproductos: this.props.resptipoproductos,
      listatipoproductosfiltrados: this.props.resptipoproductos,
      listaproductosfiltrados: tabla.elementos
    })
    this.setState({ loadingspinners: false })

  }

  filtrarProductosdeAlmacen(respproductos) {
    var productosdealmacen = [];

    this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
      var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
      if (producto != undefined) {
        productosdealmacen = productosdealmacen.concat(producto)
      }
    });

    return productosdealmacen;
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {

    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  guardarCambios = async () => {
    // Mandar datos del item de producto seleccionado
    let listaproductos = [];
    this.state.tabla.elementos.forEach(el => {
      if (this.state.productosseleccionados[el.idcatalogoproducto] != undefined) {
        listaproductos.push(el)
      }
    });

    this.showModal(listaproductos)
  }

  saveInputLocacion = async (e) => {
    this.setState({ txtbuscador: e.target.value });
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;
    var listafiltrofamilia = [];
    var listafiltrotipo = [];
    listafiltrofamilia = data;

    if (this.state.filtrofamilia != "TodasLasFamilias") {
      listafiltrofamilia = listafiltrofamilia.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
    }

    if (this.state.filtrotipoproducto != "TodasLosProductos") {
      listafiltrotipo = data.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
    } else {
      listafiltrotipo = listafiltrofamilia;
    }

    if (e.target.value.trim().length > 2) {
      const filtros = listafiltrotipo.filter((item) =>
        item.nombrecomercial.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length < 1) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ listaproductosfiltrados: filtros });
    } else if (e.target.value.trim().length < 1) {
      this.setState({ listaproductosfiltrados: listafiltrotipo });
    }
  }

  filtro = (array) => {
    this.setState({
      listaproductosfiltrados: []
    });

    setTimeout(() => {
      this.setState({
        listaproductosfiltrados: array
      });
    }, 0);

  }

  filtrarFamilia = async (idfamilia) => {
    
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      filtrofamilia: idfamilia
    })

    var filtrofinal = [];
    var listafiltrofamilia = [];
    var listafiltrotipo = [];
    var cambiodefiltrofamiia = false;

    listafiltrofamilia = data;
    if (idfamilia != "TodasLasFamilias") {

      var listatipoproductosfiltrados = this.state.listatipoproductos.filter((item) => item.idfamilia_productos == idfamilia)
      cambiodefiltrofamiia = true;
      await this.setState({
        listatipoproductosfiltrados: listatipoproductosfiltrados,
        filtrotipoproducto: "TodasLosProductos"
      })
      listafiltrofamilia = data.filter((item) => item.idfamiliaproducto == idfamilia)
    } else {
      await this.setState({
        listatipoproductosfiltrados: this.state.listatipoproductos,
        filtrotipoproducto: "TodasLosProductos"
      })
    }

    if (this.state.filtrotipoproducto != "TodasLosProductos" && !cambiodefiltrofamiia) {
      listafiltrotipo = listafiltrofamilia.filter((item) => item.idtipoproducto == this.state.filtrotipoproducto)
    } else { listafiltrotipo = listafiltrofamilia; }

    filtrofinal = listafiltrotipo;

    if (this.state.txtbuscador.length > 2) {
      filtrofinal = listafiltrotipo.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    if (cambiodefiltrofamiia) {
      this.filtro(filtrofinal)
    } else {
      this.filtro(filtrofinal)
    }

  }

  filtrarTipoProducto = async (itemfiltrado) => {
    var data1 = JSON.parse(JSON.stringify(this.state.tabla))
    var data = data1.elementos;

    await this.setState({
      filtrotipoproducto: itemfiltrado
    })

    var filtrofinal = [];

    var listafiltrofamilia = [];
    var listafiltrotipo = [];

    listafiltrotipo = data;
    if (itemfiltrado != "TodasLosProductos") {

      listafiltrotipo = data.filter((item) => item.idtipoproducto == itemfiltrado)
    }

    if (this.state.filtrofamilia != "TodasLasFamilias") {
      listafiltrofamilia = listafiltrotipo.filter((item) => item.idfamiliaproducto == this.state.filtrofamilia)
    } else { listafiltrofamilia = listafiltrotipo; }

    filtrofinal = listafiltrofamilia;

    if (this.state.txtbuscador.length > 2) {
      filtrofinal = listafiltrofamilia.filter((item) => item.nombrecomercial.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase())
        || item.clave.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
    }

    if (filtrofinal.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }
    await this.setState({
      listaproductosfiltrados: filtrofinal
    })

  }

  borrarBusqueda = () => {
    this.setState({
      txtbuscador: '',
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      listaproductosfiltrados: this.state.tabla.elementos
    });
  }

  seleccionarProducto = async (item) => {
    
    var productosseleccionados = this.state.productosseleccionados
    var productoseleccionado = productosseleccionados[item.idcatalogoproducto]
    if (productoseleccionado === undefined) {
      productosseleccionados[item.idcatalogoproducto] = item
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: true,
      })
    } else {
      delete productosseleccionados[item.idcatalogoproducto]
      var confirmarenvio = Object.keys(productosseleccionados).length != 0
      this.setState({
        productosseleccionados: productosseleccionados, confirmarenvio: confirmarenvio,
      })
    }

  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Buscar productos</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Familia</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarFamilia(event.target.value)}>
                          {this.state.filtrofamilia == "TodasLasFamilias" ?
                            <option selected="true" value='TodasLasFamilias'>Todos</option> : <option value='TodasLasFamilias'>Todos</option>}
                          {this.state.listafamiliaproductos.map((i, index) => (
                            <option value={i.idfamilia_productos} key={"tipo_" + index}  >{i.familia} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                      <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                      <div className="selectBox ">
                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                          onChange={(event) => this.filtrarTipoProducto(event.target.value)}>
                          {this.state.filtrotipoproducto == "TodasLosProductos" ?
                            <option selected="true" value='TodasLosProductos'>Todos</option> : <option value='TodasLosProductos'>Todos</option>}
                          {this.state.listatipoproductosfiltrados.map((i, index) => (
                            <option value={i.idtipo_producto} key={"tipo_" + index}  >{i.tipo} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                    <div className='txtBuscadorCN' >
                      <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                      <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                        className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                        placeholder="Buscar clave/nombre"></input>
                      {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                        onClick={this.borrarBusqueda} />) : null}
                    </div>
                  </div>
                </div>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla tablaMaxHeight">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                          {
                            this.state.listaproductosfiltrados.map((item, index) => (
                              <tr key={"tabla" + index}
                                onClick={() => this.seleccionarProducto(item)}

                                className={this.state.productosseleccionados[item.idcatalogoproducto] != undefined ? "trFondoActivoGreen" : ""} >

                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, wordBreak:"break-word" }}>
                                  {item.nombrecomercial}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.familiaproducto}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                  {item.tipoproducto}
                                </td>
                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: "center" }}>
                                  {item.claveunidadmedida}
                                </td>

                              </tr>
                            ))

                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Continuar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

