import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { CgClose } from 'react-icons/cg'
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default class ModalSeleccionAnio extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show: props.show,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,

      listaanios: new Array(100),
      listaaniosactual: new Array(2),
      anioseleccionado: props.anioseleccionado,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,
    }

  }
  async componentDidMount() {
    var listaanios = this.state.listaanios
    var listaaniosactual = this.state.listaaniosactual
    for (let i = 0; i < listaanios.length; i++) {
      listaanios[i] = 1900 + i
    }

    var anioactual = this.state.anioseleccionado//startdate.getFullYear()
    for (let i = 0; i < 20; i++) {
      listaaniosactual[i] = anioactual - 1 + i
    }
    this.setState({ listaanios: listaanios, listaaniosactual: listaaniosactual })
  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  guardarCambios = async () => {
    this.showModal({ anioseleccionado: this.state.anioseleccionado, accion: '1' })
  }

  desplazaranios = (cantidad) => {
    if(this.state.listaaniosactual[0]=="2023" && cantidad==-10){
      console.log("rechazar acción")
    }
    else{
    var listaaniosactual = this.state.listaaniosactual
    listaaniosactual = listaaniosactual.map((item) => item + cantidad)
    this.setState({ listaaniosactual: listaaniosactual })
    }
  }

  render() {
    return (

      <>
        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: '0' })} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, minWidth: '380px' }}>

                <div className="divBtnContenedorModal" style={{ marginBottom: '10px' }}>
                  <div style={{ margin: 'auto' }}>
                    <button className='lblTexto letraTitulos labelFechaa'
                      onClick={() => this.desplazaranios(-10)}
                      style={{ color: this.state.colortipolbl, borderRadius: '4px', border: 'none', minWidth: '64px', floata: 'right' }}>{'<'}
                    </button>
                    <label className='lblSubTitulosModal letraTitulos' style={{ margin: '0px 12px' }}>
                      {this.state.listaaniosactual[0] + ' - ' + this.state.listaaniosactual.slice(-1)}
                    </label>
                    <button className='lblTexto letraTitulos labelFechaa'
                      onClick={() => this.desplazaranios(10)}
                      style={{ color: this.state.colortipolbl, borderRadius: '4px', border: 'none', minWidth: '64px' }}>{'>'}
                    </button>
                  </div>
                </div>

                <div className='row align-items-end'>
                  {this.state.listaaniosactual.map((item) => (
                    <div className='col-auto' style={{ width: '20%', minWidtha: '80px', padding: '5px 5px' }}>
                      <button className='lblTexto letraTitulos labelFechaa'
                        onClick={() => this.setState({ anioseleccionado: item })}
                        style={{
                          color: this.state.colortipolbl, borderRadius: '4px',
                          border: 'solid 1px blue', minWidth: '64px', backgroundColor: (this.state.anioseleccionado == item) ? '#d2fed2' : ''
                        }}>
                        {item}
                      </button>
                    </div>
                  ))}
                </div>

              </div>
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <br />
                <div className="divBtnContenedorModal" >
                  <Button className="btnCancelModal" onClick={() => this.showModal({ accion: '0' })}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}>Aceptar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}
