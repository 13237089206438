import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoBitacoraCosecha.css';
import { BiEdit } from "react-icons/bi";
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaZonas, getListaRanchosDisponibles, catalogoBitacoraCosecha } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ModalFormularioBitacorasCosecha from '../../Complements/Modales/ModalFormularioBitacoraCosechas/ModalFormularioBitacorasCosecha';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { BsFillCalendarFill } from "react-icons/bs";

export default class CatalogoBitacoraCosecha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtfecha: '',
      fecha: new Date(),
      listapaginada: [],
      loadingspinners: false,
      errordeacceso: false,

      listazonas: [],
      listaranchos: [],
      estatus: [],
      tabla: {
        encabezados: [
          "Fecha cosecha",
          "Zona",
          "Rancho",
          "# Rejas",
          "",
          ""],
        dimensiones: [
          "20%",
          "20%",
          "22%",
          "8%",
          "15%",
          "15%",
        ],
        mindimensiones: [
          "170px",
          "180px",
          "190px",
          "80px",
          "100px",
          "100px",
        ],
        elementos: []
      },

      altura: window.innerHeight,
      anchura: window.innerWidth,

      //modal
      accionmodal: 1,
      titulomodal: '',
      redireccionando: false,
      modaldetalles: false,
      itemseleccionado: null,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    //obtiene catalogo de zonas
    var respzonas = await getListaZonas();
    if (this.manejadorLlamadasApis(respzonas)) {
      return;
    }

    //obtiene catalogo de ranchos
    var respranchos = await getListaRanchosDisponibles();
    if (this.manejadorLlamadasApis(respranchos)) {
      return;
    }

    await this.setState({
      estatus: estatus.data,
      listazonas: respzonas.data,
      listaranchos: respranchos.data
    })

    this.dimensiones();
    this.cambiarFecha({ llave: "fecha", valor: this.state.fecha })
    this.cambiarContenido(1);
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.cambiarFecha({ llave: "fecha", valor: new Date() })
    this.setState({ errordeacceso: false })
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ errordeacceso: false })
    this.setState({ loadingspinners: bandera });
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  verModalDetalles = (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo bitácora de cosecha', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar bitácora de cosecha', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Bitácora creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Bitácora editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
  }

  cambiarContenido = (pagenumber) => {
    this.setState({
      activepage: pagenumber,
      listapaginada: []
    });

    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos)
      });
    }, 0);

  }

  redireccionar = (idcosecha) => {
    localStorage.setItem("bitacoracosecha", JSON.stringify(idcosecha.contenido));
    this.setState({ redireccionando: true })
  }

  cambiarFecha = (item) => {
    this.setState({ [item.llave]: item.valor })

    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()
    this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })

    this.consultarCatalogoBitacora(['txt' + item.llave], year + '-' + month + '-' + day)

  }

  consultarCatalogoBitacora = async (llave, valor) => {
    var formatofechaini = "";
    var fechainiaux = 'txtfecha' == llave ? valor : this.state.txtfecha

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${year}-${month}-${day}`
    }

    var requestbody = {
      fecha: formatofechaini,
    }

    this.setState({ loadingspinners: true })
    var resp = await catalogoBitacoraCosecha(requestbody);

    if (this.manejadorLlamadasApis(resp)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({
      tabla: tabla
    })
    this.cambiarContenido(1);
    this.setState({ loadingspinners: false })
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
            <>
              <SpinnerModal tipo="full" show={true} />
              <Acciones bandera={'salir'} />
            </>
        :null}

        <ToastContainer />
        {(this.state.redireccionando) ?
          <Acciones bandera={'detalles'} />
        :null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ):null}

        {(this.state.modaldetalles == true) ? (
          <ModalFormularioBitacorasCosecha titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            estatus={this.state.estatus}
            show={this.state.modaldetalles}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            setLoadingSpinner={this.setLoadingSpinner} />
        ):null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end'>

              {/*///////// Título ////////*/}
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Bitácora de cosecha</label>
              </div>

              {/*///////// Filtro por fecha ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>
                  <div className='subcontenedorIconoCalendarioCompras'>
                    <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                  </div>
                  <div className='row'>
                    <div className=' col-sm-4 col-md-4'>
                      <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha:</label>
                    </div>
                    <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                      <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                        <DatePicker
                          selected={this.state.fecha}
                          onChange={(date) => this.cambiarFecha({ llave: "fecha", valor: date })}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          customInput={
                            <DateButton />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*///////// Botón refrescar lista ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/*///////// Botón para agregar nuevo registro ////////*/}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo, border: this.state.borderbtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/*///////// Sección de tabla ////////*/}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.listapaginada.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                        {item.fecha}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                        {item.zona}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla }}>
                        {item.rancho}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], color: this.state.colorencabezadostabla, textAlign: 'right' }}>
                        {this.format_numero(item.rejas)}
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4] }}>
                        <button className='btnEditar' onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })} style={{
                          backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                        }}><BiEdit className="icoBotones" /> Editar</button>
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5] }} >
                        <button className='btnEditar' onClick={this.redireccionar.bind(this, { contenido: item })}
                          style={{backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                        >
                          Ver detalles
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        {/*///////// Sección de paginación ////////*/}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.tabla.elementos}
            pagerangedisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

{/*///////// Función de redireccionamiento ////////*/}
function Acciones({ bandera }) {
  const history = useHistory();
  const location = useLocation();

  if (bandera == 'detalles') {
    history.push(location.pathname);
    return (<Redirect from='/' to={"/detallesbitacorcosecha"} />)
  }

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }

}

{/*///////// Botón de calendario ////////*/}
const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));