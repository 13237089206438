import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import { FaCalendar } from "react-icons/fa";
import { BsFillCalendarFill } from "react-icons/bs";
import { estiloColorError, estiloComboBox, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
//import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
//import { getComboFamiliaProducto, getComboTipoProducto } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import DatePicker from "react-datepicker";

import { getListaValesCombustible } from '../../../Services/Api';

export default class ModalBuscarValesCombustible extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarvales: false,
      // accion: props.accion, // 1 Nuevo, 2 editar
      //modalnuevoproducto: false,
      txtbuscador: "",
      tabla: {
        encabezados: [
          "Fecha solicitud",
          "Folio",
          "Proveedor",
          "Combustible",
          "Litros",
          "Solicitante",
          "Destino",
          "Concepto",
        ],
        dimensiones: [
          "15%",
          "15%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
        ],
        mindimensiones: [
          "100px",
          "100px",
          "100px",
          "100px",
          "100px",
          "100px",
          "100px",
          "100px",
        ],
        elementos: [],
      },
      //listaunidadmedida: props.listaunidadmedida,
      //todoslosproductosalmacen: this.props.todoslosproductosalmacen,
      //listaproductosalmacen: this.props.listaproductosalmacen,
      //productoseleccionado: undefined,
      // idproductoseleccionado: 0,
      filtrofamilia: "TodasLasFamilias",
      filtrotipoproducto: "TodasLosProductos",
      idestatus: 1,
      msgerror: '',
      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      //listafamiliaproductos: this.props.respfamiliaproductos,
      //listatipoproductos: this.props.resptipoproductos,
      //listatipoproductosfiltrados: this.props.resptipoproductos,
      listavalesfiltrados: [],
      listavalesmarcados: props.listavalesseleccionados,


      txtfechacompraini: "",
      txtfechacomprafin: "",
      fechacompraini: new Date(),
      fechacomprafin: new Date(),

    }

  }

  async componentDidMount() {
    var fechaactualFin = new Date();
    const fechaa = fechaactualFin.getDate();
    fechaactualFin.setDate(fechaa);
    const defaultValue = fechaactualFin.toLocaleDateString('en-CA');

    var fechaactualIni = new Date();
    fechaactualIni.setDate(fechaactualIni.getDate() - 7);
    const defaultValueFechaInicial = fechaactualIni.toLocaleDateString('en-CA');

    await this.setState({
      txtbuscador: '',
      txtfechacompraini: defaultValueFechaInicial,
      txtfechacomprafin: defaultValue,
      fechacompraini: fechaactualIni,
    });

    setTimeout(() => {
      this.consultarCatalogoVales();
      this.setState({ loadingspinners: false })
    }, 300);
  }

  consultarCatalogoVales = async (llave, valor) => {
    var formatofechaini = "";
    var formatofechafin = "";
    var fechainiaux = 'txtfechacompraini' == llave ? valor : this.state.txtfechacompraini
    var fechafinaux = 'txtfechacomprafin' == llave ? valor : this.state.txtfechacomprafin

    if (fechainiaux.length > 0) {
      var [year, month, day] = fechainiaux.split("-");
      formatofechaini = `${day}/${month}/${year}`
    }

    if (fechafinaux.length > 0) {
      var [year, month, day] = fechafinaux.split("-");
      formatofechafin = `${day}/${month}/${year}`
    }

    var requestbody = {
      fechainicio: formatofechaini,
      fechafin: formatofechafin,
    }

    var fechasvalidas = await this.validarFechasDeCompra(formatofechaini, formatofechafin)
    if (fechasvalidas == 1) {
      this.setState({ loadingspinners: true })
      var catalogovales = await getListaValesCombustible(requestbody);
      if (this.manejadorLlamadasApis(catalogovales)) {
        this.setState({ loadingspinners: false })
        return;
      }





      let filtro1 = []; // solo vales movidos y sin facturar


      catalogovales.data.forEach(item => { // filtrar los vales que ya fueron novidos y todavía no se han facturado
        if (parseInt(item.enalmacen) == 1 && parseInt(item.facturado) == 0) {
          filtro1.push(item);
        }
      });

      let filtro2 = []; //solo vales del mismo proveedor y con ticket adjunto

      filtro1.forEach(item => {
        if (parseInt(item.idproeedor) == parseInt(this.props.idproveedor) && item.imagenticket != "") {

          /*  let existecombustible=  this.props.listacombustiblesprecios.find(
              comb => parseInt(comb.idtipocombustible) == parseInt(item.idcombustible) && comb.activo==true);
           
           if(existecombustible != undefined)*/
          filtro2.push(item);
        }
      });



      var tabla = this.state.tabla;
      tabla.elementos = filtro2;

      await this.setState({
        txtbuscador: '',
        tabla: tabla,
        listavalesfiltrados: filtro2,
      })
      this.setState({ loadingspinners: false })
    }
  }




  validarFechasDeCompra = async (formatofechaini, formatofechafin) => {
    var fechainicio = new Date();
    var fechafin = new Date();
    var fechainiciovalido = false;
    var fechafinvalido = false;
    if (formatofechaini.length > 0) {
      fechainiciovalido = true;
      var fechacompraini = formatofechaini.split("/");
      fechainicio.setFullYear(fechacompraini[2], fechacompraini[1], fechacompraini[0]);
    }

    if (formatofechafin.length > 0) {
      fechafinvalido = true;
      var fechacomprafin = formatofechafin.split("/");
      fechafin.setFullYear(fechacomprafin[2], fechacomprafin[1], fechacomprafin[0]);
    }

    if (fechainiciovalido && fechafinvalido) {
      if (fechainicio <= fechafin) {
        return 1;
      } else {
        toast.info("Fecha inicio no puede ser mayor que fecha fin", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        var tabla = this.state.tabla;
        tabla.elementos = [];

        await this.setState({
          txtbuscador: '',
          tabla: tabla
        })
        return 2;
      }
    }
    return 0;
  }



  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }




  seleccionarVale = async (item) => {

    let marcados = this.state.listavalesmarcados;
    let encontrado = this.state.listavalesmarcados.find(vale => parseInt(vale.idvale) == parseInt(item.idvale));
    if (encontrado == undefined) {
      marcados.push(item);
    }
    else {
      marcados = [];
      this.state.listavalesmarcados.forEach(elemento => {
        if (parseInt(elemento.idvale) != parseInt(item.idvale)) {
          marcados.push(elemento)
        }
      });

    }

    await this.setState({
      listavalesmarcados: marcados,
      confirmarvales: true,
    })
  }



  showModal = (accion) => {



    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }

  guardarCambios = async () => {
    // Mandar datos de los vales seleccionados
    this.showModal(this.state.listavalesmarcados)

  }

  cambiarFecha = async (item) => {
    this.setState({ [item.llave]: item.valor })



    let day = `0${item.valor.getDate()}`.slice(-2)
    let month = `0${item.valor.getMonth() + 1}`.slice(-2)
    let year = item.valor.getFullYear()

    await this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })


    await this.consultarCatalogoVales();
    // console.log("conjunto: ", day + '/' + month + '/' + year)
    // this.setState({ [item.llave]:  day + '/' + month + '/' + year })
  }



  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />


        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Buscar vales de combustible</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                <div className='row'>

                  <div className='contenedorFechaCompras' style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}>

                    <div className='subcontenedorIconoCalendarioCompras'>
                      <BsFillCalendarFill className='iconoCalendario' style={{ color: this.state.colortipolbl, stroke: this.state.colortipolbl }} />
                    </div>
                    <div className='row'>
                      <div className=' col-sm-4 col-md-4'>
                        <label className='lblTexto letraTitulos labelFecha' style={{ color: this.state.colortipolbl, }}>Fecha vales de:</label>
                      </div>
                      <div className='row col-sm-8 col-md-8' style={{ marginLeft: '10px' }}>
                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                          <DatePicker
                            selected={this.state.fechacompraini}
                            onChange={(date) => this.cambiarFecha({ llave: "fechacompraini", valor: date })}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={
                              <DateButton />
                            }
                          />
                        </div>
                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, }}>a:</label>
                        </div>

                        <div className='col-auto' style={{ margin: '0px', padding: '0px' }}>
                          <DatePicker
                            selected={this.state.fechacomprafin}
                            onChange={(date) => this.cambiarFecha({ llave: "fechacomprafin", valor: date })}
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            customInput={
                              <DateButton />
                            }
                          />
                        </div>
                      </div>
                    </div>


                  </div>




                </div>


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla tablaMaxHeight">

                      {(this.state.listavalesfiltrados.length>0 ) ? (
                        <>
                         <label> Vales disponibles:</label>
                        <Table hover>
                          <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                              {this.state.tabla.encabezados.map((titulo, index) => (
                                <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                  style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                  {titulo}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody style={{ verticalAlign: "bottom" }}>
                            {
                              this.state.listavalesfiltrados.map((item, index) => (
                                <tr key={"tabla" + index}
                                  onClick={() => this.seleccionarVale(item)}
                                  className={
                                    this.state.listavalesmarcados.find(vale => vale.idvale == item.idvale) != undefined ?
                                      "trFondoActivoGreen" : null /*"trFondoInactivoGray"*/}>
                                  <td className="letraNormal lblSubtabla"
                                    style={{ color: this.state.colorcontenidotabla }}>
                                    {item.fechasolicitud} {/*JSON.stringify(this.props.listaproductosagregados)} - {item.idcatalogo_producto_general*/}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.folio}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.proveedor}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.combustible}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.litros}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.usuario}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {(item.tipodestino == 1) ? (item.area) : (null)}
                                    {(item.tipodestino == 2) ? (item.rancho + ", " + item.zona) : (null)}
                                    {(item.tipodestino == 3) ? (item.maquinaria) : (null)}
                                  </td>
                                  <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                    {item.concepto}
                                  </td>

                                </tr>
                              ))

                            }

                          </tbody>
                        </Table>
                        </>
                       

                      ) : ("No hay vales disponibles para el proveedor "+this.props.nombreproveedor)}

                    </div>
                  </div>
                </div>
              </div>

              {this.state.accion == 2 ? (
                <>
                  <BsQuestionSquare className='icoQuestion' data-tip data-for="registerTip" />
                  <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                    Creado: {this.props.item.fechacreacion}
                    <br />
                    Actualizado: {this.props.item.fechaactualizacion}
                  </ReactTooltip>
                </>
              ) : null}
            </div>


            <br />
            <div className="divBtnContenedor" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarvales} >GUARDAR</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}





const DateButton = React.forwardRef(({ value, onClick }, ref) => (
  <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
    style={{ maxWidth: '125px', backgroundColor: 'white', padding: '0px 0px 0px 0px' }}
  >
    <label style={{ minWidth: '80px' }}>{value}</label> <FaCalendar className="icoBotones" />
  </button>
));
