import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalFormularioResumen.css";
import { estiloColorError, estiloComboBox, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { validarPasswordEliminar } from '../../../Services/Validaciones';
import { getCambiarEstatusAutorizacionCotizaciones, autorizarProductos, cambiarStatusCotizacionProductoGeneral } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';

export default class ModalFormularioAutorizacion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      confirmarenvio: false,
      accion: props.accion ? props.accion : 1, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      item: props.item,
      idcotizacion: props.idcotizacion,

      // Rechazar requisición
      txtmotivo: '',
      vermsgerrortxtmotivo: false,
      msgerrortxtmotivo: '',

      // Modales de aprobación
      txtpassword: "",
      vermsgerrortxtpassword: false,
      msgerrortxtpassword: "",

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colortitulo: estiloFiltroEncabezados().colortitulos,
    }

  }

  showModal = (opciones) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opciones);
    }, 200);
  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  escribir = (prop) => (event) => {
    if ('txtclabe' == prop) {
      this.setState({ [prop]: event.target.value.toUpperCase() });
    } else {
      this.setState({ [prop]: event.target.value });
    }
  }

  cambiarTipo = (item) => {
    this.setState({ [item.tipo]: item.valor })
  }

  guardarCambios = async () => {

    let validacionescorrectas = true
    this.setState({ vermsgerrortxtpassword: false })

    let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
    if (txtpassword.codigo == "invalid") {
      this.setState({ msgerrortxtpassword: txtpassword.mensaje })
      this.setState({ vermsgerrortxtpassword: true })
      validacionescorrectas = false;
    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true)

      var resp = {};
      var json = {};
      if (this.state.accion == 1) { //rechazar cambio de productos
        json = {
          "idcotizacion": this.state.idcotizacion,
          "estatusautorizarproductos": 2,
          "password": this.state.txtpassword,
          "mensaje_resumen": this.state.item.txtmotivo
        }
        resp = await autorizarProductos(json);
      }
     
      else if (this.state.accion == 3) {
        json = {
          "idcotizacion_proveedor": this.state.item.idcotizacion_proveedor,
          "idestatuscotizacion": 5,// Estatus de requisición rechazada
          "password": this.state.txtpassword,
          "comentario": this.state.item.comentario
        }
        resp = await getCambiarEstatusAutorizacionCotizaciones(json);
      }
      else if (this.state.accion == 4) {
        json = {
          "idcotizacion_proveedor": this.state.item.idcotizacion_proveedor,
          "idestatuscotizacion": 3,// Estatus de requisición aprobada
          "password": this.state.txtpassword,
          "comentario": ""
        }
        resp = await getCambiarEstatusAutorizacionCotizaciones(json);
      }else if (this.state.accion == 6) {
        json = {
          "idcotizacion_proveedor": this.state.item.idcotizacion_proveedor,
          "idestatuscotizacion": 5,// Estatus de cotización? rechazada
          "password": this.state.txtpassword,
          "comentario": this.state.item.comentario
        }
        resp = await cambiarStatusCotizacionProductoGeneral(json);
        //this.showModal({ accion: this.state.accion, id: this.state.accion })
      }else if (this.state.accion == 7) {
        json = {
          "idcotizacion_proveedor": this.state.item.idcotizacion_proveedor,
          "idestatuscotizacion": 3,// Estatus de cotización? aprobada
          "password": this.state.txtpassword,
          "comentario": ""
        }
        resp = await cambiarStatusCotizacionProductoGeneral(json);
        //this.showModal({ accion: this.state.accion, id: this.state.accion })
      }

      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return;
      }else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }else {
        this.showModal({ accion: this.state.accion, id: this.state.accion })
      }
    }

  }

  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            {
              <div>
                <div className="divTituloModal">
                  <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                </div>
              </div>}

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                  type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}

                {(!this.state.vermsgerrortxtpassword && this.state.vermsgerrortxtprincipal) ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtprincipal}</p> : null}

              </div>
            </div>

            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()}>Continuar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

