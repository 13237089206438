import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './Requisicion.css';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import {
  coloresTxtBuscador, estiloBtnActualizar, estiloBtnNuevo, estiloBtnVer,
  estiloComboBox, estiloFiltroEncabezados
} from '../../Services/PaletaDeColores';
import Zona from './Zona';
import Stock from './Stock'
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
//import { BsFillEyeFill } from 'react-icons/bs';
import {
  getRequisicionInformacion, getEstatus
} from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ModalDocumentos from '../../Complements/Modales/ModalRequisicion/ModalDocumentos';
import ModalFormularioAccionEstatus from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatus';
import ModalFormularioAccionEstatusContrasena from '../../Complements/Modales/ModalRequisicion/ModalFormularioAccionEstatusContrasena';

export default class Requisicion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      txtnombrerequisicion: '',
      txttiporequesicion: '',
      txtfechaestimadacompra: '',
      txtestatus: '',
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      estatus: [],

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,

      itemseleccionado: null,

      listatiporequesicion: [],
      listaestatus: [],

      informacionrequisicion: {
        nombre: '',
        id: '',
        solicitante: '',
        tiporequisicion: '',
        fechacompraestimada: '',
        estatus: '',
        fechacreacion: '',
        ultimaedicion: ''
      },

      contenidorequisicion: [],
      estatuseditable: false,

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      tablaresumen: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Proveedor"],
        dimensiones: [
          "20%",
          "20%",
          "15%",
          "15%",
        ],
        mindimensiones: [
          "130px",
          "170px",
          "120px",
          "120px",
        ],
        maxdimensiones: [
          "140px",
          "180px",
          "130px",
          "130px",
        ],
        elementos: [],
        total: 0,
      },
      listastock: [],

      // Mdal documentos
      modaldetallesdocumentos: false,

      // Modal de acciones rechazar/aprobar requisición
      modalaccionestatus: false,

      // Modal de solicitud de contraseñas para rechazar/aprobar requisición
      modalaccionestatuscontrasena: false,

      redireccionando: false,
      editar:false,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,



      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var idrequisicion = await localStorage.getItem('dato')
    if (idrequisicion != null) {
      var resprequisicioninformacion = await getRequisicionInformacion({ idrequisicion: idrequisicion });
      if (this.manejadorLlamadasApis(resprequisicioninformacion)) {
        return;
      }

      if (resprequisicioninformacion.code == 200) {
        await toast.error(resprequisicioninformacion.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var informacionrequisicion = this.state.informacionrequisicion
      informacionrequisicion = resprequisicioninformacion.data ? resprequisicioninformacion.data : {};

      var tablaresumen = this.state.tablaresumen
      var objetoresumen = informacionrequisicion.Resumen ? informacionrequisicion.Resumen : {}
      tablaresumen.elementos = objetoresumen.productos ? objetoresumen.productos : {}
      tablaresumen.total = objetoresumen.total

      var contenidorequisicion = this.state.contenidorequisicion
      contenidorequisicion = informacionrequisicion.zonas

      var stock = informacionrequisicion.stock ? informacionrequisicion.stock : []
      var listastock = stock.productos ? stock.productos : []

      // Define si los botones de Aprobar o Rechazar requisición se incluirán
      var estatuseditable = informacionrequisicion.idestatusrequisicion == 2

      this.setState({
        informacionrequisicion: informacionrequisicion,
        contenidorequisicion: contenidorequisicion,
        tablaresumen: tablaresumen,
        estatuseditable: estatuseditable,
        listastock: listastock
      });
    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }


  
  verModalDetallesDocumentos = (ban) => {
    if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Documentos', accionmodal: 2, modaldetallesdocumentos: true });
    }
  }

  cerrarModalDocumentos = (opciones) => {
    this.setState({ modaldetallesdocumentos: !this.state.modaldetallesdocumentos })
    if (opciones.accion == 1) {
      console.log("Se cerró el modal de documentos")
    }
  }

  verModalFormularioAccionEstatus = (ban) => {
    const aux = this.state.informacionrequisicion
  var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion/*, idestatusrequisicion: aux.idestatusrequisicion*/ }
    if (ban.id == 1) {
      this.setState({ titulomodal: 'Rechazar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
    else if (ban.id == 2) {
      this.setState({ titulomodal: 'Aprobar requisición', accionmodal: ban.id, modalaccionestatus: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatus = (opciones) => {
    this.setState({ modalaccionestatus: !this.state.modalaccionestatus })
    if (opciones.accion == 1) {
      this.verModalFormularioAccionEstatusContrasena(opciones)
    }
    else if (opciones.accion == 2) {
      this.verModalFormularioAccionEstatusContrasena({ id: 2, item: opciones.item })
    }
  }

  verModalFormularioAccionEstatusContrasena = (ban) => {
    if (ban.id == 1 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: ban.item });
    }
    else if (ban.id == 2 && ban.accion == 1) {// 1: Rechazar requisición, 2: Aprobar requisición
      const aux = this.state.informacionrequisicion
      var item = { idcatalogo_requisicion: aux.idcatalogo_requisicion, idestatusrequisicion: 3 /* id de autorizar */ }
      this.setState({ titulomodal: 'Aprobar acción', accionmodal: ban.id, modalaccionestatuscontrasena: true, itemseleccionado: item });
    }
  }

  cerrarModalFormularioAccionEstatusContrasena = (opciones) => {
    this.setState({ modalaccionestatuscontrasena: !this.state.modalaccionestatuscontrasena })
    if (opciones.accion == 1) {
      this.redireccionando()
    }
  }

  

  editar = () => {
    console.log("editar",this.state.informacionrequisicion)
    localStorage.setItem("req", JSON.stringify({ idreq: this.state.informacionrequisicion.idcatalogo_requisicion, opcion: "Requisicion" }))
    this.setState({ editar: true })
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}
        {(this.state.editar) ? (<AccionesVista bandera={'EditarRequisicion'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modaldetallesdocumentos == true) ? (
          <ModalDocumentos titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalDocumentos} estatus={this.state.estatus}
            show={this.state.modaldetallesdocumentos} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            /*listadocumentos={JSON.parse(JSON.stringify(this.state.listadocumentos))}*/ />
        ) : null}

        {(this.state.modalaccionestatus == true) ? (
          <ModalFormularioAccionEstatus titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatus} estatus={this.state.estatus}
            show={this.state.modalaccionestatus} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        {(this.state.modalaccionestatuscontrasena == true) ? (
          <ModalFormularioAccionEstatusContrasena titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalFormularioAccionEstatusContrasena} estatus={this.state.estatus}
            show={this.state.modalaccionestatuscontrasena} accion={this.state.accionmodal} item={this.state.itemseleccionado} />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezado'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{this.state.informacionrequisicion.nombre}</label>
              </div>

              {this.state.informacionrequisicion.periodo && this.state.informacionrequisicion.mes ?
                <div className='col-12 col-sm-12 col-md-12 containerSubTabla paddingBottomEncabezadoss'>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>
                    {'Plan anual ' + this.state.informacionrequisicion.periodo + ', ' + this.state.informacionrequisicion.mes}
                  </label>
                </div> : null}





              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Actividad: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.actividad}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Tipo de requisición: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.tiporequisicion}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 col-lg-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha estimada de entrega: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechaestimada_compra}</label>
              </div>


              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Solicitante: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.solicitante}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de creación: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.fechacreacion}</label>
              </div>


              <div className='col-7 col-sm-7 col-md-7 col-lg-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informacionrequisicion.Estatusrequisicion}</label>

              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha de actualización: </label>
                <label className='lblTexto letraTitulos' >
                  {this.state.informacionrequisicion.fechaactualizacion ? this.state.informacionrequisicion.fechaactualizacion : this.state.informacionrequisicion.fechacreacion}
                </label>
              </div>

              {this.state.informacionrequisicion.comentario ?
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-2 pr-2 seccionAviso text-center' style={{ marginTop: '8px' }}>
                  <label className='lblTexto letraTitulos text-center'>{this.state.informacionrequisicion.comentario}</label>
                </div> : null}

            </div>
            <br />
            <br />

            {/*<div className='row paddingEncabezados align-items-end'>
              <div className='col btnAcciones'></div>
              <div className='col-auto  btnAcciones paddingBottomEncabezados'>
                <label className='letraNormal > lblSubtablaBotones'
                  /*onClick={this.redireccionar.bind(this, { contenido: 'item.idcatalogo_proveedores', ruta: "inventario" })}* /
                  style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                  <BsFillEyeFill className="icoBotones" />
                  <b className='btnVerTabla'>Ver historial </b>
                </label>
              </div>
              <div className='col-auto btnAcciones paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.verModalDetallesDocumentos.bind(this, { opcion: "2" })}><IoIosDocument />&nbsp; Ver documentos</button>
              </div>
            </div>*/}

          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTablaAA">
              {this.state.contenidorequisicion.length > 0 ?
                this.state.contenidorequisicion.map((item, index) => (
                  <Zona key={"tipo_" + index} itemzona={item} />
                ))
                : <div></div>
              }
              {this.state.listastock.length > 0 ?
                <Stock listastock={JSON.parse(JSON.stringify(this.state.listastock))} />
                : <div></div>}
            </div>

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
        <br />
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTablaMOD">
              <div className="container-fluid contenedorResumen" style={{ padding: '10px 30px', minWidth: '640px', maxWidth: '880px' }}>
                <div className='col-auto minPaddingTopBottom divMinWidth containerSubTablaAA d-flexAA align-items-center'>
                  <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Resumen</b></label>
                </div>
                <div className='containerSubTablaAA divMinWidth'>
                  <Table>
                    <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                      <tr>
                        {this.state.tablaresumen.encabezados.map((titulo, index) => (
                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                            style={{
                              minWidth: this.state.tablaresumen.mindimensiones[index],
                              maxWidth: this.state.tablaresumen.maxdimensiones[index],
                              width: this.state.tablaresumen.dimensiones[index],
                              textAlign: (index == 1 || index == 2) ? 'center' : '',
                              color: this.state.colorencabezadostabla
                            }}>
                            {titulo}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ verticalAlign: "bottom" }}>
                      {
                        this.state.tablaresumen.elementos.map((item, index) => (
                          <tr key={"tabla" + index} style={{ borderBottom: (index == this.state.tablaresumen.elementos.length - 1) ? 'solid 1px #91b9f9' : '' }}>
                            <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                              {item.Producto}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                              {item.Unidadmedida}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                              {item.cantidad}
                            </td>
                            <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: (item.idproveedorsugerido != '') ? this.state.colorcontenidotabla : '#8E8E8E' }}>
                              {item.idproveedorsugerido != '' ? item.proveedorsugerido : 'Sin proveedor'}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
                <div style={{ minHeight: '8px' }}></div>
              </div>
            </div>
          </div>

          <br />
          {parseInt(this.state.informacionrequisicion.idestatusrequisicion)==2 && //this.state.estatuseditable==true &&
            <div className="divBtnContenedorRequisicion" >
              <Button className="btnRechazar" onClick={this.verModalFormularioAccionEstatus.bind(this, { id: 1 })}>Rechazar requisición</Button>
              <Button className="btnAceptar" onClick={this.verModalFormularioAccionEstatusContrasena.bind(this, { id: 2, accion: 1 })}>Aprobar requisición</Button>
            </div>
          }


          { parseInt(this.state.informacionrequisicion.idestatusrequisicion)==1 ||  parseInt(this.state.informacionrequisicion.idestatusrequisicion)==2
          ||  parseInt(this.state.informacionrequisicion.idestatusrequisicion)==4 ?
            <div className="divBtnContenedorRequisicion" >
              <Button className="btnAceptar" onClick={()=>this.editar()}>Editar requisición </Button>
            </div>
            :null
          }
          <br />
        </div>
        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}

      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogorequisicionesagroquimicos"} />)
  }
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
  if (bandera == 'EditarRequisicion') {
    return (<Redirect from='/' to={"/editarrequisicionagroquimico"} />)
  }
}
