import React from 'react'
import './InputHora.css'
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import CustomActionBar from './CustomActionBar'
import dayjs from 'dayjs';

export default class InputHora extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txthora: null
        };
    }

    componentDidMount() {
        if(this.props.accion == 2){
            const hora = dayjs(this.props.hora, 'HH:mm:ss');
            this.setState({ txthora: hora });
        }
    }

    cambiarHoraReloj = (time) =>{
        if(time.$H < 10){
            var horas = '0' + time.$H;
        }else{
            var horas = time.$H;
        }
        if(time.$m < 10){
            var minutos = '0' + time.$m;
        }else{
            var minutos = time.$m;
        }
        this.props.escribir(horas + ":" + minutos)
    }

    render() {
        const localeText = {
            toolbarTitle: "Selecciona la hora"
        };
        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileTimePicker 
                        localeText={localeText}
                        value={this.state.txthora}
                        onChange={this.cambiarHoraReloj}
                        className={'InputHora'}
                        sx={{ height: '25px'}}
                        slots={{
                            actionBar: CustomActionBar,
                        }}
                        slotProps={{
                            actionBar: {
                                actions: ['cancel','accept'],
                            }
                        }}
                    />
                </LocalizationProvider>
            </>
        )
    }
}
