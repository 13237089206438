import React from 'react'
import './CotizacionProductoGeneral.css';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core';
import { estiloBtnActualizar, estiloComboBox, estiloFiltroEncabezados, estilosModal } from '../../Services/PaletaDeColores';
import TablaProductos from './TablaProductos';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';

export default class GuardarTablaCotizacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      accion: 1,

      nombrezona: props.itemzona.proveedor,
      listaproductos: props.itemzona.productos,

      colapsartabla: true,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colortipolbl: estiloComboBox().colorcombolbl,

      estilosmodal: estilosModal(),
    }
  }

  async componentDidMount() {
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  editarTarjeta = async () => {
    this.props.actualizarContenido(this.props.itemzona);
  }

  eliminarTarjeta = async () => {
    this.props.eliminarTarjeta(this.props.itemzona);
  }

  cambiarStatus = async () => {
    this.props.cambiarStatus(this.props.itemzona);
  }

  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="marginTopSeparator" >
          <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row flexNoWrap'>
                      <div className='col-1 containerSubTablaAA minPaddingTopBottom' >
                        <button className='btnCollapsar letraNormal'
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                          onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                      </div>
                      <div className='col-5 containerSubTablaAA minPaddingTopBottom'>
                        <p className='sinMargen'><b>{this.props.itemzona.proveedor}</b><br></br>Fecha estimada de entrega: {this.props.itemzona.fechacompra}</p>
                      </div>
                      <div className='col-3 containerSubTablaAA minPaddingTopBottom'>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Disponible</label>
                      </div>
                      <div className='col-2 containerSubTablaAA minPaddingTopBottom'>
                        <button className='btnEditarCotizaciones'
                          onClick={this.editarTarjeta}>Editar</button>
                      </div>
                      <div className='col-1 containerSubTablaAA minPaddingTopBottom'>
                        <button className='btnEliminarFila' onClick={this.eliminarTarjeta}>
                          <FaTrashAlt className="icoBotones" /></button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={this.state.colapsartabla} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <div className='tablaProductoCotizaciones'>

                          <TablaProductos key={"tabla_" + 1} listaproductos={this.props.itemzona.productos}

                            totalsuma={this.props.itemzona.total}
                            totalivas={this.props.itemzona.totalivas}
                            totalprecios={this.props.itemzona.totalprecios}
                            cuantosdecimales={this.props.cuantosdecimales}
                            
                            accion={this.state.accion} />

                          <div className='row'>
                            <div className='col-4 offset-8' >
                              <button className='btnEditarCotizaciones2'
                                onClick={this.cambiarStatus}>Solicitar autorización</button>
                            </div>
                            <br></br>
                            <br></br>
                          </div>

                        </div>
                        <br></br>
                        <br></br>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
