import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioMoverComprasAlmacen.css";
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales } from '../../../Services/Validaciones';
import {  guardarFacturaCompras } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { MdCloudUpload } from 'react-icons/md';

export default class ModalVerImagen extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,

            
            estilosmodal: estilosModal(),
        }

    }

    async componentDidMount() {
        
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({accion: 5, resp: undefined})} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                    
                                <div>
                                    <img src={this.props.img} style={{ maxWidth: '100%', height: 'auto' }}/>
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}