import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoPropuestaAnual.css';

import { Table,Button } from 'reactstrap';

import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloColorError, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalAgregarActividades from '../../Complements/Modales/ModalFormularioPropuestaAnual/ModalAgregarActividades';
import TablaActividad from './TablaActividad';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import {  getListaTipoActividades, getListaZonas,getListaRanchosDisponibles,getListaActividades,getListaLotes,getListaTablaCultivos, getPresupuestosInformacion, setPresupuestosInformacion, buscarPeriodo } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ModalAutorizacion from '../../Complements/Modales/ModalFormularioPropuestaAnual/ModalAutorizacion';
import cloneDeep from 'lodash/cloneDeep';
import { formatoNumero2 } from '../../Services/Validaciones';

export default class PropuestaAnual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso:false,
      tablaresumen: {
        encabezados: [
          "Cultivo",
          "Tabla",
          "Hectáreas",
          "Árboles",
          "Total anual tabla"
        ],
        dimensiones: [
          "20%",
          "15%",
          "15%",
          "20%",
          "30%",
        ],
        mindimensiones: [
          "110px",
          "110px",
          "80px",
          "70px",
          "160px",
          "195px",
        ],
        elementos: [
        ],
        total:""
      },
      actividades: [],
      zonaseleccionado: "TodaLaZona",
      ranchoseleccionado: "TodosLosRanchos",
      listatipoactividad:[],
      listaactividades:[],
      listazona:[],
      listarancho:[],
      listalotes: [],
      listacatalogotablas:[],

      listafiltrorancho:[],
      accionmodal: 1,
      titulomodal: '',
      modalactividades: false,


      actividadesresumen: [],
      opcioneditar: false,
      idperiodo: 0,
      periodo: "2025",
      listatablasresumen: [],
      requestbody: undefined,
      mostrarmodalautorizacion:false,
      bloquearselectorrancho:false,
      redireccionaplananual:false,
      infopropuesta: {
        "periodo":"",
        "idperiodo":-1,
        "idrancho_plananual":0,
        "idrancho":-1,
        "rancho":"",
        "idzona":"",
        "zona":"",
        "idestatus":"",
        "estatus":"Activo",
        "actividades":[
           
        ],
        "resumenanual_rancho":[
          
        ]
     },
     infopropuestabase: {
      "periodo":"",
      "idperiodo":-1,
      "idrancho_plananual":0,
      "idrancho":-1,
      "rancho":"",
      "idzona":"",
      "zona":"",
      "idestatus":"",
      "estatus":"Activo",
      "actividades":[
         
      ],
      "resumenanual_rancho":[
        
      ]
    },
    respaldoinfopropuesta: {},

   
      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      colormsgerror: estiloColorError().msgerror,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (localStorage.getItem('datop') == null || localStorage.getItem('datop') == undefined) {
      this.setState({ salir: true })
    }

    var edicion = JSON.parse(localStorage.getItem ("datop")).edicion;
    var periodo = JSON.parse(localStorage.getItem ("datop")).periodo;
     await this.setState({ loadingspinners: true,
      opcioneditar: edicion,
      ranchoseleccionado: edicion ? JSON.parse(localStorage.getItem ("datop")).rancho : "TodosLosRanchos",
      periodo: periodo
     })

    this.setState({ loadingspinners: true })
    var resplistazonas = await getListaZonas();
    if ( this.manejadorLlamadasApis (resplistazonas) ) {
      return;
    }

    var resplistaranchos = await getListaRanchosDisponibles();
    if ( this.manejadorLlamadasApis (resplistaranchos) ) {
      return;
    }
    
    var resplistaactividades = await getListaActividades();
    if ( this.manejadorLlamadasApis (resplistaactividades) ) {
      return;
    }

    var resplistatipoactividades = await getListaTipoActividades();
    if ( this.manejadorLlamadasApis (resplistatipoactividades) ) {
      return;
    }

    var replistalotes = await getListaLotes();
    if ( this.manejadorLlamadasApis (replistalotes) ) {
      return;
    }
    var resplistacultivos = await getListaTablaCultivos();
    if ( this.manejadorLlamadasApis (resplistacultivos) ) {
      return;
    }

    var respperiodo = await buscarPeriodo(periodo);
    if ( this.manejadorLlamadasApis (respperiodo) ) {
      return;
    }
   
    
    

    await this.setState({ 
        listazona: resplistazonas.data,
        listarancho: resplistaranchos.data,
        listaactividades: resplistaactividades.data,
        listatipoactividad: resplistatipoactividades.data,
        listalotes: replistalotes.data,
        listacatalogotablas: resplistacultivos.data,
        idperiodo: respperiodo.data.id_periodo
    })

    setTimeout(async () => {
      if(edicion){
        
       
        var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == this.state.zonaseleccionado)
        await this.filtrarRancho(this.state.ranchoseleccionado);
        await this.setState({ 
          listafiltrorancho: resplistaranchos,
        })
      }
     }, 200);

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1 || resp.code == 1000) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if(resp.code == 300){
      if(!this.state.errordeacceso){
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
        
      this.setState({ 
        loadingspinners: false,
        errordeacceso:true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }else if (resp.code== 401) {
      this.setState({ salir: true })
      return true;
    }else if (resp.code== 200) {
      
      this.setState({ loadingspinners: false })
      return true;
    }
    return false
  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }


filtrarZona = async (itemfiltrado) => {
 
  await this.setState({ 
    zonaseleccionado: itemfiltrado 
  })


  if(itemfiltrado != "TodaLaZona"){
    var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == itemfiltrado)
    await this.setState({ 
      listafiltrorancho: resplistaranchos,
      ranchoseleccionado: "TodosLosRanchos",
    })
  }else{
      await this.setState({ 
        listafiltrorancho: [],
        ranchoseleccionado: "TodosLosRanchos",
      })
  
    
  }

}

filtrarRancho = async (idrancho) => {
  await this.setState({ 
    ranchoseleccionado: idrancho
  })

    if(idrancho != "TodosLosRanchos"){
      var request = {
        "idrancho": idrancho,
        "idperiodo": (this.state.idperiodo !="")? (this.state.idperiodo):("0")
      }
      if(!this.state.loadingspinners){
        this.setState({ loadingspinners: true })
      }

      var respinfopresupuestos = await getPresupuestosInformacion(request);
      if (respinfopresupuestos.code== 200) {
        let infobase = this.state.infopropuestabase;
        infobase.idrancho = idrancho;
        infobase.actividades = [];
        infobase.resumenanual_rancho = [];
        await this.setState({ 
          infopropuesta: infobase,
          bloquearselectorrancho: false
        })
        toast.info("No hay resultados en la consulta", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
      }
      if ( this.manejadorLlamadasApis (respinfopresupuestos) ) {
        return;
      }
      this.setState({ loadingspinners: false })
      const respdata = cloneDeep(respinfopresupuestos.data);
      await this.setState({ 
        infopropuesta: respinfopresupuestos.data,
        respaldoinfopropuesta: respdata,
        bloquearselectorrancho: false,
        zonaseleccionado: respinfopresupuestos.data.idzona,
        ranchoseleccionado: respinfopresupuestos.data.idrancho
      })
     
      setTimeout(async () => {
        var resplistaranchos = this.state.listarancho.filter((item) => item.idcatalogo_zona == this.state.zonaseleccionado)
        await this.setState({ 
          listafiltrorancho: resplistaranchos,
        })
       await this.calcularResumen(this.state.infopropuesta.actividades);
       await  this.calcularTotalAnual();
      }, 200);
      
    }
  
}

  verModalActividades = (ban) => {

    if (ban.opcion == 1) { 
      this.setState({ titulomodal: 'Agregar actividades', accionmodal: 1 })
    }

    if(this.state.ranchoseleccionado != "TodosLosRanchos"){
      this.setState({ modalactividades: true });
    }else{
      toast.info("Deberá seleccionar un rancho", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }
    
  }

  cerrarModalActividades = async (actividades) => {
    var infopropuestaaux = this.state.infopropuesta;
    infopropuestaaux.actividades = actividades;
    await this.setState({ 
      modalactividades: !this.state.modalactividades,
      infopropuesta: infopropuestaaux
    })
    await this.calcularResumen(infopropuestaaux.actividades);
    await this.calcularTotalAnual();
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  actualizarContenido = async (actividad) => {
    var infopropuestaaux = this.state.infopropuesta;

    var index =  infopropuestaaux.actividades.findIndex((obj => obj.idactividad == actividad.idactividad));
    if(index > -1){
      infopropuestaaux.actividades[index] = actividad;
    }

    await this.calcularResumen(infopropuestaaux.actividades);
    
    await this.setState({ 
      infopropuesta: infopropuestaaux,
    })
    await this.calcularTotalAnual();
  
    var bloquear = false;
    for (let index = 0; index < infopropuestaaux.actividades.length; index++) {
      const actividad = infopropuestaaux.actividades[index];
      if(actividad.tablas.length>0){
        bloquear = true;
      }
    }

    await this.setState({ 
      bloquearselectorrancho: bloquear,
    })
  }

  calcularResumen = async (listaactividades) => {
   
    var infopropuestaaux = this.state.infopropuesta;

    var listatablasresumen = [];
    for (let index = 0; index < listaactividades.length; index++) {
      var actividad = listaactividades[index];
      
      
      for (let indextabla = 0; indextabla < actividad.tablas.length; indextabla++) {
        const tabla = actividad.tablas[indextabla];

        var totalanualtabla = 0;
        tabla.meses.forEach(element => {
          var valorp = element.presupuesto == 0 ? element.presupuesto : element.presupuesto.replaceAll(',','')
          var valor = valorp != "" ? parseFloat(valorp) : 0;
          totalanualtabla = totalanualtabla + valor;

        });

        var indextablaresumen =  listatablasresumen.findIndex((tablaresumen => tablaresumen.idtabla == tabla.idtabla));
        if(indextablaresumen < 0){



          var auxtabla = {
            "idtabla": tabla.idtabla,
            "tabla":  tabla.tabla,
            "cultivo":  tabla.cultivo,
            "hectareas":  tabla.hectareas,
            "arboles":  tabla.arboles,
            "actividades": [
              {
                "idactividad": actividad.idactividad,
                "actividad":actividad.actividad,
                "presupuesto": totalanualtabla
              }
            ]
         }
        

          listatablasresumen.push(auxtabla);
        }else{
          var nuevaactividad = {
            "idactividad": actividad.idactividad,
            "actividad":actividad.actividad,
            "presupuesto": totalanualtabla
          }
          listatablasresumen[indextablaresumen].actividades.push(nuevaactividad);

        }

      }
      
    }

    listatablasresumen.forEach(element => {
      let sumapresupuestototal = element.actividades.reduce((acumulador, actual) => acumulador + actual.presupuesto, 0);
      element.totalanual = sumapresupuestototal;
    });
   
    infopropuestaaux.resumenanual_rancho = listatablasresumen;
    await this.setState({ 
      infopropuesta: infopropuestaaux
    })
  }

  
  calcularTotalAnual = async () => {
    
    var infopropuestaaux = this.state.infopropuesta;
    var listatotalesactividades = [];
    infopropuestaaux.resumenanual_rancho.listatotalesactividades = [];
      var totalanual = 0;
      for (let indextabla = 0; indextabla < infopropuestaaux.resumenanual_rancho.length; indextabla++) {
        const tabla = infopropuestaaux.resumenanual_rancho[indextabla];

        totalanual = totalanual + tabla.totalanual;

        infopropuestaaux.actividades.forEach(element => {

          var totalactividad = {
            idactividad: element.idactividad,
             totalactividad: 0
           }
           var indexact =  tabla.actividades.findIndex((actividad => actividad.idactividad == element.idactividad));
           var totalactividad = {
            idactividad: element.idactividad,
             totalactividad: indexact > -1 ? tabla.actividades[indexact].presupuesto : 0
           }

           var indexact =  listatotalesactividades.findIndex((actividad => actividad.idactividad == element.idactividad));

           if(indexact > -1){
            listatotalesactividades[indexact].totalactividad = listatotalesactividades[indexact].totalactividad  + totalactividad.totalactividad;
           }else{
            listatotalesactividades.push(totalactividad)
           }
  
           
         });

      }
      
      infopropuestaaux.resumenanual_rancho.listatotalesactividades = listatotalesactividades;
      infopropuestaaux.resumenanual_rancho.totalanualtablas = totalanual;
      await this.setState({ infopropuesta: infopropuestaaux });
      
  }

  validarDatos = async () => {

    var actividades = [];
    var infopropuestaaux =  this.state.infopropuesta;
    var datosvalidos = true;
   
    if(this.state.ranchoseleccionado != "TodosLosRanchos"){
      var requestbody = {
        "idperiodo": this.state.idperiodo != "" ?  this.state.idperiodo : 0,
        "periodo": this.state.periodo,
        "idrancho": this.state.opcioneditar ? this.state.infopropuesta.idrancho:  this.state.ranchoseleccionado,
        "idrancho_plananual": this.state.infopropuesta.idrancho_plananual,
        "password": "",
        "idestatus": 1,
        "actividades": []
      }

      if(infopropuestaaux.actividades.length > 0){
        for (let index = 0; index < infopropuestaaux.actividades.length; index++) {
          const itemactividad = infopropuestaaux.actividades[index];
  
          if(itemactividad.tablas.length > 0){
            for (let index = 0; index < itemactividad.tablas.length; index++) {
              const itemtabla = itemactividad.tablas[index];
              var listameses = [];

              if( itemtabla.meses.length > 0){
                for (let index = 0; index < itemtabla.meses.length; index++) {
                  const itemmes = itemtabla.meses[index];
                  var mes = {
                    "idmestablaactividad_presupuestorancho": itemmes.idmestablaactividad_presupuestorancho,
                    "idmes": itemmes.idmes,
                    "presupuesto": itemmes.presupuesto
                  }
                  listameses.push(mes)
                }
      
                let actividad = {
                  idtablaactividad_presupuestorancho: itemtabla.idtablaactividad_presupuestorancho,
                  idactividad: itemactividad.idactividad,
                  idtabla: itemtabla.idtabla,
                  meses: listameses
                }
                actividades.push(actividad)
              }else{
                toast.info("Deberá agregar al menos un mes para cada actividad", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return datosvalidos = false;
              }
            }
          }else{
            toast.info("Deberá agregar al menos una tabla para cada actividad", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            return datosvalidos = false;
          }
          
        }
      }else{
        toast.info("Deberá agregar al menos una actividad", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return datosvalidos = false;
      }
      
      requestbody.actividades = actividades;

      await this.setState({ 
        requestbody: requestbody,
        mostrarmodalautorizacion:true
      })
      

    }else{
      toast.info("Deberá seleccionar un rancho", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }
  }

cancelar = async () =>{
  this.setState({ loadingspinners: false })
  await this.setState({ 
    bloquearselectorrancho: false,
  })
  this.filtrarRancho(this.state.ranchoseleccionado)

      
}

  cerrarModalAutorizacion = async (mensaje) => {
    await this.setState({ 
      mostrarmodalautorizacion:false
    })

    if(mensaje != 0){
      var request = this.state.requestbody;
      request.password = mensaje;
      this.activarSpinner(true);
      var resp = await setPresupuestosInformacion(request);
      if (resp == -1 || resp.code == 1000) {
        this.activarSpinner(false);
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return true 
      }
      else if (resp.code == 200 || resp.code == 300) {
          this.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return true 
      }else if (resp.code == 400){
        this.activarSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return true 
      } else {
       
        if(resp.code==0){
          this.setState({ 
            confirmarenvio: !this.state.confirmarenvio,
            bloquearselectorrancho: false
         })
        
         if(this.state.idperiodo == ""){
          var respperiodo = await buscarPeriodo(this.state.periodo);
          if ( this.manejadorLlamadasApis (respperiodo) ) {
            return;
          }         
          await this.setState({ 
            idperiodo: respperiodo.data.id_periodo
          })
         }
          toast.success("Propuesta guardada con éxito", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        setTimeout(async () => {
          await this.filtrarRancho(this.state.ranchoseleccionado)
        }, 200);
        
        this.activarSpinner(false);
      }
    }
    
  }

  render() {
    return (

      <>
{this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
          : null}
          {(this.state.redireccionaplananual) ? (<Acciones bandera={'iraplananual'}/>) : null}
        <ToastContainer />

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalactividades == true) ? (
          <ModalAgregarActividades titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalActividades}
            show={this.state.modalactividades} accion={this.state.accionmodal}  
            activarSpinner={this.activarSpinner}
            listaactividades={JSON.parse(JSON.stringify(this.state.listaactividades))}
            listatipoactividad={JSON.parse(JSON.stringify(this.state.listatipoactividad))}
            listaactividadesagregadas={JSON.parse(JSON.stringify(this.state.infopropuesta.actividades))}
            respaldoinfopropuesta={JSON.parse(JSON.stringify(this.state.respaldoinfopropuesta))}

            />
        ) : null}
        {(this.state.mostrarmodalautorizacion == true) ? (
          <ModalAutorizacion titulomodal={"Aprobar acción"} cerrarModalAutorizacion={this.cerrarModalAutorizacion}
            show={this.state.mostrarmodalautorizacion} accion={this.state.accionmodal}  
            activarSpinner={this.activarSpinner}
          

            />
        ) : null}

        <Navbar />

        {/* ////////////////////   Inicio Sección Filtros y Acciones   ////////////////////////////////////// */}
        <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">

            <div className='row paddingEncabezados align-items-end '>
              <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Presupuesto anual {this.state.periodo}</label>
              </div>

              <div className='col-8 containerSubTabla paddingBottomEncabezados'>
                <div className='d-flex justify-content-around w-100 contenedorWrap'>
                  <div className='col-12 col-sm-12 col-lg-5 col-xl-5  paddingBottomEncabezados paddingR'>
                    <div className={ this.state.bloquearselectorrancho ? "divComboFullWith inabilitado" : "divComboFullWith"} style={{ color: this.state.colorcbxtipolbl, backgroundColor:  this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                        <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                        <div className="selectBox ">
                            <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                disabled={this.state.bloquearselectorrancho ? "disabled":""}
                                onChange={(event) => this.filtrarZona(event.target.value)}>
                                {this.state.zonaseleccionado == "TodaLaZona" ?
                                <option selected="true" value='TodaLaZona'>Selecciona una zona</option> : <option value='TodaLaZona'>Selecciona una zona</option>}  
                                {this.state.listazona.map((i, index) => (
                                    this.state.zonaseleccionado == i.idcatalogo_zona ?
                                    <option selected value={i.idcatalogo_zona} key={"tipo_" + index}  >{i.zona} </option>:
                                    <option value={i.idcatalogo_zona} key={"tipo_" + index}  >{i.zona} </option>
                                ))}
                            </select>
                        </div>
                      </div>
                  </div>
                  <div className='col-12 col-sm-12 col-lg-5 col-xl-5  paddingBottomEncabezados'>
                      <div className={ this.state.bloquearselectorrancho ? "divComboFullWith inabilitado" : "divComboFullWith"} style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                          <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho</label>
                          <div className="selectBox ">
                              <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }} 
                                  disabled={this.state.bloquearselectorrancho ? "disabled":""}
                                  onChange={(event) => this.filtrarRancho(event.target.value)}>
                                  {this.state.zonaseleccionado == "TodaLaZona" ?
                                  <option selected="true" value='TodosLosRanchos'>Selecciona primero una zona</option> : <option value='TodosLosRanchos' >Selecciona un rancho</option>}  
                                  {this.state.listafiltrorancho.map((i, index) => (
                                    this.state.ranchoseleccionado == i.idcatalogo_rancho ?
                                      <option selected value={i.idcatalogo_rancho} key={"tipo_" + index}  >{i.rancho} </option>:
                                      <option value={i.idcatalogo_rancho} key={"tipo_" + index}  >{i.rancho} </option>
                                  ))}
                              </select>
                          </div>
                      </div>
                  </div>
                
                </div>
                {this.state.bloquearselectorrancho ? <label className="msgInvalidacion letraNormal"
                                    style={{ color: this.state.colormsgerror }}>Para ver otro presupuesto, primero deberá guardar o cancelar sus cambios</label> : null}
              </div>
              
              <div className='col containerSubTabla'></div>
            
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalActividades.bind(this, { opcion: "1" })}>+ Actividades</button>
              </div>

            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Filtros y Acciones   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <br/>
        <div className="container-fluid">
          <div className="container-fluid anchoMaximoActividades">
            <div className="container-fluid">
              {this.state.infopropuesta.actividades.length > 0 ?
                this.state.infopropuesta.actividades.map((item, index) => (
                  <TablaActividad key={"actividad" + index} 
                    actividad={item}
               //     eliminarElemento={this.eliminarElemento}
                    actualizarContenido = {this.actualizarContenido}
                    listalotes = {this.state.listalotes}
                    listacatalogotablas = {this.state.listacatalogotablas}
                    ranchoseleccionado={this.state.ranchoseleccionado}
                    respaldoinfopropuesta={JSON.parse(JSON.stringify(this.state.respaldoinfopropuesta))}
                  />
                ))
                : <div></div>
              
            }
            </div>
            
            

          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}

        {/* ////////////////////   Inicio Sección Tabla  Resumen  ////////////////////////////////////// */}
        <br />
        {
          this.state.infopropuesta.resumenanual_rancho.length > 0 ?
          (
            <div className="container-fluid containerTabla contenedorResumenPA" style={{ backgroundColor: '#ffffff' }}>
              <div className="container-fluid">
                <div className='col-auto containerSubTabla minPaddingTopBottom d-flex align-items-center'>
                  <label className='letraTitulos'><b>Resumen</b></label>
                </div>
                <div className='row contenedorNowrap' >
                  <div className='col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 contenedorTPrincipal'>
                      
                    <Table>
                        <thead style={{ verticalAlign: "bottom" }}>
                            <tr>
                              {
                               
                                this.state.tablaresumen.encabezados.map((titulo, index) => (
                                  <th key={"tabla" + index} className={(index != 0 && index != 1) ? "centerText  letraTitulos" : " letraTitulos"}
                                    style={{ minWidth: this.state.tablaresumen.mindimensiones[index], width: this.state.tablaresumen.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                    {titulo}
                                  </th>
                                ))
                              }
                              
                            </tr>
                        </thead>

                        <tbody className='' style={{ verticalAlign: "bottom" }}>
                          {this.state.infopropuesta.resumenanual_rancho.map((item, index) => (
                            <tr key={index}  >
                              <td key={"col-"+index + "-1"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                {item.cultivo}
                              </td>
                              <td key={"col-"+index + "-2"} className="letraNormal lblSubtabla text-truncate" style={{ color: this.state.colorcontenidotabla }}>
                                {item.tabla}
                              </td>
                              <td key={"col-"+index + "-3"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                                {item.hectareas} 
                              </td>
                              <td key={"col-"+index + "-4"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                                {item.arboles}
                              </td>
                              <td key={"col-"+index + "-5"} className="letraNormal lblSubtabla " style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>
                                ${formatoNumero2(item.totalanual)}
                              </td>
                            </tr>
                          
                          ))}
                        </tbody>
                        {
                           
                            <thead style={{ verticalAlign: "bottom" }} className=''>    
                          <tr key={"tabla-total" }  >
                                      <th className=" lblEncabezadosTabla letraTitulos border-0"  style={{ minWidth: this.state.tablaresumen.mindimensiones[5], width: this.state.tablaresumen.dimensiones[4], color: this.state.colorencabezadostabla }}>
                                          Total anual actividad
                                      </th>
                                     
                                      <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}></th>
                                      <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}></th>
                                      <th className=" lblEncabezadosTabla centerText border-0" style={{ color: this.state.colorcontenidotabla }}></th>
                                      <th className=" lblEncabezadosTabla  border-0" style={{ color: this.state.colorcontenidotabla, textAlign:"right" }}>${ formatoNumero2(this.state.infopropuesta.resumenanual_rancho.totalanualtablas)}</th>
                          </tr>
                        </thead>
                        }
                        
                    </Table>
                  </div>
                  <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 contenedorMeses'>

                    {
                      this.state.infopropuesta.actividades.length > 0 ? 
                      <Table >

                          <thead style={{ verticalAlign: "bottom" }}>
                              <tr>
                                  {this.state.infopropuesta.actividades.map((actividad, index) => (
                                    <th key={"th" + index} className={ "centerText  letraTitulos encabezadoResumen"}
                                      style={{ minWidth: this.state.tablaresumen.mindimensiones[0], width: this.state.tablaresumen.dimensiones[0], color: this.state.colorencabezadostabla }}>
                                      {actividad.actividad}
                                    </th>
                                  ))}
                              </tr>
                          </thead>
                          
                          <tbody className='' style={{ verticalAlign: "bottom" }}>
                            {this.state.infopropuesta.resumenanual_rancho.map((itemtabla, indextabla) => (
                              <tr key={indextabla}  >
                              {
                              this.state.infopropuesta.actividades.map((actividad, index) => (
                                itemtabla.actividades.findIndex((obj => obj.idactividad == actividad.idactividad)) > -1 ?
                                  itemtabla.actividades.map((itemactividad, indexmes) => (
                                    actividad.idactividad == itemactividad.idactividad ?
                                  <td key={"td-"+indexmes} className="letraNormal lblSubtabla celdaACtividad " style={{textAlign:"right"}}>
                                    ${formatoNumero2(itemactividad.presupuesto)}
                                </td>:null))
                                :<td key={"td-"+index} className="letraNormal lblSubtabla celdaACtividad " style={{textAlign:"right"}}>
                                    ${formatoNumero2(0)}
                                </td> 
                                ))
                              
                              }
                                
                              </tr>
                            
                            ))}
                          </tbody>
                       {  
                               <thead style={{ verticalAlign: "bottom" }} className=''>
                                                    
                               <tr key={"tabla-total" }  >

                                  {this.state.infopropuesta.resumenanual_rancho.hasOwnProperty("listatotalesactividades") ?
                                    this.state.infopropuesta.resumenanual_rancho.listatotalesactividades.map((totalactividad, index) => (
                                     <th key={"td-"+index} className="letraNormal lblSubtabla celdaACtividad " style={{textAlign:"right"}}>
                                   
                                   $ {formatoNumero2(totalactividad.totalactividad)}
                                   </th>
                                   ))
                                  : null
                                  }
                                        
                                 </tr>
                           </thead>
                              
                              }
                      </Table>
                      : null
                    }
                      
                  </div>
                </div>
              </div>
            </div>
          )


          :null
        }
        
        {/* ////////////////////   Fin Sección Tabla  Resumen ////////////////////////////////////// */}
        <>
          <br />
          <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
              <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
          </div>
          <br />
          <div className="divBtnContenedorModal spacePaddingBottom" >
              <Button className="btnCancelModal" onClick={() => this.cancelar()}>Cancelar</Button>
              <Button className="btnAceptarModal" 
              onClick={this.validarDatos}
              disabled={!this.state.confirmarenvio} >Guardar</Button>
          </div>
        </>
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }else if(bandera == "iraplananual"){
  return (<Redirect from='/' to={"/catalogoplananual"} />)
  
}
}