import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalAutorizacion.css";
import { validarPasswordEliminar } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';


export default class ModalAutorizacion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortitulo: estiloFiltroEncabezados().colortitulos,

      txtpassword: "",
      vermsgerrortxtpassword: false,
      msgerrortxtpassword: ""
    }

  }
  async componentDidMount() {
    setTimeout(async () => {
      this.refInput.focus();
    }, 500);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  confirmarSave = async () => {

    let validacionescorrectas = true
    this.setState({ vermsgerrortxtpassword: false })

    let txtpassword = validarPasswordEliminar(this.state.txtpassword.trim())
    if (txtpassword.codigo == "invalid") {
      this.setState({ msgerrortxtpassword: txtpassword.mensaje })
      this.setState({ vermsgerrortxtpassword: true })
      validacionescorrectas = false;
    }

    if (validacionescorrectas) {
      this.showModal(this.state.txtpassword)
    }
  }


  render() {
    return (

      <>
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div className="divTituloModal">
              <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Ingresa tu contraseña</p>

                <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                  type={'password'} value={this.state.txtpassword} onChange={this.escribir("txtpassword")} ref={(input) => { this.refInput = input; }}></input>

                {this.state.vermsgerrortxtpassword ? <p className="msgError letraNormal"
                  style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpassword}</p> : null}
              </div>
            </div>

            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.confirmarSave()} >Continuar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }
}
