import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { estiloColorError, estilosModal } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales } from '../../../Services/Validaciones';
import { guardarFacturaCompras } from '../../../Services/Api';
import { ImDownload3 } from 'react-icons/im'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalCarruselEvidencias extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,


            estilosmodal: estilosModal(),


            tabla: {
                encabezados: [
                    "Título",
                    "",
                ],
                dimensiones: [
                    "60%",
                    "40%",
                ],
                mindimensiones: [
                    "200px",
                    "80px",
                ],
            },
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal({ accion: 5, resp: undefined })} />
                        <label className="modalLblTitulo letraTitulos">Evidencias adjuntas</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTabla">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>


                                                                {titulo}

                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>


                                                    {this.props.evidencias.map((item, index) => (
                                                        <tr key={"tabla_nota_" + index}                            >
                                                            <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                                               Evidencia {index +1}
                                                            </td>

                                                           
                                                                <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                                                    <a href={item.imagen} download style={{ width: "50px !important", margin: "auto" }}>
                                                                        <div className='divBotonesTrash' style={{ width: "50px" }}>
                                                                            <ImDownload3 className="icoBotonesTrash" />
                                                                        </div>
                                                                    </a>
                                                                </td>
                                                                
                                                           
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}