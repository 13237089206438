import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesOrdenCosecha.css';
import { Table } from 'reactstrap';
import {estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloBtnNuevo, estiloBtnEditar} from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getListaRanchosDisponibles, getListaLotes, getListaCatalogoUsuarios,          getDetallesOrdenCosecha, getDetallesOrdenCosechaEliminarCuadrilla, getDetallesOrdenCosechaEliminarTabla } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';
import ModalFormularioNuevoSeguridadOrdenCosecha from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalFormularioNuevoSeguridadOrdenCosecha';

export default class SeguridadOrdenCosecha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: true,
      errordeacceso: false,

      accionmodal: 1,
      titulomodal: '',
      itemeliminar: undefined,
      itemeditar: undefined,

      informaciondetallescosecha: {
        cuadrillas: [],
        tablas: [],
        ordencosecha: {},
      },

      colorverlbl: estiloBtnVer().colorverlbl,
      borderver: estiloBtnVer().borderver,

      tablasplanes: {
        encabezados: [
          "Lote",
          "Supervisor de cosecha",
          "# Flete",
          "Vehículos",
          "Placas",
          "Hora de salida",
          "Hora de entrega",
          "Persona que entrega",
          "Persona que recibe",
          "Total de rejas cortadas",
          "Total de toneladas cortadas",
          "Rejas en piso",
          "Toneladas en piso",
          "Total rejas enviadas",
          "Total toneladas enviadas",
          "Saldo rejas en piso",
          "Observaciones",
          "",
          ""
        ],
        dimensiones: [
          "15%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "20%",
          "10%",
          "10%"
        ],
        mindimensiones: [
          "150px",
          "220px",
          "100px",
          "130px",
          "170px",
          "170px",
          "170px",
          "200px",
          "200px",
          "220px",
          "260px",
          "170px",
          "170px",
          "200px",
          "230px",
          "200px",
          "170px",
          "120px",
          "100px"
        ],
        maxdimensiones: [
          "150px",
          "220px",
          "100px",
          "130px",
          "170px",
          "170px",
          "170px",
          "200px",
          "200px",
          "220px",
          "260px",
          "170px",
          "170px",
          "200px",
          "230px",
          "200px",
          "170px",
          "120px",
          "100px"
        ]
      },    

      redireccionando: false,
      redireccionandobandera: "",
      listalotes: [],
      listaranchos: [],
      listausuarios: [],
      modalnuevoregistro: false,
      modalconfirmaraccion: false,

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de tabla Información General
    var ordencosechaaux = await localStorage.getItem('ordencosecha')
    var ordencosecha = JSON.parse(ordencosechaaux)
    var idcatalogo_ordenes_cosecha = ordencosecha.idcatalogo_ordenes_cosecha
    if (idcatalogo_ordenes_cosecha != null) {
      var respdetallescosecha = await getDetallesOrdenCosecha({"idcatalogo_ordenes_cosecha": idcatalogo_ordenes_cosecha});
      if (this.manejadorLlamadasApis(respdetallescosecha)) {
        return;
      }

      if (respdetallescosecha.code == 200) {
        await toast.error(respdetallescosecha.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var resplistaranchos = await getListaRanchosDisponibles();
      if ( this.manejadorLlamadasApis (resplistaranchos) ) {
        return;
      }

      var resplistalotes = await getListaLotes();
      if ( this.manejadorLlamadasApis (resplistalotes) ) {
        return;
      }

      var resplistausuarios = await getListaCatalogoUsuarios();
      if ( this.manejadorLlamadasApis (resplistausuarios) ) {
        return;
      }

      var informaciondetallescosecha = this.state.informaciondetallescosecha
      informaciondetallescosecha = respdetallescosecha.data ? respdetallescosecha.data : {};
      informaciondetallescosecha.ordencosecha = ordencosecha;

      this.setState({
        informaciondetallescosecha: informaciondetallescosecha,
        listaranchos: resplistaranchos.data,
        listausuarios: resplistausuarios.data,
        listalotes: resplistalotes.data
      });
    } else {
      this.redireccionando();
    }

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 401) {
      this.setState({ salir: true })
      return true;
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    } else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }

      this.setState({
        loadingspinners: false,
        errordeacceso: true
      })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  verModal = (ban) => {
    if (ban.opcion == 1) { //Nuevo registro
      this.setState({ titulomodal: 'Nuevo registro seguridad', accionmodal: 1, modalnuevoregistro: true })
    } else if (ban.opcion == 2) { //Editar registro
      this.setState({ titulomodal: 'Editar registro seguridad', accionmodal: 2, modalnuevoregistro: true, itemeditar: ban.item })
    } else if (ban.opcion == 3) { //confirmar accion eliminar cuadrilla
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "seguridad", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
  }

  cerrarModalNuevoRegistro = (accion) => {
    this.setState({ modalnuevoregistro: !this.state.modalnuevoregistro })
    if(accion == 1){
      this.componentDidMount();
    }
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })
    toast.info("API en desarrollo", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    /*if(elemento.accion==1){
      this.setState({ loadingspinners: true })
      if(elemento.plan=="cuadrilla"){
        let json = {
          "idcatalogo_ordenes_cosecha_cuadrillas": elemento.item.idcatalogo_ordenes_cosecha_cuadrillas
        }
        var resp = await getDetallesOrdenCosechaEliminarCuadrilla(json);
      } else if(elemento.plan=="tabla"){
        let json = {
          "idcatalogo_ordenes_cosecha_tablas": elemento.item.idcatalogo_ordenes_cosecha_tablas
        }
        var resp = await getDetallesOrdenCosechaEliminarTabla(json);
      }
  
      
      if (resp.code == 1000) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
          this.props.activarSpinner(false);
          return;
      } else if (resp.code == 200 || resp.code == 300) {
          this.props.activarSpinner(false);
          this.setState({ confirmarenvio: !this.state.confirmarenvio })
          toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          return;
      } else {
        this.setState({ loadingspinners: false })
        toast.info("Se borro el elemento exitosamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
    }*/
  }

  render() {
    return (

      <>
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <AccionesVista bandera={'salir'} />
          </>
          : null}
        <ToastContainer />

        {(this.state.redireccionando) ? (<AccionesVista bandera={'goreferencias'} />) : null}

        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}

        {(this.state.modalnuevoregistro == true) ? (
          <ModalFormularioNuevoSeguridadOrdenCosecha 
            titulomodal={this.state.titulomodal} 
            cerrarModal={this.cerrarModalNuevoRegistro}
            show={this.state.modalnuevoregistro} 
            accion={this.state.accionmodal}
            listalotes={this.state.listalotes} 
            listaranchos={this.state.listaranchos} 
            listausuarios={this.state.listausuarios} 
            item={this.state.itemeditar} 
            idordecosecha={this.state.informaciondetallescosecha.ordencosecha.idcatalogo_ordenes_cosecha}
          />
        ) : null}

        {(this.state.modalconfirmaraccion == true) ? (
          <ModalConfirarAccion 
            titulomodal={this.state.titulomodal} 
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion} 
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
            />
        ) : null} 

        <Navbar />

        {/* ////////////////////   Inicio Sección datos informativos   ////////////////////////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{"Seguridad de orden cosecha"}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.fechacosecha}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Zona: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.zona}</label>
              </div>

              <div className='col-5 col-sm-5 col-md-5 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Estatus: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.idestatus==1 ? "Activo" : ""}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Rancho: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.rancho}</label>
              </div>

            </div>
            <br />
            <br />

          </div>
        </div>


        {/* ////////////////////   INICIO Sección Tabla   ////////////////////////////////////// */}
        <br />
        <div className='row align-items-end' style={{ paddingBottom: '20px'}}>
          <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
          </div>
          <div className='col-2 paddingBottomEncabezados  ' >
            <button className='btnNuevo letraNormal'
                style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                onClick={this.verModal.bind(this, { opcion: "1" })}>+ Agregar</button>
          </div>
        </div>

        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximoTabCultivo ">
            <div className="containerSubTabla">
              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tablasplanes.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tablasplanes.mindimensiones[index], width: this.state.tablasplanes.dimensiones[index], color: this.state.colorencabezadostabla, textAlign: 'center'}}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.informaciondetallescosecha.tablas.map((item, index) => (
                      <tr key={"tabla" + index}>
                         <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"Lote 1"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"Juan esqcutia"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.zona"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.rancho"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.descripcion"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.estatuscultivo"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"fwefwefwefwef"}
                        </td> 
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"rtgrtretrtgr"} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"rtgrtretrtgr"} 
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center",  color: this.state.colorcontenidotabla }}>
                          {"fwfer ffdsf"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center",  color: this.state.colorcontenidotabla }}>
                          {"ergerg dfgdg"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center",  color: this.state.colorcontenidotabla }}>
                          {"item.desarrollo"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center",  color: this.state.colorcontenidotabla }}>
                          {"item.productivas"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center",  color: this.state.colorcontenidotabla }}>
                          {"item.fallas"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.estatus"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.estatus"}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{textAlign:"center", color: this.state.colorcontenidotabla }}>
                          {"item.estatus"}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button className='btnEditar' onClick={this.verModal.bind(this, { opcion: "2", item })} style={{
                            backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar
                          }}><BiEdit className="icoBotones" /> Editar</button>
                        </td>
                        <td key={"col-"+index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                            <div className="modalDetallesCheck" >
                              {/* <button className='btnEliminarFila' onClick={this.eliminarElemento.bind(this, {item, plan:"Tabla"})}><FaTrashAlt className="icoBotones" /></button> */}
                              <button className='btnEliminarFila' onClick={this.verModal.bind(this, { opcion: "3", item })}><FaTrashAlt className="icoBotones" /></button>
                            </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordencosecha"} />)
  }else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
