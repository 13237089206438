import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoProveedores.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoProveedor, getEstatus, getListaProveedores } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import ModalFormularioProveedores from '../../Complements/Modales/ModalFormularioProveedores/ModalFormularioProveedores';
import { AiFillCheckSquare, AiFillCloseSquare } from 'react-icons/ai';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

const todaslasopcionesdisponibles = -1

export default class CatalogoProveedores extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salir: false,
            txtbuscador: '',
            altura: window.innerHeight,
            anchura: window.innerWidth,
            filtrolistapaginada: [],
            elementosfiltrados: [],
            loadingspinners: true,
            idtipoproveedorseleccionado: todaslasopcionesdisponibles,//"TodosLosTiposDeProveedores",
            arraytipos: [],

            tabla: {
                encabezados: [
                    "Clave",
                    "Nombre",
                    "Tipo proveedor",                  
                    "Contacto",
                    "Datos fiscales",
                    "Estatus",
                    ""],
                dimensiones: [
                    "8%",
                    "50%",
                    "12%",                    
                    "12%",
                    "12%",
                    "8%",
                    "13%"
                ],
                mindimensiones: [
                    "50px",
                    "350px",
                    "150px",                   
                    "150px",
                    "150px",
                    "70px",
                    "160px"
                ],
                elementos: []
            },
            estatus: [],
            accionmodal: 1,
            titulomodal: '',
            modaldetalles: false,
            itemseleccionado: null,

            //paginacion
            activepage: 1,
            itemsperpage: 20,
            rangepages: 20,
            listapaginada: [],


            redireccionando: false,
            errordeacceso: false,


            fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
            colortitulo: estiloFiltroEncabezados().colortitulos,
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            colorencabezadostabla: estiloTabla().colorencabezadostabla,
            colorcontenidotabla: estiloTabla().colorcontenidotabla,
            colorverlbl: estiloBtnVer().colorverlbl,
            borderver: estiloBtnVer().borderver,

            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
            colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,


            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            btnfondoeditar: estiloBtnEditar().btnfondoeditar,
            btncoloreditar: estiloBtnEditar().btncoloreditar,
            btnbordereditar: estiloBtnEditar().btnbordereditar,

            btnfondomasinfo: estiloBtnMasInformacion().btnfondomasinfo,
            btncolormasinfo: estiloBtnMasInformacion().btncolormasinfo,
            btnbordermasinfo: estiloBtnMasInformacion().btnbordermasinfo,

        }
    }

    async componentDidMount() {

        if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
            this.setState({ salir: true })
        }
        this.setState({ txtbuscador: '' })

        var estatus = await getEstatus();
        if (this.manejadorLlamadasApis(estatus)) {
            return;
        }

        var resptipoproveedores = await getComboTipoProveedor();
        if (this.manejadorLlamadasApis(resptipoproveedores)) {
            return;
        }

        var resplistaproveedores = await getListaProveedores();
        if (this.manejadorLlamadasApis(resplistaproveedores)) {
            return;
        }

        var tabla = this.state.tabla;
        tabla.elementos = resplistaproveedores.data;
        await this.setState({ tabla: tabla, estatus: estatus.data, arraytipos: resptipoproveedores.data })

        this.dimensiones();
        this.cambiarContenido(1);
        window.addEventListener("resize", this.dimensiones);
        this.setState({ loadingspinners: false })

    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1 || resp.code == 1000) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 401) {
            this.setState({ salir: true })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 300 || resp.code == 200) {
            if (!this.state.errordeacceso) {
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.setState({
                loadingspinners: false,
                errordeacceso: true
            })
            return true
        }
        return false
    }

    dimensiones = () => {
        this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
    }

    actualizarTabla = async () => {
        this.setState({ loadingspinners: true });
        this.setState({ txtbuscador: '' });
        this.setState({ idtipoproveedorseleccionado: todaslasopcionesdisponibles });
        this.setState({ filtrolistapaginada: [] });
        this.setState({ errordeacceso: false });
        this.componentDidMount();
    }

    filtrarCombo = async (idtipo) => {

        await this.setState({ idtipoproveedorseleccionado: idtipo })

        var filtroproveedores = this.filtrarBusqueda(
            idtipo, this.state.txtbuscador.trim().toLocaleLowerCase())

        if (filtroproveedores.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }

        this.filtro(1, filtroproveedores)

    }

    saveInputLocacion = async (e) => {

        var cadenain = e.target.value.trim()
        var tamanocadenain = cadenain.length
        var tamanocadenast = this.state.txtbuscador.trim().length
        var banderacasosespeciales = (tamanocadenast > tamanocadenain) && (tamanocadenain < 3) && (tamanocadenast >= 3)

        this.setState({ txtbuscador: e.target.value })
        if (tamanocadenain >= 3 || banderacasosespeciales) {

            var filtroproveedores = this.filtrarBusqueda(
                this.state.idtipoproveedorseleccionado, cadenain.toLocaleLowerCase())

            if (filtroproveedores.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }

            this.filtro(1, filtroproveedores)
        }

    }

    filtrarBusqueda = (idtipo, textobusqueda) => {

        var tabla = JSON.parse(JSON.stringify(this.state.tabla))
        var filtrotipoproveedor = tabla.elementos;

        if (idtipo != todaslasopcionesdisponibles) {
            filtrotipoproveedor = filtrotipoproveedor.filter((item) => item.idtipo_proveedor == idtipo)
        }

        if (textobusqueda.length < 3) {
            return filtrotipoproveedor
        }

        return filtrotipoproveedor.filter(
            (item) => item.nombre.toLocaleLowerCase().includes(textobusqueda)
                || item.clave.toLocaleLowerCase().includes(textobusqueda))

    }

    verModalDetalles = (ban) => {
        if (ban.opcion == 1) { //Nuevo
            this.setState({ titulomodal: 'Nuevo proveedor', accionmodal: 1 })
        }
        this.setState({ modaldetalles: true });
    }

    cerrarModal = (opciones) => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
        if (opciones.accion == 1) {
            this.setState({ loadingspinners: true })
            this.redireccionar({ contenido: opciones.id })
        }
    }

    filtro = (pagenumber, array) => {
        setTimeout(() => {
            this.setState({
                elementosfiltrados: array,
                activepage: pagenumber,
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
            });
        }, 0);
    }

    cambiarContenido = (pagenumber) => {
        setTimeout(() => {
            this.setState({
                activepage: pagenumber,
                listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
                filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
            });
        }, 0);
    }

    borrarBusqueda = () => {
        this.saveInputLocacion({ target: { value: '' } })
    }

    redireccionar = (idproveedor) => {
        localStorage.setItem("dato", [idproveedor.contenido]);
        this.setState({ redireccionando: true })
    }

    render() {
        return (
            <>
                {this.state.salir ?
                    <>
                        <SpinnerModal tipo="full" show={true} />
                        <AccionesProveedores bandera={'salir'} />
                    </>
                    : null}

                {(this.state.redireccionando) ? (<AccionesProveedores bandera={'goreferencias'} />) : null}
                <ToastContainer />

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                    <ModalFormularioProveedores titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModal} estatus={this.state.estatus}
                        show={this.state.modaldetalles} accion={this.state.accionmodal} item={this.state.itemseleccionado} arraytipos={JSON.parse(JSON.stringify(this.state.arraytipos))} />
                ) : null}

                <Navbar />
                <div className="container-fluid anchoMaximo" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                    <div className="container-fluid anchoMaximoExtendido">

                        <div className='row paddingEncabezados align-items-end '>
                            <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 containerSubTabla paddingBottomEncabezados' style={{ minWidth: '200px' }} >
                                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Proveedores  </label>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Tipo</label>
                                    <div className="selectBox ">
                                        <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                            onChange={(event) => this.filtrarCombo(event.target.value)} >
                                            {<option selected={this.state.idtipoproveedorseleccionado == todaslasopcionesdisponibles} value={todaslasopcionesdisponibles}>Todos</option>}
                                            {this.state.arraytipos.map((i, index) => (
                                                <option value={i.idtipo_proveedor} key={"tipo_" + index} selected={this.state.idtipoproveedorseleccionado == i.idtipo_proveedor} >{i.tipo} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <div className='txtBuscadorAncho'>
                                    <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                    <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}
                                        className="txtBuscador letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                                        placeholder="Buscar proveedor"></input>
                                    {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                        onClick={this.borrarBusqueda} />) : null}
                                </div>
                            </div>

                            <div className='col containerSubTabla'></div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnActualizar letraNormal'
                                    style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                    onClick={this.actualizarTabla}><RiRefreshLine />&nbsp;Refrescar lista</button>
                            </div>

                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                <button className='btnNuevo letraNormal'
                                    style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                                    onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
                            </div>

                        </div>
                    </div>
                </div>


                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid anchoMaximo containerTabla">
                    <div className="container-fluid anchoMaximoExtendido ">
                        <div className="containerSubTabla">
                            <Table>
                                <thead style={{ verticalAlign: "bottom" }}>
                                    <tr>
                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                            <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                {titulo}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody style={{ verticalAlign: "bottom" }}>

                                    {this.state.txtbuscador.length < 3 && this.state.idtipoproveedorseleccionado == todaslasopcionesdisponibles ?

                                        this.state.listapaginada.map((item, index) => (
                                            <tr key={"tabla" + index}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorencabezadostabla }}>
                                                    {item.clave}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                                                    {item.nombre}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorencabezadostabla }}>
                                                    {item.tipoproveedor}
                                                </td>
                                                
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla }}>
                                                    {item.contactoprincipal == undefined || item.contactoprincipal.length == 0 ? <b className='lblSinDatos'>Sin contacto</b> : item.contactoprincipal.nombre + " " + item.contactoprincipal.telefono}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla }}>
                                                    {!item.tienedatosfiscales ? <b className='lblSinDatos'>Incompletos <AiFillCloseSquare style={{ fontSize: 'x-large' }} /></b> : "Completados"}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6], color: this.state.colorencabezadostabla }}>
                                                    {item.estatus}
                                                </td>
                                                <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                                                    <label onClick={this.redireccionar.bind(this, { contenido: item.idcatalogo_proveedores })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                        <b className='btnVerTabla'>Más información</b>
                                                    </label>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        this.state.filtrolistapaginada.map((item, index) => (

                                            <tr key={"tabla" + index}>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                                                    {item.clave}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1] }}>
                                                    {item.nombre}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2] }}>
                                                    {item.tipoproveedor}
                                                </td>
                                               
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[4], width: this.state.tabla.dimensiones[4], color: this.state.colorencabezadostabla }}>
                                                    {item.contactoprincipal == undefined || item.contactoprincipal.length == 0 ? <b className='lblSinDatos'>Sin contacto</b> : item.contactoprincipal.nombre + " " + item.contactoprincipal.telefono}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[5], width: this.state.tabla.dimensiones[5], color: this.state.colorencabezadostabla }}>
                                                    {!item.tienedatosfiscales ? <b className='lblSinDatos'>Incompletos <AiFillCloseSquare style={{ fontSize: 'x-large' }} /></b> : "Completados"}
                                                </td>
                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                                                    {item.estatus}
                                                </td>
                                                <td className="letraNormal > lblSubtablaBotones" style={{ minWidth: this.state.tabla.mindimensiones[7], width: this.state.tabla.dimensiones[7] }} >
                                                    <label onClick={this.redireccionar.bind(this, { contenido: item.idcatalogo_proveedores })} style={{ borderBottom: this.state.borderver, color: this.state.colorverlbl }} >
                                                        <b className='btnVerTabla'>Más información</b>
                                                    </label>
                                                </td>
                                            </tr>

                                        ))
                                    }


                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>
                <div className="panelPaginationSection">
                    <Paginacion
                        activepage={this.state.activepage}
                        itemscountperpage={this.state.itemsperpage}
                        totalitemscount={this.state.txtbuscador.length < 3 && this.state.idtipoproveedorseleccionado == todaslasopcionesdisponibles ? this.state.tabla.elementos : this.state.elementosfiltrados}
                        pagerangerisplayed={this.state.rangepages}
                        cambiarcontenido={(e) => this.cambiarContenido(e)}
                    />
                </div>
            </>
        )
    }
}


function AccionesProveedores({ bandera }) {
    const history = useHistory();
    const location = useLocation();

    if (bandera == 'goreferencias') {
        history.push(location.pathname);
        return (<Redirect from='/' to={"/proveedor"} />)
    }

    if (bandera == 'salir') {
        localStorage.clear();
        return (<Redirect from='/' to={"/"} />)
    }

}
