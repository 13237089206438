import React from 'react'
import './Cotizacion.css'
import { Button } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box } from '@material-ui/core'
import {
  estiloBtnAgregar, estiloBtnActualizar, estiloBtnNuevo, estiloFiltroEncabezados, estilosModal
} from '../../Services/PaletaDeColores';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Label } from 'reactstrap';
import { formatoNumero, formatoNumeroTecla } from '../../Services/Validaciones';

const coloresestatuscompra = { 1: 'lblEstatusDisponible', 2: 'lblEstatusCompra1', 3: 'lblEstatusAutorizada', 4: 'lblEstatusHecho', 5: 'lblEstatusRechazada' }
const compra = { realizada: 4, rechazada: 5 }

export default class Cotizacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso: false,
      accion: 1, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,

      tablastock: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Precio",
          "IVA",
          "IEPS",
          "Total"
        ],
        dimensiones: [
          "25%",
          "15%",
          "15%",
          "15%",
          "15%",
          "15%",
          "15%",
        ],
        mindimensiones: [
          "180px",
          "220px",
          "140px",
          "140px",
          "140px",
          "140px",
          "140px",
        ],
        elementos: []
      },
      //item: props.item,
      //listastock: props.listastock,

      colapsartabla: true,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
  }

  verModalRechazarCotizacion = (accion) => {
    var contenido = { accion: accion, item: this.props.item }
    this.props.vermodalrechazarcotizacion(contenido)
  }

  verModalAutorizacion = (accion) => {
    var contenido = { id: accion, item: this.props.item }
    this.props.vermodalautorizacion(contenido)
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }

  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="marginTopSeparatorAutoCot">
          <TableContainer component={Paper} style={{ backgroundColor: this.state.fondotitulosencabezados }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElementAutoCot nonePaddingTopBottomAutoCot' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row paddingEncabezados align-items-end ' style={{ marginBottom: "6px", padding: "8px" }}>
                      <div className="col-12">
                        <div className='row flexNoWrapAutoCot'>
                          <div className='col-auto minPaddingTopBottomAutoCot'>
                            <button className='btnCollapsarAutoCot letraNormal'
                              style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                              onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                          </div>

                          <div className="col">
                            <div className='row flexNoWrapAutoCotMM'>
                              <div className='col-auto minPaddingTopBottomAutoCot d-flex align-items-center'>
                                <p className='tituloTabla' style={{ color: 'black', fontFamily: 'SharpGrotesk_normal' }}>
                                  {this.props.item.idproveedor}: <b>{this.props.item.proveedor}</b>
                                  <br />Fecha estimada de entrega: {this.props.fechaestimadacompra}
                                  {this.props.item.idestatuscotizacion == compra.realizada && <><br />Fecha de compra: {this.props.item.fechacompra}</>}
                                </p>
                              </div>

                              <div className='col '></div>
                              <div className="col-auto align-items-top">
                                <div style={{ paddingTop: '5px', marginBottom: [compra.realizada].includes(this.props.item.idestatuscotizacion) ? '42px' : '' }}>
                                  <div>
                                    <Label className={coloresestatuscompra[this.props.item.idestatuscotizacion]}>
                                      {this.props.item.estatuscotizacion}
                                    </Label><p></p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col-12 minPaddingTopBottomAutoCot d-flex align-items-center'>
                                <p className='tituloTabla' style={{ width: '100%', color: 'black', fontFamily: 'SharpGrotesk_normal', marginTop: '-8px' }}>
                                  {this.props.item.idestatuscotizacion == compra.rechazada &&
                                    <div className='stlMsgRechazo'>
                                      <label>{this.props.item.mensaje_estatuscotizacion}</label>
                                    </div>}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                    <Collapse in={this.state.colapsartabla} timeout="auto" >
                      <Box margin={1}>
                        <div className="marginTopSeparatorAutoCot" style={{ marginBottom: '20px', padding: '20px 48px 8px 48px', backgroundColor: 'white' }} >
                          <div className="container-fluid containerTablaAA anchoTablaCotProductos">
                            <div className="container-fluid">
                              <div className='row'>
                                <div>

                                  <Table hover={'true'}>
                                    <thead style={{ verticalAlign: "bottom" }}>
                                      <tr>
                                        {this.state.tablastock.encabezados.map((titulo, index) => (
                                          <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                            style={{
                                              minWidth: this.state.tablastock.mindimensiones[index], width: this.state.tablastock.dimensiones[index], color: this.state.colorencabezadostabla,
                                              textAlign: (index > 0) ? 'center' : 'left', paddingBottom: '8px', paddingLeft: '8px'
                                            }}>
                                            {titulo}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>

                                    <tbody style={{ verticalAlign: "bottom" }}>
                                      {this.props.listastock.map((item, index) => (
                                        <tr key={index} style={{ borderTop: !index ? 'solid 1px #dee2e6' : '', borderBottom: 'solid 1px #dee2e6' }}
                                          className={''} >
                                          <td /*key={"col-" + index + "-01"}*/ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px',
                                          minWidth: this.state.tablastock.mindimensiones[0], width: this.state.tablastock.dimensiones[0], }}>
                                            {item.producto}
                                          </td>
                                          <td /*key={"col-" + index + "-02"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center',
                                          minWidth: this.state.tablastock.mindimensiones[1], width: this.state.tablastock.dimensiones[1], }}>
                                            {item.unidadmedida}
                                          </td>
                                          <td /*key={"col-" + index + "-03"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[2], width: this.state.tablastock.dimensiones[2], }}>
                                            {item.cantidad}
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[3], width: this.state.tablastock.dimensiones[3], }}>
                                            $ {formatoNumeroTecla (item.precio, this.props.cuantosdecimales)}
                                          </td>
                                           <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                           style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                           minWidth: this.state.tablastock.mindimensiones[4], width: this.state.tablastock.dimensiones[4], }}>
                                            ${formatoNumeroTecla(item.iva, this.props.cuantosdecimales)}
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[5], width: this.state.tablastock.dimensiones[5], }}>
                                            ${formatoNumeroTecla(item.ieps_calculado, this.props.cuantosdecimales)}
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[6], width: this.state.tablastock.dimensiones[6], }}>
                                            ${formatoNumeroTecla(parseFloat(item.precio)+parseFloat(item.iva)+parseFloat(item.ieps_calculado), this.props.cuantosdecimales)}
                                          </td>
                                        </tr>
                                      ))}
                                      <tr style={{fontWeight:"bold", borderBottom: this.props.item.idestatuscotizacion == 2 && this.props.permisoautorizacion ? 'solid 1px #dee2e6' : 'solid 1px white' }}>
                                      <td /*key={"col-" + index + "-01"}*/ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px',
                                          minWidth: this.state.tablastock.mindimensiones[0], width: this.state.tablastock.dimensiones[0], }}>
                                           Totales acumulados
                                          </td>
                                          <td /*key={"col-" + index + "-02"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'center',
                                          minWidth: this.state.tablastock.mindimensiones[1], width: this.state.tablastock.dimensiones[1], }}>
                                           
                                          </td>
                                          <td /*key={"col-" + index + "-03"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[2], width: this.state.tablastock.dimensiones[2], }}>
                                           
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[3], width: this.state.tablastock.dimensiones[3], }}>
                                            $ {formatoNumeroTecla (this.props.item.totalprecios, this.props.cuantosdecimales)}
                                          </td>
                                           <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                           style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                           minWidth: this.state.tablastock.mindimensiones[4], width: this.state.tablastock.dimensiones[4], }}>
                                            ${formatoNumeroTecla(this.props.item.totalivas, this.props.cuantosdecimales)}
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[5], width: this.state.tablastock.dimensiones[5], }}>
                                            ${formatoNumeroTecla(this.props.item.totalieps, this.props.cuantosdecimales)}
                                          </td>
                                          <td /*key={"col-" + index + "-04"} */ className="letraNormal lblSubtabla" 
                                          style={{ color: this.state.colorcontenidotabla, paddinfRight: '8px', paddingLeft: '8px', textAlign: 'right',
                                          minWidth: this.state.tablastock.mindimensiones[6], width: this.state.tablastock.dimensiones[6], }}>
                                            ${formatoNumeroTecla(this.props.item.total, this.props.cuantosdecimales)}
                                            </td>
                                      </tr>
                                    </tbody>
                                  </Table>

                                </div>
                              </div>
                            </div>
                            {(this.props.item.idestatuscotizacion == 2 && this.props.permisoautorizacion) &&
                              <div className='row paddingEncabezados align-items-end ' style={{ padding: '20px 12px 0px 0px' }}>
                                <div className='col'></div>
                                <div className='col-auto paddingBottomEncabezados'>
                                  <Button className="btnRechazarProd" style={{ padding: '4px 12px' }} onClick={this.verModalRechazarCotizacion.bind(this, 1)}>
                                    Rechazar cotización
                                  </Button>
                                </div>
                                <div className='col-auto paddingBottomEncabezados'>
                                  <Button className="btnAceptarProd" style={{ padding: '4px 12px' }} onClick={this.verModalAutorizacion.bind(this, 4)}>
                                    Aprobar cotización
                                  </Button>
                                </div>
                              </div>}
                          </div>
                        </div>
                      </Box>
                    </Collapse>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

