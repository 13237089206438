import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';

import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';

export default class ModalFormularioLotesDisponibles extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,

            estilosmodal: estilosModal(),
           

            listalotesdisponibles: props.listalotesdisponibles ? props.listalotesdisponibles : [],
            listalotesseleccionados: [],
            listalotesagregados: this.props.rancho.lotes,
            listazonasduplicadas: [],


            tabla: {
                encabezados: [
                    "Selecciona un lote"
                ],
                dimensiones: [
                    "15%"
                ],
                mindimensiones: [
                    "100px",
                ],
                elementos: [],
            },
        }

    }
    async componentDidMount() {

    }

    

    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };


    continuarProceso = async () => {
        if(this.state.listalotesseleccionados.length > 0){
            

            var loterepetido=false;
            var listalotesrepetidos=[];
           
            this.state.listalotesseleccionados.forEach(element => {
                var itemfound = this.state.listalotesagregados.find(item => item.idlote == element.idcatalogo_ranchos_lotes_cultivo);
                if(itemfound != undefined){
                    listalotesrepetidos.push(element)
                    loterepetido = true; 
                }
            });
            if(!loterepetido){
                var listalotesagregadosaux = []
                this.state.listalotesseleccionados.forEach(element => {
                    var nuevolote= {
                        idlote: element.idcatalogo_ranchos_lotes_cultivo,
                        lote: element.lote,
                        eliminar: false,
                        tablas: []
                    }
                    listalotesagregadosaux.push(nuevolote);
                });
                
                this.showModal(listalotesagregadosaux)
            }else{
                
                await this.setState({
                    listalotesduplicadas: listalotesrepetidos
                })
                toast.info("Los lotes ya se encuentran agregados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
            }
        }else{
            toast.info("Favor de seleccionar al menos un lote", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
    }


    seleccionarItem = async (itemseleccionado) => {
        var listalotesseleccionadosaux = this.state.listalotesseleccionados;
        var itemfound = listalotesseleccionadosaux.find(item => item.idcatalogo_ranchos_lotes_cultivo == itemseleccionado.idcatalogo_ranchos_lotes_cultivo);

        if(itemfound == undefined){
            listalotesseleccionadosaux.push(itemseleccionado)
        }else{
            var index = listalotesseleccionadosaux.indexOf(itemseleccionado)
            if(index > -1){
                listalotesseleccionadosaux.splice(index,1)
            }
        }
        await this.setState({
            listalotesseleccionados: listalotesseleccionadosaux,
            confirmarenvio: true
        })
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal([])} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                        <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTabla tablaMaxHeight">
                                            <Table hover>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        <th key={"tabla" + 1} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorencabezadostabla }}>
                                                                Selecciona un lote:
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listalotesdisponibles.length > 0 ?
                                                        this.state.listalotesdisponibles.map((item, index) => (
                                                            (<tr key={"tabla" + index} onClick={() => this.seleccionarItem(item)}
                                                             className={this.props.rancho.lotes.find(lote => lote.idlote == item.idcatalogo_ranchos_lotes_cultivo) != undefined ? "trFondoInactivoGray" : this.state.listalotesseleccionados.find(selected => selected.idcatalogo_ranchos_lotes_cultivo == item.idcatalogo_ranchos_lotes_cultivo) == undefined ? "" : "trFondoActivoGreen"}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.lote}
                                                                </td>
                                                            </tr>)
                                                        )):<p>No hay lotes disponibles para este rancho</p> 

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal([])}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.continuarProceso()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

