import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioNuevaRequisicion.css";
import { estiloColorError,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasConEspacios,validarLetrasNumerosLongitud, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getMeses } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalSeleccionMeses extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            txtbuscador:"",
            estilosmodal: estilosModal(),
            messeleccionado: props.messeleccionado,
            meses:[],
            mostrarmodalconfirmacion: false,
            mensajeconfirmacion: "Se perderá la información de los meses que se hayan desmarcado.",
            mensajepreguntaconfirmacion: "",
            labelbotonconfirmacion: "Guardar",

            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
        }

    }
    async componentDidMount() {
      
        var respmeses = await getMeses();
        if ( this.manejadorLlamadasApis (respmeses) ) {
          return;
        }

       await this.setState({ meses: respmeses.data })
    }

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
          toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if (resp.code== 401) {
          this.setState({ salir: true })
          return true;
        }
        else if (resp.code == 400) {
          toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          this.setState({ loadingspinners: false })
          return true
        }else if(resp.code == 300){
          if(!this.state.errordeacceso){
            toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
          }
            
          this.setState({ 
            loadingspinners: false,
            errordeacceso:true })
          return true
        }
        return false
      }
    
    showModal = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    continuarProceso = async () => {
        if(this.state.messeleccionado != undefined){
            this.showModal(this.state.messeleccionado)
        }else{
            toast.info("Se requiere seleccionar un mes", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        
      
    }

    seleccionarMes = async (indexitemseleccionado) => {
        var mes = this.state.meses[indexitemseleccionado]

        await this.setState({
            messeleccionado: mes,
        })
      
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

           

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal(0)} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    {this.state.meses.map((mes, index) => (
                                        <div key={index} className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 paddingBottomEncabezados'>
                                        <Button
                                            className={ (this.state.messeleccionado != undefined && this.state.messeleccionado.id_mes == mes.id_mes) ? "btnMesActivo btnMes": "btnMesInactivo btnMes"}
                                            onClick={(e) => this.seleccionarMes(index)}
                                        >
                                            {mes.mes}
                                        </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    
                        <>
                            <br />
                            <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                            </div>
                            <br />
                            <div className="divBtnContenedorModal" >
                                <Button className="btnCancelModal" onClick={() => this.showModal(0)}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.continuarProceso()}  disabled={!this.state.confirmarenvio}>Aceptar</Button>
                            </div>
                        </>
                      

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

