import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalFormularioCotizacionNaranja.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { CgClose } from 'react-icons/cg';
import { postGuardarCatalogoCotizacionNaranja} from '../../../Services/Api';

export default class ModalFormularioCotizacionNaranja extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,
            accion:props.accion,

            fecha: '',
            txtfecha: '',
            txtcliente:0,

            vermsgerrorfecha: false,
            vermsgerrorcliente: false,

            msgerrorfecha: '',
            msgerrorcliente: "",

            listaclientes: props.listaclientes,
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

        }

    }


    async componentDidMount() {
       
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    cambiarFecha = (item) => {
        this.setState({ [item.llave]: item.valor })

        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    guardardatos = async () => {
        this.setState({ vermsgerrorcliente: false })
        this.setState({ vermsgerrorfecha: false })

        let validacionescorrectas = true

        if (this.state.txtcliente < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorcliente: "Seleccione un cliente" })
            this.setState({ vermsgerrorcliente: true })
        }

        if (this.state.txtfecha == "" || this.state.txtfecha == null) {
            validacionescorrectas = false;
            this.setState({ msgerrorfecha: "Selecciona una fecha" })
            this.setState({ vermsgerrorfecha: true })
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            var resp = undefined;

            let json = {
                "idcatalogo_cotizacion_naranja": 0,
                "fecha": this.state.txtfecha,
                "idcatalogo_clientes": this.state.txtcliente
            }

            resp = await postGuardarCatalogoCotizacionNaranja(json);

            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }

        }
    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cliente </p>
                                <div className='col lblSubTitulosModal '>
                                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                        <div className="selectBox ">
                                            <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtcliente")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                {this.state.listaclientes == undefined || this.state.listaclientes.length == 0 ? null : this.state.listaclientes.map((i, index) => (
                                                    <option value={i.idcatalogo_clientes} key={"tipo_" + i.idcatalogo_clientes}>{i.nombre}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {this.state.vermsgerrorcliente ?
                                    <p
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrorcliente}
                                    </p>
                                :null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha</p>
                                <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                    <DatePicker
                                        selected={this.state.fecha}
                                        onChange={(date) => this.cambiarFecha({ llave: "fecha", valor: date })}
                                        //dateFormat="dd/MM/yyyy"
                                        dateFormat="yyyy-MM-dd"
                                        locale="es"
                                        customInput={
                                            <DateButton />
                                        }
                                    />
                                </div>

                                {this.state.vermsgerrorfecha ?
                                    <p
                                        className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}
                                    >
                                        {this.state.msgerrorfecha}
                                    </p>
                                :null}
                            </div>
                        </div>

                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedorModal" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.guardardatos()} disabled={!this.state.confirmarenvio} >Continuar</Button>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }
}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686', width: '96%', margin: '0px 8px 18px 8px'
        }}
    >
        <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value : 'dd/mm/aaaa'}</label>
        <FaCalendar className="icoBotones" style={{ minWidth: '28px' }} />
    </button>
));
