import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './DetallesBitacoraCosecha.css';
import { Table } from 'reactstrap';
import { estiloComboBox, estiloFiltroEncabezados, estiloBtnNuevo,estiloBtnEditar } from '../../Services/PaletaDeColores';
import { BiEdit } from "react-icons/bi";
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal'
import { getListaLotesCortes, getListaCuadrillas,detallesBitacoraCosecha, getListaBitacoraTraslados, postDetalleBitacora, getListaTablaCultivos } from '../../Services/Api'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';
import ModalFormularioIngresoBitacoraCosecha from '../../Complements/Modales/ModalFormularioBitacoraCosechas/ModalFormularioIngresoBitacoraCosecha';

export default class DetallesBitacoraCosecha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      loadingspinners: true,
      errordeacceso: false,
      redireccionando: false,

      listalotes: [],
      listatablas: [],
      listacuadrillas: [],

      altura: window.innerHeight,
      anchura: window.innerWidth,

      //modales
      accionmodal: 1,
      titulomodal: '',
      itemeliminar: undefined,
      itemseleccionado: null,
      modaladdplancosecha: false,
      modalconfirmaraccion: false,

      informaciondetallescosecha: {
        bitacora: [],
        resumen: [],
        ordencosecha: {},
      },

      tablasplanes: {
        encabezados: [
          "Folio",
          "Folio traslado",
          "Lote",
          "Tabla",
          "Cuadrilla",
          "# Rejas",
          "En piso",
          "Tipo corte",
          "",
          ""
        ],
        encabezados2: [
          "Representante",
          "Total personas",
          "Tipo corte",
          "Cantidad",
          "Forma de pago"
        ],
        dimensiones: [
          "20%",
          "20%",
          "20%",
          "20%",
          "20%"
        ],
        mindimensiones: [
          "120px",
          "120px",
          "120px",
          "120px",
          "120px",
        ],
        maxdimensiones: [
          "300px",
          "300px",
          "300px",
          "300px",
          "300px",
        ],
        dimensiones2: [
          "8%",//Folio
          "12%",//Folio traslado
          "12%",//Lote
          "12%",//Tabla
          "10%",//Cuadrilla
          "10%",//#Rejas
          "10%",//En piso
          "10%",//Tipo corte
          "12%",//Editar
          "5%"//Eliminar
        ],
        mindimensiones2: [
          "90px",
          "140px",
          "120px",
          "100px",
          "100px",
          "100px",
          "100px",
          "120px",
          "120px",
          "80px"
        ],
        maxdimensiones2: [
          "100px",
          "200px",
          "200px",
          "200px",
          "200px",
          "200px",
          "200px",
          "200px",
          "200px"
        ]
      },

      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true })
    }

    ///////////////// Se recuperan y asignan datos de la sección de información general
    var ordencosechaaux = await localStorage.getItem('bitacoracosecha')
    var ordencosecha = JSON.parse(ordencosechaaux)

    var idcatalogo_bitacora_cosecha = ordencosecha.idcatalogo_bitacora_cosecha

    if (idcatalogo_bitacora_cosecha != null) {
      var respdetallescosecha = await detallesBitacoraCosecha({ "idcatalogo_bitacora_cosecha": idcatalogo_bitacora_cosecha });
      if (this.manejadorLlamadasApis(respdetallescosecha)) {
        return;
      }

      if (respdetallescosecha.code == 200) {
        await toast.error(respdetallescosecha.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        setTimeout(async () => {
          this.redireccionando();
        }, 3000);
        return;
      }

      var resplistalotes = await getListaLotesCortes();
      if (this.manejadorLlamadasApis(resplistalotes)) {
        return;
      }

      var respcatalogotablacultivos = await getListaTablaCultivos();
      if (this.manejadorLlamadasApis(respcatalogotablacultivos)) {
        return;
      }

      var respcatalogocuadrillas = await getListaCuadrillas();
      if (this.manejadorLlamadasApis(respcatalogocuadrillas)) {
        return;
      }

      var respbitacoratraslados = await getListaBitacoraTraslados({
        "fecha": ordencosecha.fecha
      });
      if (this.manejadorLlamadasApis(respbitacoratraslados)) {
        return;
      }

      var informaciondetallescosecha = this.state.informaciondetallescosecha
      informaciondetallescosecha = respdetallescosecha.data ? respdetallescosecha.data : {};
      informaciondetallescosecha.ordencosecha = ordencosecha;

      this.setState({
        informaciondetallescosecha: informaciondetallescosecha,
        listalotes: resplistalotes.data,
        listatablas: respcatalogotablacultivos.data,
        listacuadrillas: respcatalogocuadrillas.data,
        listatraslados: respbitacoratraslados.data
      });
    } else {
      this.redireccionando();
    }

    window.addEventListener("resize", this.dimensiones);
    this.dimensiones();
    this.setState({ loadingspinners: false })
  }

  redireccionando = () => {
    this.setState({ redireccionando: true })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setLoadingSpinner = (band) => {
    this.setState({ loadingspinners: band })
  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  verModal = (ban) => {
    if (ban.opcion == 1) { //Nuevo  ingreso bitácora
      this.setState({ titulomodal: 'Nueva bitácora', accionmodal: 1, modaladdplancosecha: true })
    }
    else if (ban.opcion == 2) { //Editar ingreso bitácora
      this.setState({ titulomodal: 'Editar bitácora', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)), modaladdplancosecha: true });
    }
    else if (ban.opcion == 3) { //confirmar accion eliminar
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "bitácora", modalconfirmaraccion: true, itemeliminar: ban.item });
    }
  }

  cerrarModalCosecha = (accion) => {
    this.setState({ modaladdplancosecha: !this.state.modaladdplancosecha })
    if (accion == 1) {
      this.setState({loadingspinners:false})
      toast.success("Bitácora creada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Bitácora editada exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmaraccion: !this.state.modalconfirmaraccion })

    if (elemento.accion == 1) {
      this.setState({ loadingspinners: true })
      if (elemento.plan == "bitácora") {

        let json = {
          "idcatalogo_bitacora_cosecha_detalles": elemento.item.idcatalogo_bitacora_cosecha_detalles,
          "idcatalogo_bitacora_cosecha": elemento.item,
          "idcatalogo_lotecultivo_tabla": elemento.item.idcatalogo_lotecultivo_tabla,
          "idcatalogo_bitacora_traslados_detalles": elemento.item.idcatalogo_bitacora_traslados,
          "idcatalogo_cuadrillas": elemento.item.idcatalogo_cuadrillas,
          "folio":elemento.item.folio,
          "rejas":elemento.item.rejas,
          "rejaspiso": elemento.item.rejaspiso,
          "eliminar": 1
        }
        var resp = await postDetalleBitacora(json);

      }

      if(resp.code == 0){
        this.setState({ loadingspinners: false })
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.componentDidMount();
      }
      else if(resp.code == 200){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 300){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
      else{
        this.setLoadingSpinner(false);
        toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
        :null}

        <ToastContainer/>

        {(this.state.redireccionando) ?
          (<Acciones bandera={'goreferencias'} />)
        :null}

        {(this.state.loadingspinners == true) ?
          (<SpinnerModal tipo="full" show={this.state.loadingspinners} />)
        :null}

        {(this.state.modaladdplancosecha == true) ?
          <ModalFormularioIngresoBitacoraCosecha
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModalCosecha}
            show={this.state.modaladdplancosecha}
            accion={this.state.accionmodal}
            listalotes={this.state.listalotes}
            listatablas={this.state.listatablas}
            listacuadrillas={this.state.listacuadrillas}
            listatraslados={this.state.listatraslados}
            idbitacoracosecha={this.state.informaciondetallescosecha.ordencosecha.idcatalogo_bitacora_cosecha}
            infodetalles={this.state.informaciondetallescosecha}
            item={this.state.itemseleccionado}
            fecha={this.state.informaciondetallescosecha.ordencosecha.fecha}
            setLoadingSpinner={this.setLoadingSpinner} />
        :null}

        {(this.state.modalconfirmaraccion == true) ?
          <ModalConfirarAccion
            titulomodal={this.state.titulomodal}
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmaraccion}
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
          />
        :null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/* ////////////////////  Sección de encabezazdo con información general //////////////////// */}
        <div className="container-fluid" >
          <div className="container-fluid anchoMaximo ">
            <div className='row paddingEncabezados align-items-end contenedorEncabezadoAutoCot'>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>{"Detalles bitácora cosecha"}</label>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Fecha: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.fecha}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Zona: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.zona}</label>
              </div>

              <div className='col-7 col-sm-7 col-md-7 containerSubTabla paddingBottomEncabezadoss'>
                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl, paddingLeft: '0px' }}>Rancho: </label>
                <label className='lblTexto letraTitulos' >{this.state.informaciondetallescosecha.ordencosecha.rancho}</label>
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
        <br />

        {/* ////////////////////   Sección de bitácora  ////////////////////////////////////// */}
        <div className="container-fluid containerTabla" style={{ margin: '0px', padding: '0px' }}>
          <div className="container-fluid anchoMaximoaa" style={{ margin: '0px', padding: '0px' }}>
            <div className="container-fluid containerTabla">

              <div className="container-fluid contenedorResumenAutoCot" style={{ padding: '10px 30px', minWidth: '1240px', maxWidth: '1240px' }}>
                <div className='row align-items-end' style={{ paddingBottom: '20px' }}>
                  <div className='col-10 minPaddingTopBottomAutoCot divMinWidthAutoCot'>
                    <label className='letraTitulos' style={{ paddingLeft: '8px' }}><b>Bitácora</b></label>
                  </div>
                  <div className='col-2 paddingBottomEncabezados  ' >
                    <button className='btnNuevo letraNormal'
                      style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                      onClick={this.verModal.bind(this, { opcion: "1" })}>+ Agregar</button>
                  </div>
                </div>

                {/* ////////////////////  Tabla de bitácora  ////////////////////////////////////// */}
                <div className="container-fluid anchoMaximo containerTabla">
                  <div className="container-fluid anchoMaximo2 ">
                    <div className="containerSubTabla">
                      <Table>
                        <thead style={{ verticalAlign: "bottom", borderBottom: 'solid 1px #91b9f9' }}>
                          <tr>
                            {this.state.tablasplanes.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{
                                  minWidth: this.state.tablasplanes.mindimensiones2[index],
                                  maxWidth: this.state.tablasplanes.maxdimensiones2[index],
                                  width: this.state.tablasplanes.dimensiones2[index],

                                  color: this.state.colorencabezadostabla
                                }}>
                                {titulo}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody style={{ verticalAlign: "bottom" }}>
                          {this.state.informaciondetallescosecha.data && this.state.informaciondetallescosecha.data.map((item, index) => (
                            <tr key={"tabla" + index}>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.folio}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.foliotraslado}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.lote}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.tabla}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.representante}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                                {this.format_numero(item.rejas)}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                                {this.format_numero(item.rejaspiso)}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ fontWeight: 'bold', color: this.state.colorcontenidotabla }}>
                                {item.tipocorte}
                              </td>
                              <td key={"col-" + index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                <button className='btnEditar' 
                                  style={{backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}} 
                                  onClick={this.verModal.bind(this, { opcion: "2", contenido: item })}
                                ><BiEdit className="icoBotones" /> Editar</button>
                              </td>
                              <td key={"col-" + index} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                <button className='btnEliminarFila' onClick={this.verModal.bind(this, { opcion: "3", item })}><FaTrashAlt className="icoBotones" /></button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <br />
      </>
    )
  }
}

{/*///////// Sección de acciones de redireccionamiento ////////*/}
function Acciones({ bandera }) {
  if (bandera == 'goreferencias') {
    return (<Redirect from='/' to={"/catalogoordencosecha"} />)
  } else if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
