import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoTipoClientes.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import { Table } from 'reactstrap';
import { coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnNuevo, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import ModalFormularioTipoClientes from '../../Complements/Modales/ModalFormularioTipoClientes/ModalFormularioTipoClientes';
import Paginacion, { buildContent } from '../../Complements/Paginacion/Paginacion';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus, getListaTipoCliente } from '../../Services/Api';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


export default class CatalogoTipoClientes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      altura: window.innerHeight,
      anchura: window.innerWidth,
      salir: false,
      loadingspinners: true,

      txtbuscador: '',
      listapaginada: [],
      filtrolistapaginada: [],
      elementosfiltrados: [],
      estatus: [],

      tabla: {
        encabezados: [
          "Tipo cliente",
          "Estatus",
          ""],
        dimensiones: [
          "90%",
          "10%",
          "150px"
        ],
        mindimensiones: [
          "100px",
          "100px",
          "150px"
        ],
        elementos: []
      },

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      errordeacceso: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
      fondotxtbuscar: coloresTxtBuscador().fondotxtbuscar,
      colortxtbuscar: coloresTxtBuscador().colortxtbuscar,
      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,

    }
  }

  async componentDidMount() {

    this.setState({ txtbuscador: '', })
    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
      return;
    }

    if (!this.state.loadingspinners) {
      this.setState({ loadingspinners: true });
    }

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis(estatus)) {
      return;
    }

    var resp = await getListaTipoCliente();
    if (this.manejadorLlamadasApis(resp)) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({ estatus: estatus.data, tabla: tabla });

    window.addEventListener("resize", this.dimensiones);
    this.dimensiones();
    this.cambiarContenido(1);
    this.setState({ loadingspinners: false })

  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso: false });
    this.componentDidMount();
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ loadingspinners: bandera });
  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        activepage: pagenumber,
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, array)
      });
    }, 0);
  }

  cambiarContenido = (pagenumber) => {
    setTimeout(() => {
      this.setState({
        activepage: pagenumber,
        listapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.tabla.elementos),
        filtrolistapaginada: buildContent(this.state.itemsperpage, pagenumber, this.state.elementosfiltrados)
      });
    }, 0);
  }

  saveInputLocacion = async (e) => {

    this.setState({ txtbuscador: e.target.value });

    if (e.target.value.trim().length >= 3) {
      var data1 = JSON.parse(JSON.stringify(this.state.tabla))
      var data = data1.elementos;
      const filtros = data.filter((item) => item.tipo.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

      if (filtros.length <= 0) {
        toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.filtro(1, filtros)
    }
    else {
      this.setState({ filtrolistapaginada: [] });
    }

  }

  limpiarInputBusqueda = () => {
    this.setState({ txtbuscador: '' })
    this.setState({ filtrolistapaginada: [] });
    this.cambiarContenido(1);
  }

  verModalDetalles = (ban) => {
    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Nuevo tipo cliente', accionmodal: 1 })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar tipo cliente', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)) });
    }
    this.setState({ modaldetalles: true });
  }

  cerrarModal = (accion) => {
    this.setState({ modaldetalles: !this.state.modaldetalles })
    if (accion == 1) {
      toast.success("Tipo creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
    else if (accion == 2) {
      toast.success("Tipo editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.componentDidMount();
    }
  }

  render() {
    return (
      <>
        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
        :null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        :null}

        {(this.state.modaldetalles == true) ?
          <ModalFormularioTipoClientes
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            estatus={this.state.estatus}
            show={this.state.modaldetalles}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            setLoadingSpinner={this.setLoadingSpinner}
          />
        : null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado y filtros ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end '>

              {/* ////////////////////  Título  ////////////////////////////////////// */}
              <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Tipo de clientes</label>
              </div>

              {/* ////////////////////   Filtro por tipo de cliente   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='txtBuscadorAncho'>
                  <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                  <input
                    id='Search'
                    type="search"
                    onChange={this.saveInputLocacion}
                    value={this.state.txtbuscador}
                    className="txtBuscador letraNormal"
                    style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar }}
                    placeholder="Buscar tipo cliente"
                  >
                  </input>
                  {this.state.txtbuscador ?
                    <IoMdClose
                      className='icoInputBuscarClose'
                      style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                      onClick={this.limpiarInputBusqueda}
                    />
                  :null}
                </div>
              </div>

              {/* ////////////////////   Separador de filtro y botones   ////////////////////////////////////// */}
              <div className='col containerSubTabla'></div>

              {/* ////////////////////   Botón de refrescar lista   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnActualizar letraNormal'
                  style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                  onClick={this.actualizarTabla}><RiRefreshLine /> Refrescar lista</button>
              </div>

              {/* ////////////////////   Botón de agregar registro   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <button className='btnNuevo letraNormal'
                  style={{ color: this.state.colorbtnnuevo, backgroundColor: this.state.fondobtnnuevo }}
                  onClick={this.verModalDetalles.bind(this, { opcion: "1" })}>+ Nuevo</button>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/* ////////////////////   Sección de tabla   ////////////////////////////////////// */}
        <div className="container-fluid containerTabla">
          <div className="container-fluid anchoMaximo ">
            <div className="containerSubTabla">

              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.txtbuscador.length < 3 ?

                    this.state.listapaginada.map((item, index) => (
                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                          >
                            <BiEdit className="icoBotones" />Editar
                          </button>
                        </td>
                      </tr>
                    ))
                  :
                    this.state.filtrolistapaginada.map((item, index) => (

                      <tr key={"tabla" + index}>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipo}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.estatus}
                        </td>
                        <td className="letraNormal > lblSubtablaBotones" >
                          <button
                            className='btnEditar'
                            onClick={this.verModalDetalles.bind(this, { opcion: "2", contenido: item })}
                            style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar}}
                          >
                            <BiEdit className="icoBotones" />Editar
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

            </div>
          </div>
        </div>

        {/* ////////////////////   Sección de paginación   ////////////////////////////////////// */}
        <div className="panelPaginationSection">
          <Paginacion
            activepage={this.state.activepage}
            itemscountperpage={this.state.itemsperpage}
            totalitemscount={this.state.txtbuscador.length < 3 ? this.state.tabla.elementos : this.state.elementosfiltrados}
            pagerangerisplayed={this.state.rangepages}
            cambiarcontenido={(e) => this.cambiarContenido(e)}
          />
        </div>
      </>
    )
  }
}

{/* ////////////////////   Función de redireccionamiento   ////////////////////////////////////// */}
function Acciones({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
