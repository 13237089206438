import React from 'react'
import './CatalogoNuevaRequisicion.css';
import { FaTrashAlt } from 'react-icons/fa';
import {Table,TableBody,TableCell,TableContainer,Paper,TableHead,TableRow,Collapse,Box,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { estiloBtnAgregar,coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla,estilosModal } from '../../Services/PaletaDeColores';
import ModalFormularioTablasDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioTablasDisponibles';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getListaTablaCultivos } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import Tabla from './Tabla';
import 'react-toastify/dist/ReactToastify.css';


export default class Lote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      accion: props.accion,
      titulomodal: "",

      lote: this.props.itemlote,
      
      colapsartabla:true,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,

      estilosmodal: estilosModal(),
    }
  }

  async componentDidMount() {
    this.dimensiones();
   
    window.addEventListener("resize", this.dimensiones);
    
  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }
  
  actualizarContenido = (tabla) => {
    var listatablas = this.props.itemlote.tablas;

    var index =  listatablas.findIndex((obj => obj.idtabla == tabla.idtabla));
    if(index > -1){
      listatablas[index] = tabla;
    }

    var auxlote = this.state.lote;
    auxlote.tablas = listatablas;

    this.props.actualizarContenido(auxlote);
  }

  eliminarElemento = async (elementoeliminado) => {
    //Se ejecuta proceso de eliminación de seleccionados

    var tablas =  this.props.itemlote.tablas.filter(tabla => tabla.idtabla != elementoeliminado.idtabla);
    
    var auxlote = this.state.lote;
    auxlote.tablas = tablas;

    this.props.actualizarContenido(auxlote);
  }
  
  render() {
    return (

      <>    
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}

            <div className="marginTopSeparator">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                          <div className='row flexNoWrap'>
                            <div className='col-auto minPaddingTopBottom'>
                              <button className='btnCollapsar letraNormal'
                                style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                                onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                            </div>
                            <div className='col-auto minPaddingTopBottom d-flex align-items-center'>
                              <p className='sinMargen'><b>Lote:</b>{this.props.itemlote.lote}</p>
                            </div>
                          </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={this.state.colapsartabla} timeout="auto" >
                          <Box margin={1}>
                            {this.props.itemlote.tablas.length > 0 ?
                              this.props.itemlote.tablas.map((item) => (
                                <div className='spacePaddingBottom'>
                                <Tabla key={"tabla_" + item.idtabla} 
                                accion={this.state.accion} 
                                itemtabla={item} 
                                actualizarContenido={this.actualizarContenido}
                                eliminarElemento={this.eliminarElemento}
                                listaprodresumen={this.props.listaprodresumen}
                                />
                                </div>
                              ))
                              : <div></div>
                            }
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
