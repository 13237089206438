import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { IoMdClose } from 'react-icons/io';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioAlertasAlmacen.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud, validarNumerosDecimales } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaAlertasAlmacenUpdate } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import ModalFormularioBuscarProductos from '../ModalFormularioInventarioAlmacen/ModalFormularioBuscarProductos';

export default class ModalFormularioAlertasAlmacen extends React.Component {

    constructor(props) {
        super(props)
   

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,
            listaproductoscatalogo: props.listaproductoscatalogo,
            listaproductosalmacen: props.listaproductosalmacen,
            listafamilia: props.listafamilia,
            listatipoproductos: props.listatipoproductos,
            listaproductosparabusqueda: [],
            txtfamiliaproducto: (props.accion == 2) ? (props.item.familiaproducto) : '',
            txttipoproducto: (props.accion == 2) ? (props.item.tipoproducto) : '',
            txtproducto: (props.accion == 2) ? (props.item.nombreproducto) : '',
            txtcantidadminima: (props.accion == 2) ? (props.item.cantidadminima) : '',
            txtumbralnotificacion: (props.accion == 2) ? (props.item.umbralnotificacion) : '',
            idproductoseleccionado: (props.accion == 2) ? (props.item.idproducto_inventario) : '',
            arrayestatus: props.estatus,
            idestatus: 1,
            vermsgerrorfamiliaproducto: false,
            vermsgerrortipoproducto: false,
            vermsgerrorproducto: false,
            vermsgerrorcantidadminima: false,
            vermsgerrorumbralnotificacion: false,
            msgerror: '',
            msgerrorfamiliaproducto: '',
            msgerrortipoproducto: "",
            msgerrorproducto: '',
            msgerrorcantidadminima: '',
            msgerrorumbralnotificacion: '',
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

        }

    }
    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
        this.prodalmacentocatalogo()
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }
    
    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrorfamiliaproducto: false })
        this.setState({ vermsgerrortipoproducto: false })
        this.setState({ vermsgerrorproducto: false })
        this.setState({ vermsgerrorcantidadminima: false })
        this.setState({ vermsgerrorumbralnotificacion: false })

        let longitudvalida = "";
        let correcto = "";
        let validacionescorrectas = true

        longitudvalida = {longitudminima: 1, longitudmaxima: 50};
        correcto = validarLetrasNumerosLongitud(this.state.txtfamiliaproducto.trim(), longitudvalida);
        
        if (correcto.codigo == "invalid") {
            this.setState({ msgerrorfamiliaproducto: correcto.mensaje })
            this.setState({ vermsgerrorfamiliaproducto: true })
            validacionescorrectas = false;
        }

        correcto = validarLetrasNumerosLongitud(this.state.txttipoproducto.trim(), longitudvalida);
        if (correcto.codigo == "invalid") {
            this.setState({ msgerrortipoproducto: correcto.mensaje })
            this.setState({ vermsgerrortipoproducto: true })
            validacionescorrectas = false;
        }

        correcto = validarLetrasNumerosLongitud(this.state.txtproducto.trim(), longitudvalida);
        if (correcto.codigo == "invalid") {
            this.setState({ msgerrorproducto: correcto.mensaje })
            this.setState({ vermsgerrorproducto: true })
            validacionescorrectas = false;
        }

        correcto = validarNumerosDecimales(this.state.txtcantidadminima.trim(), longitudvalida);
        if (correcto.codigo == "invalid") {
            this.setState({ msgerrorcantidadminima: correcto.mensaje })
            this.setState({ vermsgerrorcantidadminima: true })
            validacionescorrectas = false;
        }

        correcto = validarNumerosDecimales(this.state.txtumbralnotificacion.trim(), longitudvalida);
        if (correcto.codigo == "invalid") {
            this.setState({ msgerrorumbralnotificacion: correcto.mensaje })
            this.setState({ vermsgerrorumbralnotificacion: true })
            validacionescorrectas = false;
        }else{
            if(parseFloat(this.state.txtumbralnotificacion)<parseFloat(this.state.txtcantidadminima)){
                this.setState({ msgerrorumbralnotificacion: "Ell umbral de notificación no puede ser menor que la cantidad mínima" })
                this.setState({ vermsgerrorumbralnotificacion: true })
                validacionescorrectas = false;
            }
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            let json = {
                "idalertaproductoinventario": this.state.accion == 1 ? ("0") : (this.props.item.idalertaproductoinventario),
                "idcatalogoalmacen":this.props.idinventario,
                "idcatalogoproducto": this.state.idproductoseleccionado,
                "cantidadminima": this.state.txtcantidadminima,
                "umbralnotificacion": this.state.txtumbralnotificacion,
                "idestatus": this.state.idestatus
            }
            var resp = await getListaAlertasAlmacenUpdate(json);
            if (resp == -1 || resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return true
              }
            else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return true
            }
            else {
                toast.success("Alerta guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                this.showModal('1')
            }
        }

    }

    vermodal = () => {
        this.setState({ modaldetalles: !this.state.modaldetalles })
    }

    cerrarModal = async (ban) => {
        if (ban == 0 || ban == undefined) {
            this.setState({ modaldetalles: !this.state.modaldetalles })
        }
        else {
            this.setState({ txtfamiliaproducto: ban.familiaproducto })
            this.setState({ txttipoproducto: ban.tipoproducto })
            this.setState({ txtproducto: ban.nombrecomercial })
            var producto = this.state.listaproductosalmacen.filter((item) => item.idcatalogoproducto==ban.idcatalogoproducto)
            
            await this.setState({ idproductoseleccionado: producto[0].idcatalogoproducto })
            

        }

    }

    filtrarProductosdeAlmacen(respproductos){
        var productosdealmacen = [];

        this.state.listaproductosalmacen.forEach(productoalmacenrecibido => {
            var producto = respproductos.filter((productogeneral) => productogeneral.idcatalogoproducto == productoalmacenrecibido.idcatalogoproducto)
            if(producto != undefined){
                productosdealmacen = productosdealmacen.concat(producto)
            }
        });

        return productosdealmacen;
    }

    prodalmacentocatalogo = async () => {
        var auxproductos = this.filtrarProductosdeAlmacen(this.state.listaproductoscatalogo)
        await this.setState({ listaproductosparabusqueda: auxproductos })
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                {(this.state.modaldetalles == true) ? (
                    <ModalFormularioBuscarProductos 
                        titulomodal={this.state.titulomodal} 
                        cerrarModal={this.cerrarModal}
                        show={this.state.modaldetalles} 
                        accion={this.state.accionmodal} 
                        todoslosproductosalmacen={true} 
                        respproductos={this.state.listaproductosparabusqueda} 
                        respfamiliaproductos={this.state.listafamilia}
                        resptipoproductos={this.state.listatipoproductos}
                        item={this.state.itemseleccionado}
                        listaproductosalmacen={this.state.listaproductosalmacen} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                            {this.state.accion == 1 ? (
                                    <button className='btnNuevo letraNormal' onClick={this.vermodal}
                                        style={{
                                            color: this.state.colorbtnnuevo,
                                            backgroundColor: this.state.fondobtnnuevo,
                                            border: this.state.borderbtnnuevo,
                                            position: 'relative'
                                        }}>

                                        <BiSearchAlt2 className='icoLupa'
                                            style={{
                                                top: '7px',
                                                paddingRight: '2px',
                                                color: this.state.colorlupa,
                                                stroke: this.state.colorlupa
                                            }} />
                                        {"Buscar producto"}
                                    </button>
                                ) : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Familia producto</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' disabled style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtfamiliaproducto} onChange={this.escribir("txtfamiliaproducto")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfamiliaproducto}</label>}

                                {this.state.vermsgerrorfamiliaproducto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfamiliaproducto}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo producto</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' disabled style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txttipoproducto} onChange={this.escribir("txttipoproducto")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txttipoproducto}</label>}

                                {this.state.vermsgerrortipoproducto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipoproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Producto</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' disabled style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtproducto} onChange={this.escribir("txtproducto")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtproducto}</label>}

                                {this.state.vermsgerrorproducto ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorproducto}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cantidad mínima (kg)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtcantidadminima} onChange={this.escribir("txtcantidadminima")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtcantidadminima}</label>}

                                {this.state.vermsgerrorcantidadminima ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcantidadminima}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Umbral de notificación (kg)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtumbralnotificacion} onChange={this.escribir("txtumbralnotificacion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtumbralnotificacion}</label>}

                                {this.state.vermsgerrorumbralnotificacion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorumbralnotificacion}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

