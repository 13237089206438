import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { FaTrashAlt } from 'react-icons/fa'
import { BiEdit } from "react-icons/bi";
//import "./ModalBuscarProducto.css";
import { estiloColorError, estiloComboBox, estiloBtnEditar, estilosModal, coloresTxtBuscador } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import ModalCarruselEvidencias from './ModalCarruselEvidencias';

import { BsFillEyeFill } from "react-icons/bs";

const todoslosfiltrosdisponibles = -1
const opcionsinespecificar = -2

export default class ModalNotasEvidenciasOrdenTrabajo extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      modalnotasformulario: false,

      tabla: {
        encabezados: [
          "Título",
          "Descripción",
          "Evidencias",
        ],
        dimensiones: [
          "25%",
          "60%",
          "15%",
        ],
        mindimensiones: [
          "200px",
          "200px",
          "80px"
        ],
        elementos: props.listanotas,
      },
     colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
      colorlupa: coloresTxtBuscador().colorlupa,
      fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar,


      modalevidencias:false,
    }

  }



  showModal = () => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal();
    }, 200);

  }

  verEvidencias = (item) =>{
    this.setState({ itemevidencias:item.evidencias, modalevidencias:true,})
  }

  cerrarModalEvidencias = () => {
this.setState({modalevidencias:false})
  }
 

  render() {
    return (

      <>
       
        {this.state.modalevidencias ? (
          <ModalCarruselEvidencias 
         
            cerrarModal={this.cerrarModalEvidencias}
            show={this.state.modalevidencias}
           
            evidencias={this.state.itemevidencias}
          />
        ) : null }

        <ToastContainer />
        <Modal isOpen={this.state.show}
          size="lg"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">Notas y evidencias</label>

            <br />
            <br />
            <div className="divCuerpoModal">

              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla">
                  <div className="container-fluid anchoMaximo ">
                    <div className="containerSubTabla">
                      <Table hover>
                        <thead style={{ verticalAlign: "bottom" }}>
                          <tr>
                            {this.state.tabla.encabezados.map((titulo, index) => (
                              <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>


                               {titulo}
                                
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>

                       
                          {this.state.tabla.elementos.map((item, index) => (
                            <tr key={"tabla_nota_" + index}                            >
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0], color: this.state.colorcontenidotabla }}>
                                {item.titulo}
                              </td>
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], color: this.state.colorcontenidotabla }}>
                                {item.descripcion}
                              </td>
                              {this.props.idestatus !=4 ? 
                              <td className="letraNormal lblSubtabla" style={{ minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], color: this.state.colorcontenidotabla }}>
                                <button className='btnEliminarFila'

                                  onClick={() => this.verEvidencias(item)}
                                  style={{
                                    backgroundColor: this.state.btnfondoeditar,
                                    color: this.state.btncoloreditar,
                                    marginRight: "10px"
                                  }}>
                                  <BsFillEyeFill className="icoBotones" /> Ver ({item.evidencias.length}) </button>
                               
                              </td>
                              :null 
  }
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>

                    </div>
                  </div>
                </div>
              </div>
            </div>

           
          </ModalBody>

        </Modal>
      </>
    )
  }

}

