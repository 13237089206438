import React from 'react'
import { Button, Collapse, Modal, ModalBody, Table } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare, BsTrash } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa'
import "./ModalAgregarProductos.css";

import { estiloBtnNuevo, estiloBtnActualizar, estiloColorError, estiloComboBox, estilosModal, estiloContenedores } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import SpinnerModal from "../SpinnerModal/SpinnerModal";
import ReactTooltip from "react-tooltip";
import {
  getListaAlmacen, getListaAlmacenes, getListaTipoMovimientoInventario,
  getListaMovimientoInventario, getAlmacenesInformaciónGuardar,
  getComboFamiliaProducto, getListaTipoProductosGeneral, getListaProductosGenerales,
  getAlmacenesGeneralesInformación
} from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiSearchAlt2 } from 'react-icons/bi';
import ModalBuscarProducto from './ModalBuscarProducto';

export default class ModalproductosGenerales extends React.Component {

  constructor(props) {
    super(props)
    //console.log("ModalproductosGenerales", props)
    this.state = {
      show: props.show,
      confirmarenvio: true,
      modalrequisicion: false,
      loadingspinners: false,
      modaldetalles: false,
      accion: 1, // 1 Nuevo, 2 editar
      titulo: props.titulomodal,
      arrayestatus: props.estatus,


      tabla: {
        encabezados: [
          "Producto",
          "Unidad de medida",
          "Cantidad",
          "Eliminar"
        ],
        dimensiones: [
          "40%",
          "10%",
          "18%",
          "12%"
        ],
        mindimensiones: [
          "108px",
          "200px",
          "108px",
          "100px"
        ],
        elementos: [],//dumyarrayproducts,//[],
      },
      infoalmacen: {},//JSON.parse(JSON.stringify(this.props.datosinventarioalmacen)),
      listaproductosalmacen: [],//JSON.parse(JSON.stringify(this.props.datosinventarioalmacen.productos)),
      todoslosproductosalmacen: true,
      listaalmacen: [],
      filtrolistapaginada: [],
      txtbuscador: '',
      idtipomovimiento: -1,
      idmovimiento: -1,
      vermsgerroridtipomovimiento: false,
      msgerroridtipomovimiento: '',
      vermsgerroridmovimiento: false,
      msgerroridmovimiento: '',
      vermsgerroralmacendestino: false,
      msgerroralmacendestino: '',
      btntexto: 'Buscar',
      origen: '#MOD Dato a cambiar',//this.props.datosinventarioalmacen.nombrealmacen,
      idelegiralmacendestino: -1,
      txtcomentario: "Este es un comentario default",

      modalbuscarproductos: false,

      respfamiliaproductos: [],
      resptipoproductos: [],
      respproductos: [],

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,
      borderbtnnuevo: estiloBtnNuevo().borderbtnnuevo,

      bordeinputcomentario: estiloContenedores().colorbordenegro,
      fondobtnbuscar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnbuscar: estiloBtnActualizar().colorbtnactualizar

    }

  }
  async componentDidMount() {

    this.setState({ loadingspinners: true })
    await this.apisproductos();

    //agregar lista de productos en caso de que tenga
    let x = this.state.tabla
    if (this.props.productosseleccionados.length > 0) {
      x.elementos = this.props.productosseleccionados
      this.setState({ tabla: x })
    }
    await this.setState({
      // listaalmacen: respcatalogoalmacenes.data,
      loadingspinners: false
    })



  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(accion);
    }, 200);

  }
  eliminar = (pos) => {
    /*var tablainfoalmacen = this.state.infoalmacen;
    var array = tablainfoalmacen.productos;
    array.splice(pos, 1);
    this.setState({ infoalmacen: tablainfoalmacen })

    var tabla = this.state.tabla;
    tabla.elementos = tablainfoalmacen.productos;
    this.setState({ tabla: tabla })*/
    var tabla = this.state.tabla;
    var elementos = tabla.elementos
    elementos.splice(pos, 1)
    tabla.elementos = elementos
    this.setState({ tabla: tabla })

  };

  

  guardarCambios = async () => {

    /*this.setState({ vermsgerroridmovimiento: false })
    this.setState({ vermsgerroridtipomovimiento: false })
    this.setState({ vermsgerroralmacendestino: false })*/
    let validacionescorrectas = true
   

    var arryatemp = this.state.tabla;
    var temp = arryatemp.elementos;
    let datoscero = 0;
    for (let i = 0; i < temp.length; i++) {
      temp[i].fondo = "white";
      if (temp[i].cantidad == undefined || temp[i].cantidad <= 0) {
        temp[i].fondo = "#fa9393";
        validacionescorrectas = false;
        datoscero = 1;

      }
    }
    arryatemp.elementos = temp
    this.setState({ tabla: arryatemp })

    if (datoscero == 1) {
      toast.error("Escriba una cantida mayor a cero", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
      return;
    }
    if (validacionescorrectas) {
      // toast.error("Pendiente a implementar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
    }
  

    //  console.log("productos seleccionados", this.state.tabla.elementos)
    this.showModal(this.state.tabla.elementos)
  }

  cerrarModal = (ban) => {
    if (ban == 0 || ban == undefined) {
      this.setState({ modaldetalles: !this.state.modaldetalles })
    }
    else {
      var tabla = this.state.tabla;
      var productoalmacen = tabla.elementos.filter((item) => item.idcatalogoproducto == ban.idcatalogoproducto)
      if (productoalmacen.length > 0) {
        toast.info("Este producto ya está en la lista", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
      } else {
        tabla.elementos.push(ban)
        this.setState({ tabla: tabla })
      }
      this.setState({ modaldetalles: !this.state.modaldetalles })
    }
  }

  vermodal = () => {

    this.setState({ vermsgerroridtipomovimiento: false })
    if (this.state.btntexto === 'Requisición') {
      this.setState({ modalrequisicion: !this.state.modalrequisicion })
    }
    else {

      if (this.state.idtipomovimiento == -1) {
        this.setState({ msgerroridtipomovimiento: "Seleccione un tipo de movimiento" })
        this.setState({ vermsgerroridtipomovimiento: true })
        return;
      }
      this.setState({ modaldetalles: !this.state.modaldetalles });

    }
  }
  elegiralmacen = (id) => {
    this.setState({ idelegiralmacendestino: id })
  }

  changecanti = async (e) => {

    if (e.target.value.trim().length < 10 && e.target.value.trim().length >= 0) {
      var arryatemp = this.state.tabla;
      var temp = arryatemp.elementos;
      temp[e.target.id].cantidad = e.target.value;
      this.setState({ tabla: arryatemp })
    }

  }


  apisproductos = async () => {

    if (this.state.respfamiliaproductos.length == 0 || this.state.resptipoproductos.length == 0 || this.state.respproductos.length == 0) {

      var respfamiliaproductos = await getComboFamiliaProducto();
      if (this.manejadorLlamadasApis(respfamiliaproductos)) {
        return;
      }

      var resptipoproductos = await getListaTipoProductosGeneral();
      if (this.manejadorLlamadasApis(resptipoproductos)) {
        return;
      }

      var respproductos = await getListaProductosGenerales();
      if (this.manejadorLlamadasApis(respproductos)) {
        return;
      }


      /*  var respalmacenproductos = await getAlmacenesGeneralesInformación(1);
        if (this.manejadorLlamadasApis(respalmacenproductos)) {
          return;
        }*/

      this.setState({
        respfamiliaproductos: respfamiliaproductos.data,
        resptipoproductos: resptipoproductos.data,
        respproductos: respproductos.data
      })
    }

  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  verModalBuscarProductos = () => {
    /*this.setState({ vermsgerroridtipomovimiento: false })
    if (this.state.btntexto === 'Requisición') {
      this.setState({ modalrequisicion: !this.state.modalrequisicion })
    } else {*/
    /*if (this.state.idtipomovimiento == -1) {
      this.setState({ msgerroridtipomovimiento: "Seleccione un tipo de movimiento" })
      this.setState({ vermsgerroridtipomovimiento: true })
      return;
    }*/
    this.setState({ titulomodal: 'Buscar productos', accionmodal: 1, modalbuscarproductos: true });
    //}
  }

  cerrarModalBuscarProductos = (ban) => {
    if (!(ban == 0) && !(ban == undefined)) {
      var tabla = this.state.tabla
      var nueva = []
      var idsregistrados = []
      tabla.elementos.forEach(item => idsregistrados.push(item.idcatalogo_producto_general))
      ban.forEach(el => {

        let x = tabla.elementos.find(x => x.idcatalogo_producto_general == el.idcatalogo_producto_general)
        if (x != undefined) {
          nueva.push(x)
        } else {
          nueva.push({
            "idcatalogo_producto_general": el.idcatalogo_producto_general,
            "clave": el.clave,
            "nombrecomercial": el.nombrecomercial,
            "idtipo_producto_general": el.idtipo_producto_general,
            "tipoproducto_general": el.tipoproducto_general,
            "idcatalogo_unidadmedida": el.idcatalogo_unidadmedida,
            "claveunidadmedida": el.claveunidadmedida,
            "unidadmedida": el.unidadmedida,
            "descripcion": el.descripcion,
            "idestatus": el.idestatus,
            "estatus": el.estatus,
            "fechacreacion": el.fechacreacion,
            "fechaactualizacion": el.fechaactualizacion,
            "cantidad": 0.00
          })
        }


      });
      /*Object.values(ban)
        .filter(item1 => idsregistrados.includes(item1.idcatalogo_producto_general))
        .forEach(item => nueva.push(item))

        Object.values(ban)
        .filter(item1 => !idsregistrados.includes(item1.idcatalogo_producto_general))
        .forEach(item => nueva.push(item))
*/
      tabla.elementos = nueva
      this.setState({ tabla: tabla })
    }
    this.setState({ modalbuscarproductos: !this.state.modalbuscarproductos })
  }


  render() {
    return (

      <>

        {/*(this.state.modalrequisicion == true) ? (
          <ModalRequisicion cerrarmodal={this.vermodal} titulomodal={"Requisiciones"} show={this.state.modalrequisicion} />
        ) : null*/}

        {(this.state.modalbuscarproductos == true) ? (
          <ModalBuscarProducto titulomodal={this.state.titulomodal} cerrarModal={this.cerrarModalBuscarProductos}
            productosseleccionados={this.state.tabla.elementos}
            todoslosproductosalmacen={this.state.todoslosproductosalmacen} listaproductosalmacen={this.state.listaproductosalmacen}
            show={this.state.modalbuscarproductos} accion={this.state.accionmodal} item={this.state.itemseleccionado}
            respfamiliaproductos={this.state.respfamiliaproductos} resptipoproductos={this.state.resptipoproductos}
            respproductos={this.state.respproductos}
          />
        ) : null}


        <ToastContainer />
        {(this.state.loadingspinners == true) ? (
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        ) : null}
        <Modal isOpen={this.state.show}
          size="xl"
          aria-labelledby=""
          centered>

          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>
            <br />
            <br />

            <div className='row' style={{ margin: '16px' }}>
              <div className='col'>
                <label className="modalLblTitulo letraTitulos">Productos seleccionados:</label>
              </div>
              <div className='col-auto'>
                <button className='btnNuevo letraNormal' onClick={this.verModalBuscarProductos}
                  style={{
                    color: this.state.colorbtnbuscar,
                    backgroundColor: this.state.fondobtnbuscar,
                    border: this.state.borderbtnnuevo,
                    position: 'relative'
                  }}>
                  <b>+</b> {this.state.btntexto}
                </button>
              </div>
            </div>

            <div className="divCuerpoModal">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                <Table>

                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr /*style={{ borderTop: 'solid 2px #a1a1a1' }}*/>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                          style={{
                            minWidth: this.state.tabla.mindimensiones[index],
                            width: this.state.tabla.dimensiones[index],
                            color: this.state.colorencabezadostabla,
                            textAlign: (index > 0) ? 'center' : ''
                          }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>

                    <> {
                      this.state.tabla.elementos.map((item, index) => (
                        <tr key={"tabla" + index} style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1', background: item.fondo }}>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[0], width: this.state.tabla.dimensiones[0] }}>
                            {item.nombrecomercial}

                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[1], width: this.state.tabla.dimensiones[1], textAlign: 'center' }}>
                            {item.claveunidadmedida}
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[2], width: this.state.tabla.dimensiones[2], textAlign: 'center' }}>
                            <input id={index} type="number" onChange={this.changecanti} value={item.cantidad}
                              className="txtcanti letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.bordertxtbuscar, maxWidth: '120px' }}
                              placeholder="00.00"></input>
                          </td>
                          <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[3], width: this.state.tabla.dimensiones[3], textAlign: 'center', padding: '3px 16px' }}>
                            <button className='btnEliminarFila' onClick={this.eliminar.bind(this, index)}/*onClick={() => this.seleccionarElementoaEliminar()}*/>
                              <FaTrashAlt className="icoBotones" />
                            </button>
                          </td>

                        </tr>
                      ))}
                      <tr style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1' }} ></tr>
                      <tr style={{ borderStyle: 'none', borderTop: 'solid 2px #a1a1a1' }} ></tr>
                    </>

                  </tbody>

                </Table>

              </div>
            </div>

            {this.state.accion == 2 || this.state.accion == 1 ? (
              <>
                <div className="divBtnContenedor" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio}>Asignar</Button>
                </div>
              </>
            ) : null}

          </ModalBody>

        </Modal>
      </>
    )
  }

}

