import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { estiloColorError, estilosModal, estiloFiltroEncabezados, estiloComboBox } from '../../../Services/PaletaDeColores';

import { Table } from 'reactstrap';

const todoslosfiltrosdisponibles = -1

export default class ModalRancho extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      titulo: props.titulomodal,
      item: props.item,
      accion: props.accion,

      listaoperacionesestatus: props.listaranchos, 
      idoperacionseleccionada: 0,      
      idestatusactual: props.item.idestatusrequisicion,
      idseleccionado: '',
      idzonaseleccionada: todoslosfiltrosdisponibles,
      filtrolistapaginada: [],

      colorfondotipo: estiloComboBox().colorfondocombo,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      estilosmodal: estilosModal(),

      itemseleccionado: null
    }

  }
  async componentDidMount() {
   
  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  showModal = (opcion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarModal(opcion);
    }, 200);
  }

  escribir = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  }

  seleccionarOperacion = async (item) => {
    this.setState({ idoperacionseleccionada: item.idcatalogo_rancho })
    this.setState({ itemseleccionado: item })
   
  }

  filtrarCombo = async (idzona) => {

    await this.setState({ idzonaseleccionada: idzona })

    var filtroranchoscultivo = this.filtrarBusqueda(
      idzona)

    if (filtroranchoscultivo.length <= 0) {
      toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
    }

    this.filtro(1, filtroranchoscultivo)

  }

  filtrarBusqueda = (idzona) => {

    var tabla = JSON.parse(JSON.stringify(this.state.listaoperacionesestatus))
    var filtrozonasdisponibles = tabla 

    if (idzona != todoslosfiltrosdisponibles) {
      filtrozonasdisponibles = filtrozonasdisponibles.filter((item) => item.idcatalogo_zona == idzona)
    }

    return filtrozonasdisponibles 

  }

  filtro = (pagenumber, array) => {
    setTimeout(() => {
      this.setState({
        elementosfiltrados: array,
        filtrolistapaginada: array
      });
    }, 0);
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>
            <div>
              <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
              <label className="modalLblTitulo letraTitulos">{this.state.titulo} </label>
            </div>

            <br />

            <div className="divModalEliminar">
              <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                <p className='nombreRequisicionModal letraTitulos' >{this.state.item.nombre}</p>

                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Selecciona un rancho </p>
                <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                  <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                    <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona</label>
                    <div className="selectBox ">
                      <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                        onChange={(event) => this.filtrarCombo(event.target.value)}>
                        <option selected={this.state.idzonaseleccionada == todoslosfiltrosdisponibles} value={todoslosfiltrosdisponibles}>Todas</option>
                        {this.props.listazonas.map((i, index) => (
                          <option value={i.idcatalogo_zona} key={"tipo_" + index} selected={i.idcatalogo_zona == this.state.idzonaseleccionada} >{i.zona} </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <br />
                <p className='lblSubTitulosModal letraTitulos' style={{ color: 'black' }}>Ranchos</p>
                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                <br />
                <div className="container-fluid containerTabla" style={{ padding: '0px', margin: '0px' }}>
                  <div className="container-fluid anchoMaximo" style={{ padding: '0px', margin: '0px' }}>
                    <div className="containerSubTablaAA" style={{ padding: '0px', margin: '0px' }}>
                      <Table hover>
                        <thead>
                          <tr>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody style={{ verticalAlign: "bottom" }}>
                          {(this.state.idzonaseleccionada == todoslosfiltrosdisponibles) ?
                            this.state.listaoperacionesestatus
                              .map((item, index) => (
                                <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion(item)}
                                  className={this.state.idoperacionseleccionada == item.idcatalogo_rancho ? "trFondoActivoGreen" : ""}>
                                  <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                    {item.rancho}
                                  </td>
                                </tr>
                              ))
                            :
                            this.state.filtrolistapaginada.map((item, index) => (
                              <tr key={"tabla" + index} onClick={() => this.seleccionarOperacion(item)}
                                className={this.state.idoperacionseleccionada == item.idcatalogo_rancho ? "trFondoActivoGreen" : ""}>
                                <td className="letraNormal lblSubtabla listaOpcionesEstatus" style={{ color: this.state.colorcontenidotabla, textAlign: 'center' }}>
                                  {item.rancho}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                <br />
                <div className="divBtnContenedorModal spacePaddingBottom" >
                  <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                  <Button className="btnAceptarModal"
                    disabled={this.state.itemseleccionado ? false : true}
                    onClick={() => this.showModal({ accion: 1, item: this.state.itemseleccionado })}>
                    Continuar
                  </Button>
                </div>

              </div>
            </div>

          </ModalBody>

        </Modal >
      </>
    )
  }
}
