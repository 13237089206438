import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { MdCloudUpload } from 'react-icons/md';
import { BsQuestionSquare, BsTelephoneFill } from 'react-icons/bs';
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import {
  validarLetrasNumerosPuntoComa, validarLetrasNumerosEspacios, validarSoloLetrasNumeros,
  validarRFC, validarTelefono2, validarCodigoPostal, validateEmail, validarLetrasConEspacios
} from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import {
  getListaCatalogoClienteUpdate, getListaClienteDatosFiscalesUpdate, getListaClienteContactosUpdate,
  getListaClienteDireccionUpdate, getListaClienteDocumentoUpdate
} from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ladapaises } from '../ModalFormularioVentanaProveedor/Util';
import './ModalFormularioClientes.css';

const todoslosfiltrosdisponibles = -1

export default class ModalFormularioInformacionClientes extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      //////////////////// Definición de datos para tabla: Información general ////////////////////
      txtclabe: (props.accion == 1) ? (props.item.clave) : '',
      vermsgerrorclabe: false,
      msgerrorclabe: "",

      txtnombrecomercial: (props.accion == 1) ? (props.item.nombre) : '',
      vermsgerrorcomercial: false,
      msgerrorcomercial: "",

      vermsgtipocliente: false,
      msgtipocliente: "",
      idtipocliente: (props.accion == 1) ? (props.item.idtipo_cliente) : todoslosfiltrosdisponibles,
      arraytipo: props.arraytipos,

      txtdescripcion: (props.accion == 1) ? (props.item.comentario) : '',
      vermsgerrordescripcion: false,
      msgerrordescripcion: "",

      txtestatusproveedor: (props.accion == 1) ? (props.item.estatus) : 'Activo',
      arrayestatus: props.estatus,
      //////////////////// Termina definición de datos para tabla: Información general ////////////////////

      //////////////////// Empieza definición de datos para Tabla: Datos Fiscales ////////////////////
      //txtpersona: (props.accion == 2) ? (props.item.persona ? props.item.persona : 'FISICA') : '',
      txtpersona: (props.accion == 2) ?
        (((!props.item.apellidos && !props.item.nombrerazonsocial) || props.item.apellidos) ? 'FISICA' : 'MORAL') : '',
      vermsgerrortxtpersona: false,
      msgerrortxtpersona: '',

      txtapellidosp: (props.accion == 2) ? (props.item.apellidos ? props.item.apellidos : '') : '',
      vermsgerrortxtapellidosp: false,
      msgerrortxtapellidosp: '',

      txtrazonsocial: (props.accion == 2) ? (props.item.nombrerazonsocial ? props.item.nombrerazonsocial : '') : '',
      vermsgerrortxtrazonsocial: false,
      msgerrortxtrazonsocial: '',

      txtrfc: (props.accion == 2) ? (props.item.rfc ? props.item.rfc : '') : '',
      vermsgerrortxtrfc: false,
      msgerrortxtrfc: '',

      txtregimen: (props.accion == 2) ? (props.item.regimenfiscal ? props.item.regimenfiscal : '') : '',
      vermsgerrortxtregimen: false,
      msgerrortxtregimen: '',

      txtcodigopostal: (props.accion == 2) ? (props.item.codigopostal ? props.item.codigopostal : '') : '',
      vermsgerrortxtcodigopostal: false,
      msgerrortxtcodigopostal: '',

      txttipovialidad: (props.accion == 2) ? (props.item.tipovialidad ? props.item.tipovialidad : '') : '',
      vermsgerrortxttipovialidad: false,
      msgerrortxttipovialidad: '',

      txtnombrevialidad: (props.accion == 2) ? (props.item.nombrevialidad ? props.item.nombrevialidad : '') : '',
      vermsgerrortxtnombrevialidad: false,
      msgerrortxtnombrevialidad: '',

      txtexteriordf: (props.accion == 2) ? (props.item.numeroexterior ? props.item.numeroexterior : '') : '',
      vermsgerrortxtexteriordf: false,
      msgerrortxtexteriordf: '',

      txtnointeriordf: (props.accion == 2) ? (props.item.numerointerior ? props.item.numerointerior : '') : '',
      vermsgerrortxtnointeriordf: false,
      msgerrortxtnointeriordf: '',

      txtcoloniadf: (props.accion == 2) ? (props.item.colonia ? props.item.colonia : '') : '',
      vermsgerrortxtcoloniadf: false,
      msgerrortxtcoloniadf: '',

      txtlocalidad: (props.accion == 2) ? (props.item.localidad ? props.item.localidad : '') : '',
      vermsgerrortxtlocalidad: false,
      msgerrortxtlocalidad: false,

      txtnombremunicipio: (props.accion == 2) ? (props.item.municipio ? props.item.municipio : '') : '',
      vermsgerrortxtnombremunicipio: false,
      msgerrortxtnombremunicipio: false,

      txtentidadfederativa: (props.accion == 2) ? (props.item.estado ? props.item.estado : '') : '',
      vermsgerrortxtentidadfederativa: false,
      msgerrortxtentidadfederativa: false,

      txtentrecalle: (props.accion == 2) ? (props.item.entrecalleuno ? props.item.entrecalleuno : '') : '',
      vermsgerrortxtentrecalle: false,
      msgerrortxtentrecalle: false,

      txtycalle: (props.accion == 2) ? (props.item.entrecalledos ? props.item.entrecalledos : '') : '',
      vermsgerrortxtycalle: false,
      msgerrortxtycalle: false,
      //////////////////// Termina definición de datos para Tabla: Datos Fiscales ////////////////////

      //////////////////// Empieza definición de datos para Tabla: Contactos ////////////////////
      txtnombrecompleto: (props.accion == 4) ? (props.item.nombre) : '',
      vermsgerrortxtnombrecompleto: false,
      msgerrortxtnombrecompleto: '',

      txtpuesto: (props.accion == 4) ? (props.item.puesto) : '',
      vermsgerrortxtpuesto: false,
      msgerrortxtpuesto: '',

      idpais: (props.accion == 4) ?
        ((props.item.idlada && ladapaises.find(item => item.lada == props.item.idlada)) ?
          ladapaises.find(item => item.lada == props.item.idlada).idpais : -1) : -1,
      vermsgerrortxtlada: false,
      msgerrortxtlada: '',

      txttelefono: (props.accion == 4) ?
        ((props.item.telefono && (props.item.telefono.length >= 10)) ?
          props.item.telefono.substring(props.item.telefono.length - 10, props.item.telefono.length) : props.item.telefono) : '',
      vermsgerrortxttelefono: false,
      msgerrortxttelefono: '',

      txtemail: (props.accion == 4) ? (props.item.email) : '',
      vermsgerrortxtemail: false,
      msgerrortxtemail: '',

      txtcomentariocontacto: (props.accion == 4) ? (props.item.comentario) : '',
      vermsgerrortxtcomentariocontacto: false,
      msgerrortxtcomentariocontacto: '',

      contactoprincipal: (props.accion == 4) ? (props.item.principal == 1 ? true : false) : false,
      vermsgerrorcontactoprincipal: false,
      msgerrorcontactoprincipal: '',
      //////////////////// Termina definición de datos para Tabla: Contactos ////////////////////

      //////////////////// Empieza la definición de datos para la Tabla Direcciones ////////////////////
      txtcalle: (props.accion == 6) ? (props.item.calle) : '',
      vermsgerrortxtcalle: false,
      msgerrortxtcalle: '',

      txtexterior: (props.accion == 6) ? (props.item.numeroexterior) : '',
      vermsgerrortxtexterior: false,
      msgerrortxtexterior: '',

      txtnointerior: (props.accion == 6) ? (props.item.numerointerior) : '',
      vermsgerrortxtnointerior: false,
      msgerrortxtnointerior: '',

      txtcolonia: (props.accion == 6) ? (props.item.colonia) : '',
      vermsgerrortxtcolonia: false,
      msgerrortxtcolonia: '',

      txtcp: (props.accion == 6) ? (props.item.codigopostal) : '',
      vermsgerrortxtcp: false,
      msgerrortxtcp: '',

      txtlocalidaddir: (props.accion == 6) ? (props.item.localidad) : '',
      vermsgerrortxtlocalidaddir: false,
      msgerrortxtlocalidaddir: false,

      txtmunicipio: (props.accion == 6) ? (props.item.municipio) : '',
      vermsgerrortxtmunicipio: false,
      msgerrortxtmunicipio: '',

      txtestado: (props.accion == 6) ? (props.item.estado) : '',
      vermsgerrortxtestado: false,
      msgerrortxtestado: '',

      txtpais: (props.accion == 6) ? (props.item.pais) : '',
      vermsgerrortxtpais: false,
      msgerrortxtpais: '',

      txtreferencia: (props.accion == 6) ? (props.item.referencia) : '',
      vermsgerrortxtreferencia: false,
      msgerrortxtreferencia: '',

      direccionprincipal: (props.accion == 6) ? (props.item.principal == 1 ? true : false) : false,
      vermsgerrordireccionprincipal: false,
      msgerrordireccionprincipal: '',
      //////////////////// Termina la definición de datos para la Tabla Direcciones ////////////////////

      //////////////////// Empieza la definición de datos para la Tabla Documentos ////////////////////
      archivocargado: null,
      txtnombrearchivo: 'Buscar',
      vermsgerrortxtarchivo: false,
      msgerrortxtarchivo: '',

      idtipodocumento: todoslosfiltrosdisponibles,
      vermsgerrortxttipodocumento: false,
      msgerrortxttipodocumento: '',

      idcatalogodocumento: todoslosfiltrosdisponibles,
      vermsgerrortxtcatalogodocumento: false,
      msgerrortxtcatalogodocumento: '',

      txtdescripciondoc: '',
      vermsgerrortxtdescripciondoc: false,
      msgerrortxtdescripciondoc: '',
      //////////////////// Termina la definición de datos para la Tabla Documentos ////////////////////

      // Datos generales
      show: props.show,
      accion: props.accion, // 1 - 8
      titulo: props.titulomodal,
      confirmarenvio: false,
      idestatus: 1,

      spersonas: [
        {
          id: "FISICA",
          nombre: "Persona fisica"
        },
        {
          id: "MORAL",
          nombre: "Persona moral"
        }
      ],

      colormsgerror: estiloColorError().msgerror,
      estilosmodal: estilosModal(),
      colorbordertipo: estiloComboBox().colorbordercombo,

    }

  }

  async componentDidMount() {

    if (this.props.accion == 1) {
      this.cambiarEstatus((this.props.accion == 1) ? (this.props.item.idestatus) : this.state.idestatus)
    }

  }

  setLoadingSpinner = (bandera) => {
    this.props.setloadingspinner(bandera);
  }

  showModal = (accion) => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.cerrarmodal(accion);
    }, 200);
  }

  escribir = (prop) => (e) => {

    let dato = e.target.value;

    if (prop == "txtclabe") {
      this.setState({ [prop]: e.target.value.toUpperCase() });
    }

    else if (prop == "txtrfc") {
      this.setState({ [prop]: e.target.value.toUpperCase() });
    }

    else if (prop == "txtnointerior" || prop == "txtexterior") {
      if (dato.length > 10) {
        e.target.value = dato.slice(0, 10);
        this.setState({ [prop]: dato.slice(0, 10) });
      }
      else {
        this.setState({ [prop]: e.target.value });
      }
    }
    else {
      this.setState({ [prop]: e.target.value });
    }

  }

  cambiarTipo = (item) => {
    if (item.tipo == "idtipodocumento") {
      this.setState({ [item.tipo]: item.valor, idcatalogodocumento: todoslosfiltrosdisponibles });
    }
    else if (['FISICA', 'MORAL'].includes(item)) {
      this.setState({ txtpersona: item });
    }
    else {
      this.setState({ [item.tipo]: item.valor });
    }
  }

  cambiarEstatus = (id) => {
    var array = this.state.arrayestatus;
    array.forEach(function (item) {
      item.activo = false;
      if (item.idestatus == id) {
        item.activo = true;
      }
    });
    this.setState({ idestatus: id })
  }

  guardarCambios = async () => {

    let validacionescorrectas = true
    if (this.props.accion == 1) {

      this.setState({ vermsgerrorclabe: false })
      this.setState({ vermsgerrorcomercial: false })
      this.setState({ vermsgtipocliente: false })
      this.setState({ vermsgerrordescripcion: false })

      let longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
      let clabe = validarSoloLetrasNumeros(this.state.txtclabe.trim(), longitudvalida);
      if (clabe.codigo == "invalid") {
        this.setState({ msgerrorclabe: clabe.mensaje })
        this.setState({ vermsgerrorclabe: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtnombrecomercial = validarLetrasNumerosEspacios(this.state.txtnombrecomercial.trim(), longitudvalida);
      if (txtnombrecomercial.codigo == "invalid") {
        this.setState({ msgerrorcomercial: txtnombrecomercial.mensaje })
        this.setState({ vermsgerrorcomercial: true })
        validacionescorrectas = false;
      }

      if (this.state.idtipocliente == todoslosfiltrosdisponibles) {
        this.setState({ msgtipocliente: "Selecciona un tipo de proveedor" })
        this.setState({ vermsgtipocliente: true })
        validacionescorrectas = false;
      }

      if (this.state.txtdescripcion.trim().length > 0) {
        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
        if (descorrecto.codigo == "invalid") {
          this.setState({ msgerrordescripcion: descorrecto.mensaje })
          this.setState({ vermsgerrordescripcion: true })
          validacionescorrectas = false;
        }
      }

    }
    /////////////////////////////////////////////////////////////////////////////
    if (this.props.accion == 2) {

      this.setState({ vermsgerrortxtrazonsocial: false })
      this.setState({ vermsgerrortxtapellidosp: false })
      this.setState({ vermsgerrortxtrfc: false })
      this.setState({ vermsgerrortxtregimen: false })
      this.setState({ vermsgerrortxtcodigopostal: false })
      this.setState({ vermsgerrortxttipovialidad: false })
      this.setState({ vermsgerrortxtnombrevialidad: false })
      this.setState({ vermsgerrortxtnointeriordf: false })//opcional
      this.setState({ vermsgerrortxtexteriordf: false })
      this.setState({ vermsgerrortxtcoloniadf: false })
      this.setState({ vermsgerrortxtlocalidad: false })
      this.setState({ vermsgerrortxtnombremunicipio: false })
      this.setState({ vermsgerrortxtentidadfederativa: false })
      this.setState({ vermsgerrortxtentrecalle: false })//opcional
      this.setState({ vermsgerrortxtycalle: false })//opcional


      let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      if (this.state.txtpersona != 'MORAL') {
        let txtrazonsocial = validarLetrasConEspacios(this.state.txtrazonsocial.trim(), longitudvalida);
        if (txtrazonsocial.codigo == "invalid") {
          this.setState({ msgerrortxtrazonsocial: txtrazonsocial.mensaje })
          this.setState({ vermsgerrortxtrazonsocial: true })
          validacionescorrectas = false;
        }

        let txtapellidosp = validarLetrasConEspacios(this.state.txtapellidosp.trim(), longitudvalida);
        if (txtapellidosp.codigo == "invalid") {
          this.setState({ msgerrortxtapellidosp: txtapellidosp.mensaje })
          this.setState({ vermsgerrortxtapellidosp: true })
          validacionescorrectas = false;
        }
      } else {
        let txtrazonsocial = validarLetrasConEspacios(this.state.txtrazonsocial.trim(), longitudvalida);
        if (txtrazonsocial.codigo == "invalid") {
          this.setState({ msgerrortxtrazonsocial: txtrazonsocial.mensaje })
          this.setState({ vermsgerrortxtrazonsocial: true })
          validacionescorrectas = false;
        }
      }

      /////////////////////////////////////////////////////
      let txtrfc = validarRFC(this.state.txtrfc.trim(), (this.state.txtpersona != 'MORAL') ? 13 : 12)
      if (txtrfc.codigo == "invalid") {
        this.setState({ msgerrortxtrfc: txtrfc.mensaje })
        this.setState({ vermsgerrortxtrfc: true })
        validacionescorrectas = false;
      }

      /////////////////////////////////////////////////////
      longitudvalida = { longitudminima: 1, longitudmaxima: 200 };
      let txtregimen = validarLetrasNumerosEspacios(this.state.txtregimen.trim(), longitudvalida)
      if (txtregimen.codigo == "invalid") {
        this.setState({ msgerrortxtregimen: txtregimen.mensaje })
        this.setState({ vermsgerrortxtregimen: true })
        validacionescorrectas = false;
      }

      /////////////////////////////////////////////////////
      let txtcodigopostal = validarCodigoPostal(this.state.txtcodigopostal.trim())
      if (txtcodigopostal.codigo == "invalid") {
        this.setState({ msgerrortxtcodigopostal: txtcodigopostal.mensaje })
        this.setState({ vermsgerrortxtcodigopostal: true })
        validacionescorrectas = false;
      }

      /////////////////////////////////////////////////////
      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txttipovialidad = validarLetrasNumerosEspacios(this.state.txttipovialidad.trim(), longitudvalida)
      if (txttipovialidad.codigo == "invalid") {
        this.setState({ msgerrortxttipovialidad: txttipovialidad.mensaje })
        this.setState({ vermsgerrortxttipovialidad: true })
        validacionescorrectas = false;
      }

      /////////////////////////////////////////////////////
      let txtnombrevialidad = validarLetrasNumerosEspacios(this.state.txtnombrevialidad.trim(), longitudvalida)
      if (txtnombrevialidad.codigo == "invalid") {
        this.setState({ msgerrortxtnombrevialidad: txtnombrevialidad.mensaje })
        this.setState({ vermsgerrortxtnombrevialidad: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
      let txtexteriordf = validarLetrasNumerosEspacios(this.state.txtexteriordf.trim(), longitudvalida)
      if (txtexteriordf.codigo == "invalid") {
        this.setState({ msgerrortxtexteriordf: txtexteriordf.mensaje })
        this.setState({ vermsgerrortxtexteriordf: true })
        validacionescorrectas = false;
      }

      if (this.state.txtnointeriordf.trim().length > 0) {
        let txtnointeriordf = validarLetrasNumerosEspacios(this.state.txtnointeriordf.trim(), longitudvalida)
        if (txtnointeriordf.codigo == "invalid") {
          this.setState({ msgerrortxtnointeriordf: txtnointeriordf.mensaje })
          this.setState({ vermsgerrortxtnointeriordf: true })
          validacionescorrectas = false;
        }
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtcoloniadf = validarLetrasNumerosEspacios(this.state.txtcoloniadf.trim(), longitudvalida)
      if (txtcoloniadf.codigo == "invalid") {
        this.setState({ msgerrortxtcoloniadf: txtcoloniadf.mensaje })
        this.setState({ vermsgerrortxtcoloniadf: true })
        validacionescorrectas = false;
      }

      let txtlocalidad = validarLetrasNumerosEspacios(this.state.txtlocalidad.trim(), longitudvalida)
      if (txtlocalidad.codigo == "invalid") {
        this.setState({ msgerrortxtlocalidad: txtlocalidad.mensaje })
        this.setState({ vermsgerrortxtlocalidad: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtnombremunicipio = validarLetrasNumerosEspacios(this.state.txtnombremunicipio.trim(), longitudvalida)
      if (txtnombremunicipio.codigo == "invalid") {
        this.setState({ msgerrortxtnombremunicipio: txtnombremunicipio.mensaje })
        this.setState({ vermsgerrortxtnombremunicipio: true })
        validacionescorrectas = false;
      }

      let txtentidadfederativa = validarLetrasNumerosEspacios(this.state.txtentidadfederativa.trim(), longitudvalida)
      if (txtentidadfederativa.codigo == "invalid") {
        this.setState({ msgerrortxtentidadfederativa: txtentidadfederativa.mensaje })
        this.setState({ vermsgerrortxtentidadfederativa: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      if (this.state.txtentrecalle.trim().length > 0) {
        let txtentrecalle = validarLetrasNumerosEspacios(this.state.txtentrecalle.trim(), longitudvalida);
        if (txtentrecalle.codigo == "invalid") {
          this.setState({ msgerrortxtentrecalle: txtentrecalle.mensaje })
          this.setState({ vermsgerrortxtentrecalle: true })
          validacionescorrectas = false;
        }
      }

      if (this.state.txtycalle.trim().length > 0) {
        let txtycalle = validarLetrasNumerosEspacios(this.state.txtycalle.trim(), longitudvalida);
        if (txtycalle.codigo == "invalid") {
          this.setState({ msgerrortxtycalle: txtycalle.mensaje })
          this.setState({ vermsgerrortxtycalle: true })
          validacionescorrectas = false;
        }
      }

    }
    /////////////////////////////////////////////////////////////////////////////
    if (this.props.accion == 3 || this.props.accion == 4) {

      this.setState({ vermsgerrortxtpuesto: false })
      this.setState({ vermsgerrortxtnombrecompleto: false })
      this.setState({ vermsgerrortxtlada: false })
      this.setState({ vermsgerrortxttelefono: false })
      this.setState({ vermsgerrortxtemail: false })
      this.setState({ vermsgerrortxtcomentariocontacto: false })
      this.setState({ vermsgerrorcontactoprincipal: false })


      let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtnombrecompleto = validarLetrasNumerosEspacios(this.state.txtnombrecompleto.trim(), longitudvalida)
      if (txtnombrecompleto.codigo == "invalid") {
        this.setState({ msgerrortxtnombrecompleto: txtnombrecompleto.mensaje })
        this.setState({ vermsgerrortxtnombrecompleto: true })
        validacionescorrectas = false;
      }

      if (this.state.txtpuesto.trim().length > 0) {
        let txtpuesto = validarLetrasNumerosEspacios(this.state.txtpuesto.trim(), longitudvalida)
        if (txtpuesto.codigo == "invalid") {
          this.setState({ msgerrortxtpuesto: txtpuesto.mensaje })
          this.setState({ vermsgerrortxtpuesto: true })
          validacionescorrectas = false;
        }
      }

      // idpais asocia tanto a la lada como al país que pertenece
      if (this.state.idpais < 0) {
        this.setState({ msgerrortxtlada: "Selecciona una lada" })
        this.setState({ vermsgerrortxtlada: true })
        validacionescorrectas = false;
      }

      let txttelefono = validarTelefono2(this.state.txttelefono.trim())
      if (txttelefono.codigo == "invalid") {
        this.setState({ msgerrortxttelefono: txttelefono.mensaje })
        this.setState({ vermsgerrortxttelefono: true })
        validacionescorrectas = false;
      }

      if (this.state.txtemail.trim().length > 0) {
        let txtemail = validateEmail(this.state.txtemail.trim())
        if (txtemail.codigo == "invalid") {
          this.setState({ msgerrortxtemail: txtemail.mensaje })
          this.setState({ vermsgerrortxtemail: true })
          validacionescorrectas = false;
        }
      }

      if (this.state.txtcomentariocontacto.trim().length > 0) {
        longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let descorrecto = validarLetrasNumerosPuntoComa(this.state.txtcomentariocontacto.trim(), longitudvalida);
        if (descorrecto.codigo == "invalid") {
          this.setState({ msgerrortxtcomentariocontacto: descorrecto.mensaje })
          this.setState({ vermsgerrortxtcomentariocontacto: true })
          validacionescorrectas = false;
        }
      }

      if (this.state.contactoprincipal != true && this.props.item != null && this.props.item.principaltabla == -1) {
        this.setState({ msgerrorcontactoprincipal: "El primer elemento debe ser principal" })
        this.setState({ vermsgerrorcontactoprincipal: true })
        validacionescorrectas = false;
      }
      else if (this.state.contactoprincipal != true && this.props.item.idcatalogo_clientes_contactos == this.props.item.principaltabla) {
        this.setState({ msgerrorcontactoprincipal: "Debe existir al menos un elemento principal" })
        this.setState({ vermsgerrorcontactoprincipal: true })
        validacionescorrectas = false;
      }

    }
    /////////////////////////////////////////////////////////////////////////////
    if (this.props.accion == 5 || this.props.accion == 6) {

      this.setState({ vermsgerrortxtcalle: false })
      this.setState({ vermsgerrortxtnointerior: false })
      this.setState({ vermsgerrortxtexterior: false })
      this.setState({ vermsgerrortxtcolonia: false })
      this.setState({ vermsgerrortxtlocalidaddir: false })
      this.setState({ vermsgerrortxtmunicipio: false })
      this.setState({ vermsgerrortxtcp: false })
      this.setState({ vermsgerrortxtestado: false })
      this.setState({ vermsgerrortxtreferencia: false })
      this.setState({ vermsgerrortxtpais: false })
      this.setState({ vermsgerrordireccionprincipal: false })

      let longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtcalle = validarLetrasNumerosEspacios(this.state.txtcalle.trim(), longitudvalida)
      if (txtcalle.codigo == "invalid") {
        this.setState({ msgerrortxtcalle: txtcalle.mensaje })
        this.setState({ vermsgerrortxtcalle: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 10 };
      let txtexterior = validarLetrasNumerosEspacios(this.state.txtexterior.trim(), longitudvalida)
      if (txtexterior.codigo == "invalid") {
        this.setState({ msgerrortxtexterior: txtexterior.mensaje })
        this.setState({ vermsgerrortxtexterior: true })
        validacionescorrectas = false;
      }

      if (this.state.txtnointerior.trim().length > 0) {
        let txtnointerior = validarLetrasNumerosEspacios(this.state.txtnointerior.trim(), longitudvalida)
        if (txtnointerior.codigo == "invalid") {
          this.setState({ msgerrortxtnointerior: txtnointerior.mensaje })
          this.setState({ vermsgerrortxtnointerior: true })
          validacionescorrectas = false;
        }
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtcolonia = validarLetrasNumerosEspacios(this.state.txtcolonia.trim(), longitudvalida)
      if (txtcolonia.codigo == "invalid") {
        this.setState({ msgerrortxtcolonia: txtcolonia.mensaje })
        this.setState({ vermsgerrortxtcolonia: true })
        validacionescorrectas = false;
      }

      let txtlocalidaddir = validarLetrasNumerosEspacios(this.state.txtlocalidaddir.trim(), longitudvalida)
      if (txtlocalidaddir.codigo == "invalid") {
        this.setState({ msgerrortxtlocalidaddir: txtlocalidaddir.mensaje })
        this.setState({ vermsgerrortxtlocalidaddir: true })
        validacionescorrectas = false;
      }

      let txtcp = validarCodigoPostal(this.state.txtcp.trim())
      if (txtcp.codigo == "invalid") {
        this.setState({ msgerrortxtcp: txtcp.mensaje })
        this.setState({ vermsgerrortxtcp: true })
        validacionescorrectas = false;
      }

      let txtmunicipio = validarLetrasNumerosEspacios(this.state.txtmunicipio.trim(), longitudvalida)
      if (txtmunicipio.codigo == "invalid") {
        this.setState({ msgerrortxtmunicipio: txtmunicipio.mensaje })
        this.setState({ vermsgerrortxtmunicipio: true })
        validacionescorrectas = false;
      }

      longitudvalida = { longitudminima: 1, longitudmaxima: 50 };
      let txtestado = validarLetrasNumerosEspacios(this.state.txtestado.trim(), longitudvalida)
      if (txtestado.codigo == "invalid") {
        this.setState({ msgerrortxtestado: txtestado.mensaje })
        this.setState({ vermsgerrortxtestado: true })
        validacionescorrectas = false;
      }

      let txtpais = validarLetrasNumerosEspacios(this.state.txtpais.trim(), longitudvalida)
      if (txtpais.codigo == "invalid") {
        this.setState({ msgerrortxtpais: txtpais.mensaje })
        this.setState({ vermsgerrortxtpais: true })
        validacionescorrectas = false;
      }

      if (this.state.txtreferencia.trim().length > 0) {
        longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtreferencia = validarLetrasNumerosEspacios(this.state.txtreferencia.trim(), longitudvalida);
        if (txtreferencia.codigo == "invalid") {
          this.setState({ msgerrortxtreferencia: txtreferencia.mensaje })
          this.setState({ vermsgerrortxtreferencia: true })
          validacionescorrectas = false;
        }
      }

      if (this.state.direccionprincipal != true && this.props.item.principaltabla == -1) {
        this.setState({ msgerrordireccionprincipal: "El primer elemento debe ser principal" })
        this.setState({ vermsgerrordireccionprincipal: true })
        validacionescorrectas = false;
      }
      else if (this.state.direccionprincipal != true && this.props.item.idcatalogo_clientes_direcciones == this.props.item.principaltabla) {
        this.setState({ msgerrordireccionprincipal: "Debe existir al menos un elemento principal" })
        this.setState({ vermsgerrordireccionprincipal: true })
        validacionescorrectas = false;
      }

    }
    /////////////////////////////////////////////////////////////////////////////
    if (this.props.accion == 7) {

      this.setState({ vermsgerrortxtarchivo: false })
      this.setState({ vermsgerrortxtcatalogodocumento: false })
      this.setState({ vermsgerrortxttipodocumento: false })
      this.setState({ vermsgerrortxtdescripciondoc: false })

      if (this.state.archivocargado == null) {
        this.setState({ msgerrortxtarchivo: "Selecciona un archivo" })
        this.setState({ vermsgerrortxtarchivo: true })
        validacionescorrectas = false;
      }

      if (this.state.idtipodocumento == todoslosfiltrosdisponibles) {
        this.setState({ msgerrortxttipodocumento: "Selecciona un tipo" })
        this.setState({ vermsgerrortxttipodocumento: true })
        validacionescorrectas = false;
      }

      if (this.state.idcatalogodocumento == todoslosfiltrosdisponibles) {
        this.setState({ msgerrortxtcatalogodocumento: "Selecciona un documento" })
        this.setState({ vermsgerrortxtcatalogodocumento: true })
        validacionescorrectas = false;
      }

      if (this.state.txtdescripciondoc.trim().length > 0) {
        let longitudvalida = { longitudminima: 1, longitudmaxima: 100 };
        let txtdescripciondoc = validarLetrasNumerosPuntoComa(this.state.txtdescripciondoc.trim(), longitudvalida);
        if (txtdescripciondoc.codigo == "invalid") {
          this.setState({ msgerrortxtdescripciondoc: txtdescripciondoc.mensaje })
          this.setState({ vermsgerrortxtdescripciondoc: true })
          validacionescorrectas = false;
        }
      }

    }


    if (validacionescorrectas) {
      this.setLoadingSpinner(true);
      var resp = {};
      var json = {};

      if (this.props.accion == 1) {
        json = {
          "idcatalogo_clientes": this.props.item.idcatalogo_clientes,
          "clave": this.state.txtclabe,
          "idtipocliente": this.state.idtipocliente,
          "nombre": this.state.txtnombrecomercial,
          "comentario": this.state.txtdescripcion,
          "idestatus": this.state.idestatus
        }
        resp = await getListaCatalogoClienteUpdate(json);
      }
      else if (this.props.accion == 2) {
        json = {
          "idcatalogo_clientes_datosfiscales": this.props.item.idcatalogo_clientes_datosfiscales ? this.props.item.idcatalogo_clientes_datosfiscales : 0,
          "idcatalogo_clientes": this.props.item.idcatalogo_clientes,
          "nombrerazonsocial": this.state.txtrazonsocial, //txtnombresp
          "apellidos": this.state.txtpersona == 'FISICA' ? this.state.txtapellidosp : undefined, //this.state.txtpersona == 'FISICA' ? this.state.txtapellidosp : '',
          "rfc": this.state.txtrfc,
          "regimenfiscal": this.state.txtregimen,
          "codigopostal": this.state.txtcodigopostal,
          "tipovialidad": this.state.txttipovialidad,
          "nombrevialidad": this.state.txtnombrevialidad,
          "numeroexterior": this.state.txtexteriordf,
          "numerointerior": this.state.txtnointeriordf,
          "colonia": this.state.txtcoloniadf,
          "localidad": this.state.txtlocalidad,
          "municipio": this.state.txtnombremunicipio,
          "estado": this.state.txtentidadfederativa,
          "entrecalleuno": this.state.txtentrecalle,
          "entrecalledos": this.state.txtycalle
        }
        resp = await getListaClienteDatosFiscalesUpdate(json);
      }
      else if (this.props.accion == 3 || this.props.accion == 4) {
        json = {
          idcatalogo_clientes_contactos: this.props.item.idcatalogo_clientes_contactos ? this.props.item.idcatalogo_clientes_contactos : 0,
          idcatalogo_clientes: this.props.item.idcatalogo_clientes,
          nombre: this.state.txtnombrecompleto,
          puesto: this.state.txtpuesto,// (opcional)
          telefono: ladapaises[this.state.idpais].lada + this.state.txttelefono,
          email: this.state.txtemail,// (opcional)
          principal: this.state.contactoprincipal ? 1 : 0,
          comentario: this.state.txtcomentariocontacto,// (opcional)
        }
        resp = await getListaClienteContactosUpdate(json)
      }
      else if (this.props.accion == 5 || this.props.accion == 6) {
        json = {
          idcatalogo_clientes_direcciones: this.props.item.idcatalogo_clientes_direcciones ? this.props.item.idcatalogo_clientes_direcciones : 0,
          idcatalogo_clientes: this.props.item.idcatalogo_clientes,
          calle: this.state.txtcalle,
          numeroexterior: this.state.txtexterior,
          numerointerior: this.state.txtnointerior,
          codigopostal: this.state.txtcp,
          colonia: this.state.txtcolonia,
          localidad: this.state.txtlocalidaddir,
          municipio: this.state.txtmunicipio,
          estado: this.state.txtestado,
          pais: this.state.txtpais,
          referencia: this.state.txtreferencia,
          principal: this.state.direccionprincipal
        }
        resp = await getListaClienteDireccionUpdate(json)
      }
      else if (this.props.accion == 7) {
        const formData = new FormData();
        formData.append('idcatalogo_clientes_documentos', "0")
        formData.append('idcatalogo_clientes', this.props.item.idcatalogo_clientes)
        formData.append('idcatalogo_documentos', this.state.idcatalogodocumento)
        formData.append('archivo', this.state.archivocargado)
        formData.append('descripcion', this.state.txtdescripciondoc.trim())

        resp = await getListaClienteDocumentoUpdate(formData)
      }

      if (resp == -1 || resp.code == 1000) {
        toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        this.setLoadingSpinner(false)
        return;
      }
      else if (resp.code == 200 || resp.code == 300 || resp.code == 400) {
        this.setLoadingSpinner(false)
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else {
        toast.success("Información guardada correctamente", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.showModal('1')
      }

    }
  }

  async seleccionarArchivo(event) {
    try {
      event.persist();
      var archi = event.target.files[0];
      if (event.target.files.length > 0) {
        this.setState({ archivocargado: archi, txtnombrearchivo: archi.name });
      }
    } catch (error) { }
  }

  cambiarValor = (band) => {

    if (band.lada) {
      let encontrado = ladapaises.find(x => x.idpais == band.lada);
      if (encontrado.idpais != 0) {
        this.refInput.focus();
      }
      this.setState({ idpais: encontrado.idpais })
    }
    else if (band.pais) {
      this.setState({ txtpais: band.pais });
    }
  }

  render() {
    return (

      <>
        <ToastContainer />

        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
            <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>
            <br />

            <br />

            {this.state.accion == 1 ?
              <div className="divCuerpoModal">

                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Clave</p>

                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtclabe} onChange={this.escribir("txtclabe")}></input>

                  {this.state.vermsgerrorclabe ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorclabe}</p> : null}


                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre completo / Nombre comercial</p>

                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtnombrecomercial} onChange={this.escribir("txtnombrecomercial")}></input>


                  {this.state.vermsgerrorcomercial ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcomercial}</p> : null}


                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo cliente</p>

                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarTipo({ tipo: "idtipocliente", valor: event.target.value })}>
                          <option value={todoslosfiltrosdisponibles} selected={this.state.idtipocliente == todoslosfiltrosdisponibles} >Seleccionar </option>
                          {this.state.arraytipo.map((i, index) => (
                            <option value={i.idtipo_cliente} key={"tipo_" + index} selected={i.idtipo_cliente == this.state.idtipocliente} >{i.tipo} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {this.state.vermsgtipocliente ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgtipocliente}</p> : null}


                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>

                  <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                    type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>


                  {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}


                  <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>

                  <div className='col lblSubTitulosModal '>
                    <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                      <div className="selectBox ">
                        <select className="cbxTexto letraNormal"
                          onChange={(event) => this.cambiarEstatus(event.target.value)}>
                          {this.state.arrayestatus.map((i, index) => (
                            <option value={i.idestatus} key={"tipo_" + index} selected={i.estatus == this.state.txtestatusproveedor} >{i.estatus} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                </div>

                <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                  Creado: {this.props.item.fechacreacion}
                  <br />
                  Actualizado: {this.props.item.fechaactualizacion}
                </ReactTooltip>

              </div>
              :

              this.state.accion == 2 ?
                <>
                  <div className="divCuerpoModal">

                    <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Persona</p>
                      <div className='col lblSubTitulosModal '>
                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                          <div className="selectBox ">
                            <select className="cbxTexto letraNormal" onChange={(event) => this.cambiarTipo(event.target.value)}>
                              {this.state.spersonas.map((i, index) => (
                                <option value={i.id} key={"tipo_" + index} selected={i.id == this.state.txtpersona} >{i.nombre} </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {this.state.txtpersona == "FISICA" ?
                        <>
                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre(s)</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtrazonsocial} onChange={this.escribir("txtrazonsocial")}></input>
                          {this.state.vermsgerrortxtrazonsocial ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrazonsocial}</p> : null}


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Apellidos</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtapellidosp} onChange={this.escribir("txtapellidosp")}></input>
                          {this.state.vermsgerrortxtapellidosp ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtapellidosp}</p> : null}
                        </>
                        :
                        <>
                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Razón social</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtrazonsocial} onChange={this.escribir("txtrazonsocial")}></input>
                          {this.state.vermsgerrortxtrazonsocial ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrazonsocial}</p> : null}
                        </>
                      }


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>RFC</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtrfc} onChange={this.escribir("txtrfc")}></input>

                      {this.state.vermsgerrortxtrfc ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtrfc}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Régimen fiscal</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtregimen} onChange={this.escribir("txtregimen")}></input>

                      {this.state.vermsgerrortxtregimen ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtregimen}</p> : null}


                    </div>

                  </div>

                  <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >Dirección fiscal</label>

                  <br />
                  <br />
                  <div className="divCuerpoModal">

                    <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Código postal</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'number'} value={this.state.txtcodigopostal} onChange={this.escribir("txtcodigopostal")}></input>

                      {this.state.vermsgerrortxtcodigopostal ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcodigopostal}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo de vialidad</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txttipovialidad} onChange={this.escribir("txttipovialidad")}></input>

                      {this.state.vermsgerrortxttipovialidad ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttipovialidad}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de vialidad</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtnombrevialidad} onChange={this.escribir("txtnombrevialidad")}></input>

                      {this.state.vermsgerrortxtnombrevialidad ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombrevialidad}</p> : null}



                      <div className='row'>

                        <div className='col-6'>
                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Exterior</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtexteriordf} onChange={this.escribir("txtexteriordf")}></input>

                          {this.state.vermsgerrortxtexteriordf ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtexteriordf}</p> : null}
                        </div>

                        <div className='col-6'>
                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Interior</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtnointeriordf} onChange={this.escribir("txtnointeriordf")}></input>


                          {this.state.vermsgerrortxtnointeriordf ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnointeriordf}</p> : null}
                        </div>

                      </div>


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de la colonia</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtcoloniadf} onChange={this.escribir("txtcoloniadf")}></input>


                      {this.state.vermsgerrortxtcoloniadf ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcoloniadf}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de la localidad</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtlocalidad} onChange={this.escribir("txtlocalidad")}></input>


                      {this.state.vermsgerrortxtlocalidad ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlocalidad}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de municipio o demarcación territorial</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtnombremunicipio} onChange={this.escribir("txtnombremunicipio")}></input>


                      {this.state.vermsgerrortxtnombremunicipio ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombremunicipio}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre de la entidad federativa</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtentidadfederativa} onChange={this.escribir("txtentidadfederativa")}></input>


                      {this.state.vermsgerrortxtentidadfederativa ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtentidadfederativa}</p> : null}



                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Entre calle</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtentrecalle} onChange={this.escribir("txtentrecalle")}></input>


                      {this.state.vermsgerrortxtentrecalle ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtentrecalle}</p> : null}

                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Y calle</p>
                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtycalle} onChange={this.escribir("txtycalle")}></input>


                      {this.state.vermsgerrortxtycalle ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtycalle}</p> : null}


                    </div>

                    {this.props.item.fechacreacion ? (
                      <>
                        <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                        <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                          Creado: {this.props.item.fechacreacion}
                          <br />
                          Actualizado: {this.props.item.fechaactualizacion}
                        </ReactTooltip>
                      </>
                    ) : null}

                  </div>

                </>
                :

                this.state.accion == 3 || this.state.accion == 4 ?
                  <div className="divCuerpoModal">

                    <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre completo</p>

                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtnombrecompleto} onChange={this.escribir("txtnombrecompleto")}></input>


                      {this.state.vermsgerrortxtnombrecompleto ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnombrecompleto}</p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Puesto</p>

                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomoda, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtpuesto} onChange={this.escribir("txtpuesto")}></input>


                      {this.state.vermsgerrortxtpuesto ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpuesto}</p> : null}



                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Teléfono</p>

                      <div className="row anchoDivTelefono" style={{ borderBottom: this.state.estilosmodal.colorborderbottom }}>

                        <div className='col-auto' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                          <BsTelephoneFill />
                        </div>

                        <div className='col-auto estiloRowPhone'>

                          {(ladapaises.length > 0) ? (
                            <select className="comboTelefono letraNormal" onChange={(event) => this.cambiarValor({ lada: event.target.value })}>
                              {this.state.idpais < 0 || this.state.idpais >= ladapaises.length ?
                                <option className='phoneSelection' value={-1} selected={true}>Lada</option> : null
                              }
                              {ladapaises.map((i, index) => (

                                (i.idpais != -1) ? (
                                  <>
                                    {(this.state.idpais == i.idpais) ? (
                                      <option className='phoneSelection' value={i.idpais} key={"pais_" + index} selected={i.idpais == this.state.idpais}>{i.lada}</option>
                                    ) :
                                      <option value={i.idpais} key={"pais_" + index} selected={i.idpais == this.state.idpais}>{i.pais}</option>}
                                  </>
                                ) : null
                              ))}
                            </select>
                          ) : null}
                        </div>

                        <div className='col'>
                          <input ref={(input) => { this.refInput = input; }} className='lblTextoModal letraNormal' style={{ marginBottom: '7px', color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none' }}
                            value={this.state.txttelefono} onChange={this.escribir("txttelefono")}></input>
                        </div>
                      </div>

                      {(this.state.vermsgerrortxttelefono || this.state.vermsgerrortxtlada) ?
                        <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>
                          {this.state.vermsgerrortxtlada ? (this.state.msgerrortxtlada) : ""}
                          {this.state.vermsgerrortxttelefono && this.state.vermsgerrortxtlada ? ". " : ""}
                          {this.state.vermsgerrortxttelefono ? (this.state.msgerrortxttelefono) : ""}
                        </p> : null}


                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Email</p>

                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtemail} onChange={this.escribir("txtemail")}></input>


                      {this.state.vermsgerrortxtemail ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtemail}</p> : null}

                      <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>

                      <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                        type={'text'} value={this.state.txtcomentariocontacto} onChange={this.escribir("txtcomentariocontacto")}></input>


                      {this.state.vermsgerrortxtcomentariocontacto ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcomentariocontacto}</p> : null}

                      <div className='modalDetallesCheckContactoPrincipal letraNormal' onClick={() => this.setState({ contactoprincipal: !this.state.contactoprincipal })}
                        style={{ marginBottom: "10px", paddingTop: "10px" }}>
                        <input type="checkbox" checked={this.state.contactoprincipal} />Contacto principal
                      </div>

                      {this.state.vermsgerrorcontactoprincipal ? <p className="msgError letraNormal"
                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcontactoprincipal}</p> : null}
                    </div>

                    {this.state.accion == 4 ? (
                      <>
                        <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                        <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                          Creado: {this.props.item.fechacreacion}
                          <br />
                          Actualizado: {this.props.item.fechaactualizacion}
                        </ReactTooltip>
                      </>
                    ) : null}

                  </div>

                  :
                  this.state.accion == 5 || this.state.accion == 6 ?
                    <div className="divCuerpoModal">

                      <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Calle</p>
                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtcalle} onChange={this.escribir("txtcalle")}></input>


                        {this.state.vermsgerrortxtcalle ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcalle}</p> : null}


                        <div className='row'>

                          <div className='col-6'>
                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Exterior</p>

                            <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                              type={'text'} value={this.state.txtexterior} onChange={this.escribir("txtexterior")}></input>

                            {this.state.vermsgerrortxtexterior ? <p className="msgError letraNormal"
                              style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtexterior}</p> : null}
                          </div>

                          <div className='col-6'>
                            <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>No. Interior</p>

                            <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                              type={'text'} value={this.state.txtnointerior} onChange={this.escribir("txtnointerior")}></input>


                            {this.state.vermsgerrortxtnointerior ? <p className="msgError letraNormal"
                              style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtnointerior}</p> : null}
                          </div>

                        </div>


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Colonia</p>

                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtcolonia} onChange={this.escribir("txtcolonia")}></input>


                        {this.state.vermsgerrortxtcolonia ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcolonia}</p> : null}



                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Código postal</p>

                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'number'} value={this.state.txtcp} onChange={this.escribir("txtcp")}></input>


                        {this.state.vermsgerrortxtcp ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcp}</p> : null}


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Localidad</p>
                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtlocalidaddir} onChange={this.escribir("txtlocalidaddir")}></input>

                        {this.state.vermsgerrortxtlocalidaddir ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtlocalidaddir}</p> : null}


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Municipio</p>

                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtmunicipio} onChange={this.escribir("txtmunicipio")}></input>


                        {this.state.vermsgerrortxtmunicipio ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtmunicipio}</p> : null}


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estado</p>

                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtestado} onChange={this.escribir("txtestado")}></input>


                        {this.state.vermsgerrortxtestado ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtestado}</p> : null}


                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>País</p>

                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtpais} onChange={this.escribir("txtpais")}></input>

                        {this.state.vermsgerrortxtpais ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtpais}</p> : null}



                        <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Referencia</p>
                        <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                          type={'text'} value={this.state.txtreferencia} onChange={this.escribir("txtreferencia")}></input>


                        {this.state.vermsgerrortxtreferencia ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtreferencia}</p> : null}

                        <div className='modalDetallesCheckContactoPrincipal letraNormal' onClick={() => this.setState({ direccionprincipal: !this.state.direccionprincipal })}
                          style={{ marginBottom: "10px", paddingTop: "10px" }}>
                          <input type="checkbox" checked={this.state.direccionprincipal} />Dirección principal
                        </div>

                        {this.state.vermsgerrordireccionprincipal ? <p className="msgError letraNormal"
                          style={{ color: this.state.colormsgerror }}>{this.state.msgerrordireccionprincipal}</p> : null}

                      </div>

                      {this.state.accion == 6 ? (
                        <>
                          <BsQuestionSquare className='icoQuestionModal' data-tip data-for="registerTip" />
                          <ReactTooltip id="registerTip" place="top" effect="solid" className='tolTipFormat'>
                            Creado: {this.props.item.fechacreacion}
                            <br />
                            Actualizado: {this.props.item.fechaactualizacion}
                          </ReactTooltip>
                        </>
                      ) : null}

                    </div>

                    :
                    this.state.accion == 7 ?
                      <div className="divCuerpoModal">

                        <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Archivo</p>
                          <div className='centrarBotonFile' >
                            <input type="file" name="file-2[]" id="file-2" className="inputFileP inputFile inputFile-2" accept=".txt, .pdf, .csv, .xsl, .doc, .docx, .jpg, .jpeg, .png"
                              onChange={(event) => this.seleccionarArchivo(event)} />
                            <label for="file-2"><MdCloudUpload />
                              <span className='letraNormal'>{this.state.txtnombrearchivo}</span>
                            </label>
                          </div>

                          {this.state.vermsgerrortxtarchivo ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtarchivo}</p> : null}
                          <br />


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo</p>

                          <div className='col lblSubTitulosModal '>
                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                              <div className="selectBox ">
                                <select className="cbxTexto letraNormal"
                                  onChange={(event) => this.cambiarTipo({ tipo: "idtipodocumento", valor: event.target.value })}>
                                  {<option value={todoslosfiltrosdisponibles} selected={this.state.idtipodocumento == todoslosfiltrosdisponibles} >Seleccionar </option>}
                                  {this.props.arraytipodocumentos.map((i, index) => (
                                    <option value={i.idtipo_documento} key={"tipo_" + index} selected={i.idtipo_documento == this.state.idtipodocumento} >{i.tipo} </option>
                                  ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          {this.state.vermsgerrortxttipodocumento ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxttipodocumento}</p> : null}


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Documento</p>

                          <div className='col lblSubTitulosModal '>
                            <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>

                              <div className="selectBox ">
                                <select className="cbxTexto letraNormal"
                                  onChange={(event) => this.cambiarTipo({ tipo: "idcatalogodocumento", valor: event.target.value })}>
                                  {<option value={todoslosfiltrosdisponibles} selected={this.state.idcatalogodocumento == todoslosfiltrosdisponibles} >
                                    {this.state.idtipodocumento == todoslosfiltrosdisponibles ? "Seleccione primero un tipo" : "Seleccionar"}
                                  </option>}
                                  {this.state.idtipodocumento != todoslosfiltrosdisponibles && this.props.arraylistacatalogodocumentos
                                    .filter((e) => e.idtipo_documento == this.state.idtipodocumento)
                                    .map((i, index) => (
                                      <option value={i.idcatalogo_documentos} key={"tipo_" + index} selected={i.idcatalogo_documentos == this.state.idcatalogodocumento} >{i.documento} </option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          {this.state.vermsgerrortxtcatalogodocumento ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtcatalogodocumento}</p> : null}


                          <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Comentario</p>

                          <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                            type={'text'} value={this.state.txtdescripciondoc}
                            onChange={this.escribir("txtdescripciondoc")}></input>

                          {this.state.vermsgerrortxtdescripciondoc ? <p className="msgError letraNormal"
                            style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtdescripciondoc}</p> : null}

                        </div>

                      </div>
                      : null}

            <br />
            <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
              <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
            </div>
            <br />
            <div className="divBtnContenedorModal" >
              <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
              <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
            </div>

          </ModalBody>

        </Modal>
      </>
    )
  }

}

