import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './CatalogoBitacoraTraslados.css';
import { BiEdit } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { Table } from 'reactstrap';
import { estiloBtnEditar, estiloComboBox, estiloFiltroEncabezados, estiloTabla } from '../../Services/PaletaDeColores';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getEstatus,postDetallesBitacoraTraslados, getListaLotesCortes,postListaBitacoraCuadrillas,postDetallesBitacoraTrasladoGuardar,getListaClientes } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import ModalFormularioDetallesBitacoraTraslado from '../../Complements/Modales/ModalFormularioDetallesBitacoraTraslado/ModalFormularioDetallesBitacoraTraslado';
import ModalConfirarAccion from '../../Complements/Modales/ModalFormularioOrdenCosecha/ModalConfirmarAccion';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export default class DetalleBitacoraTraslados extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      altura: window.innerHeight,
      anchura: window.innerWidth,
      salir: false,
      loadingspinners: false,
      errordeacceso: false,

      txtselectedzona: '0',
      txtselectedrancho: '0',
      txtfecha: "",
      idcatalogotraslado:'',
      idrancho:0,

      filtrolistapaginada: [],
      elementosfiltrados: [],
      listazonas: [],
      listaranchos: [],
      listatiposuelo: [],
      listacultivo: [],
      listaclientes:[],
      estatus: [],

      tabla: {
        encabezados: [
          "Hora de entrada",
          "Conductor",
          "Vehículo",
          "Placas",
          "Lote",
          "Cosecha",
          "Rejas entrada",
          "Rejas/tonelada salida",
          "Folio traslado",
          "Hora de Salida",
          "Cliente",
          "",
          "",
        ],
        dimensiones: [
          "10%",
          "12%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
          "10%",
        ],
        mindimensiones: [
          "160px",
          "180px",
          "120px",
          "200px",
          "120px",
          "120px",
          "140px",
          "195px",
          "140px",
          "140px",
          "120px",
          "135px",
          "135px",
        ],
        elementos: []
      },

      accionmodal: 1,
      titulomodal: '',
      modaldetalles: false,
      itemseleccionado: null,
      modalconfirmar:false,
      itemeliminar:null,

      redireccionando: false,
      idtraslado: "",
      cargadeinformacion: false,

      //paginacion
      activepage: 1,
      itemsperpage: 20,
      rangepages: 20,
      listapaginada: [],

      fondotitulosencabezados: estiloFiltroEncabezados().fondotitulosencabezados,
      colortitulo: estiloFiltroEncabezados().colortitulos,
      colortipolbl: estiloComboBox().colorcombolbl,
      colorcbxtipolbl: estiloComboBox().colorcbxlbl,
      colorbordertipo: estiloComboBox().colorbordercombo,
      colorfondotipo: estiloComboBox().colorfondocombo,

      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,

      btnfondoeditar: estiloBtnEditar().btnfondoeditar,
      btncoloreditar: estiloBtnEditar().btncoloreditar,
      btnbordereditar: estiloBtnEditar().btnbordereditar
    }
  }

  async componentDidMount() {
    this.setState({ loadingspinners: true })

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') == undefined) {
      this.setState({ salir: true })
    }

    if (localStorage.getItem('dato') == null || localStorage.getItem('dato') == undefined) {
      this.setState({ salir: true })
    }

    this.setState({
      idtraslado: JSON.parse(localStorage.getItem("dato")).id,
    })

    var estatus = await getEstatus();
    if (this.manejadorLlamadasApis (estatus)) {
      return;
    }

    if (!this.state.cargadeinformacion && this.state.idtraslado !="") {

      var infobitacoratraslados = await postDetallesBitacoraTraslados({
        idcatalogo_bitacora_traslados: this.state.idtraslado
      });
      if (this.manejadorLlamadasApis(infobitacoratraslados)) {
        return;
      }

      await this.setState({
        cargadeinformacion: true,
        txtfecha: infobitacoratraslados.data.header[0].fecha,
        txtselectedzona: infobitacoratraslados.data.header[0].zona,
        txtselectedrancho: infobitacoratraslados.data.header[0].rancho,
        idrancho:infobitacoratraslados.data.header[0].idcatalogo_rancho,
        idcatalogotraslado:infobitacoratraslados.data.header[0].idcatalogo_bitacora_traslados,
      })
    }

    //Obtiene lista de lotes
    var resplotes = await getListaLotesCortes();
    if ( this.manejadorLlamadasApis (resplotes) ) {
      return;
    }

    //Obtiene lista de clientes
    var respclientes = await getListaClientes();
    if ( this.manejadorLlamadasApis (respclientes) ) {
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = infobitacoratraslados.data.data;

    await this.setState({
      estatus: estatus.data,
      listalotes:resplotes.data,
      listaclientes:respclientes.data,
      tabla: tabla
    })

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }

  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  manejadorLlamadasApis = (resp) => {
    if (resp == -1) {
      toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 300) {
      if (!this.state.errordeacceso) {
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      }
      this.setState({ loadingspinners: false, errordeacceso: true })
      return true
    }
    else if (resp.code == 400) {
      toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    else if (resp.code == 401) {
      this.setState({ salir: true })
      return true
    }
    if (resp.code == 1000) {
      toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.setState({ loadingspinners: false })
      return true
    }
    return false
  }

  setLoadingSpinner = (bandera) => {
    this.setState({ errordeacceso:false })
    this.setState({ loadingspinners: bandera });
  }

  actualizarTabla = async () => {
    this.setState({ errordeacceso:false })
    this.componentDidMount();
    this.setState({ loadingspinners: true });
    var infobitacoratraslados = await postDetallesBitacoraTraslados({
      idcatalogo_bitacora_traslados: this.state.idtraslado
    });
    if (this.manejadorLlamadasApis(infobitacoratraslados)) {
      return;
    }
    this.setState({ loadingspinners: false });
    var tabla = this.state.tabla;
    tabla.elementos = infobitacoratraslados.data.data;

    await this.setState({
      tabla: tabla
    })
  }

  //convierte formato yyyy-mm-dd a dd/mm/yyyy
  regresaFechaSave = (fecha) => {
    var fechaf = "";
    if(fecha!=null){
        var [year, month, day] = fecha.split("-");

        if(day!=undefined && month!=undefined && year!=undefined){
          fechaf = `${day}/${month}/${year}`
          return fechaf;
        }else{
          fechaf = fecha;
        }
    }

    return fechaf;
  }

  consultarCatalogoBitacoraCuadrillas = async (valor) => {

    var requestbody = {
      fecha: valor,
    }

    this.setState({ loadingspinners: true })
    var resp = await postListaBitacoraCuadrillas(requestbody);
    if (this.manejadorLlamadasApis(resp)) {
      this.setState({ loadingspinners: false })
      return;
    }

    var tabla = this.state.tabla;
    tabla.elementos = resp.data;

    await this.setState({
      tabla: tabla
    })
    this.setState({ loadingspinners: false })

  }

  format_numero = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  verModal = async (ban) => {

    if (ban.opcion == 1) { //Nuevo
      this.setState({ titulomodal: 'Agregar traslado', accionmodal: 1, modaldetalles: true })
    }
    else if (ban.opcion == 2) { //editar
      this.setState({ titulomodal: 'Editar traslado', accionmodal: 2, itemseleccionado: JSON.parse(JSON.stringify(ban.contenido)),  modaldetalles: true  });
    }
    else if (ban.opcion == 3) { //elminar
      this.setState({ titulomodal: 'Confirmar acción', accionmodal: "registro", modalconfirmar: true, itemeliminar: ban.contenido });
    }
    this.actualizarTabla();
    this.setState({ loadingspinners: false });

  }

  cerrarModal = (accion) => {

    this.setState({ modaldetalles: !this.state.modaldetalles, loadingspinners:false })

    if (accion == 1) {
      toast.success("Registro creado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }
    else if (accion == 2) {
      toast.success("Registro editado exitósamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
      this.actualizarTabla();
    }

  }

  eliminarElemento = async (elemento) => {
    var resp = undefined;
    this.setState({ modalconfirmar: !this.state.modalconfirmar })

    if (elemento.accion == 1) {
      this.setState({ loadingspinners: true })
      if (elemento.plan == "registro") {

        let json = {
          "idcatalogo_bitacora_traslados_detalles": elemento.item.idcatalogo_bitacora_traslados_detalles,
          "idcatalogo_bitacora_traslados": this.state.idtraslado,
          "idcatalogo_ranchos_lotes_cultivo":elemento.item.idcatalogo_ranchos_lotes_cultivo,
          "folio": elemento.item.folio,
          "fecha": this.state.fecha,
          "horaentrada": elemento.item.horaentrada,
          "horasalida": elemento.item.horasalida,
          "rejasentradavacias": elemento.item.rejasentradavacias,
          "rejassalidallenas": elemento.item.rejassalidallenas,
          "conductor": elemento.item.conductor,
          "vehiculo": elemento.item.vehiculo,
          "placas": elemento.item.placas,
          "idcatalogo_clientes":elemento.item.idcatalogo_clientes,
          "eliminar": 1
      }
        var resp = await postDetallesBitacoraTrasladoGuardar(json);
      }

      if(resp.code == 0){
        this.setState({ loadingspinners: false })
        toast.success("Se borró el elemento exitosamente.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        this.actualizarTabla();
      }
      else if(resp.code == 200){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 300){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if(resp.code == 400){
        this.setLoadingSpinner(false);
        this.setState({ confirmarenvio: !this.state.confirmarenvio })
        toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        return;
      }
      else if (resp.code == 1000) {
        this.setLoadingSpinner(false);
        toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
      else{
        this.setLoadingSpinner(false);
        toast.error("Error de conexión.", { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        return;
      }
    }
  }

  render() {
    return (
      <>

        {/*///////// Sección de modales, spinner y redireccionamiento ////////*/}
        {this.state.salir ?
          <>
            <SpinnerModal tipo="full" show={true} />
            <Acciones bandera={'salir'} />
          </>
        :null}

        <ToastContainer />
        {(this.state.loadingspinners == true) ?
          <SpinnerModal tipo="full" show={this.state.loadingspinners} />
        :null}

        {(this.state.modaldetalles == true) ?
          <ModalFormularioDetallesBitacoraTraslado
            titulomodal={this.state.titulomodal}
            cerrarModal={this.cerrarModal}
            estatus={this.state.estatus}
            show={this.state.modaldetalles}
            accion={this.state.accionmodal}
            item={this.state.itemseleccionado}
            listazonas={this.state.listazonas}
            listaranchos={this.state.listaranchos}
            listaclientes={this.state.listaclientes}
            listacultivos={this.state.listacultivo}
            listatipocultivos={this.state.listatipocultivo}
            listatiposuelos={this.state.listatiposuelo}
            idcuadrilla={this.state.idcuadrilla}
            idrancho={this.state.idrancho}
            listalotes={this.state.listalotes}
            idcatalogotraslado={this.state.idcatalogotraslado}
            setLoadingSpinner={this.setLoadingSpinner}/>
        :null}

        {(this.state.modalconfirmar == true) ? 
          <ModalConfirarAccion
            titulomodal={this.state.titulomodal}
            cerrarModalConfirmar={this.eliminarElemento}
            show={this.state.modalconfirmar}
            cuerpo={this.state.accionmodal}
            item={this.state.itemeliminar}
          />
        :null}

        {/*///////// Sección de navbar ////////*/}
        <Navbar />

        {/*///////// Sección de encabezado e información general ////////*/}
        <div className="container-fluid" style={{ backgroundColor: this.state.fondotitulosencabezados }}>
          <div className="container-fluid anchoMaximo">
            <div className='row paddingEncabezados align-items-end'>

              {/* ////////////////////  Título  ////////////////////////////////////// */}
              <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 containerSubTabla paddingBottomEncabezados'>
                <label className='lblTituloHoja letraTitulos' style={{ color: this.state.colortitulo }}>Traslado de fruta </label>
              </div>

              {/* ////////////////////   Fecha   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Fecha: </label>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>{this.regresaFechaSave(this.state.txtfecha)}</label>
                </div>
              </div>

              {/* ////////////////////   Zona   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Zona: </label>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>{this.state.txtselectedzona}</label>
                </div>
              </div>

              {/* ////////////////////   Rancho   ////////////////////////////////////// */}
              <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                <div className='divCombo' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Rancho: </label>
                  <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>{this.state.txtselectedrancho}</label>
                </div>
              </div>

            </div>
          </div>
        </div>
        <br />

        {/* ////////////////////   Sección de tabla   ////////////////////////////////////// */}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className="container-fluid anchoMaximo2 ">
            <div className="containerSubTabla">

              <Table>
                <thead style={{ verticalAlign: "bottom" }}>
                  <tr>
                    {this.state.tabla.encabezados.map((titulo, index) => (
                      <th key={"tabla" + index} className="lblEncabezadosTabla letraTitulos"
                        style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                        {titulo}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody style={{ verticalAlign: "bottom" }}>
                  {this.state.tabla.elementos.map((item, index) => (
                    <tr key={"tabla" + index}>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.horaentrada}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.conductor}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.vehiculo}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.placas}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.lote}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.cultivo}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                        {this.format_numero(item.rejasentradavacias)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, textAlign: 'right' }}>
                        {this.format_numero(item.rejassalidallenas)}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.folio}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.horasalida}
                      </td>
                      <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                        {item.cliente}
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" >
                        <button
                          className='btnEditar'
                          onClick={this.verModal.bind(this, { opcion: "2", contenido: item})}
                          style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                        >
                          <BiEdit className="icoBotones" />Editar
                        </button>
                      </td>
                      <td className="letraNormal > lblSubtablaBotones" >
                        <button
                          className='btnEditar'
                          onClick={this.verModal.bind(this, { opcion: "3", contenido: item})}
                          style={{ backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}
                        >
                          <FaTrashAlt className="icoBotones" />Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
          </div>
        </div>

        {/* ////////////////////   Sección botón agregar   ////////////////////////////////////// */}
        <div className="container-fluid anchoMaximo containerTabla">
          <div className='row paddingEncabezados' style={{ marginBottom: "6px", padding: "8px" }}>
            <div className="col-auto">
              <button className=' letraNormal btnEditar' 
                onClick={this.verModal.bind(this, { opcion: "1"})}
                style={{
                  backgroundColor: this.state.btnfondoeditar, color: this.state.btncoloreditar, border: this.state.btnbordereditar }}>
                  + Agregar
              </button>
            </div>
          </div>
        </div>

      </>
    )
  }
}

{/* ////////////////////   Función de redireccionamiento   ////////////////////////////////////// */}
function Acciones({ bandera }) {
  if (bandera == 'salir') {
    return (<Redirect from='/detallesbitacoracuadrillas' to={"/catalogobitacoracuadrillas"} />)
  }
}