import React from 'react'
import './EditarRequisicion.css';
import './RequisicionGeneral.css';
import {   estiloTabla } from '../../Services/PaletaDeColores';
import { Table } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';


export default class TablaProductos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,

     
      tabla: {
        encabezados: [
          "Tipo",
          "Producto",
          "Cantidad",
          "Unidad de medida",
          "Destino"
        ],
        dimensiones: [
          "19%",
          "15%",
          "12%",
          "14%",
          "25%"
        ],
        mindimensiones: [
          "160px",
          "180px",
          "120px",
          "200px",
          "240px"
        ],
        elementos: []
      },

      //algo asi entregara el arreglo de la api informacion
      datosDummy: props.productos,
      listaimcompletos: [],
      colorencabezadostabla: estiloTabla().colorencabezadostabla,
      colorcontenidotabla: estiloTabla().colorcontenidotabla,
      listadestinos: {},
      listaguardados: {},

      destinoseleccionado: { nombre: 'Seleccionar destino', id: -1, idtipo_destino: -1 }
    }
  }

  async componentDidMount() {

    if (localStorage.getItem('ls') == null || localStorage.getItem('ls') === undefined) {
      this.setState({ salir: true })
    }

    //Se asigna atributos auxiliares
    this.setAtributosAux();

    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);
    this.setState({ loadingspinners: false })

  }


  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  setAtributosAux = async () => {
    //agregamos los atributos, detener, eliminar, editable a los elementos de las tablas
    var aux = this.state.datosDummy;
    aux.map((item) => {
      item.eliminar = false;
    })

    //a estos indices se le colocara la fila en rojo
    await this.setState({ datosDummy: aux })
  }
  
  render() {
    return (

      <>
        
        {/* ////////////////////   Tabla   ////////////////////////////////////// */}

        <br />
        <div className="container-fluid containerTabla" style={{ backgroundColor: 'white' }}>
          <div className="container-fluid anchoMaximo">
            <div className='row justify-content-center' >
              <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>

                <Table hover>
                  <thead style={{ verticalAlign: "bottom" }}>
                    <tr>
                      {this.state.tabla.encabezados.map((titulo, index) => (
                        <th key={"tabla" + index} className={(index > 1 && index < 4) ? "centerText lblEncabezadosTabla letraTitulos" : "lblEncabezadosTabla letraTitulos"}
                          style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                          {titulo}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody style={{ verticalAlign: "bottom" }}>
                    {this.props.productos.map((item, index) => (
                      <tr key={index}
                        className={
                          item.detener ? "trFondoDetener" : '' } >
                        <td key={"col-" + index + "-01"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.tipoproducto}
                        </td>
                        <td key={"col-" + index + "-02"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                          {item.producto}
                        </td>
                        <td key={"col-" + index + "-03"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                          {item.cantidad}
                        </td>
                        <td key={"col-" + index + "-04"} className="letraNormal lblSubtabla centerText" style={{ color: this.state.colorcontenidotabla }}>
                          {item.unidadmedida}
                        </td>
                        <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla, minWidth: this.state.tabla.mindimensiones[6], width: this.state.tabla.dimensiones[6] }}>
                          {item.destino}
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </Table>



              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function AccionesVista({ bandera }) {
  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}
