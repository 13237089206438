import React from 'react'
import Navbar from '../../Complements/Navbar/Navbar'
import './Requisicion.css';
import { BiEdit, BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
//import { Table } from 'reactstrap';
import { Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, Collapse, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { estiloBtnAgregar, coloresTxtBuscador, estiloBtnActualizar, estiloBtnEditar, estiloBtnMasInformacion, estiloBtnNuevo, estiloBtnVer, estiloComboBox, estiloFiltroEncabezados, estiloTabla, estilosModal } from '../../Services/PaletaDeColores';
//import ModalFormularioTablasDisponibles from '../../Complements/Modales/ModalFormularioNuevaRequisicion/ModalFormularioTablasDisponibles';
import SpinnerModal from '../../Complements/Modales/SpinnerModal/SpinnerModal';
import { getComboTipoPlagas, getEstatus, getListaPlagas } from '../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import Tabla from './Tabla';
import 'react-toastify/dist/ReactToastify.css';


export default class Lote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      salir: false,
      altura: window.innerHeight,
      anchura: window.innerWidth,
      loadingspinners: false,
      errordeacceso: false,
      accion: props.accion, // 1 Nuevo, 2 editar
      titulomodal: "",

      modaldetalles: false,
      lote: this.props.itemlote,
      listatablasagregadas: props.itemlote.tablas ? props.itemlote.tablas : [],

      colapsartabla: true,

      fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
      colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
      borderbtnactualizar: estiloBtnActualizar().borderbtnactualizar,
      colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
      fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

      colorfondobtnragregar: estiloBtnAgregar().colorfondobtnragregar,
      colorbtnagregar: estiloBtnAgregar().colorbtnagregar,
      colorbordebtnagregar: estiloBtnAgregar().colorbordebtnagregar,

      estilosmodal: estilosModal(),

    }
  }

  async componentDidMount() {
    this.dimensiones();
    window.addEventListener("resize", this.dimensiones);

  }
  dimensiones = () => {
    this.setState({ altura: window.innerHeight, anchura: window.innerWidth })
  }

  activarSpinner = (accion) => {
    this.setState({ loadingspinners: accion })
  }
  render() {
    return (
      <>
        {/* ////////////////////   Inicio Sección Tabla   ////////////////////////////////////// */}
        <div className="marginTopSeparator">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell className='rowElement nonePaddingTopBottom' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>
                    <div className='row flexNoWrap'>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom' >
                        <button className='btnCollapsar letraNormal'
                          style={{ color: this.state.colorbtnactualizar, backgroundColor: this.state.fondobtnactualizar, border: this.state.borderbtnactualizar }}
                          onClick={() => this.setState({ colapsartabla: !this.state.colapsartabla })}>{!this.state.colapsartabla ? "+" : "-"}</button>
                      </div>
                      <div className='col-auto containerSubTablaAA minPaddingTopBottom d-flex align-items-center'>
                        <p className='sinMargen'><b>Lote: </b>{this.props.itemlote.lote}</p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={this.state.colapsartabla} timeout="auto" >
                      <Box margin={1}>
                        {this.state.listatablasagregadas.length > 0 ?
                          this.state.listatablasagregadas.map((item, index) => (
                            <div key={"tabla_" + index} className='spacePaddingBottom'>
                              <Tabla accion={this.state.accion} itemtabla={item} />
                            </div>
                          ))
                          : <div></div>
                          }

                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ////////////////////   Fin Sección Tabla   ////////////////////////////////////// */}
      </>
    )
  }
}
function Acciones({ bandera }) {

  if (bandera == 'salir') {
    localStorage.clear();
    return (<Redirect from='/' to={"/"} />)
  }
}

