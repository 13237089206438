import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import "./ModalFormularioNuevaRelacion.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosEspacios, validarLetrasNumerosPuntoComa } from '../../../Services/Validaciones';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';

export default class ModalFormularioNuevaRelacion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            accion: props.accion,
            titulo: props.titulomodal,

            txtnivelautorizacion: this.props.nivelseleccionado,
            txtdescripcion: "",
            txtidarea: 0,
            txtidpuesto: 0,
            
            vermsgerrordescripcion: false,
            msgerrordescripcion: "",
            vermsgerrornivelautorizacion: false,
            msgerrornivelautorizacion: "",
            vermsgerrorarea: false,
            msgerrorarea: "",
            vermsgerrorpuesto: false,
            msgerrorpuesto: "",

            listaareas: this.props.listaareas,
            listapuestos: this.props.listapuestos,

            msgerror: '',
            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colorbordertipo: estiloComboBox().colorbordercombo
        }

    }
    async componentDidMount() {
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    agregarRelacion = async () => {

        this.setState({ vermsgerrornivelautorizacion: false })
        this.setState({ vermsgerrordescripcion: false })
        this.setState({ vermsgerrorarea: false })
        this.setState({ vermsgerrorpuesto: false })

        let validacionescorrectas = true
        
        let longitudvalida = {longitudminima: 0, longitudmaxima: 100};
        let validacionParam = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
        if (validacionParam.codigo == "invalid") {
            this.setState({ msgerrordescripcion: validacionParam.mensaje })
            this.setState({ vermsgerrordescripcion: true })
            validacionescorrectas = false;
        }

        if (this.state.txtidarea < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorarea: "Selecciona un área" })
            this.setState({ vermsgerrorarea: true })
        }

        if (this.state.txtidpuesto < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorpuesto: "Selecciona un puesto" })
            this.setState({ vermsgerrorpuesto: true })
        }

         
        if (validacionescorrectas) {
            let json = {
                idpuesto: this.state.txtidpuesto,
                descripcion: this.state.txtdescripcion,
                idarea: this.state.txtidarea
            }
            this.showModal(json)
        }

    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nivel de autorización</p>
                                {this.state.accion == 2 ? (
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>{this.state.txtnivelautorizacion}</label>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal }}>{this.state.txtnivelautorizacion}</label>}


                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Área</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal'>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txtidarea")}>
                                                    <option disabled="disabled" selected={this.state.txtidarea==0 ? true : false}>Seleccionar</option> 
                                                    {this.state.listaareas!=undefined ? this.state.listaareas.map((i, index) => (
                                                        <option value={i.idcatalogo_areas} key={"tipo_" + index} >{i.area} </option>
                                                    )) : null}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }

                                {this.state.vermsgerrorarea ? <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorarea}</p> : null}

                                <p className='lblSubTitulosModal letraNormal' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Puesto</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal lblTextoModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" onChange={this.escribir("txtidpuesto")}>
                                                    <option disabled="disabled" selected={this.state.txtidpuesto==0 ? true : false}>Seleccionar</option>
                                                    {this.state.listapuestos!=undefined ? this.state.listapuestos.map((i, index) => (
                                                        this.state.txtidarea == i.idcatalogo_areas ?
                                                        <option value={i.idcatalogo_puestos} key={"tipo_" + index} >{i.puesto} </option> : null
                                                    )) : null}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    null
                                }
                                {this.state.vermsgerrorpuesto ? <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpuesto}</p> : null}


                                <p className='lblSubTitulosModal letraNormal ' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                                {this.state.accion == 2 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                                {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}

                            </div>
                        </div>
                        <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                            <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                        </div>
                        <br />
                        <div className="divBtnContenedor" >
                            <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                            <Button className="btnAceptarModal" onClick={() => this.agregarRelacion()} disabled={!this.state.confirmarenvio} >Agregar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

}