import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import "./ModalCotizaciones.css";
import { estiloComboBox, estilosModal, coloresTxtBuscador, estiloBtnNuevo } from '../../../Services/PaletaDeColores';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { getComboFamiliaProducto, getComboTipoProducto, getLista2Productos, getComboUnidadMedida, agregarProductoEmergente } from '../../../Services/Api';
import ModalFormularioBuscarProductos from '../ModalFormularioInventarioAlmacen/ModalFormularioBuscarProductos';
import ModalFormularioBuscarProductosRequisicion from '../ModalFormularioNuevaRequisicion/ModalFormularioBuscarProductosRequisicion';
import { validarNumerosDecimales3 } from '../../../Services/Validaciones';

export default class ModalSustituirProductoCotizacion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            hubocambio: false,
            loadingspinners: true,

            tabla: {
                encabezados: [
                    "Agroquímico",
                    "Cantidad",
                    ""
                ],
                dimensiones: [
                    "15%",
                    "15%",
                    "10%"
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "10px"
                ],
                elementos: [],
            },

            productocambiar: props.item.productosustituido,
            cantidadcomprada: props.item.cantidadcomprada,
            productosresumen: props.item.productos,
            listaproductosnuevos: props.listaproductosnuevos,
            modalbuscarproducto: false,

            respfamiliaproductos: [],
            resptipoproductos: [],
            respproductos: [],
            listaunidadesmedida: [],

            listaproductosseleccionado: [],

            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,

            colorbtnnuevo: estiloBtnNuevo().colorbtnnuevo,
            fondobtnnuevo: estiloBtnNuevo().fondobtnnuevo,

            colorlupa: coloresTxtBuscador().colorlupa,
        }
    }

    async componentDidMount() {
        await this.apisproductos(this.state.productocambiar);

        setTimeout(() => {
            if(this.state.cantidadcomprada>0){
                this.agregarProductoComprado();
            }
        }, 300);
    }

    showModal = (accion) => {
        if (accion == "10") {
            
            var aux = this.state.listaproductosseleccionado.filter((item)=> item.noguardar==true)
            if(aux.length<1){
                let json = {
                    idproductosustituido: this.state.productocambiar.idproducto,
                    productos: [],
                    productosNuevos: this.state.listaproductosnuevos
                }
                this.state.listaproductosseleccionado.map((item)=>{
                    var newCantidad = ""+item.cantidadCoti;
                    var auxCantidad = newCantidad.split('.');
                    if(auxCantidad.length > 1){
                        newCantidad = auxCantidad[0].slice(0,7)+"."+auxCantidad[1].slice(0,2)
                    }else{
                        newCantidad = auxCantidad[0].slice(0,7)+".00"
                    }

                    var newPendientes = ""+item.pendientes;
                    var auxPendientes = newPendientes.split('.');
                    if(auxPendientes.length > 1){
                        newPendientes = auxPendientes[0].slice(0,7)+"."+auxPendientes[1].slice(0,2)
                    }else{
                        newPendientes = auxPendientes[0].slice(0,7)+".00"
                    }

                    let prod = {
                        cantidad: newCantidad,
                        idproducto: item.idcatalogoproducto,
                        idproducto_resumen: 0,
                        idproveedor_recomendado: 0,
                        idunidadmedida: item.idcatalogo_unidadmedida,
                        pendientes: newPendientes,
                        producto: item.nombrecomercial,
                        proveedor_recomendado: "",
                        sustitutode: this.state.productocambiar.idproducto,
                        sustituir: 0,
                        unidadmedida: item.claveunidadmedida
                    }
            
                    json.productos.push(prod)
                })
    
                this.setState({ show: false });
                setTimeout(() => {
                    this.props.cerrarModal(json);
                }, 200);
            }else{
                toast.info("El agroquímico "+aux[0].nombrecomercial + " tiene menos cantidad de lo que ya se compró", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            }
            
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.props.cerrarModal(accion);
            }, 200);
        }
    }

    verModalDetalles = (ban) => {
        if(ban.opcion == 1){
            this.setState({ titulomodal: 'Buscar agroquímicos', accionmodal: 1, modalbuscarproducto: true })
        }
    }

    eliminarProducto = async (producto, index) => {
        //se elimina de los elementos listos para guardar si es que existe
        var aux = this.state.listaproductosseleccionado.filter((item) => !(item.idcatalogoproducto == producto.idcatalogoproducto));
        await this.setState({ listaproductosseleccionado: aux, hubocambio: true })
    }

    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    escribirCantidadPrecio = async (index, prop, event) => {
        
        var longitud = { longitudminimaentera: 1, longitudmaximaentera: 7, longitudminimadecimal: 0, longitudmaximadecimal: 2 };
        let tipocorrecto = validarNumerosDecimales3(event.target.value, longitud)
        var updateValor = event.target.value;

        if(tipocorrecto.codigo=='invalid'){
            toast.error(tipocorrecto.mensaje, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }

        var auxUpdateValor = updateValor.split('.');
        if(auxUpdateValor.length > 1){
            updateValor = auxUpdateValor[0].slice(0,7)+"."+auxUpdateValor[1].slice(0,2)
        }else{
            updateValor = auxUpdateValor[0].slice(0,7)
        }

        if (prop == 'cantidadCoti' && this.state.listaproductosseleccionado[index]['comprado'] == true
            && parseFloat(event.target.value) < this.state.listaproductosseleccionado[index]['cantidadStatic']
        ) {
            this.state.listaproductosseleccionado[index]['noguardar'] = true
            toast.error("La cantidad es menor a los agroquímicos comprados: " + this.state.listaproductosseleccionado[index]['cantidadStatic'], { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
        }
        else {
            
            this.state.listaproductosseleccionado[index]['noguardar'] = false
            if(this.state.listaproductosseleccionado[index]['comprado']==true){
                var resta = parseFloat(updateValor) - parseFloat(this.state.listaproductosseleccionado[index]['cantidadStatic']);
                var newResta = ""+resta;
                var auxresta = newResta.split('.');
                if(auxresta.length > 1){
                    newResta = auxresta[0].slice(0,7)+"."+auxresta[1].slice(0,2)
                }else{
                    newResta = auxresta[0].slice(0,7)
                }
                this.state.listaproductosseleccionado[index]['pendientes'] = newResta;
            }else{
                this.state.listaproductosseleccionado[index]['pendientes'] = parseFloat(updateValor)
            }
            
        }
        this.state.listaproductosseleccionado[index][prop] = updateValor;
        var dummy = this.state.listaproductosseleccionado
        this.setState({ listaproductosseleccionado: dummy, hubocambio: true });
    };

    manejadorLlamadasApis = (resp) => {
        if (resp == -1) {
            toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        else if (resp.code == 400) {
            toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            this.setState({ loadingspinners: false })
            return true
        }
        return false
    }

    apisproductos = async () => {

        if (this.state.respfamiliaproductos.length == 0 || this.state.resptipoproductos.length == 0 || this.state.respproductos.length == 0) {

            var respfamiliaproductos = await getComboFamiliaProducto();
            if (this.manejadorLlamadasApis(respfamiliaproductos)) {
                return;
            }

            var resptipoproductos = await getComboTipoProducto();
            if (this.manejadorLlamadasApis(resptipoproductos)) {
                return;
            }

            var respproductos = await getLista2Productos();
            if (this.manejadorLlamadasApis(respproductos)) {
                return;
            }

            var respunidades= await getComboUnidadMedida();
            if (this.manejadorLlamadasApis(respunidades)) {
                return;
            }
            
            await this.setState({
                respfamiliaproductos: respfamiliaproductos.data,
                resptipoproductos: resptipoproductos.data,
                respproductos: respproductos.data,
                listaunidadesmedida: respunidades.data,
                loadingspinners: false
            })

        }

    }

    cerrarModal = async (ban) => {
        this.setState({ modalbuscarproducto: !this.state.modalbuscarproducto })
        if (ban != 0 && ban != undefined) {
            var tabla = this.state.listaproductosseleccionado;
            //validamos que no exista en la lista de la modal sustituir
            var productoalmacen = this.state.listaproductosseleccionado.filter((item) => item.idcatalogoproducto == ban.idcatalogoproducto)
            if (productoalmacen.length > 0) {
                toast.info("Este agroquímico ya está en la lista", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
            } else {
                //validamos que no exista en la lista de resumen
                var productosresumen = this.state.productosresumen.filter((item) => item.idproducto == ban.idcatalogoproducto && item.idproducto!=this.state.productocambiar.idproducto)
                if (productosresumen.length > 0){
                    toast.info("Este agroquímico ya está en el resumen", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
                }else{
                    ban.cantidadCoti = "0";
                    if(ban.idcatalogoproducto.toString().includes("nuevo")){
                        
                        var pdnew = this.state.listaproductosnuevos.filter((item) => item.idcatalogoproducto == ban.idcatalogoproducto || item.nombrecomercial==ban.nombrecomercial)
                        
                        if(pdnew.length < 1){
                            this.setState({ loadingspinners: true })
                            //se guardara el producto
                            let productoSave = {
                                idcatalogoproducto: 0,
                                nombrecomercial: ban.nombrecomercial,
                                idcatalogo_unidadmedida: ban.idcatalogo_unidadmedida
                            }

                            var respproductosemergentes = await agregarProductoEmergente(productoSave);
                            if (this.manejadorLlamadasApis(respproductosemergentes)) {
                                return;
                            }
                            
                            ban.clave = ban.idproducto + " *";
                            ban.idproducto = respproductosemergentes.data.idcatalogo_producto
                            ban.idcatalogoproducto = respproductosemergentes.data.idcatalogo_producto
                            
                            this.state.listaproductosnuevos.push(ban)
                            var aux = this.state.listaproductosnuevos
                            tabla.push(ban)
                            this.setState({ listaproductosseleccionado: tabla, listaproductosnuevos: aux })
                            this.setState({ loadingspinners: false })
                        }else{
                            toast.info("Este agroquímico nuevo ya fue agregado", { autoClose: 2000, position: toast.POSITION.TOP_LEFT })
                        }
                    }else{
                        tabla.push(ban)
                        this.setState({ listaproductosseleccionado: tabla })
                    }
                }
            }
        }
    }

    agregarProductoComprado = () => {
        var tabla = [];

        var auxprod = this.state.respproductos.filter((item) => item.idcatalogoproducto == this.state.productocambiar.idproducto)
        
        if(auxprod[0]){
            auxprod[0].cantidadCoti = this.state.cantidadcomprada;
            auxprod[0].cantidadStatic = this.state.cantidadcomprada;
            auxprod[0].comprado = true;
            auxprod[0].pendientes = 0.00;
            auxprod[0].idproducto = auxprod[0].idcatalogoproducto;
            tabla.push(auxprod[0])
            this.setState({ listaproductosseleccionado: tabla })
        }
    }

    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                {(this.state.modalbuscarproducto == 456) ? (
                    <ModalFormularioBuscarProductos 
                        titulomodal={this.state.titulomodal} 
                        cerrarModal={this.cerrarModal}
                        todoslosproductosalmacen={true} 
                        listaproductosalmacen={this.state.respproductos}
                        show={this.state.modalbuscarproducto} 
                        accion={this.state.accionmodal} 
                        item={this.state.item}
                        respfamiliaproductos={this.state.respfamiliaproductos} 
                        resptipoproductos={this.state.resptipoproductos}
                        respproductos={this.state.respproductos}
                    />
                ) : null}

                {(this.state.modalbuscarproducto == true) ? (
                    <ModalFormularioBuscarProductosRequisicion 
                        titulomodal={this.state.titulomodal} 
                        cerrarModal={this.cerrarModal}
                        todoslosproductosalmacen={true} 
                        listaproductosalmacen={this.state.respproductos}
                        show={this.state.modalbuscarproducto} 
                        accion={this.state.accionmodal}
                        item={this.state.item}
                        respfamiliaproductos={this.state.respfamiliaproductos} 
                        resptipoproductos={this.state.resptipoproductos}
                        respproductos={this.state.respproductos}
                        listaunidadmedida={this.state.listaunidadesmedida}
                        listaproductosnuevos={this.state.listaproductosnuevos}
                        listaproductosagregados={this.state.listaproductosseleccionado}
                    />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{"Sustituir agroquímico"}</label>

                        <div className="divCuerpoModal">
                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className="container-fluid " style={{ backgroundColor: this.state.fondotitulosencabezados }}>
                                    <div className="container-fluid">
                                        <div className='row paddingEncabezados'>
                                            <div className='col-auto containerSubTabla paddingBottomEncabezados'>
                                                <label className='lblTexto letraTitulos' style={{ color: this.state.colortipolbl }}>Agroquímico actual: </label>
                                                <label className='lblTexto letraTitulos'>{this.state.productocambiar ? this.state.productocambiar.producto : "Seleccione un agroquímico"}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label className="modalLblTitulo letraTitulos">Nuevos agroquímicos</label>
                                    </div>
                                    <div className='col-auto'>
                                        <button className='btnNuevo letraNormal' onClick={this.verModalDetalles.bind(this, { opcion: "1" })}
                                            style={{
                                                color: this.state.colorbtnnuevo,
                                                backgroundColor: this.state.fondobtnnuevo,
                                                border: this.state.borderbtnnuevo,
                                                position: 'relative'
                                            }}>

                                            <BiSearchAlt2 className='icoLupa'
                                                style={{
                                                    top: '7px',
                                                    paddingRight: '2px',
                                                    color: this.state.colorlupa,
                                                    stroke: this.state.colorlupa
                                                }} />
                                            {"Buscar"}
                                        </button>


                                    </div>

                                </div>

                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}
                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="containerSubTablaAu">
                                            <Table>
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                        {this.state.tabla.encabezados.map((titulo, index) => (
                                                            <th key={"encabezados" + index} className="lblEncabezadosTabla letraTitulos"
                                                                style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                                {titulo}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listaproductosseleccionado.length > 0 ? this.state.listaproductosseleccionado.map((producto, index) => (
                                                            <tr key={"contenido" + index} className={producto.cantidadCoti < 1  || producto.noguardar ? "trFondoIncompleto" : ""}>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {producto.nombrecomercial}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <input type={'number'} pattern="^\d*,?\d*$" className="inputSubtabla" value={producto.cantidadCoti} onChange={(e) => this.escribirCantidadPrecio(index, 'cantidadCoti', e)}></input>
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {
                                                                        producto.comprado==true ? null
                                                                        :
                                                                        <div className="modalDetallesCheck" >
                                                                        <button className='btnEliminarFila'
                                                                        ><FaTrashAlt className="icoBotones" onClick={() => this.eliminarProducto(producto, index)} /></button>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.state.accion == 2 || this.state.accion == 4 ? (
                            <>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.showModal('10')} disabled={!this.state.hubocambio} >Sustituir</Button>
                                </div>
                            </>
                        ) : null}
                    </ModalBody>
                </Modal>
            </>
        )
    }
}