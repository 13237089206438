import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import "./ModalSalarios.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { estiloColorError, estilosModal, estiloFiltroEncabezados } from '../../../Services/PaletaDeColores';
import { validarNumerosDecimales, validarSoloNumeros } from '../../../Services/Validaciones';
import { getListaAsignarSalario } from '../../../Services/Api';



export default class ModalSalarios extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            titulo: props.titulomodal,

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortitulo: estiloFiltroEncabezados().colortitulos,

            idusuario: props.idusuario,
            txtsalario: props.salario,

            txttardeada: props.tardeada == null || props.tardeada == undefined ? "0.00" : props.tardeada,
            vermsgerrortxtsalario: false,
            msgerrortxtsalario: "",

            txtprincipal: "",
            vermsgerrortxtprincipal: false,
            msgerrortxtprincipal: "",
            notas: props.bandera == "notas" ? props.arrayModal.notasencargado : []

        }

    }


    async componentDidMount() {
        setTimeout(async () => {
            this.refInput.focus();
        }, 500);
    }

    setLoadingSpinner = (bandera) => {
        this.props.setloadingspinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }


    guardardatos = async () => {

        //    console.log("Datos 22 ")
        this.setState({ loadingspinners: true })
        let restxtmonto = validarNumerosDecimales(this.state.txtsalario);
        if (restxtmonto.codigo == "invalid") {
            this.setState({ loadingspinners: false })
            toast.info("Escribe solo números", { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
        }
        else {
            // toast.info("Error de conexión", { autoClose: 3000, position: toast.POSITION.TOP_LEFT })
            var json = {
                "idusuario": this.state.idusuario,
                "salario": this.state.txtsalario,
                "tardeada": this.state.txttardeada
            }

            //    console.log(json)
            var resp = await getListaAsignarSalario(json);
            this.showModal(1);
            //    console.log("Datos 111 ")
        }


    }


    render() {
        return (

            <>
                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />

                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>
                        <div className="divTituloModal">
                            <label className="modalLblTitulo letraTitulos" style={{ color: this.state.colortitulo }} >{this.state.titulo}</label>
                        </div>

                        {this.props.bandera == "notas" ?

                            <div className="divModalEliminar">
                                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>
                                    {this.state.notas == undefined || this.state.notas.length == 0 ? 
                                        <div className='row'>
                                            <div className='col'>
                                                No hay notas
                                            </div>
                                        </div>
                                    :this.state.notas.map((item, index) => (

                                        <div key={index} className='row'>
                                            <div className='col'>

                                                <label className='lblSubTitulosModal' style={{ color: "black", fontSize: "medium", fontWeight: "normal" }}>{item.nota}</label>
                                                <b style={{fontWeight:"bold", fontSize:"12px"}}> - {item.fechahora}</b>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>

                            :

                            <div className="divModalEliminar">
                                <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal, boxSizing: "content-box" }}>

                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Salario</p>

                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'number'} value={this.state.txtsalario} onChange={this.escribir("txtsalario")} ref={(input) => { this.refInput = input; }}></input>

                                    <br />
                                    <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tardeada</p>

                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'number'} value={this.state.txttardeada} onChange={this.escribir("txttardeada")} ref={(input) => { this.refInput = input; }}></input>


                                    <br />

                                    {this.state.vermsgerrortxtsalario ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtsalario}</p> : null}

                                    {(!this.state.vermsgerrortxtsalario && this.state.vermsgerrortxtprincipal) ? <p className="msgError letraNormal"
                                        style={{ color: this.state.colormsgerror }}>{this.state.msgerrortxtprincipal}</p> : null}

                                </div>




                            </div>

                        }

                        {this.props.bandera == "notas" ?
                            <div className="divBtnContenedorModal" >
                                <br />
                                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Regresar</Button>
                            </div>
                            :
                            <div className="divBtnContenedorModal" >
                                <br />
                                <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                <Button className="btnAceptarModal" onClick={() => this.guardardatos()} >Continuar</Button>
                            </div>
                        }


                    </ModalBody>

                </Modal>
            </>
        )
    }
}
