import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BiSearchAlt2 } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';

import "./ModalPropuestaAnual.css";
import { estiloBtnActualizar,coloresTxtBuscador, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
import { Table } from 'reactstrap';
import ModalTablasDisponibles from './ModalTablasDisponibles';
import ModalConfirmacion from './ModalConfirmacion';


export default class ModalAgregarTablas extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            titulomodalconfirmacion: "Eliminar tabla",
            mostrarmodalbuscaract: false,
            mostrarmodalconfirmacion: false,
            mensajeconfirmacion: "",
            mensajepreguntaconfirmacion: "¿Eliminar de todos modos?",
            labelbotonconfirmacion: "Eliminar",
            txtbuscador:"",
            estilosmodal: estilosModal(),
            filtrolote: -1,
            listalotes: this.props.listalotes,
            listacatalogotablas: this.props.listacatalogotablas,
            listatablasfiltradas: this.props.listacatalogotablasagregadas,
            listatablasagregadas:this.props.listacatalogotablasagregadas,

            tablaaeliminar: undefined,
            tabla: {
                encabezados: [
                    "Tabla",
                    "Lote",
                    "Cultivo",
                    "Hectáreas",
                    "Árboles",
                    "",
                ],
                dimensiones: [
                    "20%",
                    "15%",
                    "15%",
                    "15%",
                    "15%",
                    "5%",
                ],
                mindimensiones: [
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "100px",
                    "20px",
                ],
                elementos: [],
            },
            bordertxtbuscar: coloresTxtBuscador().bordertxtbuscar,
            colorlupa: coloresTxtBuscador().colorlupa,
            fondoicocloseinputs: coloresTxtBuscador().fondoicocloseinputs,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,
            fondobtnactualizar: estiloBtnActualizar().fondobtnactualizar,
            colorbtnactualizar: estiloBtnActualizar().colorbtnactualizar,
            btnbordereditar2: "2px solid #0061fe",
        }

    }
    async componentDidMount() {

    

    }

    filtroLote = async (itemfiltrado) => {
        var data = JSON.parse(JSON.stringify(this.state.listatablasagregadas))
        await this.setState({ 
            filtrolote: itemfiltrado
        })
      
        var filtrofinal = [];
    
        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        if(itemfiltrado != -1){
      
          listafiltrotipo = data.filter((item) => item.idlote == itemfiltrado)
        }
    
        filtrofinal = listafiltrotipo;
    
        if(this.state.txtbuscador.length>2){
            filtrofinal = listafiltrotipo.filter((item) => item.tabla.toLocaleLowerCase().includes(this.state.txtbuscador.toLocaleLowerCase()))
        }
       if (filtrofinal.length <= 0) {
            toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
        }
        await this.setState({
            listatablasfiltradas: filtrofinal
        })
      
      }

    saveInputLocacion = async (e) => {
        this.setState({ txtbuscador: e.target.value });
        var data = JSON.parse(JSON.stringify(this.state.listatablasagregadas))

        var listafiltrotipo = [];
        
        listafiltrotipo = data;
        
        if(this.state.filtrolote != -1){
            listafiltrotipo = data.filter((item) => item.idlote == this.state.filtrolote)
        }

        if (e.target.value.trim().length > 2) {

            const filtros = listafiltrotipo.filter((item) => item.tabla.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))

            if (filtros.length < 1) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
           await this.setState({ listatablasfiltradas: filtros });
        }else if(e.target.value.trim().length < 1){
            this.filtroLote(this.state.filtrolote)
        }

    }

    borrarBusqueda = async () => {
       await this.setState({
            txtbuscador: ''
        });
        this.filtroLote(this.state.filtrolote)
    }

   

    showModal = (mensaje,accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje,accion);
        }, 200);

    }

    cerrarModalAgregarTablas = (mensaje) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(mensaje);
        }, 200);

    }

    mostrarModalTablasDisponibles = () => {
        this.setState({ mostrarmodalbuscaract: true });
    }
    cerrarModalTablasDisponibles = async (listatablas) => {
        var tablas = this.state.listatablasagregadas.concat(listatablas);
       await this.setState({ 
            mostrarmodalbuscaract: false,
            listatablasagregadas: tablas
        });
        
        if(listatablas.length>0){
            setTimeout(() => {
                this.filtroLote(this.state.filtrolote)
            }, 200);
        }
        
        
    }

    guardarTablas = async () => {
      
        await this.showModal(this.state.listatablasagregadas, 1)
    
    }

    mostrarModalConfirmacion = async (item) => {
        await this.setState({
            mostrarmodalconfirmacion: true,
            tablaaeliminar: item,
            mensajeconfirmacion: "Tabla " + item.tabla + " está en uso."
        })
    }

    cerrarModalConfirmacion = async (accion) => {
        if(accion == 1){
           await this.eliminarTabla();
        }
        await this.setState({
            mostrarmodalconfirmacion: false
        })
    }

    eliminarTabla = async () => {
        var listatablasagregadasaux = this.state.listatablasagregadas.filter(item => item.idtabla != this.state.tablaaeliminar.idtabla);
      
        await this.setState({
            listatablasagregadas: listatablasagregadasaux
        })
        setTimeout(() => {
            this.filtroLote(this.state.filtrolote)
        }, 200);
        
    }

    render() {
        return (

            <>
              {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}
                {(this.state.mostrarmodalbuscaract ) ? (
                <ModalTablasDisponibles  cerrarModal={this.cerrarModalTablasDisponibles}
                    show={this.state.mostrarmodalbuscaract}   
                    listacatalogotablas={JSON.parse(JSON.stringify(this.state.listacatalogotablas))}
                    listalotes={JSON.parse(JSON.stringify(this.state.listalotes))}
                    listatablasagregadas={JSON.parse(JSON.stringify(this.state.listatablasagregadas))}
                    ranchoseleccionado={this.props.ranchoseleccionado}
                    idactividad={this.props.idactividad}
                    respaldoinfopropuesta={this.props.respaldoinfopropuesta}
                    />
                ) : null}
                {this.state.mostrarmodalconfirmacion ? (
                <ModalConfirmacion titulomodal={this.state.titulomodalconfirmacion}  mensaje={this.state.mensajeconfirmacion} 
                    cerrarModal={this.cerrarModalConfirmacion}
                    show={this.state.mostrarmodalconfirmacion}
                    mensajepreguntaconfirmacion={this.state.mensajepreguntaconfirmacion}
                    labelbotonconfirmacion={this.state.labelbotonconfirmacion}
                    />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal(this.props.listacatalogotablasagregadas,0)} />
                        <label className="modalLblTitulo letraTitulos" style={{ Color: this.state.estilosmodal.colorlbltextomodal }} >{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla paddingBottomEncabezados '>
                                        <div className='divComboSelect' style={{ color: this.state.colorcbxtipolbl, backgroundColor: this.state.colorfondotipo, border: this.state.colorbordertipo }}>
                                            <label className='lblTexto letraTitulos ' style={{ color: this.state.colorlupa }}>Lote</label>
                                            <div className="selectBox selectOptionL">
                                                <select className="cbxTexto letraTitulos" style={{ color: this.state.colorcbxtipolbl }}
                                                    onChange={(event) => this.filtroLote(event.target.value)}>
                                                    {this.state.filtrolote == -1 ?
                                                        <option selected="true" value={-1}>Todos</option> : <option value={-1}>Todos</option>}
                                                    {this.state.listalotes.map((i, index) => (
                                                        i.idcatalogo_rancho == this.props.ranchoseleccionado ?
                                                        <option value={i.idcatalogo_ranchos_lotes_cultivo} key={"tipo_" + index}  >{i.lote} </option>
                                                        : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla paddingBottomEncabezados '>
                                        <div className='txtBuscadorCN' >
                                            <BiSearchAlt2 className='icoLupa' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa }} />
                                            <input id='Search' type="search" onChange={this.saveInputLocacion} value={this.state.txtbuscador}

                                                className="txtBuscadorBCN letraNormal" style={{ color: this.state.colortxtbuscar, backgroundColor: this.state.fondotxtbuscar, border: this.state.colorbordertipo }}
                                                placeholder="Buscar tabla"></input>
                                            {this.state.txtbuscador ? (<IoMdClose className='icoInputBuscarClose' style={{ color: this.state.colorlupa, stroke: this.state.colorlupa, backgroundColor: this.state.fondoicocloseinputs }}
                                                onClick={this.borrarBusqueda} />) : null}
                                        </div>
                                    </div>
                                    
                                </div>
                                <br />
                                <div className='row '>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla  '>
                                        <label className='letraTitulos'>Selecciona las tablas: </label>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 containerSubTabla '>
                                        <button className='btnActualizar letraNormal btnFloatRight' 
                                         onClick={this.mostrarModalTablasDisponibles}
                                        style={{ color: this.state.fondobtnactualizar, backgroundColor: this.state.colorbtnactualizar, border: this.state.fondobtnactualizar }}
                                        >Buscar</button>
                                    </div>
                                </div>


                                {/* ////////////////////   Tabla   ////////////////////////////////////// */}

                                <br />
                                <div className="container-fluid containerTabla">
                                    <div className="container-fluid anchoMaximo ">
                                        <div className="container-fluid tablaMaxHeight">
                                            <Table >
                                                <thead style={{ verticalAlign: "bottom" }}>
                                                    <tr>
                                                    {this.state.tabla.encabezados.map((titulo, index) => (
                                                        <th key={"tabla" + index} className={" letraTitulos"}
                                                            style={{ minWidth: this.state.tabla.mindimensiones[index], width: this.state.tabla.dimensiones[index], color: this.state.colorencabezadostabla }}>
                                                            {titulo}
                                                        </th>
                                                    ))}
                                                        
                                                    </tr>
                                                </thead>

                                                <tbody style={{ verticalAlign: "bottom" }}>
                                                    {
                                                        this.state.listatablasfiltradas.map((item, index) => (
                                                            <tr key={"tabla" + index} >
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.tabla}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.lote}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.cultivo}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.hectareas}
                                                                </td>
                                                                <td className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    {item.arboles}
                                                                </td>
                                                                <td key={"col-"+index+"-05"} className="letraNormal lblSubtabla" style={{ color: this.state.colorcontenidotabla }}>
                                                                    <div className="modalDetallesCheck" >
                                                                    <button className='btnEliminarFila' 
                                                                    onClick={() => this.mostrarModalConfirmacion(item)} 
                                                                    ><IoMdClose className="icoBotones" /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="divBtnContenedorModal" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal(this.props.listacatalogotablasagregadas,0)}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarTablas()} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

