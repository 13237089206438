import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';
import "./ModalFormularioLoteCultivos.css";
import { estiloColorError, estiloComboBox, estilosModal } from '../../../Services/PaletaDeColores';
import { validarLetrasNumerosLongitud, validarNumerosDecimales, validarNumerosDecimales2, validarPH, validarLetrasNumerosPuntoComa, validarLetrasNumerosEspacios } from '../../../Services/Validaciones';
import ReactTooltip from "react-tooltip";
import { getListaLoteCultivosUpdate, getListaRanchosDisponibles } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerModal from '../SpinnerModal/SpinnerModal';
 
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";

export default class ModalFormularioLoteCultivos extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            confirmarenvio: false,
            accion: props.accion, // 1 Nuevo, 2 editar
            titulo: props.titulomodal,
            loadingspinners: false,

            txtnombre: (props.accion == 2) ? (props.item.lote) : '',
            txtzona: (props.accion == 2) ? (props.item.idcatalogo_zona) : '0',
            txtrancho: (props.accion == 2) ? (props.item.idcatalogo_rancho) : '0',
            tipocultivo: (props.accion == 2) ? (props.item.idtipo_cultivo) : '0',
            cultivo: (props.accion == 2) ? (props.item.idcatalogo_cultivo) : '0',
           
            txtpatroninjerto: (props.accion == 2) ? (props.item.idcatalogo_patron_injerto) : '0',
            txtdescripcion: (props.accion == 2) ? (props.item.descripcion) : '',

            txtfechainicialsiembra: (props.accion == 2) ? (props.item.fechainicio) : '',
            txtfechafinsiembra: (props.accion == 2) ? (props.item.fechafin) : '',
            txtph: (props.accion == 2) ? (props.item.ph) : '',
            txtdensidad: (props.accion == 2) ? (props.item.densidad) : '',
            txtmarcoplantacion: (props.accion == 2) ? (props.item.marcoplantacion) : '',
            
            
            arrayestatus: props.estatus,
            idestatus: 1,

            vermsgerrornombre: false,
            vermsgerrorzonas: false,
            vermsgerrorranchos: false,
            vermsgerrorcultivos: false,
            vermsgerrortipocultivos: false,
          
            vermsgerrorfechainicialsiembra: false,
            vermsgerrorfechafinsiembra: false,
            vermsgerrorpatroninjerto: false,
            vermsgerrorph: false,
            vermsgerrordensidad: false,
            vermsgerrormarcoplantacion: false,
            vermsgerrordescripcion: false,

            msgerror: '',

            msgerrornombre: "",
            msgerrorzona: "",
            msgerrorrancho: "",
            msgerrorcultivos: "",
            msgerrortipocultivos: "",
            
            msgerrorfechainicialsiembra: "",
            msgerrorfechafinsiembra: "",
            msgerrorpatroninjerto: "",
            msgerrorph: "",
            msgerrordensidad: "",
            msgerrormarcoplantacion: "",
            msgerrordescripcion: "",

            colormsgerror: estiloColorError().msgerror,
            estilosmodal: estilosModal(),
            colortipolbl: estiloComboBox().colorcombolbl,
            colorcbxtipolbl: estiloComboBox().colorcbxlbl,
            colorbordertipo: estiloComboBox().colorbordercombo,
            colorfondotipo: estiloComboBox().colorfondocombo,

            listazonas: props.listazonas,
            listaranchos: props.listaranchos,
            listacultivos: props.listacultivos,
            listatipocultivos: props.listatipocultivos,
            
            listainjerto: props.listainjerto,

            fechainicialsiembra: (props.accion == 2) ? (this.formatofechas(props.item.fechainicio)) : '',
            fechafinsiembra: (props.accion == 2) ? (this.formatofechas(props.item.fechafin)) : '',

        }

    }
    async componentDidMount() {
        this.cambiarValor((this.props.accion == 2) ? (this.props.item.idestatus) : this.state.idestatus)
    }


    setLoadingSpinner = (bandera) => {
        this.props.setLoadingSpinner(bandera);
    }

    showModal = (accion) => {
        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    formatofechas(fecha) {
        if(fecha !=""){
            var cadenas = fecha.split("/");
            var formato = cadenas[1] + "/" + cadenas[0] + "/" + cadenas[2];
            return new Date(formato)

        }else{
            return fecha
        }
       
    }


    //convierte formato dd/mm/yyyy a yyyy-mm-dd
    regresaFecha = (fecha) => {
        var fechaf = "";
        if(fecha!=null){
            var [day, month, year] = fecha.split("/");
            
            if(day!=undefined && month!=undefined && year!=undefined){
                fechaf = `${year}-${month}-${day}`
                return fechaf;
            }else{
                fechaf = fecha;
            }
        }
        
        return fechaf;
    }
    //convierte formato yyyy-mm-dd a dd/mm/yyyy
    regresaFechaSave = (fecha) => {
        var fechaf = "";
        if(fecha!=null){
            var [year, month, day] = fecha.split("-");
        
            if(day!=undefined && month!=undefined && year!=undefined){
                fechaf = `${day}/${month}/${year}`
                return fechaf;
            }else{
                fechaf = fecha;
            }
        }
           
        return fechaf;
    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        if(prop=="txtzona"){
            this.setState({ txtrancho: 0 })
        }
        if(prop=="tipocultivo"){
            this.setState({ cultivo: 0 })
        }
    };

    cambiarValor = (id) => {
        var array = this.state.arrayestatus;
        array.forEach(function (item) {
            item.activo = false;
            if (item.idestatus == id) {
                item.activo = true;

            }
        });
        this.setState({ idestatus: id })
    }

    guardarCambios = async () => {

        this.setState({ vermsgerrornombre: false })
        this.setState({ vermsgerrorranchos: false })
        this.setState({ vermsgerrorzonas: false })
        this.setState({ vermsgerrorcultivos: false })
        this.setState({ vermsgerrortipocultivos: false })
        
        this.setState({ vermsgerrorfechainicialsiembra: false })
        this.setState({ vermsgerrorfechafinsiembra: false })
        this.setState({ vermsgerrorpatroninjerto: false })
        this.setState({ vermsgerrorph: false })
        this.setState({ vermsgerrordensidad: false })
        this.setState({ vermsgerrormarcoplantacion: false })
        this.setState({ vermsgerrordescripcion: false })

        let validacionescorrectas = true
        let longitudvalida = ""
        let tipocorrecto = ""

        longitudvalida = {longitudminima: 1, longitudmaxima: 50};
        tipocorrecto = validarLetrasNumerosLongitud(this.state.txtnombre.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrornombre: tipocorrecto.mensaje })
            this.setState({ vermsgerrornombre: true })
            validacionescorrectas = false;
        }

        if (this.state.txtzona < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorzona: "Selecciona una zona" })
            this.setState({ vermsgerrorzonas: true })
        }

        if (this.state.txtrancho < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorrancho: "Seleccione un rancho" })
            this.setState({ vermsgerrorranchos: true })
        }

        if (this.state.cultivo < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorcultivos: "Seleccione un cultivo" })
            this.setState({ vermsgerrorcultivos: true })
        }

        if (this.state.tipocultivo < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrortipocultivos: "Seleccione un tipo de cultivo" })
            this.setState({ vermsgerrortipocultivos: true })
        }


        if (this.state.txtpatroninjerto < 1) {
            validacionescorrectas = false;
            this.setState({ msgerrorpatroninjerto: "Selecciona un patron de injerto" })
            this.setState({ vermsgerrorpatroninjerto: true })
        }

        if(this.state.txtfechainicialsiembra=="" || this.state.txtfechainicialsiembra==null){
            validacionescorrectas = false;
            this.setState({ msgerrorfechainicialsiembra: "Selecciona una fecha" })
            this.setState({ vermsgerrorfechainicialsiembra: true })
        }

        /*if(this.state.txtfechafinsiembra==""){
            validacionescorrectas = false;
            this.setState({ msgerrorfechafinsiembra: "Selecciona una fecha" })
            this.setState({ vermsgerrorfechafinsiembra: true })
        }*/

        var fechainicial = this.regresaFechaSave(this.state.txtfechainicialsiembra);
        var fechafin = this.regresaFechaSave(this.state.txtfechafinsiembra);

        tipocorrecto = validarPH(this.state.txtph.trim());
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrorph: tipocorrecto.mensaje })
            this.setState({ vermsgerrorph: true })
            validacionescorrectas = false;
        }

        tipocorrecto = validarNumerosDecimales(this.state.txtdensidad.trim());
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrordensidad: tipocorrecto.mensaje })
            this.setState({ vermsgerrordensidad: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 1, longitudmaxima: 100};
        tipocorrecto = validarNumerosDecimales2(this.state.txtmarcoplantacion.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrormarcoplantacion: tipocorrecto.mensaje })
            this.setState({ vermsgerrormarcoplantacion: true })
            validacionescorrectas = false;
        }

        longitudvalida = {longitudminima: 0, longitudmaxima: 100};
        tipocorrecto = validarLetrasNumerosPuntoComa(this.state.txtdescripcion.trim(), longitudvalida);
        if (tipocorrecto.codigo == "invalid") {
            this.setState({ msgerrordescripcion: tipocorrecto.mensaje })
            this.setState({ vermsgerrordescripcion: true })
            validacionescorrectas = false;
        }

        if (validacionescorrectas) {
            this.setLoadingSpinner(true);
            let json = {
                "idlote": this.state.accion == 1 ? ("0") : (this.props.item.idcatalogo_ranchos_lotes_cultivo),
                "lote": this.state.txtnombre,
                "idrancho": this.state.txtrancho,
                "descripcion": this.state.txtdescripcion,
                "fechainicio": fechainicial,
                "fechafin": fechafin,
                "idcultivo": this.state.cultivo,
                "idpatron": this.state.txtpatroninjerto,
                "ph": this.state.txtph,
                "densidad": this.state.txtdensidad,
                "marcoplantacion": this.state.txtmarcoplantacion,
                "idestatus": this.state.idestatus
            }

            var resp = await getListaLoteCultivosUpdate(json);
            if(resp.code == 0){
                if (this.state.accion == 1) {
                    this.showModal('1')
                }
                else if (this.state.accion == 2) {
                    this.showModal('2')
                }
            }
            else if(resp.code == 200){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 300){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if(resp.code == 400){
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Operación rechazada, vuelve a intentar", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
                return;
            }
            else if (resp.code == 1000) {
                this.setLoadingSpinner(false);
                toast.error(resp.message, { autoClose: 1000, position: toast.POSITION.TOP_LEFT });
                return;
            }else{
                this.setLoadingSpinner(false);
                this.setState({ confirmarenvio: !this.state.confirmarenvio })
                toast.error("Error de conexión", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
        }
    }

    cambiarFecha = (item) => {
        //console.log(item)
        this.setState({ [item.llave]: item.valor })
        let day = `0${item.valor.getDate()}`.slice(-2)
        let month = `0${item.valor.getMonth() + 1}`.slice(-2)
        let year = item.valor.getFullYear()
        this.setState({ ['txt' + item.llave]: year + '-' + month + '-' + day })
    }

    render() {
        return (

            <>

                {(this.state.loadingspinners == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loadingspinners} />
                ) : null}

                <ToastContainer />
                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        <label className="modalLblTitulo letraTitulos">{this.state.titulo}</label>

                        <br />
                        <br />
                        <div className="divCuerpoModal">

                            <div className="divContenedorModal" style={{ backgroundColor: this.state.estilosmodal.fondocontenedormodal }}>

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Nombre lote</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtnombre} onChange={this.escribir("txtnombre")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtnombre}</label>}

                                {this.state.vermsgerrornombre ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrornombre}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Zona</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                <option disabled="disabled">Seleccionar</option>
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                        <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona} selected={i.idcatalogo_zona==this.state.txtzona ? true : false} >{i.zona} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtzona")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listazonas == undefined || this.state.listazonas.length == 0 ? null : this.state.listazonas.map((i, index) => (
                                                        <option value={i.idcatalogo_zona} key={"tipo_" + i.idcatalogo_zona}>{i.zona}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrorzonas ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorzona}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Rancho</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                    <option disabled="disabled" selected={this.state.txtrancho==0 ? true : false}>Seleccionar</option>
                                                    {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                        this.state.txtzona == i.idcatalogo_zona ?
                                                        <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho} selected={i.idcatalogo_rancho==this.state.txtrancho ? true : false} >{i.rancho} </option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtrancho")}>
                                                    <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listaranchos == undefined || this.state.listaranchos.length == 0 ? null : this.state.listaranchos.map((i, index) => (
                                                        this.state.txtzona == i.idcatalogo_zona ?
                                                        <option value={i.idcatalogo_rancho} key={"tipo_" + i.idcatalogo_rancho}>{i.rancho}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrorranchos ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorrancho}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Descripción</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdescripcion} onChange={this.escribir("txtdescripcion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdescripcion}</label>}

                                {this.state.vermsgerrordescripcion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordescripcion}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha inicial de siembra</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechainicialsiembra}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechainicialsiembra", valor: date })}
                                            dateFormat="dd/MM/yyyy"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfechainicialsiembra}</label>}

                                {this.state.vermsgerrorfechainicialsiembra ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechainicialsiembra}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Fecha final de siembra</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col-auto' style={{ margin: '0px', paddings: '0px', zIndex: '3' }}>
                                        <DatePicker
                                            selected={this.state.fechafinsiembra}
                                            onChange={(date) => this.cambiarFecha({ llave: "fechafinsiembra", valor: date })}
                                            dateFormat="dd/MM/yyyy"
                                            locale="es"
                                            customInput={
                                                <DateButton />
                                            }
                                        />
                                    </div>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtfechafinsiembra}</label>}

                                {this.state.vermsgerrorfechafinsiembra ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorfechafinsiembra}</p> : null}


                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Tipo de cultivo</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tipocultivo")}>
                                                <option disabled="disabled">Seleccionar</option>
                                                    {this.state.listatipocultivos == undefined || this.state.listatipocultivos.length == 0 ? null : this.state.listatipocultivos.map((i, index) => (
                                                        <option value={i.idtipo_cultivo} key={"tipo_" + i.idtipo_cultivo} selected={i.idtipo_cultivo==this.state.tipocultivo ? true : false} >{i.tipo} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("tipocultivo")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listatipocultivos == undefined || this.state.listatipocultivos.length == 0 ? null : this.state.listatipocultivos.map((i, index) => (
                                                        <option value={i.idtipo_cultivo} key={"tipo_" + i.idtipo_cultivo}>{i.tipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrortipocultivos ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrortipocultivos}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Cultivo</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("cultivo")}>
                                                <option disabled="disabled" selected={this.state.cultivo==0 ? true : false}>Seleccionar</option>
                                                    {this.state.listacultivos == undefined || this.state.listacultivos.length == 0 ? null :  this.state.listacultivos.map((i) => (
                                                        this.state.tipocultivo == i.idtipo_cultivo ?
                                                            <option value={i.idcatalogo_cultivo} key={"tipo_" + i.idcatalogo_cultivo} selected={i.idcatalogo_cultivo==this.state.cultivo ? true : false} >{i.cultivo} </option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("cultivo")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listacultivos == undefined || this.state.listacultivos.length == 0 ? null : this.state.listacultivos.map((i) => (
                                                        this.state.tipocultivo == i.idtipo_cultivo ?
                                                            <option value={i.idcatalogo_cultivo} key={"tipo_" + i.idcatalogo_cultivo}>{i.cultivo}</option> : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrorcultivos ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorcultivos}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Patrón de injerto</p>
                                {this.state.accion == 2 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtpatroninjerto")}>
                                                <option disabled="disabled">Seleccionar</option>
                                                    {this.state.listainjerto== undefined || this.state.listainjerto.length == 0 ? null : this.state.listainjerto.map((i) => (
                                                        <option value={i.idcatalogo_patron_injerto} key={"tipo_" + i.idcatalogo_patron_injerto} selected={i.idcatalogo_patron_injerto==this.state.txtpatroninjerto ? true : false} >{i.patroninjerto} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>
                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal" style={{ zIndex: 'auto' }} onChange={this.escribir("txtpatroninjerto")}>
                                                <option disabled="disabled" selected="true">Seleccionar</option>
                                                    {this.state.listainjerto == undefined || this.state.listainjerto.length == 0 ? null : this.state.listainjerto.map((i) => (
                                                        <option value={i.idcatalogo_patron_injerto} key={"tipo_" + i.idcatalogo_patron_injerto}>{i.patroninjerto}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}

                                {this.state.vermsgerrorpatroninjerto ? 
                                    <p className="msgError letraNormal" style={{ color: this.state.colormsgerror }}>{this.state.msgerrorpatroninjerto}</p> : null}

                           
                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>PH</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtph} onChange={this.escribir("txtph")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtph}</label>}

                                {this.state.vermsgerrorph ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrorph}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Densidad (unidades/hectárea)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtdensidad} onChange={this.escribir("txtdensidad")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtdensidad}</label>}

                                {this.state.vermsgerrordensidad ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrordensidad}</p> : null} 

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Marco de plantación (metros)</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <input className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, backgroundColor: this.state.estilosmodal.fondocontenedormodal, border: 'none', borderBottom: this.state.estilosmodal.colorborderbottom }}
                                        type={'text'} value={this.state.txtmarcoplantacion} onChange={this.escribir("txtmarcoplantacion")}></input>
                                ) : <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>{this.state.txtmarcoplantacion}</label>}

                                {this.state.vermsgerrormarcoplantacion ? <p className="msgError letraNormal"
                                    style={{ color: this.state.colormsgerror }}>{this.state.msgerrormarcoplantacion}</p> : null}

                                <p className='lblSubTitulosModal letraTitulos' style={{ color: this.state.estilosmodal.colorlblsubtitulosmodal }}>Estatus</p>
                                {this.state.accion == 2 || this.state.accion == 1 ? (
                                    <div className='col lblSubTitulosModal '>
                                        <div className='comboModal' style={{ borderBottom: this.state.colorbordertipo }}>

                                            <div className="selectBox ">
                                                <select className="cbxTexto letraNormal"
                                                    onChange={(event) => this.cambiarValor(event.target.value)}>
                                                    {this.state.arrayestatus.map((i, index) => (
                                                        <option value={i.idestatus} key={"tipo_" + index} selected={i.activo} >{i.estatus} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    <label className='lblTextoModal letraNormal' style={{ color: this.state.estilosmodal.colorlbltextomodal, borderBottom: this.state.estilosmodal.colorborderbottom }}>Activo</label>
                                }
                            </div>
                            
                        </div>

                        {this.state.accion == 2 || this.state.accion == 1 ? (
                            <>
                                <br />
                                <div className="modalDetallesCheck" onClick={() => this.setState({ confirmarenvio: !this.state.confirmarenvio })}>
                                    <input type="checkbox" checked={this.state.confirmarenvio} /> Confirmar información
                                </div>
                                <br />
                                <div className="divBtnContenedor" >
                                    <Button className="btnCancelModal" onClick={() => this.showModal('0')}>Cancelar</Button>
                                    <Button className="btnAceptarModal" onClick={() => this.guardarCambios()} disabled={!this.state.confirmarenvio} >Guardar</Button>
                                </div>
                            </>
                        ) : null}

                    </ModalBody>

                </Modal>
            </>
        )
    }

}

const DateButton = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} onClick={onClick} className="btnDateInput letraNormal"
        style={{
            backgroundColor: 'transparent', padding: '0px 0px 0px 0px', borderRadius: '0px',
            borderBottom: '2px solid #868686',width: '96%',margin: '0px 8px 18px 8px'
        }}
    >
      <label style={{ width: '90%', textAlign: 'left', cursor: 'pointer' }}>{value ? value: 'dd/mm/aaaa'}</label>
      <FaCalendar className="icoBotones" style={{ minWidth: '28px' }}/>
    </button>
  ));
